import React from 'react';
import { Container, Divider, List, Label, Loader, Dimmer, Popup, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
let customFontFam = "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"

export const YugtunLoader = (props) => (
  <Dimmer active={props.criteria}>
    <Loader size='massive'>
      Iñupiaq is loading...
    </Loader>
  </Dimmer>
);

// let customFontFam = "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"

export const processStyledText = (sentence) => {
      // sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
    }


export const processStyledTextLink = (sentence) => {
      sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","").replaceAll("↞","").replaceAll("&","").replaceAll("↠","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span style={{whiteSpace:'nowrap'}} dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span style={{whiteSpace:'nowrap'}}>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
    }

export const NewUsageBaseDisplay = (k) => {
    let returnNewUsageBaseDisplayLabel = {
      'verb phrase':[
        'present',
        'past',
        'question',
        'polite command',
        'command',
      ],
      'noun':[
        'stem',
        'possessive',
        'locative noun case',
        'with pronoun',
      ],
    }

    let word = k.word
    let state = k.state
    let props = k.props
    // console.log(word)


    return  <List.Item style={{paddingBottom:'8px',paddingTop:'15px'}}>
              <List.Content style={{fontSize:'16px',paddingBottom:'10px'}}><span style={{paddingRight:'20px',fontWeight:'500'}}>{word.url.replaceAll(',',', ')}</span><span style={{paddingRight:'15px',fontStyle:'italic'}}>{processStyledText(word.thisDefinition)}</span><span style={{'marginLeft':'15px',marginRight:'6px',cursor:'pointer'}}><TagColors tags={word.t} width={window.innerWidth} /></span></List.Content>
              {word.newUsageDefinitions.map((u,uindex)=>
              <List.Content style={{paddingLeft:'20px'}}>
                <Link to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:word.backendCalls[uindex],usagedictionary_dict: word.usagedictionary_dict, activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, word: word.usageWord, num: word.num, usageDefinition: word.usageDefinition, from: props.location.pathname, history:[state.search,state.wordsList,state.Yposition, state.activeTabIndex] }}}>
                  <List.Content floated='right'>
                    <Icon circular style={{marginTop:'3px', color:'#8f8f8f'}} size='small' name='chevron right' />
                  </List.Content>
                </Link>
                <List.Content style={{display:'flex',fontSize:'16px',paddingBottom:'8px',fontFamily:customFontFam,lineHeight:'25px'}}>
                  <span style={{flex:2,'paddingRight':'3px',fontWeight:'500'}}>
                  <Link style={{borderBottom:'1px solid #858585',paddingBottom:'1px',color:'#306190'}} to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:word.backendCalls[uindex],usagedictionary_dict: word.usagedictionary_dict, activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, word: word.usageWord, num: word.num, usageDefinition: word.usageDefinition, from: props.location.pathname, history:[state.search,state.wordsList, state.Yposition, state.activeTabIndex] }}}>
                  {processStyledTextLink(word.usageWords[uindex])}
                  </Link>
                  {word.audioMatrix[uindex] ?
                    <Button onClick={()=>this.repeatAudio(word.audioMatrix[uindex])} style={{paddingLeft:'7px',fontSize:'14px',color:'#4e7498'}} icon='volume up' />
                    :
                    null
                  }
                  </span>
                  <span style={{flex:5,fontSize:'16px',color:'#000000cc',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{processStyledText(word.newUsageDefinitions[uindex])}</span>
                  <span style={{flex:3,color:'#BEBEBE',fontStyle:'italic',textAlign:'right'}}>{returnNewUsageBaseDisplayLabel[word.t][uindex]}</span>
                </List.Content>
              </List.Content>
              )}
            </List.Item>  
}

export const TagColors = (props) => {
  // console.log(props)
  // let isParticle = props.tags.includes('particle');
  // let isExclamation = props.tags.includes('exclamation');
  // let isConjunction = props.tags.includes('conjunction');
  // let isAdverb = props.tags.includes('adverb');
  // let isPredicate = props.tags.includes('predicate');
  // let isDemonstrative = props.tags.includes('demonstrative');
  // let isInterjection = props.tags.includes('interjection');
  // let isPostbase = props.tags.includes('postbase');
  // let isEnclitic = props.tags.includes('enclitic');

  // console.log(props.tags)
  let upperCase = props.tags
  let width = props.width
  let homeMode = props.homeMode
  
  let conversions = {
    "C":"Carcross",
    "T":"Teslin",
    "An":"Angoon",
    "At":"Atlin",
    "RelationalNoun":"Relational Noun",
    "RelationalPostposition":"Relational Postposition",
  }

  if (upperCase in conversions) {
    upperCase = conversions[upperCase]
  }

  // console.log(upperCase)
  upperCase = upperCase.toUpperCase()
  // dialects = {
  // "(C)":["C",], # Carcross
  // "(T)":["T",], # Teslin
  // "(An)":["An",], # Angoon
  // "(At)":["At",], # Atlin
  // "(TC)":["T","C"], # Teslin, Carcross
  // "(AtT)":["At","T"], # Atlin, Teslin
  // # "(A)":["",],
  // # "(AnAT)":["",],
  // }



  if (props.tags == 'noun' || props.tags == 'Noun') {
    return <Label size='mini' style={{backgroundColor:'#f6ede4',whiteSpace:'nowrap'}}>{upperCase}</Label>
  } else if (props.tags == 'NOUN' || props.tags == 'NOUN*') {
    return <Label size='mini' style={{backgroundColor:'#f1f1f1',whiteSpace:'nowrap'}}>{upperCase}</Label>
  } else if (props.tags == 'verb stem' || props.tags == 'verb phrase' || props.tags == 'Verb') {
    if (homeMode == 1) {
      return <Label size='mini' style={{backgroundColor:'#e2eeea',whiteSpace:'nowrap'}}>{upperCase+' THEME'}</Label>
    } else {
      return <Label size='mini' style={{backgroundColor:'#e2eeea',whiteSpace:'nowrap'}}>{upperCase}</Label>
    }
  } else if (props.tags == 'C' || props.tags == 'T' || props.tags == 'An' || props.tags == 'At') {
    return <Label basic size='mini' style={{color:'#5f5f5f',whiteSpace:'nowrap'}}>{upperCase}</Label>
  } else {
    return <Label size='mini' style={{backgroundColor:'#f3f3f3',whiteSpace:'nowrap'}}>{upperCase}</Label>
  }



}


// export const WordItem = (props) => {
//   console.log(props.word)
//   let word = props.word;
//   let isNoun = true;
//   // let isVerb = props.tags.includes('verb');
//   // let isParticle = props.tags.includes('particle');
//   // let isExclamation = props.tags.includes('exclamation');
//   // let isConjunction = props.tags.includes('conjunction');
//   // let isAdverb = props.tags.includes('adverb');
//   // let isPredicate = props.tags.includes('predicate');
//   // let isDemonstrative = props.tags.includes('demonstrative');
//   // let isInterjection = props.tags.includes('interjection');


//   // let isPostbase = props.tags.includes('postbase');
//   // let isEnclitic = props.tags.includes('enclitic');

//   // let isExpression = props.tags.includes('expression');
//   // let isCommon = props.tags.includes('grammar');
//   // let isHBC = props.tags.includes('Hooper Bay Chevak');


//   return (
//     <List.Item key={word.keyString}>
//     <Link to={{pathname: '/' + word.url, state: { word: word.keyString, }}}>
//       <List.Content>
//         <List.Header style={{display:'flex',fontSize:'16px',paddingBottom:'4px',fontFamily:"Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"}}>
//           {word.keySplit.map((w,index) => 
//               <span style={{'paddingRight':'3px'}}>
//               {/*{console.log(w)}*/}
//               {this.processStyledText(w[0])}
//               {w[1][0] !== '' ?
//                   <span style={{marginLeft:'3px',fontWeight:'100'}}>{"("+w[1].join(', ')+")"}</span>
//                 :
//                 (index == word.keySplit.length-1 ?
//                   ''
//                   :
//                   ', '
//                 )
//               }
//                 <span style={{ 'marginLeft': '15px'}}>  
//                   {isNoun ? <Label size='mini' style={{backgroundColor:'#7F90B0',color:'white'}}>NOUN</Label> : ''}
//                 </span>
//               </span>
//             )}
//         </List.Header>
//         <List.Description style={{fontSize:'16px',fontWeight:'400'}}>{word.definitionString}</List.Description>
//       </List.Content>
//     </Link>
//     </List.Item>
//   );
// }
