export const categories = {
    "1": {
        "name": "The physical universe",
        "url": "The-physical-universe",
        "children": [
            "1.1",
            "1.3",
            "1.5",
            "1.6"
        ],
        "links": []
    },
    "1.1": {
        "name": "Sky",
        "url": "Sky",
        "parent": [
            "1"
        ],
        "children": [
            "1.1.1",
            "1.1.3",
            "1.2.1",
            "1.2.2"
        ],
        "links": [
            "goos' shú",
            "kagán",
            "kag̲ít",
            "xi.áat",
            "xáats'",
            "k̲ukawjig̲ít"
        ]
    },
    "1.1.1": {
        "name": "Objects in the sky",
        "url": "Objects-in-the-sky",
        "parent": [
            "1",
            "1.1"
        ],
        "children": [],
        "links": [
            "awdlidées",
            "daak uwaxíx",
            "dís",
            "dís x̲'usyee",
            "gis'óok̲",
            "góos'",
            "k'óox dísi",
            "kei uwaxíx",
            "kichx̲.anagaat",
            "lk̲'ayáak'w x̲'us.eetí",
            "xoodzí",
            "yax̲té",
            "yínde wooxeex",
            "g̲agaan",
            "g̲agaan x̲'usyee",
            "k̲utx̲.ayanahá"
        ]
    },
    "1.1.3": {
        "name": "Weather",
        "url": "Weather",
        "parent": [
            "1",
            "1.1"
        ],
        "children": [
            "1.1.3.1",
            "1.1.3.2"
        ],
        "links": [
            "aawag̲éet",
            "awdigaan",
            "ayawditee",
            "chéx̲'i",
            "dleit",
            "dleit kakétsk",
            "dleit g̲éedi",
            "jiwsitaan",
            "kadás'",
            "kaklahéen",
            "kals'éesjaa",
            "kaxwaan",
            "séew",
            "séew daak wusitán",
            "sóow",
            "taakw.eetí",
            "táakw",
            "xeitl l'íkws'i",
            "xeitl l'úkx̲u",
            "yan kaawadlán",
            "yeis",
            "yéi k̲oowatee",
            "g̲agaan kas'úkwx̲u",
            "g̲agaan át x̲'oos uwatsóow",
            "éedaa",
            "k̲oowak'ei",
            "k̲oowat'áa",
            "k̲oowdigwás'",
            "k̲oowligóos'",
            "k̲ugáas'",
            "k̲ugóos'",
            "k̲ukalt'éex' ká",
            "k̲ukawduwayél'",
            "k̲usa.áat'",
            "k̲utaan",
            "k̲utí",
            "k̲uwsi.áat'",
            "k̲uxaak",
            "k̲ées'"
        ]
    },
    "1.1.3.1": {
        "name": "Natural Disaster",
        "url": "Natural-Disaster",
        "parent": [
            "1",
            "1.1",
            "1.1.3"
        ],
        "children": [],
        "links": [
            "aan galak̲ú",
            "dleit k̲aadí",
            "tak̲aadí",
            "yoo aan ka.á"
        ]
    },
    "1.1.3.2": {
        "name": "Wind",
        "url": "Wind",
        "parent": [
            "1",
            "1.1",
            "1.1.3"
        ],
        "children": [],
        "links": [
            "aadé kawdlis'ées",
            "dákde át",
            "k'eeljáa",
            "k'eiljáa",
            "l'ag̲akáx̲",
            "sáanáx̲",
            "wuduwanúk",
            "xóon",
            "yánde át",
            "át ayawashát",
            "át wulis'ees",
            "óoxjaa"
        ]
    },
    "1.2.1": {
        "name": "Land",
        "url": "Land",
        "parent": [
            "1",
            "1.1"
        ],
        "children": [],
        "links": [
            "a góon",
            "a litká",
            "aan",
            "aasgutú",
            "at gutú",
            "ch'éix̲'w",
            "dei",
            "eech",
            "gooch",
            "gootl",
            "gáan",
            "katk̲aak̲ú",
            "katóok",
            "l'éiw",
            "l'éiw x'aayí",
            "l'éiw yátx'i",
            "l'éx̲'kw",
            "lingit'aaní",
            "lingít aaní",
            "neech",
            "noow",
            "s'é",
            "shaa",
            "shaa seiyí",
            "shaa shakée",
            "shaa yadaa",
            "shaanáx̲",
            "shaltláax̲",
            "séet",
            "sít'",
            "taashuká",
            "tatóok",
            "tax'aayí",
            "tl'átk",
            "tleiyán",
            "táay",
            "té",
            "té k̲'áatl'",
            "té k̲áas'",
            "x'aa",
            "x'aa luká",
            "x'áat'",
            "xákw",
            "yanax̲",
            "yanshuká",
            "yán",
            "g̲alg̲aak̲u",
            "g̲íl'",
            "éek̲",
            "k̲aa x̲'oos deiyí",
            "k̲útl'kw"
        ]
    },
    "1.2.2": {
        "name": "Matter",
        "url": "Matter",
        "parent": [
            "1",
            "1.1"
        ],
        "children": [],
        "links": [
            "dáanaa",
            "eek̲",
            "eek̲ háatl'i",
            "géxtl'",
            "ik̲náach'",
            "ik̲yéis'",
            "k'óox̲'",
            "k'óox̲' létl'k",
            "katl'áak'",
            "katl'áak'",
            "káts",
            "néex̲'",
            "s'oow",
            "t'ooch'",
            "t'ooch' eex̲í",
            "t'ooch' té",
            "át",
            "g̲ayéis'",
            "éech'",
            "ín",
            "x̲éel"
        ]
    },
    "1.3": {
        "name": "Water",
        "url": "Water",
        "parent": [
            "1"
        ],
        "children": [
            "1.3.1",
            "1.3.2",
            "1.3.4"
        ],
        "links": [
            "héen",
            "héen yík",
            "héen x̲'aká",
            "l'óox̲",
            "si.áat'i héen",
            "t'éex'",
            "teet x̲'achálx̲i",
            "x̲'asúnjaa",
            "x̲eel",
            "x̲eil"
        ]
    },
    "1.3.1": {
        "name": "Bodies of water",
        "url": "Bodies-of-water",
        "parent": [
            "1",
            "1.3"
        ],
        "children": [],
        "links": [
            "Tax̲héeni",
            "a wát",
            "déili",
            "eey",
            "goon",
            "hinshú",
            "héen",
            "héen sháak",
            "héen shú",
            "héen wantú",
            "héen wát",
            "héen x̲'ayaax̲",
            "héenák'w",
            "kanaadaayi héen",
            "kunag̲eey",
            "naadaayi héen",
            "sháchk",
            "t'aay",
            "x'áas",
            "áa",
            "áak'w",
            "g̲eey",
            "g̲eey tá",
            "g̲eiy",
            "éil'",
            "éix̲'",
            "ísh",
            "x̲áat héeni"
        ]
    },
    "1.3.2": {
        "name": "Movement of water",
        "url": "Movement-of-water",
        "parent": [
            "1",
            "1.3"
        ],
        "children": [],
        "links": [
            "a kanax̲ jiyawsitán",
            "anax̲ yaawadáa",
            "daak̲ uwadáa",
            "haat",
            "haat kool",
            "héen kanadaayí",
            "kaawadaa",
            "kalóox̲jaa",
            "katl'úk̲jaa",
            "kawlikoox",
            "kawlix'áas",
            "kax'áasjaa",
            "kei uwadáa",
            "kúk̲dlaa",
            "kúk̲jaa",
            "léin",
            "teet",
            "wuliláa",
            "x'óol'",
            "x'úkjaa",
            "yan jiwsitán",
            "yan uwaláa",
            "át kaawadáa",
            "át uwadáa",
            "áx̲ kaawadaa",
            "áx̲ woolaa",
            "éek̲ lukak̲ées'i",
            "k̲ées' shuwee"
        ]
    },
    "1.3.4": {
        "name": "Be in water",
        "url": "Be-in-water",
        "parent": [
            "1",
            "1.3"
        ],
        "children": [],
        "links": [
            "akawlikél",
            "ashawsiyaa",
            "daak wulihásh",
            "eech kakwéiyi",
            "eech kwéiyi",
            "hintu.eejí",
            "héen táak",
            "héeni",
            "héenx̲",
            "wootáax̲'w",
            "wuditl'ák'",
            "xáatl",
            "yan uwahóo",
            "át jiwsikwaan",
            "át uwax'ák",
            "át wulihaash",
            "át wulihásh"
        ]
    },
    "1.5": {
        "name": "Plant",
        "url": "Plant",
        "parent": [
            "1"
        ],
        "children": [
            "1.5.1",
            "1.5.2",
            "1.5.3",
            "1.5.4",
            "1.5.5",
            "1.5.6",
            "1.5.7"
        ],
        "links": [
            "aankanáagu",
            "daaw",
            "geesh",
            "hintaak x'óosi",
            "hintakx'úxi",
            "k'uwaaní",
            "k'wálx̲",
            "kanták̲w",
            "kayaaní",
            "kag̲akl'eedí",
            "laak̲'ásk",
            "lóol",
            "s'aach",
            "s'ikshaldéen",
            "s'áxt'",
            "s'íksh",
            "suk̲téitl'",
            "sú",
            "t'óok'",
            "taan x̲'adaadzaayí",
            "tayeidí",
            "tl'aak̲'wách'",
            "tsáats",
            "yaana.eit",
            "yéil kawóodi",
            "k̲'áach'",
            "x̲'áal'"
        ]
    },
    "1.5.1": {
        "name": "Tree",
        "url": "Tree",
        "parent": [
            "1",
            "1.5"
        ],
        "children": [],
        "links": [
            "a daayí",
            "a shís'k̲",
            "aas",
            "aas jiseiyí",
            "aas yádi",
            "aasdaax'ées'i",
            "at daayí",
            "ch'áal'",
            "dúk̲",
            "dúk̲l'",
            "gus'k'ik̲wáan l'oowú",
            "kalchaneit",
            "kayeix̲",
            "keishísh",
            "l'ákwti",
            "laax̲",
            "leiyís",
            "lingít k'únts'i",
            "nalháashadi",
            "s'agwáat",
            "s'éx̲",
            "sheey",
            "sháchk ka.aasí",
            "shéiyi",
            "shéix̲'w",
            "túk̲l'",
            "x'aalx'éi",
            "yán",
            "g̲unanaa tetl",
            "g̲éechadi",
            "g̲éejadi",
            "x̲áay"
        ]
    },
    "1.5.2": {
        "name": "Bush, shrub",
        "url": "Bush-shrub",
        "parent": [
            "1",
            "1.5"
        ],
        "children": [],
        "links": [
            "k'éets'an",
            "tléik̲w wás'i",
            "wás'"
        ]
    },
    "1.5.3": {
        "name": "Grass, herb, vine",
        "url": "Grass-herb-vine",
        "parent": [
            "1",
            "1.5"
        ],
        "children": [],
        "links": [
            "chookán",
            "né",
            "sháak",
            "sháchk kax̲'wáal'i"
        ]
    },
    "1.5.4": {
        "name": "Moss, fungus, algae",
        "url": "Moss-fungus-algae",
        "parent": [
            "1",
            "1.5"
        ],
        "children": [],
        "links": [
            "aasdaag̲áadli",
            "káas'",
            "s'éix̲wani",
            "s'íx'g̲aa",
            "sook̲",
            "tlaax̲",
            "wuditláx̲",
            "x̲aatl'"
        ]
    },
    "1.5.5": {
        "name": "Parts of a plant",
        "url": "Parts-of-a-plant",
        "parent": [
            "1",
            "1.5"
        ],
        "children": [],
        "links": [
            "a kadíx'i",
            "a káx̲i",
            "a láx̲'i",
            "a shaadí",
            "a t'áni",
            "a tukayátx'i",
            "a x'aakeidí",
            "cháash",
            "gán láx̲'i",
            "haaw",
            "k'eit",
            "k'inchéiyi",
            "k'inchéiyi tléig̲u",
            "kadlix̲'át'",
            "kadánjaa",
            "kayeix̲tág̲u",
            "kax̲'át'",
            "kóox",
            "l'eiwú",
            "l'oowú",
            "loon",
            "léet'",
            "s'ig̲eek̲áawu tléig̲u",
            "s'óos'ani",
            "shaak̲",
            "sheey",
            "sheey kak̲áas'i",
            "sheey tukagoodlí",
            "sheey woolí",
            "sháchgi tléig̲u",
            "sáx'",
            "tag̲anís",
            "teey woodí",
            "tl'áxch'",
            "tlag̲anís",
            "tléik̲w",
            "téil",
            "tínx",
            "x'éedadi",
            "yanxoon",
            "g̲ítg̲aa",
            "k̲'eikaxwéin",
            "k̲áas'",
            "x̲'oon",
            "x̲aat",
            "x̲áaw"
        ]
    },
    "1.5.6": {
        "name": "Growth of plants",
        "url": "Growth-of-plants",
        "parent": [
            "1",
            "1.5"
        ],
        "children": [],
        "links": [
            "kaawa.aa",
            "kawsi.aa"
        ]
    },
    "1.5.7": {
        "name": "Plant diseases",
        "url": "Plant-diseases",
        "parent": [
            "1",
            "1.5"
        ],
        "children": [],
        "links": [
            "gúnl'",
            "láax̲",
            "naak̲w",
            "k̲úlk"
        ]
    },
    "1.6": {
        "name": "Animal",
        "url": "Animal",
        "parent": [
            "1"
        ],
        "children": [
            "1.6.1",
            "1.6.2",
            "1.6.3",
            "1.6.4",
            "1.6.5",
            "1.6.7"
        ],
        "links": []
    },
    "1.6.1": {
        "name": "Types of animals",
        "url": "Types-of-animals",
        "parent": [
            "1",
            "1.6"
        ],
        "children": [
            "1.6.1.1",
            "1.6.1.2",
            "1.6.1.3",
            "1.6.1.4",
            "1.6.1.5",
            "1.6.1.6",
            "1.6.1.7",
            "1.6.1.9"
        ],
        "links": []
    },
    "1.6.1.1": {
        "name": "Mammal",
        "url": "Mammal",
        "parent": [
            "1",
            "1.6",
            "1.6.1"
        ],
        "children": [],
        "links": [
            "at gutu.ádi",
            "cheech",
            "dzísk'w",
            "dáa",
            "dóosh",
            "dóosh yádi",
            "gawdáan",
            "gawdáan yádi",
            "gishoo",
            "gukkudayáat'",
            "haadaadóoshi",
            "haadaag̲oojí",
            "hintaak xóodzi",
            "jánwu",
            "k'óox",
            "kals'áak",
            "kanals'áak",
            "kag̲áak",
            "kag̲ák",
            "keitl",
            "kooléix̲'waa",
            "kuts'een",
            "kéet",
            "kóoshdaa",
            "lukshiyáan",
            "naag̲as'éi",
            "nukshiyáan",
            "nóosk",
            "nóoskw",
            "s'aax̲",
            "s'eek",
            "s'igeidí",
            "s'uk̲kasdúk",
            "shak'únts'",
            "shalas'áaw",
            "shals'áaw",
            "shataag̲áa",
            "sít' tuxóodzi",
            "taan",
            "tawéi",
            "tsaa",
            "tsálk",
            "tsín",
            "wanadóo",
            "wanadóo yádi",
            "wasóos",
            "watsíx",
            "xaas",
            "xóots",
            "yagootl",
            "yáay",
            "yáxwch'",
            "yúxch'",
            "g̲aak̲",
            "g̲ooch",
            "g̲uwakaan",
            "g̲uwakaan yádi",
            "g̲áx̲",
            "k̲'aan",
            "k̲uwakaan",
            "x̲'óon",
            "x̲alak'ách'",
            "x̲aldleit",
            "x̲alt'ooch' naag̲as'éi"
        ]
    },
    "1.6.1.2": {
        "name": "Bird",
        "url": "Bird",
        "parent": [
            "1",
            "1.6",
            "1.6.1"
        ],
        "children": [],
        "links": [
            "Xeitl",
            "asx'aan sháach'i",
            "ayaheeyáa",
            "ch'ak'yéis'",
            "ch'eet",
            "ch'áak'",
            "cháax̲",
            "dagatgiyáa",
            "digitgiyáa",
            "dzísk'w",
            "dóol",
            "gandaadagóogu",
            "gijook",
            "gus'yadóoli",
            "gus'yé kindachooneidí",
            "gáaxw",
            "gúk̲l'",
            "hintakx'wás'g̲i",
            "hinyikgáaxu",
            "hinyikl'eix̲í",
            "k'wát'",
            "k'ákw",
            "kag̲eet",
            "kichyát",
            "kijook",
            "kindachooneit",
            "kootl'éit'aa",
            "káax'",
            "kéel",
            "kéidladi",
            "kóon",
            "lak'eech'wú",
            "lawúx̲",
            "lugán",
            "láx̲'",
            "núkt",
            "s'áas'",
            "s'élasheesh",
            "s'ús'",
            "shaayáal",
            "shoox̲'",
            "séew kooshdaneit",
            "t'aawák̲",
            "tlagu ts'ats'éeyee",
            "tlax̲aneis'",
            "ts'ats'ée",
            "ts'axweil",
            "ts'eeg̲éeni",
            "ts'eig̲éeni",
            "ts'ítskw",
            "tsísk'w",
            "wak̲kals'oox̲' gáaxw",
            "woolnáx̲ wooshk̲ák̲",
            "x'at'daayéejayi",
            "yaa k̲udzigéiyi ts'ats'ée",
            "yaa.aanuné",
            "yook̲",
            "yéil",
            "g̲anook",
            "k̲'eik̲'w",
            "k̲aatoowú",
            "k̲aax̲",
            "k̲ín",
            "x̲'al'daayéeji",
            "x̲'eis'awáa",
            "x̲'éishx'w",
            "x̲éex̲",
            "x̲ík"
        ]
    },
    "1.6.1.3": {
        "name": "Reptile",
        "url": "Reptile",
        "parent": [
            "1",
            "1.6",
            "1.6.1"
        ],
        "children": [],
        "links": [
            "kanóox'",
            "l'ut'tláak̲",
            "tadanóox'",
            "tseenx̲'é"
        ]
    },
    "1.6.1.4": {
        "name": "Amphibian",
        "url": "Amphibian",
        "parent": [
            "1",
            "1.6",
            "1.6.1"
        ],
        "children": [],
        "links": [
            "dúsh",
            "xíxch'"
        ]
    },
    "1.6.1.5": {
        "name": "Fish",
        "url": "Fish",
        "parent": [
            "1",
            "1.6",
            "1.6.1"
        ],
        "children": [],
        "links": [
            "chudéi",
            "cháas'",
            "cháatl",
            "daleiyí",
            "dzánti",
            "dákdesak'aak",
            "ishk̲een",
            "k'aagán",
            "l'ook",
            "lit.isdúk",
            "léik̲'w",
            "lóot'",
            "náak̲w",
            "nóosh",
            "s'áax̲'",
            "saak",
            "sháach'",
            "t'á",
            "t'ási",
            "tl'éitl'",
            "tlóox̲",
            "took̲",
            "té tayee tlóox̲u",
            "téel'",
            "wankashxéet",
            "wéix̲'",
            "x'éitaa",
            "xein",
            "yaa",
            "yaaw",
            "g̲aat",
            "g̲eey kanax̲ k̲utées'",
            "éetkatlóox̲u",
            "x̲'wáat'",
            "x̲'áakw",
            "x̲'áax'w",
            "x̲áat",
            "x̲áat yádi"
        ]
    },
    "1.6.1.6": {
        "name": "Shark, ray",
        "url": "Shark-ray",
        "parent": [
            "1",
            "1.6",
            "1.6.1"
        ],
        "children": [],
        "links": [
            "ch'éetg̲aa",
            "chichuyaa",
            "shax̲dák̲w",
            "tóos'",
            "x'átgu"
        ]
    },
    "1.6.1.7": {
        "name": "Insect",
        "url": "Insect",
        "parent": [
            "1",
            "1.6",
            "1.6.1"
        ],
        "children": [],
        "links": [
            "asgutuyiksháa",
            "ash wusitáax'",
            "atxaayí",
            "atx̲a át",
            "gandaas'aají",
            "gantutl'úk'x̲u",
            "k'ul'kaskéxkw",
            "kanas.aadí",
            "kook'énaa",
            "neil yee táax'ayi",
            "tl'úk'x̲",
            "táax'aa",
            "táax'aa x̲'uskudayáat'",
            "táax̲'",
            "tíx",
            "wanatíx",
            "wanatóox",
            "woon",
            "wéis'",
            "xeitl táax'aa",
            "xéen",
            "k̲aashashx̲áaw"
        ]
    },
    "1.6.1.9": {
        "name": "Sea creatures",
        "url": "Sea-creatures",
        "parent": [
            "1",
            "1.6",
            "1.6.1"
        ],
        "children": [],
        "links": [
            "dag̲asaa",
            "dzéex'w",
            "dzóox'",
            "gunx̲aa",
            "gáal'",
            "koow",
            "nées'",
            "s'igeek̲áawu yaagí",
            "s'ook",
            "s'áaw",
            "s'áx",
            "s'éex'át",
            "shaaw",
            "taakw aanási",
            "tayashagoo",
            "tayataayí",
            "tl'ildaaskeit",
            "ts'ésx̲'w",
            "x'éix̲",
            "yaak",
            "yalooleit",
            "yées'",
            "yéesh",
            "yéil ts'áaxu",
            "yéin",
            "k̲'alkátsk",
            "x̲'waash",
            "x̲éet'"
        ]
    },
    "1.6.2": {
        "name": "Parts of an animal",
        "url": "Parts-of-an-animal",
        "parent": [
            "1",
            "1.6"
        ],
        "children": [],
        "links": [
            "a doogú",
            "a dáali",
            "a geení",
            "a gooshí",
            "a gwéinli",
            "a jeig̲í",
            "a jíni",
            "a k'ishataag̲aní",
            "a k'únts'i",
            "a kageidí",
            "a kajeig̲í",
            "a koowú",
            "a kíji",
            "a l'eedí",
            "a loowú",
            "a oox̲ú",
            "a s'ak̲shutúk̲l'i",
            "a s'ak̲tu.eex̲í",
            "a s'ak̲x̲'áak túk̲l'i",
            "a sheidí",
            "a shá",
            "a tl'eeg̲í",
            "a tl'eig̲í",
            "a ts'éek'u",
            "a tási",
            "a túk̲l'i",
            "a x'éix'u",
            "a xáas'i",
            "a yik.ádi",
            "a g̲ádzi",
            "a k̲'anoox̲ú",
            "a óot'i",
            "a x̲'oosí",
            "a x̲'us.eetí",
            "a x̲'é",
            "a x̲'úx̲u",
            "a x̲aagú",
            "a x̲aawú",
            "a x̲íji",
            "at ts'ík'wti",
            "ch'áak' loowú",
            "du yoowú",
            "du x̲'adaadzaayí",
            "s'ak̲tu.eex̲í",
            "séik̲'w",
            "t'aaw",
            "toow",
            "yáay x̲'axéni",
            "átl'áni",
            "k̲énaa",
            "k̲ínaa",
            "x̲'wáal'",
            "x̲áat k'áax̲'i",
            "x̲éel'"
        ]
    },
    "1.6.3": {
        "name": "Animal life cycle",
        "url": "Animal-life-cycle",
        "parent": [
            "1",
            "1.6"
        ],
        "children": [],
        "links": [
            "a tl'éili",
            "awdlik'wát'",
            "awsix̲eit",
            "has wudzix̲eit",
            "nóox'",
            "x̲'íx'"
        ]
    },
    "1.6.4": {
        "name": "Animal actions",
        "url": "Animal-actions",
        "parent": [
            "1",
            "1.6"
        ],
        "children": [],
        "links": [
            "a óoxu",
            "aawayeek̲",
            "át has wusikwaan",
            "át yawsigóo"
        ]
    },
    "1.6.5": {
        "name": "Animal homes",
        "url": "Animal-homes",
        "parent": [
            "1",
            "1.6"
        ],
        "children": [],
        "links": [
            "a kúdi",
            "a g̲eiwú",
            "a k̲oowú",
            "gandaas'aají kúdi",
            "kút",
            "nóox'",
            "s'igeidí áayi",
            "s'igeidí x̲aayí",
            "t'áx'x̲i",
            "xáak"
        ]
    },
    "1.6.7": {
        "name": "Male and female animals",
        "url": "Male-and-female-animals",
        "parent": [
            "1",
            "1.6"
        ],
        "children": [],
        "links": [
            "sheech",
            "shich"
        ]
    },
    "2": {
        "name": "Person",
        "url": "Person",
        "children": [
            "2.1",
            "2.2",
            "2.3",
            "2.4",
            "2.5",
            "2.6",
            "2.7"
        ],
        "links": [
            "a k̲wáan",
            "aantk̲eení",
            "at danáayi",
            "goox̲",
            "kuhaankée",
            "lingít",
            "náaw s'aatí",
            "náaw éesh",
            "sh yáa awudanéiyi",
            "tlagu k̲wáanx'i",
            "k̲u.oo"
        ]
    },
    "2.1": {
        "name": "Types of people",
        "url": "Types-of-people",
        "parent": [
            "2"
        ],
        "children": [],
        "links": [
            "Ana.óot",
            "Anóoshi",
            "Cháanwaan",
            "Deikeenaa",
            "Ginjichwáan",
            "Ginjoochwáan",
            "Giyak̲w",
            "Gus'k'iyee k̲wáan",
            "Gutéix̲'",
            "Ik̲kaa",
            "Lingít",
            "T'aawyáat",
            "Ts'ootsxán",
            "Waashdan K̲wáan",
            "dleit k̲áa",
            "t'ooch' k̲áa",
            "G̲unanaa",
            "X̲'atas'aak̲",
            "k̲usax̲ak̲wáan"
        ]
    },
    "2.2": {
        "name": "Body",
        "url": "Body",
        "parent": [
            "2"
        ],
        "children": [
            "2.2.1",
            "2.2.2",
            "2.2.3",
            "2.2.4",
            "2.2.5",
            "2.2.6",
            "2.2.8"
        ],
        "links": [
            "du daa.it",
            "du daadleeyí",
            "du daashagóon",
            "du yahaayí"
        ]
    },
    "2.2.1": {
        "name": "Head",
        "url": "Head",
        "parent": [
            "2",
            "2.2"
        ],
        "children": [],
        "links": [
            "a lututúk̲l'i",
            "du gúk",
            "du k'ik̲l'án",
            "du káak'",
            "du l'óot'",
            "du lak'eech'kóog̲u",
            "du lak'éech'",
            "du laká",
            "du leitóox̲",
            "du lidíx̲'",
            "du ludíx̲'",
            "du lugóoch'",
            "du lut'aak̲",
            "du lutú",
            "du lú",
            "du oox̲",
            "du oox̲k'i.eetí",
            "du s'ee",
            "du s'ei",
            "du shadaadoogú",
            "du shaláx̲'",
            "du shá",
            "du téey",
            "du ux̲k'idleeyí",
            "du waak̲",
            "du washká",
            "du washtú",
            "du wak̲kadoogú",
            "du wak̲latáak",
            "du wak̲ltáak",
            "du wak̲shú",
            "du wax̲'ax̲éix̲'u",
            "du wásh",
            "du yat'ákw",
            "du yá",
            "du x̲'adaa",
            "du x̲'adaadzaayí",
            "du x̲'as'guwéis'i",
            "du x̲'astus'aag̲í",
            "du x̲'ás'",
            "du x̲'é"
        ]
    },
    "2.2.2": {
        "name": "Torso",
        "url": "Torso",
        "parent": [
            "2",
            "2.2"
        ],
        "children": [],
        "links": [
            "du díx̲'",
            "du góos",
            "du k'wát'",
            "du k'óol'",
            "du kasán",
            "du kool",
            "du l'aa",
            "du l'íli",
            "du láaw",
            "du s'óog̲u",
            "du tuk̲.woolí",
            "du tux̲'ax'aayí",
            "du tóok̲",
            "du woowká",
            "du wóow",
            "du x'aash",
            "du xeitká",
            "du yoowá",
            "du yoowú",
            "du g̲atsx̲'áak",
            "du k̲aatl",
            "du k̲atlyá",
            "du óox'u",
            "du x̲'óol'"
        ]
    },
    "2.2.3": {
        "name": "Arm, leg",
        "url": "Arm-leg",
        "parent": [
            "2",
            "2.2"
        ],
        "children": [],
        "links": [
            "du ch'éex̲'i",
            "du goosh",
            "du ik̲ká",
            "du jigúnl'i",
            "du jiklix'ées'",
            "du jiká",
            "du jikóol",
            "du jintakyádi",
            "du jintáak",
            "du jintú",
            "du jiwán",
            "du jig̲ei",
            "du jín",
            "du k'í",
            "du keey",
            "du keey shakanóox'u",
            "du kiyshakanóox'u",
            "du kiyshá",
            "du laayig̲águ",
            "du saayee",
            "du shutóox̲'",
            "du t'eey",
            "du tl'eek̲",
            "du tl'eik̲",
            "du tl'ek̲shá",
            "du tl'ek̲tlein",
            "du tl'ek̲x̲'áak",
            "du tl'ik̲shá",
            "du tl'ik̲tlein",
            "du wankach'eek̲",
            "du xées'",
            "du g̲ushká",
            "du g̲áts",
            "du éenee",
            "du éenee x̲aawú",
            "du x̲'eitákw",
            "du x̲'oos",
            "du x̲'us.eetí",
            "du x̲'usgoosh",
            "du x̲'ust'ákl'i",
            "du x̲'ustl'eek̲",
            "du x̲'ustl'eik̲",
            "du x̲'ustáak",
            "du x̲'usyee",
            "du x̲aakw",
            "du x̲aakw eetí",
            "du x̲eek",
            "kei jiwlitsák̲"
        ]
    },
    "2.2.4": {
        "name": "Skin",
        "url": "Skin",
        "parent": [
            "2",
            "2.2"
        ],
        "children": [],
        "links": [
            "a daaleilí",
            "du ch'áatwu",
            "du dook"
        ]
    },
    "2.2.5": {
        "name": "Hair",
        "url": "Hair",
        "parent": [
            "2",
            "2.2"
        ],
        "children": [],
        "links": [
            "daadzix̲áaw",
            "du kak'x̲aawú",
            "du shakakóoch'i",
            "du shashaaní",
            "du shax̲aawú",
            "du x'ées'i",
            "du xux̲aawú",
            "du x̲aawú",
            "shakéil'",
            "shax̲'wáas'"
        ]
    },
    "2.2.6": {
        "name": "Bone, joint",
        "url": "Bone-joint",
        "parent": [
            "2",
            "2.2"
        ],
        "children": [],
        "links": [
            "du daa.eit x̲'áak",
            "du t'iyshú",
            "du xaagú",
            "du k̲áash",
            "du x̲ikshá",
            "s'aak̲"
        ]
    },
    "2.2.8": {
        "name": "Internal organs",
        "url": "Internal-organs",
        "parent": [
            "2",
            "2.2"
        ],
        "children": [],
        "links": [
            "du daa.itwéis'i",
            "du kalóox'shani",
            "du keigú",
            "du leikachóox̲'u",
            "du naasí",
            "du teiyí",
            "du tl'óog̲u",
            "du tlag̲eiyí",
            "du téix̲'",
            "shé",
            "téet'"
        ]
    },
    "2.3": {
        "name": "Body functions",
        "url": "Body-functions",
        "parent": [
            "2"
        ],
        "children": [],
        "links": [
            "akaawanóot'",
            "at kaawanúts",
            "daséikw",
            "du gukyikk'óox̲'u",
            "du lóox'u",
            "du wax̲'ahéeni",
            "du x̲'ahéeni",
            "du x̲'óol' kastóox̲'",
            "du x̲'óol' kawditóox̲'",
            "gáan",
            "gánde nagoodí",
            "has g̲ax̲wusitee",
            "háatl'",
            "kax̲'ees",
            "kóoch'",
            "lug̲eitl'",
            "wak̲dlóok̲",
            "woog̲aax̲",
            "yóot k̲'awdzitúx̲",
            "g̲aax̲",
            "g̲wáal'",
            "x̲'astóox̲",
            "x̲'aséikw"
        ]
    },
    "2.4": {
        "name": "The senses",
        "url": "The-senses",
        "parent": [
            "2"
        ],
        "children": [
            "2.4.1",
            "2.4.2",
            "2.4.3",
            "2.4.4",
            "2.4.5"
        ],
        "links": []
    },
    "2.4.1": {
        "name": "See",
        "url": "See",
        "parent": [
            "2",
            "2.4"
        ],
        "children": [],
        "links": [
            "aadé awdlig̲ein",
            "awlitín",
            "awsiteen",
            "ayatéen",
            "du wak̲kas'óox'",
            "du wak̲ká",
            "du wak̲shiyee",
            "kawlitées'shán",
            "tlél gooháa",
            "tlél k̲ooshtéen",
            "wak̲dáanaa",
            "wak̲kadóox'",
            "át awdlig̲ein",
            "k̲uwatéen / k̲uyatéen"
        ]
    },
    "2.4.2": {
        "name": "Hear",
        "url": "Hear",
        "parent": [
            "2",
            "2.4"
        ],
        "children": [],
        "links": [
            "a kayéik",
            "a kayéikg̲aa k̲oowdzi.aax̲",
            "a yayík",
            "aawa.áx̲",
            "asaya.áx̲ch",
            "aseiwa.áx̲",
            "aya.áx̲ch",
            "ax̲'eiwa.áx̲",
            "ax̲'aya.áx̲ch",
            "du x̲'éide k̲uwdzi.aax̲",
            "du x̲'éit wusi.áx̲",
            "lagaaw",
            "sawligaaw",
            "sh wudigwál",
            "át wusi.áx̲",
            "k̲u.áx̲ch"
        ]
    },
    "2.4.3": {
        "name": "Taste",
        "url": "Taste",
        "parent": [
            "2",
            "2.4"
        ],
        "children": [],
        "links": [
            "(k'idéin) wuduwanúk",
            "du x̲'é wook'éi",
            "du x̲'éix̲ woodaa",
            "wulinúkts",
            "wusi.áax'w",
            "x̲'éi awdinúk"
        ]
    },
    "2.4.4": {
        "name": "Smell",
        "url": "Smell",
        "parent": [
            "2",
            "2.4"
        ],
        "children": [],
        "links": [
            "awsiníx' / awdziníx'",
            "wulichán",
            "wulits'áa"
        ]
    },
    "2.4.5": {
        "name": "Sense of touch",
        "url": "Sense-of-touch",
        "parent": [
            "2",
            "2.4"
        ],
        "children": [],
        "links": [
            "jée awdinúk",
            "kawlixwétl",
            "wulix'wás'k̲",
            "yéi sh tuwdinook"
        ]
    },
    "2.5": {
        "name": "Body condition",
        "url": "Body-condition",
        "parent": [
            "2"
        ],
        "children": [],
        "links": [
            "awlixwétl",
            "du éet yaan uwaháa",
            "kaxweitl",
            "latseen",
            "shaawakúx",
            "tlél wultseen",
            "wudixwétl",
            "wudlinétl",
            "wudlisáa",
            "wulitseen",
            "xweitl",
            "yaan"
        ]
    },
    "2.6": {
        "name": "Health",
        "url": "Health",
        "parent": [
            "2"
        ],
        "children": [
            "2.6.1"
        ],
        "links": [
            "Hú!",
            "aawak̲'ék'w",
            "akaawas'ít",
            "awlichún",
            "awlix̲'éx̲'",
            "awsineix̲",
            "daa.ittunéekw",
            "gáal'",
            "háas'",
            "jika.át",
            "jig̲ei.át",
            "júx̲'aa",
            "k'inashóo",
            "kawdlishúk'",
            "kawáat",
            "kwaan",
            "kóoshdaa náagu",
            "l uwaxwachgi néekw",
            "l k̲ool.áx̲ji",
            "l k̲ooshtéeni",
            "lítaa eetí",
            "neis'",
            "náakw",
            "néekw",
            "s'aag̲itunéekw",
            "s'éil'",
            "sh wudik̲'ék'w",
            "t'aay néekw",
            "t'áax̲'w",
            "teel",
            "tl'ook̲",
            "tág̲aa",
            "wooneix̲",
            "woonéekw",
            "wudichún",
            "wudix'ís'",
            "wudlik̲ít'",
            "wuwtunéekw",
            "x'ees",
            "xáatl'ákw",
            "xéesh",
            "yagúnl'",
            "yaneis'í",
            "g̲eitl'",
            "íx̲t'",
            "k̲'éik̲'w",
            "k̲u.áx̲ji",
            "k̲usa.áat' néekw",
            "k̲áakwt uwanéi",
            "k̲éech'",
            "k̲éet'",
            "óonaa eetí",
            "x̲'atl'ook̲"
        ]
    },
    "2.6.1": {
        "name": "Sick",
        "url": "Sick",
        "parent": [
            "2",
            "2.6"
        ],
        "children": [],
        "links": [
            "ash wusinéekw",
            "wudlik̲oo"
        ]
    },
    "2.7": {
        "name": "Life",
        "url": "Life",
        "parent": [
            "2"
        ],
        "children": [
            "2.7.1",
            "2.7.3",
            "2.7.4",
            "2.7.5",
            "2.7.6"
        ],
        "links": [
            "k̲ustí"
        ]
    },
    "2.7.1": {
        "name": "Marriage",
        "url": "Marriage",
        "parent": [
            "2",
            "2.7"
        ],
        "children": [],
        "links": [
            "aawasháa",
            "du shaawádi",
            "du shát",
            "du x̲án aa",
            "du x̲úx̲",
            "l s'aatí shaawát",
            "wooch has wudisháa",
            "wuduwasháa"
        ]
    },
    "2.7.3": {
        "name": "Birth",
        "url": "Birth",
        "parent": [
            "2",
            "2.7"
        ],
        "children": [],
        "links": [
            "du kayádi",
            "du taaní",
            "du taanú",
            "k̲oowdzitee"
        ]
    },
    "2.7.4": {
        "name": "Stage of life",
        "url": "Stage-of-life",
        "parent": [
            "2",
            "2.7"
        ],
        "children": [],
        "links": [
            "adátx'i",
            "atk'átsk'u",
            "atyátx'i",
            "du katáagu",
            "kei uwawát",
            "kík'i aa",
            "shaan",
            "shaan",
            "shaanák'w",
            "t'ukanéiyi",
            "wudishán",
            "yanwáat",
            "yées",
            "yées wáat",
            "yées k̲u.oo",
            "k̲áa shaan"
        ]
    },
    "2.7.5": {
        "name": "Male, female",
        "url": "Male-female",
        "parent": [
            "2",
            "2.7"
        ],
        "children": [],
        "links": [
            "k'isáani",
            "shaatk'",
            "shaatk'iyátsk'u",
            "shaatk'átsk'u",
            "shaawát",
            "shaax'wsáani",
            "sháa",
            "yadak'wátsk'u",
            "yadák'w",
            "k̲áa",
            "k̲áax'w"
        ]
    },
    "2.7.6": {
        "name": "Die",
        "url": "Die",
        "parent": [
            "2",
            "2.7"
        ],
        "children": [],
        "links": [
            "dag̲ank̲ú",
            "du néix'i",
            "naná",
            "té xóow",
            "xóow",
            "k̲aa daakeidí",
            "k̲aanaawú tl'átgi"
        ]
    },
    "3": {
        "name": "Language and thought",
        "url": "Language-and-thought",
        "children": [
            "3.1",
            "3.2",
            "3.3",
            "3.4",
            "3.5",
            "3.6"
        ],
        "links": []
    },
    "3.1": {
        "name": "Soul, spirit",
        "url": "Soul-spirit",
        "parent": [
            "3"
        ],
        "children": [
            "3.1.1"
        ],
        "links": [
            "du toowú",
            "du yahaayí",
            "du yakg̲wahéiyagu",
            "lékwaa",
            "s'ig̲eek̲áawu"
        ]
    },
    "3.1.1": {
        "name": "Personality",
        "url": "Personality",
        "parent": [
            "3",
            "3.1"
        ],
        "children": [],
        "links": [
            "at lux'aak̲áawu",
            "l yaa k̲ooshgé",
            "lookanáa",
            "naa shuklag̲eeyí",
            "oodzikaa",
            "sh kahaadí",
            "tuli.aan",
            "wuli.oos",
            "x'ig̲aak̲áa",
            "ánk'w",
            "k̲'atx̲áan",
            "k̲aa kanax̲k̲áa"
        ]
    },
    "3.2": {
        "name": "Mind",
        "url": "Mind",
        "parent": [
            "3"
        ],
        "children": [
            "3.2.1"
        ],
        "links": [
            "(yéi) tuwditaan",
            "a daa tuwatee",
            "a jiyeet",
            "a kát seiwax'ák̲w",
            "ashoowsitee",
            "at wuskóowu",
            "awshigóok",
            "awsikóo",
            "ax̲'eiwa.áx̲",
            "ax̲'aya.áx̲ch",
            "du daa yaa k̲ushuwsigéi",
            "du tuwáx'",
            "du yáa ayaawanéi / du yáa awuwanéi",
            "du éek' aawaheen",
            "sh tóo at wudlitóow",
            "sh tóo awdlitóow",
            "tundatáan",
            "x'éig̲aa át",
            "yan akaawa.ák̲w",
            "yoo akaawajeek",
            "yoo at koojeek",
            "yoo tutánk",
            "yáa at wooné",
            "átk' aheen",
            "k̲ux̲ tuwdishát"
        ]
    },
    "3.2.1": {
        "name": "Think",
        "url": "Think",
        "parent": [
            "3",
            "3.2"
        ],
        "children": [],
        "links": [
            "a daa yoo (ha)s tuwli.át",
            "a daa yoo toowatán"
        ]
    },
    "3.3": {
        "name": "Want",
        "url": "Want",
        "parent": [
            "3"
        ],
        "children": [],
        "links": [
            "(yéi) asaawahaa",
            "a eetéenáx̲ wootee",
            "aag̲áa aawawóo",
            "akaawadlénx̲aa",
            "at sag̲ahaayí",
            "du tuwáa wsigóo",
            "yéi tuwatee",
            "át awdishée",
            "k̲ukadlénx̲aa"
        ]
    },
    "3.4": {
        "name": "Emotion",
        "url": "Emotion",
        "parent": [
            "3"
        ],
        "children": [],
        "links": [
            "akoolx̲éitl'",
            "at sax̲án",
            "at shook̲",
            "at wooshook̲",
            "awshik'aan",
            "awsix̲án",
            "du toowú kawlig̲éi",
            "du toowú wook'éi",
            "du toowú wsigóo",
            "du tóot wooxeex",
            "has g̲ax̲wusitee",
            "kadéix'",
            "kanashú",
            "kasg̲aax̲",
            "kawlix̲éitl'shán",
            "sagú",
            "sh tukdlig̲éi",
            "sh tóog̲aa wditee",
            "toowú k'é",
            "toowú klag̲é",
            "toowú latseen",
            "toowú néekw",
            "toowú nóok",
            "tula.aan",
            "tuteesh",
            "tután",
            "tuwdi.ús",
            "tux'andaxeech",
            "woog̲aax̲",
            "wuliteesh",
            "x'áan",
            "x'áant uwanúk",
            "yéi sh tuwdinook",
            "áa akawdlix̲éitl'",
            "g̲aax̲",
            "k̲aa toowú lat'aa",
            "k̲ukahín",
            "k̲usax̲án"
        ]
    },
    "3.5": {
        "name": "Communication",
        "url": "Communication",
        "parent": [
            "3"
        ],
        "children": [
            "3.5.4",
            "3.5.5"
        ],
        "links": [
            "a káx̲ akawliník",
            "aadé akaawanáa",
            "aawa.éex'",
            "aawa.éex'",
            "aawax̲oox̲",
            "akaawaneek",
            "akaawashéx̲'",
            "akaawak̲aa",
            "awdzig̲áax̲",
            "ax̲'eiwatee",
            "ax̲'eiwawóos'",
            "du jeedé x̲'awditaan",
            "du jeet x̲'awditán",
            "du kát ashuwatée",
            "du satú",
            "du sé",
            "du éet x̲'eiwatán",
            "du x̲'akooká",
            "du x̲'éix̲ woodaa",
            "kashóok' yoo x̲'atánk",
            "kawdig̲aax̲",
            "kook̲énaa",
            "l yoo k̲'eishtángi",
            "neek",
            "neek s'aatí",
            "neek shatl'ék̲x'u",
            "niks'aatí",
            "sh k̲'awdliyél",
            "yoo (ha)s x̲'awli.át",
            "yoo ayawsik̲aa",
            "yoo x̲'ala.átk",
            "yoo x̲'atánk",
            "yoo x̲'eiwatán",
            "yéi adaayak̲á",
            "yéi yaawak̲aa",
            "áa ajikaawak̲aa",
            "át akaawa.aak̲w",
            "x̲'aan kagéetx' x̲'awdataan",
            "x̲'awditaan"
        ]
    },
    "3.5.4": {
        "name": "Verbal tradition",
        "url": "Verbal-tradition",
        "parent": [
            "3",
            "3.5"
        ],
        "children": [],
        "links": [
            "akawlineek",
            "naa káani",
            "sh kawdlineek",
            "shkalneek",
            "tlaagú",
            "yax̲ at g̲wakú",
            "k̲oon aawatlákw",
            "k̲oon sh kalneegí"
        ]
    },
    "3.5.5": {
        "name": "Reading and writing",
        "url": "Reading-and-writing",
        "parent": [
            "3",
            "3.5"
        ],
        "children": [],
        "links": [
            "aawatóow",
            "akawlidál'",
            "akawshixít",
            "kawjixít",
            "wuditóow"
        ]
    },
    "3.6": {
        "name": "Teach",
        "url": "Teach",
        "parent": [
            "3"
        ],
        "children": [
            "3.6.2"
        ],
        "links": [
            "ashukaawajáa",
            "du wak̲shiyeex' yéi awsinei",
            "du éex' at wulitóow",
            "du éex' awlitóow",
            "du x̲'ayáx̲"
        ]
    },
    "3.6.2": {
        "name": "School",
        "url": "School",
        "parent": [
            "3",
            "3.6"
        ],
        "children": [],
        "links": [
            "akaawatlaakw",
            "at wuskú daakahídi",
            "kooxéedaa",
            "nadáakw",
            "sgóon",
            "sgóonwaan",
            "wooch yáx̲ yaa datóowch",
            "x'úx'",
            "yaa at naskwéini",
            "áx' k̲aa ée at dultóow yé",
            "x̲éey"
        ]
    },
    "4": {
        "name": "Social behavior",
        "url": "Social-behavior",
        "children": [
            "4.1",
            "4.2",
            "4.3",
            "4.5",
            "4.7",
            "4.8",
            "4.9"
        ],
        "links": []
    },
    "4.1": {
        "name": "Relationships",
        "url": "Relationships",
        "parent": [
            "4"
        ],
        "children": [
            "4.1.8",
            "4.1.9"
        ],
        "links": [
            "awsiwát",
            "awsix̲án",
            "ax̲ x̲'éit yawdzi.áa",
            "du kacháwli",
            "du shaatk'í",
            "du tseiyí",
            "du yadák'u",
            "k'idaaká aa",
            "k'idaak̲wáani",
            "naa",
            "k̲usax̲án"
        ]
    },
    "4.1.8": {
        "name": "Show affection",
        "url": "Show-affection",
        "parent": [
            "4",
            "4.1"
        ],
        "children": [],
        "links": [
            "jáa"
        ]
    },
    "4.1.9": {
        "name": "Related by kinship",
        "url": "Related-by-kinship",
        "parent": [
            "4",
            "4.1"
        ],
        "children": [],
        "links": [
            "Chookaneidí",
            "Dak̲l'aweidí",
            "Deisheetaan",
            "Kaagwaantaan",
            "Kiks.ádi",
            "Kwaashk'i K̲wáan",
            "L'eeneidí",
            "L'uknax̲.ádi",
            "Lukaax̲.ádi",
            "Léelk'w!",
            "Shangukeidí",
            "Suk̲teeneidí",
            "T'ak̲deintaan",
            "Teik̲weidí",
            "Tsaagweidí",
            "Was'eeneidí",
            "Yanyeidí",
            "du aat",
            "du chaan",
            "du daakanóox'u",
            "du dachx̲án",
            "du dlaak'",
            "du een aa",
            "du húnx̲w",
            "du kikyádi",
            "du káak",
            "du káalk'w",
            "du káani",
            "du kéek'",
            "du kéilk'",
            "du shaawádi",
            "du shagóon",
            "du shuká",
            "du sháawu",
            "du shát",
            "du shátx̲",
            "du sáni",
            "du sée",
            "du t'aag̲í",
            "du tláa",
            "du tláak'w",
            "du wóo",
            "du xwáayi",
            "du yak̲áawu",
            "du yinaanáx̲",
            "du yádi",
            "du yátx'i",
            "du yéet",
            "du áali",
            "du éek'",
            "du éesh",
            "du k̲áawu",
            "du x̲ooní",
            "du x̲án aa",
            "du x̲úx̲",
            "neechkayádi",
            "G̲aanax̲teidí",
            "K̲aach.ádi"
        ]
    },
    "4.2": {
        "name": "Social activity",
        "url": "Social-activity",
        "parent": [
            "4"
        ],
        "children": [
            "4.2.3",
            "4.2.4",
            "4.2.6",
            "4.2.7"
        ],
        "links": [
            "aantk̲eení",
            "aawa.éex'",
            "du keekán",
            "naa káani",
            "tíxwjaa",
            "túxjaa",
            "woosh kaanáx̲ has wudi.aat",
            "woosh x̲oot has wudi.át",
            "át has yawdiháa",
            "k̲aankak.eetx'",
            "k̲u.éex'"
        ]
    },
    "4.2.3": {
        "name": "Music",
        "url": "Music",
        "parent": [
            "4",
            "4.2"
        ],
        "children": [],
        "links": [
            "aawashee",
            "ali.áx̲ch",
            "at kawlishee",
            "at shéex'i",
            "at shéeyi",
            "at shí",
            "at shí k̲óok",
            "at wooshee",
            "awli.áx̲",
            "du x̲'asheeyí",
            "gaaw",
            "gaaw",
            "shukawlix̲oox̲",
            "shí",
            "g̲íx̲'jaa k̲óok"
        ]
    },
    "4.2.4": {
        "name": "Dance",
        "url": "Dance",
        "parent": [
            "4",
            "4.2"
        ],
        "children": [],
        "links": [
            "aawal'eix̲",
            "al'eix̲",
            "daak aawal'éx̲",
            "shakee.át",
            "g̲unéi aawal'éx̲",
            "k̲aayuka.óot'i x'óow"
        ]
    },
    "4.2.6": {
        "name": "Entertainment, recreation",
        "url": "Entertainment-recreation",
        "parent": [
            "4",
            "4.2"
        ],
        "children": [],
        "links": [
            "ach kooshx̲'íl'aa yeit",
            "aldaawáa",
            "alk̲áa",
            "awdlik̲áa",
            "kooch'éit'aa",
            "kookíts'aa",
            "koot'áax'aa",
            "xát'aa",
            "yakwteiyí"
        ]
    },
    "4.2.7": {
        "name": "Play, fun",
        "url": "Play-fun",
        "parent": [
            "4",
            "4.2"
        ],
        "children": [],
        "links": [
            "ash kawdliyát",
            "ayaawadlaak̲",
            "du éet k̲uwaháa",
            "sée",
            "toolch'án",
            "k̲'awsigóo",
            "k̲'eildaháak'u",
            "k̲us.ook'"
        ]
    },
    "4.3": {
        "name": "Behavior",
        "url": "Behavior",
        "parent": [
            "4"
        ],
        "children": [
            "4.3.4",
            "4.3.9"
        ],
        "links": []
    },
    "4.3.4": {
        "name": "Do good/evil to",
        "url": "Do-good/evil-to",
        "parent": [
            "4",
            "4.3"
        ],
        "children": [],
        "links": [
            "akaawaxíl'",
            "ax̲ éet wudishée",
            "du daat yawsiták̲",
            "du jiyagéix̲",
            "du toowú awlit'áa",
            "kaxéel'",
            "l ushk'é",
            "táaw s'aatí",
            "k̲aa éet has jiwdi.át",
            "k̲oowajag̲i aa",
            "k̲u.eení"
        ]
    },
    "4.3.9": {
        "name": "Culture",
        "url": "Culture",
        "parent": [
            "4",
            "4.3"
        ],
        "children": [],
        "links": [
            "aawahéin",
            "aawahéix̲waa",
            "du néix'i",
            "kootéeyaa",
            "l'ax̲keit",
            "l'ée",
            "leineit shál",
            "lák̲t",
            "naaxein",
            "sankeit",
            "shadakóox̲'",
            "sheishóox̲",
            "tináa",
            "té xóow",
            "x'óow",
            "xóow",
            "yaka.óot' x'óow",
            "yéts' shál",
            "x̲'eint'áax'aa",
            "x̲'uskeit",
            "x̲'éen",
            "x̲aat s'áaxw"
        ]
    },
    "4.5": {
        "name": "Authority",
        "url": "Authority",
        "parent": [
            "4"
        ],
        "children": [
            "4.5.1"
        ],
        "links": []
    },
    "4.5.1": {
        "name": "Person in authority",
        "url": "Person-in-authority",
        "parent": [
            "4",
            "4.5"
        ],
        "children": [],
        "links": [
            "du s'aatí",
            "hít s'aatí",
            "k̲aa s'aatí",
            "k̲aa sháade háni",
            "k̲aa sháade nák̲x'i"
        ]
    },
    "4.7": {
        "name": "Law",
        "url": "Law",
        "parent": [
            "4"
        ],
        "children": [
            "4.7.3",
            "4.7.7"
        ],
        "links": [
            "a káa k̲ududziteeyi yoo x̲'atánk"
        ]
    },
    "4.7.3": {
        "name": "Break the law",
        "url": "Break-the-law",
        "parent": [
            "4",
            "4.7"
        ],
        "children": [],
        "links": [
            "aawatáw",
            "at géit wudzigít"
        ]
    },
    "4.7.7": {
        "name": "Punish",
        "url": "Punish",
        "parent": [
            "4",
            "4.7"
        ],
        "children": [],
        "links": [
            "áa k̲uyadujee yé",
            "g̲ayéis' hít"
        ]
    },
    "4.8": {
        "name": "Strife",
        "url": "Strife",
        "parent": [
            "4"
        ],
        "children": [
            "4.8.3",
            "4.8.4"
        ],
        "links": []
    },
    "4.8.3": {
        "name": "Peace",
        "url": "Peace",
        "parent": [
            "4",
            "4.8"
        ],
        "children": [],
        "links": [
            "awlik'éi",
            "kawduwayél'",
            "kayéil'"
        ]
    },
    "4.8.4": {
        "name": "War",
        "url": "War",
        "parent": [
            "4",
            "4.8"
        ],
        "children": [],
        "links": [
            "aawaják̲",
            "adawóotl",
            "at katé",
            "at tugáni",
            "du yaanaayí",
            "niyaháat",
            "noow",
            "shak'áts'",
            "x'áan kanáayi",
            "x'áan yinaa.át",
            "x'ús'",
            "yinaaháat",
            "g̲alsháatadi",
            "k̲ulagaaw",
            "óonaa",
            "x̲áa",
            "x̲át'aa"
        ]
    },
    "4.9": {
        "name": "Religion",
        "url": "Religion",
        "parent": [
            "4"
        ],
        "children": [],
        "links": [
            "Aank̲áawu",
            "Dikáank̲áawu",
            "Dikée aank̲áawu",
            "Diyée aank̲áawu",
            "at kookeidí",
            "du daakashú",
            "du yakg̲wahéiyagu",
            "du ítx̲ na.aadí",
            "du ítx̲ nagoodí",
            "héent ayaawatée",
            "jinaháa",
            "kanéist",
            "kashéex̲'",
            "kook̲énaa",
            "l ulitoogu K̲aa Yakg̲wahéiyagu",
            "léikwaa",
            "lékwaa",
            "nakwnéit",
            "nakws'aatí",
            "oolxéis'",
            "s'ig̲eek̲áawu",
            "sh kalneegí",
            "sh káa x̲'awdigáx'",
            "wooneix̲",
            "wulix̲éitl",
            "wáadishgaa",
            "yéik",
            "átk' aheen",
            "átk' aheení",
            "g̲aneix̲",
            "íx̲t'",
            "k̲aa at óowu",
            "k̲aa yakg̲wahéiyagu",
            "k̲oon sh kalneegí",
            "x̲'agáax'",
            "x̲'agáax' daakahídi"
        ]
    },
    "5": {
        "name": "Home",
        "url": "Home",
        "children": [
            "5.1",
            "5.2",
            "5.3",
            "5.4",
            "5.5",
            "5.6",
            "5.7"
        ],
        "links": [
            "a daax̲ yaa dulsheech át",
            "chál",
            "du eetí ká",
            "eet",
            "gaan",
            "gaan ká",
            "gáas'",
            "hít",
            "hít kagaadí",
            "hít kaságu",
            "hít kat'áayi",
            "hít ká",
            "hít shantú",
            "kashóok'",
            "neil",
            "s'eik̲ daakahídi",
            "s'ísaa hít",
            "t'aa yátx'i",
            "t'áa ká",
            "t'áa yá",
            "táay kahéix̲i",
            "k̲'anáax̲án",
            "k̲aa at oohéini",
            "x̲'aháat",
            "x̲'awool",
            "x̲'awool kayáashi",
            "x̲aawaag̲éi",
            "x̲aawaag̲í",
            "x̲aay"
        ]
    },
    "5.1": {
        "name": "Rooms of a house",
        "url": "Rooms-of-a-house",
        "parent": [
            "5"
        ],
        "children": [
            "5.1.2",
            "5.1.3",
            "5.1.4"
        ],
        "links": []
    },
    "5.1.2": {
        "name": "Furniture",
        "url": "Furniture",
        "parent": [
            "5",
            "5.1"
        ],
        "children": [],
        "links": [
            "káa x̲ex̲x'u yeit",
            "káayag̲ijeit",
            "nadáakw",
            "g̲eig̲ách'",
            "g̲íx̲'jaa k̲óok"
        ]
    },
    "5.1.3": {
        "name": "Furnishings",
        "url": "Furnishings",
        "parent": [
            "5",
            "5.1"
        ],
        "children": [],
        "links": [
            "a tóo at dult'ix'x̲i át",
            "aan kwéiyi",
            "at xáshdi x'óow",
            "kas'íx'waa",
            "kashóok' tlag̲eiyí",
            "kast'áat' x'óow",
            "stoox",
            "yee.át",
            "áx̲ kaduls'ix'w át",
            "g̲áach",
            "x̲aawaag̲éi kas'ísayi"
        ]
    },
    "5.1.4": {
        "name": "Household tools",
        "url": "Household-tools",
        "parent": [
            "5",
            "5.1"
        ],
        "children": [],
        "links": [
            "a gúgu",
            "a kaháadi",
            "a káa dul.us'ku át",
            "a sákwti",
            "a sáxwdi",
            "a tóonáx kadus'ik̲s'i át",
            "a yana.áat'ani",
            "a yee.ádi",
            "a x̲'adéex'i",
            "aan kwéiyi tugáas'i",
            "aankadushxit át",
            "at daayí k̲ákw",
            "at katáx'aa",
            "at la.át",
            "at shí k̲óok",
            "atx̲á jishagóon",
            "daakeit",
            "daneit",
            "dzaas",
            "dís woox̲éiyi",
            "gaan x̲'aháadi",
            "gaaw",
            "galnáat'ani",
            "gwálaa",
            "gwéil",
            "gúx'aa",
            "héen gúx'aa",
            "jishagóon",
            "jig̲wéinaa",
            "kadu.uxx̲u át",
            "kadulg̲óok s'eenáa",
            "kadulg̲úkx̲ s'eenáa",
            "kadushxit t'aa yá",
            "kadádzaa yeit",
            "kajúxaa",
            "kakatáx'aa",
            "kaltásk",
            "kas'éet kagwádlaa",
            "kas'éet katíx̲'aa",
            "kas'ígwaa yeit (A)",
            "kashéek'w gwéil",
            "kashóok' gwéil",
            "kast'áat'",
            "kat'éx̲'aa",
            "kat'éx̲'aa yeit",
            "kat'íshaa",
            "katíx̲'aa",
            "kaxwénaa",
            "kaxágwaa yeit",
            "kaxées'",
            "kaxíl'aa",
            "kag̲ádaa",
            "kax̲'íl'aa",
            "kax̲gáani yeit",
            "kinguchwáan x'óowu",
            "koojúxaa",
            "koojúxwaa",
            "kooxéedaa",
            "káast",
            "káat'",
            "kát dul.us'ku át",
            "kát yadu.us'ku át",
            "kéi dak̲inji s'áaxw",
            "kéit'u",
            "kélaa",
            "kílaa",
            "l'oowú ták̲l",
            "l'át'aa",
            "l'ée",
            "leineit shál",
            "lukat'íshaa",
            "lug̲wéinaa",
            "lák̲t",
            "lítaa",
            "naa.át kaxít'aa",
            "naasa.áa",
            "néil'",
            "nóox'",
            "oox̲ katság̲aa",
            "s'eenáa",
            "s'eik̲daakeit",
            "s'él'",
            "s'ísaa",
            "s'íx'",
            "s'íx' k̲'áatl'",
            "seig̲atáanaa",
            "shanax̲wáayi",
            "shanax̲wáayi yádi",
            "shayeit",
            "sheen",
            "sheen x̲'ayee",
            "shunax̲wáayi",
            "shál",
            "shéen",
            "t'aawák̲ x'eesháa",
            "t'ooch'ineit",
            "t'ook",
            "t'éesh",
            "teet x̲'achálx̲i",
            "tl'ek̲shanaa.át",
            "tl'ik̲naa.át",
            "toow s'eenáa",
            "ts'anéi",
            "tseeneidi shál",
            "tudaxákw",
            "tunax̲hinnadaa",
            "tuk̲daadaxákw",
            "táal",
            "táax'ál'",
            "tás",
            "té kas'úgwaa yeit",
            "téel daakeyéis'i",
            "tíx'",
            "tíx' yádi",
            "tóonáx̲ k̲aateen",
            "túlaa",
            "ux̲ganhéen",
            "wootsaag̲áa",
            "wéiksh",
            "wóoshnáx̲ x̲'akakéix̲i",
            "wóow daakeit",
            "x'eesháa",
            "x'ádaa",
            "x'úx'",
            "x'úx' daakax'úx'u",
            "xwaasdáa",
            "xwájaa",
            "xáshaa",
            "xát'aa",
            "xén",
            "yayéinaa",
            "yees'",
            "yoo katan lítaa",
            "yáanadi",
            "yéts' shál",
            "ách at dusx̲a át",
            "g̲altulítaa",
            "g̲eeg̲ách'",
            "g̲íl'aa",
            "éenaa",
            "ín x'eesháa",
            "ít'ch",
            "k̲'aakanéi",
            "k̲'ateil",
            "k̲'wátl",
            "k̲aashaxáshaa",
            "k̲utl'ídaa",
            "k̲ákw",
            "k̲óok",
            "x̲'akakeix̲í",
            "x̲'al'daakeit",
            "x̲'ayeit",
            "x̲'wéinaa",
            "x̲'éi shadagutx̲i lítaa",
            "x̲'éigwál'",
            "x̲aanás' éinaa",
            "x̲út'aa"
        ]
    },
    "5.2": {
        "name": "Food",
        "url": "Food",
        "parent": [
            "5"
        ],
        "children": [
            "5.2.1",
            "5.2.2",
            "5.2.3",
            "5.2.4"
        ],
        "links": [
            "atx̲á",
            "atx̲á daakahídi",
            "du x̲'a.eetí",
            "du x̲'ax̲án",
            "du x̲'eis",
            "kat'ákx̲i",
            "laaxw",
            "sakwnéin kax̲'eiltí",
            "sakwnéin éewu",
            "uwaláxw",
            "wóow",
            "g̲áatl",
            "éenwu",
            "k̲aa x̲'a.eetí"
        ]
    },
    "5.2.1": {
        "name": "Food preparation",
        "url": "Food-preparation",
        "parent": [
            "5",
            "5.2"
        ],
        "children": [],
        "links": [
            "akawlis'úk",
            "akawshix̲'ál'",
            "at wusi.ée",
            "atx'aan hídi",
            "awli.úk",
            "awlitsík",
            "awsi.ée",
            "awsit'áa",
            "awsitáa",
            "awsixúk",
            "jikak̲áas'",
            "kaat",
            "kadútlx̲i",
            "s'óos'",
            "sakwnéin",
            "si.áax'u át",
            "tl'éek'at",
            "tséek",
            "tux̲'wáns'i náakw",
            "té k̲'áatl'",
            "wudli.úk",
            "yaash ká",
            "yan akawligáa",
            "áx̲ akawlis'eik̲",
            "g̲agaan kas'úkwx̲u",
            "éil'",
            "éil' kahéeni"
        ]
    },
    "5.2.2": {
        "name": "Eat",
        "url": "Eat",
        "parent": [
            "5",
            "5.2"
        ],
        "children": [],
        "links": [
            "aawax̲áa",
            "at uwax̲áa",
            "du x̲'é wook'éi",
            "du x̲'éix̲ aawatee",
            "du x̲'éix̲ at wootee",
            "yax̲ ayawsix̲áa"
        ]
    },
    "5.2.3": {
        "name": "Types of food",
        "url": "Types-of-food",
        "parent": [
            "5",
            "5.2"
        ],
        "children": [
            "5.2.3.1",
            "5.2.3.2",
            "5.2.3.4",
            "5.2.3.6",
            "5.2.3.7"
        ],
        "links": []
    },
    "5.2.3.1": {
        "name": "Food from plants",
        "url": "Food-from-plants",
        "parent": [
            "5",
            "5.2",
            "5.2.3"
        ],
        "children": [],
        "links": [
            "anahoo",
            "ch'eex̲'",
            "dleit tléig̲u",
            "dáxw",
            "gaawák̲",
            "k'eit",
            "k'wálx̲",
            "k'únts'",
            "kaawaxoogu tléik̲w",
            "kadooheix̲.aa",
            "kadux̲'álx̲.aa",
            "kalchaneit tléig̲u",
            "kanat'á",
            "kanat'á kahéeni",
            "kaneilts'ákw",
            "kaneilts'íkw",
            "kaxwéix̲",
            "kóox",
            "laak̲'ásk",
            "lingít shákw",
            "lingít x'áax'i",
            "láx̲' loowú",
            "naanyaa kanat'aayí",
            "neigóon",
            "néx̲'w",
            "s'ín",
            "shaax̲",
            "shákw",
            "tl'aadéin.aa",
            "tl'aak̲'wách'",
            "tleikatánk",
            "tleik̲w kahínti",
            "tléik̲w",
            "tlék̲w yádi",
            "ts'éekáx̲k'w",
            "was'x'aan tléig̲u",
            "x'áax'",
            "xákwl'i",
            "xéel'i",
            "yéil'",
            "k̲'eikaxétl'k",
            "k̲'eishkaháagu",
            "k̲'áach'",
            "x̲aaheiwú"
        ]
    },
    "5.2.3.2": {
        "name": "Food from animals",
        "url": "Food-from-animals",
        "parent": [
            "5",
            "5.2",
            "5.2.3"
        ],
        "children": [],
        "links": [
            "a shís'k̲",
            "a g̲eiyí",
            "a x̲'úx̲u",
            "at x̲'éeshi",
            "daaw",
            "dleey",
            "dákwtasi",
            "eex̲",
            "eix̲",
            "gandaas'aají háatl'i",
            "gishoo taayí",
            "k'wát'",
            "kaháakw",
            "saak eex̲í",
            "taay",
            "toow",
            "g̲áax'w",
            "x̲áat"
        ]
    },
    "5.2.3.4": {
        "name": "Prepared food",
        "url": "Prepared-food",
        "parent": [
            "5",
            "5.2",
            "5.2.3"
        ],
        "children": [],
        "links": [
            "a kas'úkx̲u",
            "a téix̲i",
            "a x̲'éeshi",
            "at kaawaxúkw",
            "at uwax̲'án",
            "chíl xook",
            "eex̲ kát sakwnein",
            "gangukg̲áx̲i",
            "k'ínk'",
            "kadúkli",
            "kanálx̲i",
            "kanéegwál'",
            "katéix̲",
            "kag̲útlx̲i",
            "kak̲áshx̲i",
            "náayadi",
            "sakwnéin katéix̲i",
            "tax̲héeni",
            "ts'ak'áawásh",
            "téeyí",
            "téix̲",
            "wásh",
            "yan uwa.ée",
            "g̲ík̲s",
            "g̲ík̲saa",
            "útlx̲i"
        ]
    },
    "5.2.3.6": {
        "name": "Beverages",
        "url": "Beverages",
        "parent": [
            "5",
            "5.2",
            "5.2.3"
        ],
        "children": [],
        "links": [
            "at kahéeni",
            "at wudináa",
            "cháayoo",
            "káaxwei",
            "náaw",
            "s'ikshaldéen",
            "tléik̲w kahéeni",
            "wasóos l'aayí",
            "x'áax' kahéeni",
            "yat'aayi héen"
        ]
    },
    "5.2.3.7": {
        "name": "Alcoholic beverages",
        "url": "Alcoholic-beverages",
        "parent": [
            "5",
            "5.2",
            "5.2.3"
        ],
        "children": [],
        "links": [
            "géewaa",
            "kanashú",
            "kasiyaayi héen"
        ]
    },
    "5.2.4": {
        "name": "Tobacco",
        "url": "Tobacco",
        "parent": [
            "5",
            "5.2"
        ],
        "children": [],
        "links": [
            "kasiyéiyi s'eik̲",
            "kat'éex'",
            "s'ik̲daakeit",
            "sh x̲'awdis'eik̲",
            "tuwaakú",
            "g̲ánch"
        ]
    },
    "5.3": {
        "name": "Clothing",
        "url": "Clothing",
        "parent": [
            "5"
        ],
        "children": [
            "5.3.3",
            "5.3.6",
            "5.3.7",
            "5.3.8"
        ],
        "links": [
            "at xáshdi téel",
            "doonyaax̲ k'oodás'",
            "doonyaax̲l'aak",
            "jáaji",
            "k'oodás'",
            "kakéin k'oodás'",
            "kasanka.át",
            "kinaa.át",
            "kinaak.át",
            "l'aak",
            "l'ée x'wán",
            "l.uljíni",
            "lag̲wán",
            "naa.át",
            "neilyeetéeli",
            "s'áaxw",
            "s'éil' tsáax'",
            "sadaat'aay",
            "saka.át",
            "shadaa.át",
            "séek",
            "teik̲",
            "tuk̲'atáal",
            "tuk̲daa.át",
            "téel",
            "wak̲kadóox'",
            "x'wán"
        ]
    },
    "5.3.3": {
        "name": "Traditional clothing",
        "url": "Traditional-clothing",
        "parent": [
            "5",
            "5.3"
        ],
        "children": [],
        "links": [
            "kaséik̲'w",
            "koogéinaa",
            "l'ax̲keit",
            "naaxein",
            "sankeit",
            "shadakóox̲'",
            "x'áan yinaa.át",
            "x'óow",
            "yaka.óot' x'óow",
            "yinaaháat",
            "yuka.óot' x'óow",
            "k̲aayuka.óot'i x'óow",
            "x̲'uskeit",
            "x̲aat s'áaxw"
        ]
    },
    "5.3.6": {
        "name": "Parts of clothing",
        "url": "Parts-of-clothing",
        "parent": [
            "5",
            "5.3"
        ],
        "children": [],
        "links": [
            "a jíni",
            "a kóon",
            "at tux̲'wáns'i",
            "du kóon",
            "téel too.ádi",
            "téel x̲'adzaasí",
            "téel x̲'agudzaasí",
            "téel x̲'akadzaazí",
            "yaka.óot'",
            "yuka.óot'",
            "g̲altú",
            "k̲aayaku.óot'i"
        ]
    },
    "5.3.7": {
        "name": "Wear clothing",
        "url": "Wear-clothing",
        "parent": [
            "5",
            "5.3"
        ],
        "children": [],
        "links": [
            "kaltéelk̲",
            "kát adatéen",
            "káx̲ awditee",
            "yan sh wudzinéi",
            "yéi aawa.oo",
            "x̲'usdé awdiyík̲"
        ]
    },
    "5.3.8": {
        "name": "Naked",
        "url": "Naked",
        "parent": [
            "5",
            "5.3"
        ],
        "children": [],
        "links": [
            "kaldaag̲ákw"
        ]
    },
    "5.4": {
        "name": "Adornment",
        "url": "Adornment",
        "parent": [
            "5"
        ],
        "children": [
            "5.4.1",
            "5.4.2",
            "5.4.3"
        ],
        "links": []
    },
    "5.4.1": {
        "name": "Jewelry",
        "url": "Jewelry",
        "parent": [
            "5",
            "5.4"
        ],
        "children": [],
        "links": [
            "guk kajaash",
            "guk tl'éinx̲w",
            "guk.át",
            "jikawáach",
            "kées",
            "lunás",
            "seit",
            "tl'ik̲kakées",
            "k̲'anoox̲",
            "k̲'anoox̲ eetí",
            "x̲'eint'áax'aa"
        ]
    },
    "5.4.2": {
        "name": "Cosmetics",
        "url": "Cosmetics",
        "parent": [
            "5",
            "5.4"
        ],
        "children": [],
        "links": [
            "léix̲'w",
            "yaneis'í",
            "yawéinaa",
            "x̲'akasék̲'waa"
        ]
    },
    "5.4.3": {
        "name": "Caring for hair",
        "url": "Caring-for-hair",
        "parent": [
            "5",
            "5.4"
        ],
        "children": [],
        "links": [
            "aan yaduxas' át",
            "ch'éen",
            "shach'éen",
            "shax̲'ée x'wál'",
            "xéidu",
            "k̲aa shaksayéigu",
            "k̲aa shaksayík̲s'i"
        ]
    },
    "5.5": {
        "name": "Fire",
        "url": "Fire",
        "parent": [
            "5"
        ],
        "children": [],
        "links": [
            "at kaayí",
            "awlix̲'éx̲'",
            "ayakawlikís'",
            "dáadzi",
            "dús'",
            "gaan woolí",
            "gan eetí",
            "ganaltáak",
            "gandaa",
            "gangook",
            "ganigeidí",
            "gantuxoogú",
            "ganyal'óot'",
            "gán",
            "gán kak̲ás'ti",
            "gán tl'áak'",
            "gán yátx'i",
            "kei awsigán",
            "kei wjitúk",
            "kél't'",
            "l'eiwú",
            "l'oowú",
            "s'eek̲",
            "s'eik̲",
            "shóot awdi.ák",
            "t'ooch' té",
            "ux̲gank̲áas'",
            "wudix̲'éx̲'",
            "xoodzí",
            "xóosht'",
            "yakawlikís'",
            "yík̲dlaa",
            "át akawligán",
            "g̲unanaa tetl",
            "k̲áas'",
            "x̲'aan",
            "x̲'aan káx̲ túlaa"
        ]
    },
    "5.6": {
        "name": "Cleaning",
        "url": "Cleaning",
        "parent": [
            "5"
        ],
        "children": [
            "5.6.1"
        ],
        "links": [
            "aawa.óos'",
            "akaawa.óos'",
            "awlig̲oo",
            "t'aa ká aawaxéet'",
            "wudishúch",
            "wudixéet'",
            "xít'aa",
            "óos'i",
            "ús'aa"
        ]
    },
    "5.6.1": {
        "name": "Clean, dirty",
        "url": "Clean-dirty",
        "parent": [
            "5",
            "5.6"
        ],
        "children": [],
        "links": [
            "awlich'éx̲'w",
            "ch'éix̲'w",
            "kawshik̲útl'",
            "s'eex",
            "tl'eex",
            "ts'ák̲l",
            "wulich'éx̲'w"
        ]
    },
    "5.7": {
        "name": "Sleep",
        "url": "Sleep",
        "parent": [
            "5"
        ],
        "children": [],
        "links": [
            "a yáanáx̲ yaawatáa",
            "ash wusix̲ék̲",
            "has shawdik̲ée",
            "has woox̲éix'w",
            "kei awsigít",
            "kei wdzigít",
            "shawdinúk",
            "tá",
            "wootaa"
        ]
    },
    "6": {
        "name": "Work and occupation",
        "url": "Work-and-occupation",
        "children": [
            "6.1",
            "6.2",
            "6.4",
            "6.5",
            "6.6",
            "6.7",
            "6.8"
        ],
        "links": []
    },
    "6.1": {
        "name": "Work",
        "url": "Work",
        "parent": [
            "6"
        ],
        "children": [
            "6.1.3"
        ],
        "links": [
            "awsiwát",
            "ch'a g̲ég̲aa",
            "du ji.een",
            "ganaswáan",
            "yéi jeewanei",
            "yéi jiné",
            "yéi jinéiyi",
            "átx̲ awliyéx̲"
        ]
    },
    "6.1.3": {
        "name": "Difficult, impossible",
        "url": "Difficult-impossible",
        "parent": [
            "6",
            "6.1"
        ],
        "children": [],
        "links": [
            "wulidzée"
        ]
    },
    "6.2": {
        "name": "Agriculture",
        "url": "Agriculture",
        "parent": [
            "6"
        ],
        "children": [
            "6.2.1"
        ],
        "links": []
    },
    "6.2.1": {
        "name": "Growing crops",
        "url": "Growing-crops",
        "parent": [
            "6",
            "6.2"
        ],
        "children": [],
        "links": [
            "akaawahaa",
            "akawsi.aa",
            "katsóowaa",
            "táay kahéix̲i",
            "yéil koox̲étl'aa"
        ]
    },
    "6.4": {
        "name": "Hunt and fish",
        "url": "Hunt-and-fish",
        "parent": [
            "6"
        ],
        "children": [
            "6.4.1",
            "6.4.2",
            "6.4.5"
        ],
        "links": []
    },
    "6.4.1": {
        "name": "Hunt",
        "url": "Hunt",
        "parent": [
            "6",
            "6.4"
        ],
        "children": [],
        "links": [
            "a kádi",
            "a.únt",
            "aawa.ún",
            "aawaják̲",
            "aawal'óon",
            "aawal'óon",
            "aawat'úk",
            "al'óon",
            "al'óoni",
            "at s'aan.ax̲w dzáas",
            "at shax̲ishdi dzáas",
            "at tugáni",
            "at tux̲'wáns'i",
            "at x̲éidi",
            "ch'iyáash",
            "chooneit",
            "gútl",
            "sheexw",
            "sheixw",
            "sák̲s",
            "tláak̲",
            "woosáani",
            "óonaa"
        ]
    },
    "6.4.2": {
        "name": "Trap",
        "url": "Trap",
        "parent": [
            "6",
            "6.4"
        ],
        "children": [],
        "links": [
            "awdlidás'",
            "dáas'aa",
            "yéix̲",
            "g̲aatáa",
            "g̲aatáa yéi daanéiyi"
        ]
    },
    "6.4.5": {
        "name": "Fishing",
        "url": "Fishing",
        "parent": [
            "6",
            "6.4"
        ],
        "children": [],
        "links": [
            "a x̲aani",
            "aawasháat",
            "ashaawak'éx̲'",
            "ashalx̲óot'i",
            "ashawsiyaa",
            "ast'eix̲í",
            "asg̲eiwú",
            "awdzit'eix̲",
            "awdzig̲eiwú",
            "cháatl ast'eix̲í",
            "deegáa",
            "dlagwáa",
            "k'íx̲'aa",
            "k'óox̲' tíx'i",
            "kei awsiyík̲",
            "kooxídaa",
            "náxw",
            "shawdlix̲óot'",
            "shawdziyaa",
            "shax̲'út'aa",
            "shukalx̲aají",
            "sháal",
            "t'eix̲",
            "t'eix̲áa",
            "tsaag̲ál'",
            "tság̲aa",
            "táanaa",
            "xídlaa",
            "yawdinák̲w",
            "áadaa",
            "g̲eiwú",
            "óot'",
            "x̲áat g̲íjaa"
        ]
    },
    "6.5": {
        "name": "Working with buildings",
        "url": "Working-with-buildings",
        "parent": [
            "6"
        ],
        "children": [
            "6.5.1",
            "6.5.2",
            "6.5.3"
        ],
        "links": []
    },
    "6.5.1": {
        "name": "Building",
        "url": "Building",
        "parent": [
            "6",
            "6.5"
        ],
        "children": [],
        "links": [
            "atx'aan hídi",
            "atx̲á daakahídi",
            "awliyéx̲",
            "chál",
            "hoon daakahídi",
            "hít",
            "s'eik̲ daakahídi",
            "s'ísaa hít",
            "sh kadax'áshti hít",
            "x'úx' daakahídi",
            "g̲ayéis' hít",
            "x̲aay",
            "x̲áat daakahídi"
        ]
    },
    "6.5.2": {
        "name": "Parts of a building",
        "url": "Parts-of-a-building",
        "parent": [
            "6",
            "6.5"
        ],
        "children": [],
        "links": [
            "a kax̲yee",
            "dzeit",
            "gáas'",
            "hít kagaadí",
            "hít kaságu",
            "hít kat'áayi",
            "hít ká",
            "hít tayeegáas'i",
            "kayáash",
            "kóok̲",
            "t'aa yátx'i",
            "t'áa yá",
            "yaash ká",
            "x̲'aháat",
            "x̲'awool",
            "x̲aawaag̲éi",
            "x̲aawaag̲í"
        ]
    },
    "6.5.3": {
        "name": "Building materials",
        "url": "Building-materials",
        "parent": [
            "6",
            "6.5"
        ],
        "children": [],
        "links": [
            "hít da.ideidí",
            "hít kax'úx'u",
            "kax'ás'ti",
            "shayéen",
            "t'áa",
            "tuháayi"
        ]
    },
    "6.6": {
        "name": "Occupations",
        "url": "Occupations",
        "parent": [
            "6"
        ],
        "children": [
            "6.6.1",
            "6.6.2",
            "6.6.3",
            "6.6.5"
        ],
        "links": [
            "aan s'aatí",
            "akahéix̲i",
            "at káx̲ adéli",
            "at layeix̲ s'aatí",
            "atyátx'i latíni",
            "dahooní",
            "dáanaa t'éex̲'i",
            "hoon s'aatí",
            "kak'dakwéiy s'aatí",
            "kak'kakwéiy s'aatí",
            "kaldáal'i",
            "kashxeedí",
            "katíx̲'aa s'aatí",
            "nakwnéit",
            "sgóonwaan",
            "sh kalneegí",
            "sh kalyéiyi",
            "taat aayí adéli",
            "téel layeix̲í",
            "wanadóo latíni",
            "wáachwaan",
            "wáadishgaa",
            "x'áan kanáayi",
            "yaa at naskwéini",
            "yaakw yasatáni",
            "át k̲ukawu.aag̲ú",
            "g̲ayéis' layeix̲í",
            "k̲aa daa yaséix̲i",
            "k̲aa oox̲ layeix̲í",
            "k̲aa oox̲ yei daanéiyi",
            "k̲aa x̲'éidáx̲ kashxeedí",
            "k̲aadaaxaashí",
            "k̲oon sh kalneegí",
            "k̲unáagu",
            "k̲óo at latóowu"
        ]
    },
    "6.6.1": {
        "name": "Working with cloth",
        "url": "Working-with-cloth",
        "parent": [
            "6",
            "6.6"
        ],
        "children": [],
        "links": [
            "a káa akaawak̲áa",
            "a kát akawlik̲áa",
            "a kóon",
            "a t'einyaakawoowú",
            "aawak̲áa",
            "akawsikei",
            "akawsinei",
            "dak̲éis'",
            "du kóon",
            "kakéin",
            "kasné",
            "kawdzinéi",
            "s'ísaa",
            "tl'ek̲shanaa.át",
            "tl'ik̲naa.át",
            "táax'ál'",
            "tás",
            "téey",
            "wudik̲áa",
            "áx̲ akawlis'eik̲",
            "k̲éich'ál'",
            "x̲'éex'wál'",
            "x̲'éigwál'"
        ]
    },
    "6.6.2": {
        "name": "Working with minerals",
        "url": "Working-with-minerals",
        "parent": [
            "6",
            "6.6"
        ],
        "children": [],
        "links": [
            "dáanaa kat'éex̲'i",
            "g̲ayéis' t'éix̲'i"
        ]
    },
    "6.6.3": {
        "name": "Working with wood",
        "url": "Working-with-wood",
        "parent": [
            "6",
            "6.6"
        ],
        "children": [],
        "links": [
            "aawas'úw",
            "akaawach'ák'w",
            "akaawayéx̲",
            "at kach'áak'u",
            "kax'ás'ti daakahídi",
            "yees'"
        ]
    },
    "6.6.5": {
        "name": "Art",
        "url": "Art",
        "parent": [
            "6",
            "6.6"
        ],
        "children": [],
        "links": [
            "aawanéegwál'",
            "akawlisék̲'w",
            "du ji.eetí",
            "kanágaa",
            "kasék̲'x̲u",
            "kat'íshaa",
            "kawdisék̲'w",
            "kawóot",
            "kawóot ka.íshaa",
            "kootéeyaa",
            "lukat'íshaa",
            "léet'",
            "naaxein",
            "néegwál'",
            "s'eek̲ kawóot",
            "sháak",
            "teey woodí",
            "téel ik̲keidí",
            "téel tukanágaa",
            "x'akaskéin",
            "yuka.óot'",
            "k̲aa ji.eetí",
            "k̲aayaku.óot'i",
            "x̲'oon",
            "x̲aat",
            "x̲aat awlis'él'"
        ]
    },
    "6.7": {
        "name": "Tool",
        "url": "Tool",
        "parent": [
            "6"
        ],
        "children": [
            "6.7.7",
            "6.7.9"
        ],
        "links": [
            "aankayéx̲aa",
            "at katáx'aa",
            "dlágwaa",
            "jishagóon",
            "kaay",
            "kach'ák'waa",
            "kas'éet",
            "kas'éet katíx̲'aa",
            "kas'éet kag̲úkwaa",
            "kas'úwaa",
            "kax'ás'aa",
            "kaxées'",
            "koojúxaa",
            "koojúxwaa",
            "kwéiy",
            "káat'",
            "kít'aa",
            "l'oowú ták̲l",
            "l'át'aa",
            "s'oow x̲út'aa",
            "s'úwaa",
            "sh daxash washéen",
            "shanax̲wáayi",
            "shanax̲wáayi yádi",
            "shunax̲wáayi",
            "t'áa kayéx̲aa",
            "t'áa shukaayí",
            "t'áa shuxáshaa",
            "t'éesh kaayí",
            "tayees",
            "ták̲l",
            "té shanax̲wáayi",
            "tíx'",
            "tíx' yádi",
            "tíyaa",
            "túlaa",
            "túlx'u",
            "wéiksh",
            "wóoshnáx̲ x̲'akakéix̲i",
            "x'ádaa",
            "xáatl kaltság̲aa",
            "xáshaa",
            "yayéinaa",
            "yees",
            "yoo katan lítaa",
            "g̲ayéis' tíx'",
            "k̲utl'ídaa",
            "x̲'akakeix̲í",
            "x̲'éex'w",
            "x̲'éi shadagutx̲i lítaa",
            "x̲éey",
            "x̲út'aa"
        ]
    },
    "6.7.7": {
        "name": "Container",
        "url": "Container",
        "parent": [
            "6",
            "6.7"
        ],
        "children": [],
        "links": [
            "a xákwti",
            "a yanáa",
            "a yee.ádi",
            "at daayí k̲ákw",
            "at la.át",
            "daakeit",
            "daneit",
            "galnáat'ani",
            "kaat",
            "kadádzaa yeit",
            "lák̲t",
            "naasa.áa",
            "néil'",
            "ts'anéi",
            "tudaxákw",
            "tuk̲daadaxákw",
            "táal",
            "wóow daakeit",
            "x'eesháa",
            "k̲ákw",
            "k̲óok",
            "x̲'al'daakeit"
        ]
    },
    "6.7.9": {
        "name": "Machine",
        "url": "Machine",
        "parent": [
            "6",
            "6.7"
        ],
        "children": [],
        "links": [
            "washéen",
            "washéen katág̲ayi",
            "g̲unéi kaawajúx"
        ]
    },
    "6.8": {
        "name": "Finance",
        "url": "Finance",
        "parent": [
            "6"
        ],
        "children": [
            "6.8.4",
            "6.8.5"
        ],
        "links": [
            "a yeidí",
            "aank̲áawu",
            "akaawag̲éex'",
            "dáanaa",
            "dáanaa s'aatí",
            "dáanaa shoowú",
            "gút",
            "kaldáanaak̲",
            "kawdig̲éex'",
            "lanáalx̲",
            "wulináalx̲",
            "át kawdig̲íx'",
            "k̲'anashgidéi k̲áa",
            "x̲'awlitseen"
        ]
    },
    "6.8.4": {
        "name": "Financial transactions",
        "url": "Financial-transactions",
        "parent": [
            "6",
            "6.8"
        ],
        "children": [],
        "links": [
            "aawa.oo",
            "aawa.óow",
            "aawahoon",
            "ajeewak̲éi",
            "akaawa.oo",
            "awdlik̲áa",
            "awlihóon",
            "hoon",
            "wudlihoon"
        ]
    },
    "6.8.5": {
        "name": "Borrow",
        "url": "Borrow",
        "parent": [
            "6",
            "6.8"
        ],
        "children": [],
        "links": [
            "aawahées'",
            "akaawahées'",
            "du éet aawahís'"
        ]
    },
    "7": {
        "name": "Physical actions",
        "url": "Physical-actions",
        "children": [
            "7.1",
            "7.2",
            "7.3",
            "7.4",
            "7.5",
            "7.6",
            "7.7"
        ],
        "links": []
    },
    "7.1": {
        "name": "Posture",
        "url": "Posture",
        "parent": [
            "7"
        ],
        "children": [
            "7.1.1",
            "7.1.2",
            "7.1.3",
            "7.1.8",
            "7.1.9"
        ],
        "links": []
    },
    "7.1.1": {
        "name": "Stand",
        "url": "Stand",
        "parent": [
            "7",
            "7.1"
        ],
        "children": [],
        "links": [
            "has nák̲",
            "hán",
            "wudihaan",
            "yan has uwanák̲",
            "yan uwahán"
        ]
    },
    "7.1.2": {
        "name": "Sit",
        "url": "Sit",
        "parent": [
            "7",
            "7.1"
        ],
        "children": [],
        "links": [
            ".áa",
            "awsinook",
            "has wook̲ee",
            "has k̲éen",
            "woonook",
            "wujik̲aak̲",
            "át as.áa",
            "át tán"
        ]
    },
    "7.1.3": {
        "name": "Lie down",
        "url": "Lie-down",
        "parent": [
            "7",
            "7.1"
        ],
        "children": [],
        "links": [
            "át .áx̲",
            "át akatéen",
            "át akla.át",
            "át astán",
            "át kala.át",
            "át la.át",
            "át satéen"
        ]
    },
    "7.1.8": {
        "name": "Bend down",
        "url": "Bend-down",
        "parent": [
            "7",
            "7.1"
        ],
        "children": [],
        "links": [
            "akaawataan",
            "yóo katán"
        ]
    },
    "7.1.9": {
        "name": "Move a part of the body",
        "url": "Move-a-part-of-the-body",
        "parent": [
            "7",
            "7.1"
        ],
        "children": [],
        "links": [
            "aawaxích",
            "aawayeek̲",
            "anax̲ yaawajél",
            "kei (ha)s jiwlitsóow",
            "kei jiwlitsák̲",
            "kei jiwsitán",
            "yan jiwsitán"
        ]
    },
    "7.2": {
        "name": "Move",
        "url": "Move",
        "parent": [
            "7"
        ],
        "children": [
            "7.2.1",
            "7.2.2",
            "7.2.6"
        ],
        "links": []
    },
    "7.2.1": {
        "name": "Manner of movement",
        "url": "Manner-of-movement",
        "parent": [
            "7",
            "7.2"
        ],
        "children": [],
        "links": [
            "a daax̲ kei wdlitl'ét'",
            "a káa wooxeex",
            "a kát sh wudligás'",
            "a kát wujix̲ín",
            "a yáx̲ wudlitl'éit'",
            "aadáx̲ kawdiyaa",
            "aadé wdzigeet",
            "aadé wootlóox'",
            "aawax̲áa",
            "anax̲ kaawaxeex",
            "anax̲ yei wdzigít",
            "daak uwaxíx",
            "daak wudzigít",
            "daak̲ wudig̲wát'",
            "has loowagook̲",
            "has woo.aat",
            "héende awjit'ákw",
            "kaawajoox",
            "kei has kawduwak'én",
            "ux̲ kei",
            "wook̲oox̲",
            "wushix̲'éel'",
            "yaa nagút",
            "yaa nak̲úx̲",
            "yan uwahín",
            "yan yaawagás'",
            "át has wusikwaan",
            "át jiwsikwaan",
            "át kaawagwátl",
            "át kawdliyeech",
            "át kawdliyích",
            "át uwax'ák",
            "át uwaxíx",
            "át wootlóox'",
            "át wooxeex",
            "át wudig̲wáat'",
            "át wudik̲een",
            "át wudzigít",
            "át wujik'éin",
            "át wujixeex",
            "át wushix̲'éel'",
            "át wushix̲'íl'",
            "át yawsigóo",
            "áx̲ kawlix̲'éex'",
            "g̲unéi (ha)s loowagúk̲",
            "g̲unéi uwak̲úx̲",
            "g̲unéi wjixíx",
            "g̲wáatl'"
        ]
    },
    "7.2.2": {
        "name": "Move in a direction",
        "url": "Move-in-a-direction",
        "parent": [
            "7",
            "7.2"
        ],
        "children": [],
        "links": [
            "a daax̲ yaawak̲úx̲",
            "a nák̲ woogoot",
            "a tóonáx̲ yaawak̲úx̲",
            "a ítx̲ has loowagook̲",
            "a ítx̲ woogoot",
            "aadáx̲ woogoot",
            "aadé (ha)s kawdik'éet'",
            "aadé (ha)s loowagook̲",
            "aadé has woo.aat",
            "aadé wdik̲een",
            "aadé wjixeex",
            "aadé wligáas'",
            "aadé woogoot",
            "aadé wook̲oox̲",
            "aag̲áa has woo.aat",
            "aag̲áa woogoot",
            "akawsikei",
            "anax̲ has yaawa.át",
            "anax̲ yaawagút",
            "anák̲ kawdliyeech",
            "ayawdigút",
            "ayawdik̲úx̲",
            "daak",
            "daak awlikél'",
            "daak uwagút",
            "daak uwak̲úx̲",
            "daak̲",
            "du keekán",
            "du ít",
            "dáag̲i",
            "gági",
            "gági has uwa.át",
            "gági uwagút",
            "haat~",
            "has ashoowa.aat",
            "has ayawdi.át",
            "héide has yaawa.át",
            "héide yaawagút",
            "kei",
            "kei wusidák̲",
            "kux",
            "neil~",
            "yaa",
            "yaax̲",
            "yaax̲ woogoot",
            "yan uwahóo",
            "yan uwak̲úx̲",
            "yan~",
            "yeek̲",
            "yei",
            "yetx̲",
            "yoo",
            "áa kei (ha)s uwa.át",
            "át has kawdik'ít'",
            "át has luwagúk̲",
            "át has uwa.át",
            "át has woo.aat",
            "át uwagút",
            "át uwak̲úx̲",
            "át woogoot",
            "át wook̲oox̲",
            "át wujixíx",
            "át wuligás'",
            "áx̲ has woo.aat",
            "áx̲ woogoot",
            "áx̲ yaa (ha)s na.át",
            "k̲ut has loowagook̲",
            "k̲ut wujixeex",
            "k̲ux̲"
        ]
    },
    "7.2.6": {
        "name": "Travel",
        "url": "Travel",
        "parent": [
            "7",
            "7.2"
        ],
        "children": [],
        "links": [
            "aadé k̲oowateen",
            "anax̲ has yaawagóo",
            "sh tuwáa kasyéiyi",
            "át has yaawagoo",
            "át k̲uwatín"
        ]
    },
    "7.3": {
        "name": "Move something",
        "url": "Move-something",
        "parent": [
            "7"
        ],
        "children": [
            "7.3.1",
            "7.3.2",
            "7.3.4",
            "7.3.5",
            "7.3.6",
            "7.3.7",
            "7.3.8",
            "7.3.9"
        ],
        "links": []
    },
    "7.3.1": {
        "name": "Carry",
        "url": "Carry",
        "parent": [
            "7",
            "7.3"
        ],
        "children": [],
        "links": [
            "aadé aawatee",
            "aadé akaawajeil",
            "aadé at kaawajeil",
            "aadé awli.aat",
            "aadé awsitee",
            "aawataan",
            "aawayaa",
            "at wooyaa",
            "awsinook",
            "awsitee",
            "neil aawatée",
            "neil awsi.ín",
            "yaax̲ aawataan",
            "át aawa.aax̲",
            "át aawataan",
            "át aawatán",
            "át awsi.ín",
            "át awsinook"
        ]
    },
    "7.3.2": {
        "name": "Move something in a direction",
        "url": "Move-something-in-a-direction",
        "parent": [
            "7",
            "7.3"
        ],
        "children": [],
        "links": [
            "a kaadé ayawsixaa",
            "aadáx̲ awsixáat'",
            "aadé aawax̲óot'",
            "aadé akawlijoox",
            "aadé akawsixaa",
            "aadé awsixáat'",
            "aadé awsik̲oox̲",
            "aadé awsix̲óot'",
            "aawax̲aach",
            "aax̲ kei aawayísh",
            "akaawachák",
            "ashakawliyén",
            "ashawlihík",
            "ashawlitl'ít'",
            "awsixáat'",
            "awsik̲oox̲",
            "ayaawax̲áax'w",
            "du jeet aawa.áx̲",
            "du jeet aawatán",
            "du jeet aawatée",
            "du jeet akaawatée",
            "du jeet akawli.át",
            "du jeet awli.át",
            "du jeet awsi.ín",
            "du jeet awsitán",
            "du jeet yéi awsinei",
            "du jikaadáx̲ ayaawatán",
            "kei akaawag̲íx'",
            "kei awsinúk",
            "kei awsitán",
            "kei awsiyík̲",
            "kei ayawli.át",
            "wooch yáx̲ awsinei",
            "yaakw daak ayawliták̲",
            "yan aawax̲út'",
            "yan awsinúk",
            "áa yéi aawa.oo",
            "át aawalít",
            "át aawax̲óot'",
            "át akaawax̲ích",
            "át akawlijúx",
            "át akawsixáa",
            "át awsiyík̲",
            "át awsik̲úx̲",
            "át ayaawax̲aa",
            "át ayaawax̲áa",
            "áx̲ aawatee",
            "áx̲ akaawayaa",
            "áx̲ ashayaawatée",
            "k̲ux̲ aawatán"
        ]
    },
    "7.3.4": {
        "name": "Handle something",
        "url": "Handle-something",
        "parent": [
            "7",
            "7.3"
        ],
        "children": [],
        "links": [
            "aax̲ aawatee",
            "aax̲ yéi awsinei",
            "adaawsi.áx̲w",
            "awlisháat",
            "kei aawatée",
            "yax̲ akaawax̲ích",
            "át uwashée"
        ]
    },
    "7.3.5": {
        "name": "Turn something",
        "url": "Turn-something",
        "parent": [
            "7",
            "7.3"
        ],
        "children": [],
        "links": [
            "shóo yax̲",
            "áa yax̲",
            "áa yax̲ aawatán",
            "át akawlitíx̲'"
        ]
    },
    "7.3.6": {
        "name": "Open/Shut",
        "url": "Open/Shut",
        "parent": [
            "7",
            "7.3"
        ],
        "children": [],
        "links": [
            "ax̲'eiwas'él'",
            "x̲'éit akawlitíx̲'"
        ]
    },
    "7.3.7": {
        "name": "Cover/Uncover",
        "url": "Cover/Uncover",
        "parent": [
            "7",
            "7.3"
        ],
        "children": [],
        "links": [
            "a kaháadi",
            "a káx̲ aawayeesh",
            "a náa",
            "a yana.áat'ani",
            "a yanáa",
            "a yanáax̲ at wootaan",
            "ax̲'eiwadíx'"
        ]
    },
    "7.3.8": {
        "name": "Transportation",
        "url": "Transportation",
        "parent": [
            "7",
            "7.3"
        ],
        "children": [],
        "links": [
            "a ka.aasí",
            "a kát sh kadultsex̲t át",
            "a xées'i",
            "aandaayaagú",
            "ax̲áa",
            "dáax̲",
            "dúk̲",
            "jaak̲úx̲",
            "kajúxaa káayag̲ijeit",
            "l'áak̲w",
            "s'eenáa yaakw",
            "s'ísaa yaakw",
            "seet",
            "shawdziyaa",
            "shayéinaa",
            "shgáaw",
            "shtéen káa",
            "t'áa jáaji",
            "t'áa jáaji wootsaag̲ayí",
            "xáatl kaltság̲aa",
            "xát'aa",
            "yaakw",
            "yaakw yiks'ísayi",
            "yaakw x̲ukahídi",
            "yaakw x̲uká",
            "yakw daa.ideidí",
            "yakwtlénx'",
            "yakwyádi",
            "yax̲ak'áaw",
            "yáxwch'i yaakw",
            "x̲aanás'"
        ]
    },
    "7.3.9": {
        "name": "Keep/Leave something",
        "url": "Keep/Leave-something",
        "parent": [
            "7",
            "7.3"
        ],
        "children": [],
        "links": [
            "ajeewanák̲",
            "yan aawatán",
            "yan aawatée",
            "yan akaawatée",
            "yan akawsitán",
            "yan awli.át",
            "yan awsi.ín",
            "yan awsitán",
            "yan awsitée",
            "yan yéi akawsinéi"
        ]
    },
    "7.4": {
        "name": "Arrange",
        "url": "Arrange",
        "parent": [
            "7"
        ],
        "children": [
            "7.4.1",
            "7.4.2"
        ],
        "links": []
    },
    "7.4.1": {
        "name": "Gather",
        "url": "Gather",
        "parent": [
            "7",
            "7.4"
        ],
        "children": [],
        "links": [
            "aawa.ín",
            "ayawsiháa",
            "k̲oowak'ít'"
        ]
    },
    "7.4.2": {
        "name": "Join, attach",
        "url": "Join-attach",
        "parent": [
            "7",
            "7.4"
        ],
        "children": [],
        "links": [
            "a kát akawlik̲áa",
            "aadé akawsixát",
            "aadé ksixát",
            "akaawadúx'",
            "akawli.ísh",
            "k'idéin akawsixát",
            "yánde aawa.áx̲w",
            "át akawlis'íx'w",
            "át akawsix'óo"
        ]
    },
    "7.5": {
        "name": "Hide",
        "url": "Hide",
        "parent": [
            "7"
        ],
        "children": [
            "7.5.1",
            "7.5.2",
            "7.5.3"
        ],
        "links": [
            "awdlisín",
            "awlisín"
        ]
    },
    "7.5.1": {
        "name": "Search",
        "url": "Search",
        "parent": [
            "7",
            "7.5"
        ],
        "children": [],
        "links": [
            "aag̲áa k̲oowashee",
            "át k̲uwashée"
        ]
    },
    "7.5.2": {
        "name": "Find",
        "url": "Find",
        "parent": [
            "7",
            "7.5"
        ],
        "children": [],
        "links": [
            "aawat'ei",
            "akaawat'ei"
        ]
    },
    "7.5.3": {
        "name": "Lose, misplace",
        "url": "Lose-misplace",
        "parent": [
            "7",
            "7.5"
        ],
        "children": [],
        "links": [
            "k̲ut",
            "k̲ut aawataan",
            "k̲ut aawag̲éex'",
            "k̲ut akaawag̲éex'",
            "k̲ut akaawlisóos",
            "k̲ut has kaawasóos",
            "k̲ut has woo.aat",
            "k̲ut woogoot",
            "k̲ut wook̲oox̲",
            "k̲ut wudzigeet"
        ]
    },
    "7.6": {
        "name": "Physical impact",
        "url": "Physical-impact",
        "parent": [
            "7"
        ],
        "children": [
            "7.6.1"
        ],
        "links": [
            "aadé yawdig̲eech",
            "aawa.ún",
            "aawagwaal",
            "aawat'ách",
            "aawat'úk",
            "aawatséx̲",
            "aawaták̲",
            "aawaxás'",
            "akaawachúx",
            "akaawat'éx̲'",
            "akaawag̲útl",
            "ashaawax̲ích",
            "awlixwách",
            "ayaawagwál",
            "ayaawax'át",
            "ayaawag̲íl'",
            "át yawdig̲ích",
            "át yawdlig̲ích"
        ]
    },
    "7.6.1": {
        "name": "Hit",
        "url": "Hit",
        "parent": [
            "7",
            "7.6"
        ],
        "children": [],
        "links": [
            "aawajáak̲w",
            "át kaawaxíx"
        ]
    },
    "7.7": {
        "name": "Divide into pieces",
        "url": "Divide-into-pieces",
        "parent": [
            "7"
        ],
        "children": [
            "7.7.6"
        ],
        "links": [
            "aawal'éex'",
            "aawas'éil'",
            "aawas'úw",
            "aawaxaash",
            "aawax̲út'",
            "aax̲ aawaxásh",
            "akaawas'éil'",
            "akaawaxaash",
            "akaawayéx̲",
            "akawlicháa",
            "akawlix̲óot'",
            "awlik'oots",
            "awlil'éex'",
            "awlixaash",
            "kawdis'éil'",
            "wool'éex'",
            "wudixaash",
            "wulil'éex'"
        ]
    },
    "7.7.6": {
        "name": "Dig",
        "url": "Dig",
        "parent": [
            "7",
            "7.7"
        ],
        "children": [],
        "links": [
            "akaawahaa",
            "akaawahaa",
            "akaawaháa",
            "kei akaawaháa",
            "x̲aat awlis'él'"
        ]
    },
    "8": {
        "name": "States",
        "url": "States",
        "children": [
            "8.1",
            "8.2",
            "8.3",
            "8.4",
            "8.5",
            "8.6"
        ],
        "links": []
    },
    "8.1": {
        "name": "Quantity",
        "url": "Quantity",
        "parent": [
            "8"
        ],
        "children": [
            "8.1.2",
            "8.1.5"
        ],
        "links": [
            "a k̲ín",
            "a x̲ooní",
            "aatlein",
            "du jeet shuwaxíx",
            "shaawahík",
            "shayawdihaa",
            "shuwaxeex",
            "x'oon sá",
            "yan kaawadlán",
            "g̲aa"
        ]
    },
    "8.1.2": {
        "name": "Number",
        "url": "Number",
        "parent": [
            "8",
            "8.1"
        ],
        "children": [],
        "links": [
            "ch'u déix̲",
            "daax'oon",
            "daax'oondahéen",
            "daax'oonínáx̲",
            "dax̲adooshóonáx̲",
            "dax̲adooshú",
            "dax̲dahéen",
            "dáx̲náx̲",
            "dáx̲g̲aa",
            "dáx̲g̲aanáx̲",
            "déix̲",
            "gooshúg̲unáx̲",
            "gooshúk̲",
            "has wudzix̲eit",
            "jinkaadináx̲",
            "jinkaat",
            "jinkaat k̲a tléináx̲",
            "jinkaat k̲a tléix'",
            "keijín",
            "keijínináx̲",
            "nas'gadooshóonáx̲",
            "nas'gadooshú",
            "nas'gidahéen",
            "nás'gináx̲",
            "nás'gig̲áa",
            "nás'k",
            "nás'k jinkaat",
            "nás'k jinkaat k̲a tléix'",
            "tleidahéen",
            "tleidooshóonáx̲",
            "tleidooshú",
            "tleik̲áa",
            "tleik̲áa k̲a tléináx̲",
            "tleik̲áa k̲a tléix'",
            "tleik̲áanáx̲",
            "tlex'dahéen",
            "tléináx̲",
            "tléix'",
            "tléix' hándit",
            "tlék'g̲aa",
            "tlék'g̲aanáx̲"
        ]
    },
    "8.1.5": {
        "name": "All/Some",
        "url": "All/Some",
        "parent": [
            "8",
            "8.1"
        ],
        "children": [],
        "links": [
            "a x̲oo aa",
            "ch'a ldakát át",
            "ch'a ldakát k̲áa",
            "daak̲w.aa sá",
            "ldakát",
            "ldakát át"
        ]
    },
    "8.2": {
        "name": "Measure",
        "url": "Measure",
        "parent": [
            "8"
        ],
        "children": [
            "8.2.2",
            "8.2.4",
            "8.2.5"
        ],
        "links": [
            "a kaayí",
            "kaay",
            "kaay",
            "waat",
            "woodál",
            "yéi kaawalei",
            "áa yan shukaawatán",
            "át shukatán",
            "k̲aa x̲'oos"
        ]
    },
    "8.2.2": {
        "name": "Long/Short",
        "url": "Long/Short",
        "parent": [
            "8",
            "8.2"
        ],
        "children": [],
        "links": [
            "dliyát'x'",
            "guwáatl'",
            "kuwáat'",
            "wooyáat'",
            "wuliyát'",
            "yéi kaawayáat'",
            "yéi kwliyáat'"
        ]
    },
    "8.2.4": {
        "name": "Size",
        "url": "Size",
        "parent": [
            "8",
            "8.2"
        ],
        "children": [],
        "links": [
            "digéix'",
            "-k'",
            "kadigéix'",
            "kayagéi",
            "kusakaak",
            "ligéi",
            "tlein",
            "woogéi",
            "wusikaak",
            "yawúx̲'",
            "yéi kaawagéi",
            "yéi kawsikaak",
            "yéi kwdigéi",
            "yéi kwditláa",
            "yéi kwdzigéi",
            "yéi kwlisáa",
            "k̲'áatl'"
        ]
    },
    "8.2.5": {
        "name": "Volume",
        "url": "Volume",
        "parent": [
            "8",
            "8.2"
        ],
        "children": [],
        "links": [
            "liyék",
            "woodlaan",
            "yéi kaawadláan"
        ]
    },
    "8.3": {
        "name": "Quality",
        "url": "Quality",
        "parent": [
            "8"
        ],
        "children": [
            "8.3.1",
            "8.3.2",
            "8.3.3",
            "8.3.4",
            "8.3.5",
            "8.3.6",
            "8.3.8",
            "8.3.9"
        ],
        "links": [
            "a kaséik̲'u",
            "a kayaa",
            "a yáx̲",
            "a g̲unayáade",
            "a g̲uwanyáade",
            "ch'áagu",
            "ch'áak' loowú",
            "góon",
            "héen",
            "kanat'á kahéeni",
            "kawdisék̲'w",
            "kawdiyés'",
            "ketllóox'u",
            "l'áax̲'",
            "lawúx̲",
            "léix̲'w",
            "lóol",
            "s'agwáat",
            "s'oow",
            "s'éix̲wani",
            "shex̲'wtáax'i",
            "shéix̲'w",
            "t'ooch'",
            "tl'áak'",
            "tl'áatl'",
            "tlagu",
            "uwaxúk",
            "woosh g̲unayáade",
            "woosh g̲unayáade aa",
            "woosh g̲uwanyáade",
            "woosháash",
            "xáats'",
            "yaawdig̲íl",
            "yawlik'áts'",
            "yées",
            "g̲unayéide",
            "k̲ugáas'",
            "x̲'aan",
            "x̲'éishx'w"
        ]
    },
    "8.3.1": {
        "name": "Shape",
        "url": "Shape",
        "parent": [
            "8",
            "8.3"
        ],
        "children": [],
        "links": [
            "a yáx̲ kaaxát",
            "kawdzitéx̲'",
            "kutlá"
        ]
    },
    "8.3.2": {
        "name": "Texture",
        "url": "Texture",
        "parent": [
            "8",
            "8.3"
        ],
        "children": [],
        "links": [
            "kaawat'íx'",
            "kashix̲'íl'k",
            "kasit'éex'",
            "g̲ákw"
        ]
    },
    "8.3.3": {
        "name": "Light",
        "url": "Light",
        "parent": [
            "8",
            "8.3"
        ],
        "children": [],
        "links": [
            "a kát kawdigán",
            "chéx̲'i",
            "kawdigán",
            "kawdli.ít'ch",
            "át akaawagán"
        ]
    },
    "8.3.4": {
        "name": "Temperature",
        "url": "Temperature",
        "parent": [
            "8",
            "8.3"
        ],
        "children": [],
        "links": [
            "awsi.át'",
            "awsit'áa",
            "si.áat'i héen",
            "uwat'áa",
            "wudlit'íx'",
            "wusit'áax̲'",
            "yaawat'áa"
        ]
    },
    "8.3.5": {
        "name": "Decay",
        "url": "Decay",
        "parent": [
            "8",
            "8.3"
        ],
        "children": [],
        "links": [
            "naak̲w",
            "wulis'íx",
            "yawóol",
            "g̲ayéis' háatl'i"
        ]
    },
    "8.3.6": {
        "name": "Type, kind",
        "url": "Type-kind",
        "parent": [
            "8",
            "8.3"
        ],
        "children": [],
        "links": [
            "a yáx̲ wootee",
            "dax̲yeekaadé",
            "tleiyeekaadé"
        ]
    },
    "8.3.8": {
        "name": "Good/Bad",
        "url": "Good/Bad",
        "parent": [
            "8",
            "8.3"
        ],
        "children": [],
        "links": [
            "a géit~",
            "kawlix̲éitl'shán",
            "tlél wushk'é",
            "wook'éi",
            "k̲aak̲x̲wdaagané (A)",
            "k̲oowak'ei",
            "k̲áakwt uwanéi",
            "k̲áakwt~"
        ]
    },
    "8.3.9": {
        "name": "Appearance",
        "url": "Appearance",
        "parent": [
            "8",
            "8.3"
        ],
        "children": [],
        "links": [
            "kadlich'ách'x̲",
            "kadlig̲áas'",
            "kajikáx'x̲",
            "kawlijée",
            "kawlig̲éi",
            "oowayáa",
            "shaklig̲éi"
        ]
    },
    "8.4": {
        "name": "Time",
        "url": "Time",
        "parent": [
            "8"
        ],
        "children": [
            "8.4.1",
            "8.4.2",
            "8.4.7",
            "8.4.8"
        ],
        "links": [
            "a ít aa",
            "aag̲áa",
            "ch'a gwátgeen sá",
            "ch'a yák'w",
            "ch'a yóok'",
            "du éet k̲uwaháa",
            "dziyáagin",
            "dziyáak",
            "déi",
            "dís woox̲éiyi",
            "gaaw",
            "gaaw yáx̲",
            "gaaw ítx'",
            "jikawáach",
            "keijín yagiyee",
            "seig̲án",
            "seig̲ánin",
            "tatgé",
            "táakw niyís",
            "yaawasík",
            "yanax̲ wushik'éex̲'",
            "yeedát",
            "yeisú",
            "át k̲uwaháa",
            "g̲unéi"
        ]
    },
    "8.4.1": {
        "name": "Period of time",
        "url": "Period-of-time",
        "parent": [
            "8",
            "8.4"
        ],
        "children": [],
        "links": [
            "a yeen",
            "ch'áakw",
            "ch'ákw",
            "dís",
            "gaaw x̲'áak",
            "taakw.eetí",
            "tliyaatgé",
            "táakw",
            "yagee",
            "yakyee",
            "yeis"
        ]
    },
    "8.4.2": {
        "name": "Time of the day",
        "url": "Time-of-the-day",
        "parent": [
            "8",
            "8.4"
        ],
        "children": [],
        "links": [
            "nisdaat",
            "sitgawsáan",
            "taat",
            "taat sitgawsáani",
            "taat yeen",
            "ts'ootaat",
            "xi.áat",
            "xáanaa",
            "yagiyee",
            "k̲ee.á",
            "k̲eex̲'é",
            "k̲ei.á",
            "k̲eix̲'é"
        ]
    },
    "8.4.7": {
        "name": "Take time",
        "url": "Take-time",
        "parent": [
            "8",
            "8.4"
        ],
        "children": [],
        "links": [
            "a yeeg̲áa",
            "a yig̲áa",
            "du x̲'ayee",
            "yeeywooyáat'"
        ]
    },
    "8.4.8": {
        "name": "Speed",
        "url": "Speed",
        "parent": [
            "8",
            "8.4"
        ],
        "children": [],
        "links": [
            "tláakw",
            "woosátk"
        ]
    },
    "8.5": {
        "name": "Location",
        "url": "Location",
        "parent": [
            "8"
        ],
        "children": [
            "8.5.1",
            "8.5.2",
            "8.5.3",
            "8.5.5"
        ],
        "links": [
            "a gukshatú",
            "a gukshitú",
            "a kat'óot",
            "a ká",
            "a kíknáx̲",
            "a shutú",
            "a tú",
            "a ya.áak",
            "a yinaa",
            "a yík",
            "a g̲ei",
            "a x̲'anaa",
            "a x̲oo",
            "aan",
            "aan x̲'ayee",
            "ch'a goot'á sá",
            "ch'a ldakát yé",
            "dak̲ká",
            "dei yík",
            "diyáanax̲.á",
            "du doonyaa",
            "du jee",
            "du wak̲kas'óox'",
            "du wak̲ká",
            "du wak̲shiyee",
            "du yat'éináx̲",
            "du x̲'ax̲án",
            "dzeit shuyee",
            "ganaltáak",
            "gáan",
            "hinx̲uká",
            "héen táak",
            "héen wát",
            "héen yík",
            "héen x̲'aká",
            "ixkée",
            "ldakát yé",
            "noow g̲ei",
            "shaa yadaa",
            "tliyaa",
            "x'aa luká",
            "yaakw yík",
            "á",
            "k̲aa yat'éináx̲",
            "k̲aankak.eetx'"
        ]
    },
    "8.5.1": {
        "name": "Here, there",
        "url": "Here-there",
        "parent": [
            "8",
            "8.5"
        ],
        "children": [],
        "links": [
            "hé",
            "wé",
            "yá",
            "yú",
            "áhé",
            "ák.hé",
            "ák.wé",
            "ákyú",
            "áwé",
            "áyá",
            "áyú"
        ]
    },
    "8.5.2": {
        "name": "Direction",
        "url": "Direction",
        "parent": [
            "8",
            "8.5"
        ],
        "children": [],
        "links": [
            "a dachóon",
            "a niyaadé",
            "a nák̲",
            "a yinaadé",
            "du kagé",
            "naakée",
            "wooshdakádin",
            "yaadachóon",
            "yóot~",
            "éex",
            "k̲ux̲ dak'óol'een"
        ]
    },
    "8.5.3": {
        "name": "Be at a place",
        "url": "Be-at-a-place",
        "parent": [
            "8",
            "8.5"
        ],
        "children": [],
        "links": [
            "du wak̲shiyeex' yéi wootee",
            "du x̲ánx' yéi wootee",
            "tleiyéi yéi wootee",
            "uwax̲éi",
            "yax̲ woodláax̲'w",
            "yé",
            "áa wdinook",
            "áa k̲oowa.oo",
            "át la.áa"
        ]
    },
    "8.5.5": {
        "name": "Spatial relations",
        "url": "Spatial-relations",
        "parent": [
            "8",
            "8.5"
        ],
        "children": [],
        "links": [
            "a daa",
            "a daaká",
            "a dagiygé",
            "a dayéen",
            "a déin",
            "a eetí",
            "a eeg̲ayáak",
            "a haanaanax̲.á",
            "a jiseiyí",
            "a k'iyee",
            "a kináak",
            "a kík",
            "a litká",
            "a shadaa",
            "a shakée",
            "a shuká",
            "a shuwadaa",
            "a shuwee",
            "a t'aak̲",
            "a t'ak̲ká",
            "a t'iká",
            "a t'áak",
            "a t'éik",
            "a tayee",
            "a tuwán",
            "a tuk̲daa",
            "a wanká",
            "a wanáak",
            "a wán",
            "a yax̲'áak",
            "a yee",
            "a ít",
            "a x̲'usyee",
            "a x̲'áak",
            "a x̲'éináx̲",
            "du dzúk",
            "du jix̲án",
            "du k'idaaká",
            "du shanáa",
            "du shuká",
            "du t'ak̲ká",
            "du t'áak",
            "du yat'éik",
            "du x̲'usyee",
            "du x̲án",
            "gandaa",
            "gangook",
            "x̲'aan gook"
        ]
    },
    "8.6": {
        "name": "Parts of things",
        "url": "Parts-of-things",
        "parent": [
            "8"
        ],
        "children": [],
        "links": [
            "a déinde aa",
            "a k'í",
            "a k'óol'",
            "a lukatíx'i",
            "a lux'aa",
            "a lú",
            "a shagóon",
            "a shoowú",
            "a shuyee",
            "a shú",
            "a t'einyaa",
            "a t'einyaakawoowú",
            "a taká",
            "a táak",
            "a wát",
            "a x'aan",
            "a xées'i",
            "a yaayí",
            "a yá",
            "a x̲'ax̲éedli",
            "a x̲'é",
            "gúkshi",
            "woosh yaayí",
            "yaakw x̲uká"
        ]
    },
    "9": {
        "name": "Grammar",
        "url": "Grammar",
        "children": [
            "9.1",
            "9.2",
            "9.3",
            "9.7"
        ],
        "links": []
    },
    "9.1": {
        "name": "General words",
        "url": "General-words",
        "parent": [
            "9"
        ],
        "children": [
            "9.1.1",
            "9.1.2",
            "9.1.3"
        ],
        "links": [
            "ch'a aa sá",
            "ch'a aadóo sá",
            "ch'a daa sá",
            "ch'a goot'á sá",
            "ch'a gwátgeen sá",
            "ch'a ldakát yé",
            "ch'a ldakát át",
            "ch'a ldakát k̲áa",
            "tléil",
            "á",
            "át",
            "k̲a"
        ]
    },
    "9.1.1": {
        "name": "Be",
        "url": "Be",
        "parent": [
            "9",
            "9.1"
        ],
        "children": [],
        "links": [
            "(noun)-x̲ wusitee",
            "du daat át k̲oowdzitee",
            "yéi wootee",
            "áx̲ g̲aashóo",
            "k̲oowdzitee"
        ]
    },
    "9.1.2": {
        "name": "Do",
        "url": "Do",
        "parent": [
            "9",
            "9.1"
        ],
        "children": [],
        "links": [
            "akwshitán",
            "du wak̲shiyeex' yéi awsinei",
            "yan ashawlihík",
            "yan awsinéi",
            "yéi adaanéi",
            "yéi awsinei",
            "yéi jeewanei",
            "yéi k̲oowanook"
        ]
    },
    "9.1.3": {
        "name": "Thing",
        "url": "Thing",
        "parent": [
            "9",
            "9.1"
        ],
        "children": [],
        "links": [
            "tlél daa sá"
        ]
    },
    "9.2": {
        "name": "Part of speech",
        "url": "Part-of-speech",
        "parent": [
            "9"
        ],
        "children": [
            "9.2.1",
            "9.2.2",
            "9.2.3",
            "9.2.4",
            "9.2.6",
            "9.2.7"
        ],
        "links": []
    },
    "9.2.1": {
        "name": "Adjectives",
        "url": "Adjectives",
        "parent": [
            "9",
            "9.2"
        ],
        "children": [],
        "links": [
            "ch'áagu",
            "guwáatl'",
            "-k'",
            "kusakaak",
            "kutlá",
            "kuwáat'",
            "sheech",
            "shich",
            "sákw",
            "tlagu",
            "tlein",
            "yées",
            "k̲'áatl'"
        ]
    },
    "9.2.2": {
        "name": "Adverbs",
        "url": "Adverbs",
        "parent": [
            "9",
            "9.2"
        ],
        "children": [],
        "links": [
            "a eetéenáx̲",
            "a géit~",
            "a yinaadé",
            "a g̲unayáade",
            "a x̲'éináx̲",
            "aag̲áa",
            "ch'a koogéiyi",
            "ch'a g̲ég̲aa",
            "dax̲yeekaadé",
            "dziyáak",
            "dáag̲i",
            "dák̲de",
            "gági",
            "haandé",
            "héeni",
            "héenx̲",
            "k'idéin",
            "kaldaag̲éináx̲",
            "kaldáanaak̲",
            "shux'áanáx̲",
            "shóo yax̲",
            "shóogunáx̲",
            "tl'aadéin",
            "tl'ag̲áa",
            "tleiyeekaadé",
            "ux̲ kei",
            "woosh g̲unayáade",
            "woosh g̲uwanyáade",
            "wooshdakádin",
            "wáa sá",
            "wáa yateeyi yéix'",
            "wáang̲aneens",
            "x'éig̲aa",
            "yaax̲",
            "yanax̲",
            "yax̲",
            "yeisú",
            "yux̲",
            "yéi",
            "yóot~",
            "áa yax̲",
            "g̲aa",
            "g̲unayéi",
            "g̲unayéide",
            "g̲unéi",
            "éex",
            "éeg̲i",
            "íxde",
            "k̲waak̲x̲ daak̲",
            "k̲áakwt~",
            "k̲únáx̲",
            "k̲útx̲"
        ]
    },
    "9.2.3": {
        "name": "Pronouns",
        "url": "Pronouns",
        "parent": [
            "9",
            "9.2"
        ],
        "children": [],
        "links": [
            "a-",
            "a",
            "aa",
            "ax̲",
            "du",
            "du ee~",
            "haa",
            "haa",
            "has",
            "has",
            "has du",
            "hás",
            "hú",
            "i-",
            "i-",
            "i",
            "too-",
            "uháan",
            "wa.é",
            "wooch",
            "woosh",
            "x'oon sá",
            "yee",
            "yee-",
            "yee",
            "yeewháan",
            "x̲a-",
            "x̲at",
            "x̲át"
        ]
    },
    "9.2.4": {
        "name": "Prepositions, postpositions",
        "url": "Prepositions-postpositions",
        "parent": [
            "9",
            "9.2"
        ],
        "children": [],
        "links": [
            "a nák̲",
            "a yáanáx̲",
            "a yáx̲",
            "a yís",
            "a g̲óot",
            "a k̲ín",
            "aan",
            "-ch",
            "du jeeyís",
            "-dáx̲",
            "-dé(i) ~ -de(i)",
            "een",
            "-náx̲",
            "-t",
            "teen",
            "tin",
            "-u",
            "-x'",
            "-g̲aa",
            "-x̲"
        ]
    },
    "9.2.6": {
        "name": "Particles",
        "url": "Particles",
        "parent": [
            "9",
            "9.2"
        ],
        "children": [],
        "links": [
            "aaá",
            "ayáx̲",
            "ch'a",
            "ch'a aan",
            "ch'a k'ikát",
            "ch'a tlákw",
            "ch'a yák'w",
            "ch'a yéi",
            "ch'a yóok'",
            "ch'a g̲óot",
            "ch'as",
            "ch'u",
            "ch'u shóogu",
            "ch'u tlei",
            "ch'u tleix̲",
            "de",
            "dágáa",
            "déi",
            "gu.aal",
            "gwáa",
            "gwál",
            "gé",
            "gí",
            "kwshé",
            "nooch",
            "s'é",
            "shákdéi",
            "shé",
            "sá",
            "tlax̲",
            "tle",
            "tlákw",
            "tléik'",
            "tsu",
            "tsá",
            "tsé",
            "tsú",
            "á",
            "ágé",
            "áhé",
            "ák.hé",
            "ák.wé",
            "ákyá",
            "ákyú",
            "áwé",
            "áyá",
            "áyú",
            "k̲achoo",
            "k̲ashde",
            "k̲u.aa",
            "óosh",
            "x̲á"
        ]
    },
    "9.2.7": {
        "name": "Interjections",
        "url": "Interjections",
        "parent": [
            "9",
            "9.2"
        ],
        "children": [],
        "links": [
            "Aganáa!",
            "Atskanée!",
            "Ch'a keetáanáx̲!",
            "Doó!",
            "Déi áwé!",
            "Eesháan!",
            "Gunalchéesh!",
            "Ha.é!",
            "Haa yátx'u ée!",
            "Hadláa!",
            "Hóoch!",
            "Hú!",
            "Húsh!",
            "Ilí s'é!",
            "Ilí!",
            "Sh eelk'átl'!",
            "Tláp!",
            "kwshéi",
            "É!",
            "Ée!",
            "Éits'k'!",
            "Óos'k'!"
        ]
    },
    "9.3": {
        "name": "Propositions",
        "url": "Propositions",
        "parent": [
            "9"
        ],
        "children": [
            "9.3.4"
        ],
        "links": []
    },
    "9.3.4": {
        "name": "Plurality",
        "url": "Plurality",
        "parent": [
            "9",
            "9.3"
        ],
        "children": [],
        "links": [
            "dag̲a-",
            "hás"
        ]
    },
    "9.7": {
        "name": "Name",
        "url": "Name",
        "parent": [
            "9"
        ],
        "children": [
            "9.7.2"
        ],
        "links": [
            "Chookaneidí",
            "Dak̲l'aweidí",
            "Deisheetaan",
            "Kaagwaantaan",
            "Kiks.ádi",
            "Kwaashk'i K̲wáan",
            "L'eeneidí",
            "L'uknax̲.ádi",
            "Lukaax̲.ádi",
            "Shangukeidí",
            "Suk̲teeneidí",
            "T'ak̲deintaan",
            "Teik̲weidí",
            "Tsaagweidí",
            "Was'eeneidí",
            "Yanyeidí",
            "du saayí",
            "du tlagook̲wansaayí",
            "saa",
            "yéi aawasáa",
            "yéi aawasáakw",
            "G̲aanax̲teidí",
            "K̲aach.ádi"
        ]
    },
    "9.7.2": {
        "name": "Name of a place",
        "url": "Name-of-a-place",
        "parent": [
            "9",
            "9.7"
        ],
        "children": [],
        "links": [
            "Aangóon",
            "Anáaski",
            "Anóoshi aaní",
            "Deishú",
            "Deisleen",
            "Deisleen K̲wáan",
            "Dzántik'i Héeni",
            "Gaawt'ak̲.aan",
            "Gántiyaakw Séedi",
            "Hinyaa K̲wáan",
            "Kenasnoow",
            "Kichx̲áan",
            "Kooya K̲wáan",
            "Lawáak",
            "Ltu.aa",
            "Sanyaa K̲wáan",
            "Shee At'iká K̲wáan",
            "Sheet'ká",
            "Shtax'héen",
            "Shg̲agwei",
            "T'aak̲ú",
            "Tax̲héeni",
            "Tlákw Aan",
            "Xudzidaa K̲wáan",
            "Xunaa",
            "Yaakwdáat",
            "Áa Tlein",
            "Áa Tlein K̲wáan",
            "Áak'w",
            "Áak'w K̲wáan",
            "Áankich",
            "G̲alyáx̲ K̲wáan",
            "G̲unaax̲oo K̲wáan",
            "K̲aachx̲ana.áak'w",
            "K̲éex̲'",
            "K̲éex̲' K̲wáan",
            "K̲éix̲'"
        ]
    }
};