export const phrasebook = {
    "1": {
        "title": "Greetings and departures, visiting",
        "content": [
            {
                "type": "text",
                "english": "Cultural note. Traditionally, there were no specific greetings in Tlingit, but nowadays, because of the influence of Euro-American culture, expressions corresponding to \"How are you?\" are commonly used as a greeting."
            },
            {
                "type": "ex",
                "english": "How are you?",
                "tlingit": [
                    [
                        [
                            "Wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee?",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wáa",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sás",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee?",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "(usually with physical emphasis)"
                ],
                "ids": [
                    [
                        "1_1",
                        "N"
                    ],
                    [
                        "1_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "How are you?",
                "tlingit": [
                    [
                        [
                            "Wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwatee?",
                            [
                                {
                                    "tlingit": "yéi tuwatee",
                                    "url": "yéi tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feels that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(usually with emotional emphasis)"
                ],
                "ids": [
                    [
                        "1_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm OK.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "utí.",
                            [
                                {
                                    "tlingit": "yéi wootee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was that way",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "OK.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wáa sá.",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's OK.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "utí.",
                            [
                                {
                                    "tlingit": "yéi wootee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was that way",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm fine.",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yak'éi.",
                            [
                                {
                                    "tlingit": "yak'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "How's (your father)?",
                "tlingit": [
                    [
                        [
                            "Wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yatee",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "(i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh)?",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My father's fine.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yak'éi.",
                            [
                                {
                                    "tlingit": "yak'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Not good.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ushk'é.",
                            [
                                {
                                    "tlingit": "l ushk'é",
                                    "url": "l ushk'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "evil, sin",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél ushk'é",
                                    "url": "tlél wushk'é",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is bad",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Come in. [singular]",
                "tlingit": [
                    [
                        [
                            "Neil",
                            [
                                {
                                    "tlingit": "neil",
                                    "url": "neil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "home",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "neil",
                                    "url": "neil~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "inside, into the house, home",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "neil~",
                                    "url": "neil~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "inside, into the house, home",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gú.",
                            [
                                {
                                    "tlingit": "Át gú!",
                                    "url": "át uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Daak gú!",
                                    "url": "daak uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Walk into the open!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Gági gú!",
                                    "url": "gági uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Emerge!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Come in. [plural]",
                "tlingit": [
                    [
                        [
                            "Neil",
                            [
                                {
                                    "tlingit": "neil",
                                    "url": "neil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "home",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "neil",
                                    "url": "neil~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "inside, into the house, home",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "neil~",
                                    "url": "neil~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "inside, into the house, home",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yi.á.",
                            [
                                {
                                    "tlingit": "Át yi.á!",
                                    "url": "át has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all walk there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Gági yi.á!",
                                    "url": "gági has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all emerge!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Áa kei yi.á!",
                                    "url": "áa kei (ha)s uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all walk up there!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Come here. [singular]",
                "tlingit": [
                    [
                        [
                            "Haagú.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Come here. [plural]",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yi.á.",
                            [
                                {
                                    "tlingit": "Át yi.á!",
                                    "url": "át has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all walk there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Gági yi.á!",
                                    "url": "gági has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all emerge!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Áa kei yi.á!",
                                    "url": "áa kei (ha)s uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all walk up there!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Oh, you came / you're here. [singular]",
                "tlingit": [
                    [
                        [
                            "Haaw,",
                            [
                                {
                                    "tlingit": "haaw",
                                    "url": "haaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bough, branch with needles on it, especially of hemlock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iyagút.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Oh, you came you're here. [plural]",
                "tlingit": [
                    [
                        [
                            "Haaw,",
                            [
                                {
                                    "tlingit": "haaw",
                                    "url": "haaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bough, branch with needles on it, especially of hemlock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeey.át.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's good to see you. [plural]",
                "tlingit": [
                    [
                        [
                            "Yak'éi",
                            [
                                {
                                    "tlingit": "yak'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲wsateení.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm happy to see you. [singular]",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú sigóo",
                            [
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du toowú wsigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "ix̲wsateení.",
                            [
                                {
                                    "tlingit": "awsiteen",
                                    "url": "awsiteen",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he sees it; s/he saw it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Thank you for coming. [singular]",
                "tlingit": [
                    [
                        [
                            "Gunalchéesh",
                            [
                                {
                                    "tlingit": "Gunalchéesh!",
                                    "url": "Gunalchéesh!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Thank you!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yigoodí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Thank you for coming. [plural]",
                "tlingit": [
                    [
                        [
                            "Gunalchéesh",
                            [
                                {
                                    "tlingit": "Gunalchéesh!",
                                    "url": "Gunalchéesh!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Thank you!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeey.aadí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Give me your hand. (\"Put 'er there.\")",
                "tlingit": [
                    [
                        [
                            "Haandéi",
                            [
                                {
                                    "tlingit": "haandéi",
                                    "url": "haandé",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hand it here, bring it here",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jín.",
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Sit down. [singular]",
                "tlingit": [
                    [
                        [
                            "G̲anú.",
                            [
                                {
                                    "tlingit": "g̲anú!",
                                    "url": "woonook",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sit down!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Sit down. [plural]",
                "tlingit": [
                    [
                        [
                            "G̲ayk̲í.",
                            [
                                {
                                    "tlingit": "G̲ayk̲í!",
                                    "url": "has wook̲ee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all sit down!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Have (here's) a chair.",
                "tlingit": [
                    [
                        [
                            "Yáadu",
                            [
                                {
                                    "tlingit": "yáadu",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "locativepr"
                                    ],
                                    "english": "here is; here are",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káayag̲ajeit.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Yáadu",
                            [
                                {
                                    "tlingit": "yáadu",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "locativepr"
                                    ],
                                    "english": "here is; here are",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káayak̲ijeit.",
                            [
                                {
                                    "tlingit": "káayak̲ijeit",
                                    "url": "káayag̲ijeit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "chair",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Yáadu",
                            [
                                {
                                    "tlingit": "yáadu",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "locativepr"
                                    ],
                                    "english": "here is; here are",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káayag̲ijeit.",
                            [
                                {
                                    "tlingit": "káayag̲ijeit",
                                    "url": "káayag̲ijeit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "chair",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "1_24",
                        "J"
                    ],
                    [
                        "1_25",
                        "N"
                    ],
                    [
                        "1_26",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Have (there's) a chair.",
                "tlingit": [
                    [
                        [
                            "Wéidu",
                            [
                                {
                                    "tlingit": "wéidu",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative",
                                        "locativepr"
                                    ],
                                    "english": "there is; there are",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káayag̲ajeit.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_27",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Here's two dollars.",
                "tlingit": [
                    [
                        [
                            "Yáadu",
                            [
                                {
                                    "tlingit": "yáadu",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "locativepr"
                                    ],
                                    "english": "here is; here are",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "déix̲",
                            [
                                {
                                    "tlingit": "déix̲",
                                    "url": "déix̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dáanaa.",
                            [
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "silver",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "money, coin, dollar",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_28",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Yes.",
                "tlingit": [
                    [
                        [
                            "Aaá.",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_29",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "No.",
                "tlingit": [
                    [
                        [
                            "Tléik'.",
                            [
                                {
                                    "tlingit": "tléik'",
                                    "url": "tléik'",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_30",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That's good.",
                "tlingit": [
                    [
                        [
                            "Yak'éi",
                            [
                                {
                                    "tlingit": "yak'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_31",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Amazing.",
                "tlingit": [
                    [
                        [
                            "Likoodzí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_32",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Poor thing!",
                "tlingit": [
                    [
                        [
                            "Eesháan.",
                            [
                                {
                                    "tlingit": "Eesháan!",
                                    "url": "Eesháan!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Poor thing!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_33",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Well! See how you are!",
                "tlingit": [
                    [
                        [
                            "Dóoooooo!",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_34",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Go away! Shoo! Scram!",
                "tlingit": [
                    [
                        [
                            "Chúk!",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Júk!",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "1_35",
                        "N"
                    ],
                    [
                        "1_36",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Go away already! Get lost!",
                "tlingit": [
                    [
                        [
                            "Júk",
                            [],
                            []
                        ],
                        [
                            "déi!",
                            [
                                {
                                    "tlingit": "déi",
                                    "url": "déi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "now, this time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_37",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Too much! (Wow! Gee whiz!)",
                "tlingit": [
                    [
                        [
                            "Hadláa!",
                            [
                                {
                                    "tlingit": "Hadláa!",
                                    "url": "Hadláa!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Good grief!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Dláa!",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "1_38",
                        "J"
                    ],
                    [
                        "1_39",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "More?",
                "tlingit": [
                    [
                        [
                            "Tsóok'?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_40",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That's all. (That's enough.)",
                "tlingit": [
                    [
                        [
                            "Déi áwé.",
                            [
                                {
                                    "tlingit": "Déi áwé!",
                                    "url": "Déi áwé!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Stop it!; That's enough!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "déi",
                                    "url": "déi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "now, this time",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_41",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's all gone.",
                "tlingit": [
                    [
                        [
                            "Hóoch'",
                            [
                                {
                                    "tlingit": "Hóoch!",
                                    "url": "Hóoch!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "That's all!; All gone!; No more!; All done!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "(áwé).",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Hóoch'k'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "1_42",
                        "J"
                    ],
                    [
                        "1_43",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Let me see (or think).",
                "tlingit": [
                    [
                        [
                            "Shk'e.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_44",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "cute/tiny",
                "tlingit": [
                    [
                        [
                            "óosk'",
                            [
                                {
                                    "tlingit": "Óos'k'!",
                                    "url": "Óos'k'!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Cute!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "óosk'i",
                            [
                                {
                                    "tlingit": "Óos'k'!",
                                    "url": "Óos'k'!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Cute!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "óoxk'",
                            [
                                {
                                    "tlingit": "Óos'k'!",
                                    "url": "Óos'k'!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Cute!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "1_45",
                        "N"
                    ],
                    [
                        "1_46",
                        "J"
                    ],
                    [
                        "1_47",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That's right; right on.",
                "tlingit": [
                    [
                        [
                            "Yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_48",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That's true.",
                "tlingit": [
                    [
                        [
                            "A yáx̲",
                            [
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the face of it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yáx̲",
                                    "tags": [
                                        "RelationalPostposition"
                                    ],
                                    "english": "like it, in accordance with it, as much as it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "gaaw yáx̲",
                                    "url": "gaaw yáx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "on time; in time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wudlitl'éit'",
                                    "url": "a yáx̲ wudlitl'éit'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it climbed the face of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Wooch yáx̲ nasné!",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Straighten it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ yaa datóowch",
                                    "url": "wooch yáx̲ yaa datóowch",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "math",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_49",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Yuck!",
                "tlingit": [
                    [
                        [
                            "Éee!",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_50",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Whew!",
                "tlingit": [
                    [
                        [
                            "Xwéi!",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_51",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Well!",
                "tlingit": [
                    [
                        [
                            "Háa'!",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Há'!",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "1_52",
                        "J"
                    ],
                    [
                        "1_53",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Well (surprise).",
                "tlingit": [
                    [
                        [
                            "Haaw.",
                            [
                                {
                                    "tlingit": "haaw",
                                    "url": "haaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bough, branch with needles on it, especially of hemlock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_54",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That's what you get.",
                "tlingit": [
                    [
                        [
                            "Hachgwá'.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Chgwá'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "1_55",
                        "N"
                    ],
                    [
                        "1_56",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My gosh.",
                "tlingit": [
                    [
                        [
                            "Aganáa!",
                            [
                                {
                                    "tlingit": "Aganáa!",
                                    "url": "Aganáa!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Oh no!; Yikes!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_57",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm scared.",
                "tlingit": [
                    [
                        [
                            "Atsganée!",
                            [
                                {
                                    "tlingit": "atsganée",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Scary!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_58",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "word, speech, language",
                "tlingit": [
                    [
                        [
                            "yoo x̲'atánk",
                            [
                                {
                                    "tlingit": "yoo x̲'atánk",
                                    "url": "yoo x̲'atánk",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "speech, talk; language; word, phrase, sentence, or discourse",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "kashóok' yoo x̲'atánk",
                                    "url": "kashóok' yoo x̲'atánk",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "email",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a káa k̲ududziteeyi yoo x̲'atánk",
                                    "url": "a káa k̲ududziteeyi yoo x̲'atánk",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "law, words one lives by",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yoo",
                                    "url": "yoo",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "back and forth; to and fro; up and down",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_59",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Tlingit language",
                "tlingit": [
                    [
                        [
                            "Lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yoo x̲'atángi",
                            [
                                {
                                    "tlingit": "du yoo x̲'atángi",
                                    "url": "yoo x̲'atánk",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its speech, talk; language; word, phrase, sentence, or discourse",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yoo",
                                    "url": "yoo",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "back and forth; to and fro; up and down",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_60",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Haida language",
                "tlingit": [
                    [
                        [
                            "Deikeenaa",
                            [
                                {
                                    "tlingit": "Deikeenaa",
                                    "url": "Deikeenaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Haida",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yoo x̲'atángi",
                            [
                                {
                                    "tlingit": "du yoo x̲'atángi",
                                    "url": "yoo x̲'atánk",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its speech, talk; language; word, phrase, sentence, or discourse",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yoo",
                                    "url": "yoo",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "back and forth; to and fro; up and down",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_61",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you hear me?",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iya.áx̲ch",
                            [
                                {
                                    "tlingit": "aawa.áx̲",
                                    "url": "aawa.áx̲",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he heard it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_62",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you understand me?",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'eeya.áx̲ch",
                            [],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_63",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you understand Tlingit?",
                "tlingit": [
                    [
                        [
                            "Lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ágé",
                            [
                                {
                                    "tlingit": "ágé",
                                    "url": "ágé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[focus + interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'eeya.áx̲ch?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_64",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Yes, I understand Tlingit.",
                "tlingit": [
                    [
                        [
                            "Aaá,",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'ax̲a.áx̲ch.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_65",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Yes, a little.",
                "tlingit": [
                    [
                        [
                            "Aaá,",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ch'a yéi",
                            [
                                {
                                    "tlingit": "ch'a yéi",
                                    "url": "ch'a yéi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "ordinary, usual",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "ch'a",
                                    "url": "ch'a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "the very, just",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "gugéink'.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Aaá,",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ch'a yéi",
                            [
                                {
                                    "tlingit": "ch'a yéi",
                                    "url": "ch'a yéi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "ordinary, usual",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "ch'a",
                                    "url": "ch'a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "the very, just",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "gugéik'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "1_66",
                        "J"
                    ],
                    [
                        "1_67",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm learning Tlingit.",
                "tlingit": [
                    [
                        [
                            "Lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sh",
                            [
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tukdlig̲éi",
                                    "url": "sh tukdlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo altóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is studying it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "tux̲altóow.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_68",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What do you call this (in Tlingit)?",
                "tlingit": [
                    [
                        [
                            "Wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "duwasáakw",
                            [
                                {
                                    "tlingit": "yéi aawasáakw",
                                    "url": "yéi aawasáakw",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he called him/her/it that (name)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yáat'aa",
                            [
                                {
                                    "tlingit": "yáat'aa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "prnaa"
                                    ],
                                    "english": "this one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "(Lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éináx̲)?",
                            [
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "perlative"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'éináx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "duwasáakw",
                            [
                                {
                                    "tlingit": "yéi aawasáakw",
                                    "url": "yéi aawasáakw",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he called him/her/it that (name)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yáat'át",
                            [
                                {
                                    "tlingit": "yáat'át",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "prnat"
                                    ],
                                    "english": "this (thing)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "(Lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éináx̲)?",
                            [
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "perlative"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'éináx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "duwasáakw",
                            [
                                {
                                    "tlingit": "yéi aawasáakw",
                                    "url": "yéi aawasáakw",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he called him/her/it that (name)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yáat'ít",
                            [],
                            []
                        ],
                        [
                            "(Lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éináx̲)?",
                            [
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "perlative"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'éináx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "1_69",
                        "N"
                    ],
                    [
                        "1_70",
                        "J"
                    ],
                    [
                        "1_71",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "How do you say \"table\" (in Tlingit)?",
                "tlingit": [
                    [
                        [
                            "Wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "duwasáakw",
                            [
                                {
                                    "tlingit": "yéi aawasáakw",
                                    "url": "yéi aawasáakw",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he called him/her/it that (name)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "\"table\"",
                            [],
                            []
                        ],
                        [
                            "(Lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éináx̲)?",
                            [
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "perlative"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'éináx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_72",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "I know (it).",
                "tlingit": [
                    [
                        [
                            "X̲wasikóo.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_73",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I don't know.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲wasakú.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_74",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you [singular] know?",
                "tlingit": [
                    [
                        [
                            "Yisikóo",
                            [
                                {
                                    "tlingit": "awsikóo",
                                    "url": "awsikóo",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he knows it; s/he learned it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_75",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you [plural] know?",
                "tlingit": [
                    [
                        [
                            "Yeeysikóo",
                            [
                                {
                                    "tlingit": "awsikóo",
                                    "url": "awsikóo",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he knows it; s/he learned it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_76",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We know.",
                "tlingit": [
                    [
                        [
                            "Wutusikóo.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_77",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We don't know.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wutusakú.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_78",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he knows.",
                "tlingit": [
                    [
                        [
                            "Awsikóo.",
                            [
                                {
                                    "tlingit": "awsikóo",
                                    "url": "awsikóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he knows it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_79",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he doesn't know.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "awuskú.",
                            [
                                {
                                    "tlingit": "awsikóo",
                                    "url": "awsikóo",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he knows it; s/he learned it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_80",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They know.",
                "tlingit": [
                    [
                        [
                            "Has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "awsikóo.",
                            [
                                {
                                    "tlingit": "awsikóo",
                                    "url": "awsikóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he knows it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_81",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They don't know.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "awuskú.",
                            [
                                {
                                    "tlingit": "awsikóo",
                                    "url": "awsikóo",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he knows it; s/he learned it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_82",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I see (saw / caught sight of) it.",
                "tlingit": [
                    [
                        [
                            "X̲wasiteen.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_83",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I didn't see it.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲wasateen.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_84",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Did you [singular] see it?",
                "tlingit": [
                    [
                        [
                            "Yisiteen",
                            [],
                            []
                        ],
                        [
                            "ágé?",
                            [
                                {
                                    "tlingit": "ágé",
                                    "url": "ágé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[focus + interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_85",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Did you [plural] see it?",
                "tlingit": [
                    [
                        [
                            "Yeeysiteen",
                            [],
                            []
                        ],
                        [
                            "ágé?",
                            [
                                {
                                    "tlingit": "ágé",
                                    "url": "ágé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[focus + interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_86",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Didn't you [plural] see it?",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ák.wé",
                            [
                                {
                                    "tlingit": "ák.wé",
                                    "url": "ák.wé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeeysateen?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_87",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We saw it.",
                "tlingit": [
                    [
                        [
                            "Wutusiteen.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_88",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We didn't see it.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wutusateen.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_89",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he saw it.",
                "tlingit": [
                    [
                        [
                            "Awsiteen.",
                            [
                                {
                                    "tlingit": "awsiteen",
                                    "url": "awsiteen",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sees it; s/he saw it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_90",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he didn't see it.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "awusteen.",
                            [
                                {
                                    "tlingit": "awsiteen",
                                    "url": "awsiteen",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he sees it; s/he saw it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "awsiteen",
                                    "url": "awsiteen",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he sees it; s/he saw it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_91",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They saw it.",
                "tlingit": [
                    [
                        [
                            "Has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "awsiteen.",
                            [
                                {
                                    "tlingit": "awsiteen",
                                    "url": "awsiteen",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sees it; s/he saw it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_92",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They didn't see it.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "awusteen.",
                            [
                                {
                                    "tlingit": "awsiteen",
                                    "url": "awsiteen",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he sees it; s/he saw it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "awsiteen",
                                    "url": "awsiteen",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he sees it; s/he saw it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_93",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "There is no single word in Tlingit for \"please.\" Instead, there are ways of modifying the grammar to \"soften\" the request and make it more polite. These will be included in the phrases throughout the book. Also, there was often no traditional word for \"You're welcome.\""
            },
            {
                "type": "ex",
                "english": "Thank you.",
                "tlingit": [
                    [
                        [
                            "Gunalchéesh.",
                            [
                                {
                                    "tlingit": "Gunalchéesh!",
                                    "url": "Gunalchéesh!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Thank you!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_94",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Thank you very much.",
                "tlingit": [
                    [
                        [
                            "Aatlein",
                            [
                                {
                                    "tlingit": "aatlein",
                                    "url": "aatlein",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "much, lots of, really",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gunalchéesh.",
                            [
                                {
                                    "tlingit": "Gunalchéesh!",
                                    "url": "Gunalchéesh!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Thank you!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_95",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You're welcome. (lit. \"yes\")",
                "tlingit": [
                    [
                        [
                            "Aaá.",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲áa",
                            [
                                {
                                    "tlingit": "x̲áa",
                                    "url": "x̲á",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "you see",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲áa",
                                    "url": "x̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "war party, attacking force of warriors or soldiers; army",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Yak'éi",
                            [
                                {
                                    "tlingit": "yak'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲á.",
                            [
                                {
                                    "tlingit": "x̲á",
                                    "url": "x̲á",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "you see",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "X̲á!",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Eat it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "1_96",
                        "J"
                    ],
                    [
                        "1_97",
                        "N"
                    ],
                    [
                        "1_98",
                        "J"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Traditionally, it was quite in order to leave silently after visiting, without any formal farewell. As with greetings, because of the influence of Euro-American culture, some closing expression is now commonly used, corresponding to English \"Goodbye.\" Sometimes people say \"Gunalchéesh.\""
            },
            {
                "type": "ex",
                "english": "I'm going now.",
                "tlingit": [
                    [
                        [
                            "Dei",
                            [
                                {
                                    "tlingit": "dei",
                                    "url": "de",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "already, (by) now",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dei",
                                    "url": "dei",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "path, trail; road, street",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kk̲wagóot.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Kk̲wagóot",
                            [],
                            []
                        ],
                        [
                            "dei.",
                            [
                                {
                                    "tlingit": "dei",
                                    "url": "de",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "already, (by) now",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dei",
                                    "url": "dei",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "path, trail; road, street",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "1_99",
                        "N"
                    ],
                    [
                        "1_100",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Fine.",
                "tlingit": [
                    [
                        [
                            "Yak'éi.",
                            [
                                {
                                    "tlingit": "yak'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_101",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Come (here) again.",
                "tlingit": [
                    [
                        [
                            "Tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haagú.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_102",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do come again.",
                "tlingit": [
                    [
                        [
                            "Tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haagú",
                            [],
                            []
                        ],
                        [
                            "x'wán.",
                            [
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boot(s)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "be sure to",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_103",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Come (to us) again.",
                "tlingit": [
                    [
                        [
                            "Tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲ánt",
                            [
                                {
                                    "tlingit": "du x̲ánt",
                                    "url": "du x̲án",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "next to him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gú.",
                            [
                                {
                                    "tlingit": "Át gú!",
                                    "url": "át uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Daak gú!",
                                    "url": "daak uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Walk into the open!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Gági gú!",
                                    "url": "gági uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Emerge!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_104",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do come see us again.",
                "tlingit": [
                    [
                        [
                            "Tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲ánt",
                            [
                                {
                                    "tlingit": "du x̲ánt",
                                    "url": "du x̲án",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "next to him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gú",
                            [
                                {
                                    "tlingit": "Át gú!",
                                    "url": "át uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Daak gú!",
                                    "url": "daak uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Walk into the open!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Gági gú!",
                                    "url": "gági uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Emerge!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x'wán.",
                            [
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boot(s)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "be sure to",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_105",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'll come again.",
                "tlingit": [
                    [
                        [
                            "Tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haadéi",
                            [
                                {
                                    "tlingit": "haadéi",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "allative"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kk̲wagóot.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "1_106",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Let's go!",
                "tlingit": [
                    [
                        [
                            "Nax̲too.aat.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Nax̲too.aadí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "1_107",
                        "N"
                    ],
                    [
                        "1_108",
                        "J"
                    ]
                ]
            }
        ]
    },
    "2": {
        "title": "General commands: Home, classroom, preschool, camp, hygene, discipline",
        "content": [
            {
                "type": "ex",
                "english": "Come here. [singular]",
                "tlingit": [
                    [
                        [
                            "Haagú.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Come here. [plural]",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yi.á.",
                            [
                                {
                                    "tlingit": "Át yi.á!",
                                    "url": "át has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all walk there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Gági yi.á!",
                                    "url": "gági has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all emerge!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Áa kei yi.á!",
                                    "url": "áa kei (ha)s uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all walk up there!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Come in. [singular]",
                "tlingit": [
                    [
                        [
                            "Neil",
                            [
                                {
                                    "tlingit": "neil",
                                    "url": "neil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "home",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "neil",
                                    "url": "neil~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "inside, into the house, home",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "neil~",
                                    "url": "neil~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "inside, into the house, home",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gú.",
                            [
                                {
                                    "tlingit": "Át gú!",
                                    "url": "át uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Daak gú!",
                                    "url": "daak uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Walk into the open!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Gági gú!",
                                    "url": "gági uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Emerge!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Come in. [plural]",
                "tlingit": [
                    [
                        [
                            "Neil",
                            [
                                {
                                    "tlingit": "neil",
                                    "url": "neil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "home",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "neil",
                                    "url": "neil~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "inside, into the house, home",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "neil~",
                                    "url": "neil~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "inside, into the house, home",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yi.á.",
                            [
                                {
                                    "tlingit": "Át yi.á!",
                                    "url": "át has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all walk there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Gági yi.á!",
                                    "url": "gági has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all emerge!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Áa kei yi.á!",
                                    "url": "áa kei (ha)s uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all walk up there!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Come here now.",
                "tlingit": [
                    [
                        [
                            "Haakw",
                            [],
                            []
                        ],
                        [
                            "déi.",
                            [
                                {
                                    "tlingit": "déi",
                                    "url": "déi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "now, this time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Tláakw",
                            [
                                {
                                    "tlingit": "tláakw",
                                    "url": "tláakw",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "fast",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yi.á.",
                            [
                                {
                                    "tlingit": "Át yi.á!",
                                    "url": "át has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all walk there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Gági yi.á!",
                                    "url": "gági has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all emerge!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Áa kei yi.á!",
                                    "url": "áa kei (ha)s uwa.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all walk up there!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_5",
                        "N"
                    ],
                    [
                        "2_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Go (quickly). [singular]",
                "tlingit": [
                    [
                        [
                            "Tláakw",
                            [
                                {
                                    "tlingit": "tláakw",
                                    "url": "tláakw",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "fast",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nagú.",
                            [
                                {
                                    "tlingit": "Át nagú!",
                                    "url": "át woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Walk around there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Áx̲ nagú!",
                                    "url": "áx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Walk along it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aadé nagú!",
                                    "url": "aadé woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A nák̲ nagú!",
                                    "url": "a nák̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Leave it behind!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aadáx̲ nagú!",
                                    "url": "aadáx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Leave there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aag̲áa nagú!",
                                    "url": "aag̲áa woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go get it!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Go (quickly). [plural]",
                "tlingit": [
                    [
                        [
                            "Tláakw",
                            [
                                {
                                    "tlingit": "tláakw",
                                    "url": "tláakw",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "fast",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nay.á.",
                            [
                                {
                                    "tlingit": "Nay.á!",
                                    "url": "has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all walk!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Go over there. [plural]",
                "tlingit": [
                    [
                        [
                            "Yóode",
                            [
                                {
                                    "tlingit": "yóode",
                                    "url": "yóot~",
                                    "tags": [
                                        "Adverb",
                                        "allative"
                                    ],
                                    "english": "away, off (to someplace indefinite)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóode",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative",
                                        "allative"
                                    ],
                                    "english": "toward yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nay.á.",
                            [
                                {
                                    "tlingit": "Nay.á!",
                                    "url": "has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all walk!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Go over there. [singular]",
                "tlingit": [
                    [
                        [
                            "Yóode",
                            [
                                {
                                    "tlingit": "yóode",
                                    "url": "yóot~",
                                    "tags": [
                                        "Adverb",
                                        "allative"
                                    ],
                                    "english": "away, off (to someplace indefinite)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóode",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative",
                                        "allative"
                                    ],
                                    "english": "toward yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nagú.",
                            [
                                {
                                    "tlingit": "Át nagú!",
                                    "url": "át woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Walk around there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Áx̲ nagú!",
                                    "url": "áx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Walk along it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aadé nagú!",
                                    "url": "aadé woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A nák̲ nagú!",
                                    "url": "a nák̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Leave it behind!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aadáx̲ nagú!",
                                    "url": "aadáx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Leave there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aag̲áa nagú!",
                                    "url": "aag̲áa woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go get it!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Go outside. [singular]",
                "tlingit": [
                    [
                        [
                            "Gáande",
                            [
                                {
                                    "tlingit": "gáan",
                                    "url": "gáan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "outdoors; outside",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dé(i) ~ -de(i)",
                                    "url": "-dé(i) ~ -de(i)",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "to, toward; until; in the manner of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "nagú.",
                            [
                                {
                                    "tlingit": "Át nagú!",
                                    "url": "át woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Walk around there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Áx̲ nagú!",
                                    "url": "áx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Walk along it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aadé nagú!",
                                    "url": "aadé woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A nák̲ nagú!",
                                    "url": "a nák̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Leave it behind!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aadáx̲ nagú!",
                                    "url": "aadáx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Leave there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aag̲áa nagú!",
                                    "url": "aag̲áa woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go get it!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Get in (car or boat).",
                "tlingit": [
                    [
                        [
                            "Yaax̲ g̲agú.",
                            [
                                {
                                    "tlingit": "Yaax̲ g̲agú!",
                                    "url": "yaax̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go aboard!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yaax̲",
                                    "url": "yaax̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "into a boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaax̲",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "pertingent"
                                    ],
                                    "english": "along here",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Get out (of car or boat).",
                "tlingit": [
                    [
                        [
                            "Yei",
                            [
                                {
                                    "tlingit": "yei",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gú.",
                            [
                                {
                                    "tlingit": "Át gú!",
                                    "url": "át uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Daak gú!",
                                    "url": "daak uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Walk into the open!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Gági gú!",
                                    "url": "gági uwagút",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Emerge!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Get on board.",
                "tlingit": [
                    [
                        [
                            "A",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yíkde",
                            [
                                {
                                    "tlingit": "yíkde",
                                    "url": "a yík",
                                    "tags": [
                                        "RelationalNoun",
                                        "allative"
                                    ],
                                    "english": "into it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nagú.",
                            [
                                {
                                    "tlingit": "Át nagú!",
                                    "url": "át woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Walk around there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Áx̲ nagú!",
                                    "url": "áx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Walk along it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aadé nagú!",
                                    "url": "aadé woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A nák̲ nagú!",
                                    "url": "a nák̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Leave it behind!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aadáx̲ nagú!",
                                    "url": "aadáx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Leave there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aag̲áa nagú!",
                                    "url": "aag̲áa woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go get it!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Yaax̲ g̲agú.",
                            [
                                {
                                    "tlingit": "Yaax̲ g̲agú!",
                                    "url": "yaax̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go aboard!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yaax̲",
                                    "url": "yaax̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "into a boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaax̲",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "pertingent"
                                    ],
                                    "english": "along here",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_14",
                        "J"
                    ],
                    [
                        "2_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Sit down. [singular]",
                "tlingit": [
                    [
                        [
                            "G̲anú.",
                            [
                                {
                                    "tlingit": "g̲anú!",
                                    "url": "woonook",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sit down!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Sit down. [plural]",
                "tlingit": [
                    [
                        [
                            "G̲ayk̲í.",
                            [
                                {
                                    "tlingit": "G̲ayk̲í!",
                                    "url": "has wook̲ee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all sit down!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Stand up. [singular]",
                "tlingit": [
                    [
                        [
                            "Gidaan.",
                            [
                                {
                                    "tlingit": "Gidaan! / Gidahaan!",
                                    "url": "wudihaan",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Stand up!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Gidahaan.",
                            [
                                {
                                    "tlingit": "Gidaan! / Gidahaan!",
                                    "url": "wudihaan",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Stand up!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_18",
                        "J"
                    ],
                    [
                        "2_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Stand up. [plural]",
                "tlingit": [
                    [
                        [
                            "Gaydanaak̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Sit up! [singular]",
                "tlingit": [
                    [
                        [
                            "Kindachoon",
                            [],
                            []
                        ],
                        [
                            "nanú!",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Sit up. [plural]",
                "tlingit": [
                    [
                        [
                            "Kindachoon",
                            [],
                            []
                        ],
                        [
                            "nayk̲í.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Sit up now!",
                "tlingit": [
                    [
                        [
                            "Kindachoon",
                            [],
                            []
                        ],
                        [
                            "nanú",
                            [],
                            []
                        ],
                        [
                            "déi!",
                            [
                                {
                                    "tlingit": "déi",
                                    "url": "déi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "now, this time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Grab (hold of) him/her. [singular]",
                "tlingit": [
                    [
                        [
                            "Gasháat.",
                            [
                                {
                                    "tlingit": "Gasháat!",
                                    "url": "aawasháat",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Catch it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_24",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Grab (hold of) him/her. [plural]",
                "tlingit": [
                    [
                        [
                            "Gaysháat.",
                            [
                                {
                                    "tlingit": "Gaysháat!",
                                    "url": "aawasháat",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all catch it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Buy it. [singular]",
                "tlingit": [
                    [
                        [
                            "Na.óo.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_26",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Buy it. [plural]",
                "tlingit": [
                    [
                        [
                            "Nay.óo.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_27",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Get up! [singular] (out of bed; wake up)",
                "tlingit": [
                    [
                        [
                            "Sheedanú!",
                            [
                                {
                                    "tlingit": "Sheedanú!",
                                    "url": "shawdinúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Get up!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_28",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Get up now!",
                "tlingit": [
                    [
                        [
                            "Sheedanu",
                            [],
                            []
                        ],
                        [
                            "déi!",
                            [
                                {
                                    "tlingit": "déi",
                                    "url": "déi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "now, this time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Sheedan",
                            [],
                            []
                        ],
                        [
                            "déi!",
                            [
                                {
                                    "tlingit": "déi",
                                    "url": "déi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "now, this time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Sheenú!",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_29",
                        "N"
                    ],
                    [
                        "2_30",
                        "J"
                    ],
                    [
                        "2_31",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Sheendéi!",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Shaydak̲í!",
                            [
                                {
                                    "tlingit": "Shaydak̲í!",
                                    "url": "has shawdik̲ée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all get up!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Shaydak̲í",
                            [
                                {
                                    "tlingit": "Shaydak̲í!",
                                    "url": "has shawdik̲ée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all get up!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "déi",
                            [
                                {
                                    "tlingit": "déi",
                                    "url": "déi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "now, this time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Shaydak̲é!",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Shaydak̲é",
                            [],
                            []
                        ],
                        [
                            "déi!",
                            [
                                {
                                    "tlingit": "déi",
                                    "url": "déi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "now, this time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_32",
                        "J"
                    ],
                    [
                        "2_33",
                        "N"
                    ],
                    [
                        "2_34",
                        "J"
                    ],
                    [
                        "2_35",
                        "N"
                    ],
                    [
                        "2_36",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Wash your hands. [singular]",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jín",
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "na.óos'.",
                            [
                                {
                                    "tlingit": "Na.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Jinida.óos'.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Jeenda.óos'.",
                            [
                                {
                                    "tlingit": "aawa.óos'",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he washed it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aawa.óos'",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he washed it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_37",
                        "N"
                    ],
                    [
                        "2_38",
                        "J"
                    ],
                    [
                        "2_39",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Comb your hair. [singular]",
                "tlingit": [
                    [
                        [
                            "Shakg̲eesyá.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_40",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Comb your hair. [plural]",
                "tlingit": [
                    [
                        [
                            "Shakax̲yisyá.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_41",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Sit still. [singular]",
                "tlingit": [
                    [
                        [
                            "Tliyéi",
                            [
                                {
                                    "tlingit": "tliyéi yéi nateech",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he becomes still (every time)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "g̲anú.",
                            [
                                {
                                    "tlingit": "g̲anú!",
                                    "url": "woonook",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sit down!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Tleiyéi",
                            [
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi teex̲",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is still (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi wootee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it became still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi kg̲watée",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will be still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Tleiyéi yéi inatí!",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Be still!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Tleiyéi yéi yee natí!",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be still!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "g̲anú.",
                            [
                                {
                                    "tlingit": "g̲anú!",
                                    "url": "woonook",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sit down!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Tliyéix'",
                            [],
                            []
                        ],
                        [
                            "g̲anú.",
                            [
                                {
                                    "tlingit": "g̲anú!",
                                    "url": "woonook",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sit down!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_42",
                        "J"
                    ],
                    [
                        "2_43",
                        "N"
                    ],
                    [
                        "2_44",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Tleiyéix'",
                            [],
                            []
                        ],
                        [
                            "g̲anú.",
                            [
                                {
                                    "tlingit": "g̲anú!",
                                    "url": "woonook",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sit down!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_45",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you want to use the bathroom?",
                "tlingit": [
                    [
                        [
                            "Gánde",
                            [
                                {
                                    "tlingit": "gánde nagoodí",
                                    "url": "gánde nagoodí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "feces",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gánde nagoodí",
                                    "url": "gánde nagoodí",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its feces",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ituwatee?",
                            [
                                {
                                    "tlingit": "yéi tuwatee",
                                    "url": "yéi tuwatee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he feels that way; s/he wants to do it; s/he feels like doing it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi tuwatee",
                                    "url": "yéi tuwatee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he feels that way; s/he wants to do it; s/he feels like doing it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_46",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I want to go to the bathroom.",
                "tlingit": [
                    [
                        [
                            "Gánde",
                            [
                                {
                                    "tlingit": "gánde nagoodí",
                                    "url": "gánde nagoodí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "feces",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gánde nagoodí",
                                    "url": "gánde nagoodí",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its feces",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwatee.",
                            [
                                {
                                    "tlingit": "yéi tuwatee",
                                    "url": "yéi tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feels that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Gándei",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwatee.",
                            [
                                {
                                    "tlingit": "yéi tuwatee",
                                    "url": "yéi tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feels that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_47",
                        "N"
                    ],
                    [
                        "2_48",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "May I go?",
                "tlingit": [
                    [
                        [
                            "Nak̲agoodí",
                            [],
                            []
                        ],
                        [
                            "kwshé?",
                            [
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "Eh?; I wonder",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshéi",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "maybe; I'm not sure; [expression of uncertainly]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Nak̲agoodí",
                            [],
                            []
                        ],
                        [
                            "kwshá?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_49",
                        "N"
                    ],
                    [
                        "2_50",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Be quiet. [singular]",
                "tlingit": [
                    [
                        [
                            "Sh eelk'átl'.",
                            [
                                {
                                    "tlingit": "Sh eelk'átl'!",
                                    "url": "Sh eelk'átl'!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Shut up!; Be quiet!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "A kát sh eelgás'!",
                                    "url": "a kát sh wudligás'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Leap on it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh k̲'alyélx̲",
                                    "url": "sh k̲'awdliyél",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lies (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh wudik̲'ék'w",
                                    "url": "sh wudik̲'ék'w",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cut himself/herself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kadax'áshti hít",
                                    "url": "sh kadax'áshti hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sawmill",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh káa x̲'awdigáx'",
                                    "url": "sh káa x̲'awdigáx'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he prayed",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_51",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Be quiet. [plural]",
                "tlingit": [
                    [
                        [
                            "Sh",
                            [
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tukdlig̲éi",
                                    "url": "sh tukdlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo altóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is studying it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yeelk'átl'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_52",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Listen. [singular]",
                "tlingit": [
                    [
                        [
                            "K̲inees.aax̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_53",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Listen. [plural]",
                "tlingit": [
                    [
                        [
                            "K̲inayis.aax̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_54",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Listen to me. [singular]",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séide",
                            [],
                            []
                        ],
                        [
                            "k̲inees.aax̲.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Ax̲ x̲'éide",
                            [
                                {
                                    "tlingit": "ax̲ x̲'éide yakg̲was.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will kiss me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éide yaa yanas.éin",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is kissing me",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'éide",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "allative"
                                    ],
                                    "english": "toward its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide gux̲sa.áax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will listen to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲uwdzi.aax̲",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listened to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éide akagux̲latéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will lock it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du x̲'éide k̲unas.áx̲ch",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "k̲inees.aax̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_55",
                        "N"
                    ],
                    [
                        "2_56",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Listen to me. [plural]",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séide",
                            [],
                            []
                        ],
                        [
                            "k̲inayis.aax̲.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Ax̲ x̲'éide",
                            [
                                {
                                    "tlingit": "ax̲ x̲'éide yakg̲was.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will kiss me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éide yaa yanas.éin",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is kissing me",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'éide",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "allative"
                                    ],
                                    "english": "toward its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide gux̲sa.áax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will listen to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲uwdzi.aax̲",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listened to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éide akagux̲latéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will lock it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du x̲'éide k̲unas.áx̲ch",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "k̲inayis.aax̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_57",
                        "N"
                    ],
                    [
                        "2_58",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Say it. [singular]",
                "tlingit": [
                    [
                        [
                            "Yéi yanak̲á.",
                            [
                                {
                                    "tlingit": "Yéi yanak̲á!",
                                    "url": "yéi yaawak̲aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Say it!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "Yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wunak̲á.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_59",
                        "N"
                    ],
                    [
                        "2_60",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Say it. [plural]",
                "tlingit": [
                    [
                        [
                            "Yéi yanayk̲á.",
                            [
                                {
                                    "tlingit": "Yéi yanayk̲á!",
                                    "url": "yéi yaawak̲aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all say it!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_61",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Say it again. [singular]",
                "tlingit": [
                    [
                        [
                            "Tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi yanak̲á.",
                            [
                                {
                                    "tlingit": "Yéi yanak̲á!",
                                    "url": "yéi yaawak̲aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Say it!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "Tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wunak̲á.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_62",
                        "J"
                    ],
                    [
                        "2_63",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Say it again. [plural]",
                "tlingit": [
                    [
                        [
                            "Tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi yanayk̲á.",
                            [
                                {
                                    "tlingit": "Yéi yanayk̲á!",
                                    "url": "yéi yaawak̲aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all say it!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_64",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Please say it again.",
                "tlingit": [
                    [
                        [
                            "K'e",
                            [],
                            []
                        ],
                        [
                            "tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi yanak̲á.",
                            [
                                {
                                    "tlingit": "Yéi yanak̲á!",
                                    "url": "yéi yaawak̲aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Say it!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_65",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Please say it again, already.",
                "tlingit": [
                    [
                        [
                            "Tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi yanak̲á",
                            [
                                {
                                    "tlingit": "Yéi yanak̲á!",
                                    "url": "yéi yaawak̲aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Say it!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "déi.",
                            [
                                {
                                    "tlingit": "déi",
                                    "url": "déi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "now, this time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_66",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Ask him/her. [singular]",
                "tlingit": [
                    [
                        [
                            "X̲'anawóos'.",
                            [
                                {
                                    "tlingit": "X̲'anawóos'!",
                                    "url": "ax̲'eiwawóos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Ask him/her!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_67",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Ask him/her. [plural]",
                "tlingit": [
                    [
                        [
                            "X̲'anaywóos'.",
                            [
                                {
                                    "tlingit": "X̲'anaywóos'!",
                                    "url": "ax̲'eiwawóos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all ask him/her!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_68",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "This side of the class.",
                "tlingit": [
                    [
                        [
                            "Yáanax̲.á",
                            [],
                            []
                        ],
                        [
                            "(aax'w).",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_69",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That side of the class.",
                "tlingit": [
                    [
                        [
                            "Héinax̲.á",
                            [
                                {
                                    "tlingit": "hé",
                                    "url": "hé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this/that (over here), the other",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-náx̲",
                                    "url": "-náx̲",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "along, via; including the time of",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "á",
                                    "url": "á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "it; that place, time, reason, fact",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "(aax'w).",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_70",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Everybody.",
                "tlingit": [
                    [
                        [
                            "Ldakát k̲áa.",
                            [
                                {
                                    "tlingit": "ch'a ldakát k̲áa",
                                    "url": "ch'a ldakát k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "everyone, everybody",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ldakát",
                                    "url": "ldakát",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "all; every",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_71",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What's this?",
                "tlingit": [
                    [
                        [
                            "Dáa",
                            [
                                {
                                    "tlingit": "dáa",
                                    "url": "dáa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "weasel",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sáyá?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_72",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What's that?",
                "tlingit": [
                    [
                        [
                            "Dáa",
                            [
                                {
                                    "tlingit": "dáa",
                                    "url": "dáa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "weasel",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sáwé?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_73",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I forgot.",
                "tlingit": [
                    [
                        [
                            "A kát",
                            [
                                {
                                    "tlingit": "a kát",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a kát kawdigán",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát wujix̲ín",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it fell on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát akawlik̲áa",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sewed it on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du kát shutí!",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Blame it on him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát seiwax'ák̲w",
                                    "url": "a kát seiwax'ák̲w",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he forgot",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát sh wudligás'",
                                    "url": "a kát sh wudligás'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it leapt on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát adatéen",
                                    "url": "kát adatéen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is wearing it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "seiwax'ák̲w.",
                            [
                                {
                                    "tlingit": "a kát seiwax'ák̲w",
                                    "url": "a kát seiwax'ák̲w",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he forgot",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_74",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Name it (again). [singular]",
                "tlingit": [
                    [
                        [
                            "(Tsu)",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sá.",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_75",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Name it (again). [plural]",
                "tlingit": [
                    [
                        [
                            "(Tsu)",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yisá.",
                            [
                                {
                                    "tlingit": "Yéi yisá!",
                                    "url": "yéi aawasáa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all name him/her/it that!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_76",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Write it. [singular]",
                "tlingit": [
                    [
                        [
                            "Kashaxít.",
                            [
                                {
                                    "tlingit": "Kashaxít!",
                                    "url": "akawshixít",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Write it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_77",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Write it. [plural]",
                "tlingit": [
                    [
                        [
                            "Kayshaxít.",
                            [
                                {
                                    "tlingit": "Kayshaxít!",
                                    "url": "akawshixít",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all write it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_78",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Read. [singular]",
                "tlingit": [
                    [
                        [
                            "Nidatóow.",
                            [
                                {
                                    "tlingit": "Nidatóow!",
                                    "url": "wuditóow",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Read!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_79",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Read. [plural]",
                "tlingit": [
                    [
                        [
                            "Naydatóow.",
                            [
                                {
                                    "tlingit": "Naydatóow!",
                                    "url": "wuditóow",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all read!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_80",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Read this. [singular]",
                "tlingit": [
                    [
                        [
                            "Natóow",
                            [
                                {
                                    "tlingit": "Natóow!",
                                    "url": "aawatóow",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Read it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáat'aa.",
                            [
                                {
                                    "tlingit": "yáat'aa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "prnaa"
                                    ],
                                    "english": "this one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_81",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Read it. [singular]",
                "tlingit": [
                    [
                        [
                            "Natóow.",
                            [
                                {
                                    "tlingit": "Natóow!",
                                    "url": "aawatóow",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Read it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_82",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Read it. [plural]",
                "tlingit": [
                    [
                        [
                            "Naytóow.",
                            [
                                {
                                    "tlingit": "Naytóow!",
                                    "url": "aawatóow",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all read it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_83",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Read it to me. [singular]",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "een",
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "natóow.",
                            [
                                {
                                    "tlingit": "Natóow!",
                                    "url": "aawatóow",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Read it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_84",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Read it out loud. [singular]",
                "tlingit": [
                    [
                        [
                            "Kéenáx̲",
                            [],
                            []
                        ],
                        [
                            "natóow.",
                            [
                                {
                                    "tlingit": "Natóow!",
                                    "url": "aawatóow",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Read it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_85",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Tell me. [singular]",
                "tlingit": [
                    [
                        [
                            "X̲aan",
                            [],
                            []
                        ],
                        [
                            "kananeek.",
                            [
                                {
                                    "tlingit": "Kananeek!",
                                    "url": "akaawaneek",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Tell about it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_86",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Tell me.[plural]",
                "tlingit": [
                    [
                        [
                            "X̲aan",
                            [],
                            []
                        ],
                        [
                            "kanayneek.",
                            [
                                {
                                    "tlingit": "Kanayneek!",
                                    "url": "akaawaneek",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all tell about it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_87",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Tell me again.",
                "tlingit": [
                    [
                        [
                            "Tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "een",
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kananeek.",
                            [
                                {
                                    "tlingit": "Kananeek!",
                                    "url": "akaawaneek",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Tell about it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aan",
                            [],
                            []
                        ],
                        [
                            "kananeek.",
                            [
                                {
                                    "tlingit": "Kananeek!",
                                    "url": "akaawaneek",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Tell about it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_88",
                        "J"
                    ],
                    [
                        "2_89",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Tell me in Tlingit.",
                "tlingit": [
                    [
                        [
                            "Lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éináx̲",
                            [
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "perlative"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'éináx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aan",
                            [],
                            []
                        ],
                        [
                            "kananeek.",
                            [
                                {
                                    "tlingit": "Kananeek!",
                                    "url": "akaawaneek",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Tell about it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_90",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Go ahead, speak. [singular]",
                "tlingit": [
                    [
                        [
                            "Góok,",
                            [],
                            []
                        ],
                        [
                            "yoo x̲'atán.",
                            [
                                {
                                    "tlingit": "Yoo x̲'atán!",
                                    "url": "yoo x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Talk!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yoo",
                                    "url": "yoo",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "back and forth; to and fro; up and down",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_91",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Go ahead, speak. [plural]",
                "tlingit": [
                    [
                        [
                            "Góok,",
                            [],
                            []
                        ],
                        [
                            "yoo x̲'ayla.á.",
                            [
                                {
                                    "tlingit": "Yoo x̲'ayla.á!",
                                    "url": "yoo (ha)s x̲'awli.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all converse!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yoo",
                                    "url": "yoo",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "back and forth; to and fro; up and down",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_92",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is that right?",
                "tlingit": [
                    [
                        [
                            "Ch'a",
                            [
                                {
                                    "tlingit": "ch'a",
                                    "url": "ch'a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "the very, just",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "a yáx̲",
                            [
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the face of it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yáx̲",
                                    "tags": [
                                        "RelationalPostposition"
                                    ],
                                    "english": "like it, in accordance with it, as much as it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "gaaw yáx̲",
                                    "url": "gaaw yáx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "on time; in time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wudlitl'éit'",
                                    "url": "a yáx̲ wudlitl'éit'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it climbed the face of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Wooch yáx̲ nasné!",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Straighten it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ yaa datóowch",
                                    "url": "wooch yáx̲ yaa datóowch",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "math",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "ák.",
                            [],
                            []
                        ],
                        [
                            "wé?",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_93",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is this right?",
                "tlingit": [
                    [
                        [
                            "Yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "akwshé?",
                            [
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "Eh?; I wonder",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kwshé?",
                            [
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "Eh?; I wonder",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshéi",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "maybe; I'm not sure; [expression of uncertainly]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_94",
                        "N"
                    ],
                    [
                        "2_95",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That's right.",
                "tlingit": [
                    [
                        [
                            "Yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_96",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That's enough!",
                "tlingit": [
                    [
                        [
                            "Déi áwé!",
                            [
                                {
                                    "tlingit": "Déi áwé!",
                                    "url": "Déi áwé!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Stop it!; That's enough!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "déi",
                                    "url": "déi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "now, this time",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_97",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Take it; here, take it.",
                "tlingit": [
                    [
                        [
                            "Ná.",
                            [
                                {
                                    "tlingit": "ná",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "here, take it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_98",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Hand it over.",
                "tlingit": [
                    [
                        [
                            "Haahée.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_99",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Go / go ahead (already).",
                "tlingit": [
                    [
                        [
                            "Góok.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Góok",
                            [],
                            []
                        ],
                        [
                            "déi.",
                            [
                                {
                                    "tlingit": "déi",
                                    "url": "déi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "now, this time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_100",
                        "N"
                    ],
                    [
                        "2_101",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Stop.",
                "tlingit": [
                    [
                        [
                            "Tliyéix'.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Tleiyéix'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_102",
                        "N"
                    ],
                    [
                        "2_103",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Stop now.",
                "tlingit": [
                    [
                        [
                            "Ihí",
                            [
                                {
                                    "tlingit": "Ihí!",
                                    "url": "Ilí!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Don't!; Stop it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dé.",
                            [
                                {
                                    "tlingit": "Natá dé!",
                                    "url": "wootaa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go to sleep now!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ilnétl (dé)!",
                                    "url": "wudlinétl",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Gain weight (now)!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Idusháa (dé)!",
                                    "url": "wuduwasháa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Get married (now)!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Gashgóok (dé)!",
                                    "url": "awshigóok",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Learn how to do it (now)!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Keeshx̲'ál' (dé)!",
                                    "url": "akawshix̲'ál'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Cook the herring eggs (now)!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan ka.ák̲w (dé)!",
                                    "url": "yan akaawa.ák̲w",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Make a decision (now)!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "I daa yaa k̲ushuksagéi (dé)!",
                                    "url": "du daa yaa k̲ushuwsigéi",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Understand (now)!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_104",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wait.",
                "tlingit": [
                    [
                        [
                            "Lí",
                            [
                                {
                                    "tlingit": "Lí!",
                                    "url": "Ilí!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Don't!; Stop it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'é.",
                            [
                                {
                                    "tlingit": "s'é",
                                    "url": "s'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clay; alluvial silt",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "s'é",
                                    "url": "s'é2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "first",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "ihí",
                            [
                                {
                                    "tlingit": "Ihí!",
                                    "url": "Ilí!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Don't!; Stop it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'é.",
                            [
                                {
                                    "tlingit": "s'é",
                                    "url": "s'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clay; alluvial silt",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "s'é",
                                    "url": "s'é2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "first",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_105",
                        "J"
                    ],
                    [
                        "2_106",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Watch. (or \"Look.\")",
                "tlingit": [
                    [
                        [
                            "Latín.",
                            [
                                {
                                    "tlingit": "Latín!",
                                    "url": "awlitín",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Watch him/her/it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_107",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Look here. [singular] (short form)",
                "tlingit": [
                    [
                        [
                            "G̲án.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_108",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Look here. [singular] (long form)",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eelg̲ín.",
                            [
                                {
                                    "tlingit": "aadé awdlig̲ein",
                                    "url": "aadé awdlig̲ein",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it looked there",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eelg̲én.",
                            [
                                {
                                    "tlingit": "aadé awdlig̲ein",
                                    "url": "aadé awdlig̲ein",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it looked there",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_109",
                        "J"
                    ],
                    [
                        "2_110",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Look here. [plural] (long form)",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ayeelg̲ín.",
                            [
                                {
                                    "tlingit": "át awdlig̲ein",
                                    "url": "át awdlig̲ein",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he is looking around; s/he looked around",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ayeelg̲én.",
                            [
                                {
                                    "tlingit": "át awdlig̲ein",
                                    "url": "át awdlig̲ein",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he is looking around; s/he looked around",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_111",
                        "J"
                    ],
                    [
                        "2_112",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Go to sleep. [singular]",
                "tlingit": [
                    [
                        [
                            "Natá.",
                            [
                                {
                                    "tlingit": "Natá dé!",
                                    "url": "wootaa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go to sleep now!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Natá",
                            [
                                {
                                    "tlingit": "Natá dé!",
                                    "url": "wootaa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go to sleep now!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "déi.",
                            [
                                {
                                    "tlingit": "déi",
                                    "url": "déi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "now, this time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Nashtá.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "(firmer tone)",
                    "(in grouchier tone)"
                ],
                "ids": [
                    [
                        "2_113",
                        "J"
                    ],
                    [
                        "2_114",
                        "N"
                    ],
                    [
                        "2_115",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Go to sleep. [plural]",
                "tlingit": [
                    [
                        [
                            "Nayx̲éix'w.",
                            [
                                {
                                    "tlingit": "Nayx̲éix'w!",
                                    "url": "has woox̲éix'w",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all go to bed!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_116",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Hurry, hurry.",
                "tlingit": [
                    [
                        [
                            "Tláakw,",
                            [
                                {
                                    "tlingit": "tláakw",
                                    "url": "tláakw",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "fast",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tláakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_117",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Let's go!",
                "tlingit": [
                    [
                        [
                            "Góok",
                            [],
                            []
                        ],
                        [
                            "déi!",
                            [
                                {
                                    "tlingit": "déi",
                                    "url": "déi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "now, this time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_118",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Shhhh; hush.",
                "tlingit": [
                    [
                        [
                            "Jaaaaa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_119",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Scram! Shoo!",
                "tlingit": [
                    [
                        [
                            "Chúk!",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Júk!",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_120",
                        "J"
                    ],
                    [
                        "2_121",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Don't! (Don't touch; leave it alone.)",
                "tlingit": [
                    [
                        [
                            "Eelí.",
                            [
                                {
                                    "tlingit": "eelí",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Don't",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Ilí.",
                            [
                                {
                                    "tlingit": "Ilí!",
                                    "url": "Ilí!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Don't!; Stop it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Lí.",
                            [
                                {
                                    "tlingit": "Lí!",
                                    "url": "Ilí!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Don't!; Stop it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Ihí.",
                            [
                                {
                                    "tlingit": "Ihí!",
                                    "url": "Ilí!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Don't!; Stop it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_122",
                        "J"
                    ],
                    [
                        "2_123",
                        "N"
                    ],
                    [
                        "2_124",
                        "J"
                    ],
                    [
                        "2_125",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Drink (it).",
                "tlingit": [
                    [
                        [
                            "Idaná.",
                            [
                                {
                                    "tlingit": "Idaná!",
                                    "url": "awdináa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Drink it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_126",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Eat (it).",
                "tlingit": [
                    [
                        [
                            "X̲á!",
                            [
                                {
                                    "tlingit": "X̲á!",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Eat it!",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲á",
                                    "url": "x̲á",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "you see",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_127",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Give me something to eat.",
                "tlingit": [
                    [
                        [
                            "Ax̲ x̲'éix̲ at natí.",
                            [
                                {
                                    "tlingit": "Du x̲'éix̲ at natí!",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed him/her/it!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "Du x̲'éix̲ at naytí!",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all feed him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éix̲ yas.aa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ natí!",
                                    "url": "du x̲'éix̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed it to him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ yaa nadéin",
                                    "url": "du x̲'éix̲ woodaa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is getting used to (the flavor, pronunciation of) it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ sa.aax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éix̲ aklatéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locks it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲ánx' yéi yee natí!",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all stay with him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_128",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Tie up your shoes.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeli",
                            [
                                {
                                    "tlingit": "du téeli",
                                    "url": "téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shoe(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'aksadúx'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_129",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Tie your shoe laces up.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeli",
                            [
                                {
                                    "tlingit": "du téeli",
                                    "url": "téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shoe(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'akadzaasí",
                            [
                                {
                                    "tlingit": "téel x̲'akadzaasí",
                                    "url": "téel x̲'adzaasí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shoelace(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "téel x̲'adzaasí; téel x̲'akadzaasí",
                                    "url": "téel x̲'agudzaasí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shoelace(s)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ksadúx'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_130",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Give me the boots.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jéet",
                            [],
                            []
                        ],
                        [
                            "yéi sané",
                            [
                                {
                                    "tlingit": "Du jeet yéi sané!",
                                    "url": "du jeet yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give them to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "Yan sané!",
                                    "url": "yan awsinéi",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Finish it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sel'x'wán.",
                            [
                                {
                                    "tlingit": "s'él'",
                                    "url": "s'él'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rubber",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boot(s)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_131",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Make a fire now.",
                "tlingit": [
                    [
                        [
                            "Shóot",
                            [
                                {
                                    "tlingit": "shóot awdi.ák",
                                    "url": "shóot awdi.ák",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he built a fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "shóot ooda.aakch",
                                    "url": "shóot awdi.ák",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he builds a fire (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Shóot eeda.ák!",
                                    "url": "shóot awdi.ák",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Build a fire!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Shóot ayda.ák!",
                                    "url": "shóot awdi.ák",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all build a fire!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ida.ák",
                            [
                                {
                                    "tlingit": "shóot awdi.ák",
                                    "url": "shóot awdi.ák",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he built a fire",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "déi.",
                            [
                                {
                                    "tlingit": "déi",
                                    "url": "déi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "now, this time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_132",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Go buy some apples.",
                "tlingit": [
                    [
                        [
                            "X'áax'g̲aa",
                            [],
                            []
                        ],
                        [
                            "neelhoon.",
                            [
                                {
                                    "tlingit": "Neelhoon!",
                                    "url": "wudlihoon",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Go shopping!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_133",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Time to play!",
                "tlingit": [
                    [
                        [
                            "Ash",
                            [
                                {
                                    "tlingit": "ash koolyát",
                                    "url": "ash kawdliyát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is playing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash sinéekw",
                                    "url": "ash wusinéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's making him/her sick; it makes him/her sick",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusitáax'",
                                    "url": "ash wusitáax'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it bit him/her/it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusix̲ék̲",
                                    "url": "ash wusix̲ék̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it kept him/her awake",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wulixwétl",
                                    "url": "awlixwétl",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it made him/her tired",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash kawdliyát",
                                    "url": "ash kawdliyát",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he played",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusinéekw",
                                    "url": "ash wusinéekw",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it made him/her sick",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kulyát",
                            [],
                            []
                        ],
                        [
                            "gaawú",
                            [
                                {
                                    "tlingit": "a gaawú",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its bell",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its drum",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its clock",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá!",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_134",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's time to play outside.",
                "tlingit": [
                    [
                        [
                            "Gáanx'",
                            [
                                {
                                    "tlingit": "gáan",
                                    "url": "gáan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "outdoors; outside",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ash",
                            [
                                {
                                    "tlingit": "ash koolyát",
                                    "url": "ash kawdliyát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is playing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash sinéekw",
                                    "url": "ash wusinéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's making him/her sick; it makes him/her sick",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusitáax'",
                                    "url": "ash wusitáax'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it bit him/her/it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusix̲ék̲",
                                    "url": "ash wusix̲ék̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it kept him/her awake",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wulixwétl",
                                    "url": "awlixwétl",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it made him/her tired",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash kawdliyát",
                                    "url": "ash kawdliyát",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he played",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusinéekw",
                                    "url": "ash wusinéekw",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it made him/her sick",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kulyát",
                            [],
                            []
                        ],
                        [
                            "gaawú",
                            [
                                {
                                    "tlingit": "a gaawú",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its bell",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its drum",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its clock",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_135",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Let's go play.",
                "tlingit": [
                    [
                        [
                            "Ash",
                            [
                                {
                                    "tlingit": "ash koolyát",
                                    "url": "ash kawdliyát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is playing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash sinéekw",
                                    "url": "ash wusinéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's making him/her sick; it makes him/her sick",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusitáax'",
                                    "url": "ash wusitáax'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it bit him/her/it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusix̲ék̲",
                                    "url": "ash wusix̲ék̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it kept him/her awake",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wulixwétl",
                                    "url": "awlixwétl",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it made him/her tired",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash kawdliyát",
                                    "url": "ash kawdliyát",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he played",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusinéekw",
                                    "url": "ash wusinéekw",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it made him/her sick",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kanax̲toolyát.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_136",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Let's go outside.",
                "tlingit": [
                    [
                        [
                            "Gáande",
                            [
                                {
                                    "tlingit": "gáan",
                                    "url": "gáan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "outdoors; outside",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dé(i) ~ -de(i)",
                                    "url": "-dé(i) ~ -de(i)",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "to, toward; until; in the manner of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "nax̲too.aat.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_137",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Let's gather together.",
                "tlingit": [
                    [
                        [
                            "Woosh kaanáx̲",
                            [
                                {
                                    "tlingit": "Woosh kaanáx̲ gayda.á!",
                                    "url": "woosh kaanáx̲ has wudi.aat",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all gather together!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "woosh kaanáx̲ has wudi.aat",
                                    "url": "woosh kaanáx̲ has wudi.aat",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Pl"
                                    ],
                                    "english": "they gathered together",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "woosh kaanáx̲ has gada.átch",
                                    "url": "woosh kaanáx̲ has wudi.aat",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Pl"
                                    ],
                                    "english": "they gather together (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "woosh kaanáx̲ kei (ha)s nada.át",
                                    "url": "woosh kaanáx̲ has wudi.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are beginning to gather together",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "woosh kaanáx̲ kei (ha)s da.átch",
                                    "url": "woosh kaanáx̲ has wudi.aat",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they gather together (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl woosh kaanáx̲ kei yda.átjik̲!",
                                    "url": "woosh kaanáx̲ has wudi.aat",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Pl"
                                    ],
                                    "english": "Don't you all gather together!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "woosh kaanáx̲ kei (ha)s gug̲a.áat",
                                    "url": "woosh kaanáx̲ has wudi.aat",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will gather together",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a kaanáx̲",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "perlative"
                                    ],
                                    "english": "through the surface of it; over it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "gax̲tuda.aat.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_138",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Let's make a circle.",
                "tlingit": [
                    [
                        [
                            "Wooshduwag̲ígin",
                            [],
                            []
                        ],
                        [
                            "nax̲tuda.aat.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_139",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Let's stand in a circle.",
                "tlingit": [
                    [
                        [
                            "Wooshduwag̲igin",
                            [],
                            []
                        ],
                        [
                            "nax̲toonaak̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_140",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Stand in a circle. [plural]",
                "tlingit": [
                    [
                        [
                            "Wooshduwag̲igin",
                            [],
                            []
                        ],
                        [
                            "yan yinák̲.",
                            [
                                {
                                    "tlingit": "Yan yinák̲!",
                                    "url": "yan has uwanák̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all keep standing!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yan~",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yan",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_141",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Hold hands.",
                "tlingit": [
                    [
                        [
                            "Woosh",
                            [
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jín",
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "g̲aylasháat.",
                            [
                                {
                                    "tlingit": "G̲aylasháat!",
                                    "url": "awlisháat",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all hold it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Woosh",
                            [
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jín",
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "g̲ayilsháat.",
                            [
                                {
                                    "tlingit": "awlisháat",
                                    "url": "awlisháat",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he held it; s/he captured it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "2_142",
                        "J"
                    ],
                    [
                        "2_143",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We're getting ready to go to the gym.",
                "tlingit": [
                    [
                        [
                            "Gym-de",
                            [],
                            []
                        ],
                        [
                            "át",
                            [
                                {
                                    "tlingit": "át",
                                    "url": "át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "thing",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wutuwaxoon.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_144",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's your turn.",
                "tlingit": [
                    [
                        [
                            "l",
                            [
                                {
                                    "tlingit": "l",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k̲oowaháa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_145",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You're on. (used for singing only)",
                "tlingit": [
                    [
                        [
                            "Góosh",
                            [],
                            []
                        ],
                        [
                            "wa.éich.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_146",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Face that way. [plural]",
                "tlingit": [
                    [
                        [
                            "Tliyaadé",
                            [],
                            []
                        ],
                        [
                            "áa yax̲",
                            [
                                {
                                    "tlingit": "áa yax̲",
                                    "url": "áa yax̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "turning over",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yax̲",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb",
                                        "pertingent"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yax̲",
                                    "url": "yax̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "to completion",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "áa",
                                    "url": "áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "lake",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yinaak̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_147",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Face me. [plural]",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dayéen",
                            [
                                {
                                    "tlingit": "a dayéen",
                                    "url": "a dayéen",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "facing it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áa yax̲",
                            [
                                {
                                    "tlingit": "áa yax̲",
                                    "url": "áa yax̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "turning over",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yax̲",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb",
                                        "pertingent"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yax̲",
                                    "url": "yax̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "to completion",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "áa",
                                    "url": "áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "lake",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yinaak̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_148",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Face that way. [singular]",
                "tlingit": [
                    [
                        [
                            "Tliyaadé",
                            [],
                            []
                        ],
                        [
                            "áa yax̲ haan.",
                            [
                                {
                                    "tlingit": "yax̲ haan",
                                    "url": "yan uwahán",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he keeps standing (regularly)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "áa yax̲",
                                    "url": "áa yax̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "turning over",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yax̲",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb",
                                        "pertingent"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yax̲",
                                    "url": "yax̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "to completion",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "áa",
                                    "url": "áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "lake",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_149",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Face me. [singular]",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dayéen",
                            [
                                {
                                    "tlingit": "a dayéen",
                                    "url": "a dayéen",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "facing it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áa yax̲ haan.",
                            [
                                {
                                    "tlingit": "yax̲ haan",
                                    "url": "yan uwahán",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he keeps standing (regularly)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "áa yax̲",
                                    "url": "áa yax̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "turning over",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yax̲",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb",
                                        "pertingent"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yax̲",
                                    "url": "yax̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "to completion",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "áa",
                                    "url": "áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "lake",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_150",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Stand in the middle. [singular]",
                "tlingit": [
                    [
                        [
                            "A",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "digiygéi",
                            [],
                            []
                        ],
                        [
                            "yan hán.",
                            [
                                {
                                    "tlingit": "Yan hán!",
                                    "url": "yan uwahán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Keep standing!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yan~",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "hán",
                                    "url": "hán",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is standing",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yan",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "2_151",
                        "N"
                    ]
                ]
            }
        ]
    },
    "3": {
        "title": "Compliments; dating, schmoozing, and sweet talking",
        "content": [
            {
                "type": "ex",
                "english": "You [singular] brushed your teeth well.",
                "tlingit": [
                    [
                        [
                            "K'idéin",
                            [
                                {
                                    "tlingit": "k'idéin",
                                    "url": "k'idéin",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "well",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yee.óos'",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "oox̲.",
                            [
                                {
                                    "tlingit": "du oox̲",
                                    "url": "du oox̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her tooth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "oox̲ katság̲aa",
                                    "url": "oox̲ katság̲aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "toothpick",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ layeix̲í",
                                    "url": "k̲aa oox̲ layeix̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ yei daanéiyi",
                                    "url": "k̲aa oox̲ yei daanéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ leyeix̲í",
                                    "url": "k̲aa oox̲ layeix̲í",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You [plural] brushed your teeth well.",
                "tlingit": [
                    [
                        [
                            "K'idéin",
                            [
                                {
                                    "tlingit": "k'idéin",
                                    "url": "k'idéin",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "well",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeey.óos'",
                            [],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "oox̲.",
                            [
                                {
                                    "tlingit": "du oox̲",
                                    "url": "du oox̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her tooth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "oox̲ katság̲aa",
                                    "url": "oox̲ katság̲aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "toothpick",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ layeix̲í",
                                    "url": "k̲aa oox̲ layeix̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ yei daanéiyi",
                                    "url": "k̲aa oox̲ yei daanéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ leyeix̲í",
                                    "url": "k̲aa oox̲ layeix̲í",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You are studying well.",
                "tlingit": [
                    [
                        [
                            "K'idéin",
                            [
                                {
                                    "tlingit": "k'idéin",
                                    "url": "k'idéin",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "well",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sh",
                            [
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tukdlig̲éi",
                                    "url": "sh tukdlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo altóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is studying it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "too",
                            [],
                            []
                        ],
                        [
                            "eetóow.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You're dressed nice.",
                "tlingit": [
                    [
                        [
                            "Yak'éi",
                            [
                                {
                                    "tlingit": "yak'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aadéi",
                            [],
                            []
                        ],
                        [
                            "sh",
                            [
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tukdlig̲éi",
                                    "url": "sh tukdlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo altóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is studying it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "idi.uwu",
                            [],
                            []
                        ],
                        [
                            "yé.",
                            [
                                {
                                    "tlingit": "yé",
                                    "url": "yé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "place; way",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Your shirt is very fancy.",
                "tlingit": [
                    [
                        [
                            "K̲unáx̲",
                            [],
                            []
                        ],
                        [
                            "kalig̲éi",
                            [
                                {
                                    "tlingit": "kalig̲éi",
                                    "url": "kawlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is fancy",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k'oodás'i.",
                            [
                                {
                                    "tlingit": "du k'oodás'i",
                                    "url": "k'oodás'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shirt",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du kakéin k'oodás'i",
                                    "url": "kakéin k'oodás'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its sweater",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du doonyaax̲ k'oodás'i",
                                    "url": "doonyaax̲ k'oodás'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its undershirt",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Your shirt is very cute.",
                "tlingit": [
                    [
                        [
                            "K̲unáx̲",
                            [],
                            []
                        ],
                        [
                            "shaklig̲éi",
                            [
                                {
                                    "tlingit": "shaklig̲éi",
                                    "url": "shaklig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "she is pretty",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k'oodás'i.",
                            [
                                {
                                    "tlingit": "du k'oodás'i",
                                    "url": "k'oodás'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shirt",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du kakéin k'oodás'i",
                                    "url": "kakéin k'oodás'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its sweater",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du doonyaax̲ k'oodás'i",
                                    "url": "doonyaax̲ k'oodás'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its undershirt",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You [singular] are dressed up.",
                "tlingit": [
                    [
                        [
                            "Yán",
                            [
                                {
                                    "tlingit": "yán",
                                    "url": "yán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hemlock",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yán",
                                    "url": "yán2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shore; land",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sh",
                            [
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tukdlig̲éi",
                                    "url": "sh tukdlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo altóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is studying it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yidzinéi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You [plural] are dressed up.",
                "tlingit": [
                    [
                        [
                            "Yán",
                            [
                                {
                                    "tlingit": "yán",
                                    "url": "yán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hemlock",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yán",
                                    "url": "yán2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shore; land",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sh",
                            [
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tukdlig̲éi",
                                    "url": "sh tukdlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo altóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is studying it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yeeydzinéi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We are all dressed up.",
                "tlingit": [
                    [
                        [
                            "Yán",
                            [
                                {
                                    "tlingit": "yán",
                                    "url": "yán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hemlock",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yán",
                                    "url": "yán2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shore; land",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sh",
                            [
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tukdlig̲éi",
                                    "url": "sh tukdlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo altóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is studying it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wutudzinéi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are all dressed up.",
                "tlingit": [
                    [
                        [
                            "Yán",
                            [
                                {
                                    "tlingit": "yán",
                                    "url": "yán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hemlock",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yán",
                                    "url": "yán2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shore; land",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sh wudzinéi.",
                            [
                                {
                                    "tlingit": "yan sh wudzinéi",
                                    "url": "yan sh wudzinéi",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is dressed up",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "sh tukdlig̲éi",
                                    "url": "sh tukdlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh wudigwál",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it rang",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh wudik̲'ék'w",
                                    "url": "sh wudik̲'ék'w",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cut himself/herself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kawdlineek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he told a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh yáa awudanéiyi",
                                    "url": "sh yáa awudanéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "respected person; gentleman; lady",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh k̲'awdliyél",
                                    "url": "sh k̲'awdliyél",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lied",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yan sh usnéich",
                                    "url": "yan sh wudzinéi",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets dressed up (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I will dress up.",
                "tlingit": [
                    [
                        [
                            "Yánde sh",
                            [
                                {
                                    "tlingit": "yánde sh gug̲asnéi",
                                    "url": "yan sh wudzinéi",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will be dressed up",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yánde",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb",
                                        "allative"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yánde yaa sh nasnein",
                                    "url": "yan sh wudzinéi",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's getting dressed up",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi sh tudinook",
                                    "url": "yéi sh tuwdinook",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feels that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa sh kanalník",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is (in the process of) telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a káx̲ sh ilgáas'",
                                    "url": "a kát sh wudligás'",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it leaps on it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh daxash washéen",
                                    "url": "sh daxash washéen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "chainsaw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲oon sh kalneegí",
                                    "url": "k̲oon sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "storyteller; preacher",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "k̲wsanéi.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Yánde sh",
                            [
                                {
                                    "tlingit": "yánde sh gug̲asnéi",
                                    "url": "yan sh wudzinéi",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will be dressed up",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yánde",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb",
                                        "allative"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yánde yaa sh nasnein",
                                    "url": "yan sh wudzinéi",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's getting dressed up",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi sh tudinook",
                                    "url": "yéi sh tuwdinook",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feels that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa sh kanalník",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is (in the process of) telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a káx̲ sh ilgáas'",
                                    "url": "a kát sh wudligás'",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it leaps on it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh daxash washéen",
                                    "url": "sh daxash washéen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "chainsaw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲oon sh kalneegí",
                                    "url": "k̲oon sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "storyteller; preacher",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "kuk̲asanéi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "3_11",
                        "N"
                    ],
                    [
                        "3_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'll put on a tie.",
                "tlingit": [
                    [
                        [
                            "Necktie",
                            [],
                            []
                        ],
                        [
                            "kaadé",
                            [
                                {
                                    "tlingit": "a kaadé",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "allative"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A kaadé yanasxá!",
                                    "url": "a kaadé ayawsixaa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Pour it on there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kaadé kagux̲dagáan",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will shine on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kaadé kg̲washx̲éen",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will fall on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kaadé akagux̲lak̲áa",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will sew it on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du kaadé ashukg̲watée",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will blame it on him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kaadé sakg̲wax'áak̲w",
                                    "url": "a kát seiwax'ák̲w",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will forget",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kk̲wdatsáak̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I love you.",
                "tlingit": [
                    [
                        [
                            "Ix̲six̲án.",
                            [
                                {
                                    "tlingit": "awsix̲án",
                                    "url": "awsix̲án",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he loved him/her/it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We love you.",
                "tlingit": [
                    [
                        [
                            "Itusix̲án.",
                            [
                                {
                                    "tlingit": "awsix̲án",
                                    "url": "awsix̲án",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he loved him/her/it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you love me?",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "isix̲án",
                            [
                                {
                                    "tlingit": "awsix̲án",
                                    "url": "awsix̲án",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he loved him/her/it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he loves him/her.",
                "tlingit": [
                    [
                        [
                            "Asix̲án.",
                            [
                                {
                                    "tlingit": "asix̲án",
                                    "url": "awsix̲án",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he loves him/her/it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We love each other.",
                "tlingit": [
                    [
                        [
                            "Woosh",
                            [
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tudzix̲án.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We don't love each other.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "woosh",
                            [
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toosx̲án.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You're cute.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaklig̲éi.",
                            [
                                {
                                    "tlingit": "shaklig̲éi",
                                    "url": "shaklig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "she is pretty",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he hates him/her.",
                "tlingit": [
                    [
                        [
                            "A",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shik'áan.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he hates me.",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shik'áan.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I hate you.",
                "tlingit": [
                    [
                        [
                            "Ix̲shik'áan.",
                            [
                                {
                                    "tlingit": "awshik'aan",
                                    "url": "awshik'aan",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he hated him/her/it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he respects you.",
                "tlingit": [
                    [
                        [
                            "Ee",
                            [
                                {
                                    "tlingit": "du ee~",
                                    "url": "du ee~",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "him, her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "awoonéi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_24",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he is respectable/honorable.",
                "tlingit": [
                    [
                        [
                            "Sh yáa",
                            [
                                {
                                    "tlingit": "sh yáa awudanéiyi",
                                    "url": "sh yáa awudanéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "respected person; gentleman; lady",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo altóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is studying it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "awudinéi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you want to go to the movies?",
                "tlingit": [
                    [
                        [
                            "Shów-de",
                            [],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwatee?",
                            [
                                {
                                    "tlingit": "yéi tuwatee",
                                    "url": "yéi tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feels that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_26",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you want to go to lunch/dinner?",
                "tlingit": [
                    [
                        [
                            "Satgawsáan",
                            [
                                {
                                    "tlingit": "sitgawsáan",
                                    "url": "sitgawsáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "noon",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "atx̲aayíde",
                            [],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwatee?",
                            [
                                {
                                    "tlingit": "yéi tuwatee",
                                    "url": "yéi tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feels that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Sitgawsáan",
                            [
                                {
                                    "tlingit": "sitgawsáan",
                                    "url": "sitgawsáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "noon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "atx̲aayíde",
                            [],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwatee?",
                            [
                                {
                                    "tlingit": "yéi tuwatee",
                                    "url": "yéi tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feels that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "3_27",
                        "N"
                    ],
                    [
                        "3_28",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her sweetheart",
                "tlingit": [
                    [
                        [
                            "du tseiyí",
                            [
                                {
                                    "tlingit": "du tseiyí",
                                    "url": "du tseiyí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her sweetheart",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_29",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "my sweetheart",
                "tlingit": [
                    [
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tseiyí",
                            [
                                {
                                    "tlingit": "du tseiyí",
                                    "url": "du tseiyí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her sweetheart",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_30",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "S/he is kissing him/her (repeatedly).",
                "tlingit": [
                    [
                        [
                            "A x̲'éix̲ yas.aa.",
                            [
                                {
                                    "tlingit": "ax̲ x̲'éix̲ yas.aa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a x̲'éix̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "at its mouth",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du x̲'éix̲ sa.aax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ aawatee",
                                    "url": "du x̲'éix̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he fed it to him/her/it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ woodaa",
                                    "url": "du x̲'éix̲ woodaa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he got used to (the flavor, pronunciation of) it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ at natí!",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éix̲ aklatéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locks it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du x̲'éix̲ yaa nadéin",
                                    "url": "du x̲'éix̲ woodaa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is getting used to (the flavor, pronunciation of) it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_31",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he is not kissing him/her.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "a x̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit yawdzi.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kissed me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit woos.áaych",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yawus.aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_32",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he kissed him/her.",
                "tlingit": [
                    [
                        [
                            "A x̲'éit yawdzi.áa",
                            [
                                {
                                    "tlingit": "ax̲ x̲'éit yawdzi.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kissed me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yayis.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit woos.áaych",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(or \"S/he is kissing him/her, but only once.\")"
                ],
                "ids": [
                    [
                        "3_33",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "He kissed the ice. (at Olympics)",
                "tlingit": [
                    [
                        [
                            "T'éex'",
                            [
                                {
                                    "tlingit": "t'éex'",
                                    "url": "t'éex'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "ice",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kát",
                            [
                                {
                                    "tlingit": "a kát",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát adatéen",
                                    "url": "kát adatéen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is wearing it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du kát shutí!",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Blame it on him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A kát kalak̲á!",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sew it on it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát kawdigán",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát wujix̲ín",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it fell on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát dul.us'ku át",
                                    "url": "a káa dul.us'ku át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "washboard",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yawdzi.áa.",
                            [
                                {
                                    "tlingit": "ax̲ x̲'éit yawdzi.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kissed me",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_34",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he will kiss him/her.",
                "tlingit": [
                    [
                        [
                            "A x̲'éide",
                            [
                                {
                                    "tlingit": "a x̲'éide",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "allative"
                                    ],
                                    "english": "toward its mouth",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲ x̲'éide yakg̲was.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will kiss me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide gux̲sa.áax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will listen to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲uwdzi.aax̲",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listened to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éide akagux̲latéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will lock it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲ x̲'éide yaa yanas.éin",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is kissing me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲unas.áx̲ch",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yakgwas.áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_35",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he kissed me.",
                "tlingit": [
                    [
                        [
                            "Ax̲ x̲'éit yawdzi.áa.",
                            [
                                {
                                    "tlingit": "ax̲ x̲'éit yawdzi.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kissed me",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Ax̲ x̲'éit yayis.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit woos.áaych",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_36",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I kissed her/him.",
                "tlingit": [
                    [
                        [
                            "Du x̲'éit",
                            [
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yayis.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit yawdzi.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kissed me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit woos.áaych",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yax̲wdzi.áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_37",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We kissed.",
                "tlingit": [
                    [
                        [
                            "Woosh",
                            [
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yawtudzi.áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_38",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We were kissing.",
                "tlingit": [
                    [
                        [
                            "Woosh",
                            [
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yawtus.aayín.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_39",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We didn't kiss.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "woosh",
                            [
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yawtoos.á.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_40",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I didn't kiss her/him.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du x̲'éit",
                            [
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yayis.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit yawdzi.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kissed me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit woos.áaych",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yax̲was.aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_41",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I won't kiss her.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du x̲'éide",
                            [
                                {
                                    "tlingit": "du x̲'éide gux̲sa.áax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will listen to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲uwdzi.aax̲",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listened to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲unas.áx̲ch",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲ukg̲was.áax̲",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will listen to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide yoo k̲udzi.áx̲k",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éide k̲unees.aax̲!",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éide k̲unayis.aax̲!",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'éide",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "allative"
                                    ],
                                    "english": "toward its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éide yakg̲was.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will kiss me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éide akagux̲latéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will lock it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲ x̲'éide yaa yanas.éin",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is kissing me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éide yaa akanaltíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's locking it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yakk̲was.aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_42",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I won't kiss you.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éide",
                            [
                                {
                                    "tlingit": "a x̲'éide",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "allative"
                                    ],
                                    "english": "toward its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide gux̲sa.áax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will listen to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éide yakg̲was.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will kiss me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲uwdzi.aax̲",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listened to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éide akagux̲latéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will lock it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲unas.áx̲ch",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éide yaa akanaltíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's locking it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yakk̲was.aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_43",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Kiss me.",
                "tlingit": [
                    [
                        [
                            "Ax̲ x̲'éit yees.á.",
                            [
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Ax̲ x̲'éit yayis.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akooltéex̲'ch",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he locks it (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit yawdzi.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kissed me",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_44",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Kiss me, honey.",
                "tlingit": [
                    [
                        [
                            "Ax̲ x̲'éit",
                            [
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit yawdzi.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kissed me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit woos.áaych",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yayis.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl ax̲ x̲'éit yees.aayéek̲!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl ax̲ x̲'éit yayis.aayéek̲!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Pl"
                                    ],
                                    "english": "Don't you all kiss me!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yees.ák'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_45",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Let me kiss you.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yak̲as.aayí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_46",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I want to kiss you.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yax̲was.aayí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_47",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I don't want to kiss you.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ushgú",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yax̲was.aayí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_48",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I don't want you to kiss me.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ushgú",
                            [],
                            []
                        ],
                        [
                            "ax̲ x̲'éit",
                            [
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit yawdzi.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kissed me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit woos.áaych",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yayis.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl ax̲ x̲'éit yees.aayéek̲!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl ax̲ x̲'éit yayis.aayéek̲!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Pl"
                                    ],
                                    "english": "Don't you all kiss me!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yayis.aayí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_49",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Don't kiss me.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲ x̲'éix̲",
                            [
                                {
                                    "tlingit": "ax̲ x̲'éix̲ yas.aa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'éix̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "at its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "I x̲'éix̲ nadá!",
                                    "url": "du x̲'éix̲ woodaa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Get used to (the flavor, pronunciation of) it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ natí!",
                                    "url": "du x̲'éix̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed it to him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ sa.aax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ at teex̲",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feeds him/her/it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éix̲ aklatéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locks it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yees.aayéek̲.",
                            [
                                {
                                    "tlingit": "Líl ax̲ x̲'éit yees.aayéek̲!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't kiss me!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_50",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She doesn't want him to kiss her.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du tuwáa",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "ushgú",
                            [],
                            []
                        ],
                        [
                            "du x̲'éit",
                            [
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yayis.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit yawdzi.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kissed me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit woos.áaych",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yawus.aayí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_51",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are kissing (each other, repeatedly).",
                "tlingit": [
                    [
                        [
                            "Wooch",
                            [
                                {
                                    "tlingit": "wooch",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wooch",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éix̲",
                            [
                                {
                                    "tlingit": "a x̲'éix̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "at its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "I x̲'éix̲ nadá!",
                                    "url": "du x̲'éix̲ woodaa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Get used to (the flavor, pronunciation of) it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ natí!",
                                    "url": "du x̲'éix̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed it to him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éix̲ yas.aa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ sa.aax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éix̲ aklatéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locks it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ at teex̲",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feeds him/her/it (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yas.áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_52",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are French kissing.",
                "tlingit": [
                    [
                        [
                            "Has du x̲'é",
                            [
                                {
                                    "tlingit": "du x̲'é",
                                    "url": "du x̲'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mouth",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has du",
                                    "url": "has du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "their [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'é",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "its mouth, opening",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'é yak'éi",
                                    "url": "du x̲'é wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he likes the taste of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du x̲'é wook'éi",
                                    "url": "du x̲'é wook'éi",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he liked the taste of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'é kei kg̲wak'éi",
                                    "url": "du x̲'é wook'éi",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will like the taste of it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "teen",
                            [
                                {
                                    "tlingit": "teen",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "teen",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "teen",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "woosh",
                            [
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'adal'áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_53",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are they stuck together by the mouth already?",
                "tlingit": [
                    [
                        [
                            "Gwál",
                            [
                                {
                                    "tlingit": "gwál",
                                    "url": "gwál",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "perhaps",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "de",
                            [
                                {
                                    "tlingit": "de",
                                    "url": "de",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "already, (by) now",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wooch",
                            [
                                {
                                    "tlingit": "wooch",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wooch",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éide",
                            [
                                {
                                    "tlingit": "a x̲'éide",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "allative"
                                    ],
                                    "english": "toward its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide gux̲sa.áax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will listen to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éide yakg̲was.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will kiss me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲uwdzi.aax̲",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listened to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éide akagux̲latéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will lock it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲unas.áx̲ch",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éide yaa akanaltíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's locking it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k̲udaxát.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "3_54",
                        "J"
                    ]
                ]
            }
        ]
    },
    "4": {
        "title": "Months, seasons, days, time references",
        "content": [
            {
                "type": "text",
                "english": "The traditional Tlingit names of the months are no longer used, and are not clearly understood today. The names varied slightly from community to community. Emmons collected seven different sets of names. Some of the calendars had thirteen months, because the traditional Tlingit calendar was lunar rather than solar. The year began in July, with the return of the fish. The year ended in June, with the thirteenth month added after June. The list below is of the most commonly used names, with a few of the most common variants. For the most detail on the months, see Emmons and de Laguna The Tlingit Indians (University of Washington Press 1991:423-26; 461-63), and for a classroom unit see Dolly Garza Tlingit Moon and Tide Teaching Resource (University of Alaska Sea Grant 1999:27-28)."
            },
            {
                "type": "text",
                "english": "There are several ways to translate the names: salmon moon; moon of salmon; salmon month; month of salmon. The English word \"month\" is also related to \"moon.\""
            },
            {
                "type": "ex",
                "english": "July",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dísi",
                            [
                                {
                                    "tlingit": "k'óox dísi",
                                    "url": "k'óox dísi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Venus",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "At",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gataa",
                            [],
                            []
                        ],
                        [
                            "dísi",
                            [
                                {
                                    "tlingit": "k'óox dísi",
                                    "url": "k'óox dísi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Venus",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Salmon month; when the fish return.",
                    "Fat moon; when the animals are fat."
                ],
                "ids": [
                    [
                        "4_1",
                        "N"
                    ],
                    [
                        "4_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "August",
                "tlingit": [
                    [
                        [
                            "Shaa",
                            [
                                {
                                    "tlingit": "shaa",
                                    "url": "shaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "mountain",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "xeiyí",
                            [],
                            []
                        ],
                        [
                            "dísi",
                            [
                                {
                                    "tlingit": "k'óox dísi",
                                    "url": "k'óox dísi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Venus",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Mountain shadow month; origin unclear, but seems to refer to either berries or snow patches on the mountain sides."
                ],
                "ids": [
                    [
                        "4_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "September",
                "tlingit": [
                    [
                        [
                            "Dis",
                            [
                                {
                                    "tlingit": "dís",
                                    "url": "dís",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "month",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "dís",
                                    "url": "dís",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "month",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yádi",
                            [
                                {
                                    "tlingit": "du yádi",
                                    "url": "du yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her child",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aas yádi",
                                    "url": "aas yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sapling",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tíx' yádi",
                                    "url": "tíx' yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "string",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "dóosh yádi",
                                    "url": "dóosh yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "kitten",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲áat yádi",
                                    "url": "x̲áat yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "whitefish; baby fish; tiny fish",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlék̲w yádi",
                                    "url": "tlék̲w yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raspberry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gawdáan yádi",
                                    "url": "gawdáan yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "colt",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "S'aax̲",
                            [
                                {
                                    "tlingit": "s'aax̲",
                                    "url": "s'aax̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hoary marmot; groundhog, whistler",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "disi",
                            [
                                {
                                    "tlingit": "dís",
                                    "url": "dís",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "month",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "dís",
                                    "url": "dís",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "month",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "S'ax̲",
                            [],
                            []
                        ],
                        [
                            "tláa",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "dísi",
                            [
                                {
                                    "tlingit": "k'óox dísi",
                                    "url": "k'óox dísi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Venus",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Child moon; young moon; little moon. Seems to refer to weaning: \"Weaning month,\" when food is beginning to become scarce and we do with less.",
                    "Ground hog month; when it prepares its nest.",
                    "Mother of ground hog month."
                ],
                "ids": [
                    [
                        "4_4",
                        "J"
                    ],
                    [
                        "4_5",
                        "N"
                    ],
                    [
                        "4_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "October",
                "tlingit": [
                    [
                        [
                            "Dis",
                            [
                                {
                                    "tlingit": "dís",
                                    "url": "dís",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "month",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "dís",
                                    "url": "dís",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "month",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "tlein",
                            [
                                {
                                    "tlingit": "tlein",
                                    "url": "tlein",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "big",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Big moon; when animals are in their prime condition; wild fowl are abundant; people return to winter villages with summer food supplies."
                ],
                "ids": [
                    [
                        "4_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "November",
                "tlingit": [
                    [
                        [
                            "K̲ukahaa",
                            [],
                            []
                        ],
                        [
                            "dís",
                            [
                                {
                                    "tlingit": "dís",
                                    "url": "dís",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "month",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dís",
                                    "url": "dís2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "moon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Digging/scratching moon; when bears dig and prepare their winter dens."
                ],
                "ids": [
                    [
                        "4_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "December",
                "tlingit": [
                    [
                        [
                            "Shaanáx̲",
                            [
                                {
                                    "tlingit": "shaanáx̲",
                                    "url": "shaanáx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "mountain valley; valley",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dis",
                            [
                                {
                                    "tlingit": "dís",
                                    "url": "dís",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "month",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "dís",
                                    "url": "dís",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "month",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Through-the-head month; when hair shows on the seal embryo's head."
                ],
                "ids": [
                    [
                        "4_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "January",
                "tlingit": [
                    [
                        [
                            "T'aawák̲",
                            [
                                {
                                    "tlingit": "t'aawák̲",
                                    "url": "t'aawák̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Canada goose",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dísi",
                            [
                                {
                                    "tlingit": "k'óox dísi",
                                    "url": "k'óox dísi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Venus",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Canada Goose month; when geese fly and call."
                ],
                "ids": [
                    [
                        "4_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "February",
                "tlingit": [
                    [
                        [
                            "S'eek",
                            [
                                {
                                    "tlingit": "s'eek",
                                    "url": "s'eek",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "black bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dísi",
                            [
                                {
                                    "tlingit": "k'óox dísi",
                                    "url": "k'óox dísi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Venus",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Black bear month; when the cubs are born."
                ],
                "ids": [
                    [
                        "4_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "March",
                "tlingit": [
                    [
                        [
                            "Héen",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "taanáx̲",
                            [],
                            []
                        ],
                        [
                            "kayaaní",
                            [
                                {
                                    "tlingit": "kayaaní",
                                    "url": "kayaaní",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "leaf, leaves; vegetation, plants, herbs, herbiage",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dísi",
                            [
                                {
                                    "tlingit": "k'óox dísi",
                                    "url": "k'óox dísi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Venus",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Month of underwater plant budding."
                ],
                "ids": [
                    [
                        "4_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "April",
                "tlingit": [
                    [
                        [
                            "X'éig̲aa",
                            [
                                {
                                    "tlingit": "x'éig̲aa",
                                    "url": "x'éig̲aa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "truly, really; in truth, for sure",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kayaani",
                            [],
                            []
                        ],
                        [
                            "dísi",
                            [
                                {
                                    "tlingit": "k'óox dísi",
                                    "url": "k'óox dísi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Venus",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Month of true plant budding; when land plants begin to bud and sprout."
                ],
                "ids": [
                    [
                        "4_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "May",
                "tlingit": [
                    [
                        [
                            "At",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gadax̲it",
                            [],
                            []
                        ],
                        [
                            "yinaa",
                            [
                                {
                                    "tlingit": "a yinaa",
                                    "url": "a yinaa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "in its way; blocking its way; acting as a shield for it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "dísi",
                            [
                                {
                                    "tlingit": "k'óox dísi",
                                    "url": "k'óox dísi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Venus",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Month before giving birth (literally, \"breeding\"); before the animals give birth."
                ],
                "ids": [
                    [
                        "4_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "June",
                "tlingit": [
                    [
                        [
                            "At",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gadax̲it",
                            [],
                            []
                        ],
                        [
                            "dísi",
                            [
                                {
                                    "tlingit": "k'óox dísi",
                                    "url": "k'óox dísi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Venus",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "X̲aawákw",
                            [],
                            []
                        ],
                        [
                            "dísi",
                            [
                                {
                                    "tlingit": "k'óox dísi",
                                    "url": "k'óox dísi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Venus",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Dlixaawákw",
                            [],
                            []
                        ],
                        [
                            "dísi",
                            [
                                {
                                    "tlingit": "k'óox dísi",
                                    "url": "k'óox dísi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Venus",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Birth moon; when animals give birth (breed).",
                    "Shedding moon; when animals shed winter coats.",
                    "Shedding moon; when animals shed winter coats."
                ],
                "ids": [
                    [
                        "4_15",
                        "N"
                    ],
                    [
                        "4_16",
                        "J"
                    ],
                    [
                        "4_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Thirteenth month",
                "tlingit": [
                    [
                        [
                            "Jinkaat",
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wanáak",
                            [
                                {
                                    "tlingit": "a wanáak",
                                    "url": "a wanáak",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "separate from it; on the edge, side of it; missing its mark",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k̲a",
                            [
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nás'k",
                            [
                                {
                                    "tlingit": "nás'k",
                                    "url": "nás'k",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "three",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Ten and another three (things)."
                ],
                "ids": [
                    [
                        "4_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "summer",
                "tlingit": [
                    [
                        [
                            "k̲utaan",
                            [
                                {
                                    "tlingit": "k̲utaan",
                                    "url": "k̲utaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "summer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's getting to be summer.",
                "tlingit": [
                    [
                        [
                            "K̲utaandé",
                            [],
                            []
                        ],
                        [
                            "yaa k̲unahéin.",
                            [
                                {
                                    "tlingit": "aadé yaa k̲unahéin",
                                    "url": "át k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's getting to be time for it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's summer now.",
                "tlingit": [
                    [
                        [
                            "K̲utaant",
                            [],
                            []
                        ],
                        [
                            "yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲oowaháa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "winter/year",
                "tlingit": [
                    [
                        [
                            "táakw",
                            [
                                {
                                    "tlingit": "táakw",
                                    "url": "táakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "winter; year",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "fall, autumn",
                "tlingit": [
                    [
                        [
                            "yeis",
                            [
                                {
                                    "tlingit": "yeis",
                                    "url": "yeis",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fall; autumn",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "early spring",
                "tlingit": [
                    [
                        [
                            "yax̲k̲uhá",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_24",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "spring",
                "tlingit": [
                    [
                        [
                            "taakw.eetée",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "week/Sunday",
                "tlingit": [
                    [
                        [
                            "síndi",
                            [],
                            []
                        ],
                        [
                            "x̲'áak",
                            [
                                {
                                    "tlingit": "a x̲'áak",
                                    "url": "a x̲'áak",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "between them",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaaw x̲'áak",
                                    "url": "gaaw x̲'áak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hour",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daa.eit x̲'áak",
                                    "url": "du daa.eit x̲'áak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her joints",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "sánde",
                            [],
                            []
                        ],
                        [
                            "x̲'áak",
                            [
                                {
                                    "tlingit": "a x̲'áak",
                                    "url": "a x̲'áak",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "between them",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaaw x̲'áak",
                                    "url": "gaaw x̲'áak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hour",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daa.eit x̲'áak",
                                    "url": "du daa.eit x̲'áak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her joints",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "sándi",
                            [],
                            []
                        ],
                        [
                            "x̲'áak",
                            [
                                {
                                    "tlingit": "a x̲'áak",
                                    "url": "a x̲'áak",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "between them",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaaw x̲'áak",
                                    "url": "gaaw x̲'áak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hour",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daa.eit x̲'áak",
                                    "url": "du daa.eit x̲'áak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her joints",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(less common)",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "4_26",
                        "J"
                    ],
                    [
                        "4_27",
                        "N"
                    ],
                    [
                        "4_28",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "month/moon",
                "tlingit": [
                    [
                        [
                            "dís",
                            [
                                {
                                    "tlingit": "dís",
                                    "url": "dís",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "month",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dís",
                                    "url": "dís2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "moon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_29",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "pre-dawn",
                "tlingit": [
                    [
                        [
                            "k̲eex̲'é",
                            [
                                {
                                    "tlingit": "k̲eex̲'é",
                                    "url": "k̲ee.á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲eex̲'é",
                                    "url": "k̲eex̲'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲eex̲'é",
                                    "url": "k̲ei.á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲eex̲'é",
                                    "url": "k̲eix̲'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "k̲eix̲'é",
                            [
                                {
                                    "tlingit": "k̲eix̲'é",
                                    "url": "k̲ee.á",
                                    "tags": [
                                        "Noun",
                                        "An"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲eix̲'é",
                                    "url": "k̲ei.á",
                                    "tags": [
                                        "Noun",
                                        "An"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲eix̲'é",
                                    "url": "k̲eix̲'é",
                                    "tags": [
                                        "Noun",
                                        "An"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "4_30",
                        "N"
                    ],
                    [
                        "4_31",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "dawn",
                "tlingit": [
                    [
                        [
                            "k̲ee.á",
                            [
                                {
                                    "tlingit": "k̲ee.á",
                                    "url": "k̲ee.á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲ee.á",
                                    "url": "k̲eex̲'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲ee.á",
                                    "url": "k̲ei.á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲ee.á",
                                    "url": "k̲eix̲'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "k̲ei.á",
                            [
                                {
                                    "tlingit": "k̲ei.á",
                                    "url": "k̲ee.á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲ei.á",
                                    "url": "k̲eex̲'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲ei.á",
                                    "url": "k̲ei.á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲ei.á",
                                    "url": "k̲eix̲'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dawn, daybreak",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "4_32",
                        "N"
                    ],
                    [
                        "4_33",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "morning",
                "tlingit": [
                    [
                        [
                            "s'ootaat",
                            [
                                {
                                    "tlingit": "ts'ootaat",
                                    "url": "ts'ootaat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "morning",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ts'ootaat",
                                    "url": "ts'ootaat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "morning",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "ts'ootaat",
                            [
                                {
                                    "tlingit": "ts'ootaat",
                                    "url": "ts'ootaat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "morning",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "(most common)"
                ],
                "ids": [
                    [
                        "4_34",
                        "N"
                    ],
                    [
                        "4_35",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "this morning",
                "tlingit": [
                    [
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'ootaat",
                            [
                                {
                                    "tlingit": "ts'ootaat",
                                    "url": "ts'ootaat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "morning",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ts'ootaat",
                                    "url": "ts'ootaat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "morning",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_36",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "day/afternoon",
                "tlingit": [
                    [
                        [
                            "yakyee",
                            [
                                {
                                    "tlingit": "yakyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun",
                                        "At",
                                        "T"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yakyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yakyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "yagiyee",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "4_37",
                        "J"
                    ],
                    [
                        "4_38",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "noon",
                "tlingit": [
                    [
                        [
                            "sitgawsáan",
                            [
                                {
                                    "tlingit": "sitgawsáan",
                                    "url": "sitgawsáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "noon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_39",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "dusk",
                "tlingit": [
                    [
                        [
                            "xi.aat",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_40",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "evening",
                "tlingit": [
                    [
                        [
                            "xáanaa",
                            [
                                {
                                    "tlingit": "xáanaa",
                                    "url": "xáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "evening",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_41",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "this evening",
                "tlingit": [
                    [
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "xáanaa",
                            [
                                {
                                    "tlingit": "xáanaa",
                                    "url": "xáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "evening",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_42",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "night",
                "tlingit": [
                    [
                        [
                            "taat",
                            [
                                {
                                    "tlingit": "taat",
                                    "url": "taat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "night",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_43",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "this night",
                "tlingit": [
                    [
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "taat",
                            [
                                {
                                    "tlingit": "taat",
                                    "url": "taat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "night",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_44",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "midnight",
                "tlingit": [
                    [
                        [
                            "taat sitgawsáani",
                            [
                                {
                                    "tlingit": "taat sitgawsáani",
                                    "url": "taat sitgawsáani",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "midnight",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "taat",
                                    "url": "taat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "night",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_45",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "last night",
                "tlingit": [
                    [
                        [
                            "nisdaat",
                            [
                                {
                                    "tlingit": "nisdaat",
                                    "url": "nisdaat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "last night",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_46",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "the other day",
                "tlingit": [
                    [
                        [
                            "tliyaatgé",
                            [
                                {
                                    "tlingit": "tliyaatgé",
                                    "url": "tliyaatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "the other day; a few days ago",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_47",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "today",
                "tlingit": [
                    [
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagiyee",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yakyee",
                            [
                                {
                                    "tlingit": "yakyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun",
                                        "At",
                                        "T"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yakyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yakyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "4_48",
                        "N"
                    ],
                    [
                        "4_49",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "yesterday",
                "tlingit": [
                    [
                        [
                            "tatgé",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_50",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "day before yesterday",
                "tlingit": [
                    [
                        [
                            "tatgé",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tliya",
                            [],
                            []
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kát",
                            [
                                {
                                    "tlingit": "a kát",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát adatéen",
                                    "url": "kát adatéen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is wearing it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du kát shutí!",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Blame it on him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A kát kalak̲á!",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sew it on it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát kawdigán",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát wujix̲ín",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it fell on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát dul.us'ku át",
                                    "url": "a káa dul.us'ku át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "washboard",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_51",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "tomorrow",
                "tlingit": [
                    [
                        [
                            "seig̲án",
                            [
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲ánin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "seig̲áninx'",
                            [
                                {
                                    "tlingit": "seig̲ánin",
                                    "url": "seig̲ánin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "seig̲ánin",
                            [
                                {
                                    "tlingit": "seig̲ánin",
                                    "url": "seig̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "seig̲ánin",
                                    "url": "seig̲ánin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "4_52",
                        "N"
                    ],
                    [
                        "4_53",
                        "J"
                    ],
                    [
                        "4_54",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "day after tomorrow",
                "tlingit": [
                    [
                        [
                            "seig̲án",
                            [
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲ánin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tliya.aa",
                            [],
                            []
                        ],
                        [
                            "kádin",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_55",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "every day",
                "tlingit": [
                    [
                        [
                            "ch'a tlákw",
                            [
                                {
                                    "tlingit": "ch'a tlákw",
                                    "url": "ch'a tlákw",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "always, all the time, constantly",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "tlákw",
                                    "url": "tlákw",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "always, all the time, constantly",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ch'a",
                                    "url": "ch'a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "the very, just",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yageeyí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_56",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "all day long",
                "tlingit": [
                    [
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagiyee",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kaanáx̲",
                            [
                                {
                                    "tlingit": "a kaanáx̲",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "perlative"
                                    ],
                                    "english": "through the surface of it; over it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Woosh kaanáx̲ gayda.á!",
                                    "url": "woosh kaanáx̲ has wudi.aat",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all gather together!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "woosh kaanáx̲ has wudi.aat",
                                    "url": "woosh kaanáx̲ has wudi.aat",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Pl"
                                    ],
                                    "english": "they gathered together",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "woosh kaanáx̲ has gada.átch",
                                    "url": "woosh kaanáx̲ has wudi.aat",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Pl"
                                    ],
                                    "english": "they gather together (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "woosh kaanáx̲ kei (ha)s nada.át",
                                    "url": "woosh kaanáx̲ has wudi.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are beginning to gather together",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "woosh kaanáx̲ kei (ha)s da.átch",
                                    "url": "woosh kaanáx̲ has wudi.aat",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they gather together (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl woosh kaanáx̲ kei yda.átjik̲!",
                                    "url": "woosh kaanáx̲ has wudi.aat",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Pl"
                                    ],
                                    "english": "Don't you all gather together!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yakyee",
                            [
                                {
                                    "tlingit": "yakyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun",
                                        "At",
                                        "T"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yakyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yakyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kanax̲",
                            [
                                {
                                    "tlingit": "a kanax̲",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "perlative"
                                    ],
                                    "english": "through the surface of it; over it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kanax̲ jiyawsitán",
                                    "url": "a kanax̲ jiyawsitán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "waves washed over it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "g̲eey kanax̲ k̲utées'",
                                    "url": "g̲eey kanax̲ k̲utées'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "ratfish",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kanax̲ jiwustánch",
                                    "url": "a kanax̲ jiyawsitán",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "waves wash over it (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kanax̲ jiyagux̲satáan",
                                    "url": "a kanax̲ jiyawsitán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "waves will wash over it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kanax̲ yaa jiyanastán",
                                    "url": "a kanax̲ jiyawsitán",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "waves are washing over it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "4_57",
                        "J"
                    ],
                    [
                        "4_58",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "How long?",
                "tlingit": [
                    [
                        [
                            "Wáa",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yeekoowáat'",
                            [],
                            []
                        ],
                        [
                            "sá?",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_59",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "a long time",
                "tlingit": [
                    [
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeekoowáat'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_60",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "a short time (ago)",
                "tlingit": [
                    [
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeeguwáatl'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_61",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's getting to be a long time.",
                "tlingit": [
                    [
                        [
                            "Yéi yaa",
                            [
                                {
                                    "tlingit": "yéi yaa nanein",
                                    "url": "yéi woonei",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "that's happening to him/her/it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi yaa nanein",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is becoming that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi yaa anasnéin",
                                    "url": "yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is fixing it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi yaa kunaléin",
                                    "url": "yéi kaawalei",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's becoming that far",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi yaa kunayát'",
                                    "url": "yéi kaawayáat'",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it is getting that long",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi yaa kunaskák",
                                    "url": "yéi kawsikaak",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's getting that thick",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi yaa kundagéin",
                                    "url": "yéi kwdigéi",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they're getting that big",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yeekunayát'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_62",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "now",
                "tlingit": [
                    [
                        [
                            "yeedát",
                            [
                                {
                                    "tlingit": "yeedát",
                                    "url": "yeedát",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "now",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_63",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "from now",
                "tlingit": [
                    [
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeedádidáx̲",
                            [
                                {
                                    "tlingit": "yeedát",
                                    "url": "yeedát",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "now",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dáx̲",
                                    "url": "-dáx̲",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "from, out of; since",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_64",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "ago; since",
                "tlingit": [
                    [
                        [
                            "shoonaaxéex",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(see example below)"
                ],
                "ids": [
                    [
                        "4_65",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's three years since he got married. or Three years have elapsed since he got married.",
                "tlingit": [
                    [
                        [
                            "Nas'k",
                            [],
                            []
                        ],
                        [
                            "táakw",
                            [
                                {
                                    "tlingit": "táakw",
                                    "url": "táakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "winter; year",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shunaaxéex",
                            [],
                            []
                        ],
                        [
                            "aadáx̲",
                            [
                                {
                                    "tlingit": "aadáx̲ woogoot",
                                    "url": "aadáx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it left there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadáx̲ kawdiyaa",
                                    "url": "aadáx̲ kawdiyaa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it disappeared from there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadáx̲ awsixáat'",
                                    "url": "aadáx̲ awsixáat'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he dragged it away",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadáx̲ nagútch",
                                    "url": "aadáx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it leaves there (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aadáx̲ nagú!",
                                    "url": "aadáx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Leave there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadáx̲ gug̲agóot",
                                    "url": "aadáx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is going to leave there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadáx̲ yaa nagút",
                                    "url": "aadáx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is leaving there",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "aawashayi",
                            [
                                {
                                    "tlingit": "aawasháa",
                                    "url": "aawasháa",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he married him/her; s/he is married",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aawasháa",
                                    "url": "aawasháa",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he married him/her; s/he is married",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yé.",
                            [
                                {
                                    "tlingit": "yé",
                                    "url": "yé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "place; way",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_66",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "just now",
                "tlingit": [
                    [
                        [
                            "yeisóo",
                            [
                                {
                                    "tlingit": "yeisú",
                                    "url": "yeisú",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "just now; just then; still; yet",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "yeisú",
                            [
                                {
                                    "tlingit": "yeisú",
                                    "url": "yeisú",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "just now; just then; still; yet",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "4_67",
                        "J"
                    ],
                    [
                        "4_68",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "a while ago",
                "tlingit": [
                    [
                        [
                            "dziyáak",
                            [
                                {
                                    "tlingit": "dziyáak",
                                    "url": "dziyáak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "just now; a while ago, earlier",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_69",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "always",
                "tlingit": [
                    [
                        [
                            "ch'a tlákw",
                            [
                                {
                                    "tlingit": "ch'a tlákw",
                                    "url": "ch'a tlákw",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "always, all the time, constantly",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "tlákw",
                                    "url": "tlákw",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "always, all the time, constantly",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ch'a",
                                    "url": "ch'a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "the very, just",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_70",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "(only) now and then; sometimes, once in a while",
                "tlingit": [
                    [
                        [
                            "(ch'as)",
                            [
                                {
                                    "tlingit": "ch'as",
                                    "url": "ch'as",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "only, just",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wáang̲aneins",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "wáang̲aneins",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "wáang̲aneens",
                            [
                                {
                                    "tlingit": "wáang̲aneens",
                                    "url": "wáang̲aneens",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes, once in a while",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "4_71",
                        "J"
                    ],
                    [
                        "4_72",
                        "N"
                    ],
                    [
                        "4_73",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "When (was it)?",
                "tlingit": [
                    [
                        [
                            "Gwátk",
                            [],
                            []
                        ],
                        [
                            "sá?",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Gwátgeen sá?",
                            [
                                {
                                    "tlingit": "ch'a gwátgeen sá",
                                    "url": "ch'a gwátgeen sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "any time (in the future); whenever (in the future)",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "4_74",
                        "N"
                    ],
                    [
                        "4_75",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "When (shall we)?",
                "tlingit": [
                    [
                        [
                            "Gwátgeenx'",
                            [],
                            []
                        ],
                        [
                            "sá?",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_76",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "When did you eat?",
                "tlingit": [
                    [
                        [
                            "Gwátk",
                            [],
                            []
                        ],
                        [
                            "sáwé",
                            [],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "iyax̲áa?",
                            [
                                {
                                    "tlingit": "at uwax̲áa",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he ate",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_77",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I just ate.",
                "tlingit": [
                    [
                        [
                            "Yeisóo",
                            [
                                {
                                    "tlingit": "yeisú",
                                    "url": "yeisú",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "just now; just then; still; yet",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲waax̲áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_78",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "When will you eat?",
                "tlingit": [
                    [
                        [
                            "Gwátgeen",
                            [
                                {
                                    "tlingit": "ch'a gwátgeen sá",
                                    "url": "ch'a gwátgeen sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "any time (in the future); whenever (in the future)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sáwé",
                            [],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gag̲eex̲áa?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_79",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "in a while",
                "tlingit": [
                    [
                        [
                            "dziyáageen",
                            [
                                {
                                    "tlingit": "dziyáagin",
                                    "url": "dziyáagin",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "after a while; later on",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "dziyáageenx'",
                            [
                                {
                                    "tlingit": "dziyáagin",
                                    "url": "dziyáagin",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "after a while; later on",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "4_80",
                        "N"
                    ],
                    [
                        "4_81",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "l'll eat in a while.",
                "tlingit": [
                    [
                        [
                            "Dziyáageenx'",
                            [
                                {
                                    "tlingit": "dziyáagin",
                                    "url": "dziyáagin",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "after a while; later on",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tsá",
                            [
                                {
                                    "tlingit": "tsá",
                                    "url": "tsá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "only then",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kuk̲ax̲áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_82",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "almost",
                "tlingit": [
                    [
                        [
                            "tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "unalé",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "unalé",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "hél",
                            [
                                {
                                    "tlingit": "hél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "unalé",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nalé",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nalé",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "hél",
                            [
                                {
                                    "tlingit": "hél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "unalé",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "tlélí",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "tlélé",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "tléinlí",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "tléinlé",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "4_83",
                        "N"
                    ],
                    [
                        "4_84",
                        "J"
                    ],
                    [
                        "4_85",
                        "N"
                    ],
                    [
                        "4_86",
                        "J"
                    ],
                    [
                        "4_87",
                        "N"
                    ],
                    [
                        "4_88",
                        "J"
                    ],
                    [
                        "4_89",
                        "N"
                    ],
                    [
                        "4_90",
                        "J"
                    ],
                    [
                        "4_91",
                        "N"
                    ],
                    [
                        "4_92",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "maybe",
                "tlingit": [
                    [
                        [
                            "shákdé",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "shákdéi",
                            [
                                {
                                    "tlingit": "shákdéi",
                                    "url": "shákdéi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "perhaps, probably",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "4_93",
                        "N"
                    ],
                    [
                        "4_94",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "probably",
                "tlingit": [
                    [
                        [
                            "k̲wshé",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_95",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I don't know for sure.",
                "tlingit": [
                    [
                        [
                            "gushé",
                            [
                                {
                                    "tlingit": "gushé",
                                    "url": "kwshéi",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "maybe; I'm not sure; [expression of uncertainly]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_96",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "All right.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "utí.",
                            [
                                {
                                    "tlingit": "yéi wootee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was that way",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_97",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "all",
                "tlingit": [
                    [
                        [
                            "ldakat",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "ch'a ldakát",
                            [
                                {
                                    "tlingit": "ch'a ldakát yé",
                                    "url": "ch'a ldakát yé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "everywhere",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a ldakát yé",
                                    "url": "ldakát yé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "everywhere",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a ldakát át",
                                    "url": "ch'a ldakát át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "everything",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a ldakát k̲áa",
                                    "url": "ch'a ldakát k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "everyone, everybody",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ldakát",
                                    "url": "ldakát",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "all; every",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ch'a",
                                    "url": "ch'a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "the very, just",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "4_98",
                        "J"
                    ],
                    [
                        "4_99",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "everyone",
                "tlingit": [
                    [
                        [
                            "ldakát k̲áa",
                            [
                                {
                                    "tlingit": "ch'a ldakát k̲áa",
                                    "url": "ch'a ldakát k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "everyone, everybody",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ldakát",
                                    "url": "ldakát",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "all; every",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_100",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "everything",
                "tlingit": [
                    [
                        [
                            "ldakát át",
                            [
                                {
                                    "tlingit": "ldakát át",
                                    "url": "ldakát át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "everything",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "ldakát",
                                    "url": "ldakát",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "all; every",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "át",
                                    "url": "át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "thing",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_101",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "all kinds",
                "tlingit": [
                    [
                        [
                            "ldakát",
                            [
                                {
                                    "tlingit": "ldakát",
                                    "url": "ldakát",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "all; every",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéide",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_102",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "everywhere",
                "tlingit": [
                    [
                        [
                            "ldakát yé",
                            [
                                {
                                    "tlingit": "ldakát yé",
                                    "url": "ch'a ldakát yé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "everywhere",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ldakát yé",
                                    "url": "ldakát yé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "everywhere",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "ldakát",
                                    "url": "ldakát",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "all; every",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yé",
                                    "url": "yé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "place; way",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_103",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "any which way, any old way, haphazardly",
                "tlingit": [
                    [
                        [
                            "ch'a koogéiyi",
                            [
                                {
                                    "tlingit": "ch'a koogéiyi",
                                    "url": "ch'a koogéiyi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "however, any which way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "ch'a",
                                    "url": "ch'a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "the very, just",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "4_104",
                        "J"
                    ]
                ]
            }
        ]
    },
    "5": {
        "title": "Introducing one's self, personal phrases, living and staying in places",
        "content": [
            {
                "type": "ex",
                "english": "What's your name?",
                "tlingit": [
                    [
                        [
                            "Wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw?",
                            [
                                {
                                    "tlingit": "yéi aawasáakw",
                                    "url": "yéi aawasáakw",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he called him/her/it that (name)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_1",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My name is Mary.",
                "tlingit": [
                    [
                        [
                            "Mary",
                            [],
                            []
                        ],
                        [
                            "yóo",
                            [
                                {
                                    "tlingit": "yóo",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóo",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (distant), yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [
                                {
                                    "tlingit": "yéi aawasáakw",
                                    "url": "yéi aawasáakw",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he called him/her/it that (name)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_2",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Her name is Mary.",
                "tlingit": [
                    [
                        [
                            "Mary",
                            [],
                            []
                        ],
                        [
                            "yóo",
                            [
                                {
                                    "tlingit": "yóo",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóo",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (distant), yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [
                                {
                                    "tlingit": "yéi aawasáakw",
                                    "url": "yéi aawasáakw",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he called him/her/it that (name)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_3",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he was born.",
                "tlingit": [
                    [
                        [
                            "K̲uwdzitee.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_4",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he was born in Juneau.",
                "tlingit": [
                    [
                        [
                            "Juneau-x'",
                            [
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k̲uwdzitee.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_5",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I was born in Juneau.",
                "tlingit": [
                    [
                        [
                            "Juneau-x'",
                            [
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k̲ux̲wdzitee.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_6",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he moved to Juneau.",
                "tlingit": [
                    [
                        [
                            "Juneau-t",
                            [],
                            []
                        ],
                        [
                            "wudlitsúw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_7",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I moved to Juneau.",
                "tlingit": [
                    [
                        [
                            "Juneau-t",
                            [],
                            []
                        ],
                        [
                            "x̲wadlitsúw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_8",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We moved to Juneau.",
                "tlingit": [
                    [
                        [
                            "Juneau-t",
                            [],
                            []
                        ],
                        [
                            "wutudlitsúw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_9",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "John named me so.",
                "tlingit": [
                    [
                        [
                            "John-ch",
                            [],
                            []
                        ],
                        [
                            "yóo",
                            [
                                {
                                    "tlingit": "yóo",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóo",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (distant), yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "uwasáa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_10",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My grandparent named me so.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "léelk'wch",
                            [],
                            []
                        ],
                        [
                            "yóo",
                            [
                                {
                                    "tlingit": "yóo",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóo",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (distant), yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "uwasáa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_11",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "John named me Harry.",
                "tlingit": [
                    [
                        [
                            "John-ch",
                            [],
                            []
                        ],
                        [
                            "Harry",
                            [],
                            []
                        ],
                        [
                            "yóo",
                            [
                                {
                                    "tlingit": "yóo",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóo",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (distant), yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "uwasáa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_12",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "How old are you?",
                "tlingit": [
                    [
                        [
                            "X'oon",
                            [
                                {
                                    "tlingit": "x'oon sá",
                                    "url": "x'oon sá",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "how many; some number (of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "táakw",
                            [
                                {
                                    "tlingit": "táakw",
                                    "url": "táakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "winter; year",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "wa.é?",
                            [
                                {
                                    "tlingit": "wa.é",
                                    "url": "wa.é",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (singular) [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "X'oon sá",
                            [
                                {
                                    "tlingit": "x'oon sá",
                                    "url": "x'oon sá",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "how many; some number (of)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "katáagu?",
                            [
                                {
                                    "tlingit": "du katáagu",
                                    "url": "du katáagu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her age",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "5_13",
                        "J"
                    ],
                    [
                        "5_14",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Four years old.",
                "tlingit": [
                    [
                        [
                            "Daax'oon",
                            [
                                {
                                    "tlingit": "daax'oon",
                                    "url": "daax'oon",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "four",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "táakw.",
                            [
                                {
                                    "tlingit": "táakw",
                                    "url": "táakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "winter; year",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_15",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Five years old.",
                "tlingit": [
                    [
                        [
                            "Keijín",
                            [
                                {
                                    "tlingit": "keijín",
                                    "url": "keijín",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "five",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "táakw.",
                            [
                                {
                                    "tlingit": "táakw",
                                    "url": "táakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "winter; year",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_16",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you have sisters/brothers?",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "t'aak̲x'í",
                            [],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲udzitee?",
                            [
                                {
                                    "tlingit": "k̲udzitee",
                                    "url": "k̲oowdzitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it exists; s/he is alive",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_17",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where is s/he staying/living?",
                "tlingit": [
                    [
                        [
                            "Goox'",
                            [
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sáyá",
                            [],
                            []
                        ],
                        [
                            "yéi yatee?",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_18",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where is s/he sleeping?",
                "tlingit": [
                    [
                        [
                            "Goox'",
                            [
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "teix̲?",
                            [
                                {
                                    "tlingit": "teix̲",
                                    "url": "wootaa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sleeps (regularly)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_19",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where are you staying?",
                "tlingit": [
                    [
                        [
                            "Goox'",
                            [
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yá",
                            [
                                {
                                    "tlingit": "yá",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iyatee?",
                            [
                                {
                                    "tlingit": "a eetéenáx̲ wootee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it needed it; he/she/it lacked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ wootee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it needed it; he/she/it lacked it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_20",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where is your father living?",
                "tlingit": [
                    [
                        [
                            "Goox'",
                            [
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi yatee",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh?",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_21",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm living in Juneau.",
                "tlingit": [
                    [
                        [
                            "Juneau-x'",
                            [
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_22",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We're staying with our relative.",
                "tlingit": [
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲ooni",
                            [],
                            []
                        ],
                        [
                            "x̲ánx'",
                            [
                                {
                                    "tlingit": "du x̲ánx'",
                                    "url": "du x̲án",
                                    "tags": [
                                        "RelationalNoun",
                                        "locative"
                                    ],
                                    "english": "next to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi teex̲",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he stays with him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi wootee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲ánx' yéi inatí!",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Stay with him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi nateech",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he stays with him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi kg̲watée",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will stay with him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_23",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Our uncles used to live here.",
                "tlingit": [
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káak",
                            [
                                {
                                    "tlingit": "du káak",
                                    "url": "du káak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her maternal uncle",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "hás",
                            [
                                {
                                    "tlingit": "hás",
                                    "url": "hás",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "[plural marker for kinship terms]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "hás",
                                    "url": "hás2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáax'",
                            [
                                {
                                    "tlingit": "yáax'",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "locative"
                                    ],
                                    "english": "here",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeyin.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_24",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's living down south.",
                "tlingit": [
                    [
                        [
                            "Ixkéex'",
                            [],
                            []
                        ],
                        [
                            "yéi yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_25",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We lived in his house.",
                "tlingit": [
                    [
                        [
                            "Du hídi",
                            [
                                {
                                    "tlingit": "du hídi",
                                    "url": "hít",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its house; building",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du s'ísaa hídi",
                                    "url": "s'ísaa hít",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its tent",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du atx'aan hídi",
                                    "url": "atx'aan hídi",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its smokehouse",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "s'eik̲ hídi",
                                    "url": "s'eik̲ daakahídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "smokehouse (with smoke piped in from outside)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "atx'aan hídi",
                                    "url": "atx'aan hídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "smokehouse",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "etx'aan hídi",
                                    "url": "atx'aan hídi",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "smokehouse",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yee(x')",
                            [],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wootee.",
                            [
                                {
                                    "tlingit": "yéi wootee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ wootee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needed it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi wootee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it became still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ at wootee",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he fed him/her/it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi wootee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi wootee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it appeared before him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hídix'",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wootee.",
                            [
                                {
                                    "tlingit": "yéi wootee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ wootee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needed it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi wootee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it became still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ at wootee",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he fed him/her/it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi wootee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi wootee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it appeared before him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "5_26",
                        "N"
                    ],
                    [
                        "5_27",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are you going to stay here?",
                "tlingit": [
                    [
                        [
                            "Yáax'",
                            [
                                {
                                    "tlingit": "yáax'",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "locative"
                                    ],
                                    "english": "here",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ágé",
                            [
                                {
                                    "tlingit": "ágé",
                                    "url": "ágé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[focus + interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ikg̲watée?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_28",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's going to stay in this town.",
                "tlingit": [
                    [
                        [
                            "Yá",
                            [
                                {
                                    "tlingit": "yá",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aanx'",
                            [
                                {
                                    "tlingit": "aan",
                                    "url": "aan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "town; village; settlement; inhabited or owned land",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aan",
                                    "url": "aan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "town; village; settlement; inhabited or owned land",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi kg̲watée.",
                            [
                                {
                                    "tlingit": "yéi kg̲watée",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will be that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "tleiyéi yéi kg̲watée",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will be still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi kg̲watée",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will stay with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi kg̲watée",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will appear before him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "Yá",
                            [
                                {
                                    "tlingit": "yá",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aan",
                            [
                                {
                                    "tlingit": "aan",
                                    "url": "aan",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "with it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "aan",
                                    "url": "aan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "town; village; settlement; inhabited or owned land",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káx'",
                            [
                                {
                                    "tlingit": "a káx'",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "locative"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi kg̲watée.",
                            [
                                {
                                    "tlingit": "yéi kg̲watée",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will be that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "tleiyéi yéi kg̲watée",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will be still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi kg̲watée",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will stay with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi kg̲watée",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will appear before him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "Yá",
                            [
                                {
                                    "tlingit": "yá",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aanx'",
                            [
                                {
                                    "tlingit": "aan",
                                    "url": "aan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "town; village; settlement; inhabited or owned land",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aan",
                                    "url": "aan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "town; village; settlement; inhabited or owned land",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yéi kg̲watée.",
                            [
                                {
                                    "tlingit": "yéi kg̲watée",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will be that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "tleiyéi yéi kg̲watée",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will be still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi kg̲watée",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will stay with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi kg̲watée",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will appear before him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "5_29",
                        "J"
                    ],
                    [
                        "5_30",
                        "N"
                    ],
                    [
                        "5_31",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's going to live/stay downtown.",
                "tlingit": [
                    [
                        [
                            "Yá",
                            [
                                {
                                    "tlingit": "yá",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "noow",
                            [
                                {
                                    "tlingit": "noow",
                                    "url": "noow",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "flat-topped island with steep sides; low flat island or hill",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "noow",
                                    "url": "noow2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fort",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲eix'",
                            [],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi kg̲watée.",
                            [
                                {
                                    "tlingit": "yéi kg̲watée",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will be that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "tleiyéi yéi kg̲watée",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will be still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi kg̲watée",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will stay with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi kg̲watée",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will appear before him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(literally \"in the fort\")"
                ],
                "ids": [
                    [
                        "5_32",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm going to stay/live in Juneau.",
                "tlingit": [
                    [
                        [
                            "Juneau-x'",
                            [
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲watée.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Juneau-x'",
                            [
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gug̲atée.",
                            [
                                {
                                    "tlingit": "a yáx̲ gug̲atée",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will be like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ gug̲atée",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will need it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ at gug̲atée",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will feed him/her/it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "5_33",
                        "J"
                    ],
                    [
                        "5_34",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's not living here.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáax'",
                            [
                                {
                                    "tlingit": "yáax'",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "locative"
                                    ],
                                    "english": "here",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "utí.",
                            [
                                {
                                    "tlingit": "yéi wootee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was that way",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_35",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not living there.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wéix'",
                            [
                                {
                                    "tlingit": "wéix'",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative",
                                        "locative"
                                    ],
                                    "english": "there",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "utí.",
                            [
                                {
                                    "tlingit": "yéi wootee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was that way",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_36",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I didn't live there.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áa yéi",
                            [
                                {
                                    "tlingit": "áa yéi aawa.oo",
                                    "url": "áa yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he put them there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áa yéi a.úx̲x'",
                                    "url": "áa yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he puts them there (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áa yéi ana.ooch",
                                    "url": "áa yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he puts them there (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Áa yéi na.oo!",
                                    "url": "áa yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put them there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áa yéi akg̲wa.oo",
                                    "url": "áa yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will put them there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Áa yéi nay.oo!",
                                    "url": "áa yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all put them there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl áa yéi ee.eix̲ík̲!",
                                    "url": "áa yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't put them there!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "áa",
                                    "url": "áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "lake",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "utéeyin.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_37",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not going to stay here.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáax'",
                            [
                                {
                                    "tlingit": "yáax'",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "locative"
                                    ],
                                    "english": "here",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gug̲atee.",
                            [
                                {
                                    "tlingit": "du x̲ánx' yéi wootee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he was with him/her; s/he stayed with him/her; s/he lived with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi wootee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he was with him/her; s/he stayed with him/her; s/he lived with him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_38",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Who's staying at your [plural] house?",
                "tlingit": [
                    [
                        [
                            "Aadoo",
                            [
                                {
                                    "tlingit": "aadoo",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Who",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadoo",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Who",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hídix'",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yéi yatee?",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "Aadoo",
                            [
                                {
                                    "tlingit": "aadoo",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Who",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadoo",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Who",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hídi",
                            [
                                {
                                    "tlingit": "du hídi",
                                    "url": "hít",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its house; building",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "s'eik̲ hídi",
                                    "url": "s'eik̲ daakahídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "smokehouse (with smoke piped in from outside)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "atx'aan hídi",
                                    "url": "atx'aan hídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "smokehouse",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du s'ísaa hídi",
                                    "url": "s'ísaa hít",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its tent",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "etx'aan hídi",
                                    "url": "atx'aan hídi",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "smokehouse",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du atx'aan hídi",
                                    "url": "atx'aan hídi",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its smokehouse",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yee(x')",
                            [],
                            []
                        ],
                        [
                            "yéi yatee?",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "5_39",
                        "J"
                    ],
                    [
                        "5_40",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Our son is staying at our house.",
                "tlingit": [
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéet",
                            [
                                {
                                    "tlingit": "du yéet",
                                    "url": "du yéet",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her son, cousin",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hídix'",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yéi yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéet",
                            [
                                {
                                    "tlingit": "du yéet",
                                    "url": "du yéet",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her son, cousin",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hídi",
                            [
                                {
                                    "tlingit": "du hídi",
                                    "url": "hít",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its house; building",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "s'eik̲ hídi",
                                    "url": "s'eik̲ daakahídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "smokehouse (with smoke piped in from outside)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "atx'aan hídi",
                                    "url": "atx'aan hídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "smokehouse",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du s'ísaa hídi",
                                    "url": "s'ísaa hít",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its tent",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "etx'aan hídi",
                                    "url": "atx'aan hídi",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "smokehouse",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du atx'aan hídi",
                                    "url": "atx'aan hídi",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its smokehouse",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yee(x')",
                            [],
                            []
                        ],
                        [
                            "yéi yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "5_41",
                        "J"
                    ],
                    [
                        "5_42",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's staying with* me.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲ánx'",
                            [
                                {
                                    "tlingit": "du x̲ánx'",
                                    "url": "du x̲án",
                                    "tags": [
                                        "RelationalNoun",
                                        "locative"
                                    ],
                                    "english": "next to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi teex̲",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he stays with him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi wootee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲ánx' yéi inatí!",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Stay with him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi nateech",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he stays with him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi kg̲watée",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will stay with him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "In regional English often \"by me,\" influenced from Tlingit."
                ],
                "ids": [
                    [
                        "5_43",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm staying with* him/her.",
                "tlingit": [
                    [
                        [
                            "Du x̲ánx'",
                            [
                                {
                                    "tlingit": "du x̲ánx'",
                                    "url": "du x̲án",
                                    "tags": [
                                        "RelationalNoun",
                                        "locative"
                                    ],
                                    "english": "next to him/her",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi teex̲",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he stays with him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi wootee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi nateech",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he stays with him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi kg̲watée",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will stay with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲ánx' yéi inatí!",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Stay with him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "In regional English often \"by me,\" influenced from Tlingit."
                ],
                "ids": [
                    [
                        "5_44",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "His/her grandchild is staying with him/her.",
                "tlingit": [
                    [
                        [
                            "Du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dachx̲án(k')",
                            [
                                {
                                    "tlingit": "du dachx̲án",
                                    "url": "du dachx̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her grandchild",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-k'",
                                    "url": "-k'",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "little; precious; [diminutive suffix]",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "du x̲ánx' yéi yatee.",
                            [
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du x̲ánx' yéi nateech",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he stays with him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx'",
                                    "url": "du x̲án",
                                    "tags": [
                                        "RelationalNoun",
                                        "locative"
                                    ],
                                    "english": "next to him/her",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_45",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Who's going to be staying in your house?",
                "tlingit": [
                    [
                        [
                            "Aadoo",
                            [
                                {
                                    "tlingit": "aadoo",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Who",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadoo",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Who",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hídix'",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲watée?",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Aadoo",
                            [
                                {
                                    "tlingit": "aadoo",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Who",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadoo",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Who",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hídi",
                            [
                                {
                                    "tlingit": "du hídi",
                                    "url": "hít",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its house; building",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "s'eik̲ hídi",
                                    "url": "s'eik̲ daakahídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "smokehouse (with smoke piped in from outside)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "atx'aan hídi",
                                    "url": "atx'aan hídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "smokehouse",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du s'ísaa hídi",
                                    "url": "s'ísaa hít",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its tent",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "etx'aan hídi",
                                    "url": "atx'aan hídi",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "smokehouse",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du atx'aan hídi",
                                    "url": "atx'aan hídi",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its smokehouse",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yee(x')",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲watée?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "5_46",
                        "N"
                    ],
                    [
                        "5_47",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Who's going to be staying with you?",
                "tlingit": [
                    [
                        [
                            "Aadoo",
                            [
                                {
                                    "tlingit": "aadoo",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Who",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadoo",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Who",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲ánx' yéi",
                            [
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi teex̲",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he stays with him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi wootee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲ánx' yéi inatí!",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Stay with him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi nateech",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he stays with him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi kg̲watée",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will stay with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲ánx' yéi yee natí!",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all stay with him/her!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du x̲ánx'",
                                    "url": "du x̲án",
                                    "tags": [
                                        "RelationalNoun",
                                        "locative"
                                    ],
                                    "english": "next to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "g̲watée?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_48",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are they in the store?",
                "tlingit": [
                    [
                        [
                            "Hoon daakahídi",
                            [
                                {
                                    "tlingit": "hoon daakahídi",
                                    "url": "hoon daakahídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "store",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du hoon daakahídi",
                                    "url": "hoon daakahídi",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its store",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "atx̲á daakahídi",
                                    "url": "atx̲á daakahídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "restaurant; tavern",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x'úx' daakahídi",
                                    "url": "x'úx' daakahídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "post office",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲áat daakahídi",
                                    "url": "x̲áat daakahídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "cannery",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "s'eik̲ daakahídi",
                                    "url": "s'eik̲ daakahídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "smokehouse (with smoke piped in from outside)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at wuskú daakahídi",
                                    "url": "at wuskú daakahídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "school",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kax'ás'ti daakahídi",
                                    "url": "kax'ás'ti daakahídi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sawmill",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yeewú",
                            [],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hás?",
                            [
                                {
                                    "tlingit": "hás",
                                    "url": "hás",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "[plural marker for kinship terms]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "hás",
                                    "url": "hás2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_49",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They used to live in Raven House.",
                "tlingit": [
                    [
                        [
                            "Yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Hítx'",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "(áwé)",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeyin.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Hít",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yee(x')",
                            [],
                            []
                        ],
                        [
                            "(áwé)",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeyin.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "5_50",
                        "N"
                    ],
                    [
                        "5_51",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Did your maternal uncles used to live in Raven House?",
                "tlingit": [
                    [
                        [
                            "Yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Hítx'",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ák.wé",
                            [
                                {
                                    "tlingit": "ák.wé",
                                    "url": "ák.wé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeyin",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káak",
                            [
                                {
                                    "tlingit": "du káak",
                                    "url": "du káak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her maternal uncle",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "hás?",
                            [
                                {
                                    "tlingit": "hás",
                                    "url": "hás",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "[plural marker for kinship terms]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "hás",
                                    "url": "hás2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Hít",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yee(x')",
                            [],
                            []
                        ],
                        [
                            "ák.wé",
                            [
                                {
                                    "tlingit": "ák.wé",
                                    "url": "ák.wé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeyin",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káak",
                            [
                                {
                                    "tlingit": "du káak",
                                    "url": "du káak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her maternal uncle",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "hás?",
                            [
                                {
                                    "tlingit": "hás",
                                    "url": "hás",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "[plural marker for kinship terms]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "hás",
                                    "url": "hás2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "5_52",
                        "N"
                    ],
                    [
                        "5_53",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "This summer I'm living with my uncle.",
                "tlingit": [
                    [
                        [
                            "Yá",
                            [
                                {
                                    "tlingit": "yá",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲utaan",
                            [
                                {
                                    "tlingit": "k̲utaan",
                                    "url": "k̲utaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "summer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káak",
                            [
                                {
                                    "tlingit": "du káak",
                                    "url": "du káak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her maternal uncle",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲ánx' yéi",
                            [
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi teex̲",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he stays with him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi wootee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲ánx' yéi inatí!",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Stay with him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi nateech",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he stays with him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi kg̲watée",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will stay with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲ánx' yéi yee natí!",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all stay with him/her!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du x̲ánx'",
                                    "url": "du x̲án",
                                    "tags": [
                                        "RelationalNoun",
                                        "locative"
                                    ],
                                    "english": "next to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_54",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Five months from now we'll be living in a new house, maybe.",
                "tlingit": [
                    [
                        [
                            "Keijín",
                            [
                                {
                                    "tlingit": "keijín",
                                    "url": "keijín",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "five",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dís",
                            [
                                {
                                    "tlingit": "dís",
                                    "url": "dís",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "month",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dís",
                                    "url": "dís2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "moon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeedádidáx̲",
                            [
                                {
                                    "tlingit": "yeedát",
                                    "url": "yeedát",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "now",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dáx̲",
                                    "url": "-dáx̲",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "from, out of; since",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yées",
                            [
                                {
                                    "tlingit": "yées",
                                    "url": "yées",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "new; young; fresh",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hít",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yee(x')",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kwg̲atée",
                            [],
                            []
                        ],
                        [
                            "shakdéi.",
                            [
                                {
                                    "tlingit": "shákdéi",
                                    "url": "shákdéi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "perhaps, probably",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_55",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Ten years ago we were living in Juneau.",
                "tlingit": [
                    [
                        [
                            "Jinkaat",
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "táakw",
                            [
                                {
                                    "tlingit": "táakw",
                                    "url": "táakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "winter; year",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shoonaaxéex",
                            [],
                            []
                        ],
                        [
                            "Juneau-x'",
                            [
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeyin.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_56",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I stayed there last fall.",
                "tlingit": [
                    [
                        [
                            "Yeedát",
                            [
                                {
                                    "tlingit": "yeedát",
                                    "url": "yeedát",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "now",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeis",
                            [
                                {
                                    "tlingit": "yeis",
                                    "url": "yeis",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fall; autumn",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áa yéi",
                            [
                                {
                                    "tlingit": "áa yéi aawa.oo",
                                    "url": "áa yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he put them there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áa yéi a.úx̲x'",
                                    "url": "áa yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he puts them there (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áa yéi ana.ooch",
                                    "url": "áa yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he puts them there (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Áa yéi na.oo!",
                                    "url": "áa yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put them there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áa yéi akg̲wa.oo",
                                    "url": "áa yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will put them there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Áa yéi nay.oo!",
                                    "url": "áa yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all put them there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl áa yéi ee.eix̲ík̲!",
                                    "url": "áa yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't put them there!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "áa",
                                    "url": "áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "lake",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wootee.",
                            [
                                {
                                    "tlingit": "yéi wootee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ wootee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needed it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi wootee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it became still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ at wootee",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he fed him/her/it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi wootee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi wootee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it appeared before him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_57",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are you going to be in Angoon this spring?",
                "tlingit": [
                    [
                        [
                            "Aangóonx'",
                            [
                                {
                                    "tlingit": "Aangóon",
                                    "url": "Aangóon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Angoon",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x'",
                                    "url": "-x'",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "at (the scene of); at (the time of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ikg̲watée",
                            [],
                            []
                        ],
                        [
                            "yá",
                            [
                                {
                                    "tlingit": "yá",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "taakw",
                            [
                                {
                                    "tlingit": "taakw aanási",
                                    "url": "taakw aanási",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "jellyfish",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "eetée?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "5_58",
                        "N"
                    ]
                ]
            }
        ]
    },
    "6": {
        "title": "Health and medical",
        "content": [
            {
                "type": "ex",
                "english": "Where is your medicine?",
                "tlingit": [
                    [
                        [
                            "Goosóo",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "náagu?",
                            [
                                {
                                    "tlingit": "kóoshdaa náagu",
                                    "url": "kóoshdaa náagu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "liniment",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tux̲'wáns'i náagu",
                                    "url": "tux̲'wáns'i náakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pepper",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is this your medicine?",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "náagu",
                            [
                                {
                                    "tlingit": "kóoshdaa náagu",
                                    "url": "kóoshdaa náagu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "liniment",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tux̲'wáns'i náagu",
                                    "url": "tux̲'wáns'i náakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pepper",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ákyá?",
                            [
                                {
                                    "tlingit": "ákyá",
                                    "url": "ákyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Did you take your medicine?",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "náagu",
                            [
                                {
                                    "tlingit": "kóoshdaa náagu",
                                    "url": "kóoshdaa náagu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "liniment",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tux̲'wáns'i náagu",
                                    "url": "tux̲'wáns'i náakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pepper",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "keeyanóot'?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is your medicine running out?",
                "tlingit": [
                    [
                        [
                            "Náakw",
                            [
                                {
                                    "tlingit": "náakw",
                                    "url": "náakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "medicine",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ák.wé",
                            [
                                {
                                    "tlingit": "ák.wé",
                                    "url": "ák.wé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeedéi",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dé(i) ~ -de(i)",
                                    "url": "-dé(i) ~ -de(i)",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "to, toward; until; in the manner of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yaa shunaxíx?",
                            [
                                {
                                    "tlingit": "yaa shunaxíx",
                                    "url": "shuwaxeex",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's coming to an end",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du jeedé yaa shunaxíx",
                                    "url": "du jeet shuwaxíx",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is running out of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are you sick?",
                "tlingit": [
                    [
                        [
                            "lyanéekw",
                            [],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm sick.",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yanéekw.",
                            [
                                {
                                    "tlingit": "yanéekw",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's sick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm really sick.",
                "tlingit": [
                    [
                        [
                            "K̲únáx̲",
                            [
                                {
                                    "tlingit": "k̲únáx̲",
                                    "url": "k̲únáx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "very",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yanéekw.",
                            [
                                {
                                    "tlingit": "yanéekw",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's sick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not sick.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "unéekw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is s/he sick?",
                "tlingit": [
                    [
                        [
                            "Yanéekw",
                            [
                                {
                                    "tlingit": "yanéekw",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's sick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ágé?",
                            [
                                {
                                    "tlingit": "ágé",
                                    "url": "ágé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[focus + interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "No, but s/he's not strong.",
                "tlingit": [
                    [
                        [
                            "Tléik',",
                            [
                                {
                                    "tlingit": "tléik'",
                                    "url": "tléik'",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲u.aa",
                            [
                                {
                                    "tlingit": "k̲u.aa",
                                    "url": "k̲u.aa",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "however",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ulcheen.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's sick.",
                "tlingit": [
                    [
                        [
                            "Yanéekw.",
                            [
                                {
                                    "tlingit": "yanéekw",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's sick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's not sick.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "unéekw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are you cold?",
                "tlingit": [
                    [
                        [
                            "Iseiwa.át'",
                            [
                                {
                                    "tlingit": "awsi.át'",
                                    "url": "awsi.át'",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he chilled it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he has sugar diabetes.",
                "tlingit": [
                    [
                        [
                            "Sugar",
                            [],
                            []
                        ],
                        [
                            "diabetes",
                            [],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú.",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Nukdzi",
                            [
                                {
                                    "tlingit": "wulinúkts",
                                    "url": "wulinúkts",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "it was sweet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wulinúkts",
                                    "url": "wulinúkts",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "it was sweet",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "néekw",
                            [
                                {
                                    "tlingit": "néekw",
                                    "url": "néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sickness; illness; disease",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú.",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Nukdzi",
                            [
                                {
                                    "tlingit": "wulinúkts",
                                    "url": "wulinúkts",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "it was sweet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wulinúkts",
                                    "url": "wulinúkts",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "it was sweet",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "néekwch",
                            [
                                {
                                    "tlingit": "kei néekwch",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets sick (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "woosháat.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Nukdzi",
                            [
                                {
                                    "tlingit": "wulinúkts",
                                    "url": "wulinúkts",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "it was sweet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wulinúkts",
                                    "url": "wulinúkts",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "it was sweet",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "néekwch",
                            [
                                {
                                    "tlingit": "kei néekwch",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets sick (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "jáak̲w.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "6_14",
                        "J"
                    ],
                    [
                        "6_15",
                        "N"
                    ],
                    [
                        "6_16",
                        "J"
                    ],
                    [
                        "6_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he has a headache.",
                "tlingit": [
                    [
                        [
                            "Du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháan",
                            [],
                            []
                        ],
                        [
                            "yanéekw.",
                            [
                                {
                                    "tlingit": "yanéekw",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's sick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I don't have a headache.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháan",
                            [],
                            []
                        ],
                        [
                            "tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "unéekw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "How are you feeling?",
                "tlingit": [
                    [
                        [
                            "Wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "sh",
                            [
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tukdlig̲éi",
                                    "url": "sh tukdlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo altóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is studying it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "teedinook?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "So-so.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wáa sá.",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not feeling very well.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tlax̲",
                            [
                                {
                                    "tlingit": "tlax̲",
                                    "url": "tlax̲",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "very",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tooshk'é.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I think I'm getting sick.",
                "tlingit": [
                    [
                        [
                            "Kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nanéekw",
                            [
                                {
                                    "tlingit": "kei nanéekw",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's getting sick",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "shákdé",
                            [],
                            []
                        ],
                        [
                            "yá.",
                            [
                                {
                                    "tlingit": "yá",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Maybe you're tired?",
                "tlingit": [
                    [
                        [
                            "Iwdixwétl",
                            [
                                {
                                    "tlingit": "wudixwétl",
                                    "url": "wudixwétl",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he's tired; s/he's weary",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gwáa",
                            [
                                {
                                    "tlingit": "gwáa",
                                    "url": "gwáa",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[expression of strong surprise]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_24",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm tired.",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wudixwétl.",
                            [
                                {
                                    "tlingit": "wudixwétl",
                                    "url": "wudixwétl",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's tired; s/he was tired",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's tired.",
                "tlingit": [
                    [
                        [
                            "Wudixwétl.",
                            [
                                {
                                    "tlingit": "wudixwétl",
                                    "url": "wudixwétl",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's tired; s/he was tired",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_26",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where does it hurt?",
                "tlingit": [
                    [
                        [
                            "Goosá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yanéekw?",
                            [
                                {
                                    "tlingit": "yanéekw",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's sick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_27",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My throat hurts.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "leitóox̲",
                            [
                                {
                                    "tlingit": "du leitóox̲",
                                    "url": "du leitóox̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her throat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yanéekw.",
                            [
                                {
                                    "tlingit": "yanéekw",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's sick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "leitóox̲",
                            [
                                {
                                    "tlingit": "du leitóox̲",
                                    "url": "du leitóox̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her throat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yanóok.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "6_28",
                        "J"
                    ],
                    [
                        "6_29",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I hope you get well.",
                "tlingit": [
                    [
                        [
                            "Át",
                            [
                                {
                                    "tlingit": "át",
                                    "url": "át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "thing",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲wdishée",
                            [],
                            []
                        ],
                        [
                            "iwuneix̲í.",
                            [
                                {
                                    "tlingit": "wooneix̲",
                                    "url": "wooneix̲",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he was saved; s/he was healed; s/he was cured; s/he recovered; s/he was satisfied",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_30",
                        "J"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Note. In Tlingit, nouns for body parts are always used with a possessive pronoun (my, your, his, her, etc.) which is sometimes called a possessive object (of me, of you, of him, of her, etc.). Note that there is no gender distinction in Tlingit. English \"his\" and \"her\" are the same word in Tlingit."
            },
            {
                "type": "ex",
                "english": "his/her hand",
                "tlingit": [
                    [
                        [
                            "du jín",
                            [
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_31",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/ her head",
                "tlingit": [
                    [
                        [
                            "du shá",
                            [
                                {
                                    "tlingit": "du shá",
                                    "url": "du shá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her head",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Shá!",
                                    "url": "aawasháa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Marry him/her!",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_32",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her foot",
                "tlingit": [
                    [
                        [
                            "du x̲'oos",
                            [
                                {
                                    "tlingit": "du x̲'oos",
                                    "url": "du x̲'oos",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her foot, leg",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "k̲aa x̲'oos",
                                    "url": "k̲aa x̲'oos",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "foot (measurement)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa x̲'oos deiyí",
                                    "url": "k̲aa x̲'oos deiyí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "foot path",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "g̲agaan át x̲'oos uwatsóow",
                                    "url": "g̲agaan át x̲'oos uwatsóow",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "sun rays are shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲agaan aadé x̲'oos gug̲atsóow",
                                    "url": "g̲agaan át x̲'oos uwatsóow",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "sun rays will shine on it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_33",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her knee",
                "tlingit": [
                    [
                        [
                            "du keey",
                            [
                                {
                                    "tlingit": "du keey",
                                    "url": "du keey",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her knee",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du keey shakanóox'u",
                                    "url": "du keey shakanóox'u",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her kneecap",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du keey shakanóox'u",
                                    "url": "du kiyshakanóox'u",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her kneecap",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_34",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her shoulder",
                "tlingit": [
                    [
                        [
                            "du x̲eek",
                            [
                                {
                                    "tlingit": "du x̲eek",
                                    "url": "du x̲eek",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her upper arm",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_35",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her eye",
                "tlingit": [
                    [
                        [
                            "du waak̲",
                            [
                                {
                                    "tlingit": "du waak̲",
                                    "url": "du waak̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her eye",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_36",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her ear",
                "tlingit": [
                    [
                        [
                            "du gúk",
                            [
                                {
                                    "tlingit": "du gúk",
                                    "url": "du gúk",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her ear",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_37",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her nose",
                "tlingit": [
                    [
                        [
                            "du lú",
                            [
                                {
                                    "tlingit": "du lú",
                                    "url": "du lú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her nose",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a lú",
                                    "url": "a lú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its point (of a long thin pointed object)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_38",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her mouth",
                "tlingit": [
                    [
                        [
                            "du x̲'é",
                            [
                                {
                                    "tlingit": "du x̲'é",
                                    "url": "du x̲'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mouth",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'é",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "its mouth, opening",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'é yak'éi",
                                    "url": "du x̲'é wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he likes the taste of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du x̲'é wook'éi",
                                    "url": "du x̲'é wook'éi",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he liked the taste of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'é kei kg̲wak'éi",
                                    "url": "du x̲'é wook'éi",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will like the taste of it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_39",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her face",
                "tlingit": [
                    [
                        [
                            "du yá",
                            [
                                {
                                    "tlingit": "du yá",
                                    "url": "du yá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her face",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yá",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_40",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her hair",
                "tlingit": [
                    [
                        [
                            "du shax̲aawú",
                            [
                                {
                                    "tlingit": "du shax̲aawú",
                                    "url": "du shax̲aawú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hair",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_41",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "your chin",
                "tlingit": [
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téey",
                            [
                                {
                                    "tlingit": "téey",
                                    "url": "téey",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "patch",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_42",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "your finger",
                "tlingit": [
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tl'eik̲",
                            [
                                {
                                    "tlingit": "du tl'eik̲",
                                    "url": "du tl'eek̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her finger",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tl'eik̲",
                                    "url": "du tl'eik̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her finger",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tl'eek̲",
                            [
                                {
                                    "tlingit": "du tl'eek̲",
                                    "url": "du tl'eek̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her finger",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tl'eek̲",
                                    "url": "du tl'eik̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her finger",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "6_43",
                        "J"
                    ],
                    [
                        "6_44",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "your forehead",
                "tlingit": [
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káak'",
                            [
                                {
                                    "tlingit": "du káak'",
                                    "url": "du káak'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her forehead",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_45",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "my tooth",
                "tlingit": [
                    [
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "oox̲",
                            [
                                {
                                    "tlingit": "du oox̲",
                                    "url": "du oox̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her tooth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "oox̲ katság̲aa",
                                    "url": "oox̲ katság̲aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "toothpick",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ layeix̲í",
                                    "url": "k̲aa oox̲ layeix̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ yei daanéiyi",
                                    "url": "k̲aa oox̲ yei daanéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ leyeix̲í",
                                    "url": "k̲aa oox̲ layeix̲í",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_46",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "my chest",
                "tlingit": [
                    [
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wóow",
                            [
                                {
                                    "tlingit": "wóow",
                                    "url": "wóow",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "food, lunch, provisions taken along (on a trip, to work or school)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_47",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "my throat",
                "tlingit": [
                    [
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "leitóox̲",
                            [
                                {
                                    "tlingit": "du leitóox̲",
                                    "url": "du leitóox̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her throat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_48",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "my tummy",
                "tlingit": [
                    [
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'óol'",
                            [
                                {
                                    "tlingit": "du x̲'óol'",
                                    "url": "du x̲'óol'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her belly, paunch",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'óol' kastóox̲'",
                                    "url": "du x̲'óol' kastóox̲'",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her stomach is growling",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'óol' kadatóox̲'",
                                    "url": "du x̲'óol' kawditóox̲'",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her stomach is growling",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'óol' kawditóox̲'",
                                    "url": "du x̲'óol' kawditóox̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her stomach growled",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'óol' kagux̲datóox̲'",
                                    "url": "du x̲'óol' kawditóox̲'",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "his/her stomach will growl",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "6_49",
                        "J"
                    ]
                ]
            }
        ]
    },
    "7": {
        "title": "Feelings I: Happy and sad",
        "content": [
            {
                "type": "ex",
                "english": "I'm feeling fine.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú yak'éi.",
                            [
                                {
                                    "tlingit": "du toowú yak'éi",
                                    "url": "du toowú wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú gak'éich",
                                    "url": "du toowú wook'éi",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets happy (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú klig̲éi",
                                    "url": "du toowú kawlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm happy.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú sigóo.",
                            [
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du toowú wsigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm pleased with it.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú",
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "alik'éi.",
                            [
                                {
                                    "tlingit": "awlik'éi",
                                    "url": "awlik'éi",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he improved it; s/he made peace with him/her/it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm proud.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú klig̲éi.",
                            [
                                {
                                    "tlingit": "du toowú klig̲éi",
                                    "url": "du toowú kawlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú kawlig̲éi",
                                    "url": "du toowú kawlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he became proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú yak'éi",
                                    "url": "du toowú wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú alt'eix̲",
                                    "url": "du toowú awlit'áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is comforting him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú",
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "klig̲ée.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "7_4",
                        "J"
                    ],
                    [
                        "7_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm so proud of you.",
                "tlingit": [
                    [
                        [
                            "Tlax̲",
                            [
                                {
                                    "tlingit": "tlax̲",
                                    "url": "tlax̲",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "very",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú klig̲éi",
                            [
                                {
                                    "tlingit": "du toowú klig̲éi",
                                    "url": "du toowú kawlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú kawlig̲éi",
                                    "url": "du toowú kawlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he became proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú yak'éi",
                                    "url": "du toowú wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kaax̲.",
                            [
                                {
                                    "tlingit": "a kaax̲",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "ablative"
                                    ],
                                    "english": "off of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm proud of it.",
                "tlingit": [
                    [
                        [
                            "A kaax̲",
                            [
                                {
                                    "tlingit": "a kaax̲",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "ablative"
                                    ],
                                    "english": "off of it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú klig̲éi.",
                            [
                                {
                                    "tlingit": "du toowú klig̲éi",
                                    "url": "du toowú kawlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú kawlig̲éi",
                                    "url": "du toowú kawlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he became proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú yak'éi",
                                    "url": "du toowú wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú alt'eix̲",
                                    "url": "du toowú awlit'áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is comforting him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm sad. (lit. \"My emotions are sick.\")",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú",
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yanéekw.",
                            [
                                {
                                    "tlingit": "yanéekw",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's sick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We're sad.",
                "tlingit": [
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú",
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yanéekw.",
                            [
                                {
                                    "tlingit": "yanéekw",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's sick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Why are you sad?",
                "tlingit": [
                    [
                        [
                            "Wáanáx̲",
                            [],
                            []
                        ],
                        [
                            "sáwé",
                            [],
                            []
                        ],
                        [
                            "i toowú",
                            [
                                {
                                    "tlingit": "I toowú ksagóo!",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Be happy!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "I toowú kaklag̲éi!",
                                    "url": "du toowú kawlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Be proud!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du toowú lat'á!",
                                    "url": "du toowú awlit'áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Comfort him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú yak'éi",
                                    "url": "du toowú wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yanéekw?",
                            [
                                {
                                    "tlingit": "yanéekw",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's sick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What makes you feel so sad?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sáwé",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i toowú",
                            [
                                {
                                    "tlingit": "I toowú ksagóo!",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Be happy!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "I toowú kaklag̲éi!",
                                    "url": "du toowú kawlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Be proud!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du toowú lat'á!",
                                    "url": "du toowú awlit'áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Comfort him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú yak'éi",
                                    "url": "du toowú wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "asinéekw?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not feeling sad.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú",
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "unéekw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They became sad.",
                "tlingit": [
                    [
                        [
                            "Tuwunéekw",
                            [],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aawat'ei.",
                            [
                                {
                                    "tlingit": "aawat'ei",
                                    "url": "aawat'ei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he found it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are you [plural] happy now?",
                "tlingit": [
                    [
                        [
                            "Yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeedát",
                            [
                                {
                                    "tlingit": "yeedát",
                                    "url": "yeedát",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "now",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yee toowú sigóo?",
                            [
                                {
                                    "tlingit": "Yee toowú ksagóo!",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be happy!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yee toowú kaklag̲éi!",
                                    "url": "du toowú kawlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be proud!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú yak'éi",
                                    "url": "du toowú wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú awlit'áa",
                                    "url": "du toowú awlit'áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it comforted him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You [plural] will be happy.",
                "tlingit": [
                    [
                        [
                            "Yee toowú kei gux̲sagóo.",
                            [
                                {
                                    "tlingit": "du toowú kei gux̲sagóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will be happy",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú kei kagux̲lag̲éi",
                                    "url": "du toowú kawlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will be proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú kei sagóowch",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets happy (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú kei kg̲wak'éi",
                                    "url": "du toowú wook'éi",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will be happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú agux̲lat'áa",
                                    "url": "du toowú awlit'áa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will comfort him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa toowú let'aa",
                                    "url": "k̲aa toowú lat'aa",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "comfort",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It makes me sad/sick.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú",
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "asinéekw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm feeling brave/strong.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú",
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "litseen.",
                            [
                                {
                                    "tlingit": "litseen",
                                    "url": "wulitseen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is strong",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not feeling brave/strong.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú",
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ulcheen.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She/he/it is good.",
                "tlingit": [
                    [
                        [
                            "Yak'éi.",
                            [
                                {
                                    "tlingit": "yak'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She/he/it is not good.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ushk'é.",
                            [
                                {
                                    "tlingit": "l ushk'é",
                                    "url": "l ushk'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "evil, sin",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél ushk'é",
                                    "url": "tlél wushk'é",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is bad",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not feeling fine.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú",
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ushk'é.",
                            [
                                {
                                    "tlingit": "l ushk'é",
                                    "url": "l ushk'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "evil, sin",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél ushk'é",
                                    "url": "tlél wushk'é",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is bad",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not feeling happy.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú",
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ushgú.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What's the problem? (What's bothering you?)",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sáwé",
                            [],
                            []
                        ],
                        [
                            "ikaawaxíl'?",
                            [
                                {
                                    "tlingit": "akaawaxíl'",
                                    "url": "akaawaxíl'",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it bothered him/her; he/she/it is bothering him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Why are you crying?",
                "tlingit": [
                    [
                        [
                            "Wáanáx̲",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eeg̲áax̲?",
                            [
                                {
                                    "tlingit": "woog̲aax̲",
                                    "url": "woog̲aax̲",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he cried; s/he wept; s/he mourned; s/he lamented",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_24",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are you missing mom / your mother?",
                "tlingit": [
                    [
                        [
                            "l",
                            [
                                {
                                    "tlingit": "l",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tláa",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yisiháa?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are you missing dad / your father?",
                "tlingit": [
                    [
                        [
                            "l",
                            [
                                {
                                    "tlingit": "l",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yisiháa?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_26",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm lonesome.",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wuliteesh.",
                            [
                                {
                                    "tlingit": "wuliteesh",
                                    "url": "wuliteesh",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's lonely",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_27",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he is lonesome.",
                "tlingit": [
                    [
                        [
                            "Wuliteesh.",
                            [
                                {
                                    "tlingit": "wuliteesh",
                                    "url": "wuliteesh",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's lonely",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_28",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You're lonesome.",
                "tlingit": [
                    [
                        [
                            "Iwliteesh.",
                            [
                                {
                                    "tlingit": "wuliteesh",
                                    "url": "wuliteesh",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he's lonely",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wuliteesh",
                                    "url": "wuliteesh",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he's lonely",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_29",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Maybe you're lonesome?",
                "tlingit": [
                    [
                        [
                            "Iwliteesh",
                            [
                                {
                                    "tlingit": "wuliteesh",
                                    "url": "wuliteesh",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he's lonely",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wuliteesh",
                                    "url": "wuliteesh",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he's lonely",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gwáa",
                            [
                                {
                                    "tlingit": "gwáa",
                                    "url": "gwáa",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[expression of strong surprise]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_30",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not lonesome.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wulteesh.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_31",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Poor thing / poor guy / pitiful.",
                "tlingit": [
                    [
                        [
                            "Eesháan.",
                            [
                                {
                                    "tlingit": "Eesháan!",
                                    "url": "Eesháan!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Poor thing!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_32",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Poor me.",
                "tlingit": [
                    [
                        [
                            "Eesháan",
                            [
                                {
                                    "tlingit": "Eesháan!",
                                    "url": "Eesháan!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Poor thing!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲át.",
                            [
                                {
                                    "tlingit": "x̲át",
                                    "url": "x̲át",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "I [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_33",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Poor you.",
                "tlingit": [
                    [
                        [
                            "Eesháan",
                            [
                                {
                                    "tlingit": "Eesháan!",
                                    "url": "Eesháan!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Poor thing!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wa.é.",
                            [
                                {
                                    "tlingit": "wa.é",
                                    "url": "wa.é",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (singular) [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_34",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Poor us. (Take pity on us.)",
                "tlingit": [
                    [
                        [
                            "Eesháan",
                            [
                                {
                                    "tlingit": "Eesháan!",
                                    "url": "Eesháan!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Poor thing!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "uháan.",
                            [
                                {
                                    "tlingit": "uháan",
                                    "url": "uháan",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "we [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_35",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are you angry?",
                "tlingit": [
                    [
                        [
                            "X'áant",
                            [
                                {
                                    "tlingit": "x'áant uwanúk",
                                    "url": "x'áant uwanúk",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's angry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x'áant unúkch",
                                    "url": "x'áant uwanúk",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets angry (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "X'áant núk!",
                                    "url": "x'áant uwanúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Get angry!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "X'áant yinúk!",
                                    "url": "x'áant uwanúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all get angry!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iyanúk?",
                            [
                                {
                                    "tlingit": "x'áant uwanúk",
                                    "url": "x'áant uwanúk",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he's angry",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "X'áan",
                            [
                                {
                                    "tlingit": "x'áan",
                                    "url": "x'áan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anger",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ágé",
                            [
                                {
                                    "tlingit": "ágé",
                                    "url": "ágé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[focus + interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iya.óo?",
                            [
                                {
                                    "tlingit": "yéi aawa.oo",
                                    "url": "yéi aawa.oo",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he wore it; s/he put it on; s/he used it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "X'áan",
                            [
                                {
                                    "tlingit": "x'áan",
                                    "url": "x'áan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anger",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "itoowú?",
                            [
                                {
                                    "tlingit": "i-",
                                    "url": "i-",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (singular) [subject]",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "7_36",
                        "N"
                    ],
                    [
                        "7_37",
                        "J"
                    ],
                    [
                        "7_38",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's angry.",
                "tlingit": [
                    [
                        [
                            "X'áan",
                            [
                                {
                                    "tlingit": "x'áan",
                                    "url": "x'áan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anger",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aya.óo.",
                            [
                                {
                                    "tlingit": "yéi aya.óo",
                                    "url": "yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's wearing it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "X'áant uwanúk.",
                            [
                                {
                                    "tlingit": "x'áant uwanúk",
                                    "url": "x'áant uwanúk",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's angry",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "x'áant unúkch",
                                    "url": "x'áant uwanúk",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets angry (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "X'áant núk!",
                                    "url": "x'áant uwanúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Get angry!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "X'áant yinúk!",
                                    "url": "x'áant uwanúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all get angry!",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "7_39",
                        "J"
                    ],
                    [
                        "7_40",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm angry.",
                "tlingit": [
                    [
                        [
                            "X'áant",
                            [
                                {
                                    "tlingit": "x'áant uwanúk",
                                    "url": "x'áant uwanúk",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's angry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x'áant unúkch",
                                    "url": "x'áant uwanúk",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets angry (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "X'áant núk!",
                                    "url": "x'áant uwanúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Get angry!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "X'áant yinúk!",
                                    "url": "x'áant uwanúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all get angry!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲waanúk.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "X'áan",
                            [
                                {
                                    "tlingit": "x'áan",
                                    "url": "x'áan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anger",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aa.óo.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "X'áan",
                            [
                                {
                                    "tlingit": "x'áan",
                                    "url": "x'áan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anger",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú.",
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "7_41",
                        "J"
                    ],
                    [
                        "7_42",
                        "N"
                    ],
                    [
                        "7_43",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It hurts him when you do that.",
                "tlingit": [
                    [
                        [
                            "Ash sinéekw",
                            [
                                {
                                    "tlingit": "ash sinéekw",
                                    "url": "ash wusinéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's making him/her sick; it makes him/her sick",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "ash wusinéekw",
                                    "url": "ash wusinéekw",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it made him/her sick",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusix̲ék̲",
                                    "url": "ash wusix̲ék̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it kept him/her awake",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusitáax'",
                                    "url": "ash wusitáax'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it bit him/her/it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wulixwétl",
                                    "url": "awlixwétl",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it made him/her tired",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash kawdliyát",
                                    "url": "ash kawdliyát",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he played",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash gasanéekwch",
                                    "url": "ash wusinéekw",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "it makes him/her sick (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei ash nasnéekw",
                                    "url": "ash wusinéekw",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's starting to make him/her sick",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daa.eeneiyí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_44",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's got a bad mouth / isn't talking nice.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "chaa",
                            [],
                            []
                        ],
                        [
                            "x̲'eití.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_45",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Say, \"I'm sorry.\"",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú",
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yanéekw,\"",
                            [
                                {
                                    "tlingit": "yanéekw",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's sick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yóo",
                            [
                                {
                                    "tlingit": "yóo",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóo",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (distant), yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wunak̲á.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_46",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What can we do to help him?",
                "tlingit": [
                    [
                        [
                            "Wáa",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "du éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet x̲'eiwatán",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he spoke to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet oohées'ch",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he lends it to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲ooháaych",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets a turn (every time)",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet yidashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa éet has jiwdi.át",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Pl"
                                    ],
                                    "english": "they attacked someone",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "g̲atudishee?",
                            [
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wáa",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sáwé",
                            [],
                            []
                        ],
                        [
                            "du éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet x̲'eiwatán",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he spoke to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet oohées'ch",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he lends it to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲ooháaych",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets a turn (every time)",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet yidashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa éet has jiwdi.át",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Pl"
                                    ],
                                    "english": "they attacked someone",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "g̲aduwashee?",
                            [
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "7_47",
                        "J"
                    ],
                    [
                        "7_48",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Have courage. [singular]",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gu.aa",
                            [],
                            []
                        ],
                        [
                            "yáx̲",
                            [
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the face of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yáx̲",
                                    "tags": [
                                        "RelationalPostposition"
                                    ],
                                    "english": "like it, in accordance with it, as much as it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaaw yáx̲",
                                    "url": "gaaw yáx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "on time; in time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Wooch yáx̲ nasné!",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Straighten it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wudlitl'éit'",
                                    "url": "a yáx̲ wudlitl'éit'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it climbed the face of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x'wán.",
                            [
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boot(s)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "be sure to",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gu.aax̲",
                            [],
                            []
                        ],
                        [
                            "x'wán.",
                            [
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boot(s)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "be sure to",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "7_49",
                        "J"
                    ],
                    [
                        "7_50",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Have courage. lpJ",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gu.aa",
                            [],
                            []
                        ],
                        [
                            "yáx̲",
                            [
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the face of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yáx̲",
                                    "tags": [
                                        "RelationalPostposition"
                                    ],
                                    "english": "like it, in accordance with it, as much as it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaaw yáx̲",
                                    "url": "gaaw yáx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "on time; in time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Wooch yáx̲ nasné!",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Straighten it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wudlitl'éit'",
                                    "url": "a yáx̲ wudlitl'éit'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it climbed the face of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x'wán.",
                            [
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boot(s)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "be sure to",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gu.aax̲",
                            [],
                            []
                        ],
                        [
                            "x'wán.",
                            [
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boot(s)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "be sure to",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "7_51",
                        "J"
                    ],
                    [
                        "7_52",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Your poor hand.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jín",
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ée!",
                            [
                                {
                                    "tlingit": "Ée!",
                                    "url": "Ée!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Yuck!; Eeeew!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Aatlein",
                            [
                                {
                                    "tlingit": "aatlein",
                                    "url": "aatlein",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "much, lots of, really",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "át",
                            [
                                {
                                    "tlingit": "át",
                                    "url": "át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "thing",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jín.",
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "7_53",
                        "J"
                    ],
                    [
                        "7_54",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Woe! (Oh, my precious thing!)",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "adée!",
                            [
                                {
                                    "tlingit": "át",
                                    "url": "át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "thing",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_55",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Oh, daughter! (calling with tender emotion)",
                "tlingit": [
                    [
                        [
                            "Sik'",
                            [],
                            []
                        ],
                        [
                            "éi!",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séek'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "7_56",
                        "N"
                    ],
                    [
                        "7_57",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Oh, grandchild! (calling with tender emotion)",
                "tlingit": [
                    [
                        [
                            "Chx̲ánk'",
                            [
                                {
                                    "tlingit": "du dachx̲án",
                                    "url": "du dachx̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her grandchild",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-k'",
                                    "url": "-k'",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "little; precious; [diminutive suffix]",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "éi!",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dachx̲ánk'.",
                            [
                                {
                                    "tlingit": "du dachx̲án",
                                    "url": "du dachx̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her grandchild",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-k'",
                                    "url": "-k'",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "little; precious; [diminutive suffix]",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "7_58",
                        "N"
                    ],
                    [
                        "7_59",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he is thankful/satisfied.",
                "tlingit": [
                    [
                        [
                            "Sh tóog̲aa ditee.",
                            [
                                {
                                    "tlingit": "sh tóog̲aa ditee",
                                    "url": "sh tóog̲aa wditee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is grateful",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sh tóog̲aa wditee",
                                    "url": "sh tóog̲aa wditee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was grateful",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo at iltóow",
                                    "url": "sh tóo at wudlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he studies; s/he is studying",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo awdlitóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he studied it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kawdlineek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he told a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh x̲'awdis'eik̲",
                                    "url": "sh x̲'awdis'eik̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he smoked",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tukdlig̲éi",
                                    "url": "sh tukdlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh daxash washéen",
                                    "url": "sh daxash washéen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "chainsaw",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_60",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's OK with him/her.",
                "tlingit": [
                    [
                        [
                            "Du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tóog̲aa",
                            [
                                {
                                    "tlingit": "sh tóog̲aa ditee",
                                    "url": "sh tóog̲aa wditee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is grateful",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóog̲aa wditee",
                                    "url": "sh tóog̲aa wditee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was grateful",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Sh tóog̲aa indatí!",
                                    "url": "sh tóog̲aa wditee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Be grateful!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóog̲aa ndateech",
                                    "url": "sh tóog̲aa wditee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he is grateful (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóog̲aa gux̲datée",
                                    "url": "sh tóog̲aa wditee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will be grateful",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Sh tóog̲aa yeendatí!",
                                    "url": "sh tóog̲aa wditee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be grateful!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóog̲aa yoo diteek",
                                    "url": "sh tóog̲aa wditee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is grateful (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Sh",
                            [
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tukdlig̲éi",
                                    "url": "sh tukdlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo altóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is studying it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "tugaa",
                            [],
                            []
                        ],
                        [
                            "ditee.",
                            [
                                {
                                    "tlingit": "sh tóog̲aa ditee",
                                    "url": "sh tóog̲aa wditee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is grateful",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "7_61",
                        "J"
                    ],
                    [
                        "7_62",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he is obedient to him/her.",
                "tlingit": [
                    [
                        [
                            "Ash",
                            [
                                {
                                    "tlingit": "ash koolyát",
                                    "url": "ash kawdliyát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is playing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash sinéekw",
                                    "url": "ash wusinéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's making him/her sick; it makes him/her sick",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusitáax'",
                                    "url": "ash wusitáax'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it bit him/her/it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusix̲ék̲",
                                    "url": "ash wusix̲ék̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it kept him/her awake",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wulixwétl",
                                    "url": "awlixwétl",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it made him/her tired",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash kawdliyát",
                                    "url": "ash kawdliyát",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he played",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusinéekw",
                                    "url": "ash wusinéekw",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it made him/her sick",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éig̲aa",
                            [],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "7_63",
                        "J"
                    ]
                ]
            }
        ]
    },
    "8": {
        "title": "Feelings II: Having, needing, wanting, sleepy",
        "content": [
            {
                "type": "ex",
                "english": "What do you have?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú?",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_1",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I have a fish.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú.",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_2",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he has a knife.",
                "tlingit": [
                    [
                        [
                            "Lítaa",
                            [
                                {
                                    "tlingit": "lítaa",
                                    "url": "lítaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "knife",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú.",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_3",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We have fish.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú.",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_4",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you have a Chilkat blanket?",
                "tlingit": [
                    [
                        [
                            "Naaxein",
                            [
                                {
                                    "tlingit": "naaxein",
                                    "url": "naaxein",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Chilkat blanket",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú?",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_5",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you have money?",
                "tlingit": [
                    [
                        [
                            "Dáanaa",
                            [
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "silver",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "money, coin, dollar",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú?",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_6",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he has lots of money.",
                "tlingit": [
                    [
                        [
                            "Aatlein",
                            [
                                {
                                    "tlingit": "aatlein",
                                    "url": "aatlein",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "much, lots of, really",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dáanaa",
                            [
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "silver",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "money, coin, dollar",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú.",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Dáanaa",
                            [
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "silver",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "money, coin, dollar",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tlein",
                            [
                                {
                                    "tlingit": "tlein",
                                    "url": "tlein",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "big",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú.",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Dáanaa",
                            [
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "silver",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "money, coin, dollar",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du jee",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yagéi.",
                            [
                                {
                                    "tlingit": "yagéi",
                                    "url": "woogéi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "there are many",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "8_7",
                        "J"
                    ],
                    [
                        "8_8",
                        "N"
                    ],
                    [
                        "8_9",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I don't have any money.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dáanaa",
                            [
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "silver",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "money, coin, dollar",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jee.",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_10",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I need two dollars.",
                "tlingit": [
                    [
                        [
                            "Déix̲",
                            [
                                {
                                    "tlingit": "déix̲",
                                    "url": "déix̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dáanaa",
                            [
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "silver",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "money, coin, dollar",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eetéenáx̲",
                            [
                                {
                                    "tlingit": "a eetéenáx̲",
                                    "url": "a eetéenáx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "lacking it; without it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ wootee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needed it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ nateech",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ gug̲atée",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will need it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yoo yateek",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_11",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We need two dollars.",
                "tlingit": [
                    [
                        [
                            "Déix̲",
                            [
                                {
                                    "tlingit": "déix̲",
                                    "url": "déix̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dáanaa",
                            [
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "silver",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "money, coin, dollar",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eetéenáx̲",
                            [
                                {
                                    "tlingit": "a eetéenáx̲",
                                    "url": "a eetéenáx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "lacking it; without it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ wootee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needed it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ nateech",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ gug̲atée",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will need it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yoo yateek",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_12",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Loan me some.",
                "tlingit": [
                    [
                        [
                            "Ax̲ éet",
                            [
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet yidashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all help me!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hís'.",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_13",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Who's got the cash?",
                "tlingit": [
                    [
                        [
                            "Aadoo",
                            [
                                {
                                    "tlingit": "aadoo",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Who",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadoo",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Who",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dáanaa?",
                            [
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "silver",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "money, coin, dollar",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Aadooch",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "lashát",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dáanaa?",
                            [
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "silver",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "money, coin, dollar",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "8_14",
                        "N"
                    ],
                    [
                        "8_15",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I have it.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "á.",
                            [
                                {
                                    "tlingit": "á",
                                    "url": "á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "it; that place, time, reason, fact",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "á",
                                    "url": "á2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[puts focus on preceding phrase]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_16",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you have it?",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_17",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You [singular] have it.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "á.",
                            [
                                {
                                    "tlingit": "á",
                                    "url": "á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "it; that place, time, reason, fact",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "á",
                                    "url": "á2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[puts focus on preceding phrase]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_18",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he has it.",
                "tlingit": [
                    [
                        [
                            "Du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "á.",
                            [
                                {
                                    "tlingit": "á",
                                    "url": "á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "it; that place, time, reason, fact",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "á",
                                    "url": "á2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[puts focus on preceding phrase]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_19",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We have it.",
                "tlingit": [
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "á.",
                            [
                                {
                                    "tlingit": "á",
                                    "url": "á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "it; that place, time, reason, fact",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "á",
                                    "url": "á2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[puts focus on preceding phrase]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_20",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You [plural] have it.",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "á.",
                            [
                                {
                                    "tlingit": "á",
                                    "url": "á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "it; that place, time, reason, fact",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "á",
                                    "url": "á2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[puts focus on preceding phrase]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_21",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They have it.",
                "tlingit": [
                    [
                        [
                            "Has du",
                            [
                                {
                                    "tlingit": "has du",
                                    "url": "has du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "their [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "jeewú",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "á.",
                            [
                                {
                                    "tlingit": "á",
                                    "url": "á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "it; that place, time, reason, fact",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "á",
                                    "url": "á2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[puts focus on preceding phrase]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_22",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Whose (thing) is it?",
                "tlingit": [
                    [
                        [
                            "Aadóo",
                            [
                                {
                                    "tlingit": "ch'a aadóo sá",
                                    "url": "ch'a aa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a aadóo sá",
                                    "url": "ch'a aadóo sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "aayíx̲",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x̲",
                                    "url": "-x̲",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(in prolonged contact) at; (repeatedly arriving) at; being, in the form of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee?",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_23",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's ours.",
                "tlingit": [
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aayíx̲",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x̲",
                                    "url": "-x̲",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(in prolonged contact) at; (repeatedly arriving) at; being, in the form of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aayí",
                            [
                                {
                                    "tlingit": "taat aayí adéli",
                                    "url": "taat aayí adéli",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "night watchman",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "8_24",
                        "N"
                    ],
                    [
                        "8_25",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's mine.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aayíx̲",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x̲",
                                    "url": "-x̲",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(in prolonged contact) at; (repeatedly arriving) at; being, in the form of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aayí",
                            [
                                {
                                    "tlingit": "taat aayí adéli",
                                    "url": "taat aayí adéli",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "night watchman",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "8_26",
                        "N"
                    ],
                    [
                        "8_27",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That's mine.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aayí",
                            [
                                {
                                    "tlingit": "taat aayí adéli",
                                    "url": "taat aayí adéli",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "night watchman",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_28",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "This is mine.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aayí",
                            [
                                {
                                    "tlingit": "taat aayí adéli",
                                    "url": "taat aayí adéli",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "night watchman",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_29",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That boat is ours.",
                "tlingit": [
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aayíx̲",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x̲",
                                    "url": "-x̲",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(in prolonged contact) at; (repeatedly arriving) at; being, in the form of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sitee",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaakw.",
                            [
                                {
                                    "tlingit": "yaakw",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boat, canoe",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aayí",
                            [
                                {
                                    "tlingit": "taat aayí adéli",
                                    "url": "taat aayí adéli",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "night watchman",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áwé,",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaakw.",
                            [
                                {
                                    "tlingit": "yaakw",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boat, canoe",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "8_30",
                        "N"
                    ],
                    [
                        "8_31",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That one is going to be mine.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aayíx̲",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x̲",
                                    "url": "-x̲",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(in prolonged contact) at; (repeatedly arriving) at; being, in the form of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "goox̲satée",
                            [
                                {
                                    "tlingit": "(noun)-x̲ wusitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he became a (noun)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wéit'aa.",
                            [
                                {
                                    "tlingit": "wéit'aa",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative",
                                        "prnaa"
                                    ],
                                    "english": "that one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_32",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That dog is my very own.",
                "tlingit": [
                    [
                        [
                            "Tléináx̲",
                            [
                                {
                                    "tlingit": "tléináx̲",
                                    "url": "tléináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one (person)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ádi",
                            [
                                {
                                    "tlingit": "du ádi",
                                    "url": "át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its thing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du aan yaduxas' ádi",
                                    "url": "aan yaduxas' át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its razor",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du aankadushxit ádi",
                                    "url": "aankadushxit át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its camera",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du ách at dusx̲a ádi",
                                    "url": "ách at dusx̲a át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its fork",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "keitl.",
                            [
                                {
                                    "tlingit": "keitl",
                                    "url": "keitl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dog",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "K̲únáx̲",
                            [
                                {
                                    "tlingit": "k̲únáx̲",
                                    "url": "k̲únáx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "very",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aayí",
                            [
                                {
                                    "tlingit": "taat aayí adéli",
                                    "url": "taat aayí adéli",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "night watchman",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áwé,",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "keitl.",
                            [
                                {
                                    "tlingit": "keitl",
                                    "url": "keitl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dog",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "K̲únáx̲",
                            [
                                {
                                    "tlingit": "k̲únáx̲",
                                    "url": "k̲únáx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "very",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aayíx̲",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x̲",
                                    "url": "-x̲",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(in prolonged contact) at; (repeatedly arriving) at; being, in the form of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sitee",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "keitl.",
                            [
                                {
                                    "tlingit": "keitl",
                                    "url": "keitl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dog",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "8_33",
                        "N"
                    ],
                    [
                        "8_34",
                        "J"
                    ],
                    [
                        "8_35",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Whose shoe is this?",
                "tlingit": [
                    [
                        [
                            "Aadóo",
                            [
                                {
                                    "tlingit": "ch'a aadóo sá",
                                    "url": "ch'a aa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a aadóo sá",
                                    "url": "ch'a aadóo sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "téeli",
                            [
                                {
                                    "tlingit": "du téeli",
                                    "url": "téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shoe(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sáyá?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_36",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is this your shoe?",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeli",
                            [
                                {
                                    "tlingit": "du téeli",
                                    "url": "téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shoe(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ákyá?",
                            [
                                {
                                    "tlingit": "ákyá",
                                    "url": "ákyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_37",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Yes. That's my shoe.",
                "tlingit": [
                    [
                        [
                            "Aaá.",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeli",
                            [
                                {
                                    "tlingit": "du téeli",
                                    "url": "téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shoe(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_38",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "No. That's not my shoe.",
                "tlingit": [
                    [
                        [
                            "Tléik'.",
                            [
                                {
                                    "tlingit": "tléik'",
                                    "url": "tléik'",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeli",
                            [
                                {
                                    "tlingit": "du téeli",
                                    "url": "téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shoe(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_39",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What do you [singular] want?",
                "tlingit": [
                    [
                        [
                            "Daa sá",
                            [
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléil daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yanees.á!",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Examine it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yoo tutánk!",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Think about it!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo?",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_40",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What do you [plural] want?",
                "tlingit": [
                    [
                        [
                            "Daa sá",
                            [
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléil daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yanees.á!",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Examine it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yoo tutánk!",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Think about it!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo?",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_41",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I want water.",
                "tlingit": [
                    [
                        [
                            "Héen",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_42",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he wants ___.",
                "tlingit": [
                    [
                        [
                            "___ du tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_43",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We want ___.",
                "tlingit": [
                    [
                        [
                            "___ haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_44",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They want ___.",
                "tlingit": [
                    [
                        [
                            "___ has du tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "has du",
                                    "url": "has du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "their [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_45",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you [singular] want ___?",
                "tlingit": [
                    [
                        [
                            "___ gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo?",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_46",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you [plural] want ___?",
                "tlingit": [
                    [
                        [
                            "___ gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo?",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sigóo ___ ?",
                            [
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "8_47",
                        "N"
                    ],
                    [
                        "8_48",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I don't want water.",
                "tlingit": [
                    [
                        [
                            "Héen",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ushgú.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_49",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We don't want ___.",
                "tlingit": [
                    [
                        [
                            "___ tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ushgú.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Tlél ___ haa",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ushgú.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "8_50",
                        "J"
                    ],
                    [
                        "8_51",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm sleepy.",
                "tlingit": [
                    [
                        [
                            "Ax̲ éet",
                            [
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet yidashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all help me!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yataawaháa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_52",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are you sleepy?",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yataawaháa?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_53",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's sleepy.",
                "tlingit": [
                    [
                        [
                            "Du éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet x̲'eiwatán",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he spoke to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet oohées'ch",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he lends it to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲ooháaych",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets a turn (every time)",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet yidashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa éet has jiwdi.át",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Pl"
                                    ],
                                    "english": "they attacked someone",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yataawaháa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_54",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are sleepy.",
                "tlingit": [
                    [
                        [
                            "Has du éet",
                            [
                                {
                                    "tlingit": "has du",
                                    "url": "has du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "their [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet x̲'eiwatán",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he spoke to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet oohées'ch",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he lends it to him/her (every time)",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲aa éet has jiwdi.át",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Pl"
                                    ],
                                    "english": "they attacked someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yataawaháa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_55",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We're sleepy.",
                "tlingit": [
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yataawaháa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_56",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We're not sleepy.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yatawuháa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_57",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not sleepy.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲ éet",
                            [
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet yidashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all help me!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yatawuháa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_58",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's not sleepy.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet x̲'eiwatán",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he spoke to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet oohées'ch",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he lends it to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲ooháaych",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets a turn (every time)",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet yidashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa éet has jiwdi.át",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Pl"
                                    ],
                                    "english": "they attacked someone",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yatawuháa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_59",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "The children are not sleepy.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has du éet",
                            [
                                {
                                    "tlingit": "has du",
                                    "url": "has du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "their [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet x̲'eiwatán",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he spoke to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet oohées'ch",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he lends it to him/her (every time)",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲aa éet has jiwdi.át",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Pl"
                                    ],
                                    "english": "they attacked someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yatawuháa",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yátx'i.",
                            [
                                {
                                    "tlingit": "du yátx'i",
                                    "url": "adátx'i",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its children",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du yátx'i",
                                    "url": "atyátx'i",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its children",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du yátx'i",
                                    "url": "du yátx'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her children",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gán yátx'i",
                                    "url": "gán yátx'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "small pieces of firewood; kindling",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "t'aa yátx'i",
                                    "url": "t'aa yátx'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shingles",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "l'éiw yátx'i",
                                    "url": "l'éiw yátx'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fine sand or gravel",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du yátx'i latíni",
                                    "url": "atyátx'i latíni",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its babysitter",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_60",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's getting sleepy.",
                "tlingit": [
                    [
                        [
                            "Du eedé yaa",
                            [
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du eedé k̲ukg̲waháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will get a turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé x̲'akg̲watáan",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will speak to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ eedé yaa ndashéen",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is beginning to help me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa eedé has jigux̲da.áat",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will attack someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yatanahéin.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_61",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm getting sleepy.",
                "tlingit": [
                    [
                        [
                            "Ax̲ eedé yaa",
                            [
                                {
                                    "tlingit": "ax̲ eedé yaa ndashéen",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is beginning to help me",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲ eedé gux̲dashée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will help me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé x̲'akg̲watáan",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will speak to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa eedé has jigux̲da.áat",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will attack someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yatanahéin.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_62",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not getting sleepy.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲ eedé yaa",
                            [
                                {
                                    "tlingit": "ax̲ eedé yaa ndashéen",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is beginning to help me",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲ eedé gux̲dashée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will help me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé x̲'akg̲watáan",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will speak to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa eedé has jigux̲da.áat",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will attack someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yataanahéin.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "8_63",
                        "N"
                    ]
                ]
            }
        ]
    },
    "9": {
        "title": "Cooking, eating, talking about food",
        "content": [
            {
                "type": "ex",
                "english": "Are you [plural] hungry?",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaan.uwaháa?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_1",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are you [singular] hungry?",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaan.uwaháa?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_2",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm hungry.",
                "tlingit": [
                    [
                        [
                            "Ax̲ éet",
                            [
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet yidashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all help me!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yaan.uwaháa.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaan.uwaháa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_3",
                        "J"
                    ],
                    [
                        "9_4",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not hungry.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaanwuhá.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_5",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We're hungry.",
                "tlingit": [
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yaan.uwaháa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_6",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm getting hungry.",
                "tlingit": [
                    [
                        [
                            "Ax̲ eedé yaa",
                            [
                                {
                                    "tlingit": "ax̲ eedé yaa ndashéen",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is beginning to help me",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲ eedé gux̲dashée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will help me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé x̲'akg̲watáan",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will speak to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa eedé has jigux̲da.áat",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will attack someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yaannahéin.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Ax̲ eedé",
                            [
                                {
                                    "tlingit": "ax̲ eedé gux̲dashée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will help me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ eedé yaa ndashéen",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is beginning to help me",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du eedé k̲ukg̲waháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will get a turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé x̲'akg̲watáan",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will speak to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa eedé has jigux̲da.áat",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will attack someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yaannahéin.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_7",
                        "J"
                    ],
                    [
                        "9_8",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's getting hungry.",
                "tlingit": [
                    [
                        [
                            "Du eedé yaa",
                            [
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du eedé k̲ukg̲waháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will get a turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé x̲'akg̲watáan",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will speak to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ eedé yaa ndashéen",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is beginning to help me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa eedé has jigux̲da.áat",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will attack someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yaannahéin.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Du eedé",
                            [
                                {
                                    "tlingit": "du eedé k̲ukg̲waháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will get a turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé x̲'akg̲watáan",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will speak to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲ eedé gux̲dashée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will help me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa eedé has jigux̲da.áat",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will attack someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ eedé yaa ndashéen",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is beginning to help me",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yaannahéin.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_9",
                        "J"
                    ],
                    [
                        "9_10",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We're getting hungry.",
                "tlingit": [
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eedé yaa",
                            [
                                {
                                    "tlingit": "ax̲ eedé yaa ndashéen",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is beginning to help me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du eedé x̲'akg̲watáan",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will speak to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa eedé has jigux̲da.áat",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will attack someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé k̲ukg̲waháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will get a turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ eedé gux̲dashée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will help me",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yaannahéin.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eedé",
                            [
                                {
                                    "tlingit": "ax̲ eedé gux̲dashée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will help me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé k̲ukg̲waháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will get a turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé x̲'akg̲watáan",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will speak to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa eedé has jigux̲da.áat",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will attack someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ eedé yaa ndashéen",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is beginning to help me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yaannahéin.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_11",
                        "J"
                    ],
                    [
                        "9_12",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm hungry for pilot bread.",
                "tlingit": [
                    [
                        [
                            "G̲áatl",
                            [
                                {
                                    "tlingit": "g̲áatl",
                                    "url": "g̲áatl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pilot bread",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲ éet",
                            [
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet yidashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all help me!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "uwaháa.",
                            [
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_13",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are you hungry for berries?",
                "tlingit": [
                    [
                        [
                            "Tléik̲w",
                            [
                                {
                                    "tlingit": "tléik̲w",
                                    "url": "tléik̲w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "berry, berries",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ágé",
                            [
                                {
                                    "tlingit": "ágé",
                                    "url": "ágé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[focus + interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "uwaháa?",
                            [
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_14",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's hungry for boiled halibut.",
                "tlingit": [
                    [
                        [
                            "Cháatl",
                            [
                                {
                                    "tlingit": "cháatl",
                                    "url": "cháatl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "halibut",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "útlx̲i",
                            [
                                {
                                    "tlingit": "útlx̲i",
                                    "url": "útlx̲i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boiled fish",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet x̲'eiwatán",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he spoke to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet oohées'ch",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he lends it to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲ooháaych",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets a turn (every time)",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet yidashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa éet has jiwdi.át",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Pl"
                                    ],
                                    "english": "they attacked someone",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "uwaháa.",
                            [
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_15",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm hungry for dried fish.",
                "tlingit": [
                    [
                        [
                            "At x̲'éeshi",
                            [
                                {
                                    "tlingit": "at x̲'éeshi",
                                    "url": "at x̲'éeshi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dry fish",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'éeshi",
                                    "url": "a x̲'éeshi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its dried flesh, strips (of fish)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲éidi",
                                    "url": "at x̲éidi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "arrowhead",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲éix̲",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at ux̲'ánch",
                                    "url": "at uwax̲'án",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he dries fish (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gux̲sa.ée",
                                    "url": "at wusi.ée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will cook",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at tux̲'wáns'i",
                                    "url": "at tux̲'wáns'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "buckshot; moccasin lining",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at téix̲i",
                                    "url": "a téix̲i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boiled food; broth",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "ax̲ éet",
                            [
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet yidashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all help me!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "uwaháa.",
                            [
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_16",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm hungry for salmon.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲ éet",
                            [
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet yidashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all help me!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "uwaháa.",
                            [
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_17",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My aunt is hungry for k'ink'.",
                "tlingit": [
                    [
                        [
                            "K'ínk'",
                            [
                                {
                                    "tlingit": "k'ínk'",
                                    "url": "k'ínk'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "aged fish head",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aat",
                            [
                                {
                                    "tlingit": "du aat",
                                    "url": "du aat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her paternal aunt",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "uwaháa.",
                            [
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_18",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm getting hungry for _",
                "tlingit": [
                    [
                        [
                            "___ ax̲ eedé yaa",
                            [
                                {
                                    "tlingit": "ax̲ eedé yaa ndashéen",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is beginning to help me",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲ eedé gux̲dashée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will help me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé x̲'akg̲watáan",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will speak to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa eedé has jigux̲da.áat",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will attack someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "nahéin.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_19",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm getting hungry for pilot bread.",
                "tlingit": [
                    [
                        [
                            "G̲áatl",
                            [
                                {
                                    "tlingit": "g̲áatl",
                                    "url": "g̲áatl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pilot bread",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲ eedé yaa",
                            [
                                {
                                    "tlingit": "ax̲ eedé yaa ndashéen",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is beginning to help me",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲ eedé gux̲dashée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will help me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé x̲'akg̲watáan",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will speak to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa eedé has jigux̲da.áat",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will attack someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "nahéin.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_20",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's getting hungry for pilot bread.",
                "tlingit": [
                    [
                        [
                            "G̲áatl",
                            [
                                {
                                    "tlingit": "g̲áatl",
                                    "url": "g̲áatl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pilot bread",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du eedé yaa",
                            [
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du eedé k̲ukg̲waháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will get a turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé x̲'akg̲watáan",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will speak to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ eedé yaa ndashéen",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is beginning to help me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa eedé has jigux̲da.áat",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will attack someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "nahéin.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_21",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We're getting hungry for pilot bread.",
                "tlingit": [
                    [
                        [
                            "G̲áatl",
                            [
                                {
                                    "tlingit": "g̲áatl",
                                    "url": "g̲áatl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pilot bread",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eedé yaa",
                            [
                                {
                                    "tlingit": "ax̲ eedé yaa ndashéen",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is beginning to help me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du eedé x̲'akg̲watáan",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will speak to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa eedé has jigux̲da.áat",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will attack someone",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé k̲ukg̲waháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will get a turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ eedé gux̲dashée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will help me",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "nahéin.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_22",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm full.",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawahík.",
                            [
                                {
                                    "tlingit": "shaawahík",
                                    "url": "shaawahík",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is full",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_23",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm thirsty.",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawakúx.",
                            [
                                {
                                    "tlingit": "shaawakúx",
                                    "url": "shaawakúx",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is thirsty",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_24",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not thirsty.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shawukoox.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲ éet",
                            [
                                {
                                    "tlingit": "ax̲ éet wudishée",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is helping me; s/he helped me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet yidashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all help me!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "shakux.wuhá.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_25",
                        "J"
                    ],
                    [
                        "9_26",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What are you thirsty for?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "a yáx̲",
                            [
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the face of it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yáx̲",
                                    "tags": [
                                        "RelationalPostposition"
                                    ],
                                    "english": "like it, in accordance with it, as much as it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "gaaw yáx̲",
                                    "url": "gaaw yáx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "on time; in time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wudlitl'éit'",
                                    "url": "a yáx̲ wudlitl'éit'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it climbed the face of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Wooch yáx̲ nasné!",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Straighten it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ yaa datóowch",
                                    "url": "wooch yáx̲ yaa datóowch",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "math",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "ishaawakúx?",
                            [
                                {
                                    "tlingit": "shaawakúx",
                                    "url": "shaawakúx",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he is thirsty; it is dry",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_27",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm thirsty for juice.",
                "tlingit": [
                    [
                        [
                            "At kahéeni",
                            [
                                {
                                    "tlingit": "at kahéeni",
                                    "url": "at kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "juice",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "at shéeyi",
                                    "url": "at shéeyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "singer",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shéex'i",
                                    "url": "at shéex'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "singers, choir",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "éil' kahéeni",
                                    "url": "éil' kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "saltwater brine",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kanat'á kahéeni",
                                    "url": "kanat'á kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "blueberry juice; purple",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x'áax' kahéeni",
                                    "url": "x'áax' kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "apple juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áanjís kahéeni",
                                    "url": "áanjís kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "orange juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yáx̲",
                            [
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the face of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yáx̲",
                                    "tags": [
                                        "RelationalPostposition"
                                    ],
                                    "english": "like it, in accordance with it, as much as it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaaw yáx̲",
                                    "url": "gaaw yáx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "on time; in time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Wooch yáx̲ nasné!",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Straighten it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wudlitl'éit'",
                                    "url": "a yáx̲ wudlitl'éit'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it climbed the face of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawakúx.",
                            [
                                {
                                    "tlingit": "shaawakúx",
                                    "url": "shaawakúx",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is thirsty",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "At kahéeni",
                            [
                                {
                                    "tlingit": "at kahéeni",
                                    "url": "at kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "juice",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "at shéeyi",
                                    "url": "at shéeyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "singer",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shéex'i",
                                    "url": "at shéex'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "singers, choir",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "éil' kahéeni",
                                    "url": "éil' kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "saltwater brine",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kanat'á kahéeni",
                                    "url": "kanat'á kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "blueberry juice; purple",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x'áax' kahéeni",
                                    "url": "x'áax' kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "apple juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áanjís kahéeni",
                                    "url": "áanjís kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "orange juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "x̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "uwaháa.",
                            [
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_28",
                        "N"
                    ],
                    [
                        "9_29",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Put (lift up) the pot on the fire.",
                "tlingit": [
                    [
                        [
                            "X̲'aan",
                            [
                                {
                                    "tlingit": "x̲'aan",
                                    "url": "x̲'aan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fire",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲'aan",
                                    "url": "x̲'aan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "red",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káx'",
                            [
                                {
                                    "tlingit": "a káx'",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "locative"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sa.een",
                            [
                                {
                                    "tlingit": "át awsi.ín",
                                    "url": "át awsi.ín",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he carried it there; s/he took it there (container full of liquid or small objects)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "át awsi.ín",
                                    "url": "át awsi.ín",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he carried it there; s/he took it there (container full of liquid or small objects)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲'wátl.",
                            [
                                {
                                    "tlingit": "k̲'wátl",
                                    "url": "k̲'wátl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pot, cooking pot",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_30",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Put the pot (down) on the fire.",
                "tlingit": [
                    [
                        [
                            "X̲'aan",
                            [
                                {
                                    "tlingit": "x̲'aan",
                                    "url": "x̲'aan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fire",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲'aan",
                                    "url": "x̲'aan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "red",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káx'",
                            [
                                {
                                    "tlingit": "a káx'",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "locative"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "daak",
                            [
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sa.een",
                            [
                                {
                                    "tlingit": "át awsi.ín",
                                    "url": "át awsi.ín",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he carried it there; s/he took it there (container full of liquid or small objects)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "át awsi.ín",
                                    "url": "át awsi.ín",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he carried it there; s/he took it there (container full of liquid or small objects)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲'wátl.",
                            [
                                {
                                    "tlingit": "k̲'wátl",
                                    "url": "k̲'wátl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pot, cooking pot",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_31",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Season it with fireweed leaves.",
                "tlingit": [
                    [
                        [
                            "Lóol",
                            [
                                {
                                    "tlingit": "lóol",
                                    "url": "lóol",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pink",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "lóol",
                                    "url": "lóol2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fireweed",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kayaaní",
                            [
                                {
                                    "tlingit": "kayaaní",
                                    "url": "kayaaní",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "leaf, leaves; vegetation, plants, herbs, herbiage",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "a x̲oodé",
                            [
                                {
                                    "tlingit": "a x̲oodé",
                                    "url": "a x̲oo",
                                    "tags": [
                                        "RelationalNoun",
                                        "allative"
                                    ],
                                    "english": "to among it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "woosh x̲oodé has gux̲da.áat",
                                    "url": "woosh x̲oot has wudi.át",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will gather together",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh x̲oodé yaa (ha)s nada.át",
                                    "url": "woosh x̲oot has wudi.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are gathering together",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yéi nasné.",
                            [
                                {
                                    "tlingit": "Yéi nasné!",
                                    "url": "yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Fix it!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Wooch yáx̲ nasné!",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Straighten it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du wak̲shiyeex' yéi nasné!",
                                    "url": "du wak̲shiyeex' yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Demonstrate it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_32",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Let's cook trout.",
                "tlingit": [
                    [
                        [
                            "X̲'wáat'",
                            [
                                {
                                    "tlingit": "x̲'wáat'",
                                    "url": "x̲'wáat'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Dolly Varden trout",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gax̲toos.ée.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_33",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We're going to cook trout.",
                "tlingit": [
                    [
                        [
                            "X̲'wáat'",
                            [
                                {
                                    "tlingit": "x̲'wáat'",
                                    "url": "x̲'wáat'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Dolly Varden trout",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gag̲ax̲toos.ée.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_34",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What are you cooking?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sáwé",
                            [],
                            []
                        ],
                        [
                            "gees.ée?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_35",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm cooking fish.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gax̲as.ée.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_36",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's boiling.",
                "tlingit": [
                    [
                        [
                            "Wudli.úk.",
                            [
                                {
                                    "tlingit": "wudli.úk",
                                    "url": "wudli.úk",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's boiling; it boiled",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_37",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It smells good.",
                "tlingit": [
                    [
                        [
                            "Lits'áa.",
                            [
                                {
                                    "tlingit": "lits'áa",
                                    "url": "wulits'áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is fragrant",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_38",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's beginning to smell good.",
                "tlingit": [
                    [
                        [
                            "Kei nalts'éin.",
                            [
                                {
                                    "tlingit": "kei nalts'éin",
                                    "url": "wulits'áa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is getting to be fragrant",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_39",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he is barbecuing fish.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "atséek.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_40",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Fish baked in an oven.",
                "tlingit": [
                    [
                        [
                            "Sdoox",
                            [
                                {
                                    "tlingit": "stoox",
                                    "url": "stoox",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "stove",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "stoox",
                                    "url": "stoox",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "stove",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "tóonáx̲",
                            [
                                {
                                    "tlingit": "a tóonáx̲",
                                    "url": "a tú",
                                    "tags": [
                                        "RelationalNoun",
                                        "perlative"
                                    ],
                                    "english": "through the inside of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tóonáx̲ k̲aateen",
                                    "url": "tóonáx̲ k̲aateen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "mirror",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A tóonáx̲ yuk̲úx̲!",
                                    "url": "a tóonáx̲ yaawak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Drive through it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a tóonáx̲ wuk̲úx̲ch",
                                    "url": "a tóonáx̲ yaawak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he drives through it (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a tóonáx̲ yaawak̲úx̲",
                                    "url": "a tóonáx̲ yaawak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drove through it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A tóonáx̲ yeeyk̲úx̲!",
                                    "url": "a tóonáx̲ yaawak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all drive through it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a tóonáx̲ yaa nak̲úx̲",
                                    "url": "a tóonáx̲ yaawak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving through it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲áat.",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_41",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's making frybread.",
                "tlingit": [
                    [
                        [
                            "Eex̲ kát",
                            [
                                {
                                    "tlingit": "eex̲ kát sakwnein",
                                    "url": "eex̲ kát sakwnein",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fry bread, bannock",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eex̲ kát sakwneiní",
                                    "url": "eex̲ kát sakwnein",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its fry bread, bannock",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "eex̲",
                                    "url": "eex̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "oil, grease",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "eex̲",
                                    "url": "eix̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "oil, grease",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a kát",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du kát shutí!",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Blame it on him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A kát kalak̲á!",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sew it on it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát kawdigán",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát wujix̲ín",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it fell on it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "sakwnéin",
                            [
                                {
                                    "tlingit": "sakwnéin",
                                    "url": "sakwnéin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "flour; bread",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at'óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_42",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm going to make frybread.",
                "tlingit": [
                    [
                        [
                            "Eex̲ kát",
                            [
                                {
                                    "tlingit": "eex̲ kát sakwnein",
                                    "url": "eex̲ kát sakwnein",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fry bread, bannock",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eex̲ kát sakwneiní",
                                    "url": "eex̲ kát sakwnein",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its fry bread, bannock",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "eex̲",
                                    "url": "eex̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "oil, grease",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "eex̲",
                                    "url": "eix̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "oil, grease",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a kát",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du kát shutí!",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Blame it on him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A kát kalak̲á!",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sew it on it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát kawdigán",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát wujix̲ín",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it fell on it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "sakwnéin",
                            [
                                {
                                    "tlingit": "sakwnéin",
                                    "url": "sakwnéin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "flour; bread",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kk̲wat'óos'.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Eex̲ kát",
                            [
                                {
                                    "tlingit": "eex̲ kát sakwnein",
                                    "url": "eex̲ kát sakwnein",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fry bread, bannock",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eex̲ kát sakwneiní",
                                    "url": "eex̲ kát sakwnein",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its fry bread, bannock",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "eex̲",
                                    "url": "eex̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "oil, grease",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "eex̲",
                                    "url": "eix̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "oil, grease",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a kát",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du kát shutí!",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Blame it on him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A kát kalak̲á!",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sew it on it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát kawdigán",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát wujix̲ín",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it fell on it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "sakwnéin",
                            [
                                {
                                    "tlingit": "sakwnéin",
                                    "url": "sakwnéin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "flour; bread",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kuk̲wat'óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_43",
                        "J"
                    ],
                    [
                        "9_44",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he is baking bread.",
                "tlingit": [
                    [
                        [
                            "Sakwnéin",
                            [
                                {
                                    "tlingit": "sakwnéin",
                                    "url": "sakwnéin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "flour; bread",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at'óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_45",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We'll eat later.",
                "tlingit": [
                    [
                        [
                            "Dziyáagínx'",
                            [
                                {
                                    "tlingit": "dziyáagin",
                                    "url": "dziyáagin",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "after a while; later on",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "tsá",
                            [
                                {
                                    "tlingit": "tsá",
                                    "url": "tsá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "only then",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gax̲toox̲áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_46",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's time for breakfast.",
                "tlingit": [
                    [
                        [
                            "S'ootaat",
                            [
                                {
                                    "tlingit": "ts'ootaat",
                                    "url": "ts'ootaat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "morning",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ts'ootaat",
                                    "url": "ts'ootaat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "morning",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "atx̲á",
                            [
                                {
                                    "tlingit": "atx̲á",
                                    "url": "atx̲á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "food, a meal",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gaawú",
                            [
                                {
                                    "tlingit": "a gaawú",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its bell",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its drum",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its clock",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Ts'ootaadi",
                            [],
                            []
                        ],
                        [
                            "atx̲aayí",
                            [
                                {
                                    "tlingit": "du atx̲aayí",
                                    "url": "atx̲á",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its food, a meal",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gaawú",
                            [
                                {
                                    "tlingit": "a gaawú",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its bell",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its drum",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its clock",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_47",
                        "J"
                    ],
                    [
                        "9_48",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's lunch time.",
                "tlingit": [
                    [
                        [
                            "Sitgawsáan",
                            [
                                {
                                    "tlingit": "sitgawsáan",
                                    "url": "sitgawsáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "noon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "atx̲á",
                            [
                                {
                                    "tlingit": "atx̲á",
                                    "url": "atx̲á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "food, a meal",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gaawú",
                            [
                                {
                                    "tlingit": "a gaawú",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its bell",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its drum",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its clock",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Sitgawsáani",
                            [
                                {
                                    "tlingit": "taat sitgawsáani",
                                    "url": "taat sitgawsáani",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "midnight",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "atx̲aayí",
                            [
                                {
                                    "tlingit": "du atx̲aayí",
                                    "url": "atx̲á",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its food, a meal",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gaawú",
                            [
                                {
                                    "tlingit": "a gaawú",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its bell",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its drum",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du gaawú",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its clock",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_49",
                        "J"
                    ],
                    [
                        "9_50",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you want water?",
                "tlingit": [
                    [
                        [
                            "Héen",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo?",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_51",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I want water.",
                "tlingit": [
                    [
                        [
                            "Héen",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_52",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Pass the milk.",
                "tlingit": [
                    [
                        [
                            "Wasóos",
                            [
                                {
                                    "tlingit": "wasóos",
                                    "url": "wasóos",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "cow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "l'aa",
                            [
                                {
                                    "tlingit": "du l'aa",
                                    "url": "du l'aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her breast",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "tux̲áni",
                            [],
                            []
                        ],
                        [
                            "haadéi",
                            [
                                {
                                    "tlingit": "haadéi",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "allative"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nas.een.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_53",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is your food good?",
                "tlingit": [
                    [
                        [
                            "Yak'éi",
                            [
                                {
                                    "tlingit": "yak'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "atx̲aayí?",
                            [
                                {
                                    "tlingit": "du atx̲aayí",
                                    "url": "atx̲á",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its food, a meal",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_54",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Eat!",
                "tlingit": [
                    [
                        [
                            "At x̲á!",
                            [
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "At x̲á!",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Eat!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "at sax̲án",
                                    "url": "at sax̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "love (of things, of everything)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲'áan",
                                    "url": "at uwax̲'án",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is drying fish",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at téix̲i",
                                    "url": "a téix̲i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boiled food; broth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at téix̲i",
                                    "url": "téix̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boiled food; broth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲éidi",
                                    "url": "at x̲éidi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "arrowhead",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at la.át",
                                    "url": "at la.át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "baggage, luggage; things, stuff packed up for carrying",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_55",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What do you need?",
                "tlingit": [
                    [
                        [
                            "Daat",
                            [
                                {
                                    "tlingit": "a daat",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "about it; around it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daat",
                                    "url": "a daat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "(telling) about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daat yawsiták̲",
                                    "url": "du daat yawsiták̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is taking care of him/her; s/he took care of him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daat át k̲udzitee",
                                    "url": "du daat át k̲oowdzitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is busy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du daat iyasaták̲!",
                                    "url": "du daat yawsiták̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take care of him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du daat yee yasaták̲!",
                                    "url": "du daat yawsiták̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all take care of him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daat át k̲oowdzitee",
                                    "url": "du daat át k̲oowdzitee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was busy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "eetéenáx̲",
                            [
                                {
                                    "tlingit": "a eetéenáx̲",
                                    "url": "a eetéenáx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "lacking it; without it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ wootee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needed it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ nateech",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ gug̲atée",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will need it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yoo yateek",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iyatee?",
                            [
                                {
                                    "tlingit": "a eetéenáx̲ wootee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it needed it; he/she/it lacked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ wootee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it needed it; he/she/it lacked it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_56",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Can I help you? (Let me help.)",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éet",
                            [
                                {
                                    "tlingit": "Du éet hís'!",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lend it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ éet idashí!",
                                    "url": "ax̲ éet wudishée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Help me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet k̲uwaháa",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's his/her turn",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éet x̲'atán!",
                                    "url": "du éet x̲'eiwatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Speak to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet yaan uwaháa",
                                    "url": "du éet yaan uwaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hungry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲aa éet jeeyda.á!",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all attack someone!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éet aawahís'",
                                    "url": "du éet aawahís'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lent it to him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kwshé",
                            [
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "Eh?; I wonder",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshéi",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "maybe; I'm not sure; [expression of uncertainly]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲adashee?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_57",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "one cup/measure/template",
                "tlingit": [
                    [
                        [
                            "tléix'",
                            [
                                {
                                    "tlingit": "tléix'",
                                    "url": "tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gúx'aa",
                            [
                                {
                                    "tlingit": "gúx'aa",
                                    "url": "gúx'aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "cup; can",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kaayí",
                            [
                                {
                                    "tlingit": "a kaayí",
                                    "url": "a kaayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pattern, model, template for it; measure of it; measurement for it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at kaayí",
                                    "url": "at kaayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "cord (of wood)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du kaayí",
                                    "url": "kaay2",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its measuring stick",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "t'éesh kaayí",
                                    "url": "t'éesh kaayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "square",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "et kaayí",
                                    "url": "at kaayí",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "cord (of wood)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_58",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's bitter.",
                "tlingit": [
                    [
                        [
                            "Si.áax'w.",
                            [
                                {
                                    "tlingit": "si.áax'w",
                                    "url": "wusi.áax'w",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's bitter",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_59",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's sweet / good tasting.",
                "tlingit": [
                    [
                        [
                            "Linúkts.",
                            [
                                {
                                    "tlingit": "linúkts",
                                    "url": "wulinúkts",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is sweet",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_60",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's hot.",
                "tlingit": [
                    [
                        [
                            "Yaawat'áa.",
                            [
                                {
                                    "tlingit": "yaawat'áa",
                                    "url": "yaawat'áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's hot",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Yat'aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_61",
                        "N"
                    ],
                    [
                        "9_62",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "a hot thing",
                "tlingit": [
                    [
                        [
                            "yat'aayi",
                            [
                                {
                                    "tlingit": "yat'aayi héen",
                                    "url": "yat'aayi héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "coffee; hot water",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "át",
                            [
                                {
                                    "tlingit": "át",
                                    "url": "át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "thing",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_63",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's cold.",
                "tlingit": [
                    [
                        [
                            "Si.áat'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_64",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "a cold thing",
                "tlingit": [
                    [
                        [
                            "si.aat'i",
                            [],
                            []
                        ],
                        [
                            "át",
                            [
                                {
                                    "tlingit": "át",
                                    "url": "át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "thing",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_65",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "The water is hot.",
                "tlingit": [
                    [
                        [
                            "Yat'aa",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "héen.",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "héen",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yat'aa.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Yaawat'áa",
                            [
                                {
                                    "tlingit": "yaawat'áa",
                                    "url": "yaawat'áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's hot",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "héen.",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_66",
                        "J"
                    ],
                    [
                        "9_67",
                        "N"
                    ],
                    [
                        "9_68",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "hot water",
                "tlingit": [
                    [
                        [
                            "yaawat'ayi",
                            [],
                            []
                        ],
                        [
                            "héen",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_69",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "cold water",
                "tlingit": [
                    [
                        [
                            "si.áat'i héen",
                            [
                                {
                                    "tlingit": "si.áat'i héen",
                                    "url": "si.áat'i héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "cold water",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_70",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "It's sour; turned sour.",
                "tlingit": [
                    [
                        [
                            "Kawlis'úx.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_71",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "salty",
                "tlingit": [
                    [
                        [
                            "li.éil'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_72",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "too sweet",
                "tlingit": [
                    [
                        [
                            "a yáanáx̲",
                            [
                                {
                                    "tlingit": "a yáanáx̲",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "perlative"
                                    ],
                                    "english": "through the face of it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a yáanáx̲",
                                    "url": "a yáanáx̲",
                                    "tags": [
                                        "RelationalPostposition"
                                    ],
                                    "english": "beyond it, more than it; too much; excessively",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yáanáx̲",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "perlative"
                                    ],
                                    "english": "through here",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "linúkts",
                            [
                                {
                                    "tlingit": "linúkts",
                                    "url": "wulinúkts",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is sweet",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_73",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "just sweet enough",
                "tlingit": [
                    [
                        [
                            "ayáx̲",
                            [
                                {
                                    "tlingit": "ayáx̲",
                                    "url": "ayáx̲",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "thus, that's how",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "linúkts",
                            [
                                {
                                    "tlingit": "linúkts",
                                    "url": "wulinúkts",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is sweet",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_74",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "not sweet enough",
                "tlingit": [
                    [
                        [
                            "tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ayáx̲",
                            [
                                {
                                    "tlingit": "ayáx̲",
                                    "url": "ayáx̲",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "thus, that's how",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ulnúkts",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_75",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's not sweet.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ulnúkts.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_76",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Put it on the table.",
                "tlingit": [
                    [
                        [
                            "Nadáakw",
                            [
                                {
                                    "tlingit": "nadáakw",
                                    "url": "nadáakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "table",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káa",
                            [
                                {
                                    "tlingit": "káa",
                                    "url": "káa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "car, automobile",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yan tí.",
                            [
                                {
                                    "tlingit": "Yan tí!",
                                    "url": "yan aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yan~",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yan",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_77",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Put them on the table.",
                "tlingit": [
                    [
                        [
                            "Nadáakw",
                            [
                                {
                                    "tlingit": "nadáakw",
                                    "url": "nadáakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "table",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káx'",
                            [
                                {
                                    "tlingit": "a káx'",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "locative"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yan yéi sané.",
                            [
                                {
                                    "tlingit": "Yan yéi ksané!",
                                    "url": "yan yéi akawsinéi",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put them down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet yéi sané!",
                                    "url": "du jeet yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give them to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan yéi kaysané!",
                                    "url": "yan yéi akawsinéi",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all put them down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yan yéi akawsinéi",
                                    "url": "yan yéi akawsinéi",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he put them down",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yan yéi akoosnéich",
                                    "url": "yan yéi akawsinéi",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he puts them down (every time)",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "Yan sané!",
                                    "url": "yan awsinéi",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Finish it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yan~",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yan",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "Nadáakw",
                            [
                                {
                                    "tlingit": "nadáakw",
                                    "url": "nadáakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "table",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káa",
                            [
                                {
                                    "tlingit": "káa",
                                    "url": "káa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "car, automobile",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi na.oo.",
                            [
                                {
                                    "tlingit": "Yéi na.oo!",
                                    "url": "yéi aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wear it!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Na.oo!",
                                    "url": "aawa.oo",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Buy it!",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_78",
                        "J"
                    ],
                    [
                        "9_79",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Run, get a spoon.",
                "tlingit": [
                    [
                        [
                            "Shálg̲aa",
                            [],
                            []
                        ],
                        [
                            "neesheex.",
                            [
                                {
                                    "tlingit": "Át neesheex!",
                                    "url": "át wujixeex",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Run around!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aadé neesheex!",
                                    "url": "aadé wjixeex",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Run there!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_80",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We're going to eat herring eggs.",
                "tlingit": [
                    [
                        [
                            "G̲áax'w",
                            [
                                {
                                    "tlingit": "g̲áax'w",
                                    "url": "g̲áax'w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "herring eggs",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gax̲toox̲áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_81",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Grandma's eating her herring eggs with seal grease and soy sauce.",
                "tlingit": [
                    [
                        [
                            "Shaawát",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aa,",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "léelk'w",
                            [
                                {
                                    "tlingit": "Léelk'w!",
                                    "url": "Léelk'w!",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Grandmother!; Grandfather!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tsaa",
                            [
                                {
                                    "tlingit": "tsaa",
                                    "url": "tsaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hair seal",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eex̲í",
                            [
                                {
                                    "tlingit": "du eex̲í",
                                    "url": "eex̲",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its oil, grease",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "saak eex̲í",
                                    "url": "saak eex̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hooligan oil",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "t'ooch' eex̲í",
                                    "url": "t'ooch' eex̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "petroleum, oil",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k̲a",
                            [
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "soy",
                            [],
                            []
                        ],
                        [
                            "sauce",
                            [],
                            []
                        ],
                        [
                            "tín",
                            [
                                {
                                    "tlingit": "tín",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "tín",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "tín",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲á",
                            [
                                {
                                    "tlingit": "ax̲á",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is eating it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du g̲áax'u.",
                            [
                                {
                                    "tlingit": "du g̲áax'u",
                                    "url": "g̲áax'w",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its herring eggs",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_82",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "I'm full (of solid).",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawahík.",
                            [
                                {
                                    "tlingit": "shaawahík",
                                    "url": "shaawahík",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is full",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wooneix̲.",
                            [
                                {
                                    "tlingit": "wooneix̲",
                                    "url": "wooneix̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he recovered",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_83",
                        "N"
                    ],
                    [
                        "9_84",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm full (of liquid).",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawatl'it'.",
                            [
                                {
                                    "tlingit": "ashawlitl'ít'",
                                    "url": "ashawlitl'ít'",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he filled it (with liquid)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ashawlitl'ít'",
                                    "url": "ashawlitl'ít'",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he filled it (with liquid)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_85",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's full (of solid).",
                "tlingit": [
                    [
                        [
                            "Shaawahík.",
                            [
                                {
                                    "tlingit": "shaawahík",
                                    "url": "shaawahík",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is full",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_86",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's full (of liquid).",
                "tlingit": [
                    [
                        [
                            "Shaawatl'it'.",
                            [
                                {
                                    "tlingit": "ashawlitl'ít'",
                                    "url": "ashawlitl'ít'",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he filled it (with liquid)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ashawlitl'ít'",
                                    "url": "ashawlitl'ít'",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he filled it (with liquid)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_87",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "rice*",
                "tlingit": [
                    [
                        [
                            "kóox",
                            [
                                {
                                    "tlingit": "kóox",
                                    "url": "kóox",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rice; Kamchatka lily root",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_88",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "chicken*",
                "tlingit": [
                    [
                        [
                            "káax'",
                            [
                                {
                                    "tlingit": "káax'",
                                    "url": "káax'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "spruce grouse, spruce hen; chicken",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_89",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "crackers; pilot bread*",
                "tlingit": [
                    [
                        [
                            "g̲áatl",
                            [
                                {
                                    "tlingit": "g̲áatl",
                                    "url": "g̲áatl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pilot bread",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_90",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "* Cultural note. Traditional Tlingit names were often applied to new foods. Thus rice was originally the root of Kamchatka lily, chicken was grouse, and pilot bread the fungus that grows on trees."
            },
            {
                "type": "ex",
                "english": "boiled fish",
                "tlingit": [
                    [
                        [
                            "útlx̲i",
                            [
                                {
                                    "tlingit": "útlx̲i",
                                    "url": "útlx̲i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boiled fish",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_91",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "dry fish",
                "tlingit": [
                    [
                        [
                            "at x̲'éeshi",
                            [
                                {
                                    "tlingit": "at x̲'éeshi",
                                    "url": "at x̲'éeshi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dry fish",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'éeshi",
                                    "url": "a x̲'éeshi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its dried flesh, strips (of fish)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲éidi",
                                    "url": "at x̲éidi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "arrowhead",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲éix̲",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at ux̲'ánch",
                                    "url": "at uwax̲'án",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he dries fish (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gux̲sa.ée",
                                    "url": "at wusi.ée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will cook",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at tux̲'wáns'i",
                                    "url": "at tux̲'wáns'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "buckshot; moccasin lining",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at téix̲i",
                                    "url": "a téix̲i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boiled food; broth",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_92",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "half dry fish",
                "tlingit": [
                    [
                        [
                            "náayadi",
                            [
                                {
                                    "tlingit": "náayadi",
                                    "url": "náayadi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "half-dried salmon (smoked)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_93",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "fermented fish heads",
                "tlingit": [
                    [
                        [
                            "k'ínk'",
                            [
                                {
                                    "tlingit": "k'ínk'",
                                    "url": "k'ínk'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "aged fish head",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_94",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "salmon eggs",
                "tlingit": [
                    [
                        [
                            "kaháakw",
                            [
                                {
                                    "tlingit": "kaháakw",
                                    "url": "kaháakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "roe, eggs (of fish)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_95",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "soup, broth",
                "tlingit": [
                    [
                        [
                            "tax̲héeni",
                            [
                                {
                                    "tlingit": "tax̲héeni",
                                    "url": "tax̲héeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "soup broth; soup",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Tax̲héeni",
                                    "url": "Tax̲héeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Takhini hot springs (north of Whitehorse, Yukon Territory)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "texhéeni",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_96",
                        "N"
                    ],
                    [
                        "9_97",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "juice",
                "tlingit": [
                    [
                        [
                            "at kahéeni",
                            [
                                {
                                    "tlingit": "at kahéeni",
                                    "url": "at kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "juice",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "at shéeyi",
                                    "url": "at shéeyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "singer",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shéex'i",
                                    "url": "at shéex'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "singers, choir",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "éil' kahéeni",
                                    "url": "éil' kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "saltwater brine",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kanat'á kahéeni",
                                    "url": "kanat'á kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "blueberry juice; purple",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x'áax' kahéeni",
                                    "url": "x'áax' kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "apple juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áanjís kahéeni",
                                    "url": "áanjís kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "orange juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_98",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "berries",
                "tlingit": [
                    [
                        [
                            "tléik̲w",
                            [
                                {
                                    "tlingit": "tléik̲w",
                                    "url": "tléik̲w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "berry, berries",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_99",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "apple",
                "tlingit": [
                    [
                        [
                            "x'áax'",
                            [
                                {
                                    "tlingit": "x'áax'",
                                    "url": "x'áax'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "apple; crabapple",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_100",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "apple juice",
                "tlingit": [
                    [
                        [
                            "x'áax' kahéeni",
                            [
                                {
                                    "tlingit": "x'áax' kahéeni",
                                    "url": "x'áax' kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "apple juice",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "áanjís kahéeni",
                                    "url": "áanjís kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "orange juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kanat'á kahéeni",
                                    "url": "kanat'á kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "blueberry juice; purple",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at kahéeni",
                                    "url": "at kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "éil' kahéeni",
                                    "url": "éil' kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "saltwater brine",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléik̲w kahéeni",
                                    "url": "tléik̲w kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "berry juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x'áax'",
                                    "url": "x'áax'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "apple; crabapple",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_101",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "orange juice",
                "tlingit": [
                    [
                        [
                            "áanjís kahéeni",
                            [
                                {
                                    "tlingit": "áanjís kahéeni",
                                    "url": "áanjís kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "orange juice",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "x'áax' kahéeni",
                                    "url": "x'áax' kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "apple juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kanat'á kahéeni",
                                    "url": "kanat'á kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "blueberry juice; purple",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at kahéeni",
                                    "url": "at kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "éil' kahéeni",
                                    "url": "éil' kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "saltwater brine",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléik̲w kahéeni",
                                    "url": "tléik̲w kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "berry juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áanjís",
                                    "url": "áanjís",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "orange",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_102",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "berry juice",
                "tlingit": [
                    [
                        [
                            "tléik̲w kahéeni",
                            [
                                {
                                    "tlingit": "tléik̲w kahéeni",
                                    "url": "tléik̲w kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "berry juice",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "éil' kahéeni",
                                    "url": "éil' kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "saltwater brine",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at kahéeni",
                                    "url": "at kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kanat'á kahéeni",
                                    "url": "kanat'á kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "blueberry juice; purple",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x'áax' kahéeni",
                                    "url": "x'áax' kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "apple juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áanjís kahéeni",
                                    "url": "áanjís kahéeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "orange juice",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléik̲w",
                                    "url": "tléik̲w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "berry, berries",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_103",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "oil, grease",
                "tlingit": [
                    [
                        [
                            "eex̲",
                            [
                                {
                                    "tlingit": "eex̲",
                                    "url": "eex̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "oil, grease",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "eex̲",
                                    "url": "eix̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "oil, grease",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "eix̲",
                            [
                                {
                                    "tlingit": "eix̲",
                                    "url": "eex̲",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "oil, grease",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "eix̲",
                                    "url": "eix̲",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "oil, grease",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_104",
                        "N"
                    ],
                    [
                        "9_105",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "seal oil",
                "tlingit": [
                    [
                        [
                            "tsaa",
                            [
                                {
                                    "tlingit": "tsaa",
                                    "url": "tsaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hair seal",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eex̲í",
                            [
                                {
                                    "tlingit": "du eex̲í",
                                    "url": "eex̲",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its oil, grease",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "saak eex̲í",
                                    "url": "saak eex̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hooligan oil",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "t'ooch' eex̲í",
                                    "url": "t'ooch' eex̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "petroleum, oil",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_106",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "seal meat",
                "tlingit": [
                    [
                        [
                            "tsaa",
                            [
                                {
                                    "tlingit": "tsaa",
                                    "url": "tsaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hair seal",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dleeyí",
                            [
                                {
                                    "tlingit": "du dleeyí",
                                    "url": "dleey",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its meat, flesh",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_107",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "deer meat",
                "tlingit": [
                    [
                        [
                            "g̲uwakaan",
                            [
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "g̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "k̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dleeyi",
                            [
                                {
                                    "tlingit": "dleey",
                                    "url": "dleey",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "meat, flesh",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "dleey",
                                    "url": "dleey",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "meat, flesh",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_108",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "pilot bread",
                "tlingit": [
                    [
                        [
                            "g̲áatl",
                            [
                                {
                                    "tlingit": "g̲áatl",
                                    "url": "g̲áatl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pilot bread",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_109",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "bread (flour)",
                "tlingit": [
                    [
                        [
                            "sakwnéin",
                            [
                                {
                                    "tlingit": "sakwnéin",
                                    "url": "sakwnéin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "flour; bread",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_110",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "baked or fried bread",
                "tlingit": [
                    [
                        [
                            "sakwnéin éewu",
                            [
                                {
                                    "tlingit": "sakwnéin éewu",
                                    "url": "sakwnéin éewu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "(loaf of) bread",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sakwnéin",
                                    "url": "sakwnéin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "flour; bread",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_111",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "potatoes",
                "tlingit": [
                    [
                        [
                            "k'únts'",
                            [
                                {
                                    "tlingit": "k'únts'",
                                    "url": "k'únts'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "potato",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_112",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "gumboots",
                "tlingit": [
                    [
                        [
                            "shaaw",
                            [
                                {
                                    "tlingit": "shaaw",
                                    "url": "shaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "gumboots; chiton",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_113",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "crab",
                "tlingit": [
                    [
                        [
                            "s'áaw",
                            [
                                {
                                    "tlingit": "s'áaw",
                                    "url": "s'áaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dungeness crab",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_114",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "clams",
                "tlingit": [
                    [
                        [
                            "gáal'",
                            [
                                {
                                    "tlingit": "gáal'",
                                    "url": "gáal'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clam",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gáal'",
                                    "url": "gáal'2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "cataract",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_115",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Hudson Bay tea",
                "tlingit": [
                    [
                        [
                            "s'ikshaldéen",
                            [
                                {
                                    "tlingit": "s'ikshaldéen",
                                    "url": "s'ikshaldéen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Hudson Bay tea",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_116",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "common (dark) seaweed",
                "tlingit": [
                    [
                        [
                            "laak̲'ásk",
                            [
                                {
                                    "tlingit": "laak̲'ásk",
                                    "url": "laak̲'ásk",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dulse (type of seaweed)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_117",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "ribbon (light) seaweed",
                "tlingit": [
                    [
                        [
                            "k̲'áach'",
                            [
                                {
                                    "tlingit": "k̲'áach'",
                                    "url": "k̲'áach'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "ribbon seaweed",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_118",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "herring eggs",
                "tlingit": [
                    [
                        [
                            "g̲áax'w",
                            [
                                {
                                    "tlingit": "g̲áax'w",
                                    "url": "g̲áax'w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "herring eggs",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_119",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "hooligan",
                "tlingit": [
                    [
                        [
                            "saak",
                            [
                                {
                                    "tlingit": "saak",
                                    "url": "saak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "eulachon; candlefish; hooligan",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_120",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "hooligan oil",
                "tlingit": [
                    [
                        [
                            "saak eex̲í",
                            [
                                {
                                    "tlingit": "saak eex̲í",
                                    "url": "saak eex̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hooligan oil",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du eex̲í",
                                    "url": "eex̲",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its oil, grease",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "t'ooch' eex̲í",
                                    "url": "t'ooch' eex̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "petroleum, oil",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "saak",
                                    "url": "saak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "eulachon; candlefish; hooligan",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_121",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "carrot",
                "tlingit": [
                    [
                        [
                            "s'ín",
                            [
                                {
                                    "tlingit": "s'ín",
                                    "url": "s'ín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "carrot",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "s'án",
                            [
                                {
                                    "tlingit": "s'ín",
                                    "url": "s'ín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "carrot",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_122",
                        "N"
                    ],
                    [
                        "9_123",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "food taken home from a party",
                "tlingit": [
                    [
                        [
                            "éenwu",
                            [
                                {
                                    "tlingit": "éenwu",
                                    "url": "éenwu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "food taken home from a feast or dinner to which one was invited",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_124",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "What are you eating?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sáwé",
                            [],
                            []
                        ],
                        [
                            "eex̲á?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_125",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm eating fish.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲ax̲á.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_126",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm eating pilot bread.",
                "tlingit": [
                    [
                        [
                            "G̲áatl",
                            [
                                {
                                    "tlingit": "g̲áatl",
                                    "url": "g̲áatl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pilot bread",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲ax̲á.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_127",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are you eating dry fish?",
                "tlingit": [
                    [
                        [
                            "At x̲'éeshi",
                            [
                                {
                                    "tlingit": "at x̲'éeshi",
                                    "url": "at x̲'éeshi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dry fish",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'éeshi",
                                    "url": "a x̲'éeshi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its dried flesh, strips (of fish)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲éidi",
                                    "url": "at x̲éidi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "arrowhead",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲éix̲",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at ux̲'ánch",
                                    "url": "at uwax̲'án",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he dries fish (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gux̲sa.ée",
                                    "url": "at wusi.ée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will cook",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at tux̲'wáns'i",
                                    "url": "at tux̲'wáns'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "buckshot; moccasin lining",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at téix̲i",
                                    "url": "a téix̲i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boiled food; broth",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eex̲á?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_128",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What is s/he eating?",
                "tlingit": [
                    [
                        [
                            "Daa sá",
                            [
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléil daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yanees.á!",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Examine it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yoo tutánk!",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Think about it!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "(wé)",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲á?",
                            [
                                {
                                    "tlingit": "ax̲á",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is eating it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_129",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's eating gumboots.",
                "tlingit": [
                    [
                        [
                            "Shaaw",
                            [
                                {
                                    "tlingit": "shaaw",
                                    "url": "shaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "gumboots; chiton",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲á.",
                            [
                                {
                                    "tlingit": "ax̲á",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is eating it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_130",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What are they eating?",
                "tlingit": [
                    [
                        [
                            "Daa sá",
                            [
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléil daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yanees.á!",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Examine it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yoo tutánk!",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Think about it!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "(wé)",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲á?",
                            [
                                {
                                    "tlingit": "ax̲á",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is eating it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_131",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are eating gumboots.",
                "tlingit": [
                    [
                        [
                            "Shaaw",
                            [
                                {
                                    "tlingit": "shaaw",
                                    "url": "shaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "gumboots; chiton",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲á.",
                            [
                                {
                                    "tlingit": "ax̲á",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is eating it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_132",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Let's eat salmon eggs.",
                "tlingit": [
                    [
                        [
                            "Kaháakw",
                            [
                                {
                                    "tlingit": "kaháakw",
                                    "url": "kaháakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "roe, eggs (of fish)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲atoox̲aa.",
                            [
                                {
                                    "tlingit": "aawax̲áa",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he ate it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aawax̲áa",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he ate it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_133",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We'll eat later.",
                "tlingit": [
                    [
                        [
                            "Dziyáaginx'",
                            [
                                {
                                    "tlingit": "dziyáagin",
                                    "url": "dziyáagin",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "after a while; later on",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gax̲toox̲áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_134",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We'll eat deer meat.",
                "tlingit": [
                    [
                        [
                            "G̲uwakaan",
                            [
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "g̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "k̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dleeyí",
                            [
                                {
                                    "tlingit": "du dleeyí",
                                    "url": "dleey",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its meat, flesh",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gax̲toox̲áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_135",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Did you eat?",
                "tlingit": [
                    [
                        [
                            "At",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "iyax̲áa",
                            [
                                {
                                    "tlingit": "at uwax̲áa",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he ate",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_136",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "When did you eat?",
                "tlingit": [
                    [
                        [
                            "Gwátk",
                            [],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "iyax̲áa?",
                            [
                                {
                                    "tlingit": "at uwax̲áa",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he ate",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_137",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I just ate.",
                "tlingit": [
                    [
                        [
                            "Yeisú",
                            [
                                {
                                    "tlingit": "yeisú",
                                    "url": "yeisú",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "just now; just then; still; yet",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲waax̲áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_138",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I ate a while ago:",
                "tlingit": [
                    [
                        [
                            "Dziyáak",
                            [
                                {
                                    "tlingit": "dziyáak",
                                    "url": "dziyáak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "just now; a while ago, earlier",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲waax̲áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_139",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What did you eat?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "iyax̲áa?",
                            [
                                {
                                    "tlingit": "at uwax̲áa",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he ate",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_140",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I ate fish.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲waax̲áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_141",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "When will you eat?",
                "tlingit": [
                    [
                        [
                            "Gwátgeen",
                            [
                                {
                                    "tlingit": "ch'a gwátgeen sá",
                                    "url": "ch'a gwátgeen sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "any time (in the future); whenever (in the future)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gag̲eex̲áa?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_142",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'll eat after a while.",
                "tlingit": [
                    [
                        [
                            "Dziyáagin(x*)",
                            [
                                {
                                    "tlingit": "dziyáagin",
                                    "url": "dziyáagin",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "after a while; later on",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kuk̲ax̲áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_143",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What will you eat?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "kg̲eex̲áa?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_144",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'll eat fish.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kk̲wax̲áa.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kuk̲ax̲áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_145",
                        "N"
                    ],
                    [
                        "9_146",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What do you (habitually) eat?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "eex̲áaych?",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "eex̲áa",
                            [],
                            []
                        ],
                        [
                            "nooch?",
                            [
                                {
                                    "tlingit": "nooch",
                                    "url": "nooch",
                                    "tags": [
                                        "Other"
                                    ],
                                    "english": "always; [auxiliary]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "9_147",
                        "N"
                    ],
                    [
                        "9_148",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I (habitually) eat fish.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "(áyá)",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲wax̲áaych.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_149",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "My aunt likes kink'.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aat",
                            [
                                {
                                    "tlingit": "du aat",
                                    "url": "du aat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her paternal aunt",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éi",
                            [
                                {
                                    "tlingit": "x̲'éi awdinúk",
                                    "url": "x̲'éi awdinúk",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he tasted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éi shadagutx̲i lítaa",
                                    "url": "x̲'éi shadagutx̲i lítaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "knife with fold-in blade",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éi adinook",
                                    "url": "x̲'éi awdinúk",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he tastes it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "X̲'éi danú!",
                                    "url": "x̲'éi awdinúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Taste it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éi oodanúkch",
                                    "url": "x̲'éi awdinúk",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he tastes it (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "X̲'éi yidanú!",
                                    "url": "x̲'éi awdinúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all taste it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éi agux̲danóok",
                                    "url": "x̲'éi awdinúk",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will taste it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yak'éi",
                            [
                                {
                                    "tlingit": "yak'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k'ínk'.",
                            [
                                {
                                    "tlingit": "k'ínk'",
                                    "url": "k'ínk'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "aged fish head",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_150",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My aunt doesn't like k'ink'.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aat",
                            [
                                {
                                    "tlingit": "du aat",
                                    "url": "du aat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her paternal aunt",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éi",
                            [
                                {
                                    "tlingit": "x̲'éi awdinúk",
                                    "url": "x̲'éi awdinúk",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he tasted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éi shadagutx̲i lítaa",
                                    "url": "x̲'éi shadagutx̲i lítaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "knife with fold-in blade",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éi adinook",
                                    "url": "x̲'éi awdinúk",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he tastes it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "X̲'éi danú!",
                                    "url": "x̲'éi awdinúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Taste it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éi oodanúkch",
                                    "url": "x̲'éi awdinúk",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he tastes it (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "X̲'éi yidanú!",
                                    "url": "x̲'éi awdinúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all taste it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éi agux̲danóok",
                                    "url": "x̲'éi awdinúk",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will taste it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ushk'é",
                            [
                                {
                                    "tlingit": "l ushk'é",
                                    "url": "l ushk'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "evil, sin",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél ushk'é",
                                    "url": "tlél wushk'é",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is bad",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k'ínk'.",
                            [
                                {
                                    "tlingit": "k'ínk'",
                                    "url": "k'ínk'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "aged fish head",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_151",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My aunt is eating k'ink'.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aat",
                            [
                                {
                                    "tlingit": "du aat",
                                    "url": "du aat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her paternal aunt",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k'ínk'",
                            [
                                {
                                    "tlingit": "k'ínk'",
                                    "url": "k'ínk'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "aged fish head",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲á.",
                            [
                                {
                                    "tlingit": "ax̲á",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is eating it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_152",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My aunt will eat kink.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aat",
                            [
                                {
                                    "tlingit": "du aat",
                                    "url": "du aat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her paternal aunt",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k'ínk'",
                            [
                                {
                                    "tlingit": "k'ínk'",
                                    "url": "k'ínk'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "aged fish head",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "akg̲wax̲áa.",
                            [
                                {
                                    "tlingit": "akg̲wax̲áa",
                                    "url": "aawax̲áa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will row",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "akg̲wax̲áa",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will eat it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_153",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My aunt ate k'ink'.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aat",
                            [
                                {
                                    "tlingit": "du aat",
                                    "url": "du aat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her paternal aunt",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k'ínk'",
                            [
                                {
                                    "tlingit": "k'ínk'",
                                    "url": "k'ínk'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "aged fish head",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aawax̲aa.",
                            [
                                {
                                    "tlingit": "aawax̲áa",
                                    "url": "aawax̲áa",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he rowed; s/he paddled",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aawax̲áa",
                                    "url": "aawax̲áa",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he rowed; s/he paddled",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_154",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My uncle ate up all the k'ink'.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sánich",
                            [],
                            []
                        ],
                        [
                            "yax̲",
                            [
                                {
                                    "tlingit": "yax̲",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb",
                                        "pertingent"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yax̲",
                                    "url": "yax̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "to completion",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ayawsix̲aa",
                            [
                                {
                                    "tlingit": "yax̲ ayawsix̲áa",
                                    "url": "yax̲ ayawsix̲áa",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he ate it all up",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yax̲ ayawsix̲áa",
                                    "url": "yax̲ ayawsix̲áa",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he ate it all up",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k'ínk'.",
                            [
                                {
                                    "tlingit": "k'ínk'",
                                    "url": "k'ínk'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "aged fish head",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_155",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he is eating fish heads.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaayí",
                            [
                                {
                                    "tlingit": "a shá",
                                    "url": "a shá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its head",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲á.",
                            [
                                {
                                    "tlingit": "ax̲á",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is eating it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_156",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he is sucking on the jawbone.",
                "tlingit": [
                    [
                        [
                            "S'aak̲",
                            [
                                {
                                    "tlingit": "s'aak̲",
                                    "url": "s'aak̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bone",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "als'óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_157",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he is burping.",
                "tlingit": [
                    [
                        [
                            "Altsaa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_158",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Let's eat Chinese food.",
                "tlingit": [
                    [
                        [
                            "Chaanwaan",
                            [
                                {
                                    "tlingit": "Cháanwaan",
                                    "url": "Cháanwaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Chinese",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Cháanwaan",
                                    "url": "Cháanwaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Chinese",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "atx̲aayí",
                            [
                                {
                                    "tlingit": "du atx̲aayí",
                                    "url": "atx̲á",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its food, a meal",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "g̲atoox̲aa.",
                            [
                                {
                                    "tlingit": "aawax̲áa",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he ate it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aawax̲áa",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he ate it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_159",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Let's eat boiled fish for lunch.",
                "tlingit": [
                    [
                        [
                            "Útlx̲i",
                            [
                                {
                                    "tlingit": "útlx̲i",
                                    "url": "útlx̲i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boiled fish",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲atoox̲aa",
                            [
                                {
                                    "tlingit": "aawax̲áa",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he ate it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aawax̲áa",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he ate it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yá",
                            [
                                {
                                    "tlingit": "yá",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "satgawsaan",
                            [
                                {
                                    "tlingit": "sitgawsáan",
                                    "url": "sitgawsáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "noon",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sitgawsáan",
                                    "url": "sitgawsáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "noon",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "atx̲aayí",
                            [
                                {
                                    "tlingit": "du atx̲aayí",
                                    "url": "atx̲á",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its food, a meal",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sákw.",
                            [
                                {
                                    "tlingit": "sákw",
                                    "url": "sákw",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "future (noun), (noun) to be, for (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_160",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "We'll boil fish.",
                "tlingit": [
                    [
                        [
                            "Gagax̲toosh.óotl.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_161",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We'll eat at McDonalds.",
                "tlingit": [
                    [
                        [
                            "McDonalds-x'",
                            [],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gax̲toox̲áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_162",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Let's eat at McDonalds.",
                "tlingit": [
                    [
                        [
                            "McDonalds-x'",
                            [],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gatoox̲aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "9_163",
                        "N"
                    ]
                ]
            }
        ]
    },
    "10": {
        "title": "Kinship",
        "content": [
            {
                "type": "ex",
                "english": "Who's that?",
                "tlingit": [
                    [
                        [
                            "Aadóo",
                            [
                                {
                                    "tlingit": "ch'a aadóo sá",
                                    "url": "ch'a aa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a aadóo sá",
                                    "url": "ch'a aadóo sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sáwé?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "This is your grandparent.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "léelk'w",
                            [
                                {
                                    "tlingit": "Léelk'w!",
                                    "url": "Léelk'w!",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Grandmother!; Grandfather!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "one's relative",
                "tlingit": [
                    [
                        [
                            "k̲aa",
                            [
                                {
                                    "tlingit": "k̲aa s'aatí",
                                    "url": "k̲aa s'aatí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boss",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa x̲'oos",
                                    "url": "k̲aa x̲'oos",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "foot (measurement)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at óowu",
                                    "url": "k̲aa at óowu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession(s); that which is owned (by them)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa ji.eetí",
                                    "url": "k̲aa ji.eetí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "handiwork, handmade crafts",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa daakeidí",
                                    "url": "k̲aa daakeidí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "coffin; casket",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa x̲'a.eetí",
                                    "url": "k̲aa x̲'a.eetí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "leftovers, food scraps",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at oohéini",
                                    "url": "k̲aa at oohéini",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession; that which is claimed",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲ooní",
                            [
                                {
                                    "tlingit": "a x̲ooní",
                                    "url": "a x̲ooní",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "one that matches it; an amount that matches it; equivalent to it; one like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ooní",
                                    "url": "du x̲ooní",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her relative, friend; his/her tribesman",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is that man your relative/relation?",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲ooní",
                            [
                                {
                                    "tlingit": "a x̲ooní",
                                    "url": "a x̲ooní",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "one that matches it; an amount that matches it; equivalent to it; one like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ooní",
                                    "url": "du x̲ooní",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her relative, friend; his/her tribesman",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ák.wé",
                            [
                                {
                                    "tlingit": "ák.wé",
                                    "url": "ák.wé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa?",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is that person your brother/sister?",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "een aa",
                            [
                                {
                                    "tlingit": "du een aa",
                                    "url": "du een aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her kinsman, moiety mate",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "ak.we?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Tlingit kinship terms are always used with possessive pronouns. The most common possessive pronouns are:"
            },
            {
                "type": "ex",
                "english": "my",
                "tlingit": [
                    [
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "your (singular)",
                "tlingit": [
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her",
                "tlingit": [
                    [
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "our",
                "tlingit": [
                    [
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "your (plural)",
                "tlingit": [
                    [
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "their",
                "tlingit": [
                    [
                        [
                            "has du",
                            [
                                {
                                    "tlingit": "has du",
                                    "url": "has du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "their [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Tlingit kinship terms do not always match English. Many are gender-bound, depend on age of siblings involved, and are based on the moieties of the people involved (that is, man or woman of the same or opposite moiety; sibling of the same or opposite sex; etc.)."
            },
            {
                "type": "ex",
                "english": "his/her ancestor",
                "tlingit": [
                    [
                        [
                            "du shagóon",
                            [
                                {
                                    "tlingit": "du shagóon",
                                    "url": "du shagóon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "ancestor(s) of his/her clan or nation; his/her background, heredity",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a shagóon",
                                    "url": "a shagóon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its what it is (to be) made of; its parts, components, materials",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "du shuká",
                            [
                                {
                                    "tlingit": "du shuká",
                                    "url": "du shuká",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in front of him/her; his/her geneology, history; his/her ancestors",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a shuká",
                                    "url": "a shuká",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "front of it; ahead of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(perceived as gone behind one)",
                    "(perceived as gone ahead of one; also, his or her crest)"
                ],
                "ids": [
                    [
                        "10_12",
                        "J"
                    ],
                    [
                        "10_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her grandparent",
                "tlingit": [
                    [
                        [
                            "du léelk'w",
                            [
                                {
                                    "tlingit": "du léelk'w",
                                    "url": "du léelk'w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her grandparent",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Léelk'w!",
                                    "url": "Léelk'w!",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Grandmother!; Grandfather!",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "du áali",
                            [
                                {
                                    "tlingit": "du áali",
                                    "url": "du áali",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her grandparent",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "du daakanóox'u",
                            [
                                {
                                    "tlingit": "du daakanóox'u",
                                    "url": "du daakanóox'u",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her grandparent (term of respect)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "(older term; grandfather)",
                    "(ceremonial term of respect)"
                ],
                "ids": [
                    [
                        "10_14",
                        "J"
                    ],
                    [
                        "10_15",
                        "N"
                    ],
                    [
                        "10_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her grandchild",
                "tlingit": [
                    [
                        [
                            "du dachx̲án",
                            [
                                {
                                    "tlingit": "du dachx̲án",
                                    "url": "du dachx̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her grandchild",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her namesake",
                "tlingit": [
                    [
                        [
                            "du saayí",
                            [
                                {
                                    "tlingit": "du saayí",
                                    "url": "du saayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her name; his/her namesake",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du saayí",
                                    "url": "saa",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its name",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "his/her father",
                "tlingit": [
                    [
                        [
                            "du éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her mother",
                "tlingit": [
                    [
                        [
                            "du tláa",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her child",
                "tlingit": [
                    [
                        [
                            "du yádi",
                            [
                                {
                                    "tlingit": "du yádi",
                                    "url": "du yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her child",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "aas yádi",
                                    "url": "aas yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sapling",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "dóosh yádi",
                                    "url": "dóosh yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "kitten",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tíx' yádi",
                                    "url": "tíx' yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "string",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲áat yádi",
                                    "url": "x̲áat yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "whitefish; baby fish; tiny fish",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gawdáan yádi",
                                    "url": "gawdáan yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "colt",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wanadóo yádi",
                                    "url": "wanadóo yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "lamb",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlék̲w yádi",
                                    "url": "tlék̲w yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raspberry",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her children",
                "tlingit": [
                    [
                        [
                            "du yátx'i",
                            [
                                {
                                    "tlingit": "du yátx'i",
                                    "url": "adátx'i",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its children",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du yátx'i",
                                    "url": "atyátx'i",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its children",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du yátx'i",
                                    "url": "du yátx'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her children",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "gán yátx'i",
                                    "url": "gán yátx'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "small pieces of firewood; kindling",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "t'aa yátx'i",
                                    "url": "t'aa yátx'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shingles",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "l'éiw yátx'i",
                                    "url": "l'éiw yátx'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fine sand or gravel",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du yátx'i latíni",
                                    "url": "atyátx'i latíni",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its babysitter",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her daughter",
                "tlingit": [
                    [
                        [
                            "du sée",
                            [
                                {
                                    "tlingit": "du sée",
                                    "url": "du sée",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her daughter, cousin",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sée",
                                    "url": "sée",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "doll",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her son",
                "tlingit": [
                    [
                        [
                            "du yéet",
                            [
                                {
                                    "tlingit": "du yéet",
                                    "url": "du yéet",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her son, cousin",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_24",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "his older brother",
                "tlingit": [
                    [
                        [
                            "du húnx̲w",
                            [
                                {
                                    "tlingit": "du húnx̲w",
                                    "url": "du húnx̲w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his older brother, cousin",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(a man's older brother)"
                ],
                "ids": [
                    [
                        "10_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his younger brother",
                "tlingit": [
                    [
                        [
                            "du kéek'",
                            [
                                {
                                    "tlingit": "du kéek'",
                                    "url": "du kéek'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her younger sister; his younger brother; cousin",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(younger sibling of same sex)"
                ],
                "ids": [
                    [
                        "10_26",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his sister",
                "tlingit": [
                    [
                        [
                            "du dlaak'",
                            [
                                {
                                    "tlingit": "du dlaak'",
                                    "url": "du dlaak'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his sister",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(a man's sister)"
                ],
                "ids": [
                    [
                        "10_27",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his brother-in-law",
                "tlingit": [
                    [
                        [
                            "du káani",
                            [
                                {
                                    "tlingit": "du káani",
                                    "url": "du káani",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her brother-in-law, sister-in-law",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "naa káani",
                                    "url": "naa káani",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "master of ceremonies, elder of the opposite clan consulted conducting a ceremony",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(brother of man's wife)"
                ],
                "ids": [
                    [
                        "10_28",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "her older sister",
                "tlingit": [
                    [
                        [
                            "du shátx̲",
                            [
                                {
                                    "tlingit": "du shátx̲",
                                    "url": "du shátx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her older sister, cousin",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(a woman's older sister)"
                ],
                "ids": [
                    [
                        "10_29",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "her younger sister",
                "tlingit": [
                    [
                        [
                            "du kéek'",
                            [
                                {
                                    "tlingit": "du kéek'",
                                    "url": "du kéek'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her younger sister; his younger brother; cousin",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(younger sibling of same sex)"
                ],
                "ids": [
                    [
                        "10_30",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "her brother",
                "tlingit": [
                    [
                        [
                            "du éek'",
                            [
                                {
                                    "tlingit": "du éek'",
                                    "url": "du éek'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her brother, cousin",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du éek' ayaheen",
                                    "url": "du éek' aawaheen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he believes him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du éek' aawaheen",
                                    "url": "du éek' aawaheen",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he believed him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éek' agaheen!",
                                    "url": "du éek' aawaheen",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Believe him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du éek' agayheen!",
                                    "url": "du éek' aawaheen",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all believe him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du éek' kei akg̲wahéen",
                                    "url": "du éek' aawaheen",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will believe him",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl du éek' eeheeník̲!",
                                    "url": "du éek' aawaheen",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't believe him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(a woman's brother)"
                ],
                "ids": [
                    [
                        "10_31",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "her sister-in-law",
                "tlingit": [
                    [
                        [
                            "du káani",
                            [
                                {
                                    "tlingit": "du káani",
                                    "url": "du káani",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her brother-in-law, sister-in-law",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "naa káani",
                                    "url": "naa káani",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "master of ceremonies, elder of the opposite clan consulted conducting a ceremony",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(sister of a woman's husband)"
                ],
                "ids": [
                    [
                        "10_32",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "his/her aunt",
                "tlingit": [
                    [
                        [
                            "du aat",
                            [
                                {
                                    "tlingit": "du aat",
                                    "url": "du aat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her paternal aunt",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "du tláak'w",
                            [
                                {
                                    "tlingit": "du tláak'w",
                                    "url": "du tláak'w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her maternal aunt",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(father's sister)",
                    "(mother's sister)"
                ],
                "ids": [
                    [
                        "10_33",
                        "N"
                    ],
                    [
                        "10_34",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her uncle",
                "tlingit": [
                    [
                        [
                            "du sáni",
                            [
                                {
                                    "tlingit": "du sáni",
                                    "url": "du sáni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her paternal uncle, cousin",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "du káak",
                            [
                                {
                                    "tlingit": "du káak",
                                    "url": "du káak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her maternal uncle",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(father's brother)",
                    "(mother's brother)"
                ],
                "ids": [
                    [
                        "10_35",
                        "N"
                    ],
                    [
                        "10_36",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "nephew or niece (man's sister's child)",
                "tlingit": [
                    [
                        [
                            "du kéilk'",
                            [
                                {
                                    "tlingit": "du kéilk'",
                                    "url": "du kéilk'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his sororal niece, nephew",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(same moiety)"
                ],
                "ids": [
                    [
                        "10_37",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "nephew or niece (woman's brother's child)",
                "tlingit": [
                    [
                        [
                            "du káalk'w",
                            [
                                {
                                    "tlingit": "du káalk'w",
                                    "url": "du káalk'w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her fraternal niece, nephew, cousin",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(opposite moiety)"
                ],
                "ids": [
                    [
                        "10_38",
                        "J"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Note on nieces and nephews. In terms of kinship and social structure, a woman's sister's child is also considered her child, and a man's brother's child is also considered his child; therefore the Tlingit terms do not apply (and do not match English)."
            },
            {
                "type": "ex",
                "english": "his/her spouse",
                "tlingit": [
                    [
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲án.aa",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_39",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his wife",
                "tlingit": [
                    [
                        [
                            "du shát",
                            [
                                {
                                    "tlingit": "du shát",
                                    "url": "du shát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his wife",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_40",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "her husband",
                "tlingit": [
                    [
                        [
                            "du x̲úx̲",
                            [
                                {
                                    "tlingit": "du x̲úx̲",
                                    "url": "du x̲úx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her husband",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_41",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her mother-in-law",
                "tlingit": [
                    [
                        [
                            "du chaan",
                            [
                                {
                                    "tlingit": "du chaan",
                                    "url": "du chaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother-in-law",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_42",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her father-in-law",
                "tlingit": [
                    [
                        [
                            "du wóo",
                            [
                                {
                                    "tlingit": "du wóo",
                                    "url": "du wóo",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father-in-law",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_43",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her daughter-in-law",
                "tlingit": [
                    [
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yitshát",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_44",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her son-in-law",
                "tlingit": [
                    [
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "seix̲úx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_45",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his brother-in-law",
                "tlingit": [
                    [
                        [
                            "du káani",
                            [
                                {
                                    "tlingit": "du káani",
                                    "url": "du káani",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her brother-in-law, sister-in-law",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "naa káani",
                                    "url": "naa káani",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "master of ceremonies, elder of the opposite clan consulted conducting a ceremony",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(brother of man's wife)"
                ],
                "ids": [
                    [
                        "10_46",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "her sister-in-law",
                "tlingit": [
                    [
                        [
                            "du káani",
                            [
                                {
                                    "tlingit": "du káani",
                                    "url": "du káani",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her brother-in-law, sister-in-law",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "naa káani",
                                    "url": "naa káani",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "master of ceremonies, elder of the opposite clan consulted conducting a ceremony",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "(sister of a woman's husband)"
                ],
                "ids": [
                    [
                        "10_47",
                        "J"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Note on in-laws. The Tlingit term káani does not match the English concept of a man's sister-in-law (wife's sister or brother's wife) or a woman's brother-in-law (sister's husband or husband's brother). For these relationships, the following terms are used:"
            },
            {
                "type": "ex",
                "english": "his wife's younger sister",
                "tlingit": [
                    [
                        [
                            "du shát",
                            [
                                {
                                    "tlingit": "du shát",
                                    "url": "du shát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his wife",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "kéek'",
                            [
                                {
                                    "tlingit": "du kéek'",
                                    "url": "du kéek'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her younger sister; his younger brother; cousin",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_48",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his wife's older sister",
                "tlingit": [
                    [
                        [
                            "du shát",
                            [
                                {
                                    "tlingit": "du shát",
                                    "url": "du shát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his wife",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "shátx̲",
                            [
                                {
                                    "tlingit": "du shátx̲",
                                    "url": "du shátx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her older sister, cousin",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_81",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his older brother's wife",
                "tlingit": [
                    [
                        [
                            "du húnx̲w",
                            [
                                {
                                    "tlingit": "du húnx̲w",
                                    "url": "du húnx̲w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his older brother, cousin",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "shát",
                            [
                                {
                                    "tlingit": "du shát",
                                    "url": "du shát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his wife",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_49",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his younger brother's wife",
                "tlingit": [
                    [
                        [
                            "du kéek'",
                            [
                                {
                                    "tlingit": "du kéek'",
                                    "url": "du kéek'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her younger sister; his younger brother; cousin",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "shát",
                            [
                                {
                                    "tlingit": "du shát",
                                    "url": "du shát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his wife",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_82",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "her husband's older brother",
                "tlingit": [
                    [
                        [
                            "du x̲úx̲",
                            [
                                {
                                    "tlingit": "du x̲úx̲",
                                    "url": "du x̲úx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her husband",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "húnx̲w",
                            [
                                {
                                    "tlingit": "du húnx̲w",
                                    "url": "du húnx̲w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his older brother, cousin",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_50",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "her husband's younger brother",
                "tlingit": [
                    [
                        [
                            "du x̲úx̲",
                            [
                                {
                                    "tlingit": "du x̲úx̲",
                                    "url": "du x̲úx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her husband",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "kéek'",
                            [
                                {
                                    "tlingit": "du kéek'",
                                    "url": "du kéek'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her younger sister; his younger brother; cousin",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_83",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "her younger sister's husband",
                "tlingit": [
                    [
                        [
                            "du kéek'",
                            [
                                {
                                    "tlingit": "du kéek'",
                                    "url": "du kéek'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her younger sister; his younger brother; cousin",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "x̲úx̲",
                            [
                                {
                                    "tlingit": "du x̲úx̲",
                                    "url": "du x̲úx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her husband",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_51",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "her older sister's husband",
                "tlingit": [
                    [
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shátx̲i",
                            [],
                            []
                        ],
                        [
                            "x̲úx̲",
                            [
                                {
                                    "tlingit": "du x̲úx̲",
                                    "url": "du x̲úx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her husband",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_84",
                        "J"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "These terms can either be two separate words, as above, each with a tone of its own, or they can be compound nouns, joined, with the tone on the first word \"stolen\" by the second."
            },
            {
                "type": "ex",
                "english": "his wife's younger sister",
                "tlingit": [
                    [
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shatkéek'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_52",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his wife's older sister",
                "tlingit": [
                    [
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shatshátx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_53",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his older brother's wife",
                "tlingit": [
                    [
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hunx̲wshát",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_54",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his younger brother's wife",
                "tlingit": [
                    [
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "keek'shát",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_55",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "her husband's older brother",
                "tlingit": [
                    [
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲ux̲húnx̲w",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_56",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "her husband's younger brother",
                "tlingit": [
                    [
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲ux̲kéek'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_57",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "her younger sister's husband",
                "tlingit": [
                    [
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "keek'x̲úx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_58",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "her older sister's husband",
                "tlingit": [
                    [
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shatx̲ix̲úx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_59",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "his/her relative/tribesperson",
                "tlingit": [
                    [
                        [
                            "du x̲ooní",
                            [
                                {
                                    "tlingit": "du x̲ooní",
                                    "url": "du x̲ooní",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her relative, friend; his/her tribesman",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲ooní",
                                    "url": "a x̲ooní",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "one that matches it; an amount that matches it; equivalent to it; one like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_60",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her partner",
                "tlingit": [
                    [
                        [
                            "du yak̲áawu",
                            [
                                {
                                    "tlingit": "du yak̲áawu",
                                    "url": "du yak̲áawu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her partner",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_61",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "her husband's clan brother",
                "tlingit": [
                    [
                        [
                            "du k̲áawu",
                            [
                                {
                                    "tlingit": "du k̲áawu",
                                    "url": "du k̲áawu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her husband's clan brother; his/her man, boyfriend, husband",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_62",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his wife's clan sister",
                "tlingit": [
                    [
                        [
                            "du shaawádi",
                            [
                                {
                                    "tlingit": "du shaawádi",
                                    "url": "du shaawádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his old lady (wife)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du shaawádi, du shaawadí",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its woman",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_63",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his wife's clan sisters",
                "tlingit": [
                    [
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawatx'i",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yán",
                            [
                                {
                                    "tlingit": "yán",
                                    "url": "yán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hemlock",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yán",
                                    "url": "yán2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shore; land",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_64",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "her tribal brother",
                "tlingit": [
                    [
                        [
                            "du xwáayi",
                            [
                                {
                                    "tlingit": "du xwáayi",
                                    "url": "du xwáayi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her clan brother",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_65",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his tribal sister",
                "tlingit": [
                    [
                        [
                            "du sháawu",
                            [
                                {
                                    "tlingit": "du sháawu",
                                    "url": "du sháawu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her clan sister",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_66",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his/her sweetheart",
                "tlingit": [
                    [
                        [
                            "du tseiyí",
                            [
                                {
                                    "tlingit": "du tseiyí",
                                    "url": "du tseiyí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her sweetheart",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_67",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "He got married.",
                "tlingit": [
                    [
                        [
                            "Aawasháa.",
                            [
                                {
                                    "tlingit": "aawasháa",
                                    "url": "aawasháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he married him/her; s/he is married",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_69",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She got married.",
                "tlingit": [
                    [
                        [
                            "Ash",
                            [
                                {
                                    "tlingit": "ash koolyát",
                                    "url": "ash kawdliyát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is playing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash sinéekw",
                                    "url": "ash wusinéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's making him/her sick; it makes him/her sick",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusitáax'",
                                    "url": "ash wusitáax'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it bit him/her/it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusix̲ék̲",
                                    "url": "ash wusix̲ék̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it kept him/her awake",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wulixwétl",
                                    "url": "awlixwétl",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it made him/her tired",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash kawdliyát",
                                    "url": "ash kawdliyát",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he played",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ash wusinéekw",
                                    "url": "ash wusinéekw",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it made him/her sick",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "uwasháa.",
                            [
                                {
                                    "tlingit": "aawasháa",
                                    "url": "aawasháa",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he married him/her; s/he is married",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wuduwasháa.",
                            [
                                {
                                    "tlingit": "wuduwasháa",
                                    "url": "wuduwasháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he got married",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "10_70",
                        "J"
                    ],
                    [
                        "10_71",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Terms of address. When speaking directly to a person, the following terms are used. Most of these feature a short vowel and the -k' suffix, which is a term of endearment."
            },
            {
                "type": "ex",
                "english": "mother",
                "tlingit": [
                    [
                        [
                            "atléi",
                            [
                                {
                                    "tlingit": "atléi",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Mom",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "atlée",
                            [
                                {
                                    "tlingit": "atlée",
                                    "url": "",
                                    "tags": [
                                        ""
                                    ],
                                    "english": "Mom",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "10_72",
                        "J"
                    ],
                    [
                        "10_73",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "grandchild",
                "tlingit": [
                    [
                        [
                            "chx̲ánk'",
                            [
                                {
                                    "tlingit": "du dachx̲án",
                                    "url": "du dachx̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her grandchild",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-k'",
                                    "url": "-k'",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "little; precious; [diminutive suffix]",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_74",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "son",
                "tlingit": [
                    [
                        [
                            "yitk'",
                            [
                                {
                                    "tlingit": "du yéet",
                                    "url": "du yéet",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her son, cousin",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-k'",
                                    "url": "-k'",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "little; precious; [diminutive suffix]",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-k'",
                                    "url": "-k'",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "little; precious; [diminutive suffix]",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du yéet",
                                    "url": "du yéet",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her son, cousin",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_75",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "daughter",
                "tlingit": [
                    [
                        [
                            "sik'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_76",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "niece/nephew",
                "tlingit": [
                    [
                        [
                            "kélk'",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "kálk'w",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "10_77",
                        "N"
                    ],
                    [
                        "10_78",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "father",
                "tlingit": [
                    [
                        [
                            "éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_79",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "grandparent",
                "tlingit": [
                    [
                        [
                            "léelk'w",
                            [
                                {
                                    "tlingit": "Léelk'w!",
                                    "url": "Léelk'w!",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Grandmother!; Grandfather!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "10_80",
                        "J"
                    ]
                ]
            }
        ]
    },
    "11": {
        "title": "Reporting news, giving messages",
        "content": [
            {
                "type": "ex",
                "english": "My mother is sick.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tláa",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yanéekw.",
                            [
                                {
                                    "tlingit": "yanéekw",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's sick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My paternal uncle died.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sáni",
                            [
                                {
                                    "tlingit": "du sáni",
                                    "url": "du sáni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her paternal uncle, cousin",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "woonaa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "His father died.",
                "tlingit": [
                    [
                        [
                            "Du éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "woonaa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Did they tell you?",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "een",
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kadunéek?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Do you know that our uncle died?",
                "tlingit": [
                    [
                        [
                            "Yisikóo",
                            [
                                {
                                    "tlingit": "awsikóo",
                                    "url": "awsikóo",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he knows it; s/he learned it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sáni",
                            [
                                {
                                    "tlingit": "du sáni",
                                    "url": "du sáni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her paternal uncle, cousin",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wunaawú?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I had an operation/surgery.",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wuduwaxaash.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he had surgery.",
                "tlingit": [
                    [
                        [
                            "Wuduwaxaash.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My father is going to have surgery.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gax̲duxáash.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Someone phoned me.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeet",
                            [
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tán!",
                                    "url": "du jeet aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet .áx̲!",
                                    "url": "du jeet aawa.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet katí!",
                                    "url": "du jeet akaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet la.át!",
                                    "url": "du jeet awli.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give them to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet sa.ín!",
                                    "url": "du jeet awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet satán!",
                                    "url": "du jeet awsitán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'awduwatán.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Someone telephoned for you.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeet",
                            [
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tán!",
                                    "url": "du jeet aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet .áx̲!",
                                    "url": "du jeet aawa.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet katí!",
                                    "url": "du jeet akaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet la.át!",
                                    "url": "du jeet awli.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give them to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet sa.ín!",
                                    "url": "du jeet awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet satán!",
                                    "url": "du jeet awsitán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'awduwatán.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You're wanted on the phone.",
                "tlingit": [
                    [
                        [
                            "Telephone",
                            [],
                            []
                        ],
                        [
                            "x̲'éide",
                            [
                                {
                                    "tlingit": "a x̲'éide",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "allative"
                                    ],
                                    "english": "toward its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide gux̲sa.áax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will listen to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éide yakg̲was.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will kiss me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲uwdzi.aax̲",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listened to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éide akagux̲latéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will lock it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲unas.áx̲ch",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éide yaa akanaltíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's locking it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "idux̲oox̲.",
                            [
                                {
                                    "tlingit": "aawax̲oox̲",
                                    "url": "aawax̲oox̲",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he summoned him/her; s/he called him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aawax̲oox̲",
                                    "url": "aawax̲oox̲",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he summoned him/her; s/he called him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Your paternal uncle telephoned for you.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sánich",
                            [],
                            []
                        ],
                        [
                            "telephone",
                            [],
                            []
                        ],
                        [
                            "tóode",
                            [
                                {
                                    "tlingit": "a tóode",
                                    "url": "a tú",
                                    "tags": [
                                        "RelationalNoun",
                                        "allative"
                                    ],
                                    "english": "into it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "eewax̲óox̲.",
                            [
                                {
                                    "tlingit": "aawax̲oox̲",
                                    "url": "aawax̲oox̲",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he summoned him/her; s/he called him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They telephoned about you.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daat",
                            [
                                {
                                    "tlingit": "a daat",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "about it; around it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daat",
                                    "url": "a daat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "(telling) about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daat yawsiták̲",
                                    "url": "du daat yawsiták̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is taking care of him/her; s/he took care of him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daat át k̲udzitee",
                                    "url": "du daat át k̲oowdzitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is busy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du daat iyasaták̲!",
                                    "url": "du daat yawsiták̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take care of him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du daat yee yasaták̲!",
                                    "url": "du daat yawsiták̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all take care of him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daat át k̲oowdzitee",
                                    "url": "du daat át k̲oowdzitee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was busy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'awduwatán.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They phoned about your teeth.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "oox̲",
                            [
                                {
                                    "tlingit": "du oox̲",
                                    "url": "du oox̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her tooth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "oox̲ katság̲aa",
                                    "url": "oox̲ katság̲aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "toothpick",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ layeix̲í",
                                    "url": "k̲aa oox̲ layeix̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ yei daanéiyi",
                                    "url": "k̲aa oox̲ yei daanéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ leyeix̲í",
                                    "url": "k̲aa oox̲ layeix̲í",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "daat",
                            [
                                {
                                    "tlingit": "a daat",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "about it; around it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daat",
                                    "url": "a daat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "(telling) about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daat yawsiták̲",
                                    "url": "du daat yawsiták̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is taking care of him/her; s/he took care of him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daat át k̲udzitee",
                                    "url": "du daat át k̲oowdzitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is busy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du daat iyasaták̲!",
                                    "url": "du daat yawsiták̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take care of him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du daat yee yasaták̲!",
                                    "url": "du daat yawsiták̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all take care of him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daat át k̲oowdzitee",
                                    "url": "du daat át k̲oowdzitee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was busy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'awduwatán.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'll make an appointment.",
                "tlingit": [
                    [
                        [
                            "Appointment",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kk̲wasanéi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What are you telling him/her?",
                "tlingit": [
                    [
                        [
                            "Daa sá",
                            [
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléil daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yanees.á!",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Examine it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yoo tutánk!",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Think about it!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "du een",
                            [
                                {
                                    "tlingit": "du een aa",
                                    "url": "du een aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her kinsman, moiety mate",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "keenéek?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm telling him/her about her father.",
                "tlingit": [
                    [
                        [
                            "Du éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "daat át",
                            [
                                {
                                    "tlingit": "du daat át k̲udzitee",
                                    "url": "du daat át k̲oowdzitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is busy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daat át k̲oowdzitee",
                                    "url": "du daat át k̲oowdzitee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he was busy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daat át k̲ug̲asteech",
                                    "url": "du daat át k̲oowdzitee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets busy (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daat át yei k̲usteech",
                                    "url": "du daat át k̲oowdzitee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is busy (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daat át yei k̲ukg̲wastée",
                                    "url": "du daat át k̲oowdzitee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will be busy",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a daat",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "about it; around it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daat",
                                    "url": "a daat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "(telling) about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daat yawsiták̲",
                                    "url": "du daat yawsiták̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is taking care of him/her; s/he took care of him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "át",
                                    "url": "át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "thing",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Du daat iyasaták̲!",
                                    "url": "du daat yawsiták̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take care of him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du daat yee yasaták̲!",
                                    "url": "du daat yawsiták̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all take care of him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl du daat iyawustaag̲ík̲!",
                                    "url": "du daat yawsiták̲",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't take care of him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du een",
                            [
                                {
                                    "tlingit": "du een aa",
                                    "url": "du een aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her kinsman, moiety mate",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "kax̲anéek.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Tell him/her ___.",
                "tlingit": [
                    [
                        [
                            "Du een",
                            [
                                {
                                    "tlingit": "du een aa",
                                    "url": "du een aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her kinsman, moiety mate",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "kananeek ___.",
                            [
                                {
                                    "tlingit": "Kananeek!",
                                    "url": "akaawaneek",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Tell about it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I will tell him/her ___.",
                "tlingit": [
                    [
                        [
                            "Du een",
                            [
                                {
                                    "tlingit": "du een aa",
                                    "url": "du een aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her kinsman, moiety mate",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "kakk̲wanéek",
                            [],
                            []
                        ],
                        [
                            "___.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Did s/he call?",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'awditán?",
                            [
                                {
                                    "tlingit": "du jeet x̲'awditán",
                                    "url": "du jeet x̲'awditán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he called him/her on the phone",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "How is she/he/it?",
                "tlingit": [
                    [
                        [
                            "Wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yatee?",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She/he/it is OK.",
                "tlingit": [
                    [
                        [
                            "G̲aa",
                            [
                                {
                                    "tlingit": "g̲aa",
                                    "url": "g̲aa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "enough, acceptably",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She/he/it is not in very good shape.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲aa",
                            [
                                {
                                    "tlingit": "g̲aa",
                                    "url": "g̲aa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "enough, acceptably",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ushti.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "11_23",
                        "N"
                    ]
                ]
            }
        ]
    },
    "12": {
        "title": "Ceremonial situations, invitations to speak, models for public speaking",
        "content": [
            {
                "type": "ex",
                "english": "I am invited.",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wuduwa.éex'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We are invited.",
                "tlingit": [
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wduwa.éex'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I am invited to Sitka.",
                "tlingit": [
                    [
                        [
                            "Sheet'kaadí",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wuduwa.éex'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Our leader will speak.",
                "tlingit": [
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháade háni",
                            [
                                {
                                    "tlingit": "k̲aa sháade háni",
                                    "url": "k̲aa sháade háni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "leader",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "k̲aa sháade héni",
                                    "url": "k̲aa sháade háni",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "leader",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa sháade nák̲x'i",
                                    "url": "k̲aa sháade nák̲x'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "leaders",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "x̲'agux̲datáan.",
                            [
                                {
                                    "tlingit": "x̲'agux̲datáan",
                                    "url": "x̲'awditaan",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will speak",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "ladies and gentlemen",
                "tlingit": [
                    [
                        [
                            "sh yáa",
                            [
                                {
                                    "tlingit": "sh yáa awudanéiyi",
                                    "url": "sh yáa awudanéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "respected person; gentleman; lady",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo altóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is studying it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "awudinéiyi",
                            [],
                            []
                        ],
                        [
                            "sháa",
                            [
                                {
                                    "tlingit": "sháa",
                                    "url": "sháa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "women",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲a",
                            [
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áax'w",
                            [
                                {
                                    "tlingit": "k̲áax'w",
                                    "url": "k̲áax'w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "men",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "people of honor; honored people",
                "tlingit": [
                    [
                        [
                            "sh yáa",
                            [
                                {
                                    "tlingit": "sh yáa awudanéiyi",
                                    "url": "sh yáa awudanéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "respected person; gentleman; lady",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo altóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is studying it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "awudanéix'i",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "you people of honor seated here",
                "tlingit": [
                    [
                        [
                            "sh yáa",
                            [
                                {
                                    "tlingit": "sh yáa awudanéiyi",
                                    "url": "sh yáa awudanéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "respected person; gentleman; lady",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo altóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is studying it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "awudanéix'i",
                            [],
                            []
                        ],
                        [
                            "yáat",
                            [
                                {
                                    "tlingit": "yáat",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "punctual"
                                    ],
                                    "english": "this (place)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yik̲eení",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "those who are my in-laws",
                "tlingit": [
                    [
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kaani",
                            [],
                            []
                        ],
                        [
                            "yánx̲",
                            [],
                            []
                        ],
                        [
                            "siteeyi",
                            [],
                            []
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "you (the ones) who invited us here",
                "tlingit": [
                    [
                        [
                            "yáade",
                            [
                                {
                                    "tlingit": "yáade",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "allative"
                                    ],
                                    "english": "toward here",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeey.éex'i",
                            [],
                            []
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "that he invited us here",
                "tlingit": [
                    [
                        [
                            "yáade",
                            [
                                {
                                    "tlingit": "yáade",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "allative"
                                    ],
                                    "english": "toward here",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "woo.éex'i",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "you asked me to this table",
                "tlingit": [
                    [
                        [
                            "tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲át",
                            [
                                {
                                    "tlingit": "x̲át",
                                    "url": "x̲át",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "I [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nadáagu",
                            [],
                            []
                        ],
                        [
                            "a",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaax̲déi",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeeyx̲oox̲ú",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "tsu",
                            [
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲át",
                            [
                                {
                                    "tlingit": "x̲át",
                                    "url": "x̲át",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "I [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nadáakw",
                            [
                                {
                                    "tlingit": "nadáakw",
                                    "url": "nadáakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "table",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daadé",
                            [
                                {
                                    "tlingit": "a daadé",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "allative"
                                    ],
                                    "english": "around it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daadé yagux̲satáak̲",
                                    "url": "du daat yawsiták̲",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will take care of him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeeyx̲oox̲ú",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "12_11",
                        "N"
                    ],
                    [
                        "12_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "At this moment I (also) would like to speak.",
                "tlingit": [
                    [
                        [
                            "Yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeedát",
                            [
                                {
                                    "tlingit": "yeedát",
                                    "url": "yeedát",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "now",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "(x̲át",
                            [
                                {
                                    "tlingit": "x̲át",
                                    "url": "x̲át",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "I [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tsú)",
                            [
                                {
                                    "tlingit": "tsú",
                                    "url": "tsú",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "also, too, as well",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'ax̲wdataaní.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "At this time for a short while I would like to speak.",
                "tlingit": [
                    [
                        [
                            "Yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gaaw",
                            [
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "time",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bell",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "drum",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ch'a yéi",
                            [
                                {
                                    "tlingit": "ch'a yéi",
                                    "url": "ch'a yéi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "ordinary, usual",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "ch'a",
                                    "url": "ch'a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "the very, just",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yiguwáatl'",
                            [],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "x̲'ax̲wdataaní.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Please forgive me if I don't do this correctly. (or \"if it doesn't come out right\")",
                "tlingit": [
                    [
                        [
                            "Ch'a",
                            [
                                {
                                    "tlingit": "ch'a",
                                    "url": "ch'a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "the very, just",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aadéi",
                            [],
                            []
                        ],
                        [
                            "k̲wá",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'agax̲yee.oo",
                            [],
                            []
                        ],
                        [
                            "l",
                            [
                                {
                                    "tlingit": "l",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áyáx̲",
                            [
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yáx̲",
                                    "tags": [
                                        "RelationalPostposition"
                                    ],
                                    "english": "like it, in accordance with it, as much as it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yóot",
                            [
                                {
                                    "tlingit": "yóot",
                                    "url": "yóot~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "away, off (to someplace indefinite)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóot",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative",
                                        "punctual"
                                    ],
                                    "english": "that (distant) (place)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóot~",
                                    "url": "yóot~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "away, off (to someplace indefinite)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wuxeexí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "For a short while please give me time.",
                "tlingit": [
                    [
                        [
                            "Ch'a yéi",
                            [
                                {
                                    "tlingit": "ch'a yéi",
                                    "url": "ch'a yéi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "ordinary, usual",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "ch'a",
                                    "url": "ch'a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "the very, just",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yiguwáatl'",
                            [],
                            []
                        ],
                        [
                            "gaaw",
                            [
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "time",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bell",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "drum",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeet yití.",
                            [
                                {
                                    "tlingit": "Du jeet yití!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Kei yití!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all bring it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet kaytí!",
                                    "url": "du jeet akaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet yi.áx̲!",
                                    "url": "du jeet aawa.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tán!",
                                    "url": "du jeet aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet yila.át!",
                                    "url": "du jeet awli.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all give them to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet yisa.ín!",
                                    "url": "du jeet awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all give it to him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "This evening the time has come for us to discuss things.",
                "tlingit": [
                    [
                        [
                            "Yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "xáanaa",
                            [
                                {
                                    "tlingit": "xáanaa",
                                    "url": "xáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "evening",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "át",
                            [
                                {
                                    "tlingit": "át",
                                    "url": "át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "thing",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲oowaháa",
                            [],
                            []
                        ],
                        [
                            "daa sá",
                            [
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléil daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yanees.á!",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Examine it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yoo tutánk!",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Think about it!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "katooneegí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "This evening the time has come for people to discuss things.",
                "tlingit": [
                    [
                        [
                            "Yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "xáanaa",
                            [
                                {
                                    "tlingit": "xáanaa",
                                    "url": "xáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "evening",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "át",
                            [
                                {
                                    "tlingit": "át",
                                    "url": "át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "thing",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲oowaháa",
                            [],
                            []
                        ],
                        [
                            "daa sá",
                            [
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléil daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yanees.á!",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Examine it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yoo tutánk!",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Think about it!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "katduneegí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "At this time I would like to explain.",
                "tlingit": [
                    [
                        [
                            "Yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gaaw",
                            [
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "time",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bell",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "drum",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "k̲unáax̲",
                            [],
                            []
                        ],
                        [
                            "daak",
                            [
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kax̲waneegí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I want to say to you...",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowáx'",
                            [],
                            []
                        ],
                        [
                            "sigóo,",
                            [
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yéi yee",
                            [
                                {
                                    "tlingit": "Yéi yee natí!",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be that way!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Tleiyéi yéi yee natí!",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be still!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲ánx' yéi yee natí!",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all stay with him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl yéi yee utéek̲!",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Pl"
                                    ],
                                    "english": "Don't you all be that way!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yax̲wsak̲aayí...",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Won't you please listen to this.",
                "tlingit": [
                    [
                        [
                            "Haandéi",
                            [
                                {
                                    "tlingit": "haandéi",
                                    "url": "haandé",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hand it here, bring it here",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲unayis.aax̲",
                            [
                                {
                                    "tlingit": "Du x̲'éide k̲unayis.aax̲!",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A kayéikg̲aa k̲unayis.aax̲!",
                                    "url": "a kayéikg̲aa k̲oowdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen for the sound of it!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x'wán.",
                            [
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boot(s)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "be sure to",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Haandéi",
                            [
                                {
                                    "tlingit": "haandéi",
                                    "url": "haandé",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hand it here, bring it here",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲inayis.aax̲",
                            [],
                            []
                        ],
                        [
                            "x'wán.",
                            [
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boot(s)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "be sure to",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Haandéi",
                            [
                                {
                                    "tlingit": "haandéi",
                                    "url": "haandé",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hand it here, bring it here",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲inays.aax̲",
                            [],
                            []
                        ],
                        [
                            "x'wán.",
                            [
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boot(s)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x'wán",
                                    "url": "x'wán2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "be sure to",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "12_22",
                        "N"
                    ],
                    [
                        "12_23",
                        "J"
                    ],
                    [
                        "12_24",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "A while ago I listened to you and how you were thinking.",
                "tlingit": [
                    [
                        [
                            "Yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dziyáak",
                            [
                                {
                                    "tlingit": "dziyáak",
                                    "url": "dziyáak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "just now; a while ago, earlier",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲wasi.áx̲,",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tundatáani,",
                            [
                                {
                                    "tlingit": "du tundatáani",
                                    "url": "tundatáan",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its thought",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "aadéi",
                            [],
                            []
                        ],
                        [
                            "yateeyi",
                            [
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yé.",
                            [
                                {
                                    "tlingit": "yé",
                                    "url": "yé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "place; way",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_25",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "This is how I feel (about it).",
                "tlingit": [
                    [
                        [
                            "Yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowú",
                            [
                                {
                                    "tlingit": "du toowú",
                                    "url": "du toowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her inner being; mind; soul; feelings; intention",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú k'é",
                                    "url": "toowú k'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "good thoughts; felicity; happiness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú néekw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú nóok",
                                    "url": "toowú nóok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sorrow; sadness",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú klag̲é",
                                    "url": "toowú klag̲é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pride; self-esteem, feeling good about oneself",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "toowú latseen",
                                    "url": "toowú latseen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "strength of mind or heart; courage; resolve",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_26",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "(This) is how I am thinking about it.",
                "tlingit": [
                    [
                        [
                            "(A)",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲",
                            [
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the face of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yáx̲",
                                    "tags": [
                                        "RelationalPostposition"
                                    ],
                                    "english": "like it, in accordance with it, as much as it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaaw yáx̲",
                                    "url": "gaaw yáx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "on time; in time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Wooch yáx̲ nasné!",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Straighten it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wudlitl'éit'",
                                    "url": "a yáx̲ wudlitl'éit'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it climbed the face of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daa yoo",
                            [
                                {
                                    "tlingit": "A daa yoo tutánk!",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Think about it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yoo teeyla.át!",
                                    "url": "a daa yoo (ha)s tuwli.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all think about it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yoo toowatán",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he thought about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yoo tuwatánk",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he thinks about it; s/he is thinking about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yoo tukg̲watáan",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will think about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yoo (ha)s tuwli.át",
                                    "url": "a daa yoo (ha)s tuwli.át",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Pl"
                                    ],
                                    "english": "they thought about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yoo (ha)s tuli.átk",
                                    "url": "a daa yoo (ha)s tuwli.át",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they think about it; they are thinking about it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yoo",
                                    "url": "yoo",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "back and forth; to and fro; up and down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "tux̲aatánk.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "12_27",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "For [Name] (to wear).",
                "tlingit": [
                    [
                        [
                            "[Name]",
                            [],
                            []
                        ],
                        [
                            "kaadéi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(Said when departed are remembered.)"
                ],
                "ids": [
                    [
                        "12_28",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "For [Name] (to eat).",
                "tlingit": [
                    [
                        [
                            "[Name]",
                            [],
                            []
                        ],
                        [
                            "x̲'éide.",
                            [
                                {
                                    "tlingit": "a x̲'éide",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "allative"
                                    ],
                                    "english": "toward its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide gux̲sa.áax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will listen to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éide yakg̲was.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will kiss me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲uwdzi.aax̲",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listened to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éide akagux̲latéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will lock it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éide k̲unas.áx̲ch",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éide k̲unees.aax̲!",
                                    "url": "du x̲'éide k̲uwdzi.aax̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(Said when departed are remembered.)"
                ],
                "ids": [
                    [
                        "12_29",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Here!",
                "tlingit": [
                    [
                        [
                            "Haadé!",
                            [
                                {
                                    "tlingit": "haadé",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "allative"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(Said when gifts [other than fire dishes] are being brought.)"
                ],
                "ids": [
                    [
                        "12_30",
                        "N"
                    ]
                ]
            }
        ]
    },
    "13": {
        "title": "Coming, going, and traveling",
        "content": [
            {
                "type": "ex",
                "english": "Where are you [singular] going?",
                "tlingit": [
                    [
                        [
                            "Goodé",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "neegút?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm going home.",
                "tlingit": [
                    [
                        [
                            "Neildé",
                            [
                                {
                                    "tlingit": "neildé",
                                    "url": "neil~",
                                    "tags": [
                                        "Adverb",
                                        "allative"
                                    ],
                                    "english": "inside, into the house, home",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nx̲agút.",
                            [
                                {
                                    "tlingit": "yaa nagút",
                                    "url": "yaa nagút",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he is walking along; s/he is going along",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where is s/he going?",
                "tlingit": [
                    [
                        [
                            "Goodé",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaa nagút?",
                            [
                                {
                                    "tlingit": "yaa nagút",
                                    "url": "yaa nagút",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is walking along",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "áx̲ yaa nagút",
                                    "url": "áx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is walking along it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nagút",
                                    "url": "aadé woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is going there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nagút",
                                    "url": "át uwagút",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is going there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "daak yaa nagút",
                                    "url": "daak uwagút",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is walking into the open",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gági yaa nagút",
                                    "url": "gági uwagút",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is emerging",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a nák̲ yaa nagút",
                                    "url": "a nák̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is leaving it behind",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a ítx̲ yaa nagút",
                                    "url": "a ítx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is following it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's going home.",
                "tlingit": [
                    [
                        [
                            "Neildé",
                            [
                                {
                                    "tlingit": "neildé",
                                    "url": "neil~",
                                    "tags": [
                                        "Adverb",
                                        "allative"
                                    ],
                                    "english": "inside, into the house, home",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaa nagút.",
                            [
                                {
                                    "tlingit": "yaa nagút",
                                    "url": "yaa nagút",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is walking along",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "áx̲ yaa nagút",
                                    "url": "áx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is walking along it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nagút",
                                    "url": "aadé woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is going there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nagút",
                                    "url": "át uwagút",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is going there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "daak yaa nagút",
                                    "url": "daak uwagút",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is walking into the open",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gági yaa nagút",
                                    "url": "gági uwagút",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is emerging",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a nák̲ yaa nagút",
                                    "url": "a nák̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is leaving it behind",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a ítx̲ yaa nagút",
                                    "url": "a ítx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is following it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where are you [plural] going?",
                "tlingit": [
                    [
                        [
                            "Goodé",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nay.át?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We're going to school.",
                "tlingit": [
                    [
                        [
                            "Sgóonde",
                            [
                                {
                                    "tlingit": "sgóon",
                                    "url": "sgóon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "school",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dé(i) ~ -de(i)",
                                    "url": "-dé(i) ~ -de(i)",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "to, toward; until; in the manner of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ntoo.át.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where are they going?",
                "tlingit": [
                    [
                        [
                            "Goodé",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaa has na.át?",
                            [
                                {
                                    "tlingit": "yaa (ha)s na.át",
                                    "url": "has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yaa (ha)s ayanda.át",
                                    "url": "has ayawdi.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are turning back",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ yaa (ha)s na.át",
                                    "url": "áx̲ yaa (ha)s na.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking along there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa (ha)s ashuna.át",
                                    "url": "has ashoowa.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is leading them",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa (ha)s na.át",
                                    "url": "aadé has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa (ha)s na.át",
                                    "url": "át has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gági yaa (ha)s na.át",
                                    "url": "gági has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are emerging",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa has lunagúk̲",
                                    "url": "has loowagook̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are running",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They're going to town.",
                "tlingit": [
                    [
                        [
                            "Aandé",
                            [
                                {
                                    "tlingit": "aan",
                                    "url": "aan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "town; village; settlement; inhabited or owned land",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dé(i) ~ -de(i)",
                                    "url": "-dé(i) ~ -de(i)",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "to, toward; until; in the manner of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yaa has na.át.",
                            [
                                {
                                    "tlingit": "yaa (ha)s na.át",
                                    "url": "has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yaa (ha)s ayanda.át",
                                    "url": "has ayawdi.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are turning back",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ yaa (ha)s na.át",
                                    "url": "áx̲ yaa (ha)s na.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking along there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa (ha)s ashuna.át",
                                    "url": "has ashoowa.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is leading them",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa (ha)s na.át",
                                    "url": "aadé has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa (ha)s na.át",
                                    "url": "át has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gági yaa (ha)s na.át",
                                    "url": "gági has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are emerging",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa has lunagúk̲",
                                    "url": "has loowagook̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are running",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "Aan",
                            [
                                {
                                    "tlingit": "aan",
                                    "url": "aan",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "with it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "aan",
                                    "url": "aan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "town; village; settlement; inhabited or owned land",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kaadé yaa has na.át.",
                            [
                                {
                                    "tlingit": "yaa (ha)s na.át",
                                    "url": "has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "aadé yaa (ha)s na.át",
                                    "url": "aadé has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa (ha)s na.át",
                                    "url": "át has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aag̲áa yaa (ha)s na.át",
                                    "url": "aag̲áa has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are going to get it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ yaa (ha)s na.át",
                                    "url": "áx̲ yaa (ha)s na.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking along there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gági yaa (ha)s na.át",
                                    "url": "gági has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are emerging",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "anax̲ yaa (ha)s wuna.át",
                                    "url": "anax̲ has yaawa.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking through it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa (ha)s lunagúk̲",
                                    "url": "aadé (ha)s loowagook̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are running there",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "Aan",
                            [
                                {
                                    "tlingit": "aan",
                                    "url": "aan",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "with it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "aan",
                                    "url": "aan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "town; village; settlement; inhabited or owned land",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'ayeedé",
                            [],
                            []
                        ],
                        [
                            "yaa has na.át.",
                            [
                                {
                                    "tlingit": "yaa (ha)s na.át",
                                    "url": "has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yaa (ha)s ayanda.át",
                                    "url": "has ayawdi.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are turning back",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ yaa (ha)s na.át",
                                    "url": "áx̲ yaa (ha)s na.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking along there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa (ha)s ashuna.át",
                                    "url": "has ashoowa.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is leading them",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa (ha)s na.át",
                                    "url": "aadé has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa (ha)s na.át",
                                    "url": "át has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gági yaa (ha)s na.át",
                                    "url": "gági has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are emerging",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa has lunagúk̲",
                                    "url": "has loowagook̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are running",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "13_8",
                        "J"
                    ],
                    [
                        "13_9",
                        "N"
                    ],
                    [
                        "13_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where are you coming from?",
                "tlingit": [
                    [
                        [
                            "Goodáx̲",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "neegút?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm coming from school.",
                "tlingit": [
                    [
                        [
                            "Sgóondáx̲",
                            [
                                {
                                    "tlingit": "sgóon",
                                    "url": "sgóon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "school",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dáx̲",
                                    "url": "-dáx̲",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "from, out of; since",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nx̲agút.",
                            [
                                {
                                    "tlingit": "yaa nagút",
                                    "url": "yaa nagút",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he is walking along; s/he is going along",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm going over there.",
                "tlingit": [
                    [
                        [
                            "Yóode",
                            [
                                {
                                    "tlingit": "yóode",
                                    "url": "yóot~",
                                    "tags": [
                                        "Adverb",
                                        "allative"
                                    ],
                                    "english": "away, off (to someplace indefinite)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóode",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative",
                                        "allative"
                                    ],
                                    "english": "toward yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nx̲agút.",
                            [
                                {
                                    "tlingit": "yaa nagút",
                                    "url": "yaa nagút",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he is walking along; s/he is going along",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where do you want to go?",
                "tlingit": [
                    [
                        [
                            "Goodé",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ituwatee?",
                            [
                                {
                                    "tlingit": "yéi tuwatee",
                                    "url": "yéi tuwatee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he feels that way; s/he wants to do it; s/he feels like doing it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi tuwatee",
                                    "url": "yéi tuwatee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he feels that way; s/he wants to do it; s/he feels like doing it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Let's go to the store.",
                "tlingit": [
                    [
                        [
                            "Hoon",
                            [
                                {
                                    "tlingit": "hoon",
                                    "url": "hoon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sale",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daakahídidé",
                            [],
                            []
                        ],
                        [
                            "nax̲too.aat.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Come with me first.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "een",
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'é",
                            [
                                {
                                    "tlingit": "s'é",
                                    "url": "s'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clay; alluvial silt",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "s'é",
                                    "url": "s'é2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "first",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "na.á.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "X̲aan",
                            [],
                            []
                        ],
                        [
                            "s'é",
                            [
                                {
                                    "tlingit": "s'é",
                                    "url": "s'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clay; alluvial silt",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "s'é",
                                    "url": "s'é2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "first",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "na.á.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "13_16",
                        "J"
                    ],
                    [
                        "13_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Did you come on the bus?",
                "tlingit": [
                    [
                        [
                            "Bus",
                            [],
                            []
                        ],
                        [
                            "kát",
                            [
                                {
                                    "tlingit": "a kát",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát adatéen",
                                    "url": "kát adatéen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is wearing it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du kát shutí!",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Blame it on him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A kát kalak̲á!",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sew it on it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát kawdigán",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát wujix̲ín",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it fell on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát dul.us'ku át",
                                    "url": "a káa dul.us'ku át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "washboard",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲eeyatín?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is s/he riding the bus?",
                "tlingit": [
                    [
                        [
                            "Bus",
                            [],
                            []
                        ],
                        [
                            "kát",
                            [
                                {
                                    "tlingit": "a kát",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát adatéen",
                                    "url": "kát adatéen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is wearing it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du kát shutí!",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Blame it on him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A kát kalak̲á!",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sew it on it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát kawdigán",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát wujix̲ín",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it fell on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát dul.us'ku át",
                                    "url": "a káa dul.us'ku át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "washboard",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaa k̲unatín?",
                            [
                                {
                                    "tlingit": "aadé yaa k̲unatín",
                                    "url": "aadé k̲oowateen",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is traveling there",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is s/he riding/driving the bus?",
                "tlingit": [
                    [
                        [
                            "Bus",
                            [],
                            []
                        ],
                        [
                            "kát",
                            [
                                {
                                    "tlingit": "a kát",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát adatéen",
                                    "url": "kát adatéen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is wearing it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du kát shutí!",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Blame it on him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A kát kalak̲á!",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sew it on it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát kawdigán",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát wujix̲ín",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it fell on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát dul.us'ku át",
                                    "url": "a káa dul.us'ku át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "washboard",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaa nak̲úx̲?",
                            [
                                {
                                    "tlingit": "yaa nak̲úx̲",
                                    "url": "wook̲oox̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going (by motorized vehicle)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa nak̲úx̲",
                                    "url": "yaa nak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going along (by boat, car)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "daak nak̲úx̲",
                                    "url": "daak uwak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going out to sea",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nak̲úx̲",
                                    "url": "aadé wook̲oox̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nak̲úx̲",
                                    "url": "át uwak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving to it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yánde yaa nak̲úx̲",
                                    "url": "yan uwak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going ashore",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daax̲ yaa nak̲úx̲",
                                    "url": "a daax̲ yaawak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving around it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a tóonáx̲ yaa nak̲úx̲",
                                    "url": "a tóonáx̲ yaawak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving through it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲ut kei nak̲úx̲",
                                    "url": "k̲ut wook̲oox̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is getting lost",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where did you go? (or \"Where have you been?\")",
                "tlingit": [
                    [
                        [
                            "Goodé",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yigoodéen?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where did you travel from?",
                "tlingit": [
                    [
                        [
                            "Goodáx̲",
                            [],
                            []
                        ],
                        [
                            "sáwé",
                            [],
                            []
                        ],
                        [
                            "yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲ineetín?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Did you fly over?",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ák.wé",
                            [
                                {
                                    "tlingit": "ák.wé",
                                    "url": "ák.wé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yidik̲ín?",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yidik̲ín?",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Plane",
                            [],
                            []
                        ],
                        [
                            "kát",
                            [
                                {
                                    "tlingit": "a kát",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát adatéen",
                                    "url": "kát adatéen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is wearing it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du kát shutí!",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Blame it on him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A kát kalak̲á!",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Sew it on it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát kawdigán",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát wujix̲ín",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it fell on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát dul.us'ku át",
                                    "url": "a káa dul.us'ku át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "washboard",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲eeyatín?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "13_23",
                        "N"
                    ],
                    [
                        "13_24",
                        "J"
                    ],
                    [
                        "13_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We're flying to Sitka today.",
                "tlingit": [
                    [
                        [
                            "Sheet'kaadé",
                            [],
                            []
                        ],
                        [
                            "yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kanalyích",
                            [
                                {
                                    "tlingit": "aadé yaa kanalyích",
                                    "url": "át kawdliyích",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are flying there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "anák̲ yaa kanalyích",
                                    "url": "anák̲ kawdliyeech",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are flying away from it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagiyee.",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_26",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We're flying to Sitka tomorrow.",
                "tlingit": [
                    [
                        [
                            "Sheet'kaadé",
                            [],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kakg̲walyéech",
                            [
                                {
                                    "tlingit": "át kakg̲walyéech",
                                    "url": "át kawdliyeech",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will fly around",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé kakg̲walyéech",
                                    "url": "át kawdliyích",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will fly there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "anák̲ kakg̲walyéech",
                                    "url": "anák̲ kawdliyeech",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will fly away from it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "seig̲án.",
                            [
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲ánin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_27",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I am flying to Sitka today.",
                "tlingit": [
                    [
                        [
                            "Sheet'kaadé",
                            [],
                            []
                        ],
                        [
                            "kk̲wadak̲éen",
                            [],
                            []
                        ],
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagiyee.",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_28",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I am flying to Sitka tomorrow.",
                "tlingit": [
                    [
                        [
                            "Sheet'kaadé",
                            [],
                            []
                        ],
                        [
                            "kk̲wadak̲éen",
                            [],
                            []
                        ],
                        [
                            "seig̲án.",
                            [
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲ánin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_29",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he is flying today.",
                "tlingit": [
                    [
                        [
                            "Kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gux̲dak̲éen",
                            [
                                {
                                    "tlingit": "át gux̲dak̲éen",
                                    "url": "át wudik̲een",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will fly around",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé gux̲dak̲éen",
                                    "url": "aadé wdik̲een",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will fly there",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagiyee.",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_30",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he is flying tomorrow.",
                "tlingit": [
                    [
                        [
                            "Kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gux̲dak̲éen",
                            [
                                {
                                    "tlingit": "át gux̲dak̲éen",
                                    "url": "át wudik̲een",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will fly around",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé gux̲dak̲éen",
                                    "url": "aadé wdik̲een",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will fly there",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "seig̲án.",
                            [
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲ánin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_31",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We arrived today.",
                "tlingit": [
                    [
                        [
                            "Yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagiyee",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲uwtuwatín.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_32",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We arrived yesterday.",
                "tlingit": [
                    [
                        [
                            "Tatgé",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲uwtuwatín.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_33",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I arrived today.",
                "tlingit": [
                    [
                        [
                            "Yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagiyee",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲ux̲waatín.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagiyee",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲ux̲waatín.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "13_34",
                        "N"
                    ],
                    [
                        "13_35",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I arrived yesterday.",
                "tlingit": [
                    [
                        [
                            "Tatgé",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲ux̲waatín.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_36",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he arrived yesterday.",
                "tlingit": [
                    [
                        [
                            "Tatgé",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲oowatín.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_37",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he arrived today.",
                "tlingit": [
                    [
                        [
                            "Yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagiyee",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲oowatín.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_38",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We'll go by boat/car.",
                "tlingit": [
                    [
                        [
                            "Gax̲took̲óox̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_39",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'll go by boat/car.",
                "tlingit": [
                    [
                        [
                            "Guk̲wak̲óox̲.",
                            [
                                {
                                    "tlingit": "wook̲oox̲",
                                    "url": "wook̲oox̲",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he went (by motorized vehicle)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Kuk̲ak̲óox̲.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "K̲wak̲óox̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "13_40",
                        "N"
                    ],
                    [
                        "13_41",
                        "J"
                    ],
                    [
                        "13_42",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he will go by boat.",
                "tlingit": [
                    [
                        [
                            "Gug̲wak̲óox̲.",
                            [
                                {
                                    "tlingit": "wook̲oox̲",
                                    "url": "wook̲oox̲",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he went (by motorized vehicle)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_43",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Will you go by boat?",
                "tlingit": [
                    [
                        [
                            "Gag̲eek̲óox̲",
                            [],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_44",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are you flying to Angoon?",
                "tlingit": [
                    [
                        [
                            "Aangóonde",
                            [
                                {
                                    "tlingit": "Aangóon",
                                    "url": "Aangóon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Angoon",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dé(i) ~ -de(i)",
                                    "url": "-dé(i) ~ -de(i)",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "to, toward; until; in the manner of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kg̲idak̲éen?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_45",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We came by car.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wutuwak̲úx̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "13_46",
                        "N"
                    ]
                ]
            }
        ]
    },
    "14": {
        "title": "Locations and directions",
        "content": [
            {
                "type": "ex",
                "english": "Where is the ___?",
                "tlingit": [
                    [
                        [
                            "Goosú",
                            [],
                            []
                        ],
                        [
                            "wé ___?",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where's the drum?",
                "tlingit": [
                    [
                        [
                            "Goosú",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gaaw?",
                            [
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "time",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bell",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "drum",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where's the book?",
                "tlingit": [
                    [
                        [
                            "Goosú",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'?",
                            [
                                {
                                    "tlingit": "x'úx'",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "The book is on the table.",
                "tlingit": [
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'",
                            [
                                {
                                    "tlingit": "x'úx'",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nadáakw",
                            [
                                {
                                    "tlingit": "nadáakw",
                                    "url": "nadáakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "table",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káwu.",
                            [
                                {
                                    "tlingit": "a káwu",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "locativepr"
                                    ],
                                    "english": "is on it; are on it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Nadáakw",
                            [
                                {
                                    "tlingit": "nadáakw",
                                    "url": "nadáakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "table",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káwu",
                            [
                                {
                                    "tlingit": "a káwu",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "locativepr"
                                    ],
                                    "english": "is on it; are on it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'.",
                            [
                                {
                                    "tlingit": "x'úx'",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "14_4",
                        "J"
                    ],
                    [
                        "14_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where's your husband?",
                "tlingit": [
                    [
                        [
                            "Goosú",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲úx̲?",
                            [
                                {
                                    "tlingit": "du x̲úx̲",
                                    "url": "du x̲úx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her husband",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My husband is a home.",
                "tlingit": [
                    [
                        [
                            "Neilú",
                            [],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲úx̲.",
                            [
                                {
                                    "tlingit": "du x̲úx̲",
                                    "url": "du x̲úx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her husband",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where's your mother?",
                "tlingit": [
                    [
                        [
                            "Goosú",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tláa?",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My mother is upstairs.",
                "tlingit": [
                    [
                        [
                            "Hít",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shantóowu",
                            [],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tláa.",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is your mother upstairs?",
                "tlingit": [
                    [
                        [
                            "Hít",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shantóowu",
                            [],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tláa?",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tláa",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hít",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shantóowu?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "14_10",
                        "J"
                    ],
                    [
                        "14_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Isn't your mother upstairs?",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ágé",
                            [
                                {
                                    "tlingit": "ágé",
                                    "url": "ágé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[focus + interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hít shantú",
                            [
                                {
                                    "tlingit": "hít shantú",
                                    "url": "hít shantú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "upstairs; attic",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tláa?",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tláa",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hít shantú?",
                            [
                                {
                                    "tlingit": "hít shantú",
                                    "url": "hít shantú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "upstairs; attic",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "14_12",
                        "J"
                    ],
                    [
                        "14_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "No. My mother is not upstairs.",
                "tlingit": [
                    [
                        [
                            "Tléik'.",
                            [
                                {
                                    "tlingit": "tléik'",
                                    "url": "tléik'",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hít shantú",
                            [
                                {
                                    "tlingit": "hít shantú",
                                    "url": "hít shantú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "upstairs; attic",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tláa.",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "The berries are in the box.",
                "tlingit": [
                    [
                        [
                            "K̲óok",
                            [
                                {
                                    "tlingit": "k̲óok",
                                    "url": "k̲óok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "box",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yígu",
                            [
                                {
                                    "tlingit": "yígu",
                                    "url": "a yík",
                                    "tags": [
                                        "RelationalNoun",
                                        "locativepr"
                                    ],
                                    "english": "is in it; are in it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tléik̲w.",
                            [
                                {
                                    "tlingit": "tléik̲w",
                                    "url": "tléik̲w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "berry, berries",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "at home / indoors",
                "tlingit": [
                    [
                        [
                            "neilú",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "outdoors / outside",
                "tlingit": [
                    [
                        [
                            "gáanu",
                            [
                                {
                                    "tlingit": "gáan",
                                    "url": "gáan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "outdoors; outside",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "next door",
                "tlingit": [
                    [
                        [
                            "k'idaakáwu",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "in the woods",
                "tlingit": [
                    [
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gutóowu",
                            [
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "in that room",
                "tlingit": [
                    [
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eetkáwu",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "upstairs",
                "tlingit": [
                    [
                        [
                            "hít",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shantóowu",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "under the house",
                "tlingit": [
                    [
                        [
                            "hít",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tayeewú",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "behind the house",
                "tlingit": [
                    [
                        [
                            "hít",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "t'éiwu",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "beside the house",
                "tlingit": [
                    [
                        [
                            "hít",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwánu",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_24",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "in the stove",
                "tlingit": [
                    [
                        [
                            "sdoox",
                            [
                                {
                                    "tlingit": "stoox",
                                    "url": "stoox",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "stove",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "stoox",
                                    "url": "stoox",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "stove",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "tóowu",
                            [
                                {
                                    "tlingit": "a tóowu",
                                    "url": "a tú",
                                    "tags": [
                                        "RelationalNoun",
                                        "locativepr"
                                    ],
                                    "english": "is in it; are in it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "under the Chilkat blanket",
                "tlingit": [
                    [
                        [
                            "naaxein",
                            [
                                {
                                    "tlingit": "naaxein",
                                    "url": "naaxein",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Chilkat blanket",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tayeewú",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_26",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "here",
                "tlingit": [
                    [
                        [
                            "yáadu",
                            [
                                {
                                    "tlingit": "yáadu",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "locativepr"
                                    ],
                                    "english": "here is; here are",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_27",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "over there (nearby)",
                "tlingit": [
                    [
                        [
                            "héidu",
                            [
                                {
                                    "tlingit": "héidu",
                                    "url": "hé",
                                    "tags": [
                                        "Demonstrative",
                                        "locativepr"
                                    ],
                                    "english": "over here is; over here are",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_28",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "over there",
                "tlingit": [
                    [
                        [
                            "wéidu",
                            [
                                {
                                    "tlingit": "wéidu",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative",
                                        "locativepr"
                                    ],
                                    "english": "there is; there are",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_29",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "over there (far)",
                "tlingit": [
                    [
                        [
                            "yóodu",
                            [
                                {
                                    "tlingit": "yóodu",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative",
                                        "locativepr"
                                    ],
                                    "english": "yonder is; yonder are",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_30",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "further on",
                "tlingit": [
                    [
                        [
                            "tliyaawú",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_31",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "down south",
                "tlingit": [
                    [
                        [
                            "ixkéewu",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_32",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "in the boat",
                "tlingit": [
                    [
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaakw",
                            [
                                {
                                    "tlingit": "yaakw",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boat, canoe",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yígu",
                            [
                                {
                                    "tlingit": "yígu",
                                    "url": "a yík",
                                    "tags": [
                                        "RelationalNoun",
                                        "locativepr"
                                    ],
                                    "english": "is in it; are in it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_33",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "in his boat",
                "tlingit": [
                    [
                        [
                            "du yaagú",
                            [
                                {
                                    "tlingit": "du yaagú",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its boat, canoe",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du s'ísaa yaagú",
                                    "url": "s'ísaa yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its sailboat",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yígu",
                            [
                                {
                                    "tlingit": "yígu",
                                    "url": "a yík",
                                    "tags": [
                                        "RelationalNoun",
                                        "locativepr"
                                    ],
                                    "english": "is in it; are in it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_34",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "up the mountain",
                "tlingit": [
                    [
                        [
                            "shaa",
                            [
                                {
                                    "tlingit": "shaa",
                                    "url": "shaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "mountain",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shakéewu",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_35",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "at my father's",
                "tlingit": [
                    [
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲ánu",
                            [
                                {
                                    "tlingit": "du x̲ánu",
                                    "url": "du x̲án",
                                    "tags": [
                                        "RelationalNoun",
                                        "locativepr"
                                    ],
                                    "english": "is next to him/her; are next to him/her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_36",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "in the road",
                "tlingit": [
                    [
                        [
                            "dei",
                            [
                                {
                                    "tlingit": "dei",
                                    "url": "de",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "already, (by) now",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dei",
                                    "url": "dei",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "path, trail; road, street",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yígu",
                            [
                                {
                                    "tlingit": "yígu",
                                    "url": "a yík",
                                    "tags": [
                                        "RelationalNoun",
                                        "locativepr"
                                    ],
                                    "english": "is in it; are in it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_37",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "in the water, on the river",
                "tlingit": [
                    [
                        [
                            "héen",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yígu",
                            [
                                {
                                    "tlingit": "yígu",
                                    "url": "a yík",
                                    "tags": [
                                        "RelationalNoun",
                                        "locativepr"
                                    ],
                                    "english": "is in it; are in it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_38",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "on the bottom (of water, river)",
                "tlingit": [
                    [
                        [
                            "héen",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "táawu",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "héen",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "táagu",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "14_39",
                        "N"
                    ],
                    [
                        "14_40",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "on the (surface of the) water",
                "tlingit": [
                    [
                        [
                            "héen",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káwu",
                            [
                                {
                                    "tlingit": "a káwu",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "locativepr"
                                    ],
                                    "english": "is on it; are on it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "héen",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲ukáwu",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "14_41",
                        "N"
                    ],
                    [
                        "14_42",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "among the berries",
                "tlingit": [
                    [
                        [
                            "tléik̲w",
                            [
                                {
                                    "tlingit": "tléik̲w",
                                    "url": "tléik̲w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "berry, berries",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲oowú",
                            [
                                {
                                    "tlingit": "a x̲oowú",
                                    "url": "a x̲oo",
                                    "tags": [
                                        "RelationalNoun",
                                        "locativepr"
                                    ],
                                    "english": "is among it; are among it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_43",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "The box is under the table.",
                "tlingit": [
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲óok",
                            [
                                {
                                    "tlingit": "k̲óok",
                                    "url": "k̲óok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "box",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nadáakw",
                            [
                                {
                                    "tlingit": "nadáakw",
                                    "url": "nadáakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "table",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tayeewú.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_44",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "to town",
                "tlingit": [
                    [
                        [
                            "aan",
                            [
                                {
                                    "tlingit": "aan",
                                    "url": "aan",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "with it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "aan",
                                    "url": "aan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "town; village; settlement; inhabited or owned land",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kaadé",
                            [
                                {
                                    "tlingit": "a kaadé",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "allative"
                                    ],
                                    "english": "on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A kaadé yanasxá!",
                                    "url": "a kaadé ayawsixaa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Pour it on there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kaadé kagux̲dagáan",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will shine on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kaadé kg̲washx̲éen",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will fall on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kaadé akagux̲lak̲áa",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will sew it on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du kaadé ashukg̲watée",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will blame it on him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kaadé sakg̲wax'áak̲w",
                                    "url": "a kát seiwax'ák̲w",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will forget",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_45",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "to the beach",
                "tlingit": [
                    [
                        [
                            "éek̲de",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "léinde",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "14_46",
                        "J"
                    ],
                    [
                        "14_47",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "to the woods",
                "tlingit": [
                    [
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gutóode",
                            [
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dé(i) ~ -de(i)",
                                    "url": "-dé(i) ~ -de(i)",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "to, toward; until; in the manner of",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aasgutú",
                                    "url": "aasgutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "forest; timbered area",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dé(i) ~ -de(i)",
                                    "url": "-dé(i) ~ -de(i)",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "to, toward; until; in the manner of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_48",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "to the forest",
                "tlingit": [
                    [
                        [
                            "aas",
                            [
                                {
                                    "tlingit": "aas",
                                    "url": "aas",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tree (esp. conifer)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gutóode",
                            [
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dé(i) ~ -de(i)",
                                    "url": "-dé(i) ~ -de(i)",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "to, toward; until; in the manner of",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aasgutú",
                                    "url": "aasgutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "forest; timbered area",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dé(i) ~ -de(i)",
                                    "url": "-dé(i) ~ -de(i)",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "to, toward; until; in the manner of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "as",
                            [
                                {
                                    "tlingit": "aas",
                                    "url": "aas",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tree (esp. conifer)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aas",
                                    "url": "aas",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tree (esp. conifer)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gutóode",
                            [
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dé(i) ~ -de(i)",
                                    "url": "-dé(i) ~ -de(i)",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "to, toward; until; in the manner of",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aasgutú",
                                    "url": "aasgutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "forest; timbered area",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-dé(i) ~ -de(i)",
                                    "url": "-dé(i) ~ -de(i)",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "to, toward; until; in the manner of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "14_49",
                        "N"
                    ],
                    [
                        "14_50",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "(my) right hand",
                "tlingit": [
                    [
                        [
                            "sheeynáx̲",
                            [],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jín",
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_51",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "(my) left hand",
                "tlingit": [
                    [
                        [
                            "s'át'náx̲",
                            [],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jín",
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_52",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "(go) right",
                "tlingit": [
                    [
                        [
                            "sheeynáx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_53",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "(go) left",
                "tlingit": [
                    [
                        [
                            "s'át'náx",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_54",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Steer to your left.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'át'náx̲",
                            [],
                            []
                        ],
                        [
                            "aadé",
                            [
                                {
                                    "tlingit": "aadé ksixát",
                                    "url": "aadé ksixát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's connected there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé nashíx",
                                    "url": "át wujixíx",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is running to it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé akoonáa",
                                    "url": "aadé akaawanáa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is sending him/her there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé wjixeex",
                                    "url": "aadé wjixeex",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it ran there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé aawatee",
                                    "url": "aadé aawatee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he carried it there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé woogoot",
                                    "url": "aadé woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it went there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé awsitee",
                                    "url": "aadé awsitee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he carried it there",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yanal.aat.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_55",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's near.",
                "tlingit": [
                    [
                        [
                            "Kunaaséi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_56",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's far.",
                "tlingit": [
                    [
                        [
                            "Naaléi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_57",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "a far place; a distant place",
                "tlingit": [
                    [
                        [
                            "naaliyé",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_58",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You can hear it from far away.",
                "tlingit": [
                    [
                        [
                            "Naaliyéidáx̲",
                            [],
                            []
                        ],
                        [
                            "duwa.áx̲ch.",
                            [
                                {
                                    "tlingit": "aya.áx̲ch",
                                    "url": "aya.áx̲ch",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he can hear it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_59",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You can hear it far away.",
                "tlingit": [
                    [
                        [
                            "Naaliyéide",
                            [],
                            []
                        ],
                        [
                            "duwa.áx̲ch.",
                            [
                                {
                                    "tlingit": "aya.áx̲ch",
                                    "url": "aya.áx̲ch",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he can hear it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_60",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "high",
                "tlingit": [
                    [
                        [
                            "dikée",
                            [
                                {
                                    "tlingit": "Dikée aank̲áawu",
                                    "url": "Dikáank̲áawu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "God",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Dikée aank̲áawu",
                                    "url": "Dikée aank̲áawu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "God",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_61",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's in a high place.",
                "tlingit": [
                    [
                        [
                            "Dikée",
                            [
                                {
                                    "tlingit": "Dikée aank̲áawu",
                                    "url": "Dikáank̲áawu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "God",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Dikée aank̲áawu",
                                    "url": "Dikée aank̲áawu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "God",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kaawaháa.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Dikéex'",
                            [],
                            []
                        ],
                        [
                            "yéi yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "14_62",
                        "J"
                    ],
                    [
                        "14_63",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "low",
                "tlingit": [
                    [
                        [
                            "diyée",
                            [
                                {
                                    "tlingit": "Diyée aank̲áawu",
                                    "url": "Diyée aank̲áawu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Satan",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "14_64",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's in a low place.",
                "tlingit": [
                    [
                        [
                            "Diyée",
                            [
                                {
                                    "tlingit": "Diyée aank̲áawu",
                                    "url": "Diyée aank̲áawu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Satan",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kaawaháa.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Diyéex'",
                            [],
                            []
                        ],
                        [
                            "yéi yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "14_65",
                        "N"
                    ],
                    [
                        "14_66",
                        "J"
                    ]
                ]
            }
        ]
    },
    "15": {
        "title": "Sizes and shapes, conditions and attributes (dimensional verbs)",
        "content": [
            {
                "type": "text",
                "english": "Unlike English and other European languages most familiar to learners of Tlingit, Tlingit has relatively few \"real\" adjectives (such as \"tlein\" = \"big\"). Most of the time to translate English adjectives it will be as a verb in Tlingit. For example:"
            },
            {
                "type": "ex",
                "english": "it is good",
                "tlingit": [
                    [
                        [
                            "yak'éi",
                            [
                                {
                                    "tlingit": "yak'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "s/he is sick",
                "tlingit": [
                    [
                        [
                            "yanéekw",
                            [
                                {
                                    "tlingit": "yanéekw",
                                    "url": "woonéekw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's sick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "it is hot",
                "tlingit": [
                    [
                        [
                            "yat'áa",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "s/he is strong",
                "tlingit": [
                    [
                        [
                            "litseen",
                            [
                                {
                                    "tlingit": "litseen",
                                    "url": "wulitseen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is strong",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "it is smelly; it stinks",
                "tlingit": [
                    [
                        [
                            "lichán",
                            [
                                {
                                    "tlingit": "lichán",
                                    "url": "wulichán",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it stinks",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "lots, many",
                "tlingit": [
                    [
                        [
                            "yagéi",
                            [
                                {
                                    "tlingit": "yagéi",
                                    "url": "woogéi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "there are many",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "many of them",
                "tlingit": [
                    [
                        [
                            "yagéiyi",
                            [],
                            []
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "many",
                "tlingit": [
                    [
                        [
                            "aatlein",
                            [
                                {
                                    "tlingit": "aatlein",
                                    "url": "aatlein",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "much, lots of, really",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "aatlénx'",
                            [
                                {
                                    "tlingit": "aatlein",
                                    "url": "aatlein",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "much, lots of, really",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "[plural]",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "15_8",
                        "J"
                    ],
                    [
                        "15_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She/he/it is big/tall/large.",
                "tlingit": [
                    [
                        [
                            "Ligéi.",
                            [
                                {
                                    "tlingit": "ligéi",
                                    "url": "ligéi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is big",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are tall.",
                "tlingit": [
                    [
                        [
                            "Has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dligéix'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "a big one",
                "tlingit": [
                    [
                        [
                            "ligéiyi",
                            [],
                            []
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "big ones",
                "tlingit": [
                    [
                        [
                            "dligéix'i",
                            [],
                            []
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "medium sized one",
                "tlingit": [
                    [
                        [
                            "yáng̲aa",
                            [],
                            []
                        ],
                        [
                            "kwdligeiyi",
                            [],
                            []
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She/he/it is not big/tall/adult.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ulgé.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are not tall/adult.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ulgéix'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are not very tall.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tlax̲",
                            [
                                {
                                    "tlingit": "tlax̲",
                                    "url": "tlax̲",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "very",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ulgéix'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It is small/little/tiny. (compact object)",
                "tlingit": [
                    [
                        [
                            "Yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "googéink'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are small.",
                "tlingit": [
                    [
                        [
                            "Yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kwdzigéink'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It is small/little. (extended object)",
                "tlingit": [
                    [
                        [
                            "Yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gushigéink'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She/he/it is short.",
                "tlingit": [
                    [
                        [
                            "Yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kwsigéink'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are short.",
                "tlingit": [
                    [
                        [
                            "Yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kwdzigei.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It is long. (compact object)",
                "tlingit": [
                    [
                        [
                            "Yayát'.",
                            [
                                {
                                    "tlingit": "yayát'",
                                    "url": "wooyáat'",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it is long",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are long.",
                "tlingit": [
                    [
                        [
                            "Has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "diyát'x'.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "diyát'g̲ix'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "15_24",
                        "J"
                    ],
                    [
                        "15_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It is not long.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "uyát'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_26",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are not long.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "udayát'g̲ix'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_27",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It is long. (extended object)",
                "tlingit": [
                    [
                        [
                            "Liyat'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_28",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are long.",
                "tlingit": [
                    [
                        [
                            "Has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dliyát'x'.",
                            [
                                {
                                    "tlingit": "dliyát'x'",
                                    "url": "dliyát'x'",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they're long",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_29",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It is not long.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ulyát'.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ulyát'x'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "15_30",
                        "J"
                    ],
                    [
                        "15_31",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It is heavy. (rock; inanimate object)",
                "tlingit": [
                    [
                        [
                            "Yadál.",
                            [
                                {
                                    "tlingit": "yadál",
                                    "url": "woodál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's heavy",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_32",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are heavy.",
                "tlingit": [
                    [
                        [
                            "Has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "didálx'.",
                            [
                                {
                                    "tlingit": "woodál",
                                    "url": "woodál",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "it got heavy; it was weighty (of abstracts)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_33",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It is light. (lit. \"not heavy\")",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "udál.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_34",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are not heavy.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "udadálx'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_35",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's heavy. (person; live creature)",
                "tlingit": [
                    [
                        [
                            "Lidál.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_36",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are heavy.",
                "tlingit": [
                    [
                        [
                            "Has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dlidálx'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_37",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's light / not heavy.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "uldál.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_38",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are not heavy.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "uldálx'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_39",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "S/he's fast.",
                "tlingit": [
                    [
                        [
                            "Yasátkw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_40",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We're fast.",
                "tlingit": [
                    [
                        [
                            "Haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yasátkw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_41",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You're fast.",
                "tlingit": [
                    [
                        [
                            "iyasátkw.",
                            [
                                {
                                    "tlingit": "woosátk",
                                    "url": "woosátk",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was fast",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_42",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm fast.",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yasátkw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_43",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not fast.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "usátkw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_44",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's slow.",
                "tlingit": [
                    [
                        [
                            "Lich'éeyák̲w",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_45",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's strong.",
                "tlingit": [
                    [
                        [
                            "Litseen.",
                            [
                                {
                                    "tlingit": "litseen",
                                    "url": "wulitseen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is strong",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_46",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's not strong.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ulcheen.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_47",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You [plural] are strong.",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "litseen.",
                            [
                                {
                                    "tlingit": "litseen",
                                    "url": "wulitseen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is strong",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_48",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "a strong one",
                "tlingit": [
                    [
                        [
                            "litseeni",
                            [],
                            []
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_49",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's difficult.",
                "tlingit": [
                    [
                        [
                            "Lidzée.",
                            [
                                {
                                    "tlingit": "lidzée",
                                    "url": "wulidzée",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's difficult",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_50",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's easy. (lit, \"not hard\")",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "uldzée.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_51",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's rich.",
                "tlingit": [
                    [
                        [
                            "Lináalx̲.",
                            [
                                {
                                    "tlingit": "lináalx̲",
                                    "url": "wulináalx̲",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's rich",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_52",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's not rich.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ulnáalx̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_53",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's poor.",
                "tlingit": [
                    [
                        [
                            "K̲'anashgidéix̲",
                            [],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_54",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It stinks (smells bad).",
                "tlingit": [
                    [
                        [
                            "Lichán.",
                            [
                                {
                                    "tlingit": "lichán",
                                    "url": "wulichán",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it stinks",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_55",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's beginning to stink.",
                "tlingit": [
                    [
                        [
                            "Kei nalchán.",
                            [
                                {
                                    "tlingit": "kei nalchán",
                                    "url": "wulichán",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is starting to stink",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_56",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It smells good (fragrant).",
                "tlingit": [
                    [
                        [
                            "Lits'áa",
                            [
                                {
                                    "tlingit": "lits'áa",
                                    "url": "wulits'áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is fragrant",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_57",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's taboo.",
                "tlingit": [
                    [
                        [
                            "Lig̲aas.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_58",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She/he/it is good.",
                "tlingit": [
                    [
                        [
                            "Yak'éi.",
                            [
                                {
                                    "tlingit": "yak'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_59",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "a good one",
                "tlingit": [
                    [
                        [
                            "yak'éiyi",
                            [],
                            []
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_60",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She/he/it is not good.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ushk'é.",
                            [
                                {
                                    "tlingit": "l ushk'é",
                                    "url": "l ushk'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "evil, sin",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél ushk'é",
                                    "url": "tlél wushk'é",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is bad",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_61",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You [singular] are bad. (lit. \"not good\")",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eeshk'é.",
                            [
                                {
                                    "tlingit": "tlél wushk'é",
                                    "url": "tlél wushk'é",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was bad; he/she/it was evil",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_62",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You [plural] are bad.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ushk'é.",
                            [
                                {
                                    "tlingit": "l ushk'é",
                                    "url": "l ushk'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "evil, sin",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél ushk'é",
                                    "url": "tlél wushk'é",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is bad",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_63",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "well/nice",
                "tlingit": [
                    [
                        [
                            "k'idéin",
                            [
                                {
                                    "tlingit": "k'idéin",
                                    "url": "k'idéin",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "well",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_64",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It can be seen well.",
                "tlingit": [
                    [
                        [
                            "K'idéin",
                            [
                                {
                                    "tlingit": "k'idéin",
                                    "url": "k'idéin",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "well",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwatéen.",
                            [
                                {
                                    "tlingit": "yéi aawasáakw",
                                    "url": "yéi aawasáakw",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he called him/her/it that (name)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_65",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It can be heard well.",
                "tlingit": [
                    [
                        [
                            "K'idéin",
                            [
                                {
                                    "tlingit": "k'idéin",
                                    "url": "k'idéin",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "well",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwa.áx̲ch.",
                            [
                                {
                                    "tlingit": "aya.áx̲ch",
                                    "url": "aya.áx̲ch",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he can hear it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_66",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's OK.",
                "tlingit": [
                    [
                        [
                            "K'idéin",
                            [
                                {
                                    "tlingit": "k'idéin",
                                    "url": "k'idéin",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "well",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_67",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's crazy.",
                "tlingit": [
                    [
                        [
                            "Sh kahaadí.",
                            [
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa sh kanalník",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is (in the process of) telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh udagwaalch",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "it rings (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh k̲'awdliyél",
                                    "url": "sh k̲'awdliyél",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lied",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kadax'áshti hít",
                                    "url": "sh kadax'áshti hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sawmill",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh káa x̲'awdigáx'",
                                    "url": "sh káa x̲'awdigáx'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he prayed",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_68",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You're crazy.",
                "tlingit": [
                    [
                        [
                            "Sh kahaadí",
                            [
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh k̲'awdliyél",
                                    "url": "sh k̲'awdliyél",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he lied",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kadax'áshti hít",
                                    "url": "sh kadax'áshti hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sawmill",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh káa x̲'awdigáx'",
                                    "url": "sh káa x̲'awdigáx'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he prayed",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "wa.é.",
                            [
                                {
                                    "tlingit": "wa.é",
                                    "url": "wa.é",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (singular) [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Sh",
                            [
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh dagwáal",
                                    "url": "sh wudigwál",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's ringing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneegí",
                                    "url": "sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "preacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalyéiyi",
                                    "url": "sh kalyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "prostitute",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tukdlig̲éi",
                                    "url": "sh tukdlig̲éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is proud",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tóo altóow",
                                    "url": "sh tóo awdlitóow",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is studying it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kahaadée",
                            [],
                            []
                        ],
                        [
                            "wa.é.",
                            [
                                {
                                    "tlingit": "wa.é",
                                    "url": "wa.é",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (singular) [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "15_69",
                        "N"
                    ],
                    [
                        "15_70",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "I'm old.",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wudishán.",
                            [
                                {
                                    "tlingit": "wudishán",
                                    "url": "wudishán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is old",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_71",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My father is very old.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k̲únáx̲",
                            [
                                {
                                    "tlingit": "k̲únáx̲",
                                    "url": "k̲únáx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "very",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wudishán.",
                            [
                                {
                                    "tlingit": "wudishán",
                                    "url": "wudishán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is old",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_72",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm not old.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wudashaan.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_73",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My father is not very old.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲únáx̲",
                            [
                                {
                                    "tlingit": "k̲únáx̲",
                                    "url": "k̲únáx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "very",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wudashaan",
                            [],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh.",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_74",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My mother's not old.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wudashaan",
                            [],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tláa.",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_75",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "His brother-in-law is an old man.",
                "tlingit": [
                    [
                        [
                            "Du káani",
                            [
                                {
                                    "tlingit": "du káani",
                                    "url": "du káani",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her brother-in-law, sister-in-law",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "naa káani",
                                    "url": "naa káani",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "master of ceremonies, elder of the opposite clan consulted conducting a ceremony",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaanx̲",
                            [
                                {
                                    "tlingit": "shaan",
                                    "url": "shaan",
                                    "tags": [
                                        "Verb Root"
                                    ],
                                    "english": "age, old",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x̲",
                                    "url": "-x̲",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(in prolonged contact) at; (repeatedly arriving) at; being, in the form of",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x̲",
                                    "url": "-x̲",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(in prolonged contact) at; (repeatedly arriving) at; being, in the form of",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "shaan",
                                    "url": "shaan",
                                    "tags": [
                                        "Verb Root"
                                    ],
                                    "english": "age, old",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_76",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "His brother-in-law is old.",
                "tlingit": [
                    [
                        [
                            "Du káani",
                            [
                                {
                                    "tlingit": "du káani",
                                    "url": "du káani",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her brother-in-law, sister-in-law",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "naa káani",
                                    "url": "naa káani",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "master of ceremonies, elder of the opposite clan consulted conducting a ceremony",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "wdishán.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_77",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Phew! It's tough to get old.",
                "tlingit": [
                    [
                        [
                            "Xwéi!",
                            [],
                            []
                        ],
                        [
                            "Yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "indashán",
                            [
                                {
                                    "tlingit": "wudishán",
                                    "url": "wudishán",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he is old",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "óosh.",
                            [
                                {
                                    "tlingit": "óosh",
                                    "url": "óosh",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "as if; if only; even if",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(If you were only getting old; If you could only feel how it is to get old.)"
                ],
                "ids": [
                    [
                        "15_78",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "He's going to be a big man.",
                "tlingit": [
                    [
                        [
                            "K̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tleinx̲",
                            [],
                            []
                        ],
                        [
                            "gux̲satée.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ gux̲satée",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will be a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_79",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She's going to be a big woman.",
                "tlingit": [
                    [
                        [
                            "Shaawát",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tleinx̲",
                            [],
                            []
                        ],
                        [
                            "gux̲satée.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ gux̲satée",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will be a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_80",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "His/her mother is a good woman.",
                "tlingit": [
                    [
                        [
                            "Du tláa",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "aak'é",
                            [
                                {
                                    "tlingit": "wook'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was good; he/she/it got better; he/she/it was fine; he/she/it was pretty",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "shaawátx̲",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x̲",
                                    "url": "-x̲",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(in prolonged contact) at; (repeatedly arriving) at; being, in the form of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_81",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "He's a young man.",
                "tlingit": [
                    [
                        [
                            "Yées",
                            [
                                {
                                    "tlingit": "yées",
                                    "url": "yées",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "new; young; fresh",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_82",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She's a young woman.",
                "tlingit": [
                    [
                        [
                            "Yées",
                            [
                                {
                                    "tlingit": "yées",
                                    "url": "yées",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "new; young; fresh",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawát",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_83",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "That man is Tlingit.",
                "tlingit": [
                    [
                        [
                            "Lingitx̲",
                            [],
                            []
                        ],
                        [
                            "sitee",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa.",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Lingítx̲",
                            [],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "15_84",
                        "N"
                    ],
                    [
                        "15_85",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That woman is Tlingit.",
                "tlingit": [
                    [
                        [
                            "Lingitx̲",
                            [],
                            []
                        ],
                        [
                            "sitee",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawát.",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawát",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Lingítx̲",
                            [],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawát.",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "15_86",
                        "N"
                    ],
                    [
                        "15_87",
                        "J"
                    ],
                    [
                        "15_88",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That man is a white man.",
                "tlingit": [
                    [
                        [
                            "Dleit k̲áa",
                            [
                                {
                                    "tlingit": "dleit k̲áa",
                                    "url": "dleit k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "White, European, Caucasian (man or person)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa.",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Dleit k̲áa",
                            [
                                {
                                    "tlingit": "dleit k̲áa",
                                    "url": "dleit k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "White, European, Caucasian (man or person)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "15_89",
                        "J"
                    ],
                    [
                        "15_90",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That woman is a white woman.",
                "tlingit": [
                    [
                        [
                            "Dleit",
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawát",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawát.",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawát",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Dleit",
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawát",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "15_91",
                        "J"
                    ],
                    [
                        "15_92",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We are becoming real Tlingits.",
                "tlingit": [
                    [
                        [
                            "K̲unáx̲",
                            [],
                            []
                        ],
                        [
                            "Lingítx̲",
                            [],
                            []
                        ],
                        [
                            "yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nastéen.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_93",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That boy is a good worker.",
                "tlingit": [
                    [
                        [
                            "Aak'é",
                            [
                                {
                                    "tlingit": "wook'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was good; he/she/it got better; he/she/it was fine; he/she/it was pretty",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jinéiyix̲",
                            [],
                            []
                        ],
                        [
                            "sitee",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k'átsk'u.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Aak'é",
                            [
                                {
                                    "tlingit": "wook'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was good; he/she/it got better; he/she/it was fine; he/she/it was pretty",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yéi jinéiyi",
                            [
                                {
                                    "tlingit": "yéi jinéiyi",
                                    "url": "yéi jinéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "worker",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k'átsk'u.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k'átsk'u",
                            [],
                            []
                        ],
                        [
                            "aak'é",
                            [
                                {
                                    "tlingit": "wook'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was good; he/she/it got better; he/she/it was fine; he/she/it was pretty",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jinéiyix̲",
                            [],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k'átsk'u",
                            [],
                            []
                        ],
                        [
                            "aak'é",
                            [
                                {
                                    "tlingit": "wook'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was good; he/she/it got better; he/she/it was fine; he/she/it was pretty",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yéi jinéiyi",
                            [
                                {
                                    "tlingit": "yéi jinéiyi",
                                    "url": "yéi jinéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "worker",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "15_94",
                        "N"
                    ],
                    [
                        "15_95",
                        "J"
                    ],
                    [
                        "15_96",
                        "N"
                    ],
                    [
                        "15_97",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That girl is a good worker.",
                "tlingit": [
                    [
                        [
                            "Aak'é",
                            [
                                {
                                    "tlingit": "wook'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was good; he/she/it got better; he/she/it was fine; he/she/it was pretty",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jinéiyix̲",
                            [],
                            []
                        ],
                        [
                            "sitee",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaat",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k'átsk'u.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Aak'é",
                            [
                                {
                                    "tlingit": "wook'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was good; he/she/it got better; he/she/it was fine; he/she/it was pretty",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yéi jinéiyi",
                            [
                                {
                                    "tlingit": "yéi jinéiyi",
                                    "url": "yéi jinéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "worker",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaat",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k'átsk'u.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaat",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k'átsk'u",
                            [],
                            []
                        ],
                        [
                            "aak'é",
                            [
                                {
                                    "tlingit": "wook'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was good; he/she/it got better; he/she/it was fine; he/she/it was pretty",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jinéiyix̲",
                            [],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaat",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k'átsk'u",
                            [],
                            []
                        ],
                        [
                            "aak'é",
                            [
                                {
                                    "tlingit": "wook'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it was good; he/she/it got better; he/she/it was fine; he/she/it was pretty",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yéi jinéiyi",
                            [
                                {
                                    "tlingit": "yéi jinéiyi",
                                    "url": "yéi jinéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "worker",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "15_98",
                        "N"
                    ],
                    [
                        "15_99",
                        "J"
                    ],
                    [
                        "15_100",
                        "N"
                    ],
                    [
                        "15_101",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's a teacher.",
                "tlingit": [
                    [
                        [
                            "K̲óo at",
                            [
                                {
                                    "tlingit": "k̲óo at latóowu",
                                    "url": "k̲óo at latóowu",
                                    "tags": [
                                        "Noun",
                                        "T"
                                    ],
                                    "english": "teacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲óo at latéewu",
                                    "url": "k̲óo at latóowu",
                                    "tags": [
                                        "Noun",
                                        "At"
                                    ],
                                    "english": "teacher",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gatú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun",
                                        "T"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at óowu",
                                    "url": "k̲aa at óowu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession(s); that which is owned (by them)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at la.át",
                                    "url": "at la.át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "baggage, luggage; things, stuff packed up for carrying",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí k̲óok",
                                    "url": "at shí k̲óok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "radio, phonograph, stereo, music box, ipod; any device that plays music",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at oohéini",
                                    "url": "k̲aa at oohéini",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession; that which is claimed",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei at yeich",
                                    "url": "at wooyaa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it carries things on his/her/its back (regularly)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "latóowux̲",
                            [],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_102",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm a teacher.",
                "tlingit": [
                    [
                        [
                            "K̲óo at",
                            [
                                {
                                    "tlingit": "k̲óo at latóowu",
                                    "url": "k̲óo at latóowu",
                                    "tags": [
                                        "Noun",
                                        "T"
                                    ],
                                    "english": "teacher",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲óo at latéewu",
                                    "url": "k̲óo at latóowu",
                                    "tags": [
                                        "Noun",
                                        "At"
                                    ],
                                    "english": "teacher",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gatú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun",
                                        "T"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at óowu",
                                    "url": "k̲aa at óowu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession(s); that which is owned (by them)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at la.át",
                                    "url": "at la.át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "baggage, luggage; things, stuff packed up for carrying",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí k̲óok",
                                    "url": "at shí k̲óok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "radio, phonograph, stereo, music box, ipod; any device that plays music",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at oohéini",
                                    "url": "k̲aa at oohéini",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession; that which is claimed",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei at yeich",
                                    "url": "at wooyaa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it carries things on his/her/its back (regularly)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "latóowux̲",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_103",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's a pastor.",
                "tlingit": [
                    [
                        [
                            "Nakwnéitx̲",
                            [],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_104",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he became a pastor.",
                "tlingit": [
                    [
                        [
                            "Nakwnéitx̲",
                            [],
                            []
                        ],
                        [
                            "wusitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ wusitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he became a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_105",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm a nurse.",
                "tlingit": [
                    [
                        [
                            "K̲unáagu",
                            [
                                {
                                    "tlingit": "k̲unáagu",
                                    "url": "k̲unáagu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "healer; doctor; nurse",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawátx̲",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-x̲",
                                    "url": "-x̲",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(in prolonged contact) at; (repeatedly arriving) at; being, in the form of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_106",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My son is a preacher.",
                "tlingit": [
                    [
                        [
                            "K̲oon sh",
                            [
                                {
                                    "tlingit": "k̲oon sh kalneegí",
                                    "url": "k̲oon sh kalneegí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "storyteller; preacher",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "k̲oon atláakw",
                                    "url": "k̲oon aawatlákw",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling people a legend",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh k̲'oolyélch",
                                    "url": "sh k̲'awdliyél",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he lies (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yax̲ sh isnei",
                                    "url": "yan sh wudzinéi",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he gets dressed up (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a káx̲ sh ilgáas'",
                                    "url": "a kát sh wudligás'",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it leaps on it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yoo sh x̲'adis'eik̲k",
                                    "url": "sh x̲'awdis'eik̲",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he smokes (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kahaadí",
                                    "url": "sh kahaadí",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "crazy; insane; disturbed; mentally unbalanced",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh kalneek",
                                    "url": "sh kawdlineek",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is telling a story",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "kalneegéex̲",
                            [],
                            []
                        ],
                        [
                            "sitee",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéet.",
                            [
                                {
                                    "tlingit": "du yéet",
                                    "url": "du yéet",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her son, cousin",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_107",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "merchant/trader",
                "tlingit": [
                    [
                        [
                            "dahóoni",
                            [
                                {
                                    "tlingit": "dahooní",
                                    "url": "dahooní",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "salesman; clerk; storekeeper",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_108",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "doctor",
                "tlingit": [
                    [
                        [
                            "k̲unáagu",
                            [
                                {
                                    "tlingit": "k̲unáagu",
                                    "url": "k̲unáagu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "healer; doctor; nurse",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_109",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "a believer",
                "tlingit": [
                    [
                        [
                            "átk' aheení",
                            [
                                {
                                    "tlingit": "átk' aheení",
                                    "url": "átk' aheení",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "believer",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "átk' aheen",
                                    "url": "átk' aheen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "faith",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "átx' aheení",
                                    "url": "átk' aheení",
                                    "tags": [
                                        "Noun",
                                        "T"
                                    ],
                                    "english": "believer",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du átk' aheení",
                                    "url": "átk' aheen",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its faith",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_110",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We are Sitka people.",
                "tlingit": [
                    [
                        [
                            "Sheet'ká",
                            [
                                {
                                    "tlingit": "Sheet'ká",
                                    "url": "Sheet'ká",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Sitka",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "K̲wáanx̲",
                            [],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "15_111",
                        "J"
                    ]
                ]
            }
        ]
    },
    "16": {
        "title": "Colors",
        "content": [
            {
                "type": "text",
                "english": "The human eye is the same around the world, but our cultures and languages teach us to interpret the color spectrum differently. Indigenous color systems in Alaska Native languages are not the same as English. In Tlingit, most color terms are comparisons to a noun of certain color using the word yáx̲ and the verb \"to be,\" so that \"It is red\" is literally \"It is like fire.\" Other colors are like snow, like coal, like a Steller's jay, etc. For preschool \"readiness skills\" Tlingit translations of some colors have been created locally, so may not be the same in all communities. There seems to be much personal and regional variation on the rarer color terms. Some of the most common Tlingit words for colors are listed below."
            },
            {
                "type": "!ex",
                "english": "English",
                "tlingit": [
                    "Original Tlingit noun"
                ]
            },
            {
                "type": "ex",
                "english": "black",
                "tlingit": [
                    [
                        [
                            "t'ooch'",
                            [
                                {
                                    "tlingit": "t'ooch'",
                                    "url": "t'ooch'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "charcoal",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "t'ooch'",
                                    "url": "t'ooch'2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "black",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(charcoal)"
                ],
                "ids": [
                    [
                        "16_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "blue (deep blue)",
                "tlingit": [
                    [
                        [
                            "x̲'éishx'w",
                            [
                                {
                                    "tlingit": "x̲'éishx'w",
                                    "url": "x̲'éishx'w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dark blue",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲'éishx'w",
                                    "url": "x̲'éishx'w2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bluejay, Stellar's jay",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(blue jay, Steller's jay)"
                ],
                "ids": [
                    [
                        "16_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "brown",
                "tlingit": [
                    [
                        [
                            "s'agwáat",
                            [
                                {
                                    "tlingit": "s'agwáat",
                                    "url": "s'agwáat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "flaky surface of the outer bark of conifers, especially hemlock",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "s'agwáat",
                                    "url": "s'agwáat2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "brown",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(flat outer layer of hemlock bark)"
                ],
                "ids": [
                    [
                        "16_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "gold",
                "tlingit": [
                    [
                        [
                            "góon",
                            [
                                {
                                    "tlingit": "góon",
                                    "url": "góon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "gold",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(gold)"
                ],
                "ids": [
                    [
                        "16_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "green / light blue",
                "tlingit": [
                    [
                        [
                            "s'oow",
                            [
                                {
                                    "tlingit": "s'oow",
                                    "url": "s'oow",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "green, light blue",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "s'oow",
                                    "url": "s'oow2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "greenstone",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(greenstone, jade stone)"
                ],
                "ids": [
                    [
                        "16_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "gray",
                "tlingit": [
                    [
                        [
                            "lawúx̲",
                            [
                                {
                                    "tlingit": "lawúx̲",
                                    "url": "lawúx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "young seagull",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "lawúx̲",
                                    "url": "lawúx̲2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "gray",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "k̲ugáas'",
                            [
                                {
                                    "tlingit": "k̲ugáas'",
                                    "url": "k̲ugáas'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "gray; fog",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(young seagull)",
                    "(fog)"
                ],
                "ids": [
                    [
                        "16_6",
                        "J"
                    ],
                    [
                        "16_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "grayish/whitish",
                "tlingit": [
                    [
                        [
                            "dliwóo",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "orange",
                "tlingit": [
                    [
                        [
                            "shéix̲'w",
                            [
                                {
                                    "tlingit": "shéix̲'w",
                                    "url": "shéix̲'w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "orange (in color)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "shéix̲'w",
                                    "url": "shéix̲'w2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "red alder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "áanjís",
                            [
                                {
                                    "tlingit": "áanjís",
                                    "url": "áanjís",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "orange",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daayí",
                            [
                                {
                                    "tlingit": "a daayí",
                                    "url": "a daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its bark",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí k̲ákw",
                                    "url": "at daayí k̲ákw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch bark basket",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "et daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(red alder)",
                    "(orange skin)"
                ],
                "ids": [
                    [
                        "16_9",
                        "N"
                    ],
                    [
                        "16_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "red",
                "tlingit": [
                    [
                        [
                            "x̲'áan",
                            [
                                {
                                    "tlingit": "at x̲'áan",
                                    "url": "at uwax̲'án",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is drying fish",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(fire)"
                ],
                "ids": [
                    [
                        "16_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "silver",
                "tlingit": [
                    [
                        [
                            "dáanaa",
                            [
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "silver",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "money, coin, dollar",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(silver dollar)"
                ],
                "ids": [
                    [
                        "16_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "white",
                "tlingit": [
                    [
                        [
                            "dleit",
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(snow)"
                ],
                "ids": [
                    [
                        "16_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "yellow",
                "tlingit": [
                    [
                        [
                            "tl'áatl'",
                            [
                                {
                                    "tlingit": "tl'áatl'",
                                    "url": "tl'áatl'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yellow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(small, yellow bird; yellow salmon berries; or the color of yellow fungus)"
                ],
                "ids": [
                    [
                        "16_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "It's black.",
                "tlingit": [
                    [
                        [
                            "T'ooch'",
                            [
                                {
                                    "tlingit": "t'ooch'",
                                    "url": "t'ooch'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "charcoal",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "t'ooch'",
                                    "url": "t'ooch'2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "black",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲ yatee.",
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ awsinei",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he straightened it out",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "A black shirt.",
                "tlingit": [
                    [
                        [
                            "T'ooch'",
                            [
                                {
                                    "tlingit": "t'ooch'",
                                    "url": "t'ooch'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "charcoal",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "t'ooch'",
                                    "url": "t'ooch'2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "black",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k'oodás'.",
                            [
                                {
                                    "tlingit": "k'oodás'",
                                    "url": "k'oodás'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shirt",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's blue.",
                "tlingit": [
                    [
                        [
                            "X̲'éishx'u",
                            [],
                            []
                        ],
                        [
                            "yáx̲ yatee.",
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ awsinei",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he straightened it out",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's brown.",
                "tlingit": [
                    [
                        [
                            "S'agwáat",
                            [
                                {
                                    "tlingit": "s'agwáat",
                                    "url": "s'agwáat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "flaky surface of the outer bark of conifers, especially hemlock",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "s'agwáat",
                                    "url": "s'agwáat2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "brown",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲ yatee.",
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ awsinei",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he straightened it out",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's gold.",
                "tlingit": [
                    [
                        [
                            "Góon",
                            [
                                {
                                    "tlingit": "góon",
                                    "url": "góon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "gold",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲ yatee.",
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ awsinei",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he straightened it out",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's green / light blue.",
                "tlingit": [
                    [
                        [
                            "S'oow",
                            [
                                {
                                    "tlingit": "s'oow",
                                    "url": "s'oow",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "green, light blue",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "s'oow",
                                    "url": "s'oow2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "greenstone",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲ yatee.",
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ awsinei",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he straightened it out",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's gray.",
                "tlingit": [
                    [
                        [
                            "Lawúx̲",
                            [
                                {
                                    "tlingit": "lawúx̲",
                                    "url": "lawúx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "young seagull",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "lawúx̲",
                                    "url": "lawúx̲2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "gray",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲ yatee.",
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ awsinei",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he straightened it out",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "K̲ugáas'",
                            [
                                {
                                    "tlingit": "k̲ugáas'",
                                    "url": "k̲ugáas'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "gray; fog",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲ yatee.",
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ awsinei",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he straightened it out",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "16_21",
                        "N"
                    ],
                    [
                        "16_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's grayish/whitish.",
                "tlingit": [
                    [
                        [
                            "Dliwóo.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's orange.",
                "tlingit": [
                    [
                        [
                            "Shéix̲'w",
                            [
                                {
                                    "tlingit": "shéix̲'w",
                                    "url": "shéix̲'w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "orange (in color)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "shéix̲'w",
                                    "url": "shéix̲'w2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "red alder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲ yatee.",
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ awsinei",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he straightened it out",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "Áanjís",
                            [
                                {
                                    "tlingit": "áanjís",
                                    "url": "áanjís",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "orange",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daayí",
                            [
                                {
                                    "tlingit": "a daayí",
                                    "url": "a daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its bark",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí k̲ákw",
                                    "url": "at daayí k̲ákw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch bark basket",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "et daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲ yatee.",
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ awsinei",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he straightened it out",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "16_24",
                        "J"
                    ],
                    [
                        "16_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's red.",
                "tlingit": [
                    [
                        [
                            "X̲'áan",
                            [
                                {
                                    "tlingit": "at x̲'áan",
                                    "url": "at uwax̲'án",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is drying fish",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲ yatee.",
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ awsinei",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he straightened it out",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_26",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's silver.",
                "tlingit": [
                    [
                        [
                            "Dáanaa",
                            [
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "silver",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dáanaa",
                                    "url": "dáanaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "money, coin, dollar",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲ yatee.",
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ awsinei",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he straightened it out",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_27",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's white.",
                "tlingit": [
                    [
                        [
                            "Dleit",
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲ yatee.",
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ awsinei",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he straightened it out",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_28",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's yellow.",
                "tlingit": [
                    [
                        [
                            "Tl'áatl'",
                            [
                                {
                                    "tlingit": "tl'áatl'",
                                    "url": "tl'áatl'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yellow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲ yatee.",
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wootee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ awsinei",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he straightened it out",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_29",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Two colors are also used to describe racial groups:"
            },
            {
                "type": "ex",
                "english": "white man",
                "tlingit": [
                    [
                        [
                            "dleit k̲áa",
                            [
                                {
                                    "tlingit": "dleit k̲áa",
                                    "url": "dleit k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "White, European, Caucasian (man or person)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_30",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "English language",
                "tlingit": [
                    [
                        [
                            "dleit k̲áa",
                            [
                                {
                                    "tlingit": "dleit k̲áa",
                                    "url": "dleit k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "White, European, Caucasian (man or person)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "x̲'éináx̲",
                            [
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "perlative"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'éináx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_31",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "white woman",
                "tlingit": [
                    [
                        [
                            "dleit",
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawát",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_32",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "black man",
                "tlingit": [
                    [
                        [
                            "t'ooch' k̲áa",
                            [
                                {
                                    "tlingit": "t'ooch' k̲áa",
                                    "url": "t'ooch' k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Black (man or person); African-American",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "t'ooch'",
                                    "url": "t'ooch'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "charcoal",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "t'ooch'",
                                    "url": "t'ooch'2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "black",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_33",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "black woman",
                "tlingit": [
                    [
                        [
                            "t'ooch'",
                            [
                                {
                                    "tlingit": "t'ooch'",
                                    "url": "t'ooch'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "charcoal",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "t'ooch'",
                                    "url": "t'ooch'2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "black",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shaawát",
                            [
                                {
                                    "tlingit": "shaawát",
                                    "url": "shaawát",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woman",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "16_34",
                        "J"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Sometimes the color words are heard with the ka- prefix, as in \"kadleit.\" This probably derives from an older concept of color as applied to the surface of something, as opposed to color in the abstract."
            }
        ]
    },
    "17": {
        "title": "Numbers and counting",
        "content": [
            {
                "type": "ex",
                "english": "one",
                "tlingit": [
                    [
                        [
                            "tléix'",
                            [
                                {
                                    "tlingit": "tléix'",
                                    "url": "tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "two",
                "tlingit": [
                    [
                        [
                            "déix̲",
                            [
                                {
                                    "tlingit": "déix̲",
                                    "url": "déix̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "three",
                "tlingit": [
                    [
                        [
                            "nás'k",
                            [
                                {
                                    "tlingit": "nás'k",
                                    "url": "nás'k",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "three",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "four",
                "tlingit": [
                    [
                        [
                            "daax'oon",
                            [
                                {
                                    "tlingit": "daax'oon",
                                    "url": "daax'oon",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "four",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "five",
                "tlingit": [
                    [
                        [
                            "keijín",
                            [
                                {
                                    "tlingit": "keijín",
                                    "url": "keijín",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "five",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "six",
                "tlingit": [
                    [
                        [
                            "tleidooshú",
                            [
                                {
                                    "tlingit": "tleidooshú",
                                    "url": "tleidooshú",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "six",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "seven",
                "tlingit": [
                    [
                        [
                            "dax̲adooshú",
                            [
                                {
                                    "tlingit": "dax̲adooshú",
                                    "url": "dax̲adooshú",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "seven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "eight",
                "tlingit": [
                    [
                        [
                            "nas'gadooshú",
                            [
                                {
                                    "tlingit": "nas'gadooshú",
                                    "url": "nas'gadooshú",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "eight",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "nine",
                "tlingit": [
                    [
                        [
                            "gooshúk̲",
                            [
                                {
                                    "tlingit": "gooshúk̲",
                                    "url": "gooshúk̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "nine",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "ten",
                "tlingit": [
                    [
                        [
                            "jinkaat",
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "eleven",
                "tlingit": [
                    [
                        [
                            "jinkaat k̲a tléix'",
                            [
                                {
                                    "tlingit": "jinkaat k̲a tléix'",
                                    "url": "jinkaat k̲a tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "eleven",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "jinkaat k̲a tléináx̲",
                                    "url": "jinkaat k̲a tléináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "eleven (people)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "nás'k jinkaat k̲a tléix'",
                                    "url": "nás'k jinkaat k̲a tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "thirty one",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleik̲áa k̲a tléix'",
                                    "url": "tleik̲áa k̲a tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty one",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "tléix'",
                                    "url": "tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "twelve",
                "tlingit": [
                    [
                        [
                            "jinkaat k̲a",
                            [
                                {
                                    "tlingit": "jinkaat k̲a tléix'",
                                    "url": "jinkaat k̲a tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "eleven",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "jinkaat k̲a tléináx̲",
                                    "url": "jinkaat k̲a tléináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "eleven (people)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "nás'k jinkaat k̲a tléix'",
                                    "url": "nás'k jinkaat k̲a tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "thirty one",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "déix̲",
                            [
                                {
                                    "tlingit": "déix̲",
                                    "url": "déix̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "etc."
                ]
            },
            {
                "type": "ex",
                "english": "twenty",
                "tlingit": [
                    [
                        [
                            "tleik̲áa",
                            [
                                {
                                    "tlingit": "tleik̲áa",
                                    "url": "tleik̲áa",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "twenty one",
                "tlingit": [
                    [
                        [
                            "tleik̲áa k̲a tléix'",
                            [
                                {
                                    "tlingit": "tleik̲áa k̲a tléix'",
                                    "url": "tleik̲áa k̲a tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty one",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "tleik̲áa k̲a tléináx̲",
                                    "url": "tleik̲áa k̲a tléináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty one (people)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "jinkaat k̲a tléix'",
                                    "url": "jinkaat k̲a tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "eleven",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "nás'k jinkaat k̲a tléix'",
                                    "url": "nás'k jinkaat k̲a tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "thirty one",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleik̲áa",
                                    "url": "tleik̲áa",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "tléix'",
                                    "url": "tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "etc."
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "once",
                "tlingit": [
                    [
                        [
                            "tleidahéen",
                            [
                                {
                                    "tlingit": "tleidahéen",
                                    "url": "tleidahéen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "once, one time",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "tleidahéen",
                                    "url": "tlex'dahéen",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "once, one time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "twice",
                "tlingit": [
                    [
                        [
                            "dax̲dahéen",
                            [
                                {
                                    "tlingit": "dax̲dahéen",
                                    "url": "dax̲dahéen",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twice, two times",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "déix̲ dahéen",
                            [
                                {
                                    "tlingit": "déix̲ dahéen",
                                    "url": "dax̲dahéen",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twice, two times",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "nás'k dahéen",
                                    "url": "nas'gidahéen",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "three times",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "déix̲",
                                    "url": "déix̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "17_16",
                        "J"
                    ],
                    [
                        "17_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "thrice, three times",
                "tlingit": [
                    [
                        [
                            "nas'gidahéen",
                            [
                                {
                                    "tlingit": "nas'gidahéen",
                                    "url": "nas'gidahéen",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "three times",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "nás'k dahéen",
                            [
                                {
                                    "tlingit": "nás'k dahéen",
                                    "url": "nas'gidahéen",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "three times",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "déix̲ dahéen",
                                    "url": "dax̲dahéen",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twice, two times",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "nás'k",
                                    "url": "nás'k",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "three",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "17_18",
                        "J"
                    ],
                    [
                        "17_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "four times",
                "tlingit": [
                    [
                        [
                            "daax'oondahéen",
                            [
                                {
                                    "tlingit": "daax'oondahéen",
                                    "url": "daax'oondahéen",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "four times",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "daax'oon",
                            [
                                {
                                    "tlingit": "daax'oon",
                                    "url": "daax'oon",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "four",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dahéen",
                            [
                                {
                                    "tlingit": "déix̲ dahéen",
                                    "url": "dax̲dahéen",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twice, two times",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "nás'k dahéen",
                                    "url": "nas'gidahéen",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "three times",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "17_20",
                        "J"
                    ],
                    [
                        "17_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "five times",
                "tlingit": [
                    [
                        [
                            "keijindahéen",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "etc."
                ]
            },
            {
                "type": "ex",
                "english": "one by one",
                "tlingit": [
                    [
                        [
                            "tlék'g̲aa",
                            [
                                {
                                    "tlingit": "tlék'g̲aa",
                                    "url": "tlék'g̲aa",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one at a time, one by one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "tlák'g̲aa",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "17_24",
                        "N"
                    ],
                    [
                        "17_25",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "two by two",
                "tlingit": [
                    [
                        [
                            "dáx̲g̲aa",
                            [
                                {
                                    "tlingit": "dáx̲g̲aa",
                                    "url": "dáx̲g̲aa",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two at a time, two by two",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_26",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "three by three",
                "tlingit": [
                    [
                        [
                            "nás'gig̲aa",
                            [
                                {
                                    "tlingit": "nás'k",
                                    "url": "nás'k",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "three",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-g̲aa",
                                    "url": "-g̲aa",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(distributed) in the area of; (going) after, (waiting) for; about the time of",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_27",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "one by one (people)",
                "tlingit": [
                    [
                        [
                            "tlék'g̲aanáx̲",
                            [
                                {
                                    "tlingit": "tlék'g̲aanáx̲",
                                    "url": "tlék'g̲aanáx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one (person) at a time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_28",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "two by two (people)",
                "tlingit": [
                    [
                        [
                            "dáx̲g̲aanáx̲",
                            [
                                {
                                    "tlingit": "dáx̲g̲aanáx̲",
                                    "url": "dáx̲g̲aanáx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two (people) at a time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_29",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "and a half",
                "tlingit": [
                    [
                        [
                            "k̲a",
                            [
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "a shoowú",
                            [
                                {
                                    "tlingit": "a shoowú",
                                    "url": "a shoowú",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "part of it; half of it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "dáanaa shoowú",
                                    "url": "dáanaa shoowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "half dollar; fifty cents",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_30",
                        "N"
                    ]
                ]
            },
            {
                "type": "header",
                "english": "Counting things"
            },
            {
                "type": "text",
                "english": "When talking about things and animals, it is not necessary to make the noun plural in Tlingit."
            },
            {
                "type": "ex",
                "english": "one goat",
                "tlingit": [
                    [
                        [
                            "tléix'",
                            [
                                {
                                    "tlingit": "tléix'",
                                    "url": "tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jánwu",
                            [
                                {
                                    "tlingit": "jánwu",
                                    "url": "jánwu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "mountain goat",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_31",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "two deer",
                "tlingit": [
                    [
                        [
                            "déix̲",
                            [
                                {
                                    "tlingit": "déix̲",
                                    "url": "déix̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲uwakaan",
                            [
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "g̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "k̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_32",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "three salmon",
                "tlingit": [
                    [
                        [
                            "nás'k",
                            [
                                {
                                    "tlingit": "nás'k",
                                    "url": "nás'k",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "three",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_33",
                        "N"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "etc."
                ]
            },
            {
                "type": "header",
                "english": "Counting people"
            },
            {
                "type": "text",
                "english": "For counting people, the suffix -náx̲ or -nináx̲ is added to the number."
            },
            {
                "type": "ex",
                "english": "one person",
                "tlingit": [
                    [
                        [
                            "tléináx̲",
                            [
                                {
                                    "tlingit": "tléináx̲",
                                    "url": "tléináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one (person)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_34",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "two people",
                "tlingit": [
                    [
                        [
                            "dáx̲náx̲",
                            [
                                {
                                    "tlingit": "dáx̲náx̲",
                                    "url": "dáx̲náx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two (people)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_35",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "two women",
                "tlingit": [
                    [
                        [
                            "dáx̲náx̲",
                            [
                                {
                                    "tlingit": "dáx̲náx̲",
                                    "url": "dáx̲náx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two (people)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháa",
                            [
                                {
                                    "tlingit": "sháa",
                                    "url": "sháa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "women",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_36",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "three people",
                "tlingit": [
                    [
                        [
                            "nás'gináx̲",
                            [
                                {
                                    "tlingit": "nás'gináx̲",
                                    "url": "nás'gináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "three (people)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_37",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "four people",
                "tlingit": [
                    [
                        [
                            "daax'oonínáx̲",
                            [
                                {
                                    "tlingit": "daax'oonínáx̲",
                                    "url": "daax'oonínáx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "four (people)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_38",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "five people",
                "tlingit": [
                    [
                        [
                            "keijínináx̲",
                            [
                                {
                                    "tlingit": "keijínináx̲",
                                    "url": "keijínináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "five (people)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_39",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "six people",
                "tlingit": [
                    [
                        [
                            "tleidooshóonáx̲",
                            [
                                {
                                    "tlingit": "tleidooshóonáx̲",
                                    "url": "tleidooshóonáx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "six (people)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_40",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "seven people",
                "tlingit": [
                    [
                        [
                            "dax̲adooshóonáx̲",
                            [
                                {
                                    "tlingit": "dax̲adooshóonáx̲",
                                    "url": "dax̲adooshóonáx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "seven (people)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_41",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "eight people",
                "tlingit": [
                    [
                        [
                            "nas'gadooshóonáx̲",
                            [
                                {
                                    "tlingit": "nas'gadooshóonáx̲",
                                    "url": "nas'gadooshóonáx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "eight (people)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_42",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "nine people",
                "tlingit": [
                    [
                        [
                            "gooshúgunáx̲",
                            [],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "gooshúk̲náx̲",
                            [
                                {
                                    "tlingit": "gooshúk̲náx̲",
                                    "url": "gooshúg̲unáx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "nine (people)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "17_43",
                        "N"
                    ],
                    [
                        "17_44",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "ten people",
                "tlingit": [
                    [
                        [
                            "jinkaadináx̲",
                            [
                                {
                                    "tlingit": "jinkaadináx̲",
                                    "url": "jinkaadináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten (people)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "jinkaatnáx̲",
                            [
                                {
                                    "tlingit": "jinkaatnáx̲",
                                    "url": "jinkaadináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten (people)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "17_45",
                        "N"
                    ],
                    [
                        "17_46",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "eleven people",
                "tlingit": [
                    [
                        [
                            "jinkaat k̲a tléináx̲",
                            [
                                {
                                    "tlingit": "jinkaat k̲a tléináx̲",
                                    "url": "jinkaat k̲a tléináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "eleven (people)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "jinkaat k̲a tléix'",
                                    "url": "jinkaat k̲a tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "eleven",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleik̲áa k̲a tléináx̲",
                                    "url": "tleik̲áa k̲a tléináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty one (people)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "nás'k jinkaat k̲a tléix'",
                                    "url": "nás'k jinkaat k̲a tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "thirty one",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléináx̲",
                                    "url": "tléináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one (person)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_47",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "twelve people",
                "tlingit": [
                    [
                        [
                            "jinkaat k̲a",
                            [
                                {
                                    "tlingit": "jinkaat k̲a tléix'",
                                    "url": "jinkaat k̲a tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "eleven",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "jinkaat k̲a tléináx̲",
                                    "url": "jinkaat k̲a tléináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "eleven (people)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "nás'k jinkaat k̲a tléix'",
                                    "url": "nás'k jinkaat k̲a tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "thirty one",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "dáx̲náx̲",
                            [
                                {
                                    "tlingit": "dáx̲náx̲",
                                    "url": "dáx̲náx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two (people)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_48",
                        "J"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "etc."
                ]
            },
            {
                "type": "ex",
                "english": "twenty people",
                "tlingit": [
                    [
                        [
                            "tleik̲áanáx̲",
                            [
                                {
                                    "tlingit": "tleik̲áanáx̲",
                                    "url": "tleik̲áanáx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty (people)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_49",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "twenty one people",
                "tlingit": [
                    [
                        [
                            "tleik̲áa k̲a tléináx̲",
                            [
                                {
                                    "tlingit": "tleik̲áa k̲a tléináx̲",
                                    "url": "tleik̲áa k̲a tléináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty one (people)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "tleik̲áa k̲a tléix'",
                                    "url": "tleik̲áa k̲a tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty one",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "jinkaat k̲a tléináx̲",
                                    "url": "jinkaat k̲a tléináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "eleven (people)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleik̲áa",
                                    "url": "tleik̲áa",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "tléináx̲",
                                    "url": "tléináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one (person)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "k̲áa",
                            [
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_50",
                        "J"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "etc."
                ]
            },
            {
                "type": "header",
                "english": "Old and new counting systems"
            },
            {
                "type": "text",
                "english": "The traditional Tlingit counting system was in base 20. The new system, since European contact, is decimal (base 10). This shows up in counting by 10s (or, as the case may be, by 20s)."
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "Old (base 20)"
                ]
            },
            {
                "type": "ex",
                "english": "20",
                "tlingit": [
                    [
                        [
                            "tleik̲áa",
                            [
                                {
                                    "tlingit": "tleik̲áa",
                                    "url": "tleik̲áa",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_51",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "30",
                "tlingit": [
                    [
                        [
                            "tleik̲áa k̲a",
                            [
                                {
                                    "tlingit": "tleik̲áa k̲a tléix'",
                                    "url": "tleik̲áa k̲a tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty one",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleik̲áa k̲a tléináx̲",
                                    "url": "tleik̲áa k̲a tléináx̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty one (people)",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "tleik̲áa",
                                    "url": "tleik̲áa",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "jinkaat",
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_52",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "40",
                "tlingit": [
                    [
                        [
                            "dax̲k̲áa",
                            [
                                {
                                    "tlingit": "déix̲",
                                    "url": "déix̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_53",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "50",
                "tlingit": [
                    [
                        [
                            "dax̲k̲áa",
                            [
                                {
                                    "tlingit": "déix̲",
                                    "url": "déix̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k̲a",
                            [
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jinkaat",
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_54",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "60",
                "tlingit": [
                    [
                        [
                            "nas'gik̲áa",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_55",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "70",
                "tlingit": [
                    [
                        [
                            "nas'gik̲áa",
                            [],
                            []
                        ],
                        [
                            "k̲a",
                            [
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jinkaat",
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_56",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "80",
                "tlingit": [
                    [
                        [
                            "daax'oonk̲áa",
                            [
                                {
                                    "tlingit": "daax'oon",
                                    "url": "daax'oon",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "four",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_57",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "90",
                "tlingit": [
                    [
                        [
                            "daax'oonk̲áa",
                            [
                                {
                                    "tlingit": "daax'oon",
                                    "url": "daax'oon",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "four",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k̲a",
                            [
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jinkaat",
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_58",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "100",
                "tlingit": [
                    [
                        [
                            "keijink̲áa",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_59",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "200",
                "tlingit": [
                    [
                        [
                            "jinkaatk̲áa",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_60",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "New (decimal, base 10)"
                ]
            },
            {
                "type": "ex",
                "english": "20",
                "tlingit": [
                    [
                        [
                            "tleik̲áa",
                            [
                                {
                                    "tlingit": "tleik̲áa",
                                    "url": "tleik̲áa",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twenty",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_61",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "30",
                "tlingit": [
                    [
                        [
                            "nás'k jinkaat",
                            [
                                {
                                    "tlingit": "nás'k jinkaat",
                                    "url": "nás'k jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "thirty",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "nás'k",
                                    "url": "nás'k",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "three",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_62",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "40",
                "tlingit": [
                    [
                        [
                            "daax'oon",
                            [
                                {
                                    "tlingit": "daax'oon",
                                    "url": "daax'oon",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "four",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jinkaat",
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_63",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "50",
                "tlingit": [
                    [
                        [
                            "keijín",
                            [
                                {
                                    "tlingit": "keijín",
                                    "url": "keijín",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "five",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jinkaat",
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_64",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "60",
                "tlingit": [
                    [
                        [
                            "tleidooshú",
                            [
                                {
                                    "tlingit": "tleidooshú",
                                    "url": "tleidooshú",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "six",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jinkaat",
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_65",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "70",
                "tlingit": [
                    [
                        [
                            "dax̲adooshú",
                            [
                                {
                                    "tlingit": "dax̲adooshú",
                                    "url": "dax̲adooshú",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "seven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jinkaat",
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_66",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "80",
                "tlingit": [
                    [
                        [
                            "nas'gadooshú",
                            [
                                {
                                    "tlingit": "nas'gadooshú",
                                    "url": "nas'gadooshú",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "eight",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jinkaat",
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_67",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "90",
                "tlingit": [
                    [
                        [
                            "gooshúk̲",
                            [
                                {
                                    "tlingit": "gooshúk̲",
                                    "url": "gooshúk̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "nine",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jinkaat",
                            [
                                {
                                    "tlingit": "jinkaat",
                                    "url": "jinkaat",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "ten",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_68",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "100",
                "tlingit": [
                    [
                        [
                            "tléix' hándit",
                            [
                                {
                                    "tlingit": "tléix' hándit",
                                    "url": "tléix' hándit",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one hundred",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "tléix'",
                                    "url": "tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_69",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "200",
                "tlingit": [
                    [
                        [
                            "déix̲",
                            [
                                {
                                    "tlingit": "déix̲",
                                    "url": "déix̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hándit",
                            [
                                {
                                    "tlingit": "tléix' hándit",
                                    "url": "tléix' hándit",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one hundred",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_70",
                        "J"
                    ]
                ]
            },
            {
                "type": "header",
                "english": "Measurements"
            },
            {
                "type": "ex",
                "english": "fathom (armspan)",
                "tlingit": [
                    [
                        [
                            "waat",
                            [
                                {
                                    "tlingit": "waat",
                                    "url": "waat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "armspan; fathom",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_71",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "inch",
                "tlingit": [
                    [
                        [
                            "k̲aa",
                            [
                                {
                                    "tlingit": "k̲aa s'aatí",
                                    "url": "k̲aa s'aatí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boss",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa x̲'oos",
                                    "url": "k̲aa x̲'oos",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "foot (measurement)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at óowu",
                                    "url": "k̲aa at óowu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession(s); that which is owned (by them)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa ji.eetí",
                                    "url": "k̲aa ji.eetí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "handiwork, handmade crafts",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa daakeidí",
                                    "url": "k̲aa daakeidí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "coffin; casket",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa x̲'a.eetí",
                                    "url": "k̲aa x̲'a.eetí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "leftovers, food scraps",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at oohéini",
                                    "url": "k̲aa at oohéini",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession; that which is claimed",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "tl'ek̲x̲'áak",
                            [
                                {
                                    "tlingit": "du tl'ek̲x̲'áak",
                                    "url": "du tl'ek̲x̲'áak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "between his/her fingers",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_72",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "foot",
                "tlingit": [
                    [
                        [
                            "k̲aa x̲'oos",
                            [
                                {
                                    "tlingit": "k̲aa x̲'oos",
                                    "url": "k̲aa x̲'oos",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "foot (measurement)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "k̲aa x̲'oos deiyí",
                                    "url": "k̲aa x̲'oos deiyí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "foot path",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'oos",
                                    "url": "du x̲'oos",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her foot, leg",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa x̲'a.eetí",
                                    "url": "k̲aa x̲'a.eetí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "leftovers, food scraps",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa s'aatí",
                                    "url": "k̲aa s'aatí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boss",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at óowu",
                                    "url": "k̲aa at óowu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession(s); that which is owned (by them)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at oohéini",
                                    "url": "k̲aa at oohéini",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession; that which is claimed",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa kanax̲k̲áa",
                                    "url": "k̲aa kanax̲k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snob; person who considers himself/herself better than others",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_73",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "yard",
                "tlingit": [
                    [
                        [
                            "k̲áas'",
                            [
                                {
                                    "tlingit": "k̲áas'",
                                    "url": "k̲áas'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "match; stick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_74",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "mile",
                "tlingit": [
                    [
                        [
                            "kaay",
                            [
                                {
                                    "tlingit": "kaay",
                                    "url": "kaay",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "measure; mile",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "kaay",
                                    "url": "kaay2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "measuring stick",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_75",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It measures a foot.",
                "tlingit": [
                    [
                        [
                            "Tléix'",
                            [
                                {
                                    "tlingit": "tléix'",
                                    "url": "tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲aa x̲'oos",
                            [
                                {
                                    "tlingit": "k̲aa x̲'oos",
                                    "url": "k̲aa x̲'oos",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "foot (measurement)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "k̲aa x̲'oos deiyí",
                                    "url": "k̲aa x̲'oos deiyí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "foot path",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'oos",
                                    "url": "du x̲'oos",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her foot, leg",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa x̲'a.eetí",
                                    "url": "k̲aa x̲'a.eetí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "leftovers, food scraps",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa s'aatí",
                                    "url": "k̲aa s'aatí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boss",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at óowu",
                                    "url": "k̲aa at óowu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession(s); that which is owned (by them)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at oohéini",
                                    "url": "k̲aa at oohéini",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession; that which is claimed",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa kanax̲k̲áa",
                                    "url": "k̲aa kanax̲k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snob; person who considers himself/herself better than others",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yáx̲",
                            [
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the face of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yáx̲",
                                    "tags": [
                                        "RelationalPostposition"
                                    ],
                                    "english": "like it, in accordance with it, as much as it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaaw yáx̲",
                                    "url": "gaaw yáx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "on time; in time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Wooch yáx̲ nasné!",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Straighten it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wudlitl'éit'",
                                    "url": "a yáx̲ wudlitl'éit'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it climbed the face of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "koowáat'.",
                            [
                                {
                                    "tlingit": "yéi koowáat'",
                                    "url": "yéi kaawayáat'",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it is that long",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_76",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It measures a fathom and a half.",
                "tlingit": [
                    [
                        [
                            "Tléix'",
                            [
                                {
                                    "tlingit": "tléix'",
                                    "url": "tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "waat",
                            [
                                {
                                    "tlingit": "waat",
                                    "url": "waat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "armspan; fathom",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲a",
                            [
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "a shoowú",
                            [
                                {
                                    "tlingit": "a shoowú",
                                    "url": "a shoowú",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "part of it; half of it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "dáanaa shoowú",
                                    "url": "dáanaa shoowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "half dollar; fifty cents",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yáx̲",
                            [
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the face of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yáx̲",
                                    "tags": [
                                        "RelationalPostposition"
                                    ],
                                    "english": "like it, in accordance with it, as much as it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaaw yáx̲",
                                    "url": "gaaw yáx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "on time; in time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Wooch yáx̲ nasné!",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Straighten it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wudlitl'éit'",
                                    "url": "a yáx̲ wudlitl'éit'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it climbed the face of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "koowáat'.",
                            [
                                {
                                    "tlingit": "yéi koowáat'",
                                    "url": "yéi kaawayáat'",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it is that long",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_77",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "teaspoon",
                "tlingit": [
                    [
                        [
                            "shál",
                            [
                                {
                                    "tlingit": "shál",
                                    "url": "shál",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "spoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k'átsku",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_78",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "tablespoon",
                "tlingit": [
                    [
                        [
                            "shál",
                            [
                                {
                                    "tlingit": "shál",
                                    "url": "shál",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "spoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tlein",
                            [
                                {
                                    "tlingit": "tlein",
                                    "url": "tlein",
                                    "tags": [
                                        "Adjective"
                                    ],
                                    "english": "big",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_79",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "cup",
                "tlingit": [
                    [
                        [
                            "gúx'aa",
                            [
                                {
                                    "tlingit": "gúx'aa",
                                    "url": "gúx'aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "cup; can",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_80",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "half a cup",
                "tlingit": [
                    [
                        [
                            "gúx'aa",
                            [
                                {
                                    "tlingit": "gúx'aa",
                                    "url": "gúx'aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "cup; can",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shoowú",
                            [
                                {
                                    "tlingit": "a shoowú",
                                    "url": "a shoowú",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "part of it; half of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "dáanaa shoowú",
                                    "url": "dáanaa shoowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "half dollar; fifty cents",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_81",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "gallon",
                "tlingit": [
                    [
                        [
                            "k̲'ateil",
                            [
                                {
                                    "tlingit": "k̲'ateil",
                                    "url": "k̲'ateil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pitcher; jug",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_82",
                        "J"
                    ]
                ]
            },
            {
                "type": "header",
                "english": "Days of the week"
            },
            {
                "type": "ex",
                "english": "Monday",
                "tlingit": [
                    [
                        [
                            "tléix'",
                            [
                                {
                                    "tlingit": "tléix'",
                                    "url": "tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagiyee",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_83",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Tuesday",
                "tlingit": [
                    [
                        [
                            "déix̲",
                            [
                                {
                                    "tlingit": "déix̲",
                                    "url": "déix̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagiyee",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_84",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wednesday",
                "tlingit": [
                    [
                        [
                            "nás'k",
                            [
                                {
                                    "tlingit": "nás'k",
                                    "url": "nás'k",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "three",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagiyee",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_85",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Thursday",
                "tlingit": [
                    [
                        [
                            "daax'oon",
                            [
                                {
                                    "tlingit": "daax'oon",
                                    "url": "daax'oon",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "four",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagiyee",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_86",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Friday",
                "tlingit": [
                    [
                        [
                            "keijín yagiyee",
                            [
                                {
                                    "tlingit": "keijín yagiyee",
                                    "url": "keijín yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Friday",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "keijín",
                                    "url": "keijín",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "five",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_87",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Saturday",
                "tlingit": [
                    [
                        [
                            "Síndi",
                            [],
                            []
                        ],
                        [
                            "k'átsk'u",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_88",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Sunday",
                "tlingit": [
                    [
                        [
                            "Síndi",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Sándi",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "17_89",
                        "N"
                    ],
                    [
                        "17_90",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "What time is it?",
                "tlingit": [
                    [
                        [
                            "X'oon",
                            [
                                {
                                    "tlingit": "x'oon sá",
                                    "url": "x'oon sá",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "how many; some number (of)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gaaw",
                            [
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "time",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bell",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "drum",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sáwé?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_91",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's getting to be that time (quitting time).",
                "tlingit": [
                    [
                        [
                            "Aadé yaa",
                            [
                                {
                                    "tlingit": "aadé yaa nagút",
                                    "url": "aadé woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is going there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nagút",
                                    "url": "át uwagút",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is going there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nashíx",
                                    "url": "aadé wjixeex",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is running there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa anatán",
                                    "url": "át aawatán",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's carrying it there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nadéin",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's flowing to it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa anatéen",
                                    "url": "aadé aawatee",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is carrying it there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nax̲út'",
                                    "url": "aadé aawax̲óot'",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is dragging him/her/it there",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "aadé akoonáa",
                                    "url": "aadé akaawanáa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is sending him/her there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé nashíx",
                                    "url": "át wujixíx",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is running to it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yagux̲sagóo",
                                    "url": "át yawsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Pl"
                                    ],
                                    "english": "they will swim to it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé akawsixát",
                                    "url": "aadé akawsixát",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he connected it there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé akawsixaa",
                                    "url": "aadé akawsixaa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he poured it out there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé ayakg̲wax̲áa",
                                    "url": "át ayaawax̲áa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will transport him/her/it there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yandag̲íchch",
                                    "url": "aadé yawdig̲eech",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "it pierces it (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "kandaxít.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Aadé yaa k̲unahéin.",
                            [
                                {
                                    "tlingit": "aadé yaa k̲unahéin",
                                    "url": "át k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's getting to be time for it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "aadé yaa k̲unashéen",
                                    "url": "át k̲uwashée",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is searching along there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa k̲unatín",
                                    "url": "aadé k̲oowateen",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is traveling there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du eedé yaa k̲unahéin",
                                    "url": "du éet k̲uwaháa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "his/her turn is coming up",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nadéin",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's flowing to it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa akanasx'wéin",
                                    "url": "át akawsix'óo",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is nailing it on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa anatéen",
                                    "url": "aadé aawatee",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is carrying it there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa akanajél",
                                    "url": "aadé akaawajeil",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is carrying it all there",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "17_92",
                        "J"
                    ],
                    [
                        "17_93",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "noon",
                "tlingit": [
                    [
                        [
                            "satgawsáan",
                            [
                                {
                                    "tlingit": "sitgawsáan",
                                    "url": "sitgawsáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "noon",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_94",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "one o'clock",
                "tlingit": [
                    [
                        [
                            "tléix'",
                            [
                                {
                                    "tlingit": "tléix'",
                                    "url": "tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gaaw",
                            [
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "time",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bell",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "drum",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_95",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "two o'clock",
                "tlingit": [
                    [
                        [
                            "déix̲",
                            [
                                {
                                    "tlingit": "déix̲",
                                    "url": "déix̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gaaw",
                            [
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "time",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bell",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "drum",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "17_96",
                        "J"
                    ]
                ]
            }
        ]
    },
    "18": {
        "title": "Weather",
        "content": [
            {
                "type": "ex",
                "english": "How's the weather?",
                "tlingit": [
                    [
                        [
                            "Wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "k̲uwatee?",
                            [
                                {
                                    "tlingit": "yéi k̲uwatee",
                                    "url": "yéi k̲oowatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "the weather is that way",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "How was the weather?",
                "tlingit": [
                    [
                        [
                            "Wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "k̲oowatee?",
                            [
                                {
                                    "tlingit": "yéi k̲oowatee",
                                    "url": "yéi k̲oowatee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "the weather was that way",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's fine.",
                "tlingit": [
                    [
                        [
                            "K̲uwak'éi.",
                            [
                                {
                                    "tlingit": "k̲uwak'éi",
                                    "url": "k̲oowak'ei",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "the weather is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "now",
                "tlingit": [
                    [
                        [
                            "yeedát",
                            [
                                {
                                    "tlingit": "yeedát",
                                    "url": "yeedát",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "now",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "today",
                "tlingit": [
                    [
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagiyee",
                            [
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yagiyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yakyee",
                            [
                                {
                                    "tlingit": "yakyee",
                                    "url": "yagee",
                                    "tags": [
                                        "Noun",
                                        "At",
                                        "T"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yakyee",
                                    "url": "yagiyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yakyee",
                                    "url": "yakyee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "day, afternoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "18_5",
                        "N"
                    ],
                    [
                        "18_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "tomorrow",
                "tlingit": [
                    [
                        [
                            "seig̲án",
                            [
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲ánin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "seig̲ánin",
                            [
                                {
                                    "tlingit": "seig̲ánin",
                                    "url": "seig̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "seig̲ánin",
                                    "url": "seig̲ánin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "18_7",
                        "N"
                    ],
                    [
                        "18_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "yesterday",
                "tlingit": [
                    [
                        [
                            "tatgé",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "probably",
                "tlingit": [
                    [
                        [
                            "shákdé",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "maybe, probably",
                "tlingit": [
                    [
                        [
                            "gwál",
                            [
                                {
                                    "tlingit": "gwál",
                                    "url": "gwál",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "perhaps",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It was fine yesterday.",
                "tlingit": [
                    [
                        [
                            "K̲oowak'éi",
                            [],
                            []
                        ],
                        [
                            "tatgé.",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will be fine.",
                "tlingit": [
                    [
                        [
                            "Kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲ukg̲wak'éi.",
                            [
                                {
                                    "tlingit": "yei k̲ukg̲wak'éi",
                                    "url": "k̲oowak'ei",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "the weather will become good",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's getting fine.",
                "tlingit": [
                    [
                        [
                            "Kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲unak'éin.",
                            [
                                {
                                    "tlingit": "yei k̲unak'éin",
                                    "url": "k̲oowak'ei",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "the weather is becoming good",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will be fine tomorrow.",
                "tlingit": [
                    [
                        [
                            "Seig̲án",
                            [
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲ánin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲ukg̲wak'éi.",
                            [
                                {
                                    "tlingit": "yei k̲ukg̲wak'éi",
                                    "url": "k̲oowak'ei",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "the weather will become good",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will probably be fine.",
                "tlingit": [
                    [
                        [
                            "Kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲ukg̲wak'éi",
                            [
                                {
                                    "tlingit": "yei k̲ukg̲wak'éi",
                                    "url": "k̲oowak'ei",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "the weather will become good",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "shákdé.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will probably be fine tomorrow.",
                "tlingit": [
                    [
                        [
                            "Seig̲án",
                            [
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲ánin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲ukg̲wak'éi",
                            [
                                {
                                    "tlingit": "yei k̲ukg̲wak'éi",
                                    "url": "k̲oowak'ei",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "the weather will become good",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "shákdé.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "The weather is not good.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲ooshk'é.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's cloudy.",
                "tlingit": [
                    [
                        [
                            "K̲uligóos'.",
                            [
                                {
                                    "tlingit": "k̲uligóos'",
                                    "url": "k̲oowligóos'",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's cloudy",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It was (got) cloudy yesterday.",
                "tlingit": [
                    [
                        [
                            "K̲uwligóos'",
                            [],
                            []
                        ],
                        [
                            "tatgé.",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will be cloudy.",
                "tlingit": [
                    [
                        [
                            "Kei k̲ugux̲lagóos'.",
                            [
                                {
                                    "tlingit": "kei k̲ugux̲lagóos'",
                                    "url": "k̲oowligóos'",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will be cloudy",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's getting cloudy.",
                "tlingit": [
                    [
                        [
                            "Kei k̲unalgóos'.",
                            [
                                {
                                    "tlingit": "kei k̲unalgóos'",
                                    "url": "k̲oowligóos'",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's getting cloudy",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's sunny.",
                "tlingit": [
                    [
                        [
                            "Awdigaan.",
                            [
                                {
                                    "tlingit": "awdigaan",
                                    "url": "awdigaan",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's sunny",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It was sunny yesterday.",
                "tlingit": [
                    [
                        [
                            "Awdigaan",
                            [
                                {
                                    "tlingit": "awdigaan",
                                    "url": "awdigaan",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's sunny",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tatgé.",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_24",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will be sunny.",
                "tlingit": [
                    [
                        [
                            "Yei agux̲dagáan.",
                            [
                                {
                                    "tlingit": "yei agux̲dagáan",
                                    "url": "awdigaan",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will be sunny",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yei",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's getting sunny.",
                "tlingit": [
                    [
                        [
                            "Yei andagán.",
                            [
                                {
                                    "tlingit": "yei andagán",
                                    "url": "awdigaan",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's getting sunny",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yei",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_26",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's warm (hot).",
                "tlingit": [
                    [
                        [
                            "K̲uwat'aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_27",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It was warm yesterday.",
                "tlingit": [
                    [
                        [
                            "K̲oowat'aa",
                            [],
                            []
                        ],
                        [
                            "tatgé.",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_28",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will be warm (hot).",
                "tlingit": [
                    [
                        [
                            "K̲ukg̲wat'áa.",
                            [
                                {
                                    "tlingit": "k̲ukg̲wat'áa",
                                    "url": "k̲oowat'áa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "the weather will be hot",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲ukg̲wat'aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(w/o kei, high tone)",
                    "(w/kei, low tone)"
                ],
                "ids": [
                    [
                        "18_29",
                        "N"
                    ],
                    [
                        "18_30",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Maybe it will be hot tomorrow.",
                "tlingit": [
                    [
                        [
                            "Seig̲án",
                            [
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲ánin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲kg̲wat'aa",
                            [],
                            []
                        ],
                        [
                            "shakdé.",
                            [
                                {
                                    "tlingit": "shákdéi",
                                    "url": "shákdéi",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "perhaps, probably",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_31",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's getting warm (hot).",
                "tlingit": [
                    [
                        [
                            "Yaa k̲unat'éin.",
                            [
                                {
                                    "tlingit": "yaa k̲unat'éin",
                                    "url": "k̲oowat'áa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "the weather is getting hot",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_32",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's cold.",
                "tlingit": [
                    [
                        [
                            "K̲usi.áat'.",
                            [
                                {
                                    "tlingit": "k̲usi.áat'",
                                    "url": "k̲uwsi.áat'",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "the weather is cold",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_33",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It was (got) cold yesterday.",
                "tlingit": [
                    [
                        [
                            "K̲uwsi.áat'",
                            [
                                {
                                    "tlingit": "k̲uwsi.áat'",
                                    "url": "k̲uwsi.áat'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "the weather was cold",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tatgé.",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_34",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will be cold.",
                "tlingit": [
                    [
                        [
                            "Kei k̲ugux̲sa.áat'.",
                            [
                                {
                                    "tlingit": "kei k̲ugux̲sa.áat'",
                                    "url": "k̲uwsi.áat'",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "the weather will be cold",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_35",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's getting cold.",
                "tlingit": [
                    [
                        [
                            "Kei k̲unas.áat'.",
                            [
                                {
                                    "tlingit": "kei k̲unas.áat'",
                                    "url": "k̲uwsi.áat'",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "the weather is getting cold",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_36",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's snowing.",
                "tlingit": [
                    [
                        [
                            "Dleit",
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daak wusitán.",
                            [
                                {
                                    "tlingit": "séew daak wusitán",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's raining",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_37",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It snowed yesterday.",
                "tlingit": [
                    [
                        [
                            "Dleit",
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daak wusitán",
                            [
                                {
                                    "tlingit": "séew daak wusitán",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's raining",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "tatgé.",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_38",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will snow.",
                "tlingit": [
                    [
                        [
                            "Dleit",
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daak gux̲satáan.",
                            [
                                {
                                    "tlingit": "séew daak gux̲satáan",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will rain",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_39",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's starting to snow.",
                "tlingit": [
                    [
                        [
                            "Dleit",
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daak nastán.",
                            [
                                {
                                    "tlingit": "séew daak nastán",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's starting to rain",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_40",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will probably snow.",
                "tlingit": [
                    [
                        [
                            "Dleit",
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daak gux̲satáan",
                            [
                                {
                                    "tlingit": "séew daak gux̲satáan",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will rain",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "shákdé.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_41",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Maybe it will snow.",
                "tlingit": [
                    [
                        [
                            "Gwál",
                            [
                                {
                                    "tlingit": "gwál",
                                    "url": "gwál",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "perhaps",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dleit",
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daak gux̲satáan.",
                            [
                                {
                                    "tlingit": "séew daak gux̲satáan",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will rain",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_42",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will snow tomorrow.",
                "tlingit": [
                    [
                        [
                            "Seig̲án",
                            [
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲ánin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dleit",
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daak gux̲satáan.",
                            [
                                {
                                    "tlingit": "séew daak gux̲satáan",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will rain",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_43",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will probably snow tomorrow.",
                "tlingit": [
                    [
                        [
                            "Seig̲án",
                            [
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "seig̲án",
                                    "url": "seig̲ánin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tomorrow",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dleit",
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daak gux̲satáan",
                            [
                                {
                                    "tlingit": "séew daak gux̲satáan",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will rain",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "shákdé.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_44",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's raining.",
                "tlingit": [
                    [
                        [
                            "Séew daak wusitán.",
                            [
                                {
                                    "tlingit": "séew daak wusitán",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's raining",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "séew daak ustáanch",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "it rains (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "séew",
                                    "url": "séew",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rain",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "séew",
                                    "url": "sóow",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rain",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "séew daak nastán",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's starting to rain",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "séew daak satánx̲",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it rains (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "séew daak gux̲satáan",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will rain",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_45",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is it raining?",
                "tlingit": [
                    [
                        [
                            "Séew",
                            [
                                {
                                    "tlingit": "séew",
                                    "url": "séew",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rain",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "séew",
                                    "url": "sóow",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rain",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "daak wusitán?",
                            [
                                {
                                    "tlingit": "séew daak wusitán",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's raining",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_46",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It rained yesterday.",
                "tlingit": [
                    [
                        [
                            "Séew daak wusitán",
                            [
                                {
                                    "tlingit": "séew daak wusitán",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's raining",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "séew daak ustáanch",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "it rains (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "séew",
                                    "url": "séew",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rain",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "séew",
                                    "url": "sóow",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rain",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "séew daak nastán",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's starting to rain",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "séew daak satánx̲",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it rains (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "séew daak gux̲satáan",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will rain",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "tatgé.",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_47",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will rain.",
                "tlingit": [
                    [
                        [
                            "Séew daak gux̲satáan.",
                            [
                                {
                                    "tlingit": "séew daak gux̲satáan",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will rain",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "séew daak ustáanch",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "it rains (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "séew",
                                    "url": "séew",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rain",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "séew",
                                    "url": "sóow",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rain",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "séew daak wusitán",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's raining",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "séew daak satánx̲",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it rains (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "séew daak nastán",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's starting to rain",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_48",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's starting to rain.",
                "tlingit": [
                    [
                        [
                            "Séew daak nastán.",
                            [
                                {
                                    "tlingit": "séew daak nastán",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's starting to rain",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "séew daak wusitán",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's raining",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "séew",
                                    "url": "séew",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rain",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "séew",
                                    "url": "sóow",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rain",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "daak",
                                    "url": "daak",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "out to sea; out into the open; (falling) down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "séew daak satánx̲",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it rains (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "séew daak ustáanch",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "it rains (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "séew daak gux̲satáan",
                                    "url": "séew daak wusitán",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will rain",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_49",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's foggy.",
                "tlingit": [
                    [
                        [
                            "K̲uwdigwás'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_50",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will be foggy.",
                "tlingit": [
                    [
                        [
                            "K̲ugux̲dagwáas'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_51",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's getting foggy.",
                "tlingit": [
                    [
                        [
                            "Yaa k̲undagwás'.",
                            [
                                {
                                    "tlingit": "yaa k̲undagwás'",
                                    "url": "k̲oowdigwás'",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's getting foggy",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_52",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's stormy.",
                "tlingit": [
                    [
                        [
                            "Ayawditee.",
                            [
                                {
                                    "tlingit": "ayawditee",
                                    "url": "ayawditee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's stormy",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_53",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It was stormy yesterday.",
                "tlingit": [
                    [
                        [
                            "Ayawditee",
                            [
                                {
                                    "tlingit": "ayawditee",
                                    "url": "ayawditee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's stormy",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tatgé.",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_54",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will be stormy.",
                "tlingit": [
                    [
                        [
                            "Yei ayagux̲datée.",
                            [
                                {
                                    "tlingit": "yei ayagux̲datée",
                                    "url": "ayawditee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "it will be stormy",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yei",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_55",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's getting stormy.",
                "tlingit": [
                    [
                        [
                            "Yei",
                            [
                                {
                                    "tlingit": "yei",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ayandateen.",
                            [
                                {
                                    "tlingit": "ayawditee",
                                    "url": "ayawditee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "it's stormy; it's rough (of weather)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ayawditee",
                                    "url": "ayawditee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "it's stormy; it's rough (of weather)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_56",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's snowing hard.",
                "tlingit": [
                    [
                        [
                            "Ayakaawadán.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_57",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It was snowing hard yesterday.",
                "tlingit": [
                    [
                        [
                            "Ayakaawadán",
                            [],
                            []
                        ],
                        [
                            "tatgé.",
                            [
                                {
                                    "tlingit": "tatgé",
                                    "url": "tatgé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yesterday",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_58",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will snow hard.",
                "tlingit": [
                    [
                        [
                            "Ayakakg̲wadáan.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_59",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's starting to snow hard.",
                "tlingit": [
                    [
                        [
                            "Yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ayakanadán.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_60",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm stormbound.",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaawasík.",
                            [
                                {
                                    "tlingit": "yaawasík",
                                    "url": "yaawasík",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is delayed",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_61",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm wet.",
                "tlingit": [
                    [
                        [
                            "X̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wuditl'ák'.",
                            [
                                {
                                    "tlingit": "wuditl'ák'",
                                    "url": "wuditl'ák'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is wet",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_62",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "How is it outside?",
                "tlingit": [
                    [
                        [
                            "Wáa sá",
                            [
                                {
                                    "tlingit": "wáa sá",
                                    "url": "wáa sá",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "how",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "wáa yateeyi yéix'",
                                    "url": "wáa yateeyi yéix'",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "sometimes",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yatee",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gáan?",
                            [
                                {
                                    "tlingit": "gáan",
                                    "url": "gáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "menstrual discharge; period",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gáan",
                                    "url": "gáan2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "outdoors; outside",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_63",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'll take a look.",
                "tlingit": [
                    [
                        [
                            "Aadé",
                            [
                                {
                                    "tlingit": "aadé ksixát",
                                    "url": "aadé ksixát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's connected there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé nashíx",
                                    "url": "át wujixíx",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is running to it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé akoonáa",
                                    "url": "aadé akaawanáa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is sending him/her there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé wjixeex",
                                    "url": "aadé wjixeex",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it ran there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé aawatee",
                                    "url": "aadé aawatee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he carried it there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé woogoot",
                                    "url": "aadé woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it went there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé awsitee",
                                    "url": "aadé awsitee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he carried it there",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "akk̲walg̲éin.",
                            [
                                {
                                    "tlingit": "aadé awdlig̲ein",
                                    "url": "aadé awdlig̲ein",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it looked there",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Aadé",
                            [
                                {
                                    "tlingit": "aadé ksixát",
                                    "url": "aadé ksixát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's connected there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé nashíx",
                                    "url": "át wujixíx",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is running to it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé akoonáa",
                                    "url": "aadé akaawanáa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is sending him/her there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé wjixeex",
                                    "url": "aadé wjixeex",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it ran there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé aawatee",
                                    "url": "aadé aawatee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he carried it there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé woogoot",
                                    "url": "aadé woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it went there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé awsitee",
                                    "url": "aadé awsitee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he carried it there",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "akk̲walg̲éen.",
                            [
                                {
                                    "tlingit": "aadé awdlig̲ein",
                                    "url": "aadé awdlig̲ein",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "he/she/it looked there",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "18_64",
                        "N"
                    ],
                    [
                        "18_65",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It stopped (raining or snowing).",
                "tlingit": [
                    [
                        [
                            "Aawadaak̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_66",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It will stop (raining or snowing).",
                "tlingit": [
                    [
                        [
                            "Akg̲wadáak̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_67",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's beginning to let up.",
                "tlingit": [
                    [
                        [
                            "Yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "anadák̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "18_68",
                        "N"
                    ]
                ]
            }
        ]
    },
    "19": {
        "title": "Commands I: Washing things and self",
        "content": [
            {
                "type": "ex",
                "english": "Wash! [singular]",
                "tlingit": [
                    [
                        [
                            "Na.óos'!",
                            [
                                {
                                    "tlingit": "Na.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash! [plural]",
                "tlingit": [
                    [
                        [
                            "Nay.óos'!",
                            [
                                {
                                    "tlingit": "Nay.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wipe! [singular]",
                "tlingit": [
                    [
                        [
                            "G̲alg̲ú!",
                            [
                                {
                                    "tlingit": "G̲alg̲ú!",
                                    "url": "awlig̲oo",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wipe it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wipe! [plural]",
                "tlingit": [
                    [
                        [
                            "G̲aylag̲ú!",
                            [
                                {
                                    "tlingit": "G̲aylag̲ú!",
                                    "url": "awlig̲oo",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all wipe it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash the clothes.",
                "tlingit": [
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "naa.át",
                            [
                                {
                                    "tlingit": "naa.át",
                                    "url": "naa.át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clothes, clothing; garment",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "na.óos'.",
                            [
                                {
                                    "tlingit": "Na.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your clothes.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "naa.ádi",
                            [
                                {
                                    "tlingit": "du naa.ádi",
                                    "url": "naa.át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its clothes, clothing; garment",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "na.óos'.",
                            [
                                {
                                    "tlingit": "Na.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash the dish.",
                "tlingit": [
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'íx'",
                            [
                                {
                                    "tlingit": "s'íx'",
                                    "url": "s'íx'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dish; plate",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kana.óos'.",
                            [
                                {
                                    "tlingit": "Kana.óos'!",
                                    "url": "akaawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'íx'",
                            [
                                {
                                    "tlingit": "s'íx'",
                                    "url": "s'íx'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dish; plate",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲oo",
                            [
                                {
                                    "tlingit": "a x̲oo",
                                    "url": "a x̲oo",
                                    "tags": [
                                        "RelationalNoun",
                                        "free"
                                    ],
                                    "english": "(in) the midst of it; among it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a x̲oo aa",
                                    "url": "a x̲oo aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "some of them",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "na.óos'.",
                            [
                                {
                                    "tlingit": "Na.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "19_7",
                        "N"
                    ],
                    [
                        "19_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your dish.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'íx'i",
                            [
                                {
                                    "tlingit": "s'íx'",
                                    "url": "s'íx'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dish; plate",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kana.óos'.",
                            [
                                {
                                    "tlingit": "Kana.óos'!",
                                    "url": "akaawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash the cup.",
                "tlingit": [
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gúx'aa",
                            [
                                {
                                    "tlingit": "gúx'aa",
                                    "url": "gúx'aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "cup; can",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kana.óos'.",
                            [
                                {
                                    "tlingit": "Kana.óos'!",
                                    "url": "akaawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your cup.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gúx'ayi",
                            [
                                {
                                    "tlingit": "du gúx'ayi",
                                    "url": "gúx'aa",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its cup; can",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kana.óos'.",
                            [
                                {
                                    "tlingit": "Kana.óos'!",
                                    "url": "akaawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash the table.",
                "tlingit": [
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nadáakw",
                            [
                                {
                                    "tlingit": "nadáakw",
                                    "url": "nadáakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "table",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kana.óos'.",
                            [
                                {
                                    "tlingit": "Kana.óos'!",
                                    "url": "akaawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Wash your hands. [singular]",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jín",
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "na.óos'.",
                            [
                                {
                                    "tlingit": "Na.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your hands. [plural]",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jín",
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "nay.óos'.",
                            [
                                {
                                    "tlingit": "Nay.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wipe your hands. [singular]",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jín",
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "g̲alg̲ú.",
                            [
                                {
                                    "tlingit": "G̲alg̲ú!",
                                    "url": "awlig̲oo",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wipe it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wipe your hands. [plural]",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jín",
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "g̲aylag̲ú.",
                            [
                                {
                                    "tlingit": "G̲aylag̲ú!",
                                    "url": "awlig̲oo",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all wipe it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Wash your head. [plural]",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shá",
                            [
                                {
                                    "tlingit": "Shá!",
                                    "url": "aawasháa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Marry him/her!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nay.óos'.",
                            [
                                {
                                    "tlingit": "Nay.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your face. [plural]",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yá",
                            [
                                {
                                    "tlingit": "yá",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nay.óos'.",
                            [
                                {
                                    "tlingit": "Nay.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your hair. [plural]",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shax̲aawú",
                            [
                                {
                                    "tlingit": "du shax̲aawú",
                                    "url": "du shax̲aawú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hair",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "nay.óos'.",
                            [
                                {
                                    "tlingit": "Nay.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your foot. [plural]",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'oos",
                            [
                                {
                                    "tlingit": "du x̲'oos",
                                    "url": "du x̲'oos",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her foot, leg",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa x̲'oos",
                                    "url": "k̲aa x̲'oos",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "foot (measurement)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa x̲'oos deiyí",
                                    "url": "k̲aa x̲'oos deiyí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "foot path",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "g̲agaan át x̲'oos uwatsóow",
                                    "url": "g̲agaan át x̲'oos uwatsóow",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "sun rays are shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "g̲agaan aadé x̲'oos gug̲atsóow",
                                    "url": "g̲agaan át x̲'oos uwatsóow",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "sun rays will shine on it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "nay.óos'.",
                            [
                                {
                                    "tlingit": "Nay.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Brush your teeth. [plural]",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "oox̲",
                            [
                                {
                                    "tlingit": "du oox̲",
                                    "url": "du oox̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her tooth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "oox̲ katság̲aa",
                                    "url": "oox̲ katság̲aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "toothpick",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ layeix̲í",
                                    "url": "k̲aa oox̲ layeix̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ yei daanéiyi",
                                    "url": "k̲aa oox̲ yei daanéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ leyeix̲í",
                                    "url": "k̲aa oox̲ layeix̲í",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "nay.óos'.",
                            [
                                {
                                    "tlingit": "Nay.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Wash your head. [singular]",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shá",
                            [
                                {
                                    "tlingit": "Shá!",
                                    "url": "aawasháa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Marry him/her!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "na.óos'.",
                            [
                                {
                                    "tlingit": "Na.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your face. [singular]",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yá",
                            [
                                {
                                    "tlingit": "yá",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "na.óos'.",
                            [
                                {
                                    "tlingit": "Na.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your hair. [singular]",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shax̲aawú",
                            [
                                {
                                    "tlingit": "du shax̲aawú",
                                    "url": "du shax̲aawú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hair",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "na.óos'.",
                            [
                                {
                                    "tlingit": "Na.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_24",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your foot. [singular]",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'oos'",
                            [],
                            []
                        ],
                        [
                            "na.óos'.",
                            [
                                {
                                    "tlingit": "Na.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_25",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Brush your teeth. [singular]",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "oox̲",
                            [
                                {
                                    "tlingit": "du oox̲",
                                    "url": "du oox̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her tooth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "oox̲ katság̲aa",
                                    "url": "oox̲ katság̲aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "toothpick",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ layeix̲í",
                                    "url": "k̲aa oox̲ layeix̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ yei daanéiyi",
                                    "url": "k̲aa oox̲ yei daanéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa oox̲ leyeix̲í",
                                    "url": "k̲aa oox̲ layeix̲í",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "na.óos'.",
                            [
                                {
                                    "tlingit": "Na.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_26",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "There are alternative forms:"
            },
            {
                "type": "ex",
                "english": "Wash your hands. [sJ",
                "tlingit": [
                    [
                        [
                            "Jinida.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_27",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your hands. [Pl",
                "tlingit": [
                    [
                        [
                            "Jinayda.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_28",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your head. [singular]",
                "tlingit": [
                    [
                        [
                            "Shanida.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_29",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your head. [plural]",
                "tlingit": [
                    [
                        [
                            "Shanayda.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_30",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your face. [singular]",
                "tlingit": [
                    [
                        [
                            "Yanida.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_31",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your face. [plural]",
                "tlingit": [
                    [
                        [
                            "Yanayda.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_32",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your feet. [singular]",
                "tlingit": [
                    [
                        [
                            "X̲'usnida.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_33",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your feet. [plural]",
                "tlingit": [
                    [
                        [
                            "X̲'usnayda.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_34",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "You may hear yet further variations of the alternative forms above, using more complex contractions:"
            },
            {
                "type": "ex",
                "english": "Wash your hands. [singular]",
                "tlingit": [
                    [
                        [
                            "Jeenda.óos'.",
                            [
                                {
                                    "tlingit": "aawa.óos'",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he washed it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aawa.óos'",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he washed it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "for Jinida.óos'."
                ],
                "ids": [
                    [
                        "19_35",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your head. [singular]",
                "tlingit": [
                    [
                        [
                            "Sheenda.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "for Shanida.óos."
                ],
                "ids": [
                    [
                        "19_36",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your face. [singular]",
                "tlingit": [
                    [
                        [
                            "Yeenda.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "for Yanida.óos'."
                ],
                "ids": [
                    [
                        "19_37",
                        "J"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "There are also alternative forms using the L classifier."
            },
            {
                "type": "ex",
                "english": "Wash your hands. [S]",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jín",
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "nali.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_38",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your hands. (PJ",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jín",
                            [
                                {
                                    "tlingit": "a jín",
                                    "url": "a jíni2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "its paw",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jín",
                                    "url": "du jín",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her hand",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "nayla.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_39",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your hands. [plural]",
                "tlingit": [
                    [
                        [
                            "Jinayil.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_40",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash your feet. IP!",
                "tlingit": [
                    [
                        [
                            "X̲'usnayil.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_41",
                        "J"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "The pattern seems to change a bit. It is OK to say:"
            },
            {
                "type": "ex",
                "english": "Wash the hands. [singular]",
                "tlingit": [
                    [
                        [
                            "Jinal.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_42",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "But it implies washing someone else's hands, not your own. The L classifier is used here for action involving long objects such as fingers, toes, spruce roots (for weaving), and some (but not all) fish, etc."
            },
            {
                "type": "ex",
                "english": "She's washing her spruce roots.",
                "tlingit": [
                    [
                        [
                            "Du x̲aadí",
                            [
                                {
                                    "tlingit": "du x̲aadí",
                                    "url": "x̲aat",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its root; especially spruce root",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "al.ús'kw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_43",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She washed her spruce roots.",
                "tlingit": [
                    [
                        [
                            "Du x̲aadí",
                            [
                                {
                                    "tlingit": "du x̲aadí",
                                    "url": "x̲aat",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its root; especially spruce root",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "awli.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_44",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash the spruce roots.",
                "tlingit": [
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aat",
                            [
                                {
                                    "tlingit": "x̲aat",
                                    "url": "x̲aat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "root; especially spruce root",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nal.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_45",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Wash the hooligan.",
                "tlingit": [
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "saak",
                            [
                                {
                                    "tlingit": "saak",
                                    "url": "saak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "eulachon; candlefish; hooligan",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "nal.óos'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_46",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "But:"
            },
            {
                "type": "ex",
                "english": "Wash the salmon.",
                "tlingit": [
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "na.óos'.",
                            [
                                {
                                    "tlingit": "Na.óos'!",
                                    "url": "aawa.óos'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Wash it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "19_47",
                        "J"
                    ]
                ]
            }
        ]
    },
    "20": {
        "title": "Commands Il: Giving, taking, carrying, bringing, handling",
        "content": [
            {
                "type": "text",
                "english": "Tlingit verbs of handling (giving, bringing, taking, carrying, picking up, putting down, and putting on clothes, etc.) are very complicated and typically involve a verb stem and a series of prefixes relating to the shape of the object. For example: The Tlingit verb stem tí has the basic meaning of handling a general object, usually small. When the verb stem is in the command form with various noun phrases and directional prefixes, it has the following meanings. \"It\" or \"the X\" refers to the noun actually used."
            },
            {
                "type": "ex",
                "english": "bring [it] here; bring [the X] here",
                "tlingit": [
                    [
                        [
                            "1. haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tí",
                            [
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan tí!",
                                    "url": "yan aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "give [it] to me; give me [the X]; hand me [the X]",
                "tlingit": [
                    [
                        [
                            "2. ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeet tí",
                            [
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet katí!",
                                    "url": "du jeet akaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet yití!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tán!",
                                    "url": "du jeet aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet la.át!",
                                    "url": "du jeet awli.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give them to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet sa.ín!",
                                    "url": "du jeet awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "give [it] to him/her; give him/her [the X]; hand him/her [X]",
                "tlingit": [
                    [
                        [
                            "2a.",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du jeet tí",
                            [
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Du jeet katí!",
                                    "url": "du jeet akaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet yití!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tán!",
                                    "url": "du jeet aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jeet awli.át",
                                    "url": "du jeet awli.át",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he gave them to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jeet awsi.ín",
                                    "url": "du jeet awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he gave it to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jeet awsitán",
                                    "url": "du jeet awsitán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he gave it to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jeet shuwaxíx",
                                    "url": "du jeet shuwaxíx",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he ran out of it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "put [it] down; put down [the X]",
                "tlingit": [
                    [
                        [
                            "3. yan tí",
                            [
                                {
                                    "tlingit": "Yan tí!",
                                    "url": "yan aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yan~",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yan",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "pick [it] up; pick up [the X]",
                "tlingit": [
                    [
                        [
                            "4. aax̲ gatí",
                            [
                                {
                                    "tlingit": "Aax̲ gatí!",
                                    "url": "aax̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Pick it up off of it!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Aax̲ gaytí!",
                                    "url": "aax̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all pick it up off of it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ axásht",
                                    "url": "aax̲ aawaxásh",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cuts if off (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ kei aawayísh",
                                    "url": "aax̲ kei aawayísh",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he pulled it out of there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ yéi awsinei",
                                    "url": "aax̲ yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he picked them up off of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ agateech",
                                    "url": "aax̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he picks it up off of it (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ aawatee",
                                    "url": "aax̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he picked it up off of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ axáash",
                                    "url": "aax̲ aawaxásh",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's cutting it off",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "take [it] away; take [the X] away",
                "tlingit": [
                    [
                        [
                            "S.",
                            [
                                {
                                    "tlingit": "yaa (ha)s na.át",
                                    "url": "has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gági (ha)s .átx̲",
                                    "url": "gági has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they emerge (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yaa (ha)s shuga.á!",
                                    "url": "has ashoowa.aat",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lead them!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áa kei (ha)s na.át",
                                    "url": "áa kei (ha)s uwa.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking up there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa (ha)s ayanda.át",
                                    "url": "has ayawdi.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are turning back",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "át yoo (ha)s ya.átk",
                                    "url": "át has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they walk around (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ yei (ha)s na.át",
                                    "url": "áx̲ has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking down along it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "aax̲",
                            [
                                {
                                    "tlingit": "aax̲ axáash",
                                    "url": "aax̲ aawaxásh",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's cutting it off",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ aawatee",
                                    "url": "aax̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he picked it up off of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ yéi awsinei",
                                    "url": "aax̲ yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he picked them up off of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ kei aawayísh",
                                    "url": "aax̲ kei aawayísh",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he pulled it out of there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ axásht",
                                    "url": "aax̲ aawaxásh",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cuts if off (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ agateech",
                                    "url": "aax̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he picks it up off of it (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ aawaxásh",
                                    "url": "aax̲ aawaxásh",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cut it off",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "natí",
                            [
                                {
                                    "tlingit": "Aadé natí!",
                                    "url": "aadé aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Carry it there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yéi yee natí!",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be that way!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ natí!",
                                    "url": "du x̲'éix̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed it to him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du yáx̲ yee natí!",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be like him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ at natí!",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Tleiyéi yéi yee natí!",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be still!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲ánx' yéi yee natí!",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all stay with him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "carry [it] (there); carry [the X] (over there)",
                "tlingit": [
                    [
                        [
                            "6. yóode",
                            [
                                {
                                    "tlingit": "yóode",
                                    "url": "yóot~",
                                    "tags": [
                                        "Adverb",
                                        "allative"
                                    ],
                                    "english": "away, off (to someplace indefinite)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóode",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative",
                                        "allative"
                                    ],
                                    "english": "toward yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "natí",
                            [
                                {
                                    "tlingit": "Aadé natí!",
                                    "url": "aadé aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Carry it there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yéi yee natí!",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be that way!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ natí!",
                                    "url": "du x̲'éix̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed it to him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du yáx̲ yee natí!",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be like him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ at natí!",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Tleiyéi yéi yee natí!",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be still!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲ánx' yéi yee natí!",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all stay with him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Here are the verbs with the noun object \"stone\":"
            },
            {
                "type": "ex",
                "english": "Bring the stone here.",
                "tlingit": [
                    [
                        [
                            "1. Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tí",
                            [
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan tí!",
                                    "url": "yan aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "té.",
                            [
                                {
                                    "tlingit": "té",
                                    "url": "té",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "stone; rock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Give me the stone; hand me the stone",
                "tlingit": [
                    [
                        [
                            "2. Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeet tí",
                            [
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet katí!",
                                    "url": "du jeet akaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet yití!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tán!",
                                    "url": "du jeet aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet la.át!",
                                    "url": "du jeet awli.át",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give them to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet sa.ín!",
                                    "url": "du jeet awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "té.",
                            [
                                {
                                    "tlingit": "té",
                                    "url": "té",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "stone; rock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Give him/her the stone; hand him/her the stone.",
                "tlingit": [
                    [
                        [
                            "2a.",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Du jeet tí",
                            [
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Du jeet katí!",
                                    "url": "du jeet akaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet yití!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tán!",
                                    "url": "du jeet aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jeet awli.át",
                                    "url": "du jeet awli.át",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he gave them to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jeet awsi.ín",
                                    "url": "du jeet awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he gave it to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jeet awsitán",
                                    "url": "du jeet awsitán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he gave it to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du jeet shuwaxíx",
                                    "url": "du jeet shuwaxíx",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he ran out of it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "té.",
                            [
                                {
                                    "tlingit": "té",
                                    "url": "té",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "stone; rock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Put down the stone.",
                "tlingit": [
                    [
                        [
                            "3. Yan tí",
                            [
                                {
                                    "tlingit": "Yan tí!",
                                    "url": "yan aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yan~",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yan",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "té.",
                            [
                                {
                                    "tlingit": "té",
                                    "url": "té",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "stone; rock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Pick up the stone.",
                "tlingit": [
                    [
                        [
                            "4. Aax̲ gatí",
                            [
                                {
                                    "tlingit": "Aax̲ gatí!",
                                    "url": "aax̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Pick it up off of it!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Aax̲ gaytí!",
                                    "url": "aax̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all pick it up off of it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ axásht",
                                    "url": "aax̲ aawaxásh",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cuts if off (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ kei aawayísh",
                                    "url": "aax̲ kei aawayísh",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he pulled it out of there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ yéi awsinei",
                                    "url": "aax̲ yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he picked them up off of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ agateech",
                                    "url": "aax̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he picks it up off of it (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ aawatee",
                                    "url": "aax̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he picked it up off of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ axáash",
                                    "url": "aax̲ aawaxásh",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's cutting it off",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "té.",
                            [
                                {
                                    "tlingit": "té",
                                    "url": "té",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "stone; rock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take the stone away.",
                "tlingit": [
                    [
                        [
                            "5. Aax̲",
                            [
                                {
                                    "tlingit": "aax̲ axáash",
                                    "url": "aax̲ aawaxásh",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's cutting it off",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ aawatee",
                                    "url": "aax̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he picked it up off of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ yéi awsinei",
                                    "url": "aax̲ yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he picked them up off of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ kei aawayísh",
                                    "url": "aax̲ kei aawayísh",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he pulled it out of there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ axásht",
                                    "url": "aax̲ aawaxásh",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cuts if off (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ agateech",
                                    "url": "aax̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he picks it up off of it (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ aawaxásh",
                                    "url": "aax̲ aawaxásh",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cut it off",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "natí",
                            [
                                {
                                    "tlingit": "Aadé natí!",
                                    "url": "aadé aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Carry it there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yéi yee natí!",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be that way!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ natí!",
                                    "url": "du x̲'éix̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed it to him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du yáx̲ yee natí!",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be like him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ at natí!",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Tleiyéi yéi yee natí!",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be still!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲ánx' yéi yee natí!",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all stay with him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "té.",
                            [
                                {
                                    "tlingit": "té",
                                    "url": "té",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "stone; rock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Carry the stone over there.",
                "tlingit": [
                    [
                        [
                            "6. Yóode",
                            [
                                {
                                    "tlingit": "yóode",
                                    "url": "yóot~",
                                    "tags": [
                                        "Adverb",
                                        "allative"
                                    ],
                                    "english": "away, off (to someplace indefinite)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóode",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative",
                                        "allative"
                                    ],
                                    "english": "toward yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "natí",
                            [
                                {
                                    "tlingit": "Aadé natí!",
                                    "url": "aadé aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Carry it there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yéi yee natí!",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be that way!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ natí!",
                                    "url": "du x̲'éix̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed it to him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du yáx̲ yee natí!",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be like him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ at natí!",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Tleiyéi yéi yee natí!",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all be still!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲ánx' yéi yee natí!",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all stay with him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "té.",
                            [
                                {
                                    "tlingit": "té",
                                    "url": "té",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "stone; rock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Here are a few examples:"
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "1. tí - general object, usually small"
                ]
            },
            {
                "type": "ex",
                "english": "Bring the stone here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tí",
                            [
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan tí!",
                                    "url": "yan aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "té.",
                            [
                                {
                                    "tlingit": "té",
                                    "url": "té",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "stone; rock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Bring the book here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tí",
                            [
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan tí!",
                                    "url": "yan aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'.",
                            [
                                {
                                    "tlingit": "x'úx'",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "2. katí - small, round object"
                ]
            },
            {
                "type": "ex",
                "english": "Bring the dime here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "katí",
                            [
                                {
                                    "tlingit": "Yan katí!",
                                    "url": "yan akaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet katí!",
                                    "url": "du jeet akaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gút.",
                            [
                                {
                                    "tlingit": "gút",
                                    "url": "gút",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dime",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Bring the apple here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "katí",
                            [
                                {
                                    "tlingit": "Yan katí!",
                                    "url": "yan akaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet katí!",
                                    "url": "du jeet akaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'áax'.",
                            [
                                {
                                    "tlingit": "x'áax'",
                                    "url": "x'áax'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "apple; crabapple",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "3. satí - frame-like object; object with internal parts"
                ]
            },
            {
                "type": "ex",
                "english": "Bring the belt here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "satí",
                            [
                                {
                                    "tlingit": "Yan satí!",
                                    "url": "yan awsitée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séek.",
                            [
                                {
                                    "tlingit": "séek",
                                    "url": "séek",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "belt",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Bring the chair here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "satí",
                            [
                                {
                                    "tlingit": "Yan satí!",
                                    "url": "yan awsitée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káayag̲ijeit.",
                            [
                                {
                                    "tlingit": "káayag̲ijeit",
                                    "url": "káayag̲ijeit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "chair",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "satí",
                            [
                                {
                                    "tlingit": "Yan satí!",
                                    "url": "yan awsitée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káayak̲ijeit.",
                            [
                                {
                                    "tlingit": "káayak̲ijeit",
                                    "url": "káayag̲ijeit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "chair",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "20_20",
                        "J"
                    ],
                    [
                        "20_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Bring the walkman here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "satí",
                            [
                                {
                                    "tlingit": "Yan satí!",
                                    "url": "yan awsitée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "walkman.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "4. k(a)satí - round, frame-like object"
                ]
            },
            {
                "type": "ex",
                "english": "Bring the bracelet here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kasatí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kées.",
                            [
                                {
                                    "tlingit": "kées",
                                    "url": "kées",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bracelet",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "5. jikatí - coiled rope or yarn; skein, hank or coil of rope or yarn"
                ]
            },
            {
                "type": "ex",
                "english": "Bring the rope here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jikatí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tíx'.",
                            [
                                {
                                    "tlingit": "tíx'",
                                    "url": "tíx'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rope",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_24",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Bring the yarn here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jikatí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kakéin.",
                            [
                                {
                                    "tlingit": "kakéin",
                                    "url": "kakéin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yarn; wool",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "6. jiksatí - uncoiled rope or yarn; a piece of rope or yarn"
                ]
            },
            {
                "type": "ex",
                "english": "Bring the rope here..",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jiksatí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tíx'.",
                            [
                                {
                                    "tlingit": "tíx'",
                                    "url": "tíx'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rope",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_26",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Bring the yarn here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jiksatí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kakéin.",
                            [
                                {
                                    "tlingit": "kakéin",
                                    "url": "kakéin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yarn; wool",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_27",
                        "N"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "7. tán - empty container"
                ]
            },
            {
                "type": "ex",
                "english": "Bring the (empty) cup here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tán",
                            [
                                {
                                    "tlingit": "át tán",
                                    "url": "át tán",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's sitting there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Át tán!",
                                    "url": "át aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Carry it there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan tán!",
                                    "url": "yan aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲ux̲ tán!",
                                    "url": "k̲ux̲ aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Return it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tán!",
                                    "url": "du jeet aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gúx'aa.",
                            [
                                {
                                    "tlingit": "gúx'aa",
                                    "url": "gúx'aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "cup; can",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_28",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Bring the drum here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tán",
                            [
                                {
                                    "tlingit": "át tán",
                                    "url": "át tán",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's sitting there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Át tán!",
                                    "url": "át aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Carry it there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan tán!",
                                    "url": "yan aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲ux̲ tán!",
                                    "url": "k̲ux̲ aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Return it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tán!",
                                    "url": "du jeet aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gaaw.",
                            [
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "time",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bell",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "drum",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_29",
                        "N"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "8. satán - pole-like object"
                ]
            },
            {
                "type": "ex",
                "english": "Bring the rifle here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "satán",
                            [
                                {
                                    "tlingit": "Yan satán!",
                                    "url": "yan awsitán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet satán!",
                                    "url": "du jeet awsitán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "óonaa.",
                            [
                                {
                                    "tlingit": "óonaa",
                                    "url": "óonaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "gun, rifle",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_30",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Bring the broom here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "satán",
                            [
                                {
                                    "tlingit": "Yan satán!",
                                    "url": "yan awsitán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet satán!",
                                    "url": "du jeet awsitán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "xít'aa.",
                            [
                                {
                                    "tlingit": "xít'aa",
                                    "url": "xít'aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "broom; brush",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_31",
                        "N"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "9. kasatán - pole-like object (smaller)"
                ]
            },
            {
                "type": "ex",
                "english": "Bring the pencil here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kasatán",
                            [
                                {
                                    "tlingit": "Yan kasatán!",
                                    "url": "yan akawsitán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kooxéedaa.",
                            [
                                {
                                    "tlingit": "kooxéedaa",
                                    "url": "kooxéedaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pencil; pen; brush",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_32",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Bring the crayon here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kasatán",
                            [
                                {
                                    "tlingit": "Yan kasatán!",
                                    "url": "yan akawsitán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "crayon.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_33",
                        "N"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "10. sanú - living creature, usually carried in arms"
                ]
            },
            {
                "type": "ex",
                "english": "Bring me the cat.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sanú",
                            [
                                {
                                    "tlingit": "Kei sanú!",
                                    "url": "kei awsinúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lift him/her/it up!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan sanú!",
                                    "url": "yan awsinúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put him/her/it down!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dóosh.",
                            [
                                {
                                    "tlingit": "dóosh",
                                    "url": "dóosh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "cat",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_34",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Bring me the baby.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sanú",
                            [
                                {
                                    "tlingit": "Kei sanú!",
                                    "url": "kei awsinúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lift him/her/it up!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan sanú!",
                                    "url": "yan awsinúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put him/her/it down!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "atk'átsk'u.",
                            [
                                {
                                    "tlingit": "atk'átsk'u",
                                    "url": "atk'átsk'u",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "child",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sanú",
                            [
                                {
                                    "tlingit": "Kei sanú!",
                                    "url": "kei awsinúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lift him/her/it up!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan sanú!",
                                    "url": "yan awsinúk",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put him/her/it down!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "atk'iyátsk'u.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "20_35",
                        "N"
                    ],
                    [
                        "20_36",
                        "J"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "11. sa.ín - full container; container with contents"
                ]
            },
            {
                "type": "ex",
                "english": "Bring the (full) cup here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sa.ín",
                            [
                                {
                                    "tlingit": "Át sa.ín!",
                                    "url": "át awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Carry it there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan sa.ín!",
                                    "url": "yan awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Neil sa.ín!",
                                    "url": "neil awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet sa.ín!",
                                    "url": "du jeet awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gúx'aa.",
                            [
                                {
                                    "tlingit": "gúx'aa",
                                    "url": "gúx'aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "cup; can",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_37",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Bring the berries here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sa.ín",
                            [
                                {
                                    "tlingit": "Át sa.ín!",
                                    "url": "át awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Carry it there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan sa.ín!",
                                    "url": "yan awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Neil sa.ín!",
                                    "url": "neil awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet sa.ín!",
                                    "url": "du jeet awsi.ín",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tléik̲w.",
                            [
                                {
                                    "tlingit": "tléik̲w",
                                    "url": "tléik̲w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "berry, berries",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_38",
                        "J"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "12. áx̲ - fabric; cloth"
                ]
            },
            {
                "type": "ex",
                "english": "Bring the towel here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áx̲",
                            [
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ goot",
                                    "url": "át uwagút",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it goes there (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ shee",
                                    "url": "át uwashée",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he touches it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ xeex",
                                    "url": "át uwaxíx",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it falls on it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ aleet",
                                    "url": "át aawalít",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he throws it to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ ataan",
                                    "url": "át aawatán",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he carries it there (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ x'aak",
                                    "url": "át uwax'ák",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it swims underwater to it (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jig̲wéinaa.",
                            [
                                {
                                    "tlingit": "jig̲wéinaa",
                                    "url": "jig̲wéinaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "towel, hand towel",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_39",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Bring the shirt here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áx̲",
                            [
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ goot",
                                    "url": "át uwagút",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it goes there (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ shee",
                                    "url": "át uwashée",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he touches it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ xeex",
                                    "url": "át uwaxíx",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it falls on it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ aleet",
                                    "url": "át aawalít",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he throws it to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ ataan",
                                    "url": "át aawatán",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he carries it there (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ x'aak",
                                    "url": "át uwax'ák",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it swims underwater to it (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k'oodás'.",
                            [
                                {
                                    "tlingit": "k'oodás'",
                                    "url": "k'oodás'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shirt",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_40",
                        "J"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "13. yéi sané - plural objects in general"
                ]
            },
            {
                "type": "text",
                "english": "In general, this over-rides all other categories if the object is plural."
            },
            {
                "type": "ex",
                "english": "Bring the [X] (plural items) here.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi sané",
                            [
                                {
                                    "tlingit": "Du jeet yéi sané!",
                                    "url": "du jeet yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give them to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "Yan sané!",
                                    "url": "yan awsinéi",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Finish it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "[X].",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "saní",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "[X].",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "20_41",
                        "N"
                    ],
                    [
                        "20_42",
                        "J"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "14. kajél - handle all of something"
                ]
            },
            {
                "type": "text",
                "english": "In general, this over-rides all other categories if \"all\" is meant."
            },
            {
                "type": "ex",
                "english": "Bring (all) the [X] here. (usually plural)",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kajél",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "[X].",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_43",
                        "N"
                    ]
                ]
            },
            {
                "type": "!ex",
                "english": "",
                "tlingit": [
                    "15. satá - dead animal"
                ]
            },
            {
                "type": "ex",
                "english": "Bring the fish.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "satá",
                            [
                                {
                                    "tlingit": "Satá!",
                                    "url": "awsitáa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Steam it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲áat.",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_44",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Bring the deer.",
                "tlingit": [
                    [
                        [
                            "Haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "satá",
                            [
                                {
                                    "tlingit": "Satá!",
                                    "url": "awsitáa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Steam it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲uwakaan.",
                            [
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "g̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "k̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "20_45",
                        "N"
                    ]
                ]
            }
        ]
    },
    "21": {
        "title": "Commands IIl: Opening and closing things",
        "content": [
            {
                "type": "text",
                "english": "Tlingit verbs of opening and closing vary according to the shape of the object in question, the direction involved, and the nature of the action (hinged, sliding, tubular, etc.)."
            },
            {
                "type": "ex",
                "english": "We will open this container of wisdom.",
                "tlingit": [
                    [
                        [
                            "Héide",
                            [
                                {
                                    "tlingit": "héide",
                                    "url": "hé",
                                    "tags": [
                                        "Demonstrative",
                                        "allative"
                                    ],
                                    "english": "over this way",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shugax̲tootáan",
                            [],
                            []
                        ],
                        [
                            "yáa",
                            [
                                {
                                    "tlingit": "yáa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaak̲usgé",
                            [],
                            []
                        ],
                        [
                            "daakeit.",
                            [
                                {
                                    "tlingit": "daakeit",
                                    "url": "daakeit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "container for it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Open the box.",
                "tlingit": [
                    [
                        [
                            "A",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yanáatx̲",
                            [],
                            []
                        ],
                        [
                            "gataan",
                            [
                                {
                                    "tlingit": "Gataan!",
                                    "url": "aawataan",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Carry it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲óok.",
                            [
                                {
                                    "tlingit": "k̲óok",
                                    "url": "k̲óok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "box",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Open the pot.",
                "tlingit": [
                    [
                        [
                            "A",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yanáatx̲",
                            [],
                            []
                        ],
                        [
                            "gataan",
                            [
                                {
                                    "tlingit": "Gataan!",
                                    "url": "aawataan",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Carry it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲'wátl.",
                            [
                                {
                                    "tlingit": "k̲'wátl",
                                    "url": "k̲'wátl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pot, cooking pot",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Close the box.",
                "tlingit": [
                    [
                        [
                            "A yanáax̲",
                            [
                                {
                                    "tlingit": "a yanáax̲ at wootaan",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he covered it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yanáax̲ at g̲atánch",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he covers it (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yanáax̲ yei at tánch",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he covers it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A yanáax̲ at g̲ataan!",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Cover it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A yanáax̲ at g̲aytaan!",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all cover it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yanáax̲ yei at gug̲atáan",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will cover it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl a yanáax̲ yei at eetánjik̲!",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't cover it!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "g̲ataan",
                            [
                                {
                                    "tlingit": "Yaax̲ g̲ataan!",
                                    "url": "yaax̲ aawataan",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Carry it aboard!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A yanáax̲ at g̲ataan!",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Cover it!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲óok.",
                            [
                                {
                                    "tlingit": "k̲óok",
                                    "url": "k̲óok",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "box",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Close the pot; cover the pot.",
                "tlingit": [
                    [
                        [
                            "A yanáax̲",
                            [
                                {
                                    "tlingit": "a yanáax̲ at wootaan",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he covered it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yanáax̲ at g̲atánch",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he covers it (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yanáax̲ yei at tánch",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he covers it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A yanáax̲ at g̲ataan!",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Cover it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A yanáax̲ at g̲aytaan!",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all cover it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yanáax̲ yei at gug̲atáan",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will cover it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl a yanáax̲ yei at eetánjik̲!",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't cover it!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "g̲ataan",
                            [
                                {
                                    "tlingit": "Yaax̲ g̲ataan!",
                                    "url": "yaax̲ aawataan",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Carry it aboard!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A yanáax̲ at g̲ataan!",
                                    "url": "a yanáax̲ at wootaan",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Cover it!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲'wátl.",
                            [
                                {
                                    "tlingit": "k̲'wátl",
                                    "url": "k̲'wátl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pot, cooking pot",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Open the door.",
                "tlingit": [
                    [
                        [
                            "Héide",
                            [
                                {
                                    "tlingit": "héide",
                                    "url": "hé",
                                    "tags": [
                                        "Demonstrative",
                                        "allative"
                                    ],
                                    "english": "over this way",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shunataan",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'aháat.",
                            [
                                {
                                    "tlingit": "x̲'aháat",
                                    "url": "x̲'aháat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "door",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "A",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'éitx̲",
                            [],
                            []
                        ],
                        [
                            "shuwután",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'aháat.",
                            [
                                {
                                    "tlingit": "x̲'aháat",
                                    "url": "x̲'aháat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "door",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "21_6",
                        "J"
                    ],
                    [
                        "21_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Close the door.",
                "tlingit": [
                    [
                        [
                            "X̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "tán",
                            [
                                {
                                    "tlingit": "át tán",
                                    "url": "át tán",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's sitting there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Át tán!",
                                    "url": "át aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Carry it there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan tán!",
                                    "url": "yan aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲ux̲ tán!",
                                    "url": "k̲ux̲ aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Return it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tán!",
                                    "url": "du jeet aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'aháat.",
                            [
                                {
                                    "tlingit": "x̲'aháat",
                                    "url": "x̲'aháat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "door",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "X̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "shután",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'aháat.",
                            [
                                {
                                    "tlingit": "x̲'aháat",
                                    "url": "x̲'aháat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "door",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "A x̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit yawdzi.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kissed me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit woos.áaych",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "shután",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'aháat.",
                            [
                                {
                                    "tlingit": "x̲'aháat",
                                    "url": "x̲'aháat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "door",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "21_8",
                        "J"
                    ],
                    [
                        "21_9",
                        "N"
                    ],
                    [
                        "21_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Open the (sliding) door.",
                "tlingit": [
                    [
                        [
                            "A",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éitx̲",
                            [
                                {
                                    "tlingit": "k̲aa x̲'éitx̲ kashxeedí",
                                    "url": "k̲aa x̲'éidáx̲ kashxeedí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "secretary (stenographer)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wuyísh",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'aháat.",
                            [
                                {
                                    "tlingit": "x̲'aháat",
                                    "url": "x̲'aháat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "door",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Open the (sliding) window.",
                "tlingit": [
                    [
                        [
                            "A",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éitx̲",
                            [
                                {
                                    "tlingit": "k̲aa x̲'éitx̲ kashxeedí",
                                    "url": "k̲aa x̲'éidáx̲ kashxeedí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "secretary (stenographer)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wuyísh",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Close the (sliding) door.",
                "tlingit": [
                    [
                        [
                            "A x̲'éix̲",
                            [
                                {
                                    "tlingit": "a x̲'éix̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "at its mouth",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "I x̲'éix̲ nadá!",
                                    "url": "du x̲'éix̲ woodaa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Get used to (the flavor, pronunciation of) it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ natí!",
                                    "url": "du x̲'éix̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed it to him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éix̲ yas.aa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ sa.aax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ at teex̲",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feeds him/her/it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éix̲ aklatéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locks it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "wuyísh",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'aháat.",
                            [
                                {
                                    "tlingit": "x̲'aháat",
                                    "url": "x̲'aháat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "door",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Close the (sliding) window.",
                "tlingit": [
                    [
                        [
                            "A x̲'éix̲",
                            [
                                {
                                    "tlingit": "a x̲'éix̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "at its mouth",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "I x̲'éix̲ nadá!",
                                    "url": "du x̲'éix̲ woodaa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Get used to (the flavor, pronunciation of) it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ natí!",
                                    "url": "du x̲'éix̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed it to him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éix̲ yas.aa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ sa.aax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ at teex̲",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feeds him/her/it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éix̲ aklatéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locks it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "wuyísh",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Windows (if on hinge or crank):"
            },
            {
                "type": "ex",
                "english": "Open the window.",
                "tlingit": [
                    [
                        [
                            "Héide",
                            [
                                {
                                    "tlingit": "héide",
                                    "url": "hé",
                                    "tags": [
                                        "Demonstrative",
                                        "allative"
                                    ],
                                    "english": "over this way",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shunataan",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "A",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éitx̲",
                            [
                                {
                                    "tlingit": "k̲aa x̲'éitx̲ kashxeedí",
                                    "url": "k̲aa x̲'éidáx̲ kashxeedí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "secretary (stenographer)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "shuwután",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "21_15",
                        "N"
                    ],
                    [
                        "21_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Close the window.",
                "tlingit": [
                    [
                        [
                            "X̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "tán",
                            [
                                {
                                    "tlingit": "át tán",
                                    "url": "át tán",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's sitting there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Át tán!",
                                    "url": "át aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Carry it there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Yan tán!",
                                    "url": "yan aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "K̲ux̲ tán!",
                                    "url": "k̲ux̲ aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Return it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tán!",
                                    "url": "du jeet aawatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "X̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éit yisa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all listen to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "shután",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "A x̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit yawdzi.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kissed me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit woos.áaych",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "shután",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "21_17",
                        "N"
                    ],
                    [
                        "21_18",
                        "J"
                    ],
                    [
                        "21_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Windows (if sliding in a sash):"
            },
            {
                "type": "ex",
                "english": "Open the window (pull / push up).",
                "tlingit": [
                    [
                        [
                            "Kei yeesh",
                            [
                                {
                                    "tlingit": "Aax̲ kei yeesh!",
                                    "url": "aax̲ kei aawayísh",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Pull it out of there!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Close the window (pull down).",
                "tlingit": [
                    [
                        [
                            "A x̲'éix̲",
                            [
                                {
                                    "tlingit": "a x̲'éix̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "at its mouth",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "I x̲'éix̲ nadá!",
                                    "url": "du x̲'éix̲ woodaa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Get used to (the flavor, pronunciation of) it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ natí!",
                                    "url": "du x̲'éix̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed it to him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éix̲ yas.aa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ sa.aax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ at teex̲",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feeds him/her/it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éix̲ aklatéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locks it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "g̲ayeesh",
                            [
                                {
                                    "tlingit": "A káx̲ g̲ayeesh!",
                                    "url": "a káx̲ aawayeesh",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Pull it over him/her/it!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Áx̲",
                            [
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ goot",
                                    "url": "át uwagút",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it goes there (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ shee",
                                    "url": "át uwashée",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he touches it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ xeex",
                                    "url": "át uwaxíx",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it falls on it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ aleet",
                                    "url": "át aawalít",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he throws it to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ ataan",
                                    "url": "át aawatán",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he carries it there (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ x'aak",
                                    "url": "át uwax'ák",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it swims underwater to it (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "g̲ayeesh",
                            [
                                {
                                    "tlingit": "A káx̲ g̲ayeesh!",
                                    "url": "a káx̲ aawayeesh",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Pull it over him/her/it!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "21_21",
                        "N"
                    ],
                    [
                        "21_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Open the book. [singular]",
                "tlingit": [
                    [
                        [
                            "Wóoshdáx̲",
                            [],
                            []
                        ],
                        [
                            "shunal.aat",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'.",
                            [
                                {
                                    "tlingit": "x'úx'",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Open your book. [singular]",
                "tlingit": [
                    [
                        [
                            "Wóoshdáx̲",
                            [],
                            []
                        ],
                        [
                            "shunal.aat",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'u.",
                            [
                                {
                                    "tlingit": "du x'úx'u",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_24",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Open your books. [plural]",
                "tlingit": [
                    [
                        [
                            "Wóoshdáx̲",
                            [],
                            []
                        ],
                        [
                            "shunayla.aat",
                            [],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'u.",
                            [
                                {
                                    "tlingit": "du x'úx'u",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wóoshdáx̲",
                            [],
                            []
                        ],
                        [
                            "shinayla.aat",
                            [],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'u.",
                            [
                                {
                                    "tlingit": "du x'úx'u",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "21_25",
                        "N"
                    ],
                    [
                        "21_26",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Close the book. [singular]",
                "tlingit": [
                    [
                        [
                            "Woosh",
                            [
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yát",
                            [
                                {
                                    "tlingit": "a yát",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on the face of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "shula.á",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'.",
                            [
                                {
                                    "tlingit": "x'úx'",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_27",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Close your book. [singular]",
                "tlingit": [
                    [
                        [
                            "Woosh",
                            [
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yát",
                            [
                                {
                                    "tlingit": "a yát",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on the face of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "shula.á",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'u.",
                            [
                                {
                                    "tlingit": "du x'úx'u",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_28",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Close the books. [plural]",
                "tlingit": [
                    [
                        [
                            "Woosh",
                            [
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yát",
                            [
                                {
                                    "tlingit": "a yát",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on the face of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "shiyla.á",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'.",
                            [
                                {
                                    "tlingit": "x'úx'",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Woosh",
                            [
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yát",
                            [
                                {
                                    "tlingit": "a yát",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on the face of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "shuyla.á",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'.",
                            [
                                {
                                    "tlingit": "x'úx'",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "21_29",
                        "N"
                    ],
                    [
                        "21_30",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Close the book. [singular]",
                "tlingit": [
                    [
                        [
                            "Wóosht",
                            [],
                            []
                        ],
                        [
                            "shula.á",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'.",
                            [
                                {
                                    "tlingit": "x'úx'",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_31",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Close your books. [plural]",
                "tlingit": [
                    [
                        [
                            "Wóosht",
                            [],
                            []
                        ],
                        [
                            "shuyla.",
                            [],
                            []
                        ],
                        [
                            "á",
                            [
                                {
                                    "tlingit": "á",
                                    "url": "á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "it; that place, time, reason, fact",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "á",
                                    "url": "á2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[puts focus on preceding phrase]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'u.",
                            [
                                {
                                    "tlingit": "du x'úx'u",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wóosht",
                            [],
                            []
                        ],
                        [
                            "shiyla.á",
                            [],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x'úx'u.",
                            [
                                {
                                    "tlingit": "du x'úx'u",
                                    "url": "x'úx'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its paper; book, magazine, newspaper; letter, mail",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "21_32",
                        "J"
                    ],
                    [
                        "21_33",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Open the curtain. [singular]",
                "tlingit": [
                    [
                        [
                            "Wóoshdáx̲",
                            [],
                            []
                        ],
                        [
                            "yakoollíl'",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í kas'ísaa.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í kas'ísaa",
                                    "url": "x̲aawaag̲éi kas'ísayi",
                                    "tags": [
                                        "Noun",
                                        "T"
                                    ],
                                    "english": "window curtain",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_34",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Open the curtain. [plural]",
                "tlingit": [
                    [
                        [
                            "Wóoshdáx̲",
                            [],
                            []
                        ],
                        [
                            "yakaylalíl'",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í kas'ísaa.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í kas'ísaa",
                                    "url": "x̲aawaag̲éi kas'ísayi",
                                    "tags": [
                                        "Noun",
                                        "T"
                                    ],
                                    "english": "window curtain",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_35",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Close the curtain. [singular]",
                "tlingit": [
                    [
                        [
                            "Wóosht",
                            [],
                            []
                        ],
                        [
                            "yaklalíl'",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í kas'ísaa.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í kas'ísaa",
                                    "url": "x̲aawaag̲éi kas'ísayi",
                                    "tags": [
                                        "Noun",
                                        "T"
                                    ],
                                    "english": "window curtain",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "Wóoshde",
                            [],
                            []
                        ],
                        [
                            "yaklalíl'",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í kas'ísaa.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í kas'ísaa",
                                    "url": "x̲aawaag̲éi kas'ísayi",
                                    "tags": [
                                        "Noun",
                                        "T"
                                    ],
                                    "english": "window curtain",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "Wóosht",
                            [],
                            []
                        ],
                        [
                            "yakalalíl'",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲i",
                            [],
                            []
                        ],
                        [
                            "kas'ísaa.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í kas'ísaa",
                                    "url": "x̲aawaag̲éi kas'ísayi",
                                    "tags": [
                                        "Noun",
                                        "T"
                                    ],
                                    "english": "window curtain",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wóosht",
                            [],
                            []
                        ],
                        [
                            "yakoollíl'",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í kas'ísaa.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í kas'ísaa",
                                    "url": "x̲aawaag̲éi kas'ísayi",
                                    "tags": [
                                        "Noun",
                                        "T"
                                    ],
                                    "english": "window curtain",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "21_36",
                        "J"
                    ],
                    [
                        "21_37",
                        "N"
                    ],
                    [
                        "21_38",
                        "J"
                    ],
                    [
                        "21_39",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Close the curtain. [plural]",
                "tlingit": [
                    [
                        [
                            "Wóosht",
                            [],
                            []
                        ],
                        [
                            "yakaylalíl'",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲aawaag̲í kas'ísaa.",
                            [
                                {
                                    "tlingit": "x̲aawaag̲í kas'ísaa",
                                    "url": "x̲aawaag̲éi kas'ísayi",
                                    "tags": [
                                        "Noun",
                                        "T"
                                    ],
                                    "english": "window curtain",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲éi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲aawaag̲í",
                                    "url": "x̲aawaag̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "window",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_40",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Turn on the light.",
                "tlingit": [
                    [
                        [
                            "A kát",
                            [
                                {
                                    "tlingit": "a kát",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a kát kawdigán",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát wujix̲ín",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it fell on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát akawlik̲áa",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sewed it on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du kát shutí!",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Blame it on him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát seiwax'ák̲w",
                                    "url": "a kát seiwax'ák̲w",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he forgot",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát sh wudligás'",
                                    "url": "a kát sh wudligás'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it leapt on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát adatéen",
                                    "url": "kát adatéen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is wearing it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "shuksatán",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'eenáa.",
                            [
                                {
                                    "tlingit": "s'eenáa",
                                    "url": "s'eenáa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "lamp",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_41",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Turn on the TV.",
                "tlingit": [
                    [
                        [
                            "A kát",
                            [
                                {
                                    "tlingit": "a kát",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a kát kawdigán",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát wujix̲ín",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it fell on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát akawlik̲áa",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sewed it on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du kát shutí!",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Blame it on him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát seiwax'ák̲w",
                                    "url": "a kát seiwax'ák̲w",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he forgot",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát sh wudligás'",
                                    "url": "a kát sh wudligás'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it leapt on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát adatéen",
                                    "url": "kát adatéen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is wearing it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "shuksatí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "TV.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "A kát",
                            [
                                {
                                    "tlingit": "a kát",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a kát kawdigán",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát wujix̲ín",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it fell on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát akawlik̲áa",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sewed it on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du kát shutí!",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Blame it on him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát seiwax'ák̲w",
                                    "url": "a kát seiwax'ák̲w",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he forgot",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát sh wudligás'",
                                    "url": "a kát sh wudligás'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it leapt on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát adatéen",
                                    "url": "kát adatéen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is wearing it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "shukatí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "TV.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "A kát",
                            [
                                {
                                    "tlingit": "a kát",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "on it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a kát kawdigán",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it's shining on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát wujix̲ín",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it fell on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát akawlik̲áa",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sewed it on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du kát shutí!",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Blame it on him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát seiwax'ák̲w",
                                    "url": "a kát seiwax'ák̲w",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he forgot",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a kát sh wudligás'",
                                    "url": "a kát sh wudligás'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it leapt on it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kát adatéen",
                                    "url": "kát adatéen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is wearing it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "katí",
                            [
                                {
                                    "tlingit": "Yan katí!",
                                    "url": "yan akaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet katí!",
                                    "url": "du jeet akaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "TV.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "A",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éitx̲",
                            [
                                {
                                    "tlingit": "k̲aa x̲'éitx̲ kashxeedí",
                                    "url": "k̲aa x̲'éidáx̲ kashxeedí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "secretary (stenographer)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yakootí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "TV.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "21_42",
                        "J"
                    ],
                    [
                        "21_43",
                        "N"
                    ],
                    [
                        "21_44",
                        "J"
                    ],
                    [
                        "21_45",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Turn on the water.",
                "tlingit": [
                    [
                        [
                            "A",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'eitx̲",
                            [],
                            []
                        ],
                        [
                            "yakootí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "héen.",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_46",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Turn off the light.",
                "tlingit": [
                    [
                        [
                            "Yaklakís'",
                            [
                                {
                                    "tlingit": "Yaklakís'!",
                                    "url": "ayakawlikís'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it out!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'eenáa.",
                            [
                                {
                                    "tlingit": "s'eenáa",
                                    "url": "s'eenáa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "lamp",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "21_47",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Turn off the water.",
                "tlingit": [
                    [
                        [
                            "A x̲'éit",
                            [
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Du x̲'éit sa.áx̲!",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Listen to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit yawdzi.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kissed me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Ax̲ x̲'éit yees.á!",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Kiss me!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éit wusi.áx̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he's listening to him/her; s/he listens to him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit woos.áaych",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "kalatéx̲'",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "héen.",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "A x̲'éit kalatíx̲'",
                            [
                                {
                                    "tlingit": "X̲'éit kalatíx̲'!",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Lock it!",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a x̲'éit",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "at/to its mouth",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du x̲'éit us.aax̲ch",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Át kalatíx̲'!",
                                    "url": "át akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Screw it on it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éit yawdzi.áa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kissed me",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "x̲'éit akawlitíx̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locked it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "X̲'éit kaylatíx̲'!",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all lock it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éit akooltéex̲'ch",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "s/he locks it (every time)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "héen.",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "A x̲'éix̲",
                            [
                                {
                                    "tlingit": "a x̲'éix̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "at its mouth",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "I x̲'éix̲ nadá!",
                                    "url": "du x̲'éix̲ woodaa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Get used to (the flavor, pronunciation of) it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ natí!",
                                    "url": "du x̲'éix̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed it to him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éix̲ yas.aa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ sa.aax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ at teex̲",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feeds him/her/it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éix̲ aklatéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locks it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yakootí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "héen.",
                            [
                                {
                                    "tlingit": "héen",
                                    "url": "héen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "water",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "héen",
                                    "url": "héen2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "river, stream, creek",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "21_48",
                        "J"
                    ],
                    [
                        "21_49",
                        "N"
                    ],
                    [
                        "21_50",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Turn off the TV.",
                "tlingit": [
                    [
                        [
                            "A x̲'éix̲",
                            [
                                {
                                    "tlingit": "a x̲'éix̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "at its mouth",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "I x̲'éix̲ nadá!",
                                    "url": "du x̲'éix̲ woodaa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Get used to (the flavor, pronunciation of) it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du x̲'éix̲ natí!",
                                    "url": "du x̲'éix̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Feed it to him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ax̲ x̲'éix̲ yas.aa",
                                    "url": "ax̲ x̲'éit yawdzi.áa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he kisses me (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ sa.aax̲",
                                    "url": "du x̲'éit wusi.áx̲",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he listens to him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ at teex̲",
                                    "url": "du x̲'éix̲ at wootee",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he feeds him/her/it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲'éix̲ aklatéex̲'",
                                    "url": "x̲'éit akawlitíx̲'",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he locks it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yakootí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "TV.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "A kaax̲",
                            [
                                {
                                    "tlingit": "a kaax̲",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "ablative"
                                    ],
                                    "english": "off of it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "shuksatí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "TV.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "A kaax̲",
                            [
                                {
                                    "tlingit": "a kaax̲",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "ablative"
                                    ],
                                    "english": "off of it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yakootí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "TV.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "21_51",
                        "N"
                    ],
                    [
                        "21_52",
                        "J"
                    ],
                    [
                        "21_53",
                        "N"
                    ]
                ]
            }
        ]
    },
    "22": {
        "title": "Commands IV: Putting on and taking off clothes",
        "content": [
            {
                "type": "text",
                "english": "Verbs of putting on and taking off clothing are very complicated in Tlingit, as each phrase depends on the shape of the clothing, how it is put on, the direction of the motion, and the body part involved. You will also encounter regional, community, personal, and situational variations. Here are some examples:"
            },
            {
                "type": "header",
                "english": "Hat and hat-like objects (shaa + tí)"
            },
            {
                "type": "ex",
                "english": "Put on the hat. [singular]",
                "tlingit": [
                    [
                        [
                            "Sháax̲",
                            [],
                            []
                        ],
                        [
                            "g̲idatí",
                            [
                                {
                                    "tlingit": "Káx̲ g̲idatí!",
                                    "url": "káx̲ awditee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it on!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'áaxw.",
                            [
                                {
                                    "tlingit": "s'áaxw",
                                    "url": "s'áaxw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hat",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Put on your hat. [singular]",
                "tlingit": [
                    [
                        [
                            "Sháax̲",
                            [],
                            []
                        ],
                        [
                            "g̲idatí",
                            [
                                {
                                    "tlingit": "Káx̲ g̲idatí!",
                                    "url": "káx̲ awditee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it on!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'áaxu.",
                            [
                                {
                                    "tlingit": "du s'áaxu",
                                    "url": "s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its hat",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du kéi dak̲inji s'áaxu",
                                    "url": "kéi dak̲inji s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its umbrella",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Put the hat on your head.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháax̲",
                            [],
                            []
                        ],
                        [
                            "g̲atí",
                            [
                                {
                                    "tlingit": "Áx̲ g̲atí!",
                                    "url": "áx̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Install it there!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'áaxw.",
                            [
                                {
                                    "tlingit": "s'áaxw",
                                    "url": "s'áaxw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hat",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Put your hat on your head.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháax̲",
                            [],
                            []
                        ],
                        [
                            "g̲atí",
                            [
                                {
                                    "tlingit": "Áx̲ g̲atí!",
                                    "url": "áx̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Install it there!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'áaxu.",
                            [
                                {
                                    "tlingit": "du s'áaxu",
                                    "url": "s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its hat",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du kéi dak̲inji s'áaxu",
                                    "url": "kéi dak̲inji s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its umbrella",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Take off your hat. [singular]",
                "tlingit": [
                    [
                        [
                            "Sháadáx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "idatí",
                            [
                                {
                                    "tlingit": "káx̲ awditee",
                                    "url": "káx̲ awditee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he put it on",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'áaxu.",
                            [
                                {
                                    "tlingit": "du s'áaxu",
                                    "url": "s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its hat",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du kéi dak̲inji s'áaxu",
                                    "url": "kéi dak̲inji s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its umbrella",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháadáx̲",
                            [],
                            []
                        ],
                        [
                            "kei tí",
                            [
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Yan tí!",
                                    "url": "yan aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'áaxu.",
                            [
                                {
                                    "tlingit": "du s'áaxu",
                                    "url": "s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its hat",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du kéi dak̲inji s'áaxu",
                                    "url": "kéi dak̲inji s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its umbrella",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháatx̲",
                            [],
                            []
                        ],
                        [
                            "kei tí",
                            [
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Yan tí!",
                                    "url": "yan aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'áaxu.",
                            [
                                {
                                    "tlingit": "du s'áaxu",
                                    "url": "s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its hat",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du kéi dak̲inji s'áaxu",
                                    "url": "kéi dak̲inji s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its umbrella",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_5",
                        "N"
                    ],
                    [
                        "22_6",
                        "J"
                    ],
                    [
                        "22_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Put on your hats. [plural]",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháax̲",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲aysané",
                            [],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'áaxu.",
                            [
                                {
                                    "tlingit": "du s'áaxu",
                                    "url": "s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its hat",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du kéi dak̲inji s'áaxu",
                                    "url": "kéi dak̲inji s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its umbrella",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháax̲",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲aysaní",
                            [],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'áaxu.",
                            [
                                {
                                    "tlingit": "du s'áaxu",
                                    "url": "s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its hat",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du kéi dak̲inji s'áaxu",
                                    "url": "kéi dak̲inji s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its umbrella",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_8",
                        "J"
                    ],
                    [
                        "22_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Take off your hats. [plural]",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháatx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeiysané",
                            [],
                            []
                        ],
                        [
                            "yeé",
                            [],
                            []
                        ],
                        [
                            "s'áaxu.",
                            [
                                {
                                    "tlingit": "du s'áaxu",
                                    "url": "s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its hat",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du kéi dak̲inji s'áaxu",
                                    "url": "kéi dak̲inji s'áaxw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its umbrella",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "(You) put the hat on his/her head.",
                "tlingit": [
                    [
                        [
                            "Du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháax̲",
                            [],
                            []
                        ],
                        [
                            "g̲atí",
                            [
                                {
                                    "tlingit": "Áx̲ g̲atí!",
                                    "url": "áx̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Install it there!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'áaxw.",
                            [
                                {
                                    "tlingit": "s'áaxw",
                                    "url": "s'áaxw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hat",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "header",
                "english": "Coat and coat-like objects (naa + ti)"
            },
            {
                "type": "ex",
                "english": "Put on your coat.",
                "tlingit": [
                    [
                        [
                            "Náax̲",
                            [],
                            []
                        ],
                        [
                            "g̲idatí",
                            [
                                {
                                    "tlingit": "Káx̲ g̲idatí!",
                                    "url": "káx̲ awditee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it on!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kinaak.ádi.",
                            [
                                {
                                    "tlingit": "du kinaak.ádi",
                                    "url": "kinaak.át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its coat, overcoat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Náax̲",
                            [],
                            []
                        ],
                        [
                            "g̲idatí",
                            [
                                {
                                    "tlingit": "Káx̲ g̲idatí!",
                                    "url": "káx̲ awditee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it on!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kinaa.ádi.",
                            [
                                {
                                    "tlingit": "du kinaa.ádi",
                                    "url": "kinaa.át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its coat, overcoat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Káx̲ g̲idatí",
                            [
                                {
                                    "tlingit": "Káx̲ g̲idatí!",
                                    "url": "káx̲ awditee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it on!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Káx̲ g̲aydatí!",
                                    "url": "káx̲ awditee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Pl"
                                    ],
                                    "english": "You all put it on!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A káx̲ g̲ayeesh!",
                                    "url": "a káx̲ aawayeesh",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Pull it over him/her/it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a káx̲ kadagaan",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it shines on it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A káx̲ kalaník!",
                                    "url": "a káx̲ akawliník",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Talk him/her out of it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du káx̲ ashutee",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he blames it on him/her (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at káx̲ adéli",
                                    "url": "at káx̲ adéli",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "guard, watchman",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a káx̲ aklak̲éix̲",
                                    "url": "a kát akawlik̲áa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sews it on it (regularly)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kinaak.ádi.",
                            [
                                {
                                    "tlingit": "du kinaak.ádi",
                                    "url": "kinaak.át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its coat, overcoat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "náax̲",
                            [],
                            []
                        ],
                        [
                            "g̲atí",
                            [
                                {
                                    "tlingit": "Áx̲ g̲atí!",
                                    "url": "áx̲ aawatee",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Install it there!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kinaak.ádi.",
                            [
                                {
                                    "tlingit": "du kinaak.ádi",
                                    "url": "kinaak.át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its coat, overcoat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_12",
                        "J"
                    ],
                    [
                        "22_13",
                        "N"
                    ],
                    [
                        "22_14",
                        "J"
                    ],
                    [
                        "22_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off your coat.",
                "tlingit": [
                    [
                        [
                            "Náatx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "idatí",
                            [
                                {
                                    "tlingit": "káx̲ awditee",
                                    "url": "káx̲ awditee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he put it on",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kinaak.ádi.",
                            [
                                {
                                    "tlingit": "du kinaak.ádi",
                                    "url": "kinaak.át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its coat, overcoat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "náatx̲",
                            [],
                            []
                        ],
                        [
                            "kei tí",
                            [
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Yan tí!",
                                    "url": "yan aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kinaak.ádi.",
                            [
                                {
                                    "tlingit": "du kinaak.ádi",
                                    "url": "kinaak.át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its coat, overcoat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Kaax̲",
                            [
                                {
                                    "tlingit": "a kaax̲",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "ablative"
                                    ],
                                    "english": "off of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "idati",
                            [
                                {
                                    "tlingit": "káx̲ awditee",
                                    "url": "káx̲ awditee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he put it on",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "káx̲ awditee",
                                    "url": "káx̲ awditee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he put it on",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kinaak.ádi.",
                            [
                                {
                                    "tlingit": "du kinaak.ádi",
                                    "url": "kinaak.át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its coat, overcoat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_16",
                        "J"
                    ],
                    [
                        "22_17",
                        "N"
                    ],
                    [
                        "22_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Some nouns that use this verb theme and noun phrase:"
            },
            {
                "type": "ex",
                "english": "dress",
                "tlingit": [
                    [
                        [
                            "l'aak",
                            [
                                {
                                    "tlingit": "l'aak",
                                    "url": "l'aak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dress",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "vest",
                "tlingit": [
                    [
                        [
                            "l.uljíni",
                            [
                                {
                                    "tlingit": "l.uljíni",
                                    "url": "l.uljíni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "vest; sleeveless top",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "shirt",
                "tlingit": [
                    [
                        [
                            "k'oodás'",
                            [
                                {
                                    "tlingit": "k'oodás'",
                                    "url": "k'oodás'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shirt",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "goodás'",
                            [
                                {
                                    "tlingit": "goodás'",
                                    "url": "k'oodás'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shirt",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_21",
                        "N"
                    ],
                    [
                        "22_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "sweater",
                "tlingit": [
                    [
                        [
                            "kakéin k'oodás'",
                            [
                                {
                                    "tlingit": "kakéin k'oodás'",
                                    "url": "kakéin k'oodás'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sweater",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "k'oodás'",
                                    "url": "k'oodás'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shirt",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "kakéin",
                                    "url": "kakéin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yarn; wool",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "coat",
                "tlingit": [
                    [
                        [
                            "kinaak.át",
                            [
                                {
                                    "tlingit": "kinaak.át",
                                    "url": "kinaa.át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "coat, overcoat",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "kinaak.át",
                                    "url": "kinaak.át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "coat, overcoat",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "kinaa.át",
                            [
                                {
                                    "tlingit": "kinaa.át",
                                    "url": "kinaa.át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "coat, overcoat",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "kinaa.át",
                                    "url": "kinaak.át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "coat, overcoat",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_24",
                        "J"
                    ],
                    [
                        "22_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "your dress",
                "tlingit": [
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "l'aagí",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "l'aakí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "(rarely)"
                ],
                "ids": [
                    [
                        "22_26",
                        "J"
                    ],
                    [
                        "22_27",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "your vest",
                "tlingit": [
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "l.uljíni",
                            [
                                {
                                    "tlingit": "l.uljíni",
                                    "url": "l.uljíni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "vest; sleeveless top",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_28",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "your shirt",
                "tlingit": [
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k'oodás'i",
                            [
                                {
                                    "tlingit": "du k'oodás'i",
                                    "url": "k'oodás'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shirt",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du kakéin k'oodás'i",
                                    "url": "kakéin k'oodás'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its sweater",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du doonyaax̲ k'oodás'i",
                                    "url": "doonyaax̲ k'oodás'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its undershirt",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "goodás'i",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_29",
                        "N"
                    ],
                    [
                        "22_30",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "your sweater",
                "tlingit": [
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kakéin k'oodás'i",
                            [
                                {
                                    "tlingit": "du kakéin k'oodás'i",
                                    "url": "kakéin k'oodás'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its sweater",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du k'oodás'i",
                                    "url": "k'oodás'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shirt",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du doonyaax̲ k'oodás'i",
                                    "url": "doonyaax̲ k'oodás'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its undershirt",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kakéin",
                                    "url": "kakéin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "yarn; wool",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_31",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "your coat",
                "tlingit": [
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kinaak.ádi",
                            [
                                {
                                    "tlingit": "du kinaak.ádi",
                                    "url": "kinaak.át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its coat, overcoat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kinaa.ádi",
                            [
                                {
                                    "tlingit": "du kinaa.ádi",
                                    "url": "kinaa.át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its coat, overcoat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_32",
                        "J"
                    ],
                    [
                        "22_33",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "K'oodás' (goodás' for some speakers) is a generic term and can include shirt, coat, blazer, halibut jacket, and ceremonial shirts and tunics."
            },
            {
                "type": "header",
                "english": "Shoe and shoe-like objects (x̲'oos + yék̲)"
            },
            {
                "type": "ex",
                "english": "Put on your shoe(s).",
                "tlingit": [
                    [
                        [
                            "X̲'oosx̲",
                            [],
                            []
                        ],
                        [
                            "idayék̲",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeli.",
                            [
                                {
                                    "tlingit": "du téeli",
                                    "url": "téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shoe(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "X̲'oosx̲",
                            [],
                            []
                        ],
                        [
                            "idayík̲",
                            [
                                {
                                    "tlingit": "X̲'usx̲ idayík̲!",
                                    "url": "x̲'usdé awdiyík̲",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put them on!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeli.",
                            [
                                {
                                    "tlingit": "du téeli",
                                    "url": "téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shoe(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "X̲'oosdé",
                            [],
                            []
                        ],
                        [
                            "idayék̲",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeli.",
                            [
                                {
                                    "tlingit": "du téeli",
                                    "url": "téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shoe(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'oosx̲",
                            [],
                            []
                        ],
                        [
                            "yék̲",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeli.",
                            [
                                {
                                    "tlingit": "du téeli",
                                    "url": "téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shoe(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'oosdé",
                            [],
                            []
                        ],
                        [
                            "yék̲",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeli.",
                            [
                                {
                                    "tlingit": "du téeli",
                                    "url": "téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shoe(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_34",
                        "N"
                    ],
                    [
                        "22_35",
                        "J"
                    ],
                    [
                        "22_36",
                        "N"
                    ],
                    [
                        "22_37",
                        "J"
                    ],
                    [
                        "22_38",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off your shoe(s).",
                "tlingit": [
                    [
                        [
                            "X̲'oosdáx̲",
                            [],
                            []
                        ],
                        [
                            "idayék̲",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeli.",
                            [
                                {
                                    "tlingit": "du téeli",
                                    "url": "téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shoe(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'oosdáx̲",
                            [],
                            []
                        ],
                        [
                            "yék̲",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "téeli.",
                            [
                                {
                                    "tlingit": "du téeli",
                                    "url": "téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shoe(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_39",
                        "J"
                    ],
                    [
                        "22_40",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Some nouns that use this verb theme and noun phrase:"
            },
            {
                "type": "ex",
                "english": "socks",
                "tlingit": [
                    [
                        [
                            "l'éex'wán",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_41",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "rubber boots",
                "tlingit": [
                    [
                        [
                            "s'el'téel",
                            [
                                {
                                    "tlingit": "s'él'",
                                    "url": "s'él'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rubber",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "téel",
                                    "url": "téel",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shoe(s)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_42",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "moccasins",
                "tlingit": [
                    [
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "xáshti",
                            [],
                            []
                        ],
                        [
                            "téel",
                            [
                                {
                                    "tlingit": "téel",
                                    "url": "téel",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shoe(s)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "at xáshdi téel",
                            [
                                {
                                    "tlingit": "at xáshdi téel",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "moccasins",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "et xáshdi téel",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "C"
                                    ],
                                    "english": "moccasins",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at xáshdi x'óow",
                                    "url": "at xáshdi x'óow",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "blanket sewn from scraps of hide",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at kagux̲lashée",
                                    "url": "at kawlishee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will compose a song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shéeyi",
                                    "url": "at shéeyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "singer",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shéex'i",
                                    "url": "at shéex'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "singers, choir",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲'éeshi",
                                    "url": "at x̲'éeshi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dry fish",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at káx̲ adéli",
                                    "url": "at káx̲ adéli",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "guard, watchman",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_43",
                        "J"
                    ],
                    [
                        "22_44",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "pants",
                "tlingit": [
                    [
                        [
                            "tuk̲'atáal",
                            [
                                {
                                    "tlingit": "tuk̲'atáal",
                                    "url": "tuk̲'atáal",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pants, trousers",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_45",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "your socks",
                "tlingit": [
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "l'éex'wáni",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_46",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "your boots",
                "tlingit": [
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'el'téeli",
                            [
                                {
                                    "tlingit": "s'él'",
                                    "url": "s'él'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "rubber",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "téel",
                                    "url": "téel",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shoe(s)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_47",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "your moccasins",
                "tlingit": [
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "xáshti",
                            [],
                            []
                        ],
                        [
                            "téeli",
                            [
                                {
                                    "tlingit": "du téeli",
                                    "url": "téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its shoe(s)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at xáshdi téeli",
                            [
                                {
                                    "tlingit": "du at xáshdi téeli",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its moccasins",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "at xáshdi téel",
                                    "url": "at xáshdi téel",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "moccasins",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at xáshdi x'óow",
                                    "url": "at xáshdi x'óow",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "blanket sewn from scraps of hide",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shéeyi",
                                    "url": "at shéeyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "singer",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shéex'i",
                                    "url": "at shéex'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "singers, choir",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at káx̲ adéli",
                                    "url": "at káx̲ adéli",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "guard, watchman",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at kagux̲lashée",
                                    "url": "at kawlishee",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will compose a song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shax̲ishdi dzáas",
                                    "url": "at shax̲ishdi dzáas",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "spear for clubbing",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_48",
                        "N"
                    ],
                    [
                        "22_49",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "your pants",
                "tlingit": [
                    [
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuk̲'atáali",
                            [
                                {
                                    "tlingit": "du tuk̲'atáali",
                                    "url": "tuk̲'atáal",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its pants, trousers",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_50",
                        "N"
                    ]
                ]
            },
            {
                "type": "header",
                "english": "Gloves and glove-like objects (ji + yék̲)"
            },
            {
                "type": "ex",
                "english": "Put on your gloves/mittens.",
                "tlingit": [
                    [
                        [
                            "Jikáx̲",
                            [],
                            []
                        ],
                        [
                            "idayék̲",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tsáax'i.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jikáx̲",
                            [],
                            []
                        ],
                        [
                            "yék̲",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tsáax'i.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_51",
                        "J"
                    ],
                    [
                        "22_52",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off your gloves/mittens.",
                "tlingit": [
                    [
                        [
                            "Jikaatx̲",
                            [],
                            []
                        ],
                        [
                            "idayék̲",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tsáax'i.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jikaatx̲",
                            [],
                            []
                        ],
                        [
                            "yék̲",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tsáax'i.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_53",
                        "J"
                    ],
                    [
                        "22_54",
                        "N"
                    ]
                ]
            },
            {
                "type": "header",
                "english": "Blankets and blanket-like objects (x'óo)"
            },
            {
                "type": "ex",
                "english": "Put on the Chilkat blanket.",
                "tlingit": [
                    [
                        [
                            "Idax'óo",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "naaxein.",
                            [
                                {
                                    "tlingit": "naaxein",
                                    "url": "naaxein",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Chilkat blanket",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_55",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Put on the button blanket.",
                "tlingit": [
                    [
                        [
                            "Idax'óo",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲aayuka.oot'",
                            [],
                            []
                        ],
                        [
                            "x'óow.",
                            [
                                {
                                    "tlingit": "x'óow",
                                    "url": "x'óow",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "blanket; robe",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_56",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Put on the shawl.",
                "tlingit": [
                    [
                        [
                            "Eesx'óo",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sadaat'aay.",
                            [
                                {
                                    "tlingit": "sadaat'aay",
                                    "url": "sadaat'aay",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "neck scarf; kerchief",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_57",
                        "J"
                    ]
                ]
            },
            {
                "type": "header",
                "english": "Headbands and headband-like objects (shaa + ka-tí)"
            },
            {
                "type": "ex",
                "english": "Put on your headband.",
                "tlingit": [
                    [
                        [
                            "Sháax̲",
                            [],
                            []
                        ],
                        [
                            "kag̲idatí",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kootáag̲ayi.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháax̲",
                            [],
                            []
                        ],
                        [
                            "kag̲atí",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kootáag̲ayi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_58",
                        "N"
                    ],
                    [
                        "22_59",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off your headband.",
                "tlingit": [
                    [
                        [
                            "Sháatx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "keedatí",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kootáag̲ayi.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháatx̲",
                            [],
                            []
                        ],
                        [
                            "kei tí",
                            [
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Yan tí!",
                                    "url": "yan aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kootáag̲ayi.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shakadóox'u",
                            [
                                {
                                    "tlingit": "shakadóox'u",
                                    "url": "a shá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Headband that is tied",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "shakadóox'u",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun"
                                    ],
                                    "english": "Headband that is tied",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "shakadóox'u",
                                    "url": "akaawadúx'",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "Headband that is tied",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sháatx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jikeedatí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_60",
                        "N"
                    ],
                    [
                        "22_61",
                        "J"
                    ],
                    [
                        "22_62",
                        "N"
                    ]
                ]
            },
            {
                "type": "header",
                "english": "Bibs, bib-like objects, and koogéinaa (sei + ka-tí)"
            },
            {
                "type": "ex",
                "english": "Put on the koogeinaa.",
                "tlingit": [
                    [
                        [
                            "Séix̲",
                            [],
                            []
                        ],
                        [
                            "kag̲idatí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "koogéinaa.",
                            [
                                {
                                    "tlingit": "koogéinaa",
                                    "url": "koogéinaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sash (worn over shoulder)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séix̲",
                            [],
                            []
                        ],
                        [
                            "kag̲atí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "koogéinaa.",
                            [
                                {
                                    "tlingit": "koogéinaa",
                                    "url": "koogéinaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sash (worn over shoulder)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_63",
                        "J"
                    ],
                    [
                        "22_64",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Put on the ceremonial bib.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séix̲",
                            [],
                            []
                        ],
                        [
                            "kag̲atí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "saka",
                            [],
                            []
                        ],
                        [
                            "át.",
                            [
                                {
                                    "tlingit": "át",
                                    "url": "át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "thing",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_65",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off the koogeinaa.",
                "tlingit": [
                    [
                        [
                            "Séitx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "keedatí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "koogéinaa.",
                            [
                                {
                                    "tlingit": "koogéinaa",
                                    "url": "koogéinaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sash (worn over shoulder)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séitx̲",
                            [],
                            []
                        ],
                        [
                            "kei tí",
                            [
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Yan tí!",
                                    "url": "yan aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "koogéinaa.",
                            [
                                {
                                    "tlingit": "koogéinaa",
                                    "url": "koogéinaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sash (worn over shoulder)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_66",
                        "N"
                    ],
                    [
                        "22_67",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Put the (baby) bib on him/her.",
                "tlingit": [
                    [
                        [
                            "Du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séix̲",
                            [],
                            []
                        ],
                        [
                            "kag̲atí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'axíl'k",
                            [],
                            []
                        ],
                        [
                            "yinaa",
                            [
                                {
                                    "tlingit": "a yinaa",
                                    "url": "a yinaa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "in its way; blocking its way; acting as a shield for it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "háadi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_68",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Put the (ceremonial) bib on him/her.",
                "tlingit": [
                    [
                        [
                            "Du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séix̲",
                            [],
                            []
                        ],
                        [
                            "kag̲ati",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "saka.át.",
                            [
                                {
                                    "tlingit": "saka.át",
                                    "url": "saka.át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "necktie",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_69",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take the (baby) bib off him/her.",
                "tlingit": [
                    [
                        [
                            "Du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séitx̲",
                            [],
                            []
                        ],
                        [
                            "kei tí",
                            [
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Yan tí!",
                                    "url": "yan aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'axíl'k",
                            [],
                            []
                        ],
                        [
                            "yinaa",
                            [
                                {
                                    "tlingit": "a yinaa",
                                    "url": "a yinaa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "in its way; blocking its way; acting as a shield for it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "háadi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_70",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take the (ceremonial) bib off him/her.",
                "tlingit": [
                    [
                        [
                            "Du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séitx̲",
                            [],
                            []
                        ],
                        [
                            "kei tí",
                            [
                                {
                                    "tlingit": "Kei tí!",
                                    "url": "kei aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Bring it out!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Neil tí!",
                                    "url": "neil aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Take it inside!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Yan tí!",
                                    "url": "yan aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du jeet tí!",
                                    "url": "du jeet aawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give it to him/her!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "saka.át.",
                            [
                                {
                                    "tlingit": "saka.át",
                                    "url": "saka.át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "necktie",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_71",
                        "J"
                    ]
                ]
            },
            {
                "type": "header",
                "english": "Shakee.át (shaa + sa-tí)"
            },
            {
                "type": "ex",
                "english": "Put on the shakee.át.",
                "tlingit": [
                    [
                        [
                            "Sháax̲",
                            [],
                            []
                        ],
                        [
                            "g̲eestí",
                            [
                                {
                                    "tlingit": "awsitee",
                                    "url": "awsitee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he carried it (solid, complex object)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shakee.át.",
                            [
                                {
                                    "tlingit": "shakee.át",
                                    "url": "shakee.át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "headdress, dance hat",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháax̲",
                            [],
                            []
                        ],
                        [
                            "g̲astí",
                            [
                                {
                                    "tlingit": "awsitee",
                                    "url": "awsitee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he carried it (solid, complex object)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shakee.át.",
                            [
                                {
                                    "tlingit": "shakee.át",
                                    "url": "shakee.át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "headdress, dance hat",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_72",
                        "N"
                    ],
                    [
                        "22_73",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off the shakee.át.",
                "tlingit": [
                    [
                        [
                            "Sháatx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eestí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shakee.át.",
                            [
                                {
                                    "tlingit": "shakee.át",
                                    "url": "shakee.át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "headdress, dance hat",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sháatx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "satí",
                            [
                                {
                                    "tlingit": "Yan satí!",
                                    "url": "yan awsitée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put it down!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shakee.át.",
                            [
                                {
                                    "tlingit": "shakee.át",
                                    "url": "shakee.át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "headdress, dance hat",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_74",
                        "N"
                    ],
                    [
                        "22_75",
                        "J"
                    ]
                ]
            },
            {
                "type": "header",
                "english": "Belts and belt-like objects (káa + sa-tí)"
            },
            {
                "type": "ex",
                "english": "Put on your belt.",
                "tlingit": [
                    [
                        [
                            "Káx̲",
                            [
                                {
                                    "tlingit": "a káx̲",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the surface of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "káx̲ awditee",
                                    "url": "káx̲ awditee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he put it on",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at káx̲ adéli",
                                    "url": "at káx̲ adéli",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "guard, watchman",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a káx̲ kadagaan",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it shines on it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a káx̲ ishx̲een",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it falls on it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A káx̲ kalaník!",
                                    "url": "a káx̲ akawliník",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Talk him/her out of it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du káx̲ ashutee",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he blames it on him/her (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "g̲eestí",
                            [
                                {
                                    "tlingit": "awsitee",
                                    "url": "awsitee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he carried it (solid, complex object)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séegi.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "káx̲",
                            [
                                {
                                    "tlingit": "a káx̲",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the surface of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "káx̲ awditee",
                                    "url": "káx̲ awditee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he put it on",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at káx̲ adéli",
                                    "url": "at káx̲ adéli",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "guard, watchman",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a káx̲ kadagaan",
                                    "url": "a kát kawdigán",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it shines on it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a káx̲ ishx̲een",
                                    "url": "a kát wujix̲ín",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it falls on it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A káx̲ kalaník!",
                                    "url": "a káx̲ akawliník",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Talk him/her out of it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du káx̲ ashutee",
                                    "url": "du kát ashuwatée",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he blames it on him/her (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "g̲astí",
                            [
                                {
                                    "tlingit": "awsitee",
                                    "url": "awsitee",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he carried it (solid, complex object)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séegi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_76",
                        "N"
                    ],
                    [
                        "22_77",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off your belt.",
                "tlingit": [
                    [
                        [
                            "Kaax̲",
                            [
                                {
                                    "tlingit": "a kaax̲",
                                    "url": "a ká",
                                    "tags": [
                                        "RelationalNoun",
                                        "ablative"
                                    ],
                                    "english": "off of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "eestí",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séegi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_78",
                        "N"
                    ]
                ]
            },
            {
                "type": "header",
                "english": "Bracelets and bracelet-like objects (ji + ka-sa-ti)"
            },
            {
                "type": "ex",
                "english": "Put on your bracelet.",
                "tlingit": [
                    [
                        [
                            "Jikáx̲",
                            [],
                            []
                        ],
                        [
                            "kag̲eestí",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kéesi.",
                            [
                                {
                                    "tlingit": "du kéesi",
                                    "url": "kées",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its bracelet",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jikáx̲",
                            [],
                            []
                        ],
                        [
                            "kax̲satí",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kéesi.",
                            [
                                {
                                    "tlingit": "du kéesi",
                                    "url": "kées",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its bracelet",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_79",
                        "J"
                    ],
                    [
                        "22_80",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off the bracelet.",
                "tlingit": [
                    [
                        [
                            "Jikaatx̲",
                            [],
                            []
                        ],
                        [
                            "kag̲eestí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kées.",
                            [
                                {
                                    "tlingit": "kées",
                                    "url": "kées",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bracelet",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_81",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off your bracelet.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jikaatx̲",
                            [],
                            []
                        ],
                        [
                            "kax̲satí",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kéesi.",
                            [
                                {
                                    "tlingit": "du kéesi",
                                    "url": "kées",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its bracelet",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_82",
                        "N"
                    ]
                ]
            },
            {
                "type": "header",
                "english": "Necklaces and necklace-like objects (sei + ji-ka-sa-ti)"
            },
            {
                "type": "ex",
                "english": "Put on the necklace.",
                "tlingit": [
                    [
                        [
                            "Séix̲",
                            [],
                            []
                        ],
                        [
                            "jikag̲eestí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "seit.",
                            [
                                {
                                    "tlingit": "seit",
                                    "url": "seit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "necklace",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_83",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Put on your necklace.",
                "tlingit": [
                    [
                        [
                            "Séix̲",
                            [],
                            []
                        ],
                        [
                            "jikag̲eestí",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "seidí.",
                            [
                                {
                                    "tlingit": "seit",
                                    "url": "seit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "necklace",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_84",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off the necklace.",
                "tlingit": [
                    [
                        [
                            "Séitx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jikeestí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "seit.",
                            [
                                {
                                    "tlingit": "seit",
                                    "url": "seit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "necklace",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_85",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off your necklace.",
                "tlingit": [
                    [
                        [
                            "Séitx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jikeestí",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "seidí.",
                            [
                                {
                                    "tlingit": "seit",
                                    "url": "seit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "necklace",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_86",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off the necklace.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séitx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jiksatí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "seit.",
                            [
                                {
                                    "tlingit": "seit",
                                    "url": "seit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "necklace",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_87",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off your necklace.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séitx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jiksatí",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "seidí.",
                            [
                                {
                                    "tlingit": "seit",
                                    "url": "seit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "necklace",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_88",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off the necklace.",
                "tlingit": [
                    [
                        [
                            "Séidáx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jikeestí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "seit.",
                            [
                                {
                                    "tlingit": "seit",
                                    "url": "seit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "necklace",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_89",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off your necklace.",
                "tlingit": [
                    [
                        [
                            "Séidáx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jikeestí",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "seidí.",
                            [
                                {
                                    "tlingit": "seit",
                                    "url": "seit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "necklace",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_90",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off the necklace.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séidáx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jiksatí",
                            [],
                            []
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "seit.",
                            [
                                {
                                    "tlingit": "seit",
                                    "url": "seit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "necklace",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_91",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Take off your necklace.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séidáx̲",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jiksatí",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "seidí.",
                            [
                                {
                                    "tlingit": "seit",
                                    "url": "seit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "necklace",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_92",
                        "N"
                    ]
                ]
            },
            {
                "type": "header",
                "english": "Scarf; necktie (sei + ka-dúx')"
            },
            {
                "type": "ex",
                "english": "Put on (tie on) your scarf.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séi",
                            [],
                            []
                        ],
                        [
                            "kadúx'",
                            [
                                {
                                    "tlingit": "Kadúx'!",
                                    "url": "akaawadúx'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Tie it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sadat'aayí.",
                            [
                                {
                                    "tlingit": "sadaat'aay",
                                    "url": "sadaat'aay",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "neck scarf; kerchief",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_93",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Put on (tie on) your necktie.",
                "tlingit": [
                    [
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "séi",
                            [],
                            []
                        ],
                        [
                            "kadúx'",
                            [
                                {
                                    "tlingit": "Kadúx'!",
                                    "url": "akaawadúx'",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Tie it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "saka.ádi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "22_94",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "For removing a scarf other verb stems may be used:"
            },
            {
                "type": "ex",
                "english": "Take off your scarf.",
                "tlingit": [
                    [
                        [
                            "Séitx̲",
                            [],
                            []
                        ],
                        [
                            "nida.aax̲",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sadat'aayí.",
                            [
                                {
                                    "tlingit": "sadaat'aay",
                                    "url": "sadaat'aay",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "neck scarf; kerchief",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Séitx̲",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "neesné",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sadat'aayí.",
                            [
                                {
                                    "tlingit": "sadaat'aay",
                                    "url": "sadaat'aay",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "neck scarf; kerchief",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_95",
                        "J"
                    ],
                    [
                        "22_96",
                        "N"
                    ]
                ]
            },
            {
                "type": "header",
                "english": "Eyeglasses a. (waak̲ + ka-sa-ti) b. (waak̲ + yéi sa-né) c. (waak̲ + yéi ka-sa-né)"
            },
            {
                "type": "text",
                "english": "There are several permissible alternatives for handling eyeglasses, depending on how the object is perceived: as single, framelike object (a); as general plural objects (b); as round, plural objects (c)."
            },
            {
                "type": "ex",
                "english": "Put on your glasses.",
                "tlingit": [
                    [
                        [
                            "a.",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "waak̲náx̲",
                            [],
                            []
                        ],
                        [
                            "kax̲satí",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wak̲dáanayi.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "b. I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "waak̲náx̲",
                            [],
                            []
                        ],
                        [
                            "kei yéi sané",
                            [
                                {
                                    "tlingit": "Du jeet yéi sané!",
                                    "url": "du jeet yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Give them to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ kei yéi asneich",
                                    "url": "aax̲ yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he picks them up off of it (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "Yan sané!",
                                    "url": "yan awsinéi",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Finish it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wak̲dáanayi.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "c.",
                            [],
                            []
                        ],
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "waak̲náx̲",
                            [],
                            []
                        ],
                        [
                            "kei yéi ksané",
                            [
                                {
                                    "tlingit": "Yan yéi ksané!",
                                    "url": "yan yéi akawsinéi",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put them down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aax̲ yéi ksané!",
                                    "url": "aax̲ yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Pick them up off of it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aax̲ kei yéi asneich",
                                    "url": "aax̲ yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he picks them up off of it (regularly)",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wak̲dáanayi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_97",
                        "J"
                    ],
                    [
                        "22_98",
                        "N"
                    ],
                    [
                        "22_99",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Take off your glasses.",
                "tlingit": [
                    [
                        [
                            "a.",
                            [
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "waak̲dáx̲",
                            [],
                            []
                        ],
                        [
                            "kax̲satí",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wak̲dáanayi.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "b. I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "waak̲dáx̲",
                            [],
                            []
                        ],
                        [
                            "yéi nasné",
                            [
                                {
                                    "tlingit": "Yéi nasné!",
                                    "url": "yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Fix it!",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "Wooch yáx̲ nasné!",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Straighten it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Du wak̲shiyeex' yéi nasné!",
                                    "url": "du wak̲shiyeex' yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Demonstrate it to him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wak̲dáanayi.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "c.",
                            [],
                            []
                        ],
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wak̲kaatx̲",
                            [],
                            []
                        ],
                        [
                            "yéi ksané",
                            [
                                {
                                    "tlingit": "Yan yéi ksané!",
                                    "url": "yan yéi akawsinéi",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put them down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aax̲ yéi ksané!",
                                    "url": "aax̲ yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Pick them up off of it!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wak̲dáanayi.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "c.",
                            [],
                            []
                        ],
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wak̲kaax̲",
                            [],
                            []
                        ],
                        [
                            "yéi ksané",
                            [
                                {
                                    "tlingit": "Yan yéi ksané!",
                                    "url": "yan yéi akawsinéi",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Put them down!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aax̲ yéi ksané!",
                                    "url": "aax̲ yéi awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Pick them up off of it!",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wak̲dáanayi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "",
                    "",
                    ""
                ],
                "ids": [
                    [
                        "22_100",
                        "N"
                    ],
                    [
                        "22_101",
                        "J"
                    ],
                    [
                        "22_102",
                        "N"
                    ],
                    [
                        "22_103",
                        "J"
                    ]
                ]
            }
        ]
    },
    "23": {
        "title": "Hunting",
        "content": [
            {
                "type": "ex",
                "english": "We're going to go mountain goat hunting.",
                "tlingit": [
                    [
                        [
                            "Jánwu",
                            [
                                {
                                    "tlingit": "jánwu",
                                    "url": "jánwu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "mountain goat",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "l'óon",
                            [],
                            []
                        ],
                        [
                            "gax̲too.áat.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "Note. In traditional Tlingit culture, it was taboo or bad manners to talk about future plans for hunting animals. Thus, the future in this case is grammatically correct, but may not be socially correct for some elders."
            },
            {
                "type": "ex",
                "english": "s it a black bear you shot?",
                "tlingit": [
                    [
                        [
                            "S'eek",
                            [
                                {
                                    "tlingit": "s'eek",
                                    "url": "s'eek",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "black bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ák.wé",
                            [
                                {
                                    "tlingit": "ák.wé",
                                    "url": "ák.wé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iya.ún?",
                            [
                                {
                                    "tlingit": "aawa.ún",
                                    "url": "aawa.ún",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he shot it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They went seal hunting.",
                "tlingit": [
                    [
                        [
                            "Tsaa",
                            [
                                {
                                    "tlingit": "tsaa",
                                    "url": "tsaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hair seal",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "l'óon",
                            [],
                            []
                        ],
                        [
                            "(áwé)",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wook̲oox̲.",
                            [
                                {
                                    "tlingit": "wook̲oox̲",
                                    "url": "wook̲oox̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he went (by motorized vehicle)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I like caribou meat.",
                "tlingit": [
                    [
                        [
                            "Watsíx",
                            [
                                {
                                    "tlingit": "watsíx",
                                    "url": "watsíx",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "caribou",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dleeyí",
                            [
                                {
                                    "tlingit": "du dleeyí",
                                    "url": "dleey",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its meat, flesh",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "(áwé)",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "A brown bear is walking over there.",
                "tlingit": [
                    [
                        [
                            "Xóots",
                            [
                                {
                                    "tlingit": "xóots",
                                    "url": "xóots",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "grizzly bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yóox̲",
                            [
                                {
                                    "tlingit": "yóox̲",
                                    "url": "yóot~",
                                    "tags": [
                                        "Adverb",
                                        "pertingent"
                                    ],
                                    "english": "away, off (to someplace indefinite)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóox̲",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative",
                                        "pertingent"
                                    ],
                                    "english": "along there yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaa nagút.",
                            [
                                {
                                    "tlingit": "yaa nagút",
                                    "url": "yaa nagút",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is walking along",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "áx̲ yaa nagút",
                                    "url": "áx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is walking along it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nagút",
                                    "url": "aadé woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is going there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nagút",
                                    "url": "át uwagút",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is going there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "daak yaa nagút",
                                    "url": "daak uwagút",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is walking into the open",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gági yaa nagút",
                                    "url": "gági uwagút",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is emerging",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a nák̲ yaa nagút",
                                    "url": "a nák̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is leaving it behind",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a ítx̲ yaa nagút",
                                    "url": "a ítx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is following it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "deer",
                "tlingit": [
                    [
                        [
                            "g̲uwakaan",
                            [
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "g̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "k̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My father shot a deer.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "g̲uwakaan",
                            [
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "g̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "k̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aawa.ún.",
                            [
                                {
                                    "tlingit": "aawa.ún",
                                    "url": "aawa.ún",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he shot it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My uncle shot two deer.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sáni",
                            [
                                {
                                    "tlingit": "du sáni",
                                    "url": "du sáni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her paternal uncle, cousin",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "déix",
                            [
                                {
                                    "tlingit": "déix̲",
                                    "url": "déix̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "g̲uwakaan",
                            [
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "g̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "k̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aawa.ún",
                            [
                                {
                                    "tlingit": "aawa.ún",
                                    "url": "aawa.ún",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he shot it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "Did you [singular] go hunting?",
                "tlingit": [
                    [
                        [
                            "Al'óon",
                            [
                                {
                                    "tlingit": "al'óon",
                                    "url": "aawal'óon",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he hunts it; s/he is hunting it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "al'óon",
                                    "url": "aawal'óon2",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hunting",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "al'óon",
                                    "url": "al'óon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hunt",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeegoot?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I went hunting.",
                "tlingit": [
                    [
                        [
                            "Al'óon",
                            [
                                {
                                    "tlingit": "al'óon",
                                    "url": "aawal'óon",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he hunts it; s/he is hunting it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "al'óon",
                                    "url": "aawal'óon2",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hunting",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "al'óon",
                                    "url": "al'óon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hunt",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲waagoot.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Did you [plural] go hunting?",
                "tlingit": [
                    [
                        [
                            "Al'óon",
                            [
                                {
                                    "tlingit": "al'óon",
                                    "url": "aawal'óon",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he hunts it; s/he is hunting it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "al'óon",
                                    "url": "aawal'óon2",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hunting",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "al'óon",
                                    "url": "al'óon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hunt",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yeey.aat?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We went hunting.",
                "tlingit": [
                    [
                        [
                            "Al'óon",
                            [
                                {
                                    "tlingit": "al'óon",
                                    "url": "aawal'óon",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he hunts it; s/he is hunting it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "al'óon",
                                    "url": "aawal'óon2",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hunting",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "al'óon",
                                    "url": "al'óon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hunt",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wutuwa.aat.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My father went hunting.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "al'óon",
                            [
                                {
                                    "tlingit": "al'óon",
                                    "url": "aawal'óon",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he hunts it; s/he is hunting it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "al'óon",
                                    "url": "aawal'óon2",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hunting",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "al'óon",
                                    "url": "al'óon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hunt",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "woogoot.",
                            [
                                {
                                    "tlingit": "át woogoot",
                                    "url": "át woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is walking around there; he/she/it walked around there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ woogoot",
                                    "url": "áx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it walked along it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲ut woogoot",
                                    "url": "k̲ut woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he got lost",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé woogoot",
                                    "url": "aadé woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it went there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaax̲ woogoot",
                                    "url": "yaax̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he went aboard",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a nák̲ woogoot",
                                    "url": "a nák̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he left it behind",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a ítx̲ woogoot",
                                    "url": "a ítx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it followed it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We went hunting together.",
                "tlingit": [
                    [
                        [
                            "Woosh",
                            [
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "een",
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "al'óon",
                            [
                                {
                                    "tlingit": "al'óon",
                                    "url": "aawal'óon",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he hunts it; s/he is hunting it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "al'óon",
                                    "url": "aawal'óon2",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is hunting",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "al'óon",
                                    "url": "al'óon",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hunt",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wutuwa.aat.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What did he get (kill)?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "aawaják̲?",
                            [
                                {
                                    "tlingit": "aawaják̲",
                                    "url": "aawaják̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he killed him/her/it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "He got (killed) a deer.",
                "tlingit": [
                    [
                        [
                            "G̲uwakaan",
                            [
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "g̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "k̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aawaják̲.",
                            [
                                {
                                    "tlingit": "aawaják̲",
                                    "url": "aawaják̲",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he killed him/her/it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What did you [singular] get?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "iyaják̲?",
                            [
                                {
                                    "tlingit": "aawaják̲",
                                    "url": "aawaják̲",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he killed him/her/it; s/he let it go",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I got a deer.",
                "tlingit": [
                    [
                        [
                            "G̲uwakaan",
                            [
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "g̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "k̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲waaják̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What did s/he shoot?",
                "tlingit": [
                    [
                        [
                            "Daa sá",
                            [
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléil daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yanees.á!",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Examine it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yoo tutánk!",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Think about it!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "(wé)",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aawa.ún?",
                            [
                                {
                                    "tlingit": "aawa.ún",
                                    "url": "aawa.ún",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he shot it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he shot a deer.",
                "tlingit": [
                    [
                        [
                            "G̲uwakaan",
                            [
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "g̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "k̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aawa.ún.",
                            [
                                {
                                    "tlingit": "aawa.ún",
                                    "url": "aawa.ún",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he shot it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What did you [singular] shoot?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "iya.ún?",
                            [
                                {
                                    "tlingit": "aawa.ún",
                                    "url": "aawa.ún",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he shot it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I shot a deer.",
                "tlingit": [
                    [
                        [
                            "G̲uwakaan",
                            [
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "g̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "k̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲waa.ún.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What did you lpl get?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "yeeyják̲?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We killed a deer.",
                "tlingit": [
                    [
                        [
                            "G̲uwakaan",
                            [
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "g̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "k̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wutuwaják̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_24",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What did you [plural] shoot?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "yeey.ún?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We shot a deer.",
                "tlingit": [
                    [
                        [
                            "G̲uwakaan",
                            [
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "g̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "k̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wutuwa.ún.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_26",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We'll eat deer meat tonight.",
                "tlingit": [
                    [
                        [
                            "Yá",
                            [
                                {
                                    "tlingit": "yá",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "this (right here)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "xáanaa",
                            [
                                {
                                    "tlingit": "xáanaa",
                                    "url": "xáanaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "evening",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲uwakaan",
                            [
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "g̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲uwakaan",
                                    "url": "k̲uwakaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "deer",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dleeyí",
                            [
                                {
                                    "tlingit": "du dleeyí",
                                    "url": "dleey",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its meat, flesh",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gax̲toox̲áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_27",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'll invite my older brother.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "húnx̲w",
                            [
                                {
                                    "tlingit": "du húnx̲w",
                                    "url": "du húnx̲w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his older brother, cousin",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "tsú",
                            [
                                {
                                    "tlingit": "tsú",
                                    "url": "tsú",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "also, too, as well",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "tsu",
                                    "url": "tsu",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "again; still; some more",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yei",
                            [
                                {
                                    "tlingit": "yei",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kk̲wa.éex'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "23_28",
                        "J"
                    ]
                ]
            }
        ]
    },
    "24": {
        "title": "Fishing, tides, and water",
        "content": [
            {
                "type": "ex",
                "english": "There were a lot of salmon there.",
                "tlingit": [
                    [
                        [
                            "Áa",
                            [
                                {
                                    "tlingit": "áa",
                                    "url": "áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "lake",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "woogei",
                            [],
                            []
                        ],
                        [
                            "x̲áat.",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are there a lot of salmon there?",
                "tlingit": [
                    [
                        [
                            "Áa",
                            [
                                {
                                    "tlingit": "áa",
                                    "url": "áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "lake",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yagéi",
                            [
                                {
                                    "tlingit": "yagéi",
                                    "url": "woogéi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "there are many",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲áat?",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_2",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are there (any) salmon there?",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ágé",
                            [
                                {
                                    "tlingit": "ágé",
                                    "url": "ágé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[focus + interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwu",
                            [],
                            []
                        ],
                        [
                            "á?",
                            [
                                {
                                    "tlingit": "á",
                                    "url": "á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "it; that place, time, reason, fact",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "á",
                                    "url": "á2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[puts focus on preceding phrase]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Are there salmon jumping?",
                "tlingit": [
                    [
                        [
                            "Kadutáan",
                            [],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲áat?",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "táani",
                            [],
                            []
                        ],
                        [
                            "ágé",
                            [
                                {
                                    "tlingit": "ágé",
                                    "url": "ágé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[focus + interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwu",
                            [],
                            []
                        ],
                        [
                            "á?",
                            [
                                {
                                    "tlingit": "á",
                                    "url": "á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "it; that place, time, reason, fact",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "á",
                                    "url": "á2",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[puts focus on preceding phrase]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "24_4",
                        "J"
                    ],
                    [
                        "24_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "The fish are here!",
                "tlingit": [
                    [
                        [
                            "Yáadu",
                            [
                                {
                                    "tlingit": "yáadu",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "locativepr"
                                    ],
                                    "english": "here is; here are",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲áat!",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "There are still some.",
                "tlingit": [
                    [
                        [
                            "Ch'a",
                            [
                                {
                                    "tlingit": "ch'a",
                                    "url": "ch'a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "the very, just",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwu",
                            [],
                            []
                        ],
                        [
                            "aa.",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "There's nothing here.",
                "tlingit": [
                    [
                        [
                            "Tléil daa sá",
                            [
                                {
                                    "tlingit": "tléil daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "tlél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yanees.á!",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Examine it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yoo tutánk!",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Think about it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa daa yaséix̲i",
                                    "url": "k̲aa daa yaséix̲i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "doctor",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yáat.",
                            [
                                {
                                    "tlingit": "yáat",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "punctual"
                                    ],
                                    "english": "this (place)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That's a dog salmon jumping.",
                "tlingit": [
                    [
                        [
                            "Téel'",
                            [
                                {
                                    "tlingit": "téel'",
                                    "url": "téel'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dog salmon; chum salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "át",
                            [
                                {
                                    "tlingit": "át",
                                    "url": "át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "thing",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wootáan.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "A fish jumped.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wootáan.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "uwatán.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "24_10",
                        "J"
                    ],
                    [
                        "24_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "There are a lot of fish jumping.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "táani",
                            [],
                            []
                        ],
                        [
                            "yagéi.",
                            [
                                {
                                    "tlingit": "yagéi",
                                    "url": "woogéi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "there are many",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "There's not much fish jumping.",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "táan",
                            [],
                            []
                        ],
                        [
                            "tlax̲",
                            [
                                {
                                    "tlingit": "tlax̲",
                                    "url": "tlax̲",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "very",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲oostí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "salmon (in general)",
                "tlingit": [
                    [
                        [
                            "x̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "king salmon (chinook)",
                "tlingit": [
                    [
                        [
                            "t'á",
                            [
                                {
                                    "tlingit": "t'á",
                                    "url": "t'á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "king salmon; chinook salmon; spring salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_15",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "coho (silver)",
                "tlingit": [
                    [
                        [
                            "l'ook",
                            [
                                {
                                    "tlingit": "l'ook",
                                    "url": "l'ook",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "coho salmon; silver salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_16",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "sockeye (red)",
                "tlingit": [
                    [
                        [
                            "g̲aat",
                            [
                                {
                                    "tlingit": "g̲aat",
                                    "url": "g̲aat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sockeye salmon; red salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_17",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "chum (dog)",
                "tlingit": [
                    [
                        [
                            "téel'",
                            [
                                {
                                    "tlingit": "téel'",
                                    "url": "téel'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dog salmon; chum salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_18",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "humpy (pink)",
                "tlingit": [
                    [
                        [
                            "cháas'",
                            [
                                {
                                    "tlingit": "cháas'",
                                    "url": "cháas'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pink salmon; humpy, humpback salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_19",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "freshwater sockeye & coho",
                "tlingit": [
                    [
                        [
                            "x̲'áakw",
                            [
                                {
                                    "tlingit": "x̲'áakw",
                                    "url": "x̲'áakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sockeye or coho salmon that has entered fresh water",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_20",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "shark",
                "tlingit": [
                    [
                        [
                            "tóos'",
                            [
                                {
                                    "tlingit": "tóos'",
                                    "url": "tóos'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "shark",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_21",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "herring",
                "tlingit": [
                    [
                        [
                            "yaaw",
                            [
                                {
                                    "tlingit": "yaaw",
                                    "url": "yaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "herring",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_22",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "halibut",
                "tlingit": [
                    [
                        [
                            "cháatl",
                            [
                                {
                                    "tlingit": "cháatl",
                                    "url": "cháatl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "halibut",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_23",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "boat",
                "tlingit": [
                    [
                        [
                            "yaakw",
                            [
                                {
                                    "tlingit": "yaakw",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boat, canoe",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_24",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "his boat",
                "tlingit": [
                    [
                        [
                            "du yaagú",
                            [
                                {
                                    "tlingit": "du yaagú",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its boat, canoe",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du s'ísaa yaagú",
                                    "url": "s'ísaa yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its sailboat",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_25",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "their boat",
                "tlingit": [
                    [
                        [
                            "has du yaagú",
                            [
                                {
                                    "tlingit": "du yaagú",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its boat, canoe",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has du",
                                    "url": "has du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "their [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du s'ísaa yaagú",
                                    "url": "s'ísaa yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its sailboat",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_26",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "seine",
                "tlingit": [
                    [
                        [
                            "g̲eiwú",
                            [
                                {
                                    "tlingit": "g̲eiwú",
                                    "url": "g̲eiwú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish net; seine net",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_27",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "seine boat",
                "tlingit": [
                    [
                        [
                            "asg̲eiwú",
                            [
                                {
                                    "tlingit": "asg̲eiwú",
                                    "url": "asg̲eiwú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "seine fisherman; seine boat",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "asg̲eiwú",
                                    "url": "awdzig̲eiwú",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is seining",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaagú",
                            [
                                {
                                    "tlingit": "du yaagú",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its boat, canoe",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du s'ísaa yaagú",
                                    "url": "s'ísaa yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its sailboat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_28",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "herring seiner",
                "tlingit": [
                    [
                        [
                            "yaaw",
                            [
                                {
                                    "tlingit": "yaaw",
                                    "url": "yaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "herring",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "asg̲eiwú",
                            [
                                {
                                    "tlingit": "asg̲eiwú",
                                    "url": "asg̲eiwú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "seine fisherman; seine boat",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "asg̲eiwú",
                                    "url": "awdzig̲eiwú",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is seining",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaagú",
                            [
                                {
                                    "tlingit": "du yaagú",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its boat, canoe",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du s'ísaa yaagú",
                                    "url": "s'ísaa yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its sailboat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "yaaw",
                            [
                                {
                                    "tlingit": "yaaw",
                                    "url": "yaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "herring",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "isg̲eiwú",
                            [
                                {
                                    "tlingit": "awdzig̲eiwú",
                                    "url": "awdzig̲eiwú",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he seined; s/he fished (with a net)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaagú",
                            [
                                {
                                    "tlingit": "du yaagú",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its boat, canoe",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du s'ísaa yaagú",
                                    "url": "s'ísaa yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its sailboat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "24_29",
                        "N"
                    ],
                    [
                        "24_30",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "halibut boat",
                "tlingit": [
                    [
                        [
                            "cháatl",
                            [
                                {
                                    "tlingit": "cháatl",
                                    "url": "cháatl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "halibut",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaagú",
                            [
                                {
                                    "tlingit": "du yaagú",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its boat, canoe",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du s'ísaa yaagú",
                                    "url": "s'ísaa yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its sailboat",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "cháatl",
                            [
                                {
                                    "tlingit": "cháatl",
                                    "url": "cháatl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "halibut",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi daanéiyi",
                            [
                                {
                                    "tlingit": "g̲aatáa yéi daanéiyi",
                                    "url": "g̲aatáa yéi daanéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trapper",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "k̲aa oox̲ yei daanéiyi",
                                    "url": "k̲aa oox̲ yei daanéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dentist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "24_31",
                        "N"
                    ],
                    [
                        "24_32",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "The boat is going along.",
                "tlingit": [
                    [
                        [
                            "Yaa nak̲úx̲",
                            [
                                {
                                    "tlingit": "yaa nak̲úx̲",
                                    "url": "wook̲oox̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going (by motorized vehicle)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa nak̲úx̲",
                                    "url": "yaa nak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going along (by boat, car)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "daak nak̲úx̲",
                                    "url": "daak uwak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going out to sea",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nak̲úx̲",
                                    "url": "aadé wook̲oox̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nak̲úx̲",
                                    "url": "át uwak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving to it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yánde yaa nak̲úx̲",
                                    "url": "yan uwak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going ashore",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daax̲ yaa nak̲úx̲",
                                    "url": "a daax̲ yaawak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving around it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a tóonáx̲ yaa nak̲úx̲",
                                    "url": "a tóonáx̲ yaawak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving through it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲ut kei nak̲úx̲",
                                    "url": "k̲ut wook̲oox̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is getting lost",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaakw.",
                            [
                                {
                                    "tlingit": "yaakw",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boat, canoe",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_33",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They're setting halibut gear.",
                "tlingit": [
                    [
                        [
                            "Cháatl",
                            [
                                {
                                    "tlingit": "cháatl",
                                    "url": "cháatl",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "halibut",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'ayee",
                            [
                                {
                                    "tlingit": "du x̲'ayee",
                                    "url": "du x̲'ayee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "ready, waiting for him/her to eat, drink; waiting for him/her to speak or finish speaking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aan x̲'ayee",
                                    "url": "aan x̲'ayee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in a town, on the streets of a town",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sheen x̲'ayee",
                                    "url": "sheen x̲'ayee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dipper (for dipping water)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "tíx'i",
                            [
                                {
                                    "tlingit": "k'óox̲' tíx'i",
                                    "url": "k'óox̲' tíx'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "leadline (of net)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du g̲ayéis' tíx'i",
                                    "url": "g̲ayéis' tíx'",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its cable",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yaa s",
                            [
                                {
                                    "tlingit": "yaa (ha)s na.át",
                                    "url": "has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa s shayandahéin",
                                    "url": "shayawdihaa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "there are getting to be a lot",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa (ha)s ayanda.át",
                                    "url": "has ayawdi.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are turning back",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ yaa (ha)s na.át",
                                    "url": "áx̲ yaa (ha)s na.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking along there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa (ha)s ashuna.át",
                                    "url": "has ashoowa.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is leading them",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa (ha)s na.át",
                                    "url": "aadé has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa (ha)s na.át",
                                    "url": "át has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking there",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yei (ha)s nak̲éen",
                                    "url": "has wook̲ee",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are sitting down",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei (ha)s nasx̲ét",
                                    "url": "has wudzix̲eit",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are multiplying",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áa kei (ha)s na.át",
                                    "url": "áa kei (ha)s uwa.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking up there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yoo (ha)s tuwli.át",
                                    "url": "a daa yoo (ha)s tuwli.át",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Pl"
                                    ],
                                    "english": "they thought about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "át yoo (ha)s ya.átk",
                                    "url": "át has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they walk around (regularly)",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "ashukanak̲úx̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_34",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We made one set.",
                "tlingit": [
                    [
                        [
                            "Tléix'",
                            [
                                {
                                    "tlingit": "tléix'",
                                    "url": "tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "dahéen",
                            [
                                {
                                    "tlingit": "déix̲ dahéen",
                                    "url": "dax̲dahéen",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "twice, two times",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "nás'k dahéen",
                                    "url": "nas'gidahéen",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "three times",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "héent",
                            [
                                {
                                    "tlingit": "Héent yatí!",
                                    "url": "héent ayaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Baptize him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "héent ayaawatée",
                                    "url": "héent ayaawatée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he baptized him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl héent yayeetéek̲!",
                                    "url": "héent ayaawatée",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't baptize him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wutuwag̲ix'.",
                            [],
                            []
                        ]
                    ],
                    [
                        [
                            "Tleidahéen",
                            [
                                {
                                    "tlingit": "tleidahéen",
                                    "url": "tleidahéen",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "once, one time",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "tleidahéen",
                                    "url": "tlex'dahéen",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "once, one time",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "héent",
                            [
                                {
                                    "tlingit": "Héent yatí!",
                                    "url": "héent ayaawatée",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Baptize him/her!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "héent ayaawatée",
                                    "url": "héent ayaawatée",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he baptized him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl héent yayeetéek̲!",
                                    "url": "héent ayaawatée",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't baptize him/her!",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "wutuwag̲íx'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "24_35",
                        "N"
                    ],
                    [
                        "24_36",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It (the set) wasn't good.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wuk'éi.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_37",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "There wasn't much in it.",
                "tlingit": [
                    [
                        [
                            "Tléil",
                            [
                                {
                                    "tlingit": "tléil",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wugei",
                            [],
                            []
                        ],
                        [
                            "a tóox'.",
                            [
                                {
                                    "tlingit": "a tóox'",
                                    "url": "a tú",
                                    "tags": [
                                        "RelationalNoun",
                                        "locative"
                                    ],
                                    "english": "in it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a tóox' at dult'ix' át",
                                    "url": "a tóo at dult'ix'x̲i át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "freezer",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_38",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It (the set) was good.",
                "tlingit": [
                    [
                        [
                            "Wook'éi.",
                            [
                                {
                                    "tlingit": "wook'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it was good; he/she/it got better",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_39",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "There was a lot in it.",
                "tlingit": [
                    [
                        [
                            "Woogei",
                            [],
                            []
                        ],
                        [
                            "a tóox'.",
                            [
                                {
                                    "tlingit": "a tóox'",
                                    "url": "a tú",
                                    "tags": [
                                        "RelationalNoun",
                                        "locative"
                                    ],
                                    "english": "in it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a tóox' at dult'ix' át",
                                    "url": "a tóo at dult'ix'x̲i át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "freezer",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a",
                                    "url": "a",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "its [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_40",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They're pulling their net in (slowly).",
                "tlingit": [
                    [
                        [
                            "Has du g̲eiwú",
                            [
                                {
                                    "tlingit": "du g̲eiwú",
                                    "url": "g̲eiwú",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its fish net; seine net",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has du",
                                    "url": "has du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "their [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "g̲eiwú",
                                    "url": "g̲eiwú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish net; seine net",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "kei s",
                            [
                                {
                                    "tlingit": "kei (ha)s nasx̲ét",
                                    "url": "has wudzix̲eit",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are multiplying",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áa kei (ha)s na.át",
                                    "url": "áa kei (ha)s uwa.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking up there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei (ha)s ashu.átch",
                                    "url": "has ashoowa.aat",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he leads them (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei (ha)s kanduk'én",
                                    "url": "kei has kawduwak'én",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they're getting ready to jump",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei (ha)s jiwlitsóow",
                                    "url": "kei (ha)s jiwlitsóow",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Pl"
                                    ],
                                    "english": "they raised their hands (plural subject)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲ut kei (ha)s na.át",
                                    "url": "k̲ut has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are getting lost",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei (ha)s g̲ax̲nastéen",
                                    "url": "has g̲ax̲wusitee",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are beginning to cry",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yei (ha)s nak̲éen",
                                    "url": "has wook̲ee",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are sitting down",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ yei (ha)s na.át",
                                    "url": "áx̲ has woo.aat",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are walking down along it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gági (ha)s .átx̲",
                                    "url": "gági has uwa.át",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they emerge (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "héide yaa (ha)s .átch",
                                    "url": "héide has yaawa.át",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they move over that way (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "g̲unéi (ha)s lugúk̲x̲",
                                    "url": "g̲unéi (ha)s loowagúk̲",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they start running (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa éede yaa (ha)s jinda.át",
                                    "url": "k̲aa éet has jiwdi.át",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they're attacking people",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "anayísh.",
                            [
                                {
                                    "tlingit": "a káx̲ yei anayísh",
                                    "url": "a káx̲ aawayeesh",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is pulling it over him/her/it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_41",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "He's pulling his net aboard (quickly).",
                "tlingit": [
                    [
                        [
                            "Du g̲eiwú",
                            [
                                {
                                    "tlingit": "du g̲eiwú",
                                    "url": "g̲eiwú",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its fish net; seine net",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "g̲eiwú",
                                    "url": "g̲eiwú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish net; seine net",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "yaakwdéi",
                            [],
                            []
                        ],
                        [
                            "yaa anax̲út'.",
                            [
                                {
                                    "tlingit": "yánde yaa anax̲út'",
                                    "url": "yan aawax̲út'",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is pulling it in",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_42",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "His boat is getting full.",
                "tlingit": [
                    [
                        [
                            "Yaa ashanalhík",
                            [
                                {
                                    "tlingit": "yaa ashanalhík",
                                    "url": "ashawlihík",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is filling it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "du yaagú.",
                            [
                                {
                                    "tlingit": "du yaagú",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its boat, canoe",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du s'ísaa yaagú",
                                    "url": "s'ísaa yaakw",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its sailboat",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_43",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "brail net",
                "tlingit": [
                    [
                        [
                            "kaxwénaa",
                            [
                                {
                                    "tlingit": "kaxwénaa",
                                    "url": "kaxwénaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dipper, scoop, ladle; brailer bag",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_44",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "(The boat) is getting full (from brailing).",
                "tlingit": [
                    [
                        [
                            "Yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shandaxwén.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_45",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "He's brailing the boat full.",
                "tlingit": [
                    [
                        [
                            "Yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ashanaxwén.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_46",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "(The boat) is full (from brailing).",
                "tlingit": [
                    [
                        [
                            "Yaa",
                            [
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shawdixwén.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_47",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It (boat) is traveling in a wind.",
                "tlingit": [
                    [
                        [
                            "Óoxjaa",
                            [
                                {
                                    "tlingit": "óoxjaa",
                                    "url": "óoxjaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "wind",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tóox̲",
                            [
                                {
                                    "tlingit": "a tóox̲",
                                    "url": "a tú",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the inside of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yaa nak̲úx̲.",
                            [
                                {
                                    "tlingit": "yaa nak̲úx̲",
                                    "url": "wook̲oox̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going (by motorized vehicle)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa nak̲úx̲",
                                    "url": "yaa nak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going along (by boat, car)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "daak nak̲úx̲",
                                    "url": "daak uwak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going out to sea",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nak̲úx̲",
                                    "url": "aadé wook̲oox̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nak̲úx̲",
                                    "url": "át uwak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving to it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yánde yaa nak̲úx̲",
                                    "url": "yan uwak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going ashore",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daax̲ yaa nak̲úx̲",
                                    "url": "a daax̲ yaawak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving around it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a tóonáx̲ yaa nak̲úx̲",
                                    "url": "a tóonáx̲ yaawak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving through it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲ut kei nak̲úx̲",
                                    "url": "k̲ut wook̲oox̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is getting lost",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_48",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It (boat) is traveling in a storm.",
                "tlingit": [
                    [
                        [
                            "K'eeljáa",
                            [
                                {
                                    "tlingit": "k'eeljáa",
                                    "url": "k'eeljáa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "chinook wind; south wind",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k'eeljáa",
                                    "url": "k'eiljáa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "chinook wind; south wind",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tóox̲",
                            [
                                {
                                    "tlingit": "a tóox̲",
                                    "url": "a tú",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the inside of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yaa nak̲úx̲.",
                            [
                                {
                                    "tlingit": "yaa nak̲úx̲",
                                    "url": "wook̲oox̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going (by motorized vehicle)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa nak̲úx̲",
                                    "url": "yaa nak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going along (by boat, car)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "daak nak̲úx̲",
                                    "url": "daak uwak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going out to sea",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nak̲úx̲",
                                    "url": "aadé wook̲oox̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé yaa nak̲úx̲",
                                    "url": "át uwak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving to it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yánde yaa nak̲úx̲",
                                    "url": "yan uwak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is going ashore",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daax̲ yaa nak̲úx̲",
                                    "url": "a daax̲ yaawak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving around it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a tóonáx̲ yaa nak̲úx̲",
                                    "url": "a tóonáx̲ yaawak̲úx̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is driving through it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲ut kei nak̲úx̲",
                                    "url": "k̲ut wook̲oox̲",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is getting lost",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_49",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We will paint the bottom of the boat.",
                "tlingit": [
                    [
                        [
                            "Wé",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaakw",
                            [
                                {
                                    "tlingit": "yaakw",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boat, canoe",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tadaa",
                            [],
                            []
                        ],
                        [
                            "gax̲toonéegwál.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_50",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My (paternal) uncle is mending his net.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sáni",
                            [
                                {
                                    "tlingit": "du sáni",
                                    "url": "du sáni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her paternal uncle, cousin",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "du g̲eiwú",
                            [
                                {
                                    "tlingit": "du g̲eiwú",
                                    "url": "g̲eiwú",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its fish net; seine net",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "g̲eiwú",
                                    "url": "g̲eiwú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish net; seine net",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "ak̲éis'.",
                            [
                                {
                                    "tlingit": "ak̲éis'",
                                    "url": "aawak̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is sewing it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_51",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's trolling.",
                "tlingit": [
                    [
                        [
                            "Shukalx̲aach.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_52",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's fishing for salmon (with a pole).",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ast'eix̲.",
                            [
                                {
                                    "tlingit": "ast'eix̲",
                                    "url": "awdzit'eix̲",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is fishing (with a hook)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ast'eix̲",
                                    "url": "awsit'áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is warming it up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_53",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's seining.",
                "tlingit": [
                    [
                        [
                            "Asg̲eiwú.",
                            [
                                {
                                    "tlingit": "asg̲eiwú",
                                    "url": "asg̲eiwú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "seine fisherman; seine boat",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "asg̲eiwú",
                                    "url": "awdzig̲eiwú",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is seining",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_54",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he's jigging for halibut.",
                "tlingit": [
                    [
                        [
                            "Kasyéik̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_55",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "My daughter likes to fish with hoochies.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sée",
                            [
                                {
                                    "tlingit": "sée",
                                    "url": "sée",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "doll",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "hoochies-tin",
                            [],
                            []
                        ],
                        [
                            "ast'eix̲í",
                            [
                                {
                                    "tlingit": "ast'eix̲í",
                                    "url": "ast'eix̲í",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fisherman (troller)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_56",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "flasher (bracelet spoon)",
                "tlingit": [
                    [
                        [
                            "kées",
                            [
                                {
                                    "tlingit": "kées",
                                    "url": "kées",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bracelet",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shál",
                            [
                                {
                                    "tlingit": "shál",
                                    "url": "shál",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "spoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_57",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "spinner",
                "tlingit": [
                    [
                        [
                            "shál",
                            [
                                {
                                    "tlingit": "shál",
                                    "url": "shál",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "spoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k'wát'",
                            [
                                {
                                    "tlingit": "k'wát'",
                                    "url": "k'wát'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "egg (of bird)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_58",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "The tide is going down.",
                "tlingit": [
                    [
                        [
                            "Yei naléin.",
                            [
                                {
                                    "tlingit": "áx̲ yei naléin",
                                    "url": "áx̲ woolaa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "the tide is going out from under it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yánde yaa naléin",
                                    "url": "yan uwaláa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "the tide is going out",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yei",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_59",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "The tide is down.",
                "tlingit": [
                    [
                        [
                            "Yan uwaláa.",
                            [
                                {
                                    "tlingit": "yan uwaláa",
                                    "url": "yan uwaláa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "the tide is low",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "yan~",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yan",
                                    "url": "yan~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "ashore, onto ground; to rest",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_60",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "The tide has turned to come in.",
                "tlingit": [
                    [
                        [
                            "Dák̲de",
                            [
                                {
                                    "tlingit": "dák̲de",
                                    "url": "daak̲",
                                    "tags": [
                                        "Adverb",
                                        "allative"
                                    ],
                                    "english": "up in the woods; inland; back (away from the open, away from the water's edge, inside)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dák̲de",
                                    "url": "dák̲de",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "toward the inland, interior; up to the woods; back (away from the open)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yaawadáa.",
                            [
                                {
                                    "tlingit": "anax̲ yaawadáa",
                                    "url": "anax̲ yaawadáa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it flowed there",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_61",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "The tide is coming in.",
                "tlingit": [
                    [
                        [
                            "Daak̲ nadéin.",
                            [
                                {
                                    "tlingit": "daak̲ nadéin",
                                    "url": "daak̲ uwadáa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "the tide is coming in",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "aadé yaa nadéin",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's flowing to it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "anax̲ yaa nadéin",
                                    "url": "anax̲ yaawadáa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's flowing there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei nadéin",
                                    "url": "kei uwadáa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "the water level is rising",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲'éix̲ yaa nadéin",
                                    "url": "du x̲'éix̲ woodaa",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is getting used to (the flavor, pronunciation of) it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "daak̲",
                                    "url": "daak̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up in the woods; inland; back (away from the open, away from the water's edge, inside)",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_62",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "The tide has come up.",
                "tlingit": [
                    [
                        [
                            "Yankát",
                            [],
                            []
                        ],
                        [
                            "uwadáa.",
                            [
                                {
                                    "tlingit": "át uwadáa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it flowed to it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei uwadáa",
                                    "url": "kei uwadáa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "the water level rose",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "daak̲ uwadáa",
                                    "url": "daak̲ uwadáa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "the tide is in",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_63",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It is half tide.",
                "tlingit": [
                    [
                        [
                            "K̲aa",
                            [
                                {
                                    "tlingit": "k̲aa s'aatí",
                                    "url": "k̲aa s'aatí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boss",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa x̲'oos",
                                    "url": "k̲aa x̲'oos",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "foot (measurement)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at óowu",
                                    "url": "k̲aa at óowu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession(s); that which is owned (by them)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa ji.eetí",
                                    "url": "k̲aa ji.eetí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "handiwork, handmade crafts",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa daakeidí",
                                    "url": "k̲aa daakeidí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "coffin; casket",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa x̲'a.eetí",
                                    "url": "k̲aa x̲'a.eetí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "leftovers, food scraps",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at oohéini",
                                    "url": "k̲aa at oohéini",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession; that which is claimed",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "shoowú",
                            [
                                {
                                    "tlingit": "a shoowú",
                                    "url": "a shoowú",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "part of it; half of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "dáanaa shoowú",
                                    "url": "dáanaa shoowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "half dollar; fifty cents",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲",
                            [
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the face of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yáx̲",
                                    "tags": [
                                        "RelationalPostposition"
                                    ],
                                    "english": "like it, in accordance with it, as much as it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaaw yáx̲",
                                    "url": "gaaw yáx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "on time; in time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ kaaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it looks like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Wooch yáx̲ nasné!",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Straighten it out!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ wudlitl'éit'",
                                    "url": "a yáx̲ wudlitl'éit'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it climbed the face of it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "daak̲ uwadáa.",
                            [
                                {
                                    "tlingit": "daak̲ uwadáa",
                                    "url": "daak̲ uwadáa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "the tide is in",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "kei uwadáa",
                                    "url": "kei uwadáa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "the water level rose",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "át uwadáa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it flowed to it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "daak̲",
                                    "url": "daak̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up in the woods; inland; back (away from the open, away from the water's edge, inside)",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "K̲aa",
                            [
                                {
                                    "tlingit": "k̲aa s'aatí",
                                    "url": "k̲aa s'aatí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boss",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa x̲'oos",
                                    "url": "k̲aa x̲'oos",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "foot (measurement)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at óowu",
                                    "url": "k̲aa at óowu",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession(s); that which is owned (by them)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa ji.eetí",
                                    "url": "k̲aa ji.eetí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "handiwork, handmade crafts",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa daakeidí",
                                    "url": "k̲aa daakeidí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "coffin; casket",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa x̲'a.eetí",
                                    "url": "k̲aa x̲'a.eetí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "leftovers, food scraps",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲aa at oohéini",
                                    "url": "k̲aa at oohéini",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "possession; that which is claimed",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "shoowú",
                            [
                                {
                                    "tlingit": "a shoowú",
                                    "url": "a shoowú",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "part of it; half of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "dáanaa shoowú",
                                    "url": "dáanaa shoowú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "half dollar; fifty cents",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "yáx̲ yaa",
                            [
                                {
                                    "tlingit": "a yáx̲ yaa kanaxát",
                                    "url": "a yáx̲ kaaxát",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it's beginning to look like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ yaa anasnéin",
                                    "url": "wooch yáx̲ awsinei",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is straightening it out",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "wooch yáx̲ yaa datóowch",
                                    "url": "wooch yáx̲ yaa datóowch",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "math",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yá",
                                    "tags": [
                                        "RelationalNoun",
                                        "pertingent"
                                    ],
                                    "english": "along the face of it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲",
                                    "url": "a yáx̲",
                                    "tags": [
                                        "RelationalPostposition"
                                    ],
                                    "english": "like it, in accordance with it, as much as it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaaw yáx̲",
                                    "url": "gaaw yáx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "on time; in time",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "along; down",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yaa",
                                    "url": "yaa2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "trout (sea)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a yáx̲ gug̲altl'éit'",
                                    "url": "a yáx̲ wudlitl'éit'",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will climb the face of it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "woolaa.",
                            [
                                {
                                    "tlingit": "áx̲ woolaa",
                                    "url": "áx̲ woolaa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "the tide went out from under it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "24_64",
                        "J"
                    ],
                    [
                        "24_65",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "You'll see the (tide) marker.",
                "tlingit": [
                    [
                        [
                            "Kwéiy",
                            [
                                {
                                    "tlingit": "kwéiy",
                                    "url": "kwéiy",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "marker; mark, sign",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yei",
                            [
                                {
                                    "tlingit": "yei",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kg̲isatéen.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_66",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Let's go to the tide flats.",
                "tlingit": [
                    [
                        [
                            "Léinde",
                            [],
                            []
                        ],
                        [
                            "nax̲too.aat!",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_67",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Where's your (paternal) aunt?",
                "tlingit": [
                    [
                        [
                            "Goosóo",
                            [],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aat?",
                            [
                                {
                                    "tlingit": "du aat",
                                    "url": "du aat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her paternal aunt",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_68",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he went down to the tide flats.",
                "tlingit": [
                    [
                        [
                            "Léinde",
                            [],
                            []
                        ],
                        [
                            "woogoot.",
                            [
                                {
                                    "tlingit": "át woogoot",
                                    "url": "át woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is walking around there; he/she/it walked around there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ woogoot",
                                    "url": "áx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it walked along it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé woogoot",
                                    "url": "aadé woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it went there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲ut woogoot",
                                    "url": "k̲ut woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he got lost",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaax̲ woogoot",
                                    "url": "yaax̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he went aboard",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a nák̲ woogoot",
                                    "url": "a nák̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he left it behind",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a ítx̲ woogoot",
                                    "url": "a ítx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it followed it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_69",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "When is she coming back?",
                "tlingit": [
                    [
                        [
                            "Gwatgeen",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haadé",
                            [
                                {
                                    "tlingit": "haadé",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "allative"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kg̲wagóot?",
                            [
                                {
                                    "tlingit": "aadé kg̲wagóot",
                                    "url": "aadé woogoot",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will go there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadé kg̲wagóot",
                                    "url": "át uwagút",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will go there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gági kg̲wagóot",
                                    "url": "gági uwagút",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will emerge",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "k̲ut kei kg̲wagóot",
                                    "url": "k̲ut woogoot",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will get lost",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yaax̲ yei kg̲wagóot",
                                    "url": "yaax̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will go aboard",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a ítx̲ yaa kg̲wagóot",
                                    "url": "a ítx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will follow it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_70",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She'll be gone for an hour.",
                "tlingit": [
                    [
                        [
                            "Tléix'",
                            [
                                {
                                    "tlingit": "tléix'",
                                    "url": "tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gaaw x̲'áak",
                            [
                                {
                                    "tlingit": "gaaw x̲'áak",
                                    "url": "gaaw x̲'áak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "hour",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a x̲'áak",
                                    "url": "a x̲'áak",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "between them",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du daa.eit x̲'áak",
                                    "url": "du daa.eit x̲'áak",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her joints",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "time",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bell",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw3",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "drum",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "gaaw",
                                    "url": "gaaw4",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "clock",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "gugayéix̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_71",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Tell her we are cooking.",
                "tlingit": [
                    [
                        [
                            "Du een",
                            [
                                {
                                    "tlingit": "du een aa",
                                    "url": "du een aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her kinsman, moiety mate",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "kananeek",
                            [
                                {
                                    "tlingit": "Kananeek!",
                                    "url": "akaawaneek",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Tell about it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gatoos.ée.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_72",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "So she can come there?",
                "tlingit": [
                    [
                        [
                            "Wéide",
                            [
                                {
                                    "tlingit": "wéide",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative",
                                        "allative"
                                    ],
                                    "english": "toward there",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ng̲agoodéet",
                            [],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Wéit",
                            [
                                {
                                    "tlingit": "wéit",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative",
                                        "punctual"
                                    ],
                                    "english": "that (place)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲agoott",
                            [],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    "[Yes, two ts!]"
                ],
                "ids": [
                    [
                        "24_73",
                        "N"
                    ],
                    [
                        "24_74",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Yes, have her come.",
                "tlingit": [
                    [
                        [
                            "Aaá,",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲agoodí.",
                            [
                                {
                                    "tlingit": "yaa nagút",
                                    "url": "yaa nagút",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he is walking along; s/he is going along",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Aaá,",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haat",
                            [
                                {
                                    "tlingit": "haat",
                                    "url": "haat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "current, tide",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haat~",
                                    "url": "haat~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hither, toward speaker",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "g̲agoot.",
                            [
                                {
                                    "tlingit": "yaa nagút",
                                    "url": "yaa nagút",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he is walking along; s/he is going along",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "24_75",
                        "N"
                    ],
                    [
                        "24_76",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We have all kinds of food.",
                "tlingit": [
                    [
                        [
                            "Ldakát",
                            [
                                {
                                    "tlingit": "ldakát",
                                    "url": "ldakát",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "all; every",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéide",
                            [],
                            []
                        ],
                        [
                            "atx̲á",
                            [
                                {
                                    "tlingit": "atx̲á",
                                    "url": "atx̲á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "food, a meal",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haa",
                            [
                                {
                                    "tlingit": "haa",
                                    "url": "haa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "our [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "haa",
                                    "url": "haa2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "us [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú.",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_77",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he has all kinds of food.",
                "tlingit": [
                    [
                        [
                            "Ldakát",
                            [
                                {
                                    "tlingit": "ldakát",
                                    "url": "ldakát",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "all; every",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéide",
                            [],
                            []
                        ],
                        [
                            "atx̲á",
                            [
                                {
                                    "tlingit": "atx̲á",
                                    "url": "atx̲á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "food, a meal",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "jeewú.",
                            [
                                {
                                    "tlingit": "du jee",
                                    "url": "du jee",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "in his/her possession",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "-u",
                                    "url": "-u",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "is/are at",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_78",
                        "J"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "ex",
                "english": "What did you [singular] catch?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ydzit'éx̲?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_79",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I caught a king salmon.",
                "tlingit": [
                    [
                        [
                            "T'á",
                            [
                                {
                                    "tlingit": "t'á",
                                    "url": "t'á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "king salmon; chinook salmon; spring salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲wdzit'éx̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_80",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What did you [plural] catch?",
                "tlingit": [
                    [
                        [
                            "Daa sá",
                            [
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléil daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yanees.á!",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Examine it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yoo tutánk!",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Think about it!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "(wé)",
                            [
                                {
                                    "tlingit": "wé",
                                    "url": "wé",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (at hand)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ydzit'éx̲?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_81",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We caught two king salmon.",
                "tlingit": [
                    [
                        [
                            "Déix̲",
                            [
                                {
                                    "tlingit": "déix̲",
                                    "url": "déix̲",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "two",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "t'á",
                            [
                                {
                                    "tlingit": "t'á",
                                    "url": "t'á",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "king salmon; chinook salmon; spring salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "wtudzit'éx̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_82",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What did s/he catch?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "awdzit'éx̲?",
                            [
                                {
                                    "tlingit": "awdzit'eix̲",
                                    "url": "awdzit'eix̲",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he fished (with a hook); s/he trolled",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_83",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he caught a coho.",
                "tlingit": [
                    [
                        [
                            "L'ook",
                            [
                                {
                                    "tlingit": "l'ook",
                                    "url": "l'ook",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "coho salmon; silver salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kei",
                            [
                                {
                                    "tlingit": "kei",
                                    "url": "kei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "up",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "awdzit'éx̲.",
                            [
                                {
                                    "tlingit": "awdzit'eix̲",
                                    "url": "awdzit'eix̲",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he fished (with a hook); s/he trolled",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_84",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "What are you [singular] butchering?",
                "tlingit": [
                    [
                        [
                            "Daa",
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a daa yas.éix̲",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is examining it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sá(wé)",
                            [],
                            []
                        ],
                        [
                            "eexáash?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_85",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I'm butchering fish.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲axáash.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_86",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he is butchering fish.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "axáash.",
                            [
                                {
                                    "tlingit": "axáash",
                                    "url": "aawaxaash",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cuts it; s/he is cutting it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_87",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "We are butchering fish.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tooxáash.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_88",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are butchering fish.",
                "tlingit": [
                    [
                        [
                            "X̲áat",
                            [
                                {
                                    "tlingit": "x̲áat",
                                    "url": "x̲áat",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fish; salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "axáash.",
                            [
                                {
                                    "tlingit": "axáash",
                                    "url": "aawaxaash",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cuts it; s/he is cutting it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "24_89",
                        "N"
                    ]
                ]
            }
        ]
    },
    "25": {
        "title": "Plants, gardens, and nature",
        "content": [
            {
                "type": "ex",
                "english": "S/he is working in her garden.",
                "tlingit": [
                    [
                        [
                            "Du",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "táayi",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "adaané.",
                            [
                                {
                                    "tlingit": "yéi adaanéi",
                                    "url": "yéi adaanéi",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he does it; s/he is doing it; s/he works on it; s/he is working on it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "25_1",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "S/he likes to garden.",
                "tlingit": [
                    [
                        [
                            "At",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "kahéix̲",
                            [],
                            []
                        ],
                        [
                            "du tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ],
                    [
                        [
                            "Kadahéix̲",
                            [],
                            []
                        ],
                        [
                            "du tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "ids": [
                    [
                        "25_2",
                        "J"
                    ],
                    [
                        "25_3",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "She grows flowers.",
                "tlingit": [
                    [
                        [
                            "Woosh",
                            [
                                {
                                    "tlingit": "woosh",
                                    "url": "wooch",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "woosh",
                                    "url": "woosh",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "together [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gunayáade",
                            [
                                {
                                    "tlingit": "woosh g̲unayáade",
                                    "url": "woosh g̲unayáade",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "differently",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k̲'eikaxwéin",
                            [
                                {
                                    "tlingit": "k̲'eikaxwéin",
                                    "url": "k̲'eikaxwéin",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "flower; blossom",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "akahéix̲.",
                            [
                                {
                                    "tlingit": "akahéix̲",
                                    "url": "akaawahaa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is planting it",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "akahéix̲",
                                    "url": "akaawahaa2",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is gardening",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "25_4",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "It's ripe.",
                "tlingit": [
                    [
                        [
                            "Kaawat'áa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "25_5",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is it ripe?",
                "tlingit": [
                    [
                        [
                            "Kaawat'áa",
                            [],
                            []
                        ],
                        [
                            "gé?",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "25_6",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "When is it ripe?",
                "tlingit": [
                    [
                        [
                            "Gwátgeen",
                            [
                                {
                                    "tlingit": "ch'a gwátgeen sá",
                                    "url": "ch'a gwátgeen sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "any time (in the future); whenever (in the future)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "sáwé",
                            [],
                            []
                        ],
                        [
                            "kakg̲wat'áa?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "25_7",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Can you eat it?",
                "tlingit": [
                    [
                        [
                            "Dux̲á",
                            [
                                {
                                    "tlingit": "aawax̲áa",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he ate it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "ágé?",
                            [
                                {
                                    "tlingit": "ágé",
                                    "url": "ágé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[focus + interrogative]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "[lit. Is it edible?]"
                ],
                "ids": [
                    [
                        "25_8",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Maybe it's no good to eat?",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gwáagé",
                            [],
                            []
                        ],
                        [
                            "ushk'é",
                            [
                                {
                                    "tlingit": "l ushk'é",
                                    "url": "l ushk'é",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "evil, sin",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél ushk'é",
                                    "url": "tlél wushk'é",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is bad",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "dux̲aayí?",
                            [
                                {
                                    "tlingit": "aawax̲áa",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb"
                                    ],
                                    "english": "s/he ate it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "25_9",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Is it good for medicine?",
                "tlingit": [
                    [
                        [
                            "Náakw",
                            [
                                {
                                    "tlingit": "náakw",
                                    "url": "náakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "medicine",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yís",
                            [
                                {
                                    "tlingit": "a yís",
                                    "url": "a yís",
                                    "tags": [
                                        "RelationalPostposition"
                                    ],
                                    "english": "for it; to that end",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yak'éi?",
                            [
                                {
                                    "tlingit": "yak'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "25_10",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "They are digging potatoes.",
                "tlingit": [
                    [
                        [
                            "K'únts'",
                            [
                                {
                                    "tlingit": "k'únts'",
                                    "url": "k'únts'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "potato",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "akaawaháa.",
                            [
                                {
                                    "tlingit": "akaawaháa",
                                    "url": "akaawaháa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he dug it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "25_11",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "I picked berries.",
                "tlingit": [
                    [
                        [
                            "Tléik̲w",
                            [
                                {
                                    "tlingit": "tléik̲w",
                                    "url": "tléik̲w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "berry, berries",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲waa.ín.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "25_12",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "A raven is sitting in a tree.",
                "tlingit": [
                    [
                        [
                            "Yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "aas",
                            [
                                {
                                    "tlingit": "aas",
                                    "url": "aas",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tree (esp. conifer)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yíkt",
                            [
                                {
                                    "tlingit": "yíkt",
                                    "url": "a yík",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "in it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áa.",
                            [
                                {
                                    "tlingit": "áa",
                                    "url": "áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "lake",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "25_13",
                        "N"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "Small birds are sitting in the tree.",
                "tlingit": [
                    [
                        [
                            "Ts'ítsgux'",
                            [],
                            []
                        ],
                        [
                            "sáani",
                            [],
                            []
                        ],
                        [
                            "aas",
                            [
                                {
                                    "tlingit": "aas",
                                    "url": "aas",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tree (esp. conifer)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yíkt",
                            [
                                {
                                    "tlingit": "yíkt",
                                    "url": "a yík",
                                    "tags": [
                                        "RelationalNoun",
                                        "punctual"
                                    ],
                                    "english": "in it",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has k̲éen.",
                            [
                                {
                                    "tlingit": "has k̲éen",
                                    "url": "has k̲éen",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Pl"
                                    ],
                                    "english": "they are sitting",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "25_14",
                        "J"
                    ]
                ]
            },
            {
                "type": "ex",
                "english": "That's a sea pigeon over there.",
                "tlingit": [
                    [
                        [
                            "K̲'eik̲'w",
                            [
                                {
                                    "tlingit": "k̲'eik̲'w",
                                    "url": "k̲'eik̲'w",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tern",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áyú",
                            [
                                {
                                    "tlingit": "áyú",
                                    "url": "áyú",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (distant), yonder [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yóot.",
                            [
                                {
                                    "tlingit": "yóot~",
                                    "url": "yóot~",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "away, off (to someplace indefinite)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóot",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative",
                                        "punctual"
                                    ],
                                    "english": "that (distant) (place)",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóot",
                                    "url": "yóot~",
                                    "tags": [
                                        "Adverb",
                                        "punctual"
                                    ],
                                    "english": "away, off (to someplace indefinite)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "ids": [
                    [
                        "25_15",
                        "N"
                    ]
                ]
            }
        ]
    }
};