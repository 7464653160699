import React, { Component, createRef } from 'react';
import { Button, Icon, Grid, Dropdown, List, Popup, Transition, Modal, Segment, Input, Label} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { API_URL } from '../App.js';
import axios from 'axios';
import { TagColors } from './HomeHelper.js';
import NewUsageBaseDisplay from './NewUsageBaseDisplay.js'
import * as Scroll from 'react-scroll';
import fuzzysort from 'fuzzysort'
import ReactGA from "react-ga4";
ReactGA.initialize("G-BCN5V0JQN1")

var scroll = Scroll.animateScroll;

let customFontFam = "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"
let letterbutton = {paddingTop:'10px',paddingBottom:'10px',paddingRight:'14px',paddingLeft:'14px'}

let timeWordsList = [ 
{"key":"akkupak","eng":"now"},
{"key":"uvlupak","eng":"today"},
{"key":"uvlaaku","eng":"tomorrow"},
{"key":"unnugman","eng":"yesterday"},
// {"key":"akkupak","eng":"now"},
{"key":"uvlaaq","eng":"this past morning"},
{"key":"unnuaq","eng":"last night"},
{"key":"unnuaġu","eng":"tonight"},
{"key":"uvlaaġu","eng":"tomorrow night"},
{"key":"akku","eng":"a while ago"},
{"key":"aaqagu","eng":"in a while"},


{"key":"aippaanigu","eng":"in the very distant future"},
{"key":"taimanigu","eng":"in the distant future"},
{"key":"pagmami","eng":"nowadays, in modern times"},
{"key":"pagmapak","eng":"nowadays, in modern times"},
{"key":"taimani","eng":"in/at the past"},
{"key":"aippaani","eng":"in olden times; in ancient times; beyond the memory of living man"},
{"key":"alġaani","eng":"in olden times; in ancient times; beyond the memory of living man"},
{"key":"ukiutqigu","eng":"year after next"},
{"key":"ukiuġu","eng":"next year"},
{"key":"ikpaksraaġu","eng":"in a few days"},
{"key":"uvlaakutqigu","eng":"day after tomorrow"},

{"key":"ikpaksraq","eng":"yesterday"},
{"key":"unnugmatqik","eng":"day before yesterday"},
{"key":"ikpaksratqik","eng":"day before yesterday"},
{"key":"ikpaksraaq","eng":"a few days ago/recently"},
{"key":"qaŋaqisuuraq","eng":"a few days ago/recently"},
{"key":"ukiuq","eng":"last year"},
{"key":"ukiutqik","eng":"year before last"},
{"key":"aaqagusaaq","eng":"in a long while"},
{"key":"aaqaguqisuuraq","eng":"in a short while"},
{"key":"akkuqisuuraq","eng":"a short while ago"},
{"key":"akkusaaq","eng":"a long while ago"},
{"key":"akkusaaġruk","eng":"a long while ago"},
{"key":"uvlaatqigu","eng":"morning after tomorrow morning"},
{"key":"unnuatqigu","eng":"night after tonight"},

{"key":"uvlaatqik","eng":"yesterday morning"},
{"key":"unnuatqik","eng":"night before last"},
{"key":"upinġaatqigu","eng":"summer after this coming summer"},
{"key":"upinġaksratqigu","eng":"spring after this coming spring"},
{"key":"ukiutqigu","eng":"winter after this coming winter"},
{"key":"ukiatqigu","eng":"fall after this coming fall"},
{"key":"upinġaaġu","eng":"this coming summer"},
{"key":"upinġaksraġu","eng":"this coming spring"},
{"key":"ukiuġu","eng":"this coming winter"},
{"key":"ukiaġu","eng":"this coming fall"},
{"key":"upinġaapak","eng":"this current summer"},
{"key":"upinġaksraq","eng":"this past spring"},
{"key":"ukiuq","eng":"this past winter"},
{"key":"ukiaq","eng":"last fall"},
{"key":"upinġaaq","eng":"last summer"},
{"key":"upinġaksratqik","eng":"spring before this past spring"},
{"key":"ukiutqik","eng":"winter before last winter"},
{"key":"ukiatqik","eng":"fall before last fall"},
{"key":"upinġaatqik","eng":"summer before last summer"},
// {"key":"itpaksraq","eng":""}
// {"key":"itpaksraġu","eng":""}
// {"key":"uglupak","eng":""}
// {"key":"unnuapak","eng":""}
// {"key":"unnuaġruaq","eng":""}
// {"key":"uŋaliagu","eng":""}
]

let colorsList = { 
    '1stPerson':"#f19d38",
    '2ndPerson':"#8b2cf5",

    'mvv.s':'#852828',
    'mvv.is':'#7b7b7b',
    'mvv.o':'#C84141',
    'mvv.b':'#000000',
    // 'mvv.m':'#838383',
    'mvv.m':'#7b7b7b',
    'mvv.1':'#d3741e',
    'mvv.2':'#3455b5',
    'mvv.3':'#b165d3',
    'mvqWord':'#148f38',

    'timeWord':'#023e8a',
    
    "pb.1":"#cfb541",
    "pb.2":"#89bb68",
    "pb.3":"#4a90c1",
    "pb.4":"#89bb68",
    "pb.5":"#60187e",
    "pb.6":"#6cb28a",
    "pb.7":"#75b67e",
    "pb.8":"#e68833",
    "pb.9":"#93bd60",
    "pb.10":"#403f96",
    "pb.11":"#64ae97",
    "pb.12":"#e59535",
    "pb.13":"#e77930",
    "pb.14":"#4685c2",
    "pb.15":"#e4572a",
    "pb.16":"#bdbc48",
    "pb.17":"#dda93b",
    "pb.18":"#c6b944",
    "pb.19":"#4f99bb",
    "pb.20":"#9ebe58",
    "pb.21":"#781c81",
    "pb.22":"#3f4ea1",
    "pb.23":"#e59535",
    "pb.24":"#e6692d",
    "pb.25":"#7eb972",
    "pb.26":"#e1a038",
    "pb.27":"#3f5dac",
    "pb.28":"#521c80",
    "pb.29":"#537522",
    "pb.30":"#89bb68",
    "pb.31":"#e68833",
    "pb.32":"#406cb6",
    "pb.33":"#43b8be",
    "pb.34":"#75b67e",
    "pb.35":"#e6692d",
    "pb.36":"#a8be52",
    "pb.37":"#d6b03d",
    "pb.38":"#dda93b",


    // 'past':'#dd9221',
    // 'future':'#dd9221',
    'past':'#000000',
    'future':'#000000',

		"mvns00.e":'#000000DE',
    'mvns00.b':'#852828',
    'mvns00.pd':'#852828',
    'mvns00.ps':'#a5429f',
    'mvns10.b':'#a5429f',
    'mvns10.pd':'#a5429f',
    'mvns10.ps':'#6848e6',
    'mvns20.b':'#6848e6',
    'mvns20.pd':'#6848e6',
    'mvns20.ps':'#5c5c5c',

    // 'mvns00.ps':'#000000',
    // 'mvns10.b':'#000000',
    // 'mvns10.pd':'#000000',
    // 'mvns20.b':'#000000',
    // 'mvns20.pd':'#000000',

    // 'mvns10.b':'#852828',
    // 'mvns10.pd':'#852828',
    'mvns00.c':'#663782',    
    'mvns10.c':'#663782',    
    'mvns20.c':'#663782',    
    'mvns00.1':'#852828',
    'mvns00.2':'#852828',
    'mvns00.3':'#852828',


    // 'mvno00.b':'#C84141',
    // 'mvno00.pd':'#C84141', 
    // 'mvno00.ps':'#5c5c5c',
    // 'mvno10.ps':'#5c5c5c',
    // 'mvno20.ps':'#5c5c5c',

    'mvno00.c':'#663782',
    'mvno10.c':'#663782',
    'mvno20.c':'#663782',

    'cvno10.c':'#663782',
    'cvno20.c':'#663782',
    'mvneo10.c':'#663782',
    'mvneo20.c':'#663782',

    'cvns10.c':'#663782',
    'cvns20.c':'#663782',
    'mvnes10.c':'#663782',
    'mvnes20.c':'#663782',

        "mvno00.e":'#000000DE',
    'mvno00.b':'#C84141',
    'mvno00.pd':'#C84141',
    'mvno00.ps':'#cf5ca0',
    'mvno10.b':'#cf5ca0',
    'mvno10.pd':'#cf5ca0',
    'mvno10.ps':'#977ef9',
    'mvno20.b':'#977ef9',
    'mvno20.pd':'#977ef9',
    'mvno20.ps':'#5c5c5c',


    'mvneo00.b':'#9135BC',
    'mvneo00.c':'#f37837',
    'mvneo00.pd':'#9135BC', 
    'mvneo00.ps':'#cf5ca0', 
    'mvneo10.b':'#cf5ca0',
    'mvneo10.pd':'#cf5ca0',
    'mvneo10.ps':'#977ef9',
    'mvneo20.b':'#977ef9',
    'mvneo20.pd':'#977ef9',
    'mvneo20.ps':'#5c5c5c',

    'mvnes00.b':'#3360CB',
    'mvnes00.c':'#f37837',
    'mvnes00.pd':'#3360CB',
    'mvnes00.ps':'#a5429f',
    'mvnes10.b':'#a5429f',
    'mvnes10.pd':'#a5429f',
    'mvnes10.ps':'#6848e6',
    'mvnes20.b':'#6848e6',
    'mvnes20.pd':'#6848e6',
    'mvnes20.ps':'#5c5c5c',

    'mvno00.c':'#f37837',
    // 'mvno10.b':'#000000',
    // 'mvno20.b':'#000000',
    // 'mvno10.b':'#C84141',
    // 'mvno10.pd':'#C84141', 
    'mvno00.1':'#C84141',
    'mvno00.2':'#C84141',
    'mvno00.3':'#C84141',

    'cvv.b':'#000000',
    'cvv.s':'#287485',
    'cvv.o':'#565994',
    'cvv.m':'#d188be',
    'cvv.1':'#000000',
    'cvv.2':'#000000',
    'cvv.3':'#000000',

    'cvns00.c':'#f37837',

    "cvns00.e":'#000000DE',
    'cvns00.b':'#287485',
    'cvns00.pd':'#287485',
    'cvns00.ps':'#a5429f',
    'cvns10.b':'#a5429f',
    'cvns10.pd':'#a5429f',
    'cvns10.ps':'#6848e6',
    'cvns20.b':'#6848e6',
    'cvns20.pd':'#6848e6',
    'cvns20.ps':'#5c5c5c',

    // 'cvns00.b':'#287485',
    // 'cvns00.pd':'#287485',

    // 'cvns10.ps':'#9c9c9c',
    // 'cvns20.ps':'#9c9c9c',

    // 'cvns00.c':'#663782',    
    // 'cvns10.b':'#000000',
    // 'cvns20.b':'#000000',
    // 'cvns10.pd':'#287485',
    // 'cvns00.1':'#287485',
    // 'cvns00.2':'#287485',
    // 'cvns00.3':'#287485',

    // 'cvno00.b':'#565994',
    // 'cvno00.pd':'#565994', 

    // 'cvno10.ps':'#9c9c9c',
    // 'cvno20.ps':'#9c9c9c',

    // 'cvno00.c':'#663782',
    // 'cvno10.b':'#000000',
    // 'cvno20.b':'#000000',
    // 'cvno10.b':'#565994',
    // 'cvno10.pd':'#565994', 
    // 'cvno00.1':'#565994',
    // 'cvno00.2':'#565994',
    // 'cvno00.3':'#565994',


    'cvno00.c':'#663782',

    "cvno00.e":'#000000DE',
    'cvno00.b':'#565994',
    'cvno00.pd':'#565994',
    'cvno00.ps':'#cf5ca0',
    'cvno10.b':'#cf5ca0',
    'cvno10.pd':'#cf5ca0',
    'cvno10.ps':'#977ef9',
    'cvno20.b':'#977ef9',
    'cvno20.pd':'#977ef9',
    'cvno20.ps':'#5c5c5c',

    // 'svv.b':'#000000',
    // 'svv.o':'#565994',
    // 'svv.s':'#852828',
    // 'svv.e':'#565994',
    // 'svv.m':'#000000',
    // 'svv.1':'#000000',
    // 'svv.2':'#000000',
    // 'svv.3':'#000000',

    // 'svno00.b':'#565994',
    // 'svno00.pd':'#565994',

    // 'svno10.ps':'#9c9c9c',
    // 'svno20.ps':'#9c9c9c',

    // 'svno00.c':'#663782',
    // // 'svno10.b':'#000000',
    // // 'svno20.b':'#000000',
    // // 'svno10.b':'#565994',
    // // 'svno10.pd':'#565994', 
    // 'svno00.1':'#565994',
    // 'svno00.2':'#565994',
    // 'svno00.3':'#565994',

    // 'npn00.b':'#852828',
    // 'npn00.pd':'#852828',
    // 'npn00.ps':'#5c5c5c',
    'npn00.c':'#663782',    
    'npn00.b':'#852828',
    'npn00.pd':'#852828',
    'npn00.ps':'#a5429f',
    'npn10.c':'#663782',    
    'npn10.b':'#a5429f',
    'npn10.pd':'#a5429f',
    'npn10.ps':'#6848e6',
    'npn20.c':'#663782',    
    'npn20.b':'#6848e6',
    'npn20.pd':'#6848e6',
    'npn20.ps':'#5c5c5c',


    'npnc00.c':'#663782',    
    'npnc00.b':'#852828',
    'npnc00.1':'#852828',
    'npnc00.pd':'#852828',
    'npnc00.ps':'#539e29',
    'npnc10.c':'#663782',    
    'npnc10.b':'#539e29',
    'npnc10.pd':'#539e29',
    'npnc10.ps':'#de9000',
    'npnc20.c':'#663782',    
    'npnc20.b':'#de9000',
    'npnc20.pd':'#de9000',
    'npnc20.ps':'#5c5c5c',


    'mvnObliques000.c':'#663782',
    'mvnObliques000.b':'#000000',
    'mvnObliques000.pd':'#000000',
    'mvnObliques000.ps':'#a5429f',

    'mvnObliques010.c':'#663782',
    'mvnObliques010.b':'#a5429f',
    'mvnObliques010.pd':'#a5429f',
    'mvnObliques010.ps':'#6848e6',

    'mvnObliques020.c':'#663782',
    'mvnObliques020.b':'#6848e6',
    'mvnObliques020.pd':'#6848e6',
    'mvnObliques020.ps':'#9c9c9c',

    'mvnObliques100.c':'#663782',
    'mvnObliques100.b':'#000000',
    'mvnObliques100.pd':'#000000',
    'mvnObliques100.ps':'#a5429f',

    'mvnObliques110.c':'#663782',
    'mvnObliques110.b':'#a5429f',
    'mvnObliques110.pd':'#a5429f',
    'mvnObliques110.ps':'#6848e6',

    'mvnObliques120.c':'#663782',
    'mvnObliques120.b':'#6848e6',
    'mvnObliques120.pd':'#6848e6',
    'mvnObliques120.ps':'#9c9c9c',

    'mvnObliques200.c':'#663782',
    'mvnObliques200.b':'#000000',
    'mvnObliques200.pd':'#000000',
    'mvnObliques200.ps':'#a5429f',

    'mvnObliques210.c':'#663782',
    'mvnObliques210.b':'#a5429f',
    'mvnObliques210.pd':'#a5429f',
    'mvnObliques210.ps':'#6848e6',

    'mvnObliques220.c':'#663782',
    'mvnObliques220.b':'#6848e6',
    'mvnObliques220.pd':'#6848e6',
    'mvnObliques220.ps':'#9c9c9c',


    // 'cvnObliques000.b':'#000000',
    // 'cvnObliques000.pd':'#000000',
    // 'cvnObliques010.ps':'#9c9c9c',
    // 'cvnObliques000.c':'#663782',
    // 'cvnObliques000.1':'#000000',
    // 'cvnObliques000.2':'#000000',
    // 'cvnObliques000.3':'#000000',

    // 'cvnObliques100.b':'#000000',
    // 'cvnObliques100.pd':'#000000',
    // 'cvnObliques110.ps':'#9c9c9c',
    // 'cvnObliques100.c':'#663782',
    // 'cvnObliques100.1':'#000000',
    // 'cvnObliques100.2':'#000000',
    // 'cvnObliques100.3':'#000000',

    // 'svnObliques000.b':'#000000',
    // 'svnObliques000.pd':'#000000',
    // 'svnObliques010.ps':'#9c9c9c',
    // 'svnObliques000.c':'#663782',
    // 'svnObliques000.1':'#000000',
    // 'svnObliques000.2':'#000000',
    // 'svnObliques000.3':'#000000',

    // 'svnObliques100.b':'#000000',
    // 'svnObliques100.pd':'#000000',
    // 'svnObliques110.ps':'#9c9c9c',
    // 'svnObliques100.c':'#663782',
    // 'svnObliques100.1':'#000000',
    // 'svnObliques100.2':'#000000',
    // 'svnObliques100.3':'#000000',


    // #287485
    // #565994
  }

class WordBuilder extends Component {
	constructor(props) {
		// console.log("props",props)
		super(props);
		this.state = {
			entryUrl: decodeURI(props.match.params.word),
			history: props.location.state === undefined ? [] : props.location.state.history,
			from: props.location.state === undefined ? '' : props.location.state.from,

      entryUsageData: this.props.usagedictionary_dict[decodeURI(props.match.params.word)],
			initBackendCall: props.location.state === undefined ? [] : props.location.state.initBackendCall,
			activeIndexes:[],
			combined:{'words':[]},
			wordsList:[],
      searchQuery:'',
			englishGroups:[],
      npnCase:'',
      mvvMood:'',

      filteredDictV: props.location.state === undefined ? [] : this.props.usagedictionary.filter(entry => (entry.tag.includes("i") || entry.tag.includes("t"))),
      filteredDictVi: props.location.state === undefined ? [] : this.props.usagedictionary.filter(entry => (entry.tag.includes("i"))),
      filteredDictVt: props.location.state === undefined ? [] : this.props.usagedictionary.filter(entry => (entry.tag.includes("t"))),
      filteredDictN: props.location.state === undefined ? [] : this.props.usagedictionary.filter(entry => (entry.tag.includes("n"))),

      ncType:'',
      showModal:false,
      newModalInfo:{},

      playingAudio: false,
      clickedAudioUrl: "",
      clickedAudio: null,
      visible:false,

      mvvBase:[],
      mvvBaseTransitivity:'',
      cvvBaseTransitivity:'',
      cvvBase:[],
      ongoing:false,

      demPresent:false,
      pointPresent:false,
      locativePresent:false,
		}
    scroll.scrollTo(0);
    this.handleRef = createRef()

    if (this.state.initBackendCall.length === 0) {
      // set initial direct-linked backendCall
      // console.log("entryUsageData",this.state.entryUsageData)
      this.state.initBackendCall = this.usageCalls(this.state.entryUsageData[0]['backendCalls'][0],this.state.entryUsageData[0].properties)
    }
    this.newBackEndCall(this.state.initBackendCall,true)
	    
	}

  componentDidUpdate(prevProps, prevState) {
    if (this.state.initBackendCall !== prevState.initBackendCall) {
        this.newBackEndCall(this.state.initBackendCall)
    }
    if (this.state.combined.words.length !== prevState.combined.words.length) {
        this.setState({
            activeIndexes:[],
        })
    }
  }


  processStyledText = (sentence) => {
    // sentence = sentence.replaceAll(">","")


    sentence = sentence.replaceAll("⟨","").replaceAll("⟩","")
    let matches = sentence.match(/⎡.*?⎤/g)
    let matches1 = sentence.match(/{.*?}/g)
    let matches2 = sentence.match(/_.*?_/g)
    let matches3 = sentence.match(/\^.*?\^/g)
    // console.log(matches2)
    if (matches !== null || matches1 !== null || matches2 !== null || matches3 !== null) {
      if (matches !== null) {
        matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
      }
      if (matches1 !== null) {
        matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
      }
      if (matches2 !== null) {
        matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
      }
      if (matches3 !== null) {
        matches3.map((m) => sentence = sentence.replace(m,'<sup>'+m.slice(1,-1)+'</sup>'))                      
      }
      return <span dangerouslySetInnerHTML={{__html: sentence}} />    
    } else {
      return <span>{sentence}</span>
    }


    // let matches = sentence.match(/\⎡.*?\⎤/g)
    // if (matches !== null) {
    //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
    //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
    // } else {
    //   return <span>{sentence}</span>
    // }
  }


  processStyledTextLink = (sentence) => {
    sentence = sentence.replaceAll(">","")


    sentence = sentence.replaceAll("⟨","").replaceAll("⟩","").replaceAll("↞","").replaceAll("&","").replaceAll("↠","")
    let matches = sentence.match(/⎡.*?⎤/g)
    let matches1 = sentence.match(/{.*?}/g)
    let matches2 = sentence.match(/_.*?_/g)
    let matches3 = sentence.match(/\^.*?\^/g)
    // console.log(matches2)
    if (matches !== null || matches1 !== null || matches2 !== null || matches3 !== null) {
      if (matches !== null) {
        matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
      }
      if (matches1 !== null) {
        matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
      }
      if (matches2 !== null) {
        matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
      }
      if (matches3 !== null) {
        matches3.map((m) => sentence = sentence.replace(m,'<sup>'+m.slice(1,-1)+'</sup>'))                      
      }
      return <span style={{whiteSpace:'nowrap'}} dangerouslySetInnerHTML={{__html: sentence}} />    
    } else {
      return <span style={{whiteSpace:'nowrap'}}>{sentence}</span>
    }



  }


  repeatAudio(audio, event, data) {

    
    let audioURL = API_URL + "/inupiaqaudiolibrary/" +  audio.replace('/','~');

    // stop audio on double click
    if (this.state.playingAudio === true && this.state.clickedAudioUrl === audioURL) {
      let audio = this.state.clickedAudio;
      audio.pause();
      audio.currentTime = 0;
      this.setState({clickedAudio:audio, playingAudio: false})
    }

    // stop first audio then play new one
    if (this.state.playingAudio === true && this.state.clickedAudioUrl !== audioURL) {
      let firstAudio = this.state.clickedAudio;
      firstAudio.pause();
      firstAudio.currentTime = 0;

      this.setState({playingAudio: true});
      let secondAudio = new Audio(audioURL);
      secondAudio.play();
      this.setState({clickedAudioUrl:audioURL, clickedAudio:secondAudio})
      secondAudio.onended=()=>{this.setState({playingAudio: false})};
    }

    // play audio again
    if (this.state.playingAudio === false && this.state.clickedAudioUrl === audioURL) {
      let audio = this.state.clickedAudio;
      this.setState({playingAudio: true });
      audio.play();
      audio.onended=()=>{this.setState({playingAudio: false})};
    }

    // play new audio
    if (this.state.playingAudio === false && this.state.clickedAudioUrl !== audioURL) {
      this.setState({playingAudio: true});
      let audio = new Audio(audioURL);
      audio.play();
      this.setState({clickedAudioUrl:audioURL, clickedAudio:audio})
      audio.onended=()=>{this.setState({playingAudio: false})};
    }

  }

  usageCalls(usageDetails,properties) {
    let tag = usageDetails[0]
    let index = usageDetails[1]
    
    // backend calls for usage
    if (tag === 'v') { // verb
      let verbBase = usageDetails[2]
      let verbTrans = usageDetails[3]
      if (index === 0) {
          // '>+V+Ind+Prs+3Sg'
          return [['Insert',['mv',], [verbBase, verbTrans, 'Ind+Prs']],]
      } else if (index === 1) {
        // '>+V+Ind+Pst+3Sg'
        if (properties.includes('non-human subject')) {
          // it subject
          return [['Insert',['mv',], [verbBase, verbTrans, 'Ind+Pst']],]
        } else {
          // she subject
          return [['Insert',['mv',], [verbBase, verbTrans, 'Ind+Pst']],['Update',['mv','vs'], [3,1,2]],]
        }
      } else if (index === 2) {
          // '>+V+Int+3Sg'
          return [['Insert',['mv',], [verbBase, verbTrans, 'Int', 'Intrg']],]
      } else if (index === 3) {
          // '>+V+Opt+3Sg'
        if (properties.includes('non-human subject')) {
          // it subject
          return [['Insert',['mv',], [verbBase, verbTrans, 'Opt']],]
        } else {
          // she subject
          return [['Insert',['mv',], [verbBase, verbTrans, 'Opt']],['Update',['mv','vs'], [3,1,2]],]
        }
      } else if (index === 4) {
          // '>+V+Imp+2Sg'
          return [['Insert',['mv',], [verbBase, verbTrans, 'Imp']],]
      }
    } else if (tag == 'n') { // noun
      let nounBase = usageDetails[2]
      if (index === 0) {
          // '>+N+Abs+Sg'
          return [['Insert',['np',], [nounBase, [0,0,0,1], 'Abs']],]
      } else if (index === 1) {
          // '>+N+Abs+Sg+1Sg'
          return [['Insert',['np',], [nounBase, [1,1,0,1], 'Abs']],]
      } else if (index === 2) {
          // '>+N+Loc+Sg'
          return [['Insert',['np',], [nounBase, [0,0,0,1], 'Loc']],]
      } else if (index === 3) {
          // una + '>+N+Abs+Sg'
          return [['Insert',['np',], [nounBase, [0,0,0,1], 'Abs']],['Insert',['np','nc'], [[["una",0,0]], [0,0,0,1]]]]
      }
    }
  }

  newBackEndCall = (keyChanged, firstTime) => {
    console.log(keyChanged)
    axios
      .post(API_URL + "/inupiaqsentencebuilder", {
      	keyChanged:keyChanged,
      	mv:this.state.mv,
      	cv:this.state.cv,
      	sv:this.state.sv,
      	np:this.state.np,
      })
      .then(response => {
      	// console.log(response.data)
      	this.setState({
      		mv:response.data.mv,
      		cv:response.data.cv,
      		sv:response.data.sv,
      		np:response.data.np,
      		combined:response.data.combined,
      		englishGroups:response.data.englishGroups,
      	})
      if ('np' in response.data) {
          if (Object.keys(response.data.np).length > 0) {
              this.setState({
                  npnCase:response.data.np.nCase,
              })
              if ('ncType' in response.data.np) {
                  this.setState({
                      ncType:response.data.np.ncType,
                  })
              } else {
                  this.setState({
                      ncType:"",
                  })                    
              }
          }
      } else {
        this.setState({
            ncType:"",
            npnCase:"",
        })             
      }
      if ('cv' in response.data) {
        this.setState({cvvBase:response.data.cv.vBase[0]})     
        this.setState({cvvBaseTransitivity:response.data.cv.vBase[1]})
      } else {
        this.setState({cvvBase:[]})              
      }
      if ('mv' in response.data) {
          if (Object.keys(response.data.mv).length > 0) {
              this.setState({
                  mvvMood:response.data.mv.vMood,
              })
          }
          this.setState({mvvBase:response.data.mv.vBase[0]})
          this.setState({mvvBaseTransitivity:response.data.mv.vBase[1]})
          // console.log(response.data.mv.vBase[0][0])
          let verbProperties = this.props.usagedictionary_dict[response.data.mv.vBase[0][0][0]][response.data.mv.vBase[0][0][1].toString()]['properties']
          // console.log(verbProperties)
          if (response.data.mv.vMood == 'Ind+Prs' && response.data.mv.vBase[0].length == 1 && (verbProperties.includes('yup-presentS') || verbProperties.includes('yup-past')) && !verbProperties.includes('yup-gerund')) {
            this.setState({ongoing:true})
          } else {
            this.setState({ongoing:false})            
          }
      } else {
          this.setState({
              mvvMood:"",
              mvvBase:[],
          })            
      }
      //initialize search results
      // console.log(keyChanged)
      if (keyChanged.length !== 0) {
        if (keyChanged[0][0] === 'Insert' || keyChanged[0][0] === 'Delete') {
            scroll.scrollTo(0);
            this.setState({
                activeIndexes:[],
            })
        }        
      }

      this.setState({
          wordsList: [],
          searchQuery: '',
          showModal:false,
      })

      if (firstTime) {
        this.setState({
            visible:true,
        })        
      }

      let demPresent = false
      let pointPresent = false
      let locativePresent = false

      this.state.englishGroups.map((k)=>{
        k.map((j)=>{
          if (j['type']==='dropdown') {
          // console.log(j, j['currentText'][0].includes('visible'))
            if (j['currentText'][0][0].includes('visible')) {
              demPresent = true
            }
            if (j['currentText'][0][0]=='to' || j['currentText'][0][0]=='toward' || j['currentText'][0][0]=='from' || j['currentText'][0][0]=='from the direction') {
              pointPresent = true
            }
            if (j['currentText'][0][0]=='located') {
              locativePresent = true
            }
          }
        })
      })
      this.setState({demPresent:demPresent, pointPresent:pointPresent, locativePresent:locativePresent})    
      


    })
    
  }



  handleClick = (index) => {
    const { activeIndexes } = this.state;
    let newIndex = this.state.activeIndexes.slice()
    const currentIndexPosition = activeIndexes.indexOf(index);

    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1);
    } else {
    	// if (allowAdd) {
      
      	newIndex.push(index); //this allows multiple open  
      	// newIndex = [index] //restricts open to one at a time

      	// this.setState({
	    		// searchQuery:'',
	    		// wordsList:[],      		
      	// })
    	// }
    }
 
    this.setState({ activeIndexes: newIndex });
  };

  returnEditedBackEndCall = (l, wordData, entry) => {
      // console.log(l, wordData, entry)
      let insertType = l['tag']
      if (l['tag'] === 'Conseq' || l['tag'] === 'Cond') {
          if (entry['tag'] === "t") {
              l['AddSomething'][0][2][1] = 't'
          } else {
              l['AddSomething'][0][2][1] = 'i'
          }
      }

      if (insertType === 'mvns' || insertType === 'mvno') {
          l['AddSomething'][0][2][0][0] = wordData
      } else {
          l['AddSomething'][0][2][0][0] = wordData            
      }
      // console.log(l['AddSomething'])
      return l['AddSomething']
  }

  onChangeSearch = (buttonkey,newWord,tag,event,data) => {
      let word
      // console.log(tag, buttonkey, newWord,event,data)
      if (buttonkey) {
          word = newWord
      } else {
          word = data.value
      }
    // let word
    // if (bool) {
    //   word = data
    // } else {
    //   word = data.value
    //   homeMode = this.state.homeMode
    // }

    //   let new_search, wordsList
    // word = word.replaceAll("’","'").replaceAll("ḷ","ḷ").replaceAll("ł̣","ł̣").replaceAll("G","ġ").replaceAll("g.","ġ").replaceAll("l.","ḷ").replaceAll("L","ł").replaceAll("ł.","ł̣");
    // new_search = word.replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('G','g').replaceAll('(','').replaceAll('-','').replaceAll(')','').toLowerCase().replaceAll('he is ','').replaceAll('she is ','').replaceAll('it is ','').replaceAll('i am ','').replaceAll(' ','').replaceAll(',','')

    // // console.log(word, new_search)
    //   if (homeMode === 1) {
    //       wordsList = fuzzysort.go(new_search, this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj));
    //   } else if (homeMode === 0) {
    //       wordsList = fuzzysort.go(new_search, this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj));
    //   } else if (homeMode === 2) {
    //       wordsList = fuzzysort.go(new_search, this.props.audiolibrary, optionsAudioFuzzy).map(({ obj }) => (obj));
    //   }
      let wordsList
    word = word.replaceAll("’","'").replaceAll("ḷ","ḷ").replaceAll("ł̣","ł̣").replaceAll("G","ġ").replaceAll("g.","ġ").replaceAll("l.","ḷ").replaceAll("L","ł").replaceAll("ł.","ł̣");
    let newSearch = word.replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('G','g').replaceAll('(','').replaceAll('-','').replaceAll(')','').toLowerCase().replaceAll('he is ','').replaceAll('she is ','').replaceAll('it is ','').replaceAll('i am ','').replaceAll(' ','').replaceAll(',','')
      // let newSearch = word.toLowerCase().replaceAll(/^is /g,'').replaceAll(/^am /g,'').replaceAll(/^are /g,'')

      let optionsFuzzy = {
        keys: ['usageWordNormal', 'thisDefinition'],
        limit: 10, // don't return more results than you need!
        threshold: -10000, // don't return bad results
      };

      let timeWordOptionsFuzzy = {
        keys: ['key', 'eng'],
        limit: 10, // don't return more results than you need!
        threshold: -10000, // don't return bad results
      };

      // console.log(tag, 'tag')
      if (tag === 'timeWord') {
          wordsList = fuzzysort.go(newSearch, timeWordsList, timeWordOptionsFuzzy).map(({ obj }) => (obj));
          this.setState({ wordsList: wordsList, searchQuery: word });  
      } else if (tag === 'Loc' || tag === 'Via' || tag === 'Sim' || tag === 'mvns' || tag === 'mvno' || tag === 'cvns' || tag === 'cvno' || tag === 'mvnObliques' || tag === 'posd' || tag === 'poss' || tag === 'nounnc') {
          wordsList = fuzzysort.go(newSearch, this.state.filteredDictN, optionsFuzzy).map(({ obj }) => (obj));
          this.setState({ wordsList: wordsList, searchQuery: word });     
      } else {
          wordsList = fuzzysort.go(newSearch, this.state.filteredDictV, optionsFuzzy).map(({ obj }) => (obj));
          this.setState({ wordsList: wordsList, searchQuery: word });
      }

      // if (tag === 'NOUN') {
      //     wordsList = fuzzysort.go(newSearch, this.state.filteredDictN, optionsFuzzy).map(({ obj }) => (obj));
      //     this.setState({ wordsList: wordsList, searchQuery: word });
      // } else {
      //     wordsList = fuzzysort.go(newSearch, this.state.filteredDictV, optionsFuzzy).map(({ obj }) => (obj));
      //     this.setState({ wordsList: wordsList, searchQuery: word });
      // }
  }

  returnPopularBases = (fullData, word, tag) => {
    let transitivizingPostbases = ['31','32','33','34','35','36','37']
    let verbBase = this.state.mvvBase
    if (tag.includes('cv')) {
      verbBase = this.state.cvvBase
    }
    let containsTransitivizingPostbases = false
    verbBase.map((k)=>{
      if (transitivizingPostbases.includes(k[0])) {
        containsTransitivizingPostbases = true
      }
    })
    let popularBaseVerbs = [
        ["niġi-",0,0],
        ["niġi-",1,0],
        ["kaak-",0,0],
        ["kaaliq-",0,0],
        ["isiq-",0,0],
        ["tautuk-",0,0],
        ["aullaq-",0,0],
    ]
    let popularBaseBeingsNouns = [
        ["aŋun",0,0],
        ["aġnaq",0,0],
        ["miqłiqtuq",0,0],
        ["qimmiq",0,0], // dog
        ["iqaluk(-),qaluk(-)",0,0], // fish
        ["iñuk(-)",0,0], // person
        ["nanuq",0,0],
        ["tuttu",0,0],
        ["ui",0,0], 
        ["nuliaq(-)",0,0], 
        ["tuvaaqan",1,0],
        ["qitunġaq",0,0],
        ["piayaaq",0,0],
        ["aapiyaq",0,0],
        ["aatauraq",0,0],
        ["aqqaluk",0,0],
        ["nayaaluk",0,0],
        ["nuka,nukaq,nukatchiaq,nukaaluk",0,4],
        // ["aññaq",0,0],
        ["iḷḷuq,iḷḷualuk",0,0],
        ["aaka",0,0],
        ["aapa",0,0],
        ["aakaaluk",0,0],
        ["aapaaluk",0,0],
        // ["iġñiq",0,0],
        ["panik",0,0],
        ["iñġutaq",0,0],
        ["tutik,tutaaluk",0,0],
        ["amau,amaułuk,amauluk",0,1],

    ]

    let popularBaseFoodNouns = [
        ["mikigaq",0,0],
        ["uunaalik",0,0],
        ["maktaaq",0,0],
        // ["akutuq",0,0],
        ["iqaluk(-),qaluk(-)",0,0], // fish
        ["suu",0,0],
        ["tuttu",0,0],
    ]

    // // isn't used?
    // let popularBaseVerbsInterrogative = [
    //     ["niġi-",0,0], // to eat something
    //     ["niġi-",1,0], // to eat it
    //     ["tautuk-",0,0], // to see something
    //     ["tautuk-",1,0], // to see it
    //     ["ikayuq-",0,0], // to help someone
    //     ["ikayuq-",1,0], // to help her
    //     ["atuq-²",0,0], // to use something
    //     ["atuq-²",1,0], // to use it
    //     ["uqallauti-",0,0], // to tell her
    //     ["tikit-",0,0], // to arrive
    //     ["tikit-",1,0], // to reach it
    //     ["savak-",0,0], // to work
    //     ["savak-",1,0], // to work on it
    //     ["iḷisaurri(-)",1,0], // to teach
    // ]

	  // // isn't used?
    // let popularBaseVerbsImperative = [
    //     ["isiq-",0,0], // come in
    //     ["qai-",0,0], // come here
    //     ["ani-",0,0], // to go out
    //     ["niġi-",0,0], // to eat something
    //     ["niġi-",1,0], // to eat it       
    //     ["tigu-",0,0], // to take it
    //     ["ai-²",0,0], // go home
    //     ["savak-",0,0], // to work
    //     ["savak-",1,0], // to work on it
    //     ["nakuu-",0,0], // be good
    //     ["nipaiq-",0,0], // be quiet
    //     ["utiq-",0,0], // to go back
    //     ["utiq-",1,0], // to go back for it
    // ]

    // // isn't used?
    // let popularBaseVerbsOptative = [
    //     ["isiq-",0,0], // to enter
    //     ["aullaq-",0,0], // to depart
    //     ["ani-",0,0], // to go out
    //     ["savak-",0,0], // to work
    //     ["savak-",1,0], // to work on it
    //     ["niġi-",0,0], // to eat something
    //     ["niġi-",1,0], // to eat it
    //     ["isaguti-",0,0], // to begin
    //     ["isaguti-",1,0], // to begin it
    //     ["utiq-",0,0], // to go back
    //     ["utiq-",1,0], // to go back for it
    // ]

    // let popularBaseVerbsConditional = [
    //     ["ai-²",0,0], // go home
    //     ["naat-¹",1,0], // to finish
    //     ["tautuk-",0,0], // to see something
    //     ["tautuk-",1,0], // to see it
    //     ["kaak-",0,0], // to be hungry
    //     ["uqallak-¹",0,0], // to speak
    //     ["ani-",0,0], // to go out
    // ]

    let popularBaseVerbsConsequentialCond = [
        // same as conditional?
        ["ai-²",0,0], // go home
        ["naat-¹",1,0], // to finish
        ["tautuk-",0,0], // to see something
        ["tautuk-",1,0], // to see it
        ["kaak-",0,0], // to be hungry
        ["uqallak-¹",0,0], // to speak
        ["ani-",0,0], // to go out

        ["ikit-¹",2,0],
        ["qamit-",2,0],
        ["agmaq-,aŋmaq-",1,0],
        ["umik(-)¹",4,0], 
        ["unit-",0,0], 
        ["kaluq-",0,0], 
        ["itiq-",0,0], 
        ["siqu-",0,0], 
        ["makit-",1,0], 
        ["tuttaaq-",0,0], 
        ["nallaq-",0,0], 
        ["makit-",0,0], 
        ["quviasuk-",0,0], 
        ["quviit-",0,0], 
        ["ipiqtusuk-",0,0], 
        ["miquq-",1,0], 
        ["kiluaq-",1,0], 
        ["qiḷak-²",0,0], 
        ["miquq-",1,0], 

        // // ["nigiqpaq(-)",0,0], // nigiqpaq(-) east wind (LS: fst error)
        // ["nigiqłuk(-)",0,0], // nigiqłuk(-) blizzard from east
        // ["siḷaluk(-),sialuk(-)",0,1], // sialuk(-) rain, to rain
        // // ["anuqłiq-",0,0], // anuqłiq(-) wind, be windy (LS: fst error)
        // ["kalluk(-)",0,0], // kalluk(-) thunder, to thunder
        // ["auk(-)¹",0,0], // auk(-) blood, bleed
        // ["nuvak(-)",0,0], // nuvak(-) cold, have a cold
        // ["iqaluk(-),qaluk(-)",0,0], // iqaluk(-) fish, catch a fish
        // ["mikigaq",0,0], // mikigaq fermented whale meat and maktak

        // ["niglaq-",0,0], // niglaq- become cool

        // ["uunaq-",0,0], // uunaq- be hot
        // ["qiiyanaq-",0,0], // qiiyanaq- be cold
        // ["siḷaluk(-),sialuk(-)",1,1], // sialuk(-) rain, to rain (LS: missing (i/t))
        // // ["anuqłiq-",0,0], // anuqłiq(-) wind, be windy (LS: fst error)
        // ["kalluk(-)",1,0], // kalluk(-) thunder, to thunder
        // ["aiḷaq-",0,0], // aiḷaq- to be wet
        // // ["qiqit-",0,0], // qiqit- to freeze (LS: missing (i/t))
        // // ["auk-",0,0], // auk- to melt (LS: no object in (t) definition error)
        // ["auk(-)¹",1,0], // auk(-) blood, bleed
        // ["mamit-",0,0], // mamit- to heal
        // ["naŋit-¹",0,0], // naŋit- be sick
        // ["nakuu-",0,0], // nakuu- be good, well, satisfactory
        // ["nuvak(-)",2,0], // nuvak(-) cold, have a cold
        // // ["uunnautiqaq-",0,0], // uunnautiqaq- have a fever (LS: uunnaun has uunnautiqaqtuq example)
        // ["uunnait-,uunnautait-",0,1], // uunnautait- not have a fever
        // ["quiqsuq-",0,0], // quiqsuq- to cough
        // // ["miġiaġaq-",0,0], // muġiaġaq- to vomit (LS: miġiaq(-),muġiaq(-),miġġiq to vomit, miġiaġaq- to vomit repeatedly fst error)
        // // ["qauri-",0,0], // qauri- gain consciousness (LS: fst error)
        // ["qaurimaiq-",0,0], // qaurimaiq- to lose consciousness

        // ["aqiattuq-",1,0], // aqiattuq- become satisfied, full of food “stomach is satisfied”

        // ["tuttuliaq-",0,0], // tuttuliaq- go caribou hunting
        // ["tuttut-",0,0], // tuttut- kill a caribou
        // ["iqaluk(-),qaluk(-)",1,0], // iqaluk(-) fish, catch a fish
        // ["iqalliaq-",0,0], // iqalliaq- go fishing
        // // ["natchiḷiaq-",0,0], // natchiḷiaq- go seal hunting (LS: combined postbase)
        // // ["ugruksiuq-",0,0], // ugruksiuq- be bearded seal hunting (LS: combined postbase)
        // // ["ugruksiuġiaq-",0,0], // ugruksiuġiaq- go bearded seal hunting (LS: combined postbase)
        // ["aġviqsiuq-,aġviġniaq-",0,0], // aġviqsiuq- be whaling
        // ["ataaqtuq-",0,0], // ataaqtuq- to be at whaling camp on the ice
        // // ["aiviqsiuq-",0,0], // aiviqsiuq- be walrus hunting (LS: combined postbase)
        // ["aivvak-",0,0], // aivvak- kill a walrus 
        // // ["qaukkiqi-",0,0], // qaukkiqi- be duck hunting (LS: qaukkiaq-)
        // // ["niġliaq-",0,0], // niġliaq- go geese hunting (LS: combined postbase)

        // // ["mikigaqtullatu-",0,0], // mikigaqtullatu- to love to eat mikigaq LS: combined postbase

        // ["aqpat-",0,0], // aqpat-to run
        // ["paammak-",0,0], // paammak- to crawl
        // ["makita-",0,0], // makita- be standing
        // // ["aksraliuk-",0,0], // aksraliuk- to roll (LS: aksrak-; aksralik-,aksralui-)
        // ["minŋiq-,mitŋiq-",0,0], // minŋiq- to jump
        // ["tiŋi-",0,0], // tiŋi- to fly away, take off
        // ["mitchaaq-",0,0], // mitchaaq- to land from flight
        // ["aullaq-",0,0], // aullaq- to leave
        // ["nutqaq-",0,0], // nutqaq- to stop

    ]

    let popularBaseNounsLocative = [
        ["iglu(-)",0,0], // house
        ["natiq(-)¹",0,0], // floor
        ["Utqiaġvik,Utqiaġviit",0,0], // barrow
        ["Tikiġaq",0,0], // point hope
        // ["kuuk¹",0,0], // river
        ["siku(-)¹",0,0], // ice 
        ["iġġi,iġġiq",0,0], // mountain
        ["tauqsiġñiaġvik,tauqsiġiaġvik",0,0], // store
        ["tupiq(-)",0,0], // tent
    ]
    // filter nouns by physical objects
    // same for ablative and terminalis

    let popularBaseNounsVialis = [
        // mode of transportation
        ["umiaq(-)",0,0],
        ["tiŋŋun",0,0],
        ["qamun",0,0],
        // via area, place, thing
        ["nuna",0,0], // land
        ["siku(-)¹",0,0], // ice 
        // ["kuuk¹",0,0], // river
        ["apqun",0,0], // road

    ]
    // filter nouns by physical objects
    // "physical_entity"

    let popularBaseNounsSimilaris = [
        ["Iñupiaq",0,0],
        ["Tanik",1,0], // white person
        ["aŋun",0,0], // male
        ["aġnaq",0,0], // female
        ["qimmiq",0,0], // dog
        ["iqaluk(-),qaluk(-)",0,0], // fish
        ["iñuk(-)",0,0], // person
    ]


    // filter by physical?
    // "physical_entity"

    // what is the noun marker for a sentient being? 
    // "animate"

    // console.log(fullData, word, tag)
    if (tag === 'Conseq') {
        return popularBaseVerbsConsequentialCond
    } else if (tag === 'Cond') {
        return popularBaseVerbsConsequentialCond        
    } else if (tag === 'Loc') {
        return popularBaseNounsLocative        
    } else if (tag === 'Via') {
        return popularBaseNounsVialis        
    } else if (tag === 'Sim') {
        return popularBaseNounsSimilaris        
    } else if (tag === 'mvns' || tag === 'mvno' || tag === 'cvns' || tag === 'cvno' || 'mvnObliques') {
        if ((tag === 'mvno' || tag === 'cvno') && (verbBase[0][0] == "niġi-" && !containsTransitivizingPostbases)) { // not valid for the with___ case
            return popularBaseFoodNouns                    
        } else {
            return popularBaseBeingsNouns                    
        }
    } else if (tag === 'posd' || tag === 'poss') {
        return popularBaseBeingsNouns
    } 
    // else if (tag === 'Int') {
    //     return popularBaseVerbsInterrogative        
    // } else if (tag === 'Imp') {
    //     return popularBaseVerbsImperative       
    // } else if (tag === 'Opt') {
    //     return popularBaseVerbsOptative        
    // } 
    else {
        return popularBaseVerbs
    }
  }

  searchWord = (fullData, word, tag) => {
    // console.log(fullData, word, tag, this.state)
    // console.log(this.state.wordsList)
    let unatext = 'una'
    if (tag == 'cvns') {
      if (this.state.cvvBaseTransitivity == 't') {
        unatext = 'uuma'
      }
    } else if (tag == 'cvno') {
      if (this.state.cvvBaseTransitivity == 'it') {
        unatext = 'uumiŋa'
      }
    } else if (tag == 'mvns') {
      if (this.state.mvvBaseTransitivity == 't') {
        unatext = 'uuma'
      }
    } else if (tag == 'poss' ) {
        unatext = 'uuma'
    } else if (tag == 'mvno') {
      if (this.state.mvvBaseTransitivity == 'it') {
        unatext = 'uumiŋa'
      }
    } else if (tag == 'nes') {
      unatext = 'uumuŋa'
    }

		return <Modal
			      trigger={<span />}
			      on='click'
			      // position='bottom center'
			      // positionFixed
			      open={this.state.showModal}
			      style={{
			      	maxWidth:500,
                    top:(window.innerWidth < 480 ? 10 : window.innerHeight/4),
			      }}
			      // ref={(element)=>{this.subjectMenu[tag]=element;}}
			      onOpen={()=>{
			      	// this.setState({
			      		// currentlyOpen:tag,
			      		// overlayOn:true,
								// candidateCall:[],
								// candidateBase:[],
								// candidateDisplay:[],
								// unallowable_next_ids:[],
								// nextTenses:[],			      		
			      	// })
			      }}
			      onClose={()=>{
			      	this.setState({showModal:false,wordsList:[],searchQuery:''})
			      }}
			      content={


						        <div style={{fontSize:'16px',paddingTop:8}}>
            						        <Input 
            						        // icon='search' 
            						        // iconPosition='left' 
                                            icon={this.state.searchQuery.length > 0 ? <Icon style={{marginRight:'7px'}} name='x' color='grey' onClick={()=>{this.setState({searchQuery:''})}} link /> : null}
            						        name='search'     
            						        autoComplete='off'
            						        autoFocus

            						        placeholder={word}
            						        // width='100%'
                                            ref={this.handleRef}
            						        style={{width:'100%',padding:'0px 7px',fontSize:'18px'}}
								                value={this.state.searchQuery}
                                            onChange={this.onChangeSearch.bind(this,false,null,tag)}
								            // onClose={()=>{this.setState({searchQuery:'',wordsList:[]})}}
								            />
                                              <div style={{display:'flex',justifyContent:'space-evenly',flexDirection:'row',marginTop:'3px'}}>
                                                <Button.Group basic>
                                                  <Button onClick={()=>{this.handleRef.current.focus(); this.onChangeSearch(true,this.state.searchQuery+'ġ',tag)}} style={letterbutton}>ġ</Button>
                                                  <Button onClick={()=>{this.handleRef.current.focus(); this.onChangeSearch(true,this.state.searchQuery+'ḷ',tag)}} style={letterbutton}>ḷ</Button>
                                                  <Button onClick={()=>{this.handleRef.current.focus(); this.onChangeSearch(true,this.state.searchQuery+'ł',tag)}} style={letterbutton}>ł</Button>
                                                  <Button onClick={()=>{this.handleRef.current.focus(); this.onChangeSearch(true,this.state.searchQuery+'ł̣',tag)}} style={letterbutton}>ł̣</Button>
                                                  <Button onClick={()=>{this.handleRef.current.focus(); this.onChangeSearch(true,this.state.searchQuery+'ñ',tag)}} style={letterbutton}>ñ</Button>
                                                  <Button onClick={()=>{this.handleRef.current.focus(); this.onChangeSearch(true,this.state.searchQuery+'ŋ',tag)}} style={letterbutton}>ŋ</Button>
                                                </Button.Group>
                                                {tag == 'mvns' || tag == 'cvns' || tag == 'mvno' || tag == 'cvno' || tag == 'nes' || tag == 'poss' ?
                                                  <Button.Group vertical basic fluid>
                                                  <Button style={{textAlign:'left',fontFamily:customFontFam,padding:'10px',paddingRight:'2px',fontSize:'16px'}} onClick={()=>{this.newBackEndCall([["Insert", fullData['AddSomething'][0][1], [[["una",0,0],], [0,0,0,1]]]],)}}>
                                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                        <div style={{fontWeight:500,color:'#000000DE'}}>
                                                        <span>{unatext}</span>
                                                        </div>
                                                        {window.innerWidth > 550 ?
                                                          <div style={{marginLeft:'8px',color:'#000000CC'}}>{'this one here...'}</div>
                                                          :
                                                          null
                                                        }
                                                    <div style={{margin:0,marginLeft:'10px'}}>
                                                      <Icon style={{color:'#a3a3a3',fontSize:'18px'}} name='chevron right' />
                                                    </div>   
                                                    </div>

                                                  </Button>
                                                  </Button.Group>
                                                  :
                                                  null
                                                }
                                              </div>
								            		{this.state.wordsList.length > 0 ?
								            			<span>
													      <Segment vertical style={{maxHeight:220,overflow: 'auto',padding:0,margin:"4px 7px",borderBottom:'0px solid #e2e2e2'}}>
												    		<Button.Group vertical basic fluid>
													      	{this.state.wordsList.map((k,index)=>{
                                      // let url = ''
                                      // let thisbackendCall = []
                                      // let thisDefinition = ''
                                      if (tag == 'timeWord') {
                                        // url = k.key
                                        // thisbackendCall = [["Insert", ["mv","timeWord"], [k.key,0,0]]]
                                        // thisDefinition = k.eng
                                        return <Button style={{textAlign:'left',fontFamily:customFontFam,padding:'10px',fontSize:'16px'}} onClick={()=>{this.newBackEndCall([["Insert", ["mv","timeWord"], [k.key,0,0]]])}}>
                                                <div>
                                                    <div style={{marginBottom:'6px',fontWeight:500,color:'#000000DE'}}>
                                                    <span>{k.key.replace(',',', ')}</span>
                                                    </div>
                                                    <div style={{marginLeft:'8px',color:'#000000CC'}}>{this.processStyledText(k.eng)}</div>
                                                </div>
                                                  <div style={{position:'absolute',right:'2px'}}>
                                                    <Icon style={{color:'#a3a3a3',fontSize:'18px',paddingTop:'9px'}} name='chevron right' />
                                                  </div>   
                                         </Button>
                                      } else {
                                        // url = k.url
                                        // thisbackendCall = this.returnEditedBackEndCall(fullData,[k['url'],k['num'],0], k)
                                        // thisDefinition = k.thisDefinition
                                        return <Button style={{textAlign:'left',fontFamily:customFontFam,padding:'10px',fontSize:'16px'}} onClick={()=>{this.newBackEndCall(this.returnEditedBackEndCall(fullData,[k['url'],k['num'],0], k))}}>
                                                <div>
                                                    <div style={{marginBottom:'6px',fontWeight:500,color:'#000000DE'}}>
                                                    <span>{k.url.replace(',',', ')}</span>
                                                    </div>
                                                    <div style={{marginLeft:'8px',color:'#000000CC'}}>{this.processStyledText(k.thisDefinition)}</div>
                                                </div>
                                                  <div style={{position:'absolute',right:'2px'}}>
                                                    <Icon style={{color:'#a3a3a3',fontSize:'18px',paddingTop:'9px'}} name='chevron right' />
                                                  </div>   
                                         </Button>
                                      }
													      	})}
												    		</Button.Group>
													      </Segment>
                                      <div style={{height:'4px'}}>
												    		</div>
												    	</span>
												      :
                              (this.state.searchQuery.length > 0 ?
                                  <div style={{fontStyle:'italic',color:'#737373',margin:"14px 18px"}}>{'No results...'}</div>
                                  :
                                  <span>
                                    <Segment vertical style={{maxHeight:220,overflow: 'auto',padding:0,margin:"4px 7px",borderBottom:'0px solid #e2e2e2'}}>
                                      {tag == 'timeWord' ?
                                      <Button.Group vertical basic fluid>
                                      {timeWordsList.slice(0,10).map((p)=>{
                                              return <Button style={{textAlign:'left',fontFamily:customFontFam,padding:'10px',fontSize:'16px'}} onClick={()=>{this.newBackEndCall([["Insert", ["mv","timeWord"], [p.key,0,0]]],)}}>
                                                      <div>
                                                          <div style={{marginBottom:'6px',fontWeight:500,color:'#000000DE'}}>
                                                          <span>{p.key.replace(',',', ')}</span>
                                                          </div>
                                                          <div style={{marginLeft:'8px',color:'#000000CC'}}>{this.processStyledText(p.eng)}</div>
                                                      </div>
                                                        <div style={{position:'absolute',right:'2px'}}>
                                                          <Icon style={{color:'#a3a3a3',fontSize:'18px',paddingTop:'9px'}} name='chevron right' />
                                                        </div>   
                                               </Button>
                                      })}
                                      </Button.Group>
                                      :
                                      <Button.Group vertical basic fluid>
                                      {this.returnPopularBases(fullData, word, tag).map((p)=>{
                                          let entry = this.props.usagedictionary_dict[p[0]][p[1]]
                                              return <Button style={{textAlign:'left',fontFamily:customFontFam,padding:'10px',fontSize:'16px'}} onClick={()=>{this.newBackEndCall(this.returnEditedBackEndCall(fullData,p,entry))}}>
                                                   
                                                      <div>
                                                          <div style={{marginBottom:'6px',fontWeight:500,color:'#000000DE'}}>
                                                          <span>{entry.url.replace(',',', ')}</span>
                                                          </div>
                                                          <div style={{marginLeft:'8px',color:'#000000CC'}}>{this.processStyledText(entry.thisDefinition)}</div>
                                                      </div>
                                                        <div style={{position:'absolute',right:'2px'}}>
                                                          <Icon style={{color:'#a3a3a3',fontSize:'18px',paddingTop:'9px'}} name='chevron right' />
                                                        </div>   
                                               </Button>
                                      })}
                                      </Button.Group>
                                      }
                                    </Segment>
                                      <div style={{height:'4px'}}>
                                      </div>
                                  </span>
                                  )

												    }

										</div>


			      	}
			    />
  }

	getEnglishGroups = () => {

	return this.state.englishGroups.map((k)=>{
		return <div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',alignItems:'center',flexWrap:'wrap',lineHeight:'20px',padding:'0px 10px',fontSize:'18px'}}>
		{k.map((j)=>{
      if (Object.keys(j).length != 0) {
        if (j['type']==='dropdown') {
          return  <Dropdown inline scrolling style={{fontFamily:customFontFam, margin:'4px 2px',border:'solid 1px #22242626',color:colorsList[j['color']],fontSize:'18px',padding:'5px',borderRadius:'5px'}} text={j['currentText'].map((k)=><span style={{color:colorsList[k[1]]}}>{k[0]}</span>)}>
                <Dropdown.Menu style={{maxHeight:130}}>
                  {j['dropdownData'].map((l,lindex)=>{                                    
                    if ('AddSomething' in l) {
                      return <Dropdown.Item  onClick={()=>{this.setState({newModalInfo:l},()=>{this.setState({showModal:true})})}}>
                              <span style={{fontSize:'14px'}}> {l['ClickDescription']}</span>
                             </Dropdown.Item>
                    } else {
                      return <Dropdown.Item active={j['currentText'][0][0]===l['ClickDescription']} value={l['value']} onClick={()=>{this.newBackEndCall(l['backEndCall'])}}>{l['ClickDescription']}</Dropdown.Item>                                 
                    }
                  })}
                </Dropdown.Menu>
              </Dropdown>
        } else { // if (j['type']==='text') {
          return j['content'].map((k)=>{
            return <span style={{margin:'4px 2px',lineHeight:'32px',textAlign:'center',color:colorsList[k[1]]}}>{k[0]}</span>
          })
        }               
      }
		})}
		</div>
	})

	}

  newUsageBaseDisplay = (word) => {
    // console.log(word)
    console.log(this.state)
    let returnNewUsageBaseDisplayLabel = {
      'verb phrase':[
        'present',
        'past',
        'question',
        'polite command',
        'command',
      ],
      'noun':[
        'stem',
        'possessive',
        'locative noun case',
        'with pronoun',
      ],
    }


    return  <List.Item style={{paddingBottom:'8px',paddingTop:'15px'}}>
              <List.Content style={{fontSize:'16px',paddingBottom:'10px'}}><span style={{paddingRight:'20px',fontWeight:'500'}}>{word.url.replaceAll(',',', ')}</span><span style={{paddingRight:'15px',fontStyle:'italic'}}>{this.processStyledText(word.thisDefinition)}</span><span style={{'marginLeft':'15px',marginRight:'6px',cursor:'pointer'}}><TagColors tags={word.t} width={window.innerWidth} /></span></List.Content>
              {word.newUsageDefinitions.map((u,uindex)=>
              <List.Content style={{paddingLeft:'20px'}}>
                {/*{console.log('initBackendCall', word.backendCalls[uindex])}*/}
              <List.Content onClick={()=>{scroll.scrollTo(0); this.newBackEndCall(word['backendCalls'][uindex])}} floated='right'>
                <Icon circular style={{cursor:'pointer',marginTop:'3px', color:'#8f8f8f'}} size='small' name='chevron right' />
              </List.Content>
                <List.Content style={{display:'flex',fontSize:'16px',paddingBottom:'8px',fontFamily:customFontFam,lineHeight:'25px'}}>
                  <span style={{flex:2,'paddingRight':'3px',fontWeight:'500'}}>
                  <span onClick={()=>{scroll.scrollTo(0);this.newBackEndCall(word['backendCalls'][uindex])}} style={{cursor:'pointer',borderBottom:'1px solid #858585',paddingBottom:'1px',color:'#306190'}}>
                  {this.processStyledTextLink(word.usageWords[uindex])}
                  </span>
                  {word.audioMatrix[uindex] ?
					<Button onClick={()=>this.repeatAudio(word.audioMatrix[uindex])} style={{paddingLeft:'7px',fontSize:'14px',color:'#4e7498'}} icon='volume up' />
					:
					null
				  }
                  </span>
                  <span style={{flex:5,fontSize:'16px',color:'#000000cc',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processStyledText(word.newUsageDefinitions[uindex])}</span>
                  <span style={{flex:3,color:'#BEBEBE',fontStyle:'italic',textAlign:'right'}}>{returnNewUsageBaseDisplayLabel[word.t][uindex]}</span>
                </List.Content>
              </List.Content>
              )}
            </List.Item>


                            // <List.Item style={{padding:7}} key={i}>
                            //   <List.Content style={{cursor:'pointer'}} onClick={()=>this.newBackEndCall(this.state.entryUsageData[i]['backendCalls'][jindex])} floated='right'>
                            //     <Icon circular style={{marginTop:'8px', color:'#8f8f8f'}} size='large' name='chevron right' />
                            //   </List.Content>
                            //   <List.Content  style={{paddingRight:'16px'}}>
                            //     <List.Header style={{fontSize:'19px',paddingBottom:'4px',paddingLeft:'15px',fontFamily:customFontFam,lineHeight:'25px'}}>
                            //             <div> 
                            //           <span style={{'paddingRight':'3px',fontWeight:'400'}}>
                            //             <span style={{borderBottom:'1px solid #858585',paddingBottom:'1px',color:'#306190',cursor:'pointer'}} onClick={()=>this.newBackEndCall(this.state.entryUsageData[i]['backendCalls'][jindex])}>
                            //           {j.replace('>','').replace('&','').replace('↞','').replace('↠','')}
                            //           </span>
                            //             <span style={{'marginLeft':'15px',marginRight:'6px',cursor:'pointer'}}>  
                            //         {/*<TagColors tags={this.state.entryUsageData[i]['tag']} width={window.innerWidth} />*/}
                            //             </span>

                            //           </span>
                            //           </div>
                            //     </List.Header>
                            //     <List.Description style={{fontSize:'16px',color:'#000000cc',paddingLeft:'15px',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>
                            //         {this.state.entryUsageData[i]['newUsageDefinitions'][jindex]}
                            //     </List.Description>
                            //   </List.Content>
                            // </List.Item>



  }

	render() {
		// console.log("props",this.props)
		// console.log("state",this.state)
		// console.log(this.state.colorsList)

		let justOne = this.state.combined.words.length === 1 && false

		return (
			<div style={{fontFamily:customFontFam}}>

            {this.state.showModal ?
                this.searchWord(this.state.newModalInfo,this.state.newModalInfo['ClickDescription'],this.state.newModalInfo['tag'])
                :
                null
            }

			<Transition visible={this.state.visible} animation='fade' duration={300}>

      <Grid textAlign='center'>
      <Grid.Row  style={{height:40,paddingBottom:0}}>
      <Grid.Column style={{ maxWidth: 800, padding: 10, paddingBottom:30 }} textAlign='left'>

		<div style={{display:'flex'}}>
	      <div style={{flex:1,justifyContent:'flex-start'}}>
{/*					<Link to={{pathname: '/'}}>
					Ivaqłiqsatqiun Allanik Uqaluŋnik - Back to Search
					</Link>*/}

					{this.state.from === '' ? 
						<Link to={{pathname: '/'}}>
		          <Icon circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
						</Link>
						:
						(this.state.from === '/' ?
							<Link to={{pathname: "/", state: { history:this.state.history }}} >
		          	<Icon circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
		          </Link>
							:
	          	<Icon onClick={()=>{this.props.history.goBack()}} circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
						)
					}

					{this.state.from === '/' ?
						<Link to={{pathname: "/", state: { history:['', [], 0, 1] }}} >
	          <Icon circular style={{margin:0,marginLeft:5,color:'#B1B1B1',cursor:'pointer'}} size='large' name='search' />
						</Link>
						:
						<Link to={{pathname: '/'}}>
	          <Icon circular style={{margin:0,marginLeft:5,color:'#B1B1B1',cursor:'pointer'}} size='large' name='search' />
						</Link>
					}



	      </div>


	        <div style={{flex:1,display:'flex',justifyContent:'flex-end',paddingTop:5}}>
					<Link to={{pathname: '/'+ this.state.entryUrl}}>
					<span style={{fontSize:'20px',fontWeight:'500',paddingBottom:'2px',borderBottom:'1px solid #858585',color:'#306190'}}>
					{this.state.entryUrl.replaceAll(',',', ')}
					</span>
					</Link>
				</div>
			</div>

					<div style={{border:'1px solid #E3E3E3',marginTop:'20px'}}>

					<div className='hierarchymain'>
					<span className='span1'>Uqaluliuġun</span>
					<span className='span2'>Word Builder</span>
					</div>
							<div>
								<div style={{marginTop:'30px',marginBottom:'10px',fontSize:'28px',color:'#000000',fontWeight:'400'}}>
								{this.state.combined.words.map((word, w)=>
									<div>
										<div style={{display:'flex',justifyContent:'center',alignItems:'center',lineHeight:'55px'}}>
                                            {word.clickable ?
    											<div onClick={()=>{this.handleClick(w)}} style={{cursor:'pointer', display:'flex',justifyContent:'center',alignItems:'center', width:(window.innerWidth > 550 ? 80:66)}}>
    												<Icon style={{height:'100%',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name={this.state.activeIndexes.includes(w) ? 'caret down':'caret right'} />
    											</div>
                                                :
                                                <div style={{width:(window.innerWidth > 550 ? 80:66)}} />
                                            }
                                            {word.clickable ?
    											<div onClick={()=>{this.handleClick(w)}} style={{flex:1,cursor:'pointer', maxWidth:350,textAlign:'center'}}>
    											{word.inupiaq[0].map((k)=>{
    												return <span style={{fontSize:(window.innerWidth < 550 ? '26px':'28px'),color:colorsList[k[1]]}}>{k[0]}</span>
    											})}						
    											</div>
                                                :
                                                <div style={{flex:1,maxWidth:350,textAlign:'center'}}>
                                                {word.inupiaq[0].map((k)=>{
                                                    return <span style={{fontSize:(window.innerWidth < 550 ? '26px':'28px'),color:colorsList[k[1]]}}>{k[0]}</span>
                                                })}                     
                                                </div>
                                            }
											<div style={{display:'flex',justifyContent:'center',alignItems:'center',width:(window.innerWidth > 550? 80:66)}}>
												{word.sound ?
													<Icon circular onClick={()=>this.repeatAudio(word.sound)} style={{fontSize:(window.innerWidth > 550? '18px':'14px'),color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
													:
													<div />
												}	
												<Icon circular onClick={()=>{this.newBackEndCall(word.deleteCall)}} style={{fontSize:(window.innerWidth > 550? '18px':'14px'),color:'#8F8F8F',cursor:'pointer'}} name='x' />
											</div>
										</div>

										{(this.state.activeIndexes.includes(w) && word.clickable) || justOne ? 
											<div style={{backgroundColor:(justOne ? 'white':'#F1F6FF'),paddingBottom:( word.dropdownButtons.length > 0 ? '15px' : '0px')}}>
												<div style={{display:'flex',justifyContent:'center',padding:'10px'}}>
													<div> 



                                                        <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',fontSize:'20px',marginBottom:'10px',fontWeight:'300'}}> 
                                                            {word.fst.map((j,index)=>{
                                                                return <span style={{fontFamily:'Times New Roman',margin:'10px',marginRight:(index !== 0 && index !== word.fst.length-1 ? '0px' : '10px')}}>
                                                                    <Popup on='click' position='bottom' content={
                                                                        <span style={{fontFamily:'Times New Roman',fontSize:'17px'}}>
                                                                            {j[2][0].map((l,lindex)=>{
                                                                                return <span style={{color:colorsList[l[1]]}}>{this.processStyledText(l[0])}</span>
                                                                            })}
                                                                            {j[2][2] !== "" ?
                                                                                <div style={{color:'#7b7b7b',fontSize:'16px',paddingTop:'10px'}}>
                                                                                    <div>
                                                                                        {j[2][1]}
                                                                                    </div>
                                                                                    <div>
                                                                                        {j[2][2]}
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }

                                                                        </span>
                                                                    } trigger={
                                                                        <span style={{height:'28px'}}>
                                                                            {j[0].map((k,kindex)=>{
                                                                                return <span style={{cursor:'pointer',color:colorsList[k[1]], paddingBottom:'3px', borderBottom:'2px solid '+colorsList[k[1]]}}>{this.processStyledText(k[0].replace(',',', '))}</span>
                                                                            })}
                                                                        </span>
                                                                    } />
                                                                    { index === 0 && 'changeBaseDropdown' in word ?
                                                                      <Dropdown inline scrolling style={{fontFamily:customFontFam,color:'#000000',fontSize:'18px',paddingLeft:'2px'}} text={''}>
                                                                        <Dropdown.Menu style={{maxHeight:140}}>
                                                                          {word['changeBaseDropdown']['dropdownData'].map((l,lindex)=>{                                    
                                                                              return <Dropdown.Item active={word['changeBaseDropdown']['activeIndex']===l['value']} value={l['value']} onClick={()=>{this.newBackEndCall(l['backEndCall'])}}>{l['ClickDescription']}</Dropdown.Item>                                 
                                                                          })}
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                      :
                                                                      null
                                                                    }

                                                                    {index !== 0 && index !== word.fst.length-1 ?
                                                                        (word.activeButtons[index-1]['DisplayForm'] !== ":uti-" ?
                                                                            <Icon circular onClick={()=>{this.newBackEndCall(word.activeButtons[index-1]['backEndCall'])}} style={{marginTop:'3px',fontSize:'14px',color:colorsList[word.activeButtons[index-1]['color']],cursor:'pointer',backgroundColor:'white',marginLeft:'7px'}} name='x' />
                                                                            :
                                                                            <div style={{width:42}} />
                                                                        )
                                                                        :
                                                                        null
                                                                    }
                                                                </span>
                                                            })}
                                                        </div>

													</div>
												</div>
                                                {word.alternates.length > 0 || word.inupiaq.length > 1 ?
                                                    <div style={{display:'flex',flexWrap:'wrap',fontFamily:'Times New Roman',justifyContent:'center',paddingBottom:'15px'}}>
                                                        <span style={{color:'#737373', fontSize:'16px'}}>{'alternates: '}</span>
                                                        {word.inupiaq.map((j,jindex)=>
                                                            (jindex !== 0 ?
                                                                <span style={{fontSize:'16px', color:'#737373',paddingLeft:7}}>
                                                                    {j.map((p)=><span>{p[0]}</span>)}
                                                                    {word.alternates.length === 0 && jindex === word.inupiaq.length-1 ? null:<span>{', '}</span>}
                                                                </span>
                                                                :
                                                                null
                                                            )
                                                        )}
                                                        {word.alternates.map((k,kindex)=> <span style={{color:'#737373', paddingLeft:7, fontSize:'16px'}}>{k.map((p)=>{return <span>{p[0]}</span>})}{word.alternates.length-1 !== kindex ? <span>{', '}</span> : null}</span>)}                                                               
                                                    </div>
                                                    :
                                                    null
                                                }
		

{/*												{word.activeButtons.map((k)=>{
													return <div style={{display:'flex',justifyContent:'center',paddingBottom:'20px',paddingRight:'30px',paddingLeft:'30px'}}>
													<Button fluid style={{display:'flex',maxWidth:'350px',justifyContent:'space-between',paddingLeft:'14px', paddingRight:'3px', color:colorsList[k.color], border:'1px solid '+colorsList[k.color],backgroundColor:'white'}} onClick={()=>{this.newBackEndCall(k.backEndCall)}}>
													<span>{k.DisplayForm}</span>
													<span>{k.ClickDescription}</span>
													<Icon name='delete'/>
													</Button>
													</div>
												})}	*/}
												{justOne ? 
													<div style={{marginTop:'20px',marginBottom:'30px',}}>
													{this.getEnglishGroups()}
													</div>
													:
													null
												}

												{word.dropdownButtons.map((k)=>{
													return <div style={{fontFamily:'Times New Roman',marginBottom:'10px',fontSize:'17px'}}>
														<div style={{display:'flex',justifyContent:'center',paddingRight:'30px',paddingLeft:'30px'}}>
														  <Dropdown
														  	style={{maxWidth:'350px',paddingBottom:10,paddingTop:10}}
														    text={()=><div style={{display:'flex', flexDirection:'column'}}><span>{k['inupiaq']}</span><span style={{color:'#737373',paddingTop:'4px',fontSize:'16px'}}>{k['english']}</span></div>}
														    // text='Dropdown'
														    fluid
														    selection
														    // value={this.state.postbase1}
														    selectOnBlur={false}
														    
														    // onChange={this.addPostbase.bind(this)}
														  >
															  <Dropdown.Menu style={{maxHeight:140}}>
                                                                
															  	{k.dropdownData.map((j)=>{
                                                                    if ('AddSomething' in j) {
                                                                        return <Dropdown.Item style={{fontSize:'16px'}} onClick={()=>{this.setState({newModalInfo:j},()=>{this.setState({showModal:true})})}}>
                                                                                <div>
                                                                                    <Icon style={{color:'#838383',marginLeft:-3,fontSize:'14px'}} name='search' />
                                                                                    <span style={{color:'#737373',paddingLeft:'10px'}}>{j['ClickDescription']}</span>
                                                                                </div>
                                                                                </Dropdown.Item>
                                                                    } else {
                                                                        return <Dropdown.Item style={{fontSize:'16px'}} onClick={()=>{this.newBackEndCall(j['backEndCall'])}}>
                                                                            <div>
                                                                                <span>{j['DisplayForm']}</span>
                                                                                <span style={{color:'#737373',paddingLeft:'10px'}}>{j['ClickDescription']}</span>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    }
															  	})}
															  </Dropdown.Menu>
														  </Dropdown>
														</div>
													</div>
												})}

{/*												<div style={{display:'flex',fontFamily:customFontFam,justifyContent:'center',paddingBottom:'20px',paddingRight:'30px',paddingLeft:'30px'}}><Button fluid style={{display:'flex',textAlign:'left',maxWidth:'350px',justifyContent:'space-between',paddingLeft:'14px', paddingRight:'3px', border:'1px solid #22242626',borderRadius:'.28571429rem',backgroundColor:'white'}} onClick={()=>{}}><div style={{display:'flex', fontWeight:400, flexDirection:'column'}}><span style={{color:'#000000DE',fontFamily:customFontFam}}>{"Igaq Aug'arluku"}</span><span style={{fontFamily:customFontFam, color:'#737373',paddingTop:'4px'}}>{'Delete Word'}</span></div><div><Icon name='delete'/></div></Button></div>
*/}
{/*												<div style={{display:'flex',fontFamily:customFontFam,justifyContent:'center',paddingBottom:'20px',paddingRight:'30px',paddingLeft:'30px'}}><Button fluid style={{display:'flex',maxWidth:'350px',justifyContent:'space-between',paddingLeft:'14px', paddingRight:'3px', border:'1px solid #22242626',borderRadius:'.28571429rem',backgroundColor:'white'}} onClick={()=>{}}><div style={{display:'flex', fontWeight:400, flexDirection:'row'}}><span style={{color:'#000000DE',fontFamily:customFontFam}}>{"Igaq Aug'arluku"}</span><span style={{fontFamily:customFontFam, color:'#737373',paddingLeft:'10px'}}>{'Delete Word'}</span></div><div><Icon name='delete'/></div></Button></div>
*/}
											</div>
											:
											null
										}

									</div>						
								)}
								</div>

								{justOne ? 
									null
									:
									this.getEnglishGroups()
								}

                                {this.state.englishGroups.length === 0 ?
                                    <div style={{textAlign:'center',margin:'20px',fontSize:'12pt',fontStyle:'italic',color:'#737373'}}>Return to the <Link style={{textDecoration:'underline'}} to={{pathname: "/", state: { history:['', [], 0, 1] }}} >homepage</Link> to try other stems.</div>
                                    :
                                    null
                                }

                                {this.state.npnCase === 'Rel' ?
                                    <div style={{paddingTop:'20px',textAlign:'center',margin:'20px',fontSize:'12pt',color:'#737373'}}>This ending (relative case) is used when this word is a subject of a transitive verb or is the owner of something. By itself, it is likely a response to a question.</div>
                                    :
                                    null
                                }
                                {this.state.npnCase === 'Mod' ?
                                    <div style={{paddingTop:'20px',textAlign:'center',margin:'20px',fontSize:'12pt',color:'#737373'}}>This ending (modalis case) is used when this word is an indirect object. For example, "Tautuktuq tuttu<i>mik</i> - He sees <i>a</i> caribou" versus "Tautukkaa tuttu - He sees <i>the</i> caribou". By itself, it is likely a response to a question.</div>
                                    :
                                    null
                                }
                                {this.state.ongoing ?
                                    <div style={{paddingTop:'20px',textAlign:'center',margin:'20px',fontSize:'12pt',color:'#737373',fontStyle:'italic'}}>currently happening or recent past</div>
                                    :
                                    null
                                }


                                {this.state.mvvMood === 'Opt' ?
                                    <div style={{paddingTop:'20px',textAlign:'center',margin:'20px',fontSize:'12pt',color:'#737373'}}>With rising intonation, this word can also be understood as a question, "should he?" or "should I?"</div>
                                    :
                                    null
                                }
                                {this.state.pointPresent ?
                                    <div>
                                    <div style={{textAlign:'center',margin:'20px',paddingTop:'15px',fontSize:'12pt',color:'#737373'}}>requires a verb of movement<div style={{cursor:'pointer',marginTop:7,marginBottom:10}}><TagColors tags={'see examples1'} width={window.innerWidth} /></div></div>
                                    </div>
                                    :
                                    null
                                }

                                {this.state.locativePresent ?
                                    <div>
                                    <div style={{textAlign:'center',margin:'20px',paddingTop:'15px',fontSize:'12pt',color:'#737373'}}>requires a verb of no movement between boundaries<div style={{cursor:'pointer',marginTop:7,marginBottom:10}}><TagColors tags={'see examples2'} width={window.innerWidth} /></div></div>
                                    </div>
                                    :
                                    null
                                }

                                {this.state.demPresent ?
                                    <div>
                                    <div style={{paddingTop:(this.state.pointPresent || this.state.locativePresent ? '10px':'20px'),textAlign:'center',margin:'20px',marginBottom:'5px',fontSize:'12pt',color:'#737373'}}>restricted: occupying a restricted area or stationary</div>
                                    <div style={{textAlign:'center',margin:'5px 20px',fontSize:'12pt',color:'#737373'}}>extended: occupying a large area or in motion</div>
                                    <div style={{textAlign:'center',margin:'20px',marginTop:'5px',fontSize:'12pt',color:'#737373'}}>tat-: entity in reference may be closer or visible to the listener</div>
                                    </div>
                                    :
                                    null
                                }

{/*                                {this.state.entryUsageData[0]['properties'].includes('elemental') ?
                                  <div style={{paddingTop:'15px',textAlign:'center',margin:'20px',marginBottom:'5px',fontSize:'12pt',color:'#737373'}}> This weather/elemental stem can also take transitive endings, with "weather" acting as the agent.
                                  <Popup position='bottom center' on='click' content={
                                                          <div style={{fontSize:'12pt',color:'#737373'}}>
                                                            <div style={{marginBottom:'5px', textDecoration:'underline'}}>For example:</div>
                                                            <div>sialuktuq "it is raining"</div>
                                                            <div>sialukkaa "it (the weather) is raining on it"</div>
                                                          </div>
                                  } trigger={<Label size='mini' style={{cursor:'pointer',fontSize:'11px',backgroundColor:'#f3f3f3',marginLeft:'5px',whiteSpace:'nowrap'}}><Icon style={{fontSize:'10px',margin:0,padding:0}} name='info' /></Label>} />
                                  </div>
                                  :
                                  null
                                }*/}
                               
				
							</div>

				{this.state.entryUsageData.length !== 0 ?
					<div style={{marginTop:'30px'}}>
    					<div className='hierarchymain'>
    					<span className='span1'>Isuliqługu Maŋŋuk</span>
    					<span className='span2'>With Endings</span>
    					</div> 
    					<List style={{marginLeft:20,marginRight:20,marginTop:4}} divided>
    					{Object.keys(this.state.entryUsageData).map((i)=>
                            <NewUsageBaseDisplay newBackEndCall={this.newBackEndCall} sentenceBuilder={true} currentProps={this.props} currentState={this.state} word={this.state.entryUsageData[i]} width={window.innerWidth} />
              			)}
						</List>
					</div>
					:
					null
				}

					</div>



                    {false ?
                        <div>
							<div>
							Basic Noun: 
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],], "i", "Ind+Prs"] ],
									[ "Insert", ["mv","ns"],	[[["nanuq",0,0],],[0,0,0,1]] ],
									[ "Update", ["mv","ns",0,0],	[1,1,0,1] ],
									// [ "Update", ["mv","nsBases",0,0],	[["aŋun",0,0],] ],
								])}}>nannuutiga tautuktuq</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],], "i", "Ind+Prs"] ],
									[ "Insert", ["mv","ns"],	[[["aŋun",0,0],],[0,0,0,1]] ],
									[ "Update", ["mv","ns",0,0],	[1,1,0,1] ],
									[ "Update", ["mv","nsBases",0,0],	[["nanuq",0,0],] ],
								])}}>nannuutiga tautuktuq</Button>
							</div>
							<div>
							Basic Verb: 
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],["29",0,0],["9",0,0],["6",0,0],], "i", "Ind+Prs"] ],
								])}}>tautullasuktuq</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],["29",0,0],["9",0,0],["6",0,0],], "i", "Ind+Prs"] ],
									[ "Insert", ["mv","no"], [[["aġnaq",0,0],], [0,0,0,1]]],
								])}}>tautullasuktuq arnamik</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",1,0],], "t", "Ind+Prs"] ],
									[ "Insert", ["mv","ns"], [[["aŋun",0,0],], [0,0,0,1]] ],
									[ "Insert", ["mv","no"], [[["aġnaq",0,0],], [0,0,0,1]] ],
								])}}>angutim tautukkaa arnaq</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",1,0],], "t", "Ind+Prs"] ],
								])}}>tautukkaa</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[['tuyuġi-', 0, 0],], "t", "Ind+Prs"] ],
								])}}>tuyugigaa</Button>
							</div>
							<div>
							Interrogative: 
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],], "i", "Int", "Intrg"] ],
								])}}>yes/no</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],], "i", "Int", "Intrg9"] ],
								])}}>summan-why</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],], "i", "Int", "IntrgA"] ],
								])}}>qanuq-how</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],], "i", "Int", "Intrg6"] ],
								])}}>sumi-at where</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],], "i", "Int", "Intrg7"] ],
								])}}>sumiñ-from where</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],], "i", "Int", "Intrg4"] ],
								])}}>qaŋa-when(past)</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],], "i", "Int", "Intrg5"] ],
								])}}>qakugu-when(future)</Button>
							</div>
							<div>
							Interrogative 2: 
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],], "i", "Int", "Intrg0"] ],
								])}}>kina_subj_i</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",1,0],], "t", "Int", "Intrg0"] ],
								])}}>kina_subj_t</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],], "i", "Int", "Intrg1"] ],
								])}}>kina_obj_i</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",1,0],], "t", "Int", "Intrg1"] ],
								])}}>kina_obj_t</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],], "i", "Int", "Intrg2"] ],
								])}}>suna_subj_i</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",1,0],], "t", "Int", "Intrg2"] ],
								])}}>suna_subj_t</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],], "i", "Int", "Intrg3"] ],
								])}}>suna_obj_i</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",1,0],], "t", "Int", "Intrg3"] ],
								])}}>suna_obj_t</Button>
							</div>
							<div>
							Opt-Imp: 
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["niġi-",0,0],], "i", "Opt"] ],
								])}}>Opt_i</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["niġi-",1,0],], "t", "Opt"] ],
								])}}>Opt_t</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["niġi-",0,0],], "i", "Imp"] ],
								])}}>Imp_i</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["niġi-",1,0],], "t", "Imp"] ],
								])}}>Imp_t</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["niġi-",0,0],], "i", "Cont1+Unreal"] ],
								])}}>Cont1+Unreal_i</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["niġi-",1,0],], "t", "Cont1+Unreal"] ],
								])}}>Cont1+Unreal_t</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["niġi-",0,0],], "i", "ImpNeg"] ],
								])}}>ImpNeg_i ERROR</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["niġi-",1,0],], "t", "ImpNeg"] ],
								])}}>ImpNeg_t</Button>
							</div>
							<div>
							Embedded Verb: 
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 			[[["tautuk-",0,0],], "i", "Ind+Prs"] ],
									[ "Update", ["mv","vs"],	  	[3,1,2] ],
									[ "Insert", ["mv","ns"],	  	[[["aġnaq",0,0],],[0,0,0,1]] ],
									[ "Insert", ["mv","no"],	  	[[["aŋun",0,0],],[0,0,0,1]] ],
									[ "Update", ["mv","vBase"],	    [[["tautuk-",0,0],["36","ii",0],], 'i'] ],
								])}}>aġnaq tautuŋniruaq aŋunmik (ii)</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 			[[["tautuk-",1,0],], "t", "Ind+Prs"] ],
									[ "Insert", ["mv","ns"],	  	[[["aŋun",0,0],],[0,0,0,1]] ],
									[ "Insert", ["mv","no"],	  	[[["aġnaq",0,0],],[0,0,0,1]] ],
									[ "Update", ["mv","vBase"],	    [[["tautuk-",1,0],["36","ti",0],], 'i'] ],
									[ "Update", ["mv","vs"],	  	[3,1,2] ],
								])}}>aġnaq tautuŋniruaq aŋunmun (ti)</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 			[[["tautuk-",0,0],], "i", "Ind+Prs"] ],
									[ "Insert", ["mv","ns"],	  	[[["aġnaq",0,0],],[0,0,0,1]] ],
									[ "Insert", ["mv","no"],	  	[[["iñuk(-)",0,0],],[0,0,0,1]] ],
									[ "Update", ["mv","vBase"],	    [[["tautuk-",0,0],["36","it",0],], 't'] ],
									[ "Insert", ["mv","ns"],	  	[[["aŋun",0,0],],[0,0,0,1]] ],
								])}}>aŋutim tautuŋnigaa aġnaq iñuŋmik (it)</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 			[[["tautuk-",1,0],], "t", "Ind+Prs"] ],
									[ "Insert", ["mv","ns"],	  	[[["iñuk(-)",0,0],],[0,0,0,1]] ],
									[ "Insert", ["mv","no"],	  	[[["aġnaq",0,0],],[0,0,0,1]] ],
									[ "Update", ["mv","vBase"],	    [[["tautuk-",1,0],["36","tt",0],], 't'] ],
									[ "Insert", ["mv","ns"],	  	[[["aŋun",0,0],],[0,0,0,1]] ],
								])}}>aŋutim tautuŋnigaa aġnaq iñuŋmun (tt)</Button>
							</div>
							<div>
							EV Pronoun: 
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 			[[["tautuk-",1,0],], "t", "Ind+Prs"] ],
									[ "Update", ["mv","vs"],	  	[1,1,0] ],
									[ "Update", ["mv","vo"],	  	[2,1,0] ],
									[ "Update", ["mv","vBase"],	    [[["tautuk-",1,0],["36","ti",0],], 'i'] ],
									[ "Update", ["mv","nes",0,0],	[1,1,0,-1] ],
								])}}>tautugnirutin uvamnun (ti)</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 			[[["tautuk-",1,0],], "t", "Ind+Prs"] ],
									[ "Update", ["mv","vs"],	  	[1,1,0] ],
									[ "Update", ["mv","vo"],	  	[2,1,0] ],
									[ "Update", ["mv","vBase"],	    [[["tautuk-",1,0],["36","tt",0],], 't'] ],
									[ "Update", ["mv","nes",0,0],	[1,1,0,-1] ],
								])}}>tautuŋnigaatin uvamnun (tt)</Button>
							</div>
							<div>
							Noun and Copula: 
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["np",],		[[["aŋun",0,0],],[0,0,0,1],"Abs"] ],
								])}}>angun</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["np",],		[[["aŋun",0,0],],[0,0,0,1],"Abs"] ],
									[ "Insert", ["np","n",-1],	  	[[["aġnaq",0,0],],[0,0,0,1]] ],
								])}}>aġnam angutinga</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["np",],		[[["aŋun",0,0],],[0,0,0,1],"Abs"] ],
									[ "Insert", ["np","nc"],	[[["nanuq",0,0],],[0,0,0,1]] ],
									[ "Update", ["np","ncType"],	"noun" ],
								])}}>nanuq angun</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["np",],		[[["qimmiq",0,0],],[0,0,0,1],"Abs"] ],
									[ "Insert", ["np","nc"],	[[[]],[1,1,0,-1]] ],
									[ "Update", ["np","ncType"],	"perPros" ],
								])}}>uvaŋa angun</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["np",],		[[["aŋun",0,0],],[0,0,0,1],"Abs"] ],
									[ "Insert", ["np","nc"],	[[["una",0,0]],[0,0,0,1]] ],
									[ "Update", ["np","ncType"],	"demPros" ],
								])}}>una angun</Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["np",],		[[["aŋun",0,0],],[0,0,0,1],"Abs"] ],
									[ "Insert", ["np","nc"],	[[["uvva",0,0]],[0,0,0,1]] ],
									[ "Update", ["np","ncType"],	"demAdvs" ],
								])}}>uvva angun</Button>
							</div>
							<div>
							Conseq: 
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	[[["tautuk-",0,0],], "i", "Ind+Pst"] ],
									[ "Insert", ["cv",], 	[[["niġi-",0,0],], "i", "Conseq"] ],
								])}}>tautuktuaq niġimman</Button>
							Cond:
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	[[["tautuk-",0,0],["1",0,0]], "i", "Ind+Prs"] ],
									[ "Insert", ["cv",], 	[[["niġi-",0,0],], "i", "Cond"] ],
								])}}>tautuŋniaqtuq niġikpan</Button>
							</div>
							<div>
							Possessive: 
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 			[[["quviasuk-",0,0],], "i", "Ind+Prs"] ],
									[ "Insert", ["mv","ns"],	  	[[["qimmiq",0,0]],[0,0,0,1]] ],
                                    [ "Insert", ["mv","ns",-1],     [[["panik",0,0],],[0,0,0,1]] ],
                                    [ "Insert", ["mv","ns",-1],     [[["umialik",0,0],],[0,0,0,1]] ],
									// [ "Update", ["mv","ns",0,0],	[3,1,0,3] ],
								])}}>the whaling captain's daughter's dog is happy</Button>
							</div>
                            <div>
                            Possessive: 
                            <Button onClick={()=>{this.newBackEndCall([
                                    [ "Insert", ["mv",],            [[["uŋut-",0,0],], "t", "Ind+Prs"] ],
                                    [ "Insert", ["mv","ns"],        [[["qimmiq",0,0]],[0,0,0,1]] ],
                                    [ "Insert", ["mv","ns",-1],     [[["panik",0,0],],[0,0,0,1]] ],
                                    [ "Insert", ["mv","ns",-1],     [[["umialik",0,0],],[0,0,0,1]] ],
                                    // [ "Update", ["mv","ns",0,0], [3,1,0,3] ],
                                ])}}>the whaling captain's daughter's dog is chasing it away</Button>
                            </div>

							<div>
							nObliques: 
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 					[[["niġi-",0,0],], "i", "Ind+Pst"] ],
									[ "Insert", ["mv","nObliques"],         [[["iglu(-)",0,0],],[0,0,0,1],"Loc"] ],
									// [ "Insert", ["mv","nObliques",-1],      [[["aġnaq",0,0],],[0,0,0,1],"Sim"] ],
									// [ "Insert", ["mv","nObliques",0,-1],    [[["nanuq",0,0],],[0,0,0,1]] ],
									// [ "Insert", ["mv","nObliques",0,0,-1],  [[["qatiqtaaq",0,0],],[0,0,0,1]] ],
									// [ "Update", ["mv","nObliques",0,"n",1,0],    [0,0,0,2] ],
								])}}> he ate in the house </Button>
							</div>
							<div>
							Reflexive Agreement: 
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",1,0],], "t", "Ind+Pst"] ],
									[ "Insert", ["mv","ns"],	  	[[["aġnaq",0,0],],[0,0,0,1]] ],
									[ "Insert", ["mv","no"],	  	[[["panik",0,0],],[4,1,0,1]] ],
								])}}>mvno -> mvvs </Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["tautuk-",0,0],], "i", "Ind+Pst"] ],
									[ "Insert", ["mv","ns"], [[["aġnaq",0,0],], [0,0,0,1]]],
									[ "Insert", ["cv",], 	 [[["niġi-",0,0],], "i", "Conseq"] ],
									[ "Update", ["cv","vs"],	  	[4,1,0] ],
								])}}>cvvs -> mvvs </Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["niġi-",0,0],], "i", "Ind+Pst"] ],
									[ "Insert", ["mv","ns"], [[["aġnaq",0,0],], [0,0,0,1]]],
									[ "Insert", ["cv",], 	 [[["tautuk-",1,0],], "t", "Conseq"] ],
									[ "Update", ["cv","vo"],	  	[4,1,0] ],
								])}}>cvvo -> mvvs </Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["niġi-",0,0],], "i", "Ind+Pst"] ],
									[ "Insert", ["mv","ns"], [[["aġnaq",0,0],], [0,0,0,1]]],
									[ "Insert", ["cv",], 	 [[["niġi-",0,0],], "i", "Conseq"] ],
									[ "Insert", ["cv","ns"],	  	[[["panik",0,0],],[4,1,0,1]] ],
								])}}>cvns -> mvvs (?) </Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["niġi-",0,0],], "i", "Ind+Pst"] ],
									[ "Insert", ["mv","ns"], [[["aġnaq",0,0],], [0,0,0,1]]],
									[ "Insert", ["cv",], 	 [[["tautuk-",1,0],], "t", "Conseq"] ],
									[ "Insert", ["cv","ns"],	  	[[["aŋun",0,0],],[0,0,0,1]] ],
									[ "Insert", ["cv","no"],	  	[[["panik",0,0],],[4,1,0,1]] ],
								])}}>cvno -> mvvs (both?) </Button>
							<Button onClick={()=>{this.newBackEndCall([
									[ "Insert", ["mv",], 	 [[["niġi-",0,0],], "i", "Ind+Pst"] ],
									[ "Insert", ["mv","ns"], [[["aġnaq",0,0],], [0,0,0,1]]],
									[ "Insert", ["cv",], 	 [[["tautuk-",1,0],], "t", "Conseq"] ],
									[ "Insert", ["cv","ns"],	  	[[["aŋun",0,0],],[0,0,0,1]] ],
									[ "Insert", ["cv","no"],	  	[[["panik",0,0],],[4,1,1,1]] ],
								])}}>cvno -> cvvs (both?) </Button>
							</div>
                        </div>
                        :
                        null
                    }

						<div style={{display:'flex',justifyContent:'center',marginTop:'10px'}}>
						<span style={{fontSize:'14px',color:'#737373',padding:'10px',fontWeight:'400',lineHeight:'23px'}}>
						<span style={{textDecoration:'underline'}}>Disclaimer:</span> This word builder is still in development. Determine whether the noun you've chosen can do the action or receive the action. Please be aware that there may be mistakes with word results and translations. The automated computer rules that produce these words don't often reflect common language use, context, and cultural knowledge. As a general rule, if the English translation doesn't make sense then most likely the Iñupiaq word is not used.
						</span>
						</div>



			    <Link to={{pathname: '/report'}}>
						<div style={{cursor:'pointer',paddingTop:'10px',paddingLeft:'8px'}}>
						<Icon style={{color:'#a9a9a9',}} name='exclamation circle' />
						<span style={{fontSize:'14px',color:'#9d9d9d',fontWeight:'400',lineHeight:'23px'}}>Report an Issue</span>
						</div>
          </Link>


			</Grid.Column>
			</Grid.Row>
			</Grid>
      
      </Transition>

			</div>
		);
	}
}
export default WordBuilder;