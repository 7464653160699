export const categoriesUrlLookup = {
    "The-physical-universe": "1",
    "Sky": "1.1",
    "Objects-in-the-sky": "1.1.1",
    "Weather": "1.1.3",
    "Natural-Disaster": "1.1.3.1",
    "Wind": "1.1.3.2",
    "Land": "1.2.1",
    "Matter": "1.2.2",
    "Water": "1.3",
    "Bodies-of-water": "1.3.1",
    "Movement-of-water": "1.3.2",
    "Be-in-water": "1.3.4",
    "Plant": "1.5",
    "Tree": "1.5.1",
    "Bush-shrub": "1.5.2",
    "Grass-herb-vine": "1.5.3",
    "Moss-fungus-algae": "1.5.4",
    "Parts-of-a-plant": "1.5.5",
    "Growth-of-plants": "1.5.6",
    "Plant-diseases": "1.5.7",
    "Animal": "1.6",
    "Types-of-animals": "1.6.1",
    "Mammal": "1.6.1.1",
    "Bird": "1.6.1.2",
    "Reptile": "1.6.1.3",
    "Amphibian": "1.6.1.4",
    "Fish": "1.6.1.5",
    "Shark-ray": "1.6.1.6",
    "Insect": "1.6.1.7",
    "Sea-creatures": "1.6.1.9",
    "Parts-of-an-animal": "1.6.2",
    "Animal-life-cycle": "1.6.3",
    "Animal-actions": "1.6.4",
    "Animal-homes": "1.6.5",
    "Male-and-female-animals": "1.6.7",
    "Person": "2",
    "Types-of-people": "2.1",
    "Body": "2.2",
    "Head": "2.2.1",
    "Torso": "2.2.2",
    "Arm-leg": "2.2.3",
    "Skin": "2.2.4",
    "Hair": "2.2.5",
    "Bone-joint": "2.2.6",
    "Internal-organs": "2.2.8",
    "Body-functions": "2.3",
    "The-senses": "2.4",
    "See": "2.4.1",
    "Hear": "2.4.2",
    "Taste": "2.4.3",
    "Smell": "2.4.4",
    "Sense-of-touch": "2.4.5",
    "Body-condition": "2.5",
    "Health": "2.6",
    "Sick": "2.6.1",
    "Life": "2.7",
    "Marriage": "2.7.1",
    "Birth": "2.7.3",
    "Stage-of-life": "2.7.4",
    "Male-female": "2.7.5",
    "Die": "2.7.6",
    "Language-and-thought": "3",
    "Soul-spirit": "3.1",
    "Personality": "3.1.1",
    "Mind": "3.2",
    "Think": "3.2.1",
    "Want": "3.3",
    "Emotion": "3.4",
    "Communication": "3.5",
    "Verbal-tradition": "3.5.4",
    "Reading-and-writing": "3.5.5",
    "Teach": "3.6",
    "School": "3.6.2",
    "Social-behavior": "4",
    "Relationships": "4.1",
    "Show-affection": "4.1.8",
    "Related-by-kinship": "4.1.9",
    "Social-activity": "4.2",
    "Music": "4.2.3",
    "Dance": "4.2.4",
    "Entertainment-recreation": "4.2.6",
    "Play-fun": "4.2.7",
    "Behavior": "4.3",
    "Do-good/evil-to": "4.3.4",
    "Culture": "4.3.9",
    "Authority": "4.5",
    "Person-in-authority": "4.5.1",
    "Law": "4.7",
    "Break-the-law": "4.7.3",
    "Punish": "4.7.7",
    "Strife": "4.8",
    "Peace": "4.8.3",
    "War": "4.8.4",
    "Religion": "4.9",
    "Home": "5",
    "Rooms-of-a-house": "5.1",
    "Furniture": "5.1.2",
    "Furnishings": "5.1.3",
    "Household-tools": "5.1.4",
    "Food": "5.2",
    "Food-preparation": "5.2.1",
    "Eat": "5.2.2",
    "Types-of-food": "5.2.3",
    "Food-from-plants": "5.2.3.1",
    "Food-from-animals": "5.2.3.2",
    "Prepared-food": "5.2.3.4",
    "Beverages": "5.2.3.6",
    "Alcoholic-beverages": "5.2.3.7",
    "Tobacco": "5.2.4",
    "Clothing": "5.3",
    "Traditional-clothing": "5.3.3",
    "Parts-of-clothing": "5.3.6",
    "Wear-clothing": "5.3.7",
    "Naked": "5.3.8",
    "Adornment": "5.4",
    "Jewelry": "5.4.1",
    "Cosmetics": "5.4.2",
    "Caring-for-hair": "5.4.3",
    "Fire": "5.5",
    "Cleaning": "5.6",
    "Clean-dirty": "5.6.1",
    "Sleep": "5.7",
    "Work-and-occupation": "6",
    "Work": "6.1",
    "Difficult-impossible": "6.1.3",
    "Agriculture": "6.2",
    "Growing-crops": "6.2.1",
    "Hunt-and-fish": "6.4",
    "Hunt": "6.4.1",
    "Trap": "6.4.2",
    "Fishing": "6.4.5",
    "Working-with-buildings": "6.5",
    "Building": "6.5.1",
    "Parts-of-a-building": "6.5.2",
    "Building-materials": "6.5.3",
    "Occupations": "6.6",
    "Working-with-cloth": "6.6.1",
    "Working-with-minerals": "6.6.2",
    "Working-with-wood": "6.6.3",
    "Art": "6.6.5",
    "Tool": "6.7",
    "Container": "6.7.7",
    "Machine": "6.7.9",
    "Finance": "6.8",
    "Financial-transactions": "6.8.4",
    "Borrow": "6.8.5",
    "Physical-actions": "7",
    "Posture": "7.1",
    "Stand": "7.1.1",
    "Sit": "7.1.2",
    "Lie-down": "7.1.3",
    "Bend-down": "7.1.8",
    "Move-a-part-of-the-body": "7.1.9",
    "Move": "7.2",
    "Manner-of-movement": "7.2.1",
    "Move-in-a-direction": "7.2.2",
    "Travel": "7.2.6",
    "Move-something": "7.3",
    "Carry": "7.3.1",
    "Move-something-in-a-direction": "7.3.2",
    "Handle-something": "7.3.4",
    "Turn-something": "7.3.5",
    "Open/Shut": "7.3.6",
    "Cover/Uncover": "7.3.7",
    "Transportation": "7.3.8",
    "Keep/Leave-something": "7.3.9",
    "Arrange": "7.4",
    "Gather": "7.4.1",
    "Join-attach": "7.4.2",
    "Hide": "7.5",
    "Search": "7.5.1",
    "Find": "7.5.2",
    "Lose-misplace": "7.5.3",
    "Physical-impact": "7.6",
    "Hit": "7.6.1",
    "Divide-into-pieces": "7.7",
    "Dig": "7.7.6",
    "States": "8",
    "Quantity": "8.1",
    "Number": "8.1.2",
    "All/Some": "8.1.5",
    "Measure": "8.2",
    "Long/Short": "8.2.2",
    "Size": "8.2.4",
    "Volume": "8.2.5",
    "Quality": "8.3",
    "Shape": "8.3.1",
    "Texture": "8.3.2",
    "Light": "8.3.3",
    "Temperature": "8.3.4",
    "Decay": "8.3.5",
    "Type-kind": "8.3.6",
    "Good/Bad": "8.3.8",
    "Appearance": "8.3.9",
    "Time": "8.4",
    "Period-of-time": "8.4.1",
    "Time-of-the-day": "8.4.2",
    "Take-time": "8.4.7",
    "Speed": "8.4.8",
    "Location": "8.5",
    "Here-there": "8.5.1",
    "Direction": "8.5.2",
    "Be-at-a-place": "8.5.3",
    "Spatial-relations": "8.5.5",
    "Parts-of-things": "8.6",
    "Grammar": "9",
    "General-words": "9.1",
    "Be": "9.1.1",
    "Do": "9.1.2",
    "Thing": "9.1.3",
    "Part-of-speech": "9.2",
    "Adjectives": "9.2.1",
    "Adverbs": "9.2.2",
    "Pronouns": "9.2.3",
    "Prepositions-postpositions": "9.2.4",
    "Particles": "9.2.6",
    "Interjections": "9.2.7",
    "Propositions": "9.3",
    "Plurality": "9.3.4",
    "Name": "9.7",
    "Name-of-a-place": "9.7.2"
};