
import React, { Component } from 'react';
import { Container, Header, Button, Icon, Divider, Image, Grid, Dropdown, List, Label, Popup, Checkbox, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { API_URL } from '../App.js';
import axios from 'axios';
import {retrieveSubjectObject, nounoptions1, nounoptions2, nounoptions3, nounoptionsmodalis, options1, options2, options3, verbPostbases, nounPostbases} from './constants/constants.js'
import {postbasesEndingsUnderlying} from './constants/underlying.js'
import palette from 'google-palette';
import shuffle from 'shuffle-array';
import { TagColors } from './HomeHelper.js';
import * as Scroll from 'react-scroll';
import ReactGA from "react-ga4";
import L from "leaflet";
import {geoJSONMap} from "./constants/placenames20.js";
import 'leaflet/dist/leaflet.css';
import 'leaflet.zoomhome/dist/leaflet.zoomhome.js';
// import icon from 'leaflet/dist/images/marker-icon.png';
// import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import "leaflet-extra-markers/dist/js/leaflet.extra-markers.js";
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'
// import { dom } from '@fortawesome/fontawesome-svg-core'
// dom.watch() // This will kick of the initial replacement of i to svg tags and configure a MutationObserver
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { customFontFam3, customTitleFontFam3, customFirefoxTitleFontFam } from './constants/constants.js';
// import { far } from '@fortawesome/free-regular-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas) 
dom.i2svg() 

ReactGA.initialize("G-BCN5V0JQN1")

var scroll    = Scroll.animateScroll;

let customFontFam = customFontFam3
let customTitleFontFam = customTitleFontFam3
let customFontFam2 = {fontFamily:customTitleFontFam3}
let letterbutton = {fontFamily:customFontFam3,paddingTop:'10px',paddingBottom:'10px',paddingRight:'14px',paddingLeft:'14px'}
const isFirefox = typeof InstallTrigger !== 'undefined';

if (isFirefox) {
  customFontFam = customFirefoxTitleFontFam
  customTitleFontFam = customFirefoxTitleFontFam
  customFontFam2 = {fontFamily:customFirefoxTitleFontFam}
  letterbutton = {fontFamily:customFirefoxTitleFontFam,paddingTop:'10px',paddingBottom:'10px',paddingRight:'14px',paddingLeft:'14px'}
}

let placenames = [
// ["id","name","translation","location","googleName","googleDescription","point locations","audio filename","audio filename 2"],
["1","At Ch'éeni Shaa","Sheep's Bow Mountain","Upper Taku River (Carter Mountain)","","","good","map-1.mp3",""],
["2","Koosawu Áa","Narrow Lake","Surprise Lake","","","good","map-2.mp3",""],
["3","Koosawu Héen","Narrow Creek","Pine Creek","","","good","map-3.mp3",""],
["4","Inhéeni","Flint Creek","McKee Creek","","","good","map-4.mp3",""],
["5","Yaawat'aayi Héen G̲eeyí","Warm Water Bay","Warm Bay","","","good","map-5.mp3",""],
["6","Watsíx Héeni","Caribou River","O'Donnel River","","","good","map-6.mp3",""],
["7","Yat'aayi Héen","Hot Springs","Hot Springs","","","unmarked","map-7.mp3","map-7_2.mp3"],
["8","Áa Tlein","Big Lake","Atlin Lake","","","marked, no big highlight","map-8.mp3","map-8_2.mp3"],
["9","Dashdané Áayi","Suckerfish Lake","Dixie Lake","","","good","map-9.mp3",""],
["10","S'eek X'áat'i","Black Bear Island","Griffith Island","","","good","map-10.mp3",""],
["11","G̲at.áayi Shú","End of Sockeye Salmon Lake","End of Kuthai Lake","","","unmarked","map-11.mp3",""],
["12","Neixinté","Blue-Green Claystone (Flint)","Near Nakina Village*","","","unsure","map-12.mp3",""],
["13","G̲at.áayi","Sockeye Salmon Lake","Kuthai Lake","","","good","map-13.mp3",""],
["14","Chookán Tlein","Great Grassland","Silver Salmon River","","","unmarked","map-14.mp3",""],
["15","Naak'ina.áa","Naak'ina Village","Nakina Village area","","","unsure","map-15.mp3","map-15_2.mp3"],
["16","Ts'eitina Héeni","Ts'eitina Creek","Gold Bottom Creek","","","good","map-16.mp3",""],
["17","Tatlenx'ix̲oo","Bestrewn with Big Rocks","Nakina Village site","","","unsure","map-17.mp3",""],
["18","Sinwaa Yádi","Little Limestone Outcropping","Upper Taku River","","","unmarked","map-18.mp3",""],
["19","A Shuyee","The Foot of [the Glacier]","Head of Llewellyn Inlet","","","unsure","map-19.mp3",""],
["20","G̲athéeni","Sockeye Creek","Silver Salmon River","","","good","map-20.mp3",""],
["21","Sinwaa [Tlein]","Big Limestone Outcropping","White Quartz Mountain","","","unsure","map-21.mp3",""],
["22","L'óox̲'u Héen","Silty River","Sloko River","","","good","map-22.mp3","map-22_2.mp3"],
["23","Tatlenx'ix̲oo Héeni (S'iknoowóo Héeni)","Tatlenx'ix̲oo River","Upper Nakina River","","","unmarked","map-23.mp3",""],
["24","X̲'aak Tlein Héeni","Big Ravine Creek","Upper Taku River","","","unmarked","map-24.mp3",""],
["25","X̲'aak Tlein","Big Ravine","Upper Taku River","","","unmarked","map-25.mp3",""],
["26","T'ooch' Shakée","Peak of Charcoal [Mountain]","Upper Taku River","","","unmarked","map-26.mp3",""],
["27","T'ooch' Héeni","Charcoal Creek","Fourth of July Creek","","","unmarked","map-27.mp3",""],
["28","L'óox̲'u Áa","Silty Lake","Sloko Lake","","","good","map-28.mp3",""],
["29","Séik̲'u Té","Vermilion Rock","Sloko River area","","","unmarked","map-29.mp3","map-29_2.mp3"],
["30","Séik̲'u Té Héeni","Séik̲'u Té Creek","Sloko River tributary","","","good","map-30.mp3","map-30_2.mp3"],
["31","Naak'ina.áa Héeni","Naak'ina.áa Creek","Lower Nakina River","","","unmarked","map-31.mp3",""],
["32","T'ooch' Yayá","Side of Charcoal [Mountain]","Upper Taku River","","","unmarked","map-32.mp3",""],
["33","Naak'ina.áakw*","Little Naak'ina Lake","Nakonake River","","","unmarked","map-33.mp3",""],
["34","Woosh Keekanax̲ Kukwantaayi.áan*","Opposite Each Other Village*","Taku River near headwaters","","","unmarked","map-34.mp3",""],
["35","T'eix̲ X̲'a.eetí","Fishhook Site","Upper Taku River","","","unmarked","map-35.mp3",""],
["36","K'wálx̲i Héeni","Fern Creek","McGavin Creek","","","good","map-36.mp3",""],
["37","Yayuwaa","Interstice","Luwa Mountain ridge","","","unsure","map-37.mp3",""],
["38","Kax̲noowk'ú Tuk̲ká","Rise Adjacent to Kax̲noowk'ú","Abutment above Kax̲noowk'ú","","","unmarked","map-38.mp3",""],
["39","Kax̲noowk'ú","Ancient Settlement","Upper Taku River","","","unmarked","map-39.mp3",""],
["40","Aan Sakweis's'í*","Village Unknown*","Berners Bay area","","","unsure","map-40.mp3",""],
["41","K̲utak.aan*","Unknown* Village","Mouth of Lace River, Berners Bay","","","good","map-41.mp3",""],
["42","Héen Tlein","Big River","Inklen River","","","good","map-42.mp3","map-42_2.mp3"],
["43","Shaanax̲héeni","Valley River","Upper Taku River","","","unmarked","map-43.mp3",""],
["44","Yayéinaa Héeni","Whetstone Creek","Shazah Creek","","","good","map-44.mp3",""],
["45","Taaltsux̲éi","In Front of Big Flat Basket*","Tulsequah River","","","good","map-45.mp3","map-45_2.mp3"],
["46","Dax̲anáak","Between Two Points*","Berners Bay","","","unmarked","map-46.mp3",""],
["47","Shaanáx̲ Tlein Héeni","Big Valley Creek","Wilms Creek (Canyon Creek)","","","good","map-47.mp3",""],
["48","Asgutug̲íl'i","Rocky Outcropping in Wooded Area","Near Polaris Mine","","","unmarked","map-48.mp3",""],
["49","Shaanáx̲ Tlein","Big Valley","Wilms Creek Valley","","","unmarked","map-49.mp3",""],
["50","Ch'eet' Taayí","Murrelet Fat*","Cowee Creek, Echo Cove","","","good","map-50.mp3",""],
["51","S'iknoow","Black Bear Fort","Above Tulsequah","","","unmarked","map-51.mp3",""],
["52","K̲anaguk* Yík","Inside Unknown*","Upper Taku River","","","unsure","map-52.mp3",""],
["53","Aanx̲'atinyé","Place Where the Opening (Mouth) of the Land Lies","Canyon Island","","","unsure","map-53.mp3",""],
["54","Taan X'áat'i","Sea Lion Island","Benjamin Island","","","good","map-54.mp3",""],
["55","Shaa Tlein","Big Mountain","Kluchman Mountain","","","unsure, shade whole mountain?","map-55.mp3",""],
["56","Naahéeni","Tribe* Creek","Taku River, secondary channel","","","unsure","map-56.mp3",""],
["57","Lkoodaséits'k Shaayí","Lkoodaséits'k's Head","Taku River","","","unmarked","map-57.mp3",""],
["58","Sít' Áayi","Glacier Lake","Twin Glacier Lake","","","good","map-58.mp3",""],
["59","Asx̲'ée","Twisted Tree (Dam)*","Eagle River","","","unsure","map-59.mp3",""],
["60","Eeyák'w","Small Rapids","Eagle River Landing","","","unsure","map-60.mp3",""],
["61","K̲aa Shaa Teiwalg̲i*","Person's Head Rock Hole*","Herbert Creek","","","unmarked","map-61.mp3",""],
["62","T'aak̲ú Téix̲'i","Heart of the T'aak̲ú","Upper Taku River","","","unmarked","map-62.mp3",""],
["63","Lkoodaséits'k Leikachóox̲'u","Lkoodaséits'k's Windpipe","Taku River waterfall","","","unmarked","map-63.mp3",""],
["64","Naayádi","Child of the Clan","Lincoln Island","","","good","map-64.mp3",""],
["65","L'éiw Aan","Sand Village","Taku River*","","","unmarked","map-65.mp3","map-65_2.mp3"],
["66","Kax̲tóok","Cave*","Cave by Taku Lodge","","","unmarked","map-66.mp3",""],
["67","Lkoodaséits'k","Giant's name","Taku River mountain","","","unmarked","map-67.mp3",""],
["68","Kax̲tóok X̲'ayee","Area below Kax̲tóok","Area below Kax̲tóok","","","unmarked","map-68.mp3",""],
["69","T'aawák̲ Éix̲'i","Goose Slough","Taku River","","","unmarked","map-69.mp3",""],
["70","Kichx̲aak' Tuk̲yee","Wing Island* Bottom End","Shelter Island fort","","","unsure","map-70.mp3",""],
["71","Jánwu Téix̲'i","Mountain Goat Heart","Mount Golub, Chilkat Peninsula","","","good","map-71.mp3",""],
["72","Sít'","Glacier","Mendenhall Glacier","","","good","map-72.mp3","map-72_2.mp3"],
["73","T'aak̲ú K̲wáan Sít'i","T'aak̲ú People's Glacier","Taku Glacier","","","good","map-73.mp3",""],
["74","Sít' K̲unaa G̲eeyí","Glacier-Bidding Bay","Taku Glacier cove","","","unsure","map-74.mp3",""],
["75","Sít' X'aayí","Glacier Point","Swede Point","","","unmarked","map-75.mp3",""],
["76","Kichx̲aak'","Wing Island*","Shelter Island","","","good","map-76.mp3",""],
["77","Sit'.áa","Glacier Lake","Mendenhall Lake","","","good","map-77.mp3",""],
["78","Yakwdeiyí","Canoe Path","Across from Taku Glacier","","","unsure","map-78.mp3","map-78_2.mp3"],
["79","Wóoshde X̲'al.at Yé","Place Where the Open Sides Face Each Other*","Tee Harbor","","","good","map-79.mp3",""],
["80","Xutsnoowú Lutú","Brown Bear Fort Point (Nose)","Point Retreat","","","good","map-80.mp3",""],
["81","Kax̲dig̲oowu Héen","Going Back Clear Water","Montana Creek","","","good","map-81.mp3","map-81_2.mp3"],
["82","Tleix̲satanjín","Hand at Rest","Heintzleman Ridge","","","good","map-82.mp3",""],
["83","Yanyeidi X'aayí","Yanyeidí Point","Taku Point","","","unsure","map-83.mp3",""],
["84","Nex̲'w X'aayí","Cloudberry Point*","Lena Point","","","good","map-84.mp3",""],
["85","Yéilch Awataayi Yé","Raven's Sleeping* Place","Lena Point shaman site","","","unsure","map-85.mp3",""],
["86","Áak'w","Little Lake","Auke Lake","","","good","map-86.mp3","map-86_2.mp3"],
["87","Sít'","Glacier","Lemon Creek Glacier","","","good","map-72.mp3","map-72_2.mp3"],
["88","Shaanáx̲ (Shaanáx̲ Tlein)","Valley (Big Valley)","Lower Lemon Creek Valley","","","unsure","map-88.mp3",""],
["89","G̲athéeni","Sockeye Creek","Auke Creek","","","good","map-89.mp3",""],
["90","Áak'w Táak","Inland from Little Lake","Mendenhall Valley","","","good","map-90.mp3",""],
["91","K̲aalaká","Inside of Human Mouth","Ferry terminal","","","good","map-91.mp3",""],
["92","Aanchg̲altsóow","Town That Moved","Auke Bay village (Modern Auke Rec. Area)","","","good","map-92.mp3",""],
["93","Áak'w Noow","Little Lake Fort","Fairhaven","","","very unsure","map-93.mp3",""],
["94","Áak'w Tá","Back of Little Lake [Bay]","Auke Bay","","","good","map-94.mp3",""],
["95","X'unáx̲i","Camping Place*","Auke Cape","","","unsure","map-95.mp3",""],
["96","Yax̲té","Dipper","Auke Lake/Bay","","","unsure","map-96.mp3","map-96_2.mp3"],
["97","Sawdáat X'áat'i","Soldier Island","Off Auke Cape","","","unmarked","map-97.mp3",""],
["98","Kax̲dig̲oowu Héen Dei","Going Back Clear Water Trail","Trail to Montana Creek","","","unsure","map-98.mp3",""],
["99","Sit'k̲ú Héen","Glacier Area Creek","Lemon Creek","","","good","map-99.mp3",""],
["100","T'áa Shuyee","Board Squared at the End","Mendenhall Valley","","","unsure","map-100.mp3",""],
["101","Sít' T'ak Aaní","Land With Glaciers Above","Sittakanay River area","","","unmarked","map-101.mp3",""],
["102","Katlaax̲ Jíni (Tlax̲ Satán Jín)","Hand of Moldy Head (Idle Hands)","Blackerby Ridge*","","","good","map-102.mp3",""],
["103","Shaa X'áat'i","Mountain Island","Island at end of Fritz Cove","","","good","map-103.mp3",""],
["104","Eix̲'gul'héen","Creek at the End of the Slough","Lemon Creek or Switzer Creek","","","good","map-104.mp3",""],
["105","Yáxwch'i K̲aadí Táak","Sea Otter Slide Back of Bay","Barlow Cove","","","good","map-105.mp3",""],
["106","Kadigooni X'áat'","Island That Has Spring Water","Auke Bay","","","good","map-106.mp3",""],
["107","Yaana.éit X'áat'i","WIld Celery Island","Auke Bay","","","good","map-107.mp3",""],
["108","Aangoox̲a Yé","Beside the Slaves of the Town Place","Fish Creek","","","unsure","map-108.mp3",""],
["109","Til'héeni","Dog Salmon Creek","Salmon Creek","","","good","map-109.mp3",""],
["110","Katlaax̲ (Shaa Tlaax̲)","Moldy Top (Moldy Mountain)","Mount Juneau (top)","","","unsure, make region?","map-110.mp3",""],
["110.1","Yadaa.at Kalé","Beautifully Adorned Face","Mount Juneau (face)","","","unsure, make region?","map-110.1.mp3",""],
["111","Aangoox̲a Yé X'áat' T'aak̲","Beside Aangoox̲a Yé Island","Island at Fish Creek","","","unsure","map-111.mp3",""],
["112","L'awkalag̲é","Shiny Beach","Portland Island","","","unsure","map-112.mp3",""],
["113","Shikaagi Noow","Thick-Walled (of Logs)* Fort","Outer Point","","","unsure","map-113.mp3",""],
["114","It'ji Shaanáx̲","Sparkling Valley","Perseverance Valley","","","unsure","map-114.mp3",""],
["114.1","Dzánti","Flounder","Hill above Gold Creek (Capitol Hill)","","","unsure","map-114.1.mp3",""],
["115","Dzántik'i Héeni (Dzanti K'ihéeni)","Flounder at the Base of the Creek*","Juneau (Gold Creek)","","","unsure","map-115.mp3","map-115_2.mp3"],
["116","Deishú Áak'w","Little Lagoon at the End of the Trail","Douglas Island, Outer Point cove","","","unsure","map-116.mp3",""],
["117","Kanáx̲s'*","Unknown*","West side of Douglas Island, Peterson Creek*","","","unsure","map-117.mp3",""],
["118","Kaawa.ée Héenak'u","Kaawa.ée's Little Creek","Kowee Creek, near Juneau-Douglas Bridge","","","very unsure","map-118.mp3",""],
["119","K̲aalahéenak'u*","Inside a Person's Mouth Creek*","Peterson Creek","","","good","map-119.mp3",""],
["120","Wooshkeenax̲ Deiyí","Trails above Each Other","On Mount Roberts","","","good","map-120.mp3",""],
["121","T'aak̲ú","Flood of Geese","Taku River","","","unsure, just point, shade whole river?","map-121.mp3","map-121_2.mp3"],
["122","Shgóonaa Héenak'u","Schooner's Little Creek","Lawson Creek village","","","unsure","map-122.mp3",""],
["123","Deishú","End of the Trail","Lawson Creek village","","","unsure","map-123.mp3","map-123_2.mp3"],
["124","Sayéik*","Spirit Helper*","Douglas","","","unsure","map-124.mp3",""],
["125","X'áat' T'áak","Beside the Island","Douglas (Mayflower Island*)","","","unsure","map-125.mp3","map-125_2.mp3"],
["126","Chas'héeni","Humpback Salmon Creek","Sheep Creek","","","good","map-126.mp3",""],
["127","Anax̲ Yaa Andagan Yé","Where the Sun Rays Hit First","Douglas Boat Harbor","","","good","map-127.mp3",""],
["128","K̲oosh","Oozing Sore","Thane","","","unsure","map-128.mp3",""],
["129","Shakanáx̲k'w","Dead Trees on Mountain*","Funter Bay","","","good","map-129.mp3",""],
["130","Was'as'éi","Giant's name","Dorothy Peak*","","","unmarked","map-130.mp3",""],
["131","Keishixjix'aa","Runs-Up Point","Bishop Point","","","good","map-131.mp3",""],
["132","T'aak̲ú X̲'aka.aan","Town at the Mouth of T'aak̲ú","Taku River","","","unsure","map-132.mp3",""],
["133","Kanak̲'aa Sít'[i]","Skinny* Glacier","Young Glacier","","","unsure","map-133.mp3",""],
["134","Tsaa T'éi Héen","Behind the Seals Creek","Young Bay creek","","","unsure","map-134.mp3",""],
["135","Weineidei Aan (Weineideiyan)*","Alkali Deposit* Village","In Young Bay","","","unsure","map-135.mp3",""],
["136","G̲athéeni","Sockeye Creek","Snettisham","","","unmarked","map-136.mp3",""],
["137","Weineidei","Alkali Deposit* Trail","Hawk Inlet","","","unsure","map-137.mp3",""],
["138","Seenáa","Unknown*","Camp in Snettisham","","","unmarked","map-138.mp3",""],
["139","Deishú Áak'u","Little Lake at the End of the Trail","Oliver Inlet, Admiralty Island","","","good","map-139.mp3",""],
["140","Deishú Áak'u Aan","Camp on Deishú Áak'u","Camp on Oliver Inlet, Admiralty Island","","","unsure","map-140.mp3",""],
["141","S'iknax̲s'aank'í (Sik'náx̲ Aan G̲eey*)","Little One below Siknáx̲","Taku Harbor","","","good","map-141.mp3",""],
["142","S'iknáx̲ (Sik'náx̲*)","Black Bear Community* (Grindstone/Whetstone*)","Limestone Inlet","","","unmarked","map-142.mp3",""],
["143","Kanak̲'aa","Skinny*","Seymour Canal","","","good","map-143.mp3",""],
["144","Sit'k̲ú","Glacier Area","Port Snettisham","","","good","map-144.mp3","map-144_2.mp3"],
["145","Sít' G̲eeyí","Glacier Bay","Gilbert Bay","","","good","map-145.mp3",""],
["146","Tlaksidak*","Unknown*","Sweetheart Creek*","","","good","map-146.mp3",""],
["147","Chichnáx̲","Porpoise Harbor*","Windfall Harbor","","","good","map-147.mp3",""],
["148","Kichx'aa Yík*","Inside the Wing* Point","Island in Seymour Canal","","","good","map-148.mp3",""],
["149","Ldak̲éex̲'","Unknown*","Gambier Bay","","","good","map-149.mp3",""],
["150","G̲eiwk'óo [G̲eiwk'ú]","Unknown*","Upper Seymour Canal*","","","good","map-150.mp3",""],
]
// console.log("placenames",placenames)

let tlingitNameSort = JSON.parse(JSON.stringify(placenames));
tlingitNameSort.sort((a, b) => a[1].localeCompare(b[1]));
// console.log("tlingitNameSort",tlingitNameSort)

let translationSort = JSON.parse(JSON.stringify(placenames));
translationSort.sort((a, b) => a[2].localeCompare(b[2]));
// console.log("translationSort",translationSort)

let locationSort = JSON.parse(JSON.stringify(placenames));
locationSort.sort((a, b) => a[3].localeCompare(b[3]));
// console.log("translationSort",locationSort)

const options = [
  { key: 't', text: 'Tlingit Name', value: 0 },
  { key: 'tr', text: 'Translation', value: 1 },
  { key: 'l', text: 'Location', value: 2 },
]

let placenamesSorted = tlingitNameSort

class Report extends Component {
	constructor(props) {
		// console.log(props)
		super(props);
		this.state = {
			map:undefined,
			markersById:{},
			overlayMaps:{},
      sortValue:0,
			// history: props.location.state === undefined ? [] : props.location.state.history,
			// from: props.location.state === undefined ? '' : props.location.state.from,
		}	   
		scroll.scrollTo(0);

	}

	repeatAudio(audio, event, data) {
	  if (!this.state.playingAudio) {

	    let sound = new Audio(API_URL + "/tlingitaudiolibrary/" +  audio);
	    this.setState({playingAudio: true});

	    sound.play()

	    sound.onended=()=>{
	      this.setState({playingAudio: false});
	    }
	  }
	}

// <link rel="preconnect" href="https://fonts.googleapis.com">
// <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="">
// <link href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&amp;display=swap" rel="stylesheet">
// [leaflet-map address="Juneau, Alaska" zoom="10" height="700" tileurl=https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.jpg zoomcontrol dragging scrollwheel gesture doubleclickzoom]
// [leaflet-geojson src="https://gist.githubusercontent.com/lonnystrunk/fd99e52cfb86c1ebe207506bad898119/raw/fffe18df77df9c39af18a272d708b905571d7c92/placenames.geojson"]
	// <span style="font-family:'Raleway'"><b>{name}</b> #{id}
	// {translation}
	// {location}</span>
// [/leaflet-geojson]
// [geojsonmarker svg property=marker-color 
	// icondefault=#B5B5B5 
	// iconprops="#0056d6,#6EE5FF,#2EA524,#890101,#6F4413,#7c7c7c" 
	// shape=circle 
	// icon=fa-solid 
	// prefix=fa-circle 
	// disableClusteringAtZoom=4  
	// values="#0056d6,#00c7fc,#4f7a28,#555555,#d95000,#b5b5b5" 
	// groups="Water,Ice,Land,Settlement,Trail,Unsure" 
	// visible=1,1,1,1,1,0]
// [fullscreen][zoomhomemap !fit]

	componentDidMount() {
		var name2color = {
			// "Water":"#0056d6",
			// "Ice":"#00c7fc",
			// "Land":"#4f7a28",
			// "Settlement":"#555555",
			// "Trail":"#d95000",
			// "Unsure":"#b5b5b5",
			"Water":["#0056d6","Water","Héen - ","#0056d6"],
			"Ice":["#00c7fc","Glacier","Sít - ","#00c7fc"],
			"Land":["#4f7a28","Island","X'áat' - ","#4f7a28"],
			"Settlement":["#555555","Settlement","Aan - ","#9b1c20"],
			"Trail":["#d95000","Trail","Dei - ","#d95000"],
			"Unsure":["#b5b5b5","Unsure","","#555555"],
		};
		var overlayMaps = {};

		for (var n2c in name2color) {
			let n2cTitle = name2color[n2c][2] + name2color[n2c][1]
			overlayMaps[n2cTitle] = L.geoJSON(geoJSONMap, {
				pointToLayer: function (feature, latlng) {
					var marker = L.ExtraMarkers.icon({
						shape: 'circle',
						svg: true,
						markerColor: name2color[n2c][3],
						prefix: 'fa-solid',
						icon: 'fa-circle',
					});
					return L.marker(latlng, {icon: marker});
				},
				style: function(feature) {
					if (feature.geometry.type === "Point") {
						return {
							color: feature.properties['marker-color"'],
						}
					} else if (feature.geometry.type === "LineString") {
						return {
							color: feature.properties['stroke'],
							weight: feature.properties['stroke-width'],
							opacity: feature.properties['stroke-opacity'],
						}
					} else if (feature.geometry.type === "Polygon") {
						return {
							color: feature.properties['stroke'],
							weight: feature.properties['stroke-width'],
							opacity: feature.properties['stroke-opacity'],
							fillColor: feature.properties['fill'],
							fillOpacity: feature.properties['fill-opacity'],
						}
					} else {
						return {}
					}	
				},
				onEachFeature: function(feature, layer) {
					var popupContent
					if (feature.properties.audio2) {
            popupContent = "<div style=\"font-family:"+customFontFam+"; font-size:14px\"><div style=\"white-space:nowrap; padding-right:10px\"><audio id=\"player2\"><source src=\""+API_URL + "/tlingitaudiolibrary/" + feature.properties.audio2+"\" type=\"audio/mpeg\">Your browser does not support the audio tag.</audio><b>"+feature.properties.name+"</b>&nbsp;&nbsp;<a style=\"cursor:pointer\" onclick=\"document.getElementById('player2').play()\"><i class=\"fas fa-volume-up\"></i></a>"+"</div><div style=\"font-style:italic\">"+'"'+feature.properties.translation+'"'+"</div><div>"+feature.properties.location+"</div></div>";
            // popupContent = "<span style=\"font-family:'Raleway'\"><b>"+feature.properties.name+"</b>&nbsp;<a onclick={this.repeatAudio("+feature.properties.audio+")}><i class=\"fas fa-volume-up\"></i></a>&nbsp;#"+feature.properties.id+"<br>"+feature.properties.translation+"<br>"+feature.properties.location+"</span>";
          } else if (feature.properties.audio) {
						popupContent = "<div style=\"font-family:"+customFontFam+"; font-size:14px\"><div style=\"white-space:nowrap; padding-right:10px\"><audio id=\"player\"><source src=\""+API_URL + "/tlingitaudiolibrary/" + feature.properties.audio+"\" type=\"audio/mpeg\">Your browser does not support the audio tag.</audio><b>"+feature.properties.name+"</b>&nbsp;&nbsp;<a style=\"cursor:pointer\" onclick=\"document.getElementById('player').play()\"><i class=\"fas fa-volume-up\"></i></a>"+"</div><div style=\"font-style:italic\">"+'"'+feature.properties.translation+'"'+"</div><div>"+feature.properties.location+"</div></div>";
						// popupContent = "<span style=\"font-family:'Raleway'\"><b>"+feature.properties.name+"</b>&nbsp;<a onclick={this.repeatAudio("+feature.properties.audio+")}><i class=\"fas fa-volume-up\"></i></a>&nbsp;#"+feature.properties.id+"<br>"+feature.properties.translation+"<br>"+feature.properties.location+"</span>";
					} else {
						popupContent = "<span style=\"font-family:"+customFontFam+"; font-size:14px\"><b>"+feature.properties.name+"</b>"+"<br>"+'<span style=\"font-style:italic\">"'+feature.properties.translation+'"</span>'+"<br>"+feature.properties.location+"</span>";
					}
					layer.bindPopup(popupContent);
				},
				filter: function(feature, layer) {
					return (feature.properties["marker-color"] === name2color[n2c][0]);
				},
			});
		};
		
		var mapLink = '<a href="http://www.esri.com/">Esri</a>';
		var wholink = 'i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';

		var baseMap = L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { // 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
			maxZoom: 19,
			attribution: '&copy; '+mapLink+', '+wholink,
		});
		var baseMaps = {
			"base":baseMap
		};
		
		var mapOptions = {
			center:[58.301041, -134.424637],
			zoom:10,
			zoomControl: false,
			layers:[baseMap,overlayMaps["Héen - Water"],overlayMaps["Sít - Glacier"],overlayMaps["X'áat' - Island"],overlayMaps["Aan - Settlement"],overlayMaps["Dei - Trail"],overlayMaps["Unsure"]] // what to default on
		};
		var map = L.map('map', mapOptions);

		var layersOptions = {
			position:"bottomright",
			collapsed:false,
			hideSingleBase:true,
		};
		L.control.layers(baseMaps,overlayMaps,layersOptions).addTo(map);
		L.Control.zoomHome().addTo(map);

		var markersById = {};
		for (var layer in overlayMaps) {
			for (var m in overlayMaps[layer]._layers) {
				var marker = overlayMaps[layer]._layers[m]
				if (marker.feature.geometry.type == "Point") {
					markersById[marker.feature.properties.id] = [marker,layer]
				}
			}
		}
		this.setState({map:map, markersById:markersById, overlayMaps:overlayMaps})
	}

	flyToMarker(id) {
    scroll.scrollTo(0);
		if (this.state.markersById.hasOwnProperty(id)) {
			const marker = this.state.markersById[id][0]
			const layer = this.state.markersById[id][1]
			if (!(this.state.map.hasLayer(this.state.overlayMaps[layer]))) {
				this.state.map.addLayer(this.state.overlayMaps[layer])
			}
			const latlng = marker._latlng
			const zoom = 12
			const zoomPanOptions = {}
			this.state.map.flyTo(latlng, zoom, zoomPanOptions);
			marker.openPopup();
		}
	}

  handleSortChange = (e, {value}) => {
    if (value === 0 && this.state.sortValue !== 0) {
      this.setState({sortValue: 0})
      placenamesSorted = tlingitNameSort
    } else if (value === 1 && this.state.sortValue !== 1) {
      this.setState({sortValue: 1})
      placenamesSorted = translationSort
    } else if (value === 2 && this.state.sortValue !== 2) {
      this.setState({sortValue: 2})
      placenamesSorted = locationSort
    }
  } 


	render() {

    
		return (
			<div style={{fontFamily:customFontFam}}>
{/*      <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:(window.innerWidth < 600 ? 'column':'row'),height:(window.innerWidth < 600 ? '250px':'200px'),backgroundColor:'#890101'}}>
            <Image style={{'maxWidth': '300px',cursor:'pointer'}} src={"/dialogo1.png"}/>   
            <div style={{fontFamily:'Raleway',color:'white',fontSize:'20px',fontWeight:'400',maxWidth:(window.innerWidth < 600 ? '365px':'600px'),}}>
            <div>Anax Yaa Andagan Ye ~ Sayeik | Where the Sun Rays Touch First ~ Spirit Helper</div> 
            </div>      
      </div>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <span style={{width:(window.innerWidth < 600 ? '250px':'880px'),height:'34px',margin:'16px 20px',fontWeight:'700',fontSize:'28px',fontFamily:'"Cormorant SC"'}}><a href={"https://diataku.com/"} style={{color:'#333333',}}><Icon circular style={{fontSize:'16px',marginRight:'10px',}} name='chevron left' />Return to Home</a></span>
      </div>*/}

			<Grid textAlign='center'>
			<Grid.Row style={{padding:'0px 20px',margin:0,}}>
				<Grid.Column style={{ maxWidth: 800, padding: (window !== window.parent ? 0 : 10)}} textAlign='left'>

          <Link to={{pathname: "/"}}>
          <Icon circular style={{margin:15,marginLeft:10,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
          </Link>         

					<div style={{border:'1px solid #E3E3E3'}}>

						<div className='hierarchymain'>
						<span className='span1'></span>
						<span className='span2'>T'aak̲ú Áak'w K̲wáan Place Names Map</span>
						</div>
						<div style={{height:(window.innerWidth < 480 ? '400px':'500px')}} id="map"></div>
						


					</div>
					<div style={{border:'1px solid #E3E3E3',marginTop:'14px'}}>


						<div style={{display:'flex',backgroundColor:'#F3F3EC',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
							
              {window.innerWidth < 480 ?
  							 null
                 :
              <div className='hierarchymain' style={{marginLeft:8}}>
                 <span className='span2'>Place Names List</span>
              </div>
               }

							<div className='hierarchymain' style={{marginRight:15}}>
							<span className='span2' style={{marginLeft:15,fontSize:(window.innerWidth < 480 ? '15px':'16px')}}>Sort by:</span>
							<Dropdown options={options} value={this.state.sortValue} onChange={this.handleSortChange} style={{fontFamily:customFontFam,fontSize:(window.innerWidth < 480 ? '15px':'16px'),fontWeight:400,color:'#000000DE',padding:5,borderRadius:5,marginLeft:5}} />
							</div>

						</div>

          <Segment style={{fontFamily:customFontFam, maxHeight:280,border:'0px',overflow: 'auto',fontSize:(window.innerWidth < 480 ? '15px':'16px'),padding:(window.innerWidth < 480 ? '10px':'15px'),margin:0}}>
            <div style={{display:'flex',flexDirection:'column'}}>

            {placenamesSorted.map((d,ind)=>{
							const [id,name,translation,location,googleName,googleDescription,pointQuality,audioFilename,audioFilename2] = d;
            	return <div style={{display:'flex',flexDirection:'row',marginBottom:(ind != placenamesSorted.length-1 ? '8px':'0px'),lineHeight:'24px'}}>
	      				<div style={{flex:(window.innerWidth < 480 ? 5:4)}}>
	      					{pointQuality.includes('unmarked') ? 
                    <span>{name}</span>
                    :
                    <a style={{color:'#4A80B5',cursor:'pointer',}} onClick={() => this.flyToMarker(id)}>{name}</a>
                  }
                  {audioFilename2 ? <Icon circular onClick={()=>this.repeatAudio(audioFilename2)} style={{marginLeft:(window.innerWidth < 480 ? '5px':'10px'),fontSize:'13px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' /> : 
                    (audioFilename ? <Icon circular onClick={()=>this.repeatAudio(audioFilename)} style={{marginLeft:(window.innerWidth < 480 ? '5px':'10px'),fontSize:'13px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' /> : null)
                  }
	      				</div>
	      				<div style={{flex:4, color:'#666666',lineHeight:'22px',paddingLeft:'3px',paddingRight:'3px',fontStyle:'italic'}}>{'"'+translation+'"'}</div>
	      				<div style={{flex:4, color:'#666666',lineHeight:'22px',paddingLeft:'3px',paddingRight:'3px'}}>{location}</div>
	      			</div>            	
            })}
	      			</div>            	

          </Segment> 


					</div>





					<div style={{border:'1px solid #E3E3E3',marginTop:'15px'}}>

				  <div style={{margin:'20px',fontSize:'16px',fontFamily:customFontFam,}}>
            <div style={{color:'#666666',fontStyle:'italic'}}>
            *not validated
            </div>
						<div style={{marginTop:'12px'}}>
						Place names are based on the 2012 publication <b>"Haa Léelk'w Has Aaní Saax'ú : Our Grandparents' Names on the Land"</b>.
						</div>
						<div style={{marginTop:'12px'}}>
						This online map is still in development. Please note that there will be continued corrections of names, positions, and labels. Place names voiced by Koodéik' (Joseph Marks). 
						</div>
						<div style={{marginTop:'12px',fontStyle:'italic'}}>
						Source: Thornton, Thomas F., and Sealaska Heritage Institute. Haa Léelk'w Has Aaní Saax'ú : Our Grandparents' Names on the Land. Seattle : Juneau: University of Washington Press ; Sealaska Heritage Institute, 2012.
						</div>		                
				  </div>
					</div>

				</Grid.Column>
			</Grid.Row>
			</Grid>

			</div>

		);
	}
}
export default Report;
