export const dialogues = {
    "1": {
        "title": "Nouns used in exercises",
        "content": [
            {
                "type": "subtitle",
                "english": "Smaller nouns (can be held in hand)"
            },
            {
                "type": "dia",
                "english": "cup",
                "tlingit": [
                    [
                        [
                            "gúx'aa",
                            [
                                {
                                    "tlingit": "gúx'aa",
                                    "url": "gúx'aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "cup; can",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_31",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "scissors",
                "tlingit": [
                    [
                        [
                            "k̲aashaxáshaa",
                            [
                                {
                                    "tlingit": "k̲aashaxáshaa",
                                    "url": "k̲aashaxáshaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "scissors",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_32",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "spoon",
                "tlingit": [
                    [
                        [
                            "shál",
                            [
                                {
                                    "tlingit": "shál",
                                    "url": "shál",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "spoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_33",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "fork",
                "tlingit": [
                    [
                        [
                            "ách at dusx̲a át",
                            [
                                {
                                    "tlingit": "ách at dusx̲a át",
                                    "url": "ách at dusx̲a át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fork",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "ách at yadusx̲a át",
                                    "url": "ách at dusx̲a át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fork",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a tóo at dult'ix' át",
                                    "url": "a tóo at dult'ix'x̲i át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "freezer",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "át at kawu.aag̲ú",
                                    "url": "át k̲ukawu.aag̲ú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "director, planner; commander",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl at eex̲áak̲!",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't eat!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gux̲danáa",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will drink",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei at gug̲ayáa",
                                    "url": "at wooyaa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will carry things on his/her/its back",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl at eex̲'aaník̲!",
                                    "url": "at uwax̲'án",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't dry fish!",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_34",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "paper",
                "tlingit": [
                    [
                        [
                            "x’úx'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_35",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "pencil or pen",
                "tlingit": [
                    [
                        [
                            "kooxéedaa",
                            [
                                {
                                    "tlingit": "kooxéedaa",
                                    "url": "kooxéedaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pencil; pen; brush",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_36",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "dime",
                "tlingit": [
                    [
                        [
                            "gút",
                            [
                                {
                                    "tlingit": "gút",
                                    "url": "gút",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dime",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_37",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "handkerchief",
                "tlingit": [
                    [
                        [
                            "lug̲wéinaa",
                            [
                                {
                                    "tlingit": "lug̲wéinaa",
                                    "url": "lug̲wéinaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "handkerchief",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_38",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "hand towel",
                "tlingit": [
                    [
                        [
                            "jig̲wéinaa",
                            [
                                {
                                    "tlingit": "jig̲wéinaa",
                                    "url": "jig̲wéinaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "towel, hand towel",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_39",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "bracelet",
                "tlingit": [
                    [
                        [
                            "kées",
                            [
                                {
                                    "tlingit": "kées",
                                    "url": "kées",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bracelet",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_40",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "subtitle",
                "english": "Larger nouns"
            },
            {
                "type": "dia",
                "english": "tree",
                "tlingit": [
                    [
                        [
                            "aas",
                            [
                                {
                                    "tlingit": "aas",
                                    "url": "aas",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tree (esp. conifer)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_41",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "boat",
                "tlingit": [
                    [
                        [
                            "yaakw",
                            [
                                {
                                    "tlingit": "yaakw",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boat, canoe",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_42",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "brown bear",
                "tlingit": [
                    [
                        [
                            "xóots",
                            [
                                {
                                    "tlingit": "xóots",
                                    "url": "xóots",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "grizzly bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_43",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "house",
                "tlingit": [
                    [
                        [
                            "hít",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_44",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "rock",
                "tlingit": [
                    [
                        [
                            "té",
                            [
                                {
                                    "tlingit": "té",
                                    "url": "té",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "stone; rock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_45",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "bald eagle",
                "tlingit": [
                    [
                        [
                            "ch'áak'",
                            [
                                {
                                    "tlingit": "ch'áak'",
                                    "url": "ch'áak'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bald eagle",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_46",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "raven",
                "tlingit": [
                    [
                        [
                            "yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_47",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "whale",
                "tlingit": [
                    [
                        [
                            "yáay",
                            [
                                {
                                    "tlingit": "yáay",
                                    "url": "yáay",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "whale",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_48",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "killer whale",
                "tlingit": [
                    [
                        [
                            "kéet",
                            [
                                {
                                    "tlingit": "kéet",
                                    "url": "kéet",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "killerwhale",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_49",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "glacier",
                "tlingit": [
                    [
                        [
                            "sít'",
                            [
                                {
                                    "tlingit": "sít'",
                                    "url": "sít'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "glacier",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_50",
                        "N",
                        "L"
                    ]
                ]
            }
        ],
        "subtitles": {}
    },
    "2": {
        "title": "What's she/he/it called?",
        "content": [
            {
                "type": "dia",
                "english": "How is s/he/it called?",
                "tlingit": [
                    [
                        [
                            "Waa",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_28",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "What is this one here called?",
                "tlingit": [
                    [
                        [
                            "Waa",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "yáat’aa?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_29",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "What is that one called there?",
                "tlingit": [
                    [
                        [
                            "Waa",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "wéit’aa?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_30",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "This one is called [name].",
                "tlingit": [
                    [
                        [
                            "[name]",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "yáat’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_51",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "She/he/it is called [name].",
                "tlingit": [
                    [
                        [
                            "[name]",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_52",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "This one is called cup.",
                "tlingit": [
                    [
                        [
                            "Gúx'aa",
                            [
                                {
                                    "tlingit": "gúx'aa",
                                    "url": "gúx'aa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "cup; can",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "yáat’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_53",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "This one is called scissors.",
                "tlingit": [
                    [
                        [
                            "K̲aashaxáshaa",
                            [
                                {
                                    "tlingit": "k̲aashaxáshaa",
                                    "url": "k̲aashaxáshaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "scissors",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "yáat’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_54",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "This one is called spoon.",
                "tlingit": [
                    [
                        [
                            "Shál",
                            [
                                {
                                    "tlingit": "shál",
                                    "url": "shál",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "spoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "yáat’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_55",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "This one is called fork.",
                "tlingit": [
                    [
                        [
                            "Ách at dusx̲a át",
                            [
                                {
                                    "tlingit": "ách at dusx̲a át",
                                    "url": "ách at dusx̲a át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fork",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "ách at yadusx̲a át",
                                    "url": "ách at dusx̲a át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fork",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a tóo at dult'ix' át",
                                    "url": "a tóo at dult'ix'x̲i át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "freezer",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "át at kawu.aag̲ú",
                                    "url": "át k̲ukawu.aag̲ú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "director, planner; commander",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl at eex̲áak̲!",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't eat!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gux̲danáa",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will drink",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei at gug̲ayáa",
                                    "url": "at wooyaa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will carry things on his/her/its back",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl at eex̲'aaník̲!",
                                    "url": "at uwax̲'án",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't dry fish!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "yáat’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_56",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "This one is called paper.",
                "tlingit": [
                    [
                        [
                            "X’úx",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "yáat’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_57",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "This one is called pencil or pen.",
                "tlingit": [
                    [
                        [
                            "Kooxéedaa",
                            [
                                {
                                    "tlingit": "kooxéedaa",
                                    "url": "kooxéedaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pencil; pen; brush",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "yáat’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_58",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "That one is called [name].",
                "tlingit": [
                    [
                        [
                            "[name]",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "wéit’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_59",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "That one is called tree.",
                "tlingit": [
                    [
                        [
                            "Aas",
                            [
                                {
                                    "tlingit": "aas",
                                    "url": "aas",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tree (esp. conifer)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "wéit’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_60",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "That one is called boat.",
                "tlingit": [
                    [
                        [
                            "Yaakw",
                            [
                                {
                                    "tlingit": "yaakw",
                                    "url": "yaakw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "boat, canoe",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "wéit’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_61",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "That one is called brown bear.",
                "tlingit": [
                    [
                        [
                            "Xóots",
                            [
                                {
                                    "tlingit": "xóots",
                                    "url": "xóots",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "grizzly bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "wéit’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_62",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "That one is called black bear.",
                "tlingit": [
                    [
                        [
                            "S'eek",
                            [
                                {
                                    "tlingit": "s'eek",
                                    "url": "s'eek",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "black bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "wéit’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_63",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "That one is called bald eagle.",
                "tlingit": [
                    [
                        [
                            "Ch'áak'",
                            [
                                {
                                    "tlingit": "ch'áak'",
                                    "url": "ch'áak'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bald eagle",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "wéit’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_64",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "That one is called raven.",
                "tlingit": [
                    [
                        [
                            "Yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "wéit’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_65",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "That one is called whale.",
                "tlingit": [
                    [
                        [
                            "Yáay",
                            [
                                {
                                    "tlingit": "yáay",
                                    "url": "yáay",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "whale",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "wéit’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_66",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "That one is called orca.",
                "tlingit": [
                    [
                        [
                            "Kéet",
                            [
                                {
                                    "tlingit": "kéet",
                                    "url": "kéet",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "killerwhale",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "wéit’aa.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_67",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "dia",
                "english": "In Tlingit it is called [name].",
                "tlingit": [
                    [
                        [
                            "[name]",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’eináx̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_68",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In Tlingit it is called black bear.",
                "tlingit": [
                    [
                        [
                            "S'eek",
                            [
                                {
                                    "tlingit": "s'eek",
                                    "url": "s'eek",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "black bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’eináx̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_69",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In Tlingit it is called bald eagle.",
                "tlingit": [
                    [
                        [
                            "Ch'áak'",
                            [
                                {
                                    "tlingit": "ch'áak'",
                                    "url": "ch'áak'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bald eagle",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’eináx̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_70",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In Tlingit it is called raven.",
                "tlingit": [
                    [
                        [
                            "Yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’eináx̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_71",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In Tlingit it is called whale.",
                "tlingit": [
                    [
                        [
                            "Yáay",
                            [
                                {
                                    "tlingit": "yáay",
                                    "url": "yáay",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "whale",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’eináx̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_72",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "dia",
                "english": "It is called a black bear, right?",
                "tlingit": [
                    [
                        [
                            "S'eek",
                            [
                                {
                                    "tlingit": "s'eek",
                                    "url": "s'eek",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "black bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kwshé?",
                            [
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "Eh?; I wonder",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshéi",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "maybe; I'm not sure; [expression of uncertainly]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_73",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Yes, it is called black bear.",
                "tlingit": [
                    [
                        [
                            "Aaá,",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "s'eek",
                            [
                                {
                                    "tlingit": "s'eek",
                                    "url": "s'eek",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "black bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_74",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "No, it is called brown bear.",
                "tlingit": [
                    [
                        [
                            "Tléik’,",
                            [],
                            []
                        ],
                        [
                            "xóots",
                            [
                                {
                                    "tlingit": "xóots",
                                    "url": "xóots",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "grizzly bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_75",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "It is called a brown bear, right?",
                "tlingit": [
                    [
                        [
                            "Xóots",
                            [
                                {
                                    "tlingit": "xóots",
                                    "url": "xóots",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "grizzly bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kwshé?",
                            [
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "Eh?; I wonder",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshéi",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "maybe; I'm not sure; [expression of uncertainly]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_76",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Yes, it is called a brown bear.",
                "tlingit": [
                    [
                        [
                            "Aaá,",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "xóots",
                            [
                                {
                                    "tlingit": "xóots",
                                    "url": "xóots",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "grizzly bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_77",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "No, it is called a black bear.",
                "tlingit": [
                    [
                        [
                            "Tléik’,",
                            [],
                            []
                        ],
                        [
                            "s'eek",
                            [
                                {
                                    "tlingit": "s'eek",
                                    "url": "s'eek",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "black bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_78",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "It is called a killer whale, right?",
                "tlingit": [
                    [
                        [
                            "Kéet",
                            [
                                {
                                    "tlingit": "kéet",
                                    "url": "kéet",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "killerwhale",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kwshé?",
                            [
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "Eh?; I wonder",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshéi",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "maybe; I'm not sure; [expression of uncertainly]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_79",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Yes, it is called a killer whale.",
                "tlingit": [
                    [
                        [
                            "Aaá,",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kéet",
                            [
                                {
                                    "tlingit": "kéet",
                                    "url": "kéet",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "killerwhale",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_80",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "No, it is called a whale.",
                "tlingit": [
                    [
                        [
                            "Tléik’,",
                            [],
                            []
                        ],
                        [
                            "yáay",
                            [
                                {
                                    "tlingit": "yáay",
                                    "url": "yáay",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "whale",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_81",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "What is this here and this here called?",
                "tlingit": [
                    [
                        [
                            "Waa",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw,",
                            [],
                            []
                        ],
                        [
                            "yáat’aa",
                            [],
                            []
                        ],
                        [
                            "k̲a",
                            [
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáat’aa?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_121",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "This one is pencil, this one is bracelet.",
                "tlingit": [
                    [
                        [
                            "Yáat’aa",
                            [],
                            []
                        ],
                        [
                            "kooxéedaa",
                            [
                                {
                                    "tlingit": "kooxéedaa",
                                    "url": "kooxéedaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pencil; pen; brush",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "k̲a",
                            [
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáat'aa",
                            [
                                {
                                    "tlingit": "yáat'aa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "prnaa"
                                    ],
                                    "english": "this one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kées",
                            [
                                {
                                    "tlingit": "kées",
                                    "url": "kées",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bracelet",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_122",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "This one is spoon, this one is fork.",
                "tlingit": [
                    [
                        [
                            "Yáat’aa",
                            [],
                            []
                        ],
                        [
                            "shál",
                            [
                                {
                                    "tlingit": "shál",
                                    "url": "shál",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "spoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "k̲a",
                            [
                                {
                                    "tlingit": "k̲a",
                                    "url": "k̲a",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "and",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáat'aa",
                            [
                                {
                                    "tlingit": "yáat'aa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "prnaa"
                                    ],
                                    "english": "this one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ách at dusx̲a",
                            [
                                {
                                    "tlingit": "ách at dusx̲a át",
                                    "url": "ách at dusx̲a át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fork",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du ách at dusx̲a ádi",
                                    "url": "ách at dusx̲a át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its fork",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "at sax̲án",
                                    "url": "at sax̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "love (of things, of everything)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at uwax̲áa",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he ate",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gux̲danáa",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will drink",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gux̲sa.ée",
                                    "url": "at wusi.ée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will cook",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at uwax̲'án",
                                    "url": "at uwax̲'án",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he dried fish",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi at gug̲wanei",
                                    "url": "yéi at woonei",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "that will happen",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at tux̲'wáns'i",
                                    "url": "at tux̲'wáns'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "buckshot; moccasin lining",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_123",
                        "N",
                        "R"
                    ]
                ]
            }
        ],
        "subtitles": {}
    },
    "3": {
        "title": "Is it a [thing]?",
        "content": [
            {
                "type": "dia",
                "english": "Is it a brown bear?",
                "tlingit": [
                    [
                        [
                            "Xoots",
                            [],
                            []
                        ],
                        [
                            "ák.yá?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_82",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "It's a brown bear.",
                "tlingit": [
                    [
                        [
                            "Xoots",
                            [],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_83",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Is it a tree?",
                "tlingit": [
                    [
                        [
                            "Aas",
                            [
                                {
                                    "tlingit": "aas",
                                    "url": "aas",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tree (esp. conifer)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ák.yá?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_84",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "It's a tree.",
                "tlingit": [
                    [
                        [
                            "Aas",
                            [
                                {
                                    "tlingit": "aas",
                                    "url": "aas",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tree (esp. conifer)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_85",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Is it a whale?",
                "tlingit": [
                    [
                        [
                            "Yáay",
                            [
                                {
                                    "tlingit": "yáay",
                                    "url": "yáay",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "whale",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ák.yá?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_86",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "It's a whale.",
                "tlingit": [
                    [
                        [
                            "Yáay",
                            [
                                {
                                    "tlingit": "yáay",
                                    "url": "yáay",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "whale",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_87",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Is it a house?",
                "tlingit": [
                    [
                        [
                            "Hít",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ák.yá?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_88",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "It's a house.",
                "tlingit": [
                    [
                        [
                            "Hít",
                            [
                                {
                                    "tlingit": "hít",
                                    "url": "hít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "house; building",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_89",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Is it a black bear?",
                "tlingit": [
                    [
                        [
                            "S'eek",
                            [
                                {
                                    "tlingit": "s'eek",
                                    "url": "s'eek",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "black bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ák.yá?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_90",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "It's a black bear.",
                "tlingit": [
                    [
                        [
                            "S'eek",
                            [
                                {
                                    "tlingit": "s'eek",
                                    "url": "s'eek",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "black bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_91",
                        "N",
                        "R"
                    ]
                ]
            }
        ],
        "subtitles": {}
    },
    "4": {
        "title": "Which moiety/clan?",
        "content": [
            {
                "type": "dia",
                "english": "Which moiety is s/he?",
                "tlingit": [
                    [
                        [
                            "Daaḱw",
                            [],
                            []
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee?",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_98",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "S/he is raven.",
                "tlingit": [
                    [
                        [
                            "Yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_99",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "S/he is eagle.",
                "tlingit": [
                    [
                        [
                            "Ch’áak’",
                            [],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_100",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Which moiety/clan are you?",
                "tlingit": [
                    [
                        [
                            "Daak̲w.aa",
                            [
                                {
                                    "tlingit": "daak̲w.aa sá",
                                    "url": "daak̲w.aa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "which (one); some (certain one)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "isitee?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_101",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Are you eagle?",
                "tlingit": [
                    [
                        [
                            "Ch’áak’",
                            [],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "isitee?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_102",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Yes, I am eagle, Kaagwaantaan clan.",
                "tlingit": [
                    [
                        [
                            "Aaá,",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ch’áak’",
                            [],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee,",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Kaagwaantaan",
                            [
                                {
                                    "tlingit": "Kaagwaantaan",
                                    "url": "Kaagwaantaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Kaagwaantaan, locally called \"Wolf\"; a clan of the Eagle moiety whose principal crest is the Wolf",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at.",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_103",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "No, I am raven, L'uknaax̲.ádi clan.",
                "tlingit": [
                    [
                        [
                            "Tléik’,",
                            [],
                            []
                        ],
                        [
                            "yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee,",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "L'uknaax̲.ádi",
                            [],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at.",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_104",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Are you raven?",
                "tlingit": [
                    [
                        [
                            "Yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "isitee?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_105",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Yes, I am raven, L'uknaax̲.ádi clan",
                "tlingit": [
                    [
                        [
                            "Aaá,",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee,",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "L'uknaax̲.ádi",
                            [],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at.",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_106",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "No, I am eagle, Kaagwaantaan clan",
                "tlingit": [
                    [
                        [
                            "Tléik’,",
                            [],
                            []
                        ],
                        [
                            "ch’áak’",
                            [],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee,",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Kaagwaantaan",
                            [
                                {
                                    "tlingit": "Kaagwaantaan",
                                    "url": "Kaagwaantaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Kaagwaantaan, locally called \"Wolf\"; a clan of the Eagle moiety whose principal crest is the Wolf",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at.",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_107",
                        "J",
                        "R"
                    ]
                ]
            }
        ],
        "subtitles": {}
    },
    "5": {
        "title": "Which/what do you want?",
        "content": [
            {
                "type": "dia",
                "english": "Do you want this one here?",
                "tlingit": [
                    [
                        [
                            "Yáat'aa",
                            [
                                {
                                    "tlingit": "yáat'aa",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "prnaa"
                                    ],
                                    "english": "this one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo?",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_110",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Yes",
                "tlingit": [
                    [
                        [
                            "Aaá",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_111",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "No",
                "tlingit": [
                    [
                        [
                            "Tléik’",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_112",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Here! (giving something to someone)",
                "tlingit": [
                    [
                        [
                            "Ná!",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_113",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "dia",
                "english": "Which one do you want?",
                "tlingit": [
                    [
                        [
                            "Daak̲w",
                            [],
                            []
                        ],
                        [
                            "aa sá",
                            [
                                {
                                    "tlingit": "ch'a aa sá",
                                    "url": "ch'a aa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a aa sá",
                                    "url": "ch'a aadóo sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo?",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_108",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "What do you want?",
                "tlingit": [
                    [
                        [
                            "Daa sá",
                            [
                                {
                                    "tlingit": "hél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a daa sá",
                                    "url": "ch'a daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anything; whatever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlél daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tléil daa sá",
                                    "url": "tlél daa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nothing",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "a daa",
                                    "url": "a daa",
                                    "tags": [
                                        "RelationalNoun",
                                        "possessed"
                                    ],
                                    "english": "around it; about it; concerning it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "áx̲ daa",
                                    "url": "át uwadáa",
                                    "tags": [
                                        "Verb",
                                        "repetitive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "it flows to it (regularly)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yanees.á!",
                                    "url": "a daa yawdzi.aa",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Examine it!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gaan daa",
                                    "url": "gandaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "around the fire",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "a daa tuwatee",
                                    "url": "a daa tuwatee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he cares about it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "A daa yoo tutánk!",
                                    "url": "a daa yoo toowatán",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Think about it!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo?",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_109",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I want everything.",
                "tlingit": [
                    [
                        [
                            "Ldakát át",
                            [
                                {
                                    "tlingit": "ldakát át",
                                    "url": "ldakát át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "everything",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "ldakát",
                                    "url": "ldakát",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "all; every",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "át",
                                    "url": "át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "thing",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_114",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I want a dime.",
                "tlingit": [
                    [
                        [
                            "Gút",
                            [
                                {
                                    "tlingit": "gút",
                                    "url": "gút",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "dime",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_119",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I want a bracelet.",
                "tlingit": [
                    [
                        [
                            "Kées",
                            [
                                {
                                    "tlingit": "kées",
                                    "url": "kées",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bracelet",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_120",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "dia",
                "english": "Which one do you want spoon or fork?",
                "tlingit": [
                    [
                        [
                            "Daak̲w",
                            [],
                            []
                        ],
                        [
                            "aa sá",
                            [
                                {
                                    "tlingit": "ch'a aa sá",
                                    "url": "ch'a aa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a aa sá",
                                    "url": "ch'a aadóo sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "shál",
                            [
                                {
                                    "tlingit": "shál",
                                    "url": "shál",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "spoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kachoo",
                            [],
                            []
                        ],
                        [
                            "ách at dusx̲a?",
                            [
                                {
                                    "tlingit": "ách at dusx̲a át",
                                    "url": "ách at dusx̲a át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fork",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du ách at dusx̲a ádi",
                                    "url": "ách at dusx̲a át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its fork",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "yéi at gug̲wanei",
                                    "url": "yéi at woonei",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "that will happen",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at sax̲án",
                                    "url": "at sax̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "love (of things, of everything)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at tux̲'wáns'i",
                                    "url": "at tux̲'wáns'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "buckshot; moccasin lining",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yax̲ at g̲wakú",
                                    "url": "yax̲ at g̲wakú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "saying, proverb; event that is so common it has become a saying or proverb",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at uwax̲áa",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he ate",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gux̲danáa",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will drink",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gux̲sa.ée",
                                    "url": "at wusi.ée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will cook",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_124",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I want a spoon.",
                "tlingit": [
                    [
                        [
                            "Shál",
                            [
                                {
                                    "tlingit": "shál",
                                    "url": "shál",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "spoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_115",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I want a fork.",
                "tlingit": [
                    [
                        [
                            "Ách at dusx̲a át",
                            [
                                {
                                    "tlingit": "ách at dusx̲a át",
                                    "url": "ách at dusx̲a át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fork",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "ách at yadusx̲a át",
                                    "url": "ách at dusx̲a át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fork",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a tóo at dult'ix' át",
                                    "url": "a tóo at dult'ix'x̲i át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "freezer",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "át at kawu.aag̲ú",
                                    "url": "át k̲ukawu.aag̲ú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "director, planner; commander",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl at eex̲áak̲!",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't eat!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gux̲danáa",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will drink",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "kei at gug̲ayáa",
                                    "url": "at wooyaa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it will carry things on his/her/its back",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Líl at eex̲'aaník̲!",
                                    "url": "at uwax̲'án",
                                    "tags": [
                                        "Verb",
                                        "prohibitive",
                                        "2Sg"
                                    ],
                                    "english": "Don't dry fish!",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_116",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Which one do you want paper or pencil?",
                "tlingit": [
                    [
                        [
                            "Daak̲w",
                            [],
                            []
                        ],
                        [
                            "aa sá",
                            [
                                {
                                    "tlingit": "ch'a aa sá",
                                    "url": "ch'a aa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a aa sá",
                                    "url": "ch'a aadóo sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "i",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "x’úx",
                            [],
                            []
                        ],
                        [
                            "kachoo",
                            [],
                            []
                        ],
                        [
                            "kooxéedaa?",
                            [
                                {
                                    "tlingit": "kooxéedaa",
                                    "url": "kooxéedaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pencil; pen; brush",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_125",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I want paper.",
                "tlingit": [
                    [
                        [
                            "X’úx'",
                            [],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_117",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I want a pencil/pen.",
                "tlingit": [
                    [
                        [
                            "Kooxéedaa",
                            [
                                {
                                    "tlingit": "kooxéedaa",
                                    "url": "kooxéedaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pencil; pen; brush",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_118",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "dia",
                "english": "Which one does s/he/it want?",
                "tlingit": [
                    [
                        [
                            "Daak̲w",
                            [],
                            []
                        ],
                        [
                            "aa sá",
                            [
                                {
                                    "tlingit": "ch'a aa sá",
                                    "url": "ch'a aa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a aa sá",
                                    "url": "ch'a aadóo sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "du tuwáa sigóo?",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_126",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "S/he/it wants paper.",
                "tlingit": [
                    [
                        [
                            "X’úx",
                            [],
                            []
                        ],
                        [
                            "du tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_127",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "S/he/it wants a spoon.",
                "tlingit": [
                    [
                        [
                            "Shál",
                            [
                                {
                                    "tlingit": "shál",
                                    "url": "shál",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "spoon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_128",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "S/he/it wants a fork.",
                "tlingit": [
                    [
                        [
                            "Ách at dusx̲a",
                            [
                                {
                                    "tlingit": "ách at dusx̲a át",
                                    "url": "ách at dusx̲a át",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "fork",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du ách at dusx̲a ádi",
                                    "url": "ách at dusx̲a át",
                                    "tags": [
                                        "Noun",
                                        "possessed"
                                    ],
                                    "english": "his/her/its fork",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "at sax̲án",
                                    "url": "at sax̲án",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "love (of things, of everything)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at uwax̲áa",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he ate",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gux̲danáa",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will drink",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gux̲sa.ée",
                                    "url": "at wusi.ée",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will cook",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at uwax̲'án",
                                    "url": "at uwax̲'án",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he dried fish",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi at gug̲wanei",
                                    "url": "yéi at woonei",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "that will happen",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at tux̲'wáns'i",
                                    "url": "at tux̲'wáns'i",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "buckshot; moccasin lining",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "du tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_129",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "S/he/it wants a pencil/pen.",
                "tlingit": [
                    [
                        [
                            "Kooxéedaa",
                            [
                                {
                                    "tlingit": "kooxéedaa",
                                    "url": "kooxéedaa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "pencil; pen; brush",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "du tuwáa sigóo.",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_130",
                        "J",
                        "R"
                    ]
                ]
            }
        ],
        "subtitles": {}
    },
    "6": {
        "title": "I am speaking. I want to [verb].",
        "content": [
            {
                "type": "dia",
                "english": "I am speaking.",
                "tlingit": [
                    [
                        [
                            "Yóo",
                            [
                                {
                                    "tlingit": "yóo",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóo",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (distant), yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’ax̲atánk.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_131",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I am thinking.",
                "tlingit": [
                    [
                        [
                            "Yóo",
                            [
                                {
                                    "tlingit": "yóo",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóo",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (distant), yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tux̲datánk.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_132",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I am speaking with you (sg).",
                "tlingit": [
                    [
                        [
                            "Ee",
                            [
                                {
                                    "tlingit": "du ee~",
                                    "url": "du ee~",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "him, her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "een",
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yóo",
                            [
                                {
                                    "tlingit": "yóo",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóo",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (distant), yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’ax̲atánk.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_133",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I am speaking with you all.",
                "tlingit": [
                    [
                        [
                            "Yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "een",
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yóo",
                            [
                                {
                                    "tlingit": "yóo",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóo",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (distant), yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’ax̲atánk.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_134",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I want to speak with you (sg).",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "ee",
                            [
                                {
                                    "tlingit": "du ee~",
                                    "url": "du ee~",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "him, her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "een",
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yóo",
                            [
                                {
                                    "tlingit": "yóo",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóo",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (distant), yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’ax̲ataaní.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_135",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I want to eat it (sg).",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowáa",
                            [],
                            []
                        ],
                        [
                            "sigóo",
                            [
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲waax̲aayí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_136",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I want to sleep.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowáa",
                            [],
                            []
                        ],
                        [
                            "sigóo",
                            [
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲waataayí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_137",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I want to dance.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowáa",
                            [],
                            []
                        ],
                        [
                            "sigóo",
                            [
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲waal'eix̲í.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_138",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I want to leave.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowáa",
                            [],
                            []
                        ],
                        [
                            "sigóo",
                            [
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "aadáx̲",
                            [
                                {
                                    "tlingit": "aadáx̲ woogoot",
                                    "url": "aadáx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it left there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadáx̲ kawdiyaa",
                                    "url": "aadáx̲ kawdiyaa",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it disappeared from there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadáx̲ awsixáat'",
                                    "url": "aadáx̲ awsixáat'",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he dragged it away",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadáx̲ nagútch",
                                    "url": "aadáx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "perfective_habitual",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it leaves there (every time)",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Aadáx̲ nagú!",
                                    "url": "aadáx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Leave there!",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadáx̲ gug̲agóot",
                                    "url": "aadáx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is going to leave there",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aadáx̲ yaa nagút",
                                    "url": "aadáx̲ woogoot",
                                    "tags": [
                                        "Verb",
                                        "progressive_imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is leaving there",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲waagoodí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_139",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I want to come.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "toowáa",
                            [],
                            []
                        ],
                        [
                            "sigóo",
                            [
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "at",
                            [
                                {
                                    "tlingit": "at shí",
                                    "url": "at shí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "music, singing, song",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at shí",
                                    "url": "at wooshee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he sings; s/he is singing",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at x̲á",
                                    "url": "at uwax̲áa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he eats; s/he is eating",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at gutú",
                                    "url": "at gutú",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "woods; bush; brush, underbrush",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at katé",
                                    "url": "at katé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bullet",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daná",
                                    "url": "at wudináa",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he drinks, is drinking",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "at daayí",
                                    "url": "at daayí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "birch",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "x̲waagoodí.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_140",
                        "J",
                        "L"
                    ]
                ]
            }
        ],
        "subtitles": {}
    },
    "7": {
        "title": "Introducing yourself",
        "content": [
            {
                "type": "subtitle",
                "english": "Names"
            },
            {
                "type": "dia",
                "english": "How are you called?",
                "tlingit": [
                    [
                        [
                            "Waa",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iduwasáakw?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_24",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I am called Koodéik’",
                "tlingit": [
                    [
                        [
                            "Koodéik’",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "add note (you might also hear yoo duwasaak'w or yei duwasaak'w variation, both are correct in this phrase)"
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_25",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "You are called Koodéik’, right?",
                "tlingit": [
                    [
                        [
                            "Koodéik’",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iduwasáakw",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kwshé?",
                            [
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "Eh?; I wonder",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshéi",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "maybe; I'm not sure; [expression of uncertainly]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_26",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "You are called Yaandu.ein, right?",
                "tlingit": [
                    [
                        [
                            "Yaandu.ein",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iduwasáakw",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kwshé?",
                            [
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "Eh?; I wonder",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshéi",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "maybe; I'm not sure; [expression of uncertainly]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_27",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "How about you?",
                "tlingit": [
                    [
                        [
                            "Wa.é",
                            [
                                {
                                    "tlingit": "wa.é",
                                    "url": "wa.é",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (singular) [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲u.aa?",
                            [
                                {
                                    "tlingit": "k̲u.aa",
                                    "url": "k̲u.aa",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "however",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_144",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Me though,  [introduction]",
                "tlingit": [
                    [
                        [
                            "X̲át",
                            [
                                {
                                    "tlingit": "x̲át",
                                    "url": "x̲át",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "I [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲u.aa,",
                            [
                                {
                                    "tlingit": "k̲u.aa",
                                    "url": "k̲u.aa",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "however",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "[introduction]",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_145",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "[name] ones often call me.",
                "tlingit": [
                    [
                        [
                            "[name]",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    "My name is _____"
                ],
                "ids": [
                    [
                        "d_146",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I am [name].",
                "tlingit": [
                    [
                        [
                            "[name]",
                            [],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at.",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_147",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "[name] ones often call me in Tlingit.",
                "tlingit": [
                    [
                        [
                            "[name]",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲'éináx̲.",
                            [
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "perlative"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'éináx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_148",
                        "N",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In English though [name] is my name.",
                "tlingit": [
                    [
                        [
                            "Dleit k̲áa",
                            [
                                {
                                    "tlingit": "dleit k̲áa",
                                    "url": "dleit k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "White, European, Caucasian (man or person)",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "snow",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "dleit",
                                    "url": "dleit2",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "white",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "k̲áa",
                                    "url": "k̲áa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "man; male; person, people",
                                    "exactMatch": true
                                }
                            ]
                        ],
                        [
                            "x̲'éináx̲",
                            [
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'é",
                                    "tags": [
                                        "RelationalNoun",
                                        "perlative"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a x̲'éináx̲",
                                    "url": "a x̲'éináx̲",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "through its mouth",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "k̲u.aa",
                            [
                                {
                                    "tlingit": "k̲u.aa",
                                    "url": "k̲u.aa",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "however",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "[name]",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(you might hear some people say ku.aas)"
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_149",
                        "J",
                        "R"
                    ]
                ]
            },
            {
                "type": "subtitle",
                "english": "Moiety"
            },
            {
                "type": "dia",
                "english": "I am raven moiety.",
                "tlingit": [
                    [
                        [
                            "Yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    "Moiety"
                ],
                "ids": [
                    [
                        "d_150",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I am eagle moiety.",
                "tlingit": [
                    [
                        [
                            "Ch’áak’",
                            [],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_151",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I am Ch'áak' - that's it.",
                "tlingit": [
                    [
                        [
                            "Ch'áak'x̲",
                            [],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "sometimes they forget"
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_152",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I am Yéil - that's it.",
                "tlingit": [
                    [
                        [
                            "Yéilx̲",
                            [],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "áwé.",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "if your dad's a raven, but mom is not tlingit, you would be an eagle"
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_153",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "subtitle",
                "english": "Mother's Clan"
            },
            {
                "type": "dia",
                "english": "I am [clan].",
                "tlingit": [
                    [
                        [
                            "[clan]",
                            [],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at.",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    "Mother's Clan"
                ],
                "ids": [
                    [
                        "d_154",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "My mother [clan].",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tláa",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "[clan]",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_155",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "[clan] is",
                "tlingit": [
                    [
                        [
                            "[clan]",
                            [],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(can be used if following 1st person phrase)"
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_156",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "[clan] we are",
                "tlingit": [
                    [
                        [
                            "[clan]",
                            [],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "uháan.",
                            [
                                {
                                    "tlingit": "uháan",
                                    "url": "uháan",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "we [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_157",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "From [clan house] House.",
                "tlingit": [
                    [
                        [
                            "[clan",
                            [],
                            []
                        ],
                        [
                            "house]hít",
                            [],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_158",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "From [clan house] House.",
                "tlingit": [
                    [
                        [
                            "[clan",
                            [],
                            []
                        ],
                        [
                            "house]hít-dáx̲.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_159",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "subtitle",
                "english": "Father's Clan"
            },
            {
                "type": "dia",
                "english": "Child of [clan].",
                "tlingit": [
                    [
                        [
                            "[clan]",
                            [],
                            []
                        ],
                        [
                            "yádi",
                            [
                                {
                                    "tlingit": "du yádi",
                                    "url": "du yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her child",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "aas yádi",
                                    "url": "aas yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "sapling",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tíx' yádi",
                                    "url": "tíx' yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "string",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "dóosh yádi",
                                    "url": "dóosh yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "kitten",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "x̲áat yádi",
                                    "url": "x̲áat yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "whitefish; baby fish; tiny fish",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tlék̲w yádi",
                                    "url": "tlék̲w yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raspberry",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "gawdáan yádi",
                                    "url": "gawdáan yádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "colt",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    "Father's Clan"
                ],
                "ids": [
                    [
                        "d_160",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "My father [clan].",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "[clan]",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_161",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "subtitle",
                "english": "Grandparent/Grandchildren/Claimed Family"
            },
            {
                "type": "dia",
                "english": "My grandparent [clan]",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "léelk'w",
                            [
                                {
                                    "tlingit": "Léelk'w!",
                                    "url": "Léelk'w!",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Grandmother!; Grandfather!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "[clan]",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    "Grandparent/Grandchildren/Claimed Family"
                ],
                "ids": [
                    [
                        "d_162",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "My grandparent's people [clan]",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "léelk'w",
                            [
                                {
                                    "tlingit": "Léelk'w!",
                                    "url": "Léelk'w!",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Grandmother!; Grandfather!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "has",
                            [
                                {
                                    "tlingit": "has",
                                    "url": "has",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "them [object]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "has",
                                    "url": "has2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "they [subject]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "[clan]",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_163",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "One of my grandparents [clan]",
                "tlingit": [
                    [
                        [
                            "Tléix'aa",
                            [],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "léelk'w",
                            [
                                {
                                    "tlingit": "Léelk'w!",
                                    "url": "Léelk'w!",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Grandmother!; Grandfather!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "[clan]",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(individual person)"
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_164",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "[clan] grandchild; I am a grandchild of [clan]",
                "tlingit": [
                    [
                        [
                            "[clan]",
                            [],
                            []
                        ],
                        [
                            "dachx̲ánk'.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "(son's wife's clan)? or (mother's father clan)?"
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_165",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "mom's dad [clan]",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tláa",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "(du)",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "[clan]",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_166",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "dad's dad [clan] (same moiety as you)",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "(du)",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "[clan]",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_167",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "dad's mom [clan] (same moiety as your dad)",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "éesh",
                            [
                                {
                                    "tlingit": "du éesh",
                                    "url": "du éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her father",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "náaw éesh",
                                    "url": "náaw éesh",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "alcoholic",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "(du)",
                            [
                                {
                                    "tlingit": "du",
                                    "url": "du",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "his/her [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tláa",
                            [
                                {
                                    "tlingit": "du tláa",
                                    "url": "du tláa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "his/her mother",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "[clan]",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_168",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "subtitle",
                "english": "Raven Clans"
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "naa",
                            [
                                {
                                    "tlingit": "naa",
                                    "url": "naa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nation; moiety; clan; band of people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_190",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Laayaneidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Name for all Raven clans"
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_250",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "G̲aanax̲.ádi",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_191",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "K̲oosk'eidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_192",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Deisheetaan",
                            [
                                {
                                    "tlingit": "Deisheetaan",
                                    "url": "Deisheetaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Deisheetaan, locally called \"Beaver\"; a clan of the Raven moiety whose principal crest is the Beaver",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_193",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Kook̲hittaan",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_194",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "L'uknax̲.ádi",
                            [
                                {
                                    "tlingit": "L'uknax̲.ádi",
                                    "url": "L'uknax̲.ádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "L'uknax̲.ádi, locally called \"Coho\"; a clan of the Raven moiety whose principal crest is the Coho",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_195",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Ishkeetaan",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_196",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Lukaax̲.ádi",
                            [
                                {
                                    "tlingit": "Lukaax̲.ádi",
                                    "url": "Lukaax̲.ádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Lukaax̲.ádi, locally called \"Sockeye\"; a clan of the Raven moiety whose principal crest is the Sockeye",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_197",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "L'eeneidí",
                            [
                                {
                                    "tlingit": "L'eeneidí",
                                    "url": "L'eeneidí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "L'eeneidí, locally called \"Dog Salmon\"; a clan of the Raven moiety whose principal crest is the Dog Salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_198",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Kiks.ádi",
                            [
                                {
                                    "tlingit": "Kiks.ádi",
                                    "url": "Kiks.ádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Kiks.ádi, locally called \"Frog\"; a clan of the Raven moiety whose principal crest is the Frog",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_199",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "G̲aanax̲teidí",
                            [
                                {
                                    "tlingit": "G̲aanax̲teidí",
                                    "url": "G̲aanax̲teidí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "G̲aanax̲teidí, locally called \"Frog\"; a clan of the Raven moiety whose principal crest is the Frog",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_200",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "X'at'ka.aayí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_201",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Taakw.aaneidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_202",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Teeyneidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_203",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "T'ak̲deintaan",
                            [
                                {
                                    "tlingit": "T'ak̲deintaan",
                                    "url": "T'ak̲deintaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "T'ak̲deintaan, locally called \"Seagull\"; a clan of the Raven moiety whose principal crest is the Seagull",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_204",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "K̲aach.ádi",
                            [
                                {
                                    "tlingit": "K̲aach.ádi",
                                    "url": "K̲aach.ádi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "K̲aach.ádi, locally called \"Sockeye\"; a clan of the Raven moiety whose principal crest is the Sockeye",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_205",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Kwáashk'i",
                            [],
                            []
                        ],
                        [
                            "K̲wáan",
                            [
                                {
                                    "tlingit": "a k̲wáan",
                                    "url": "a k̲wáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person or people from that place",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Áak'w K̲wáan",
                                    "url": "Áak'w K̲wáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "people of Auke Bay, southern Lynn Canal, Juneau area, and northern Admiralty Island",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Kooya K̲wáan",
                                    "url": "Kooya K̲wáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Kuiu Island people",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Hinyaa K̲wáan",
                                    "url": "Hinyaa K̲wáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "people of Klawock",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Sanyaa K̲wáan",
                                    "url": "Sanyaa K̲wáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "people of Cape Fox, Saxman",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Sheet'ká K̲wáan",
                                    "url": "Shee At'iká K̲wáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "people of Sitka",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "Kwaashk'i K̲wáan",
                                    "url": "Kwaashk'i K̲wáan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Kwaashk'i K̲wáan, locally called \"Humpback Salmon\"; a clan of the Raven moiety whose principal crest is the Humpback Salmon",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_206",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Took̲a.ádi",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_207",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Naach'ooneidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_208",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "K̲ak'weidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_209",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Ishkahittaan",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_210",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Watineidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_211",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Noowshaka.aayí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_212",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Suk̲teeneidí",
                            [
                                {
                                    "tlingit": "Suk̲teeneidí",
                                    "url": "Suk̲teeneidí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Suk̲teeneidí, locally called \"Dog Salmon\"; a clan of the Raven moiety whose principal crest is the Dog Salmon",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_213",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Teeyineidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_214",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "X̲'alchaneidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_215",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Kooyu.eidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_216",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Kaasx̲'agweidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_217",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Taalk̲weidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_218",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Teeyhíttaan",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_219",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "K’óoxeeneidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_220",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Tuk̲yeidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_221",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Taneidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_252",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "subtitle",
                "english": "Eagle Clans"
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Ch'aak'",
                            [],
                            []
                        ],
                        [
                            "naa",
                            [
                                {
                                    "tlingit": "naa",
                                    "url": "naa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nation; moiety; clan; band of people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_222",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "G̲ooch",
                            [
                                {
                                    "tlingit": "g̲ooch",
                                    "url": "g̲ooch",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "wolf",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "naa",
                            [
                                {
                                    "tlingit": "naa",
                                    "url": "naa",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "nation; moiety; clan; band of people",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_223",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Tléix'",
                            [
                                {
                                    "tlingit": "tléix'",
                                    "url": "tléix'",
                                    "tags": [
                                        "Numeral"
                                    ],
                                    "english": "one",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "shangukeidí",
                            [
                                {
                                    "tlingit": "Shangukeidí",
                                    "url": "Shangukeidí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Shangukeidí, locally known as \"Thunderbird\"; a clan of the Eagle moiety whose principal crest is the Thunderbird",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "Old way of calling all Eagle/Wolf clans"
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_251",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Kaagwaantaan",
                            [
                                {
                                    "tlingit": "Kaagwaantaan",
                                    "url": "Kaagwaantaan",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Kaagwaantaan, locally called \"Wolf\"; a clan of the Eagle moiety whose principal crest is the Wolf",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_224",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Dak̲l'aweidí",
                            [
                                {
                                    "tlingit": "Dak̲l'aweidí",
                                    "url": "Dak̲l'aweidí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Dak̲l'aweidí, locally called \"Killer Whale\"; a clan of the Eagle moiety whose principal crest is the Killer Whale",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_225",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Wooshkeetaan",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_226",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Teik̲weidí",
                            [
                                {
                                    "tlingit": "Teik̲weidí",
                                    "url": "Teik̲weidí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Teik̲weidí, locally called \"Brown Bear\"; a clan of the Eagle moiety whose principal crest is the Brown Bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_227",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Yanyeidí",
                            [
                                {
                                    "tlingit": "Yanyeidí",
                                    "url": "Yanyeidí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Yanyeidí, locally known as \"Bear\"; a clan of the Eagle moiety whose principal crest is the Brown Bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_228",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Shangukeidí",
                            [
                                {
                                    "tlingit": "Shangukeidí",
                                    "url": "Shangukeidí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Shangukeidí, locally known as \"Thunderbird\"; a clan of the Eagle moiety whose principal crest is the Thunderbird",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_229",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Dag̲isdinaa",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_230",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Chookaneidí",
                            [
                                {
                                    "tlingit": "Chookaneidí",
                                    "url": "Chookaneidí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Chookaneidí; a clan of the Eagle moiety whose principal crests are the Porpoise and Brown Bear",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_231",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "K̲aax̲'oos.hittaan",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_232",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Naasteidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_233",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Lk̲uweidi",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_234",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "S'eet'k̲weidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_235",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "K̲ookhittaan",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_236",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "X̲’ax’ahittaan",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_237",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Tsaagweidí",
                            [
                                {
                                    "tlingit": "Tsaagweidí",
                                    "url": "Tsaagweidí",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tsaagweidí; a clan of the Eagle moiety whose principal crests are the Seal and Killerwhale",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_238",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Nees.ádi",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_239",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Was'ineidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_240",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Tsaateeneidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_241",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Naanyaa.aayí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_242",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Sik'nax̲.ádi",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_243",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "X̲ook'eidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_244",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Neix̲.ádi",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_245",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Jishk̲weidí",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_246",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Kayaashkeiditaan",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_247",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "",
                "tlingit": [
                    [
                        [
                            "Kadak̲w.ádi",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_248",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "subtitle",
                "english": "I am from [place]. I live in [place]."
            },
            {
                "type": "dia",
                "english": "S/he lives in Juneau.",
                "tlingit": [
                    [
                        [
                            "Juneau-x’",
                            [],
                            []
                        ],
                        [
                            "yéi yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": true
                                }
                            ],
                            [
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_93",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I live in Juneau.",
                "tlingit": [
                    [
                        [
                            "Juneau-x’",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_94",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I live in Sitka.",
                "tlingit": [
                    [
                        [
                            "Sheet'ká-x’",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_95",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I live in Kake.",
                "tlingit": [
                    [
                        [
                            "K̲éex̲'-x’",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_96",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I live in Haines.",
                "tlingit": [
                    [
                        [
                            "Deishú-x’",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_97",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "break"
            },
            {
                "type": "dia",
                "english": "From [place], I am.",
                "tlingit": [
                    [
                        [
                            "[place]-dáx̲",
                            [],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at.",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    "Hometown"
                ],
                "ids": [
                    [
                        "d_169",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In [place], I'm living.",
                "tlingit": [
                    [
                        [
                            "[place]x'",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yatee.",
                            [
                                {
                                    "tlingit": "yéi yatee",
                                    "url": "yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is that way",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a yáx̲ yatee",
                                    "url": "a yáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is like it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "a eetéenáx̲ yatee",
                                    "url": "a eetéenáx̲ wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it needs it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "tleiyéi yéi yatee",
                                    "url": "tleiyéi yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is still",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du x̲ánx' yéi yatee",
                                    "url": "du x̲ánx' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is with him/her",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du wak̲shiyeex' yéi yatee",
                                    "url": "du wak̲shiyeex' yéi wootee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is in front of his/her eyes",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    "I am living in"
                ],
                "ids": [
                    [
                        "d_170",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In [place], I was born.",
                "tlingit": [
                    [
                        [
                            "[place]x'",
                            [],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲oowdzitee.",
                            [
                                {
                                    "tlingit": "k̲oowdzitee",
                                    "url": "k̲oowdzitee",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "it existed; s/he was born",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    "I was born in"
                ],
                "ids": [
                    [
                        "d_171",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In [place], I was born.",
                "tlingit": [
                    [
                        [
                            "[place]x'",
                            [],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲ux̲wdzitee.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_172",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "From [place], I was born.",
                "tlingit": [
                    [
                        [
                            "[place]-dáx̲",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "k̲uwdzitee.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_173",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "subtitle",
                "english": "Places"
            },
            {
                "type": "dia",
                "english": "In Kake",
                "tlingit": [
                    [
                        [
                            "K̲'éex̲'x'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_174",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "From Kake",
                "tlingit": [
                    [
                        [
                            "K̲éex̲'dáx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_175",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In Juneau (Gold Creek)",
                "tlingit": [
                    [
                        [
                            "Dzántik'i",
                            [
                                {
                                    "tlingit": "Dzántik'i Héeni",
                                    "url": "Dzántik'i Héeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Juneau; Gold Creek (in Juneau)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "Héenix'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_176",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "From Juneau (Gold Creek)",
                "tlingit": [
                    [
                        [
                            "Dzántik'i",
                            [
                                {
                                    "tlingit": "Dzántik'i Héeni",
                                    "url": "Dzántik'i Héeni",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Juneau; Gold Creek (in Juneau)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "Héenidáx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_177",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In Juneau",
                "tlingit": [
                    [
                        [
                            "Juneau-x'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_178",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "From Juneau",
                "tlingit": [
                    [
                        [
                            "Juneau-dáx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_179",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In Sitka",
                "tlingit": [
                    [
                        [
                            "Sheet'káx'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_180",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "From Sitka",
                "tlingit": [
                    [
                        [
                            "Sheet'kádáx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_181",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In Haines",
                "tlingit": [
                    [
                        [
                            "Deishúx'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_182",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "From Haines",
                "tlingit": [
                    [
                        [
                            "Deishúdáx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_183",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In Ketchikan",
                "tlingit": [
                    [
                        [
                            "Kichx̲áanx'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_184",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "From Ketchikan",
                "tlingit": [
                    [
                        [
                            "Kichx̲áandáx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_185",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In Anchorage",
                "tlingit": [
                    [
                        [
                            "Áankichx'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_186",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "From Anchorage",
                "tlingit": [
                    [
                        [
                            "Áankichdáx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_187",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "In Yakutat",
                "tlingit": [
                    [
                        [
                            "Yaakwdáatx'",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_188",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "From Yakutat",
                "tlingit": [
                    [
                        [
                            "Yaakwdáatdáx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_189",
                        "J",
                        "L"
                    ]
                ]
            }
        ],
        "subtitles": {}
    },
    "8": {
        "title": "Dialogue - Introduction between two people",
        "content": [
            {
                "type": "dia",
                "english": "I want to speak with you in the Tlingit language.",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’éináx̲",
                            [],
                            []
                        ],
                        [
                            "ee",
                            [
                                {
                                    "tlingit": "du ee~",
                                    "url": "du ee~",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "him, her",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "een",
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yóo",
                            [
                                {
                                    "tlingit": "yóo",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóo",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (distant), yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’ax̲ataaní",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Go ahead.",
                "tlingit": [
                    [
                        [
                            "Góok",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "[name] that is how I'm called in Tlingit. [moiety] I am, [clan] is me.",
                "tlingit": [
                    [
                        [
                            "[name]",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’éináx̲",
                            [],
                            []
                        ],
                        [
                            "[moiety]",
                            [],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "[clan]",
                            [],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲át",
                            [
                                {
                                    "tlingit": "x̲át",
                                    "url": "x̲át",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "I [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Thank you.",
                "tlingit": [
                    [
                        [
                            "Gunalchéesh",
                            [
                                {
                                    "tlingit": "Gunalchéesh!",
                                    "url": "Gunalchéesh!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Thank you!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Okay.",
                "tlingit": [
                    [
                        [
                            "Aaá",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Paul, how does one call you in Tlingit?",
                "tlingit": [
                    [
                        [
                            "Paul,",
                            [],
                            []
                        ],
                        [
                            "waa",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iduwasáakw",
                            [],
                            []
                        ],
                        [
                            "lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’éináx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "[name] that is how I'm called in Tlingit.",
                "tlingit": [
                    [
                        [
                            "[name]",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw",
                            [],
                            []
                        ],
                        [
                            "lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’éináx̲",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "[name] that is how you are called, right?",
                "tlingit": [
                    [
                        [
                            "[name]",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iduwasáakw",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kwshé",
                            [
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "Eh?; I wonder",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "kwshé",
                                    "url": "kwshéi",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "maybe; I'm not sure; [expression of uncertainly]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Yes.",
                "tlingit": [
                    [
                        [
                            "Aaá",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Which moiety/clan are you?",
                "tlingit": [
                    [
                        [
                            "Daak̲w.aa",
                            [
                                {
                                    "tlingit": "daak̲w.aa sá",
                                    "url": "daak̲w.aa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "which (one); some (certain one)",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "isitee",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "[moiety] I am, [clan] is me.",
                "tlingit": [
                    [
                        [
                            "[moiety]",
                            [],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "[clan]",
                            [],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲át",
                            [
                                {
                                    "tlingit": "x̲át",
                                    "url": "x̲át",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "I [independent]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Thank you, give me your hand.",
                "tlingit": [
                    [
                        [
                            "Gunalchéesh",
                            [
                                {
                                    "tlingit": "Gunalchéesh!",
                                    "url": "Gunalchéesh!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Thank you!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "haandéi",
                            [
                                {
                                    "tlingit": "haandéi",
                                    "url": "haandé",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hand it here, bring it here",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ijín",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Thank you go ahead.",
                "tlingit": [
                    [
                        [
                            "Gunalchéesh",
                            [
                                {
                                    "tlingit": "Gunalchéesh!",
                                    "url": "Gunalchéesh!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Thank you!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "góok",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Thank you.",
                "tlingit": [
                    [
                        [
                            "Gunalchéesh",
                            [
                                {
                                    "tlingit": "Gunalchéesh!",
                                    "url": "Gunalchéesh!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Thank you!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            }
        ],
        "subtitles": {}
    },
    "9": {
        "title": "Dialogue - Informal exchange between young adults",
        "content": [
            {
                "type": "dia",
                "english": "What's his/her/its name?",
                "tlingit": [
                    [
                        [
                            "Waa",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Which one?",
                "tlingit": [
                    [
                        [
                            "Daak̲w",
                            [],
                            []
                        ],
                        [
                            "aa sá?",
                            [
                                {
                                    "tlingit": "ch'a aa sá",
                                    "url": "ch'a aa sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "ch'a aa sá",
                                    "url": "ch'a aadóo sá",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "anyone, anybody; whoever",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ]
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "That one.",
                "tlingit": [
                    [
                        [
                            "Wéit’aa",
                            [],
                            []
                        ],
                        [
                            "x̲á.",
                            [
                                {
                                    "tlingit": "x̲á",
                                    "url": "x̲á",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "you see",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "X̲á!",
                                    "url": "aawax̲áa2",
                                    "tags": [
                                        "Verb",
                                        "imperative",
                                        "2Sg"
                                    ],
                                    "english": "Eat it!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Ohh, I don't know. He looks Tlingit / Is he Tlingit?",
                "tlingit": [
                    [
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲wasikóo.",
                            [],
                            []
                        ],
                        [
                            "Lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ák.yá?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Maybe. I don't know.",
                "tlingit": [
                    [
                        [
                            "Gwál",
                            [
                                {
                                    "tlingit": "gwál",
                                    "url": "gwál",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "perhaps",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yé.",
                            [
                                {
                                    "tlingit": "yé",
                                    "url": "yé",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "place; way",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "Tlél",
                            [
                                {
                                    "tlingit": "tlél",
                                    "url": "tléil",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "no, none, not",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲wasikóo.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "text",
                "english": "a few moments later"
            },
            {
                "type": "dia",
                "english": "Hi, what's your name?",
                "tlingit": [
                    [
                        [
                            "Yak'éi,",
                            [
                                {
                                    "tlingit": "yak'éi",
                                    "url": "wook'éi",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "he/she/it is good",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "waa",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iduwasáakw?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    "a few moments later"
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "George.",
                "tlingit": [
                    [
                        [
                            "George.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "George, what's your name in Tlingit?",
                "tlingit": [
                    [
                        [
                            "George,",
                            [],
                            []
                        ],
                        [
                            "waa",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iduwasáakw",
                            [],
                            []
                        ],
                        [
                            "Lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’eináx̲?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "My name is [name].",
                "tlingit": [
                    [
                        [
                            "[Name]",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Give me your hand (to shake).",
                "tlingit": [
                    [
                        [
                            "Haandéi",
                            [
                                {
                                    "tlingit": "haandéi",
                                    "url": "haandé",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hand it here, bring it here",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ijín",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "What's your name?",
                "tlingit": [
                    [
                        [
                            "Waa",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "iduwasáakw?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "My name is [name].",
                "tlingit": [
                    [
                        [
                            "[Name]",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "My younger brother here. His name is [name].",
                "tlingit": [
                    [
                        [
                            "Ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "kéek'",
                            [
                                {
                                    "tlingit": "du kéek'",
                                    "url": "du kéek'",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her younger sister; his younger brother; cousin",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ],
                        [
                            "áwé",
                            [
                                {
                                    "tlingit": "áwé",
                                    "url": "áwé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "that (at hand) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yáadu.",
                            [
                                {
                                    "tlingit": "yáadu",
                                    "url": "yá",
                                    "tags": [
                                        "Demonstrative",
                                        "locativepr"
                                    ],
                                    "english": "here is; here are",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "[Name]",
                            [],
                            []
                        ],
                        [
                            "yéi",
                            [
                                {
                                    "tlingit": "yéi",
                                    "url": "yei",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "down; out of boat, vehicle",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yéi",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "duwasáakw.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Give me your hand (to shake).",
                "tlingit": [
                    [
                        [
                            "Haandéi",
                            [
                                {
                                    "tlingit": "haandéi",
                                    "url": "haandé",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "hand it here, bring it here",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ijín.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    "is this awkward?"
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Go ahead.",
                "tlingit": [
                    [
                        [
                            "Góok.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    "does the boy respond?"
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Which clan are you in?",
                "tlingit": [
                    [
                        [
                            "Daak̲w",
                            [],
                            []
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "sá",
                            [
                                {
                                    "tlingit": "sá",
                                    "url": "sá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative - marks WH-questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "isitee?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    "Option 1"
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "I am Ch’áak’ clan.",
                "tlingit": [
                    [
                        [
                            "Ch’áak’",
                            [],
                            []
                        ],
                        [
                            "áyá",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲at.",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ],
                    [
                        [
                            "Ch’áak’",
                            [],
                            []
                        ],
                        [
                            "naa-x",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    "",
                    ""
                ],
                "extraExtra": [
                    "",
                    "alternate"
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ],
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Are you ch'aak clan?",
                "tlingit": [
                    [
                        [
                            "Ch’áak’",
                            [],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "gé",
                            [
                                {
                                    "tlingit": "gé",
                                    "url": "gé",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "[interrogative marks yes-no questions]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "isitee?",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    "Option 2"
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "No, I am yéil naa.",
                "tlingit": [
                    [
                        [
                            "Tléik’,",
                            [],
                            []
                        ],
                        [
                            "Yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "x̲at",
                            [
                                {
                                    "tlingit": "x̲at",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "sitee.",
                            [
                                {
                                    "tlingit": "(noun)-x̲ sitee",
                                    "url": "(noun)-x̲ wusitee",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is a (noun)",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Thank you",
                "tlingit": [
                    [
                        [
                            "Gunalchéesh.",
                            [
                                {
                                    "tlingit": "Gunalchéesh!",
                                    "url": "Gunalchéesh!",
                                    "tags": [
                                        "Interjection"
                                    ],
                                    "english": "Thank you!",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Ok (it's done)",
                "tlingit": [
                    [
                        [
                            "Aaá",
                            [
                                {
                                    "tlingit": "aaá",
                                    "url": "aaá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "yes",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    "a few moments later"
                ],
                "ids": [
                    [
                        "",
                        "",
                        "R"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Woooow! Amazing, he is Ch'aak clan. <giggle> Your future husband.",
                "tlingit": [
                    [
                        [
                            "Haaw,",
                            [
                                {
                                    "tlingit": "haaw",
                                    "url": "haaw",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "bough, branch with needles on it, especially of hemlock",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "likoodzee,",
                            [],
                            []
                        ],
                        [
                            "Ch’áak’",
                            [],
                            []
                        ],
                        [
                            "áyá.",
                            [
                                {
                                    "tlingit": "áyá",
                                    "url": "áyá",
                                    "tags": [
                                        "Particle"
                                    ],
                                    "english": "this (right here) [focus]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "I",
                            [
                                {
                                    "tlingit": "i",
                                    "url": "i",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (singular) [possessive]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲úx̲.",
                            [
                                {
                                    "tlingit": "du x̲úx̲",
                                    "url": "du x̲úx̲",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "her husband",
                                    "exactMatch": false
                                }
                            ],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "",
                        "",
                        "L"
                    ]
                ]
            }
        ],
        "subtitles": {}
    },
    "10": {
        "title": "Dialogue - Celebration Clan Introductions",
        "content": [
            {
                "type": "dia",
                "english": "All of you who are eagles, I want to speak with you all in the Tlingit language.",
                "tlingit": [
                    [
                        [
                            "Ldakát",
                            [
                                {
                                    "tlingit": "ldakát",
                                    "url": "ldakát",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "all; every",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ch’aak’",
                            [],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "siteeyee",
                            [],
                            []
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’éináx̲",
                            [],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "een",
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yóo",
                            [
                                {
                                    "tlingit": "yóo",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóo",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (distant), yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’ax̲ataani.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_142",
                        "N",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "All of you who are ravens, I want to speak with you all in the Tlingit language.",
                "tlingit": [
                    [
                        [
                            "Ldakát",
                            [
                                {
                                    "tlingit": "ldakát",
                                    "url": "ldakát",
                                    "tags": [
                                        "Quantifier"
                                    ],
                                    "english": "all; every",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yéil",
                            [
                                {
                                    "tlingit": "yéil",
                                    "url": "yéil",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "raven",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "naa-x̲",
                            [],
                            []
                        ],
                        [
                            "siteeyee",
                            [],
                            []
                        ],
                        [
                            "aa",
                            [
                                {
                                    "tlingit": "aa",
                                    "url": "aa",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "one, one of [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "ax̲",
                            [
                                {
                                    "tlingit": "ax̲",
                                    "url": "ax̲",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "my [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "ax̲",
                                    "url": "x̲at",
                                    "tags": [
                                        "Pronoun",
                                        "An"
                                    ],
                                    "english": "me [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "tuwáa sigóo",
                            [
                                {
                                    "tlingit": "du tuwáa sigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wants it",
                                    "exactMatch": false
                                }
                            ],
                            [
                                {
                                    "tlingit": "du tuwáa wsigóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "perfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he wanted it",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du toowú sigóo",
                                    "url": "du toowú wsigóo",
                                    "tags": [
                                        "Verb",
                                        "imperfective",
                                        "3Sg"
                                    ],
                                    "english": "s/he is happy",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "sh tuwáa kasyéiyi",
                                    "url": "sh tuwáa kasyéiyi",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "tourist",
                                    "exactMatch": false
                                },
                                {
                                    "tlingit": "du tuwáa kei gux̲sagóo",
                                    "url": "du tuwáa wsigóo",
                                    "tags": [
                                        "Verb",
                                        "future",
                                        "3Sg"
                                    ],
                                    "english": "s/he will want it",
                                    "exactMatch": false
                                }
                            ]
                        ],
                        [
                            "lingít",
                            [
                                {
                                    "tlingit": "lingít",
                                    "url": "lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "person",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "Lingít",
                                    "url": "Lingít",
                                    "tags": [
                                        "Noun"
                                    ],
                                    "english": "Tlingit",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’éináx̲",
                            [],
                            []
                        ],
                        [
                            "yee",
                            [
                                {
                                    "tlingit": "yee",
                                    "url": "yee",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "your (plural) [possessive]",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yee",
                                    "url": "yee2",
                                    "tags": [
                                        "Pronoun"
                                    ],
                                    "english": "you (plural) [object]",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "een",
                            [
                                {
                                    "tlingit": "een",
                                    "url": "een",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "teen",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "een",
                                    "url": "tin",
                                    "tags": [
                                        "Postposition"
                                    ],
                                    "english": "(along) with, by means of; as soon as",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "yóo",
                            [
                                {
                                    "tlingit": "yóo",
                                    "url": "yéi",
                                    "tags": [
                                        "Adverb"
                                    ],
                                    "english": "thus, specifically",
                                    "exactMatch": true
                                },
                                {
                                    "tlingit": "yóo",
                                    "url": "yú",
                                    "tags": [
                                        "Demonstrative"
                                    ],
                                    "english": "that (distant), yonder",
                                    "exactMatch": true
                                }
                            ],
                            []
                        ],
                        [
                            "x̲’ax̲ataani.",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_143",
                        "J",
                        "L"
                    ]
                ]
            },
            {
                "type": "dia",
                "english": "Go ahead",
                "tlingit": [
                    [
                        [
                            "Góok",
                            [],
                            []
                        ]
                    ]
                ],
                "extra": [
                    ""
                ],
                "extraExtra": [
                    ""
                ],
                "ids": [
                    [
                        "d_141",
                        "N",
                        "R"
                    ]
                ]
            }
        ],
        "subtitles": {}
    }
};