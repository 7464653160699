export const geoJSONMap = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                "id": "17",
                "name": "Tatlenx'ix̲oo",
                "translation": "Bestrewn with Big Rocks",
                "location": "Nakina Village site",
                "audio": "map-17.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -132.78247833252,
                    59.1740810423094,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "56",
                "name": "Naahéeni",
                "translation": "Tribe* Creek",
                "location": "Taku River, secondary channel",
                "audio": "map-56.mp3",
                "type": "water",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.71826293972254,
                    58.51841226164089
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "53",
                "name": "Aanx̲'atinyé",
                "translation": "Place Where the Opening (Mouth) of the Land Lies",
                "location": "Canyon Island",
                "audio": "map-53.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.67620092396785,
                    58.538316430711404
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "55",
                "name": "Shaa Tlein",
                "translation": "Big Mountain",
                "location": "Kluchman Mountain",
                "audio": "map-55.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.73899127529415,
                    58.56769258566672
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "52",
                "name": "K̲anaguk* Yík",
                "translation": "Inside Unknown*",
                "location": "Upper Taku River",
                "audio": "map-52.mp3",
                "type": "water",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.60571046510194,
                    58.59351639103957
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "15",
                "name": "Naak'ina.áa",
                "translation": "Naak'ina Village",
                "location": "Nakina Village area",
                "audio": "map-15.mp3",
                "audio2": "map-15_2.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -132.775955200195,
                    59.1890317673616,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "22",
                "name": "L'óox̲'u Héen",
                "translation": "Silty River",
                "location": "Sloko River",
                "audio": "map-22.mp3",
                "audio2": "map-22_2.mp3",
                "type": "river",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -133.66617599099828,
                        59.11068856908241
                    ],
                    [
                        -133.66288700353982,
                        59.10924713272365
                    ],
                    [
                        -133.6556672749727,
                        59.108876467862274
                    ],
                    [
                        -133.65350135640256,
                        59.10982371454111
                    ],
                    [
                        -133.65157609545122,
                        59.108917653044955
                    ],
                    [
                        -133.64740469672347,
                        59.11068856908241
                    ],
                    [
                        -133.64523877815333,
                        59.11274765873273
                    ],
                    [
                        -133.63729707672954,
                        59.113283001779735
                    ],
                    [
                        -133.63192238990734,
                        59.11468309021521
                    ],
                    [
                        -133.62237630435723,
                        59.11653606049228
                    ],
                    [
                        -133.61956863213663,
                        59.11571253051591
                    ],
                    [
                        -133.61732249436025,
                        59.11690664252464
                    ],
                    [
                        -133.6141137261082,
                        59.117112519700356
                    ],
                    [
                        -133.60905991611105,
                        59.1202828720092
                    ],
                    [
                        -133.60052329694278,
                        59.120439176844144
                    ],
                    [
                        -133.5980365015474,
                        59.12010980474895
                    ],
                    [
                        -133.59434641805754,
                        59.12150961428853
                    ],
                    [
                        -133.59442663726375,
                        59.122868198268435
                    ],
                    [
                        -133.58664537425256,
                        59.126285004678294
                    ],
                    [
                        -133.58455967488862,
                        59.13122436324983
                    ],
                    [
                        -133.58014761854207,
                        59.13250024844464
                    ],
                    [
                        -133.5805487145735,
                        59.133570309053994
                    ],
                    [
                        -133.57549490457654,
                        59.13801481831399
                    ],
                    [
                        -133.56874011630555,
                        59.139422289220335
                    ],
                    [
                        -133.56641375932276,
                        59.14267286649769
                    ],
                    [
                        -133.5668950745606,
                        59.144894602497345
                    ],
                    [
                        -133.56047753805652,
                        59.14830921187047
                    ],
                    [
                        -133.55775008504213,
                        59.14884399935082
                    ],
                    [
                        -133.55534350885313,
                        59.148350349665776
                    ],
                    [
                        -133.55093145250663,
                        59.1502837702636
                    ],
                    [
                        -133.5480435610796,
                        59.14917309518526
                    ],
                    [
                        -133.53769528346663,
                        59.15007809241311
                    ],
                    [
                        -133.53119752775615,
                        59.15077739206143
                    ],
                    [
                        -133.52429867601424,
                        59.15122987245499
                    ],
                    [
                        -133.5222931958567,
                        59.15402689117391
                    ],
                    [
                        -133.5184247751913,
                        59.15374508637703
                    ],
                    [
                        -133.51609841820854,
                        59.15576046003409
                    ],
                    [
                        -133.51377206122578,
                        59.15794022035428
                    ],
                    [
                        -133.5141731572574,
                        59.15876273532854
                    ],
                    [
                        -133.50695342869005,
                        59.16118903929191
                    ],
                    [
                        -133.50270181075615,
                        59.16246380800564
                    ],
                    [
                        -133.50125786504265,
                        59.16406748181424
                    ],
                    [
                        -133.49716668552134,
                        59.16468425941278
                    ],
                    [
                        -133.4953216437764,
                        59.16624671288591
                    ],
                    [
                        -133.4901073953666,
                        59.16637006143597
                    ],
                    [
                        -133.48754038076504,
                        59.167521293116806
                    ],
                    [
                        -133.48393051648134,
                        59.16735683382086
                    ],
                    [
                        -133.47695144553305,
                        59.1680557803673
                    ],
                    [
                        -133.46796689442718,
                        59.16883693901505
                    ],
                    [
                        -133.46371527649325,
                        59.16789132364167
                    ],
                    [
                        -133.45842080887726,
                        59.16863137267987
                    ],
                    [
                        -133.45346239739285,
                        59.16776241418441
                    ],
                    [
                        -133.45009319072824,
                        59.168255783849304
                    ],
                    [
                        -133.4458415727941,
                        59.167104576892825
                    ],
                    [
                        -133.4403866667657,
                        59.16735126736015
                    ],
                    [
                        -133.43043948518417,
                        59.164843164692
                    ],
                    [
                        -133.43268562296052,
                        59.162910566688566
                    ],
                    [
                        -133.43140211565984,
                        59.16015539777027
                    ],
                    [
                        -133.42859444343924,
                        59.15863379146339
                    ],
                    [
                        -133.4228186605854,
                        59.15809915696437
                    ],
                    [
                        -133.4188077002703,
                        59.155878278073686
                    ],
                    [
                        -133.41784506979462,
                        59.1538217801876
                    ],
                    [
                        -133.41375389027334,
                        59.154603263944665
                    ],
                    [
                        -133.41255060217884,
                        59.152670087381836
                    ],
                    [
                        -133.40813584034095,
                        59.15181772246646
                    ],
                    [
                        -133.4061303601834,
                        59.150131203198896
                    ],
                    [
                        -133.39883041240986,
                        59.14836232540182
                    ],
                    [
                        -133.39626339780827,
                        59.14651107631431
                    ],
                    [
                        -133.39145024543012,
                        59.14667563584328
                    ],
                    [
                        -133.38784038114645,
                        59.1444951578728
                    ],
                    [
                        -133.38495248971964,
                        59.142931710810444
                    ],
                    [
                        -133.37861517242175,
                        59.13996919423022
                    ],
                    [
                        -133.3697910597285,
                        59.136924118370615
                    ],
                    [
                        -133.36765604571488,
                        59.13498808864506
                    ],
                    [
                        -133.369260429841,
                        59.13309496011766
                    ],
                    [
                        -133.36589122317636,
                        59.131819097079216
                    ],
                    [
                        -133.36484837349428,
                        59.13004927287986
                    ],
                    [
                        -133.35939346746588,
                        59.130090432621074
                    ],
                    [
                        -133.35610448000742,
                        59.12745610947502
                    ],
                    [
                        -133.35217373889853,
                        59.12790889818484
                    ],
                    [
                        -133.350168258741,
                        59.12613887192339
                    ],
                    [
                        -133.34804363758454,
                        59.122547995088325
                    ],
                    [
                        -133.34363158123801,
                        59.122136304814205
                    ],
                    [
                        -133.33889864806605,
                        59.11904847006994
                    ],
                    [
                        -133.34034259377958,
                        59.11703093440315
                    ],
                    [
                        -133.33649207187707,
                        59.11558976476991
                    ],
                    [
                        -133.3327217691808,
                        59.11171889491351
                    ],
                    [
                        -133.33232067314935,
                        59.10755928153142
                    ],
                    [
                        -133.33114400579043,
                        59.10286703277009
                    ],
                    [
                        -133.32697260706271,
                        59.099365497673745
                    ],
                    [
                        -133.32464625007995,
                        59.099283104306636
                    ],
                    [
                        -133.32095616659007,
                        59.094091923081294
                    ],
                    [
                        -133.3199935361144,
                        59.09075432019873
                    ],
                    [
                        -133.31501994532363,
                        59.085603061147054
                    ],
                    [
                        -133.31645654714177,
                        59.08244371593361
                    ],
                    [
                        -133.31244558682667,
                        59.08104231063521
                    ],
                    [
                        -133.31228514841405,
                        59.07873398890982
                    ],
                    [
                        -133.30835440730516,
                        59.07613694137629
                    ],
                    [
                        -133.300573144294,
                        59.07419933347853
                    ],
                    [
                        -133.2941556077897,
                        59.071272101361245
                    ],
                    [
                        -133.2910947801771,
                        59.07103607085196
                    ],
                    [
                        -133.28515855891067,
                        59.06852090829753
                    ],
                    [
                        -133.2813882562146,
                        59.06716016978163
                    ],
                    [
                        -133.27978387208847,
                        59.06546948013758
                    ],
                    [
                        -133.27521137732933,
                        59.06456224649668
                    ],
                    [
                        -133.27023778653856,
                        59.06588185114137
                    ],
                    [
                        -133.26734989511175,
                        59.06410862068759
                    ],
                    [
                        -133.26061148178223,
                        59.06361374933448
                    ],
                    [
                        -133.25274999956466,
                        59.0638199465983
                    ],
                    [
                        -133.24986210813785,
                        59.06485091434584
                    ],
                    [
                        -133.24865882004315,
                        59.06311887084934
                    ],
                    [
                        -133.2424017219517,
                        59.063077630653936
                    ],
                    [
                        -133.23933564498964,
                        59.06168179109065
                    ],
                    [
                        -133.23845323372038,
                        59.059165943247194
                    ],
                    [
                        -133.23412139658006,
                        59.05755735378247
                    ],
                    [
                        -133.23131372435944,
                        59.05755735378247
                    ],
                    [
                        -133.22361268055448,
                        59.05623742923689
                    ],
                    [
                        -133.22273026928522,
                        59.054339948842426
                    ],
                    [
                        -133.22032369309602,
                        59.05413369465805
                    ],
                    [
                        -133.2185588705575,
                        59.051493531724645
                    ],
                    [
                        -133.21655339039975,
                        59.05017337417084
                    ],
                    [
                        -133.21807755531967,
                        59.047986751638774
                    ],
                    [
                        -133.21494900627383,
                        59.04518106986703
                    ],
                    [
                        -133.21486878706742,
                        59.04299412962993
                    ],
                    [
                        -133.20989519627685,
                        59.04121971736785
                    ],
                    [
                        -133.18935463459843,
                        59.04286575301413
                    ],
                    [
                        -133.18807112729752,
                        59.03964697204847
                    ],
                    [
                        -133.18678761999664,
                        59.03527224806115
                    ],
                    [
                        -133.16938005222914,
                        59.02982370021195
                    ],
                    [
                        -133.17082400589524,
                        59.02614956513216
                    ],
                    [
                        -133.16729436081778,
                        59.02272277603075
                    ],
                    [
                        -133.16665260716752,
                        59.01818072219382
                    ],
                    [
                        -133.15871090574353,
                        59.01623984358912
                    ],
                    [
                        -133.15726696003003,
                        59.01157302777074
                    ],
                    [
                        -133.15670542558598,
                        59.006409883490335
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "21",
                "name": "Sinwaa [Tlein]",
                "translation": "Big Limestone Outcropping",
                "location": "White Quartz Mountain",
                "audio": "map-21.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -132.95720521651646,
                    59.143800665339825
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "36",
                "name": "K'wálx̲i Héeni",
                "translation": "Fern Creek",
                "location": "McGavin Creek",
                "audio": "map-36.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -133.35675299798777,
                        58.92725752130406
                    ],
                    [
                        -133.35175097866494,
                        58.92713836701523
                    ],
                    [
                        -133.34794174856552,
                        58.92630427547948
                    ],
                    [
                        -133.34470967454166,
                        58.92622483713953
                    ],
                    [
                        -133.34020785715106,
                        58.92862776606884
                    ],
                    [
                        -133.33659101241014,
                        58.92852847479776
                    ],
                    [
                        -133.3322431033066,
                        58.928290174581434
                    ],
                    [
                        -133.32714227871656,
                        58.92704692125346
                    ],
                    [
                        -133.32094747017112,
                        58.927483819459184
                    ],
                    [
                        -133.31444484505155,
                        58.92605395022514
                    ],
                    [
                        -133.31001998180432,
                        58.92512053141937
                    ],
                    [
                        -133.30644161413525,
                        58.923273479417276
                    ],
                    [
                        -133.3035943126198,
                        58.92168453476381
                    ],
                    [
                        -133.297553412361,
                        58.92261804645389
                    ],
                    [
                        -133.29208966817777,
                        58.92444527288882
                    ],
                    [
                        -133.28808805271944,
                        58.9256170302605
                    ],
                    [
                        -133.2805995326217,
                        58.92732243619042
                    ],
                    [
                        -133.2717113290557,
                        58.92817636162064
                    ],
                    [
                        -133.2702876774023,
                        58.92732243619042
                    ],
                    [
                        -133.26336180449422,
                        58.928891259691426
                    ],
                    [
                        -133.26209206112748,
                        58.92978486146265
                    ],
                    [
                        -133.25839826224325,
                        58.929328134558375
                    ],
                    [
                        -133.2543966467849,
                        58.930420297538944
                    ],
                    [
                        -133.24786057908108,
                        58.93069583165919
                    ],
                    [
                        -133.24512870698945,
                        58.930814973670465
                    ],
                    [
                        -133.24185815589414,
                        58.93150996054078
                    ],
                    [
                        -133.23793349457912,
                        58.931768094957505
                    ],
                    [
                        -133.23397035619263,
                        58.929841968545674
                    ],
                    [
                        -133.23131543824474,
                        58.9298022534681
                    ],
                    [
                        -133.22993026366282,
                        58.93041783203401
                    ],
                    [
                        -133.22569778577446,
                        58.930258974100354
                    ],
                    [
                        -133.22408174876256,
                        58.930973829045996
                    ],
                    [
                        -133.22134987667093,
                        58.9303979748324
                    ],
                    [
                        -133.2172827596121,
                        58.92948674713557
                    ],
                    [
                        -133.2155128143135,
                        58.93016190340822
                    ],
                    [
                        -133.20254604114626,
                        58.932147580624104
                    ],
                    [
                        -133.20173800499072,
                        58.93270354976053
                    ],
                    [
                        -133.1992369953295,
                        58.933160232008845
                    ],
                    [
                        -133.19892917875575,
                        58.93385517166257
                    ],
                    [
                        -133.19685141688308,
                        58.934073578378786
                    ],
                    [
                        -133.1959279671619,
                        58.934967046007785
                    ],
                    [
                        -133.1920033058473,
                        58.93476849964455
                    ],
                    [
                        -133.19150310391504,
                        58.93592005265347
                    ],
                    [
                        -133.18777082795896,
                        58.93699215367633
                    ],
                    [
                        -133.18503895586733,
                        58.93905684700036
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "36",
                "name": "K'wálx̲i Héeni",
                "translation": "Fern Creek",
                "location": "McGavin Creek",
                "audio": "map-36.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.25935792181167,
                    58.92951535552953
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "42",
                "name": "Héen Tlein",
                "translation": "Big River",
                "location": "Inklen River",
                "audio": "map-42.mp3",
                "audio2": "map-42_2.mp3",
                "type": "river",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -132.81071835849593,
                    58.76182003417489
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "37",
                "name": "Yayuwaa",
                "translation": "Interstice",
                "location": "Luwa Mountain ridge",
                "audio": "map-37.mp3",
                "type": "ridge",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.12162240874184,
                    58.93922865585978
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "91",
                "name": "K̲aalaká",
                "translation": "Inside of Human Mouth",
                "location": "Ferry terminal",
                "audio": "map-91.mp3",
                "type": "default",
                "marker-color": "#555555",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.68710710097884,
                    58.38200603936057
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "93",
                "name": "Áak'w Noow",
                "translation": "Little Lake Fort",
                "location": "Fairhaven",
                "audio": "map-93.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.69214590804185,
                    58.376812669946844
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "112",
                "name": "L'awkalag̲é",
                "translation": "Shiny Beach",
                "location": "Portland Island",
                "audio": "map-112.mp3",
                "type": "land",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.73877293359064,
                    58.3351850686605
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "95",
                "name": "X'unáx̲i",
                "translation": "Camping Place*",
                "location": "Auke Cape",
                "audio": "map-95.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.6966642603711,
                    58.37501537992417
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "96",
                "name": "Yax̲té",
                "translation": "Dipper",
                "location": "Auke Lake/Bay",
                "audio": "map-96.mp3",
                "audio2": "map-96_2.mp3",
                "type": "water",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.66742139028003,
                    58.36949492823865
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "45",
                "name": "Taaltsux̲éi",
                "translation": "In Front of Big Flat Basket*",
                "location": "Tulsequah River",
                "audio": "map-45.mp3",
                "audio2": "map-45_2.mp3",
                "type": "river",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -133.55457982098673,
                        58.629750675305814
                    ],
                    [
                        -133.5574856633519,
                        58.638868807245046
                    ],
                    [
                        -133.57076951416403,
                        58.65001475771692
                    ],
                    [
                        -133.58098147671836,
                        58.66025034506393
                    ],
                    [
                        -133.59617488108472,
                        58.66461143382307
                    ],
                    [
                        -133.6087679959984,
                        58.67933323272112
                    ],
                    [
                        -133.6155759695397,
                        58.69119971103083
                    ],
                    [
                        -133.60370352288103,
                        58.708366593341964
                    ],
                    [
                        -133.60146187305648,
                        58.721602601968186
                    ],
                    [
                        -133.60685844025124,
                        58.72867126064588
                    ],
                    [
                        -133.63193170865927,
                        58.74254575515039
                    ],
                    [
                        -133.65434819195477,
                        58.759472045109675
                    ],
                    [
                        -133.65077815704896,
                        58.77397987599295
                    ],
                    [
                        -133.6549314392914,
                        58.7863347582219
                    ],
                    [
                        -133.66256965351204,
                        58.79076611813622
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "45",
                "name": "Taaltsux̲éi",
                "translation": "In Front of Big Flat Basket*",
                "location": "Tulsequah River",
                "audio": "map-45.mp3",
                "audio2": "map-45_2.mp3",
                "type": "river",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.6022128360791,
                    58.71690250867505
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "47",
                "name": "Shaanáx̲ Tlein Héeni",
                "translation": "Big Valley Creek",
                "location": "Wilms Creek (Canyon Creek)",
                "audio": "map-47.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -133.76439414377904,
                        58.680977296988715
                    ],
                    [
                        -133.7602636964172,
                        58.68145201130105
                    ],
                    [
                        -133.7553030083796,
                        58.681257810772706
                    ],
                    [
                        -133.75123482906832,
                        58.682185647977946
                    ],
                    [
                        -133.74826671865245,
                        58.68278980776671
                    ],
                    [
                        -133.74635715013767,
                        58.68234747307636
                    ],
                    [
                        -133.74309845548538,
                        58.68325370462608
                    ],
                    [
                        -133.73990202888365,
                        58.68287611100985
                    ],
                    [
                        -133.73707921058613,
                        58.68241220468266
                    ],
                    [
                        -133.735211162686,
                        58.68132253530527
                    ],
                    [
                        -133.7325128804897,
                        58.68177566734687
                    ],
                    [
                        -133.72942023397263,
                        58.6812578016756
                    ],
                    [
                        -133.72705404804665,
                        58.68042704275908
                    ],
                    [
                        -133.72360031529823,
                        58.68021903276309
                    ],
                    [
                        -133.71924155175054,
                        58.68066139001675
                    ],
                    [
                        -133.71558849277727,
                        58.68051034182949
                    ],
                    [
                        -133.7118734722345,
                        58.67875157528678
                    ],
                    [
                        -133.70712034436582,
                        58.67813655560042
                    ],
                    [
                        -133.698859449642,
                        58.675460554187396
                    ],
                    [
                        -133.69151289853318,
                        58.6748396899776
                    ],
                    [
                        -133.6844766088062,
                        58.673490797080206
                    ],
                    [
                        -133.6814697748295,
                        58.67226979549426
                    ],
                    [
                        -133.6779827639914,
                        58.672302170668615
                    ],
                    [
                        -133.6756788461161,
                        58.67171941293577
                    ],
                    [
                        -133.67075967011226,
                        58.67161149376827
                    ],
                    [
                        -133.66963884519987,
                        58.67233454581253
                    ],
                    [
                        -133.66812365596667,
                        58.671989209392734
                    ],
                    [
                        -133.66359884428385,
                        58.67224821202828
                    ],
                    [
                        -133.659966528893,
                        58.67302520773063
                    ],
                    [
                        -133.65647951805485,
                        58.67373743919089
                    ],
                    [
                        -133.65322082340236,
                        58.673532404352414
                    ],
                    [
                        -133.64946834966517,
                        58.671846584018766
                    ],
                    [
                        -133.64623041102968,
                        58.669191685242964
                    ],
                    [
                        -133.6428002695617,
                        58.66680454523029
                    ],
                    [
                        -133.64082844795675,
                        58.66702041297975
                    ],
                    [
                        -133.63642817237533,
                        58.664710558731656
                    ],
                    [
                        -133.63326788498327,
                        58.6625880503872
                    ],
                    [
                        -133.6312545513444,
                        58.66031028894514
                    ],
                    [
                        -133.6293308572465,
                        58.65875547463531
                    ],
                    [
                        -133.62843578425978,
                        58.657867215460016
                    ],
                    [
                        -133.62876697025675,
                        58.657078311644796
                    ],
                    [
                        -133.6273183579811,
                        58.6555322095644
                    ],
                    [
                        -133.62561525265477,
                        58.65379298969859
                    ],
                    [
                        -133.62438377449084,
                        58.65163568316919
                    ],
                    [
                        -133.6226129735317,
                        58.6486905385552
                    ],
                    [
                        -133.62354246831987,
                        58.64649284247466
                    ],
                    [
                        -133.62363160708168,
                        58.6424881247963
                    ],
                    [
                        -133.62155600708417,
                        58.64028365525664
                    ],
                    [
                        -133.6204559381888,
                        58.638533747995524
                    ],
                    [
                        -133.62251994825988,
                        58.635531013403124
                    ],
                    [
                        -133.6244552601887,
                        58.63154875595097
                    ],
                    [
                        -133.62653680948736,
                        58.62845787100508
                    ],
                    [
                        -133.62647990777776,
                        58.62622418808789
                    ],
                    [
                        -133.6243829128276,
                        58.624056465294814
                    ],
                    [
                        -133.62082759514473,
                        58.624868075527246
                    ],
                    [
                        -133.61825383567677,
                        58.62238253184367
                    ],
                    [
                        -133.61949919669036,
                        58.62035072612417
                    ],
                    [
                        -133.61694591447414,
                        58.61776787104668
                    ],
                    [
                        -133.61572130947732,
                        58.61692479083996
                    ],
                    [
                        -133.616779866339,
                        58.61637353509323
                    ],
                    [
                        -133.61499484888597,
                        58.615022381296114
                    ],
                    [
                        -133.6156382965117,
                        58.61424409074806
                    ],
                    [
                        -133.61806675048814,
                        58.61331444590556
                    ],
                    [
                        -133.61744406998145,
                        58.61265503166888
                    ],
                    [
                        -133.61787994633625,
                        58.611898311478996
                    ],
                    [
                        -133.61781767828552,
                        58.61015779287558
                    ],
                    [
                        -133.6191460534484,
                        58.609173985135584
                    ],
                    [
                        -133.62404447343528,
                        58.60719547078571
                    ],
                    [
                        -133.62516527411677,
                        58.60523848616265
                    ],
                    [
                        -133.62466712971127,
                        58.60443835460583
                    ],
                    [
                        -133.62624458699543,
                        58.60351926198072
                    ],
                    [
                        -133.62564373627603,
                        58.60313743624613
                    ],
                    [
                        -133.62805143423574,
                        58.602175055805674
                    ],
                    [
                        -133.62738724169512,
                        58.60075847012118
                    ],
                    [
                        -133.62862225629462,
                        58.59857397012664
                    ],
                    [
                        -133.6284354521425,
                        58.59604327957271
                    ],
                    [
                        -133.6296600571393,
                        58.595740449633524
                    ],
                    [
                        -133.62914115671686,
                        58.59506988829628
                    ],
                    [
                        -133.63084315040538,
                        58.59289588308829
                    ],
                    [
                        -133.6300544217633,
                        58.591651988626325
                    ],
                    [
                        -133.6341996868997,
                        58.589167359233954
                    ],
                    [
                        -133.6408554881883,
                        58.58895028539135
                    ],
                    [
                        -133.64502744758383,
                        58.589913029750335
                    ],
                    [
                        -133.65109373453768,
                        58.58805165779836
                    ],
                    [
                        -133.65426940512248,
                        58.58489272374183
                    ],
                    [
                        -133.659875632657,
                        58.58458848764067
                    ],
                    [
                        -133.66309281527552,
                        58.58246792885484
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "47",
                "name": "Shaanáx̲ Tlein Héeni",
                "translation": "Big Valley Creek",
                "location": "Wilms Creek (Canyon Creek)",
                "audio": "map-47.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.63171103637907,
                    58.66083863636783
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "12",
                "name": "Neixinté",
                "translation": "Blue-Green Claystone (Flint)",
                "location": "Near Nakina Village*",
                "audio": "map-12.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -132.789344787598,
                    59.2071397662104,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "8",
                "name": "Áa Tlein",
                "translation": "Big Lake",
                "location": "Atlin Lake",
                "audio": "map-8.mp3",
                "audio2": "map-8_2.mp3",
                "type": "lake",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.76325445108012,
                    59.56212471649775
                ],
                "type": "Point"
            },
            "id": 22
        },
        {
            "type": "Feature",
            "properties": {
                "id": "121",
                "name": "T'aak̲ú",
                "translation": "Flood of Geese",
                "location": "Taku River",
                "audio": "map-121.mp3",
                "audio2": "map-121_2.mp3",
                "type": "water",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.0966148164973,
                    58.28303123379572
                ],
                "type": "Point"
            },
            "id": 23
        },
        {
            "type": "Feature",
            "properties": {
                "id": "5",
                "name": "Yaawat'aayi Héen G̲eeyí",
                "translation": "Warm Water Bay",
                "location": "Warm Bay",
                "audio": "map-5.mp3",
                "type": "bay",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.5828874870772,
                    59.41162148512831
                ],
                "type": "Point"
            },
            "id": 24
        },
        {
            "type": "Feature",
            "properties": {
                "id": "4",
                "name": "Inhéeni",
                "translation": "Flint Creek",
                "location": "McKee Creek",
                "audio": "map-4.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -133.62214698849556,
                        59.46217050804549
                    ],
                    [
                        -133.62176475933467,
                        59.462712256779525
                    ],
                    [
                        -133.6219256979287,
                        59.46369351539653
                    ],
                    [
                        -133.6218653459559,
                        59.46418413402262
                    ],
                    [
                        -133.62104053566154,
                        59.46466452452617
                    ],
                    [
                        -133.6201151387457,
                        59.46517557075785
                    ],
                    [
                        -133.61900868591167,
                        59.465093803880194
                    ],
                    [
                        -133.61743953461982,
                        59.4647973972898
                    ],
                    [
                        -133.61526686360025,
                        59.46431700867484
                    ],
                    [
                        -133.6132551311747,
                        59.46375484311429
                    ],
                    [
                        -133.61158813025514,
                        59.46332693685329
                    ],
                    [
                        -133.60955628050527,
                        59.46250921444809
                    ],
                    [
                        -133.60772560399815,
                        59.46188568782094
                    ],
                    [
                        -133.60507011719642,
                        59.461701694159245
                    ],
                    [
                        -133.60215310517935,
                        59.461865244130365
                    ],
                    [
                        -133.59951772386808,
                        59.461947018774964
                    ],
                    [
                        -133.59700305833627,
                        59.46218211990313
                    ],
                    [
                        -133.59477003534388,
                        59.462519436056596
                    ],
                    [
                        -133.59324111870052,
                        59.46279541949647
                    ],
                    [
                        -133.59038445865625,
                        59.462141232867765
                    ],
                    [
                        -133.58837272623072,
                        59.46200834966169
                    ],
                    [
                        -133.586655637254,
                        59.461733083167815
                    ],
                    [
                        -133.5846439048285,
                        59.46217262151214
                    ],
                    [
                        -133.58281322832113,
                        59.46270414861877
                    ],
                    [
                        -133.5804796187075,
                        59.46273481338895
                    ],
                    [
                        -133.57838741698504,
                        59.46318456015808
                    ],
                    [
                        -133.5736196111365,
                        59.46376717775664
                    ],
                    [
                        -133.57063227324312,
                        59.46403035634006
                    ],
                    [
                        -133.56888206603287,
                        59.464653843404136
                    ],
                    [
                        -133.56731291474105,
                        59.464388096225775
                    ],
                    [
                        -133.56411740931,
                        59.46549367920676
                    ],
                    [
                        -133.56272931393636,
                        59.466801910033354
                    ],
                    [
                        -133.5597519499465,
                        59.46818162986193
                    ],
                    [
                        -133.55659353003847,
                        59.46931602396626
                    ],
                    [
                        -133.55260952506288,
                        59.47085934518961
                    ],
                    [
                        -133.548827468103,
                        59.47277026816454
                    ],
                    [
                        -133.5441398771486,
                        59.47454837674118
                    ],
                    [
                        -133.54242990458687,
                        59.47525341225972
                    ],
                    [
                        -133.53862773030264,
                        59.476101478968815
                    ],
                    [
                        -133.53625253480465,
                        59.47902403196301
                    ],
                    [
                        -133.53393904251533,
                        59.4804236852448
                    ],
                    [
                        -133.5317060241722,
                        59.481373773761334
                    ],
                    [
                        -133.5293321799101,
                        59.482344274903
                    ],
                    [
                        -133.52911088934326,
                        59.483784651502305
                    ],
                    [
                        -133.52776302861804,
                        59.483713144893414
                    ],
                    [
                        -133.52448390476445,
                        59.48540883227125
                    ],
                    [
                        -133.52151877398958,
                        59.48594946924857
                    ],
                    [
                        -133.51976856677936,
                        59.48688919608088
                    ],
                    [
                        -133.51731425322015,
                        59.48764504433811
                    ],
                    [
                        -133.5144173705286,
                        59.48858472353368
                    ],
                    [
                        -133.51224469950904,
                        59.489044339923936
                    ],
                    [
                        -133.5094081567891,
                        59.48964693860157
                    ],
                    [
                        -133.5060686809627,
                        59.490361872235184
                    ],
                    [
                        -133.50025699323277,
                        59.49171417982359
                    ],
                    [
                        -133.49607258978767,
                        59.49313373201662
                    ],
                    [
                        -133.4924313540975,
                        59.49370562106847
                    ],
                    [
                        -133.48888062104874,
                        59.4944038681551
                    ],
                    [
                        -133.4859032570589,
                        59.49545568846523
                    ],
                    [
                        -133.48459563098237,
                        59.4961807105679
                    ],
                    [
                        -133.48047157951004,
                        59.49679339316202
                    ],
                    [
                        -133.47598541620096,
                        59.49734479798886
                    ],
                    [
                        -133.47351111811076,
                        59.49723950973322
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "4",
                "name": "Inhéeni",
                "translation": "Flint Creek",
                "location": "McKee Creek",
                "audio": "map-4.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.55291823916582,
                    59.47070275046022
                ],
                "type": "Point"
            },
            "id": 26
        },
        {
            "type": "Feature",
            "properties": {
                "id": "22",
                "name": "L'óox̲'u Héen",
                "translation": "Silty River",
                "location": "Sloko River",
                "audio": "map-22.mp3",
                "audio2": "map-22_2.mp3",
                "type": "river",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.3813795549566,
                    59.14126921905745
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "16",
                "name": "Ts'eitina Héeni",
                "translation": "Ts'eitina Creek",
                "location": "Gold Bottom Creek",
                "audio": "map-16.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -133.2793625596799,
                        59.167877110123925
                    ],
                    [
                        -133.28105531127414,
                        59.16979811825914
                    ],
                    [
                        -133.28375565310304,
                        59.17392895299429
                    ],
                    [
                        -133.28758448089053,
                        59.17826578228369
                    ],
                    [
                        -133.29266273567325,
                        59.18045464467636
                    ],
                    [
                        -133.29475852336145,
                        59.18105345996952
                    ],
                    [
                        -133.29959495648794,
                        59.18260207156274
                    ],
                    [
                        -133.30281924523882,
                        59.18318020190421
                    ],
                    [
                        -133.3080587144591,
                        59.18408867268303
                    ],
                    [
                        -133.31313696924198,
                        59.18565779260513
                    ],
                    [
                        -133.32615501593236,
                        59.194369214849814
                    ],
                    [
                        -133.3308705382306,
                        59.19752707728372
                    ],
                    [
                        -133.3347799883411,
                        59.19796048659933
                    ],
                    [
                        -133.3360857616431,
                        59.201525544814814
                    ],
                    [
                        -133.34088189116022,
                        59.20443513266119
                    ],
                    [
                        -133.34390466186423,
                        59.208231668822776
                    ],
                    [
                        -133.34708864700582,
                        59.211078793941084
                    ],
                    [
                        -133.35158367312,
                        59.21610253907073
                    ],
                    [
                        -133.35686344594956,
                        59.21969152586101
                    ],
                    [
                        -133.36399718481115,
                        59.22037215319534
                    ],
                    [
                        -133.3721385139072,
                        59.22140338086862
                    ],
                    [
                        -133.38225477132576,
                        59.22328013999592
                    ],
                    [
                        -133.3841893445764,
                        59.22229021647186
                    ],
                    [
                        -133.39233067367246,
                        59.22119714255365
                    ],
                    [
                        -133.40430084566052,
                        59.22026903344124
                    ],
                    [
                        -133.40786909295076,
                        59.21570610848457
                    ],
                    [
                        -133.40722423520054,
                        59.21224048149958
                    ],
                    [
                        -133.405088143903,
                        59.208341230564486
                    ],
                    [
                        -133.41301926658025,
                        59.20716152860851
                    ],
                    [
                        -133.41757357444095,
                        59.203736304003485
                    ],
                    [
                        -133.42188606064542,
                        59.20080601993416
                    ],
                    [
                        -133.42402215194295,
                        59.19847399451163
                    ],
                    [
                        -133.41999179331938,
                        59.19426728243005
                    ],
                    [
                        -133.4178153984125,
                        59.18993247318457
                    ],
                    [
                        -133.41346260859868,
                        59.18497773199917
                    ],
                    [
                        -133.41093516819188,
                        59.180116808060546
                    ],
                    [
                        -133.41238609812967,
                        59.17503673031928
                    ],
                    [
                        -133.41800678081512,
                        59.170417133755706
                    ],
                    [
                        -133.4213116767847,
                        59.166967566862326
                    ],
                    [
                        -133.4223595706289,
                        59.16475718241904
                    ],
                    [
                        -133.42498191440868,
                        59.16169494430605
                    ],
                    [
                        -133.42413553861155,
                        59.15946355744245
                    ],
                    [
                        -133.4251028252367,
                        59.158533769957785
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "16",
                "name": "Ts'eitina Héeni",
                "translation": "Ts'eitina Creek",
                "location": "Gold Bottom Creek",
                "audio": "map-16.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.41710261966645,
                    59.20398656691191
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "30",
                "name": "Séik̲'u Té Héeni",
                "translation": "Séik̲'u Té Creek",
                "location": "Sloko River tributary",
                "audio": "map-30.mp3",
                "audio2": "map-30_2.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -133.5018160328233,
                        59.06539812818593
                    ],
                    [
                        -133.49540775893084,
                        59.06651690612847
                    ],
                    [
                        -133.49157891603903,
                        59.06521166165146
                    ],
                    [
                        -133.48722612622524,
                        59.06394780622864
                    ],
                    [
                        -133.48247030031766,
                        59.06260102384783
                    ],
                    [
                        -133.47932661878542,
                        59.06081904598628
                    ],
                    [
                        -133.47448592968084,
                        59.05901625352226
                    ],
                    [
                        -133.4691255496324,
                        59.05878830748719
                    ],
                    [
                        -133.46400699124035,
                        59.05901625352226
                    ],
                    [
                        -133.45953329059833,
                        59.05922347587784
                    ],
                    [
                        -133.4570344668163,
                        59.060508226577696
                    ],
                    [
                        -133.45094862179894,
                        59.06171004662852
                    ],
                    [
                        -133.44543692054827,
                        59.06214167019536
                    ],
                    [
                        -133.44563843859518,
                        59.061520052198176
                    ],
                    [
                        -133.44382477617273,
                        59.06174798010346
                    ],
                    [
                        -133.44253506067247,
                        59.06369566596359
                    ],
                    [
                        -133.44023775493736,
                        59.063550629333974
                    ],
                    [
                        -133.43697316257692,
                        59.064876655717626
                    ],
                    [
                        -133.43207723076694,
                        59.067204974047684
                    ],
                    [
                        -133.42969931781295,
                        59.06811652567117
                    ],
                    [
                        -133.4251853135616,
                        59.0689037553247
                    ],
                    [
                        -133.42075191652913,
                        59.070146712764966
                    ],
                    [
                        -133.41701257267653,
                        59.06927987644218
                    ],
                    [
                        -133.41548103551986,
                        59.07068854270864
                    ],
                    [
                        -133.41499739220717,
                        59.07147571338905
                    ],
                    [
                        -133.4120955323314,
                        59.072283580319265
                    ],
                    [
                        -133.40907276162739,
                        59.0723042946058
                    ],
                    [
                        -133.4051126571737,
                        59.07307248871072
                    ],
                    [
                        -133.40213019007902,
                        59.07406673752243
                    ],
                    [
                        -133.39854316884367,
                        59.0741081639315
                    ],
                    [
                        -133.39427098624856,
                        59.07524737058404
                    ],
                    [
                        -133.38971667838786,
                        59.07655223356534
                    ],
                    [
                        -133.3885444117158,
                        59.077391937139794
                    ],
                    [
                        -133.3888668405909,
                        59.078178954110996
                    ],
                    [
                        -133.38467526521467,
                        59.078406771445316
                    ],
                    [
                        -133.3826600847453,
                        59.07805468947356
                    ],
                    [
                        -133.38197492338577,
                        59.07869671859234
                    ],
                    [
                        -133.37955670682243,
                        59.07952512551543
                    ],
                    [
                        -133.37471685297453,
                        59.0806231690874
                    ],
                    [
                        -133.3716537786612,
                        59.0815343644517
                    ],
                    [
                        -133.36718007801917,
                        59.0826112005073
                    ],
                    [
                        -133.3640767000963,
                        59.08292182001304
                    ],
                    [
                        -133.36125544743936,
                        59.08354305058873
                    ],
                    [
                        -133.35847449839156,
                        59.08447487536438
                    ],
                    [
                        -133.35398064545336,
                        59.08572910183807
                    ],
                    [
                        -133.35051453504602,
                        59.08577051417112
                    ],
                    [
                        -133.34837844374866,
                        59.08599828111099
                    ],
                    [
                        -133.34475111890376,
                        59.08473519084339
                    ],
                    [
                        -133.3409625796213,
                        59.08498367129138
                    ],
                    [
                        -133.33987438216798,
                        59.08577051417112
                    ],
                    [
                        -133.33725464755773,
                        59.085459920453275
                    ],
                    [
                        -133.3318090699333,
                        59.086184638899226
                    ],
                    [
                        -133.33080147969864,
                        59.08674369375842
                    ],
                    [
                        -133.32604565379086,
                        59.0865987544845
                    ],
                    [
                        -133.3237886516653,
                        59.0870335704694
                    ],
                    [
                        -133.32092709539882,
                        59.0865987544845
                    ],
                    [
                        -133.31947616546083,
                        59.08688863242003
                    ],
                    [
                        -133.31786402108526,
                        59.08647452033364
                    ],
                    [
                        -133.31770280664773,
                        59.086163932988484
                    ],
                    [
                        -133.31601005505348,
                        59.08626746241606
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "30",
                "name": "Séik̲'u Té Héeni",
                "translation": "Séik̲'u Té Creek",
                "location": "Sloko River tributary",
                "audio": "map-30.mp3",
                "audio2": "map-30_2.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.40094506080362,
                    59.07406812598842
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "20",
                "name": "G̲athéeni",
                "translation": "Sockeye Creek",
                "location": "Silver Salmon River",
                "audio": "map-20.mp3",
                "type": "river",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -133.0492009190765,
                        59.274394309596374
                    ],
                    [
                        -133.05903256702354,
                        59.27198469517222
                    ],
                    [
                        -133.0620699867144,
                        59.27151500470961
                    ],
                    [
                        -133.06802492795052,
                        59.2708206678125
                    ],
                    [
                        -133.07206149885548,
                        59.27084108968788
                    ],
                    [
                        -133.0750589524978,
                        59.26941152883276
                    ],
                    [
                        -133.07737698331456,
                        59.268615033186165
                    ],
                    [
                        -133.08029450485986,
                        59.26855376351878
                    ],
                    [
                        -133.0828523319679,
                        59.26679731952382
                    ],
                    [
                        -133.08413124552203,
                        59.264162483710976
                    ],
                    [
                        -133.0902860193417,
                        59.264775256562274
                    ],
                    [
                        -133.09432259024666,
                        59.26595991533017
                    ],
                    [
                        -133.0953217414608,
                        59.266572653700706
                    ],
                    [
                        -133.09724011179196,
                        59.26675647306229
                    ],
                    [
                        -133.10159641108547,
                        59.26806360436308
                    ],
                    [
                        -133.10615254062174,
                        59.26804318082233
                    ],
                    [
                        -133.10851053748718,
                        59.269288994404036
                    ],
                    [
                        -133.11494507130593,
                        59.26906434286312
                    ],
                    [
                        -133.11918147245387,
                        59.26924814877944
                    ],
                    [
                        -133.12090001254208,
                        59.269840405540094
                    ],
                    [
                        -133.12833369757513,
                        59.26802275726939
                    ],
                    [
                        -133.13021210185758,
                        59.26871715120416
                    ],
                    [
                        -133.13660673930133,
                        59.26767554672301
                    ],
                    [
                        -133.14304127312028,
                        59.26700155573167
                    ],
                    [
                        -133.14739757241378,
                        59.26632755140457
                    ],
                    [
                        -133.15251322663008,
                        59.265081629508586
                    ],
                    [
                        -133.1533525136499,
                        59.26320244800027
                    ],
                    [
                        -133.15876879350432,
                        59.26065667835525
                    ],
                    [
                        -133.16216590763221,
                        59.257653633613245
                    ],
                    [
                        -133.1631250858734,
                        59.25481377901505
                    ],
                    [
                        -133.16472372781584,
                        59.25411910166534
                    ],
                    [
                        -133.1683206721867,
                        59.25181021932886
                    ],
                    [
                        -133.168840230818,
                        59.25219844942134
                    ],
                    [
                        -133.17179771841182,
                        59.25136068422407
                    ],
                    [
                        -133.17335639430578,
                        59.25035942563608
                    ],
                    [
                        -133.17675350843388,
                        59.25219844942134
                    ],
                    [
                        -133.1775927954537,
                        59.25305662656592
                    ],
                    [
                        -133.1802305546588,
                        59.25197368517013
                    ],
                    [
                        -133.17907153925043,
                        59.25152415222078
                    ],
                    [
                        -133.18055028304732,
                        59.25136068422407
                    ],
                    [
                        -133.18154943426146,
                        59.25211671713788
                    ],
                    [
                        -133.18622546194365,
                        59.25148328529491
                    ],
                    [
                        -133.1867173689851,
                        59.25077439997807
                    ],
                    [
                        -133.18887553560756,
                        59.250631362377504
                    ],
                    [
                        -133.19231261578418,
                        59.25144871201067
                    ],
                    [
                        -133.19495037498945,
                        59.25144871201067
                    ],
                    [
                        -133.19666891507765,
                        59.25253167008637
                    ],
                    [
                        -133.1981876249231,
                        59.253961939340314
                    ],
                    [
                        -133.20670039326748,
                        59.254717914556124
                    ],
                    [
                        -133.2106570320752,
                        59.256311537103386
                    ],
                    [
                        -133.21737147116912,
                        59.256250240729145
                    ],
                    [
                        -133.2200491964229,
                        59.257700783690325
                    ],
                    [
                        -133.22160787231687,
                        59.257128745952286
                    ],
                    [
                        -133.2270832209702,
                        59.25737390615876
                    ],
                    [
                        -133.22772267774727,
                        59.25606636467717
                    ],
                    [
                        -133.22636383209613,
                        59.25324680763819
                    ],
                    [
                        -133.2261640069857,
                        59.25236819901602
                    ],
                    [
                        -133.22388594221763,
                        59.25110133290593
                    ],
                    [
                        -133.2235662138291,
                        59.247586554603714
                    ],
                    [
                        -133.22520482182028,
                        59.24405097596278
                    ],
                    [
                        -133.22696332624375,
                        59.238409642855686
                    ],
                    [
                        -133.22404580469845,
                        59.23775551459974
                    ],
                    [
                        -133.22332641582435,
                        59.23683562551213
                    ],
                    [
                        -133.22436553308697,
                        59.23587482597466
                    ],
                    [
                        -133.22316655163007,
                        59.23436202290921
                    ],
                    [
                        -133.22508492196107,
                        59.23266515044449
                    ],
                    [
                        -133.22824223979765,
                        59.23039570713917
                    ],
                    [
                        -133.2275628383925,
                        59.22920980771346
                    ],
                    [
                        -133.2290016161407,
                        59.22865773894338
                    ],
                    [
                        -133.22880178589793,
                        59.22732864785442
                    ],
                    [
                        -133.22192762554488,
                        59.226347131969106
                    ],
                    [
                        -133.22312660706174,
                        59.22700147903342
                    ],
                    [
                        -133.22260704843043,
                        59.227241846240105
                    ],
                    [
                        -133.22072864414798,
                        59.22716005412008
                    ],
                    [
                        -133.2203289836622,
                        59.22763035613295
                    ],
                    [
                        -133.2188502398653,
                        59.226996469291834
                    ],
                    [
                        -133.21821078308824,
                        59.227528117116606
                    ],
                    [
                        -133.2191300022053,
                        59.22820288896398
                    ],
                    [
                        -133.21705176767986,
                        59.227528117116606
                    ],
                    [
                        -133.21537319364018,
                        59.22824378379866
                    ],
                    [
                        -133.2135347431584,
                        59.228346008363246
                    ],
                    [
                        -133.2125355919443,
                        59.229286573210544
                    ],
                    [
                        -133.21069715371033,
                        59.228223324080034
                    ],
                    [
                        -133.20881874942785,
                        59.22961372012264
                    ],
                    [
                        -133.2067804809509,
                        59.22920478599241
                    ],
                    [
                        -133.20682044699964,
                        59.22861182279658
                    ],
                    [
                        -133.20538166925124,
                        59.22889808217772
                    ],
                    [
                        -133.2050219748142,
                        59.22767123934648
                    ],
                    [
                        -133.203103604483,
                        59.227793925614975
                    ],
                    [
                        -133.2021044532689,
                        59.22691466427543
                    ],
                    [
                        -133.19698879905258,
                        59.226219418337536
                    ],
                    [
                        -133.19786805212115,
                        59.22574909687552
                    ],
                    [
                        -133.19718862929557,
                        59.22468573749754
                    ],
                    [
                        -133.19702678905668,
                        59.22339839350684
                    ],
                    [
                        -133.19586777364833,
                        59.22356199558652
                    ],
                    [
                        -133.1934298446858,
                        59.22143510737041
                    ],
                    [
                        -133.19111181386924,
                        59.222396313654485
                    ],
                    [
                        -133.18827422442098,
                        59.22149646177195
                    ],
                    [
                        -133.18733584109853,
                        59.220305707091995
                    ],
                    [
                        -133.18541747076756,
                        59.220367063524066
                    ],
                    [
                        -133.18813516206987,
                        59.21920127245252
                    ],
                    [
                        -133.18441831955343,
                        59.21913991392455
                    ],
                    [
                        -133.18174059429964,
                        59.217401376458696
                    ],
                    [
                        -133.1799421221142,
                        59.21783090571634
                    ],
                    [
                        -133.1782235820258,
                        59.21760591487461
                    ],
                    [
                        -133.17778395549172,
                        59.216317302390024
                    ],
                    [
                        -133.17418701112084,
                        59.21429224162074
                    ],
                    [
                        -133.1721887086926,
                        59.21271711129509
                    ],
                    [
                        -133.16959091553605,
                        59.210323592327796
                    ],
                    [
                        -133.1672711176136,
                        59.207908560126924
                    ],
                    [
                        -133.16347434299996,
                        59.2080926959525
                    ],
                    [
                        -133.16003726282335,
                        59.20854280156769
                    ],
                    [
                        -133.15787909620087,
                        59.20711062674164
                    ],
                    [
                        -133.15440204997574,
                        59.20684464480274
                    ],
                    [
                        -133.1552413369956,
                        59.20426655864998
                    ],
                    [
                        -133.15312316328266,
                        59.20424611958521
                    ],
                    [
                        -133.1524837065058,
                        59.202711451163395
                    ],
                    [
                        -133.15108489480593,
                        59.20283422717512
                    ],
                    [
                        -133.15084509851462,
                        59.1992530788587
                    ],
                    [
                        -133.1480075090666,
                        59.197227005794986
                    ],
                    [
                        -133.14684849365818,
                        59.19448445285164
                    ],
                    [
                        -133.14345119153404,
                        59.193358712162905
                    ],
                    [
                        -133.14201241378566,
                        59.19190543131151
                    ],
                    [
                        -133.13729642005512,
                        59.19145510638975
                    ],
                    [
                        -133.1333397812472,
                        59.188916800367366
                    ],
                    [
                        -133.12986273502207,
                        59.18750427422802
                    ],
                    [
                        -133.12866375385238,
                        59.18257021054694
                    ],
                    [
                        -133.13010253160076,
                        59.18146455496182
                    ],
                    [
                        -133.12854385570665,
                        59.17812689617591
                    ],
                    [
                        -133.1249345436203,
                        59.17448794513899
                    ],
                    [
                        -133.12365563006617,
                        59.17524568244991
                    ],
                    [
                        -133.12389542635768,
                        59.172173670465924
                    ],
                    [
                        -133.12145749739517,
                        59.17076045226452
                    ],
                    [
                        -133.11590221383622,
                        59.17012550466964
                    ],
                    [
                        -133.11258503180537,
                        59.16693012801002
                    ],
                    [
                        -133.11102635591143,
                        59.16615172180633
                    ],
                    [
                        -133.10467175418972,
                        59.16557814800336
                    ],
                    [
                        -133.09839708456502,
                        59.163713966711356
                    ],
                    [
                        -133.0943049467141,
                        59.16169487771748
                    ],
                    [
                        -133.0916671875088,
                        59.15999439152296
                    ],
                    [
                        -133.08503282344708,
                        59.1610392785949
                    ],
                    [
                        -133.08171564141622,
                        59.16095732782122
                    ],
                    [
                        -133.0804367278621,
                        59.15978950796247
                    ],
                    [
                        -133.0775591723655,
                        59.160158297488124
                    ],
                    [
                        -133.07324283912055,
                        59.15868311553166
                    ],
                    [
                        -133.06900271137664,
                        59.15868311320361
                    ],
                    [
                        -133.06804352621103,
                        59.15825283981701
                    ],
                    [
                        -133.06168892448932,
                        59.15872409133928
                    ],
                    [
                        -133.06001035044966,
                        59.157884029750335
                    ],
                    [
                        -133.05369571477644,
                        59.15784305060845
                    ],
                    [
                        -133.05001883994777,
                        59.15550715988434
                    ],
                    [
                        -133.04734111469395,
                        59.15374489004438
                    ],
                    [
                        -133.04094654692352,
                        59.15194154323015
                    ],
                    [
                        -133.03704144031013,
                        59.14946256031038
                    ],
                    [
                        -133.03736116869865,
                        59.148294348061285
                    ],
                    [
                        -133.03448361320207,
                        59.14718758388395
                    ],
                    [
                        -133.0306868385884,
                        59.14642922480388
                    ],
                    [
                        -133.02693003002327,
                        59.14331362741444
                    ],
                    [
                        -133.02253375866414,
                        59.142329691954046
                    ],
                    [
                        -133.0195363050218,
                        59.14153022656174
                    ],
                    [
                        -133.01945637292476,
                        59.140115742054434
                    ],
                    [
                        -133.0207352864787,
                        59.13894721077227
                    ],
                    [
                        -133.0176179346908,
                        59.13820917046621
                    ],
                    [
                        -133.01465984656045,
                        59.13610409630391
                    ],
                    [
                        -133.01002378492694,
                        59.13265948608776
                    ],
                    [
                        -133.0091445318584,
                        59.13116261294152
                    ],
                    [
                        -133.010383479364,
                        59.130424404836475
                    ],
                    [
                        -133.0105890226012,
                        59.127653059276014
                    ],
                    [
                        -133.00957983085382,
                        59.12410490436466
                    ],
                    [
                        -133.0078213247171,
                        59.121766709062314
                    ],
                    [
                        -133.00678220745448,
                        59.11914117678407
                    ],
                    [
                        -133.00626264882317,
                        59.11815655030938
                    ],
                    [
                        -133.00494376922052,
                        59.117069325709025
                    ],
                    [
                        -133.00498373526904,
                        59.11606412510642
                    ],
                    [
                        -133.00242590816077,
                        59.114463949034246
                    ],
                    [
                        -133.00134682484963,
                        59.114299824187015
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "20",
                "name": "G̲athéeni",
                "translation": "Sockeye Creek",
                "location": "Silver Salmon River",
                "audio": "map-20.mp3",
                "type": "river",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.17107856644532,
                    59.21235599823359
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "42",
                "name": "Héen Tlein",
                "translation": "Big River",
                "location": "Inklen River",
                "audio": "map-42.mp3",
                "audio2": "map-42_2.mp3",
                "type": "river",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -132.0930403630772,
                        58.775950516569594
                    ],
                    [
                        -132.1018921131638,
                        58.779490131302026
                    ],
                    [
                        -132.107456070361,
                        58.78335707575215
                    ],
                    [
                        -132.11036450253226,
                        58.78407798387124
                    ],
                    [
                        -132.11807817046468,
                        58.78224291558186
                    ],
                    [
                        -132.1375520206553,
                        58.78119426158716
                    ],
                    [
                        -132.1487358709336,
                        58.779686763754
                    ],
                    [
                        -132.15973733175542,
                        58.782570611227214
                    ],
                    [
                        -132.16820972112387,
                        58.77929349326945
                    ],
                    [
                        -132.1807286248178,
                        58.77988339732585
                    ],
                    [
                        -132.19438561066556,
                        58.7792279477558
                    ],
                    [
                        -132.20930713223973,
                        58.77981785292562
                    ],
                    [
                        -132.21540197123488,
                        58.77438241824905
                    ],
                    [
                        -132.23019703923654,
                        58.775169065990355
                    ],
                    [
                        -132.245118560811,
                        58.77680785823182
                    ],
                    [
                        -132.25523484662432,
                        58.773005741831525
                    ],
                    [
                        -132.26610987272193,
                        58.77280906037541
                    ],
                    [
                        -132.2666157107671,
                        58.777135586649365
                    ],
                    [
                        -132.27749071801645,
                        58.77333350612531
                    ],
                    [
                        -132.28621601453023,
                        58.76998995349834
                    ],
                    [
                        -132.2907683309012,
                        58.77031776528494
                    ],
                    [
                        -132.300252348851,
                        58.77366128636126
                    ],
                    [
                        -132.31580613828896,
                        58.774382395775746
                    ],
                    [
                        -132.32491079552062,
                        58.77366128636126
                    ],
                    [
                        -132.34504645513573,
                        58.777667631756884
                    ],
                    [
                        -132.34808134087982,
                        58.77288226011507
                    ],
                    [
                        -132.3662906553434,
                        58.77242335421954
                    ],
                    [
                        -132.3807063626272,
                        58.77032542149183
                    ],
                    [
                        -132.40106538782635,
                        58.772685586902156
                    ],
                    [
                        -132.41524671993463,
                        58.77147504379499
                    ],
                    [
                        -132.4289037057824,
                        58.777047214505416
                    ],
                    [
                        -132.44660720595533,
                        58.77803044595922
                    ],
                    [
                        -132.46506942756457,
                        58.77619505806268
                    ],
                    [
                        -132.47708251696747,
                        58.77914474129
                    ],
                    [
                        -132.49422258543328,
                        58.77975087672897
                    ],
                    [
                        -132.50193625336604,
                        58.77765338655385
                    ],
                    [
                        -132.51407579634176,
                        58.778636600846795
                    ],
                    [
                        -132.53051476078798,
                        58.77522800561758
                    ],
                    [
                        -132.5368374394212,
                        58.775752426657505
                    ],
                    [
                        -132.557871220344,
                        58.76680334674887
                    ],
                    [
                        -132.57785088482495,
                        58.76083605318465
                    ],
                    [
                        -132.5849322848942,
                        58.75513010002686
                    ],
                    [
                        -132.59669246715174,
                        58.754474183317996
                    ],
                    [
                        -132.60605003152904,
                        58.75519569101729
                    ],
                    [
                        -132.61515468876098,
                        58.75611395188463
                    ],
                    [
                        -132.63024993652792,
                        58.75394944272165
                    ],
                    [
                        -132.63834296517854,
                        58.74948881353228
                    ],
                    [
                        -132.65364384747085,
                        58.75073522342214
                    ],
                    [
                        -132.66856536904533,
                        58.74830796294452
                    ],
                    [
                        -132.67969328343972,
                        58.74693025323765
                    ],
                    [
                        -132.68276781026128,
                        58.74215049618013
                    ],
                    [
                        -132.6917460139204,
                        58.73775397701198
                    ],
                    [
                        -132.70704689621274,
                        58.73414447875197
                    ],
                    [
                        -132.72323295351393,
                        58.73368506119749
                    ],
                    [
                        -132.7344873214811,
                        58.73781960078642
                    ],
                    [
                        -132.74232744298635,
                        58.74110063166455
                    ],
                    [
                        -132.76104257174063,
                        58.74254418720287
                    ],
                    [
                        -132.78203400285355,
                        58.742675427207075
                    ],
                    [
                        -132.7921502886327,
                        58.745234300570985
                    ],
                    [
                        -132.80327820302708,
                        58.74858023549612
                    ],
                    [
                        -132.81111832453232,
                        58.7567141068526
                    ],
                    [
                        -132.81061251024164,
                        58.763141142762066
                    ],
                    [
                        -132.8121299377738,
                        58.77299075291316
                    ],
                    [
                        -132.80972731989323,
                        58.78124999659087
                    ],
                    [
                        -132.81276220563728,
                        58.78944175007311
                    ],
                    [
                        -132.82603983076692,
                        58.79481449060131
                    ],
                    [
                        -132.8372941987341,
                        58.80090695820931
                    ],
                    [
                        -132.84443257944073,
                        58.807955203494004
                    ],
                    [
                        -132.85075525807395,
                        58.814962069893596
                    ],
                    [
                        -132.856698575989,
                        58.82150926358838
                    ],
                    [
                        -132.86972329397355,
                        58.825960649200795
                    ],
                    [
                        -132.88869132987318,
                        58.82360410448808
                    ],
                    [
                        -132.8988076156862,
                        58.81947976575668
                    ],
                    [
                        -132.9125910551068,
                        58.81561684489404
                    ],
                    [
                        -132.92645279921567,
                        58.813527301567916
                    ],
                    [
                        -132.9340400135756,
                        58.815950007548366
                    ],
                    [
                        -132.93694844574685,
                        58.823020721890344
                    ],
                    [
                        -132.94352403152538,
                        58.82544276464981
                    ],
                    [
                        -132.9372013528922,
                        58.829959095069825
                    ],
                    [
                        -132.9390981564821,
                        58.835194683564424
                    ],
                    [
                        -132.9483435045915,
                        58.838868336668526
                    ],
                    [
                        -132.95163129748093,
                        58.84259781548468
                    ],
                    [
                        -132.95681589395997,
                        58.84567269777085
                    ],
                    [
                        -132.96351793331132,
                        58.85241031510475
                    ],
                    [
                        -132.96832316907248,
                        58.858819646834064
                    ],
                    [
                        -132.97186386910727,
                        58.86136996923659
                    ],
                    [
                        -132.97742782630442,
                        58.86097762417293
                    ],
                    [
                        -132.98148066332297,
                        58.86417510780416
                    ],
                    [
                        -132.98767688838367,
                        58.86515587152206
                    ],
                    [
                        -132.99184985628133,
                        58.867444212120574
                    ],
                    [
                        -132.99387311344407,
                        58.87110524237423
                    ],
                    [
                        -133.00120742065863,
                        58.872870243573686
                    ],
                    [
                        -133.00753009929184,
                        58.87528880269906
                    ],
                    [
                        -133.00689783142863,
                        58.880190768747326
                    ],
                    [
                        -133.00639201713796,
                        58.883785102395905
                    ],
                    [
                        -133.00930044930922,
                        58.88502669448914
                    ],
                    [
                        -133.01562312794243,
                        58.88404649390918
                    ],
                    [
                        -133.0173934779598,
                        58.88809780974344
                    ],
                    [
                        -133.01815219939553,
                        58.89110331802931
                    ],
                    [
                        -133.02839493878133,
                        58.895741741954936
                    ],
                    [
                        -133.0342780545893,
                        58.8962132817218
                    ],
                    [
                        -133.04338271182124,
                        58.89967531431128
                    ],
                    [
                        -133.05021120474515,
                        58.89908744642619
                    ],
                    [
                        -133.05526934765163,
                        58.89817296539789
                    ],
                    [
                        -133.0639946441654,
                        58.9006550719011
                    ],
                    [
                        -133.07132895138,
                        58.90183074434816
                    ],
                    [
                        -133.07638709428647,
                        58.90189605831142
                    ],
                    [
                        -133.08195105148368,
                        58.90522690677233
                    ],
                    [
                        -133.09054989442495,
                        58.90365948866423
                    ],
                    [
                        -133.0940905944594,
                        58.90881863846431
                    ],
                    [
                        -133.1035746124092,
                        58.909536940001146
                    ],
                    [
                        -133.10863275531602,
                        58.91045112035988
                    ],
                    [
                        -133.11103537319661,
                        58.913519977509225
                    ],
                    [
                        -133.1208542591017,
                        58.91267114738096
                    ],
                    [
                        -133.12995891633363,
                        58.91169173043926
                    ],
                    [
                        -133.13931648071062,
                        58.90738196601208
                    ],
                    [
                        -133.1429836343179,
                        58.90300635222263
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "6",
                "name": "Watsíx Héeni",
                "translation": "Caribou River",
                "location": "O'Donnel River",
                "audio": "map-6.mp3",
                "type": "river",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.43665418466946,
                    59.319088392566954
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "2",
                "name": "Koosawu Áa",
                "translation": "Narrow Lake",
                "location": "Surprise Lake",
                "audio": "map-2.mp3",
                "type": "lake",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#0042aa",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -133.42024340795692,
                            59.63049865924742
                        ],
                        [
                            -133.4032490151415,
                            59.631942535369454
                        ],
                        [
                            -133.37709046692154,
                            59.637128486498895
                        ],
                        [
                            -133.36152872978923,
                            59.63802918066746
                        ],
                        [
                            -133.3586634184234,
                            59.640366083175664
                        ],
                        [
                            -133.3480271122606,
                            59.64270298568386
                        ],
                        [
                            -133.33558517584083,
                            59.642735227713615
                        ],
                        [
                            -133.32526260163806,
                            59.645266637892334
                        ],
                        [
                            -133.26576247924638,
                            59.65550577774089
                        ],
                        [
                            -133.2564205960007,
                            59.658963590054896
                        ],
                        [
                            -133.24532232274564,
                            59.66686065834705
                        ],
                        [
                            -133.2292788709829,
                            59.669940378036756
                        ],
                        [
                            -133.21132104890734,
                            59.68002385321386
                        ],
                        [
                            -133.19936808644826,
                            59.69028463596858
                        ],
                        [
                            -133.19659902693147,
                            59.69698004389737
                        ],
                        [
                            -133.18128406442892,
                            59.70629209124232
                        ],
                        [
                            -133.1680912323199,
                            59.71722768442859
                        ],
                        [
                            -133.1713098604877,
                            59.72374627666764
                        ],
                        [
                            -133.16571754269313,
                            59.72841426189032
                        ],
                        [
                            -133.18110683805168,
                            59.7398877882971
                        ],
                        [
                            -133.2009016063917,
                            59.753580658768584
                        ],
                        [
                            -133.20745009571306,
                            59.76395054578106
                        ],
                        [
                            -133.204393673425,
                            59.785803379084285
                        ],
                        [
                            -133.21333286153452,
                            59.78571072061936
                        ],
                        [
                            -133.22122603718046,
                            59.76599066595499
                        ],
                        [
                            -133.22104251236067,
                            59.75069820105045
                        ],
                        [
                            -133.21626998974023,
                            59.74673835867897
                        ],
                        [
                            -133.2191905275,
                            59.74306450076159
                        ],
                        [
                            -133.2185069021955,
                            59.7397784185018
                        ],
                        [
                            -133.20716069738575,
                            59.73301397952994
                        ],
                        [
                            -133.2018735242,
                            59.727740584482646
                        ],
                        [
                            -133.19927299252967,
                            59.718984477443485
                        ],
                        [
                            -133.2036670774595,
                            59.712308717126376
                        ],
                        [
                            -133.21552569476557,
                            59.70418420652899
                        ],
                        [
                            -133.21683804001944,
                            59.70001659314532
                        ],
                        [
                            -133.22198844458126,
                            59.69962474019284
                        ],
                        [
                            -133.23535970115128,
                            59.68799649268466
                        ],
                        [
                            -133.26056699056852,
                            59.675585233706244
                        ],
                        [
                            -133.2785251624021,
                            59.66905963852309
                        ],
                        [
                            -133.29725094609717,
                            59.663503131999065
                        ],
                        [
                            -133.31469526005026,
                            59.66017223407458
                        ],
                        [
                            -133.31909017235606,
                            59.65470871237705
                        ],
                        [
                            -133.33018283255194,
                            59.652700561154404
                        ],
                        [
                            -133.34507584025903,
                            59.65469508304975
                        ],
                        [
                            -133.3507040615192,
                            59.65220925196225
                        ],
                        [
                            -133.36324078953376,
                            59.64933558758962
                        ],
                        [
                            -133.3720024935039,
                            59.64843615420433
                        ],
                        [
                            -133.3768603057272,
                            59.64624944193761
                        ],
                        [
                            -133.3804350352817,
                            59.64711246271236
                        ],
                        [
                            -133.38489785287453,
                            59.64476573894011
                        ],
                        [
                            -133.38354584289235,
                            59.64128426726326
                        ],
                        [
                            -133.3897783446558,
                            59.640368058578005
                        ],
                        [
                            -133.39409181676527,
                            59.638093957615936
                        ],
                        [
                            -133.4003871037039,
                            59.63878485402201
                        ],
                        [
                            -133.40281476182673,
                            59.63784666296158
                        ],
                        [
                            -133.40702547326993,
                            59.63882119176464
                        ],
                        [
                            -133.40900084812958,
                            59.63775642649833
                        ],
                        [
                            -133.41639479125053,
                            59.63437193748473
                        ],
                        [
                            -133.42024340795692,
                            59.63049865924742
                        ]
                    ]
                ],
                "type": "Polygon"
            },
            "id": 36
        },
        {
            "type": "Feature",
            "properties": {
                "id": "13",
                "name": "G̲at.áayi",
                "translation": "Sockeye Salmon Lake",
                "location": "Kuthai Lake",
                "audio": "map-13.mp3",
                "type": "lake",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#0042aa",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -133.29146846528477,
                            59.23647187088034
                        ],
                        [
                            -133.29155933077118,
                            59.23447328706274
                        ],
                        [
                            -133.28910596263518,
                            59.23349719193706
                        ],
                        [
                            -133.28183672371296,
                            59.23331126588894
                        ],
                        [
                            -133.27611219806184,
                            59.23261403418249
                        ],
                        [
                            -133.27238671311417,
                            59.23419440559124
                        ],
                        [
                            -133.26657132197644,
                            59.233822560082416
                        ],
                        [
                            -133.26366362640766,
                            59.23210272187333
                        ],
                        [
                            -133.26084679632532,
                            59.23015036801115
                        ],
                        [
                            -133.2560309255393,
                            59.23033631128834
                        ],
                        [
                            -133.24748956980585,
                            59.22898819956643
                        ],
                        [
                            -133.24049292734333,
                            59.22894171200522
                        ],
                        [
                            -133.23776696274763,
                            59.23015036801115
                        ],
                        [
                            -133.23304195744822,
                            59.230661709584666
                        ],
                        [
                            -133.23495013266526,
                            59.232892928575154
                        ],
                        [
                            -133.2380395592071,
                            59.232567551562084
                        ],
                        [
                            -133.24203764061423,
                            59.23368311697146
                        ],
                        [
                            -133.24530879812934,
                            59.23307885690349
                        ],
                        [
                            -133.2493977450229,
                            59.23363663580787
                        ],
                        [
                            -133.25430448129532,
                            59.23359015458095
                        ],
                        [
                            -133.2568487149182,
                            59.23438032682549
                        ],
                        [
                            -133.2631184334885,
                            59.23647187088034
                        ],
                        [
                            -133.2676617078148,
                            59.23768026009634
                        ],
                        [
                            -133.27138719276246,
                            59.23823796375558
                        ],
                        [
                            -133.27674825646744,
                            59.23888860649774
                        ],
                        [
                            -133.2784747007114,
                            59.238377388245425
                        ],
                        [
                            -133.2831997060108,
                            59.23819148879903
                        ],
                        [
                            -133.2846535537953,
                            59.23884213242789
                        ],
                        [
                            -133.28674345998536,
                            59.238098538695766
                        ],
                        [
                            -133.2898328865272,
                            59.23800558833932
                        ],
                        [
                            -133.29146846528477,
                            59.23647187088034
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "13",
                "name": "G̲at.áayi",
                "translation": "Sockeye Salmon Lake",
                "location": "Kuthai Lake",
                "audio": "map-13.mp3",
                "type": "lake",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.2699787777214,
                    59.23556555087063
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "28",
                "name": "L'óox̲'u Áa",
                "translation": "Silty Lake",
                "location": "Sloko Lake",
                "audio": "map-28.mp3",
                "type": "lake",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#0042aa",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -133.84091663314413,
                            59.072661446006265
                        ],
                        [
                            -133.83928381766222,
                            59.07272578670441
                        ],
                        [
                            -133.83719161593956,
                            59.073552920086854
                        ],
                        [
                            -133.8341337826528,
                            59.074297323090576
                        ],
                        [
                            -133.83115642212226,
                            59.074380032319795
                        ],
                        [
                            -133.8272134265681,
                            59.07388376664025
                        ],
                        [
                            -133.82574038016855,
                            59.07546578973677
                        ],
                        [
                            -133.82292395477276,
                            59.078112335140275
                        ],
                        [
                            -133.82002706008006,
                            59.07943553131659
                        ],
                        [
                            -133.81809579695147,
                            59.08096541330079
                        ],
                        [
                            -133.81560124874395,
                            59.081999078734725
                        ],
                        [
                            -133.816164533823,
                            59.08266060827248
                        ],
                        [
                            -133.81544031014988,
                            59.084479748743775
                        ],
                        [
                            -133.8119801303779,
                            59.08658817725032
                        ],
                        [
                            -133.80932464357616,
                            59.08774569074845
                        ],
                        [
                            -133.80779572693277,
                            59.089481887772706
                        ],
                        [
                            -133.80489883224007,
                            59.09146600535337
                        ],
                        [
                            -133.80055349020088,
                            59.092706020565316
                        ],
                        [
                            -133.79797847269606,
                            59.09179668045965
                        ],
                        [
                            -133.7955643937855,
                            59.09233402070845
                        ],
                        [
                            -133.79403547714213,
                            59.091300666605065
                        ],
                        [
                            -133.79033388947917,
                            59.090184609207455
                        ],
                        [
                            -133.78574713954882,
                            59.089977927926896
                        ],
                        [
                            -133.78140179750966,
                            59.089936591521194
                        ],
                        [
                            -133.77705645547047,
                            59.089357876613406
                        ],
                        [
                            -133.77520566163898,
                            59.08873781409295
                        ],
                        [
                            -133.77174461149878,
                            59.08923426310554
                        ],
                        [
                            -133.76973287907327,
                            59.088696874298876
                        ],
                        [
                            -133.76699692297439,
                            59.088738212198024
                        ],
                        [
                            -133.7634562739055,
                            59.088490184054905
                        ],
                        [
                            -133.7612836028859,
                            59.09076370825008
                        ],
                        [
                            -133.76023750202467,
                            59.091879746807024
                        ],
                        [
                            -133.75862811608414,
                            59.09163174137666
                        ],
                        [
                            -133.75726013803484,
                            59.091797078529225
                        ],
                        [
                            -133.75323667318375,
                            59.092458419170896
                        ],
                        [
                            -133.7496155548177,
                            59.092706418624346
                        ],
                        [
                            -133.7474428837981,
                            59.0915904069638
                        ],
                        [
                            -133.74358035754108,
                            59.092169084209246
                        ],
                        [
                            -133.74060299355125,
                            59.091797078529225
                        ],
                        [
                            -133.74020064706622,
                            59.092458419170896
                        ],
                        [
                            -133.73867173042285,
                            59.09241708575459
                        ],
                        [
                            -133.7376256295616,
                            59.0929957490535
                        ],
                        [
                            -133.73513108135393,
                            59.093078414442516
                        ],
                        [
                            -133.7325560638491,
                            59.09229308520605
                        ],
                        [
                            -133.72901541478018,
                            59.09225175159011
                        ],
                        [
                            -133.7255552350084,
                            59.09340907400457
                        ],
                        [
                            -133.72169270875116,
                            59.09543429427072
                        ],
                        [
                            -133.71927862984057,
                            59.09601290666845
                        ],
                        [
                            -133.71702548952408,
                            59.09721142986788
                        ],
                        [
                            -133.71517469569238,
                            59.098409911182955
                        ],
                        [
                            -133.7109902922473,
                            59.099484376063856
                        ],
                        [
                            -133.70863110580734,
                            59.1004827224933
                        ],
                        [
                            -133.70356154009505,
                            59.10172241173785
                        ],
                        [
                            -133.69889432086777,
                            59.1031273387004
                        ],
                        [
                            -133.6930200621852,
                            59.10457352691387
                        ],
                        [
                            -133.69052551397752,
                            59.10564779866954
                        ],
                        [
                            -133.68618017193836,
                            59.106845985147004
                        ],
                        [
                            -133.68368562373067,
                            59.10800281511354
                        ],
                        [
                            -133.6784551194243,
                            59.109283545612755
                        ],
                        [
                            -133.67475353176135,
                            59.10990323674034
                        ],
                        [
                            -133.66968396604904,
                            59.11052291666485
                        ],
                        [
                            -133.66590190908894,
                            59.11081209679605
                        ],
                        [
                            -133.6663847248711,
                            59.11312545001428
                        ],
                        [
                            -133.66759176432637,
                            59.11378637939845
                        ],
                        [
                            -133.669523027455,
                            59.113166758474364
                        ],
                        [
                            -133.66992537394003,
                            59.11349722436137
                        ],
                        [
                            -133.6674308257323,
                            59.11419945379103
                        ],
                        [
                            -133.66863786518763,
                            59.11560386948551
                        ],
                        [
                            -133.6698449046431,
                            59.116347360377205
                        ],
                        [
                            -133.67290273792986,
                            59.11601692197249
                        ],
                        [
                            -133.6742707159792,
                            59.116512578384516
                        ],
                        [
                            -133.67571916332565,
                            59.11490166883266
                        ],
                        [
                            -133.67893793520648,
                            59.113992917217104
                        ],
                        [
                            -133.67998403606768,
                            59.11424076095665
                        ],
                        [
                            -133.6822371763844,
                            59.11362114824752
                        ],
                        [
                            -133.68457078599803,
                            59.113951609753144
                        ],
                        [
                            -133.68947941311632,
                            59.11279498054256
                        ],
                        [
                            -133.69189349202685,
                            59.11262974461195
                        ],
                        [
                            -133.6929395928883,
                            59.11163831229811
                        ],
                        [
                            -133.69688258844224,
                            59.11110127448714
                        ],
                        [
                            -133.70010136032306,
                            59.10986192434686
                        ],
                        [
                            -133.70364200939218,
                            59.10982061190401
                        ],
                        [
                            -133.7143506188731,
                            59.10746572744736
                        ],
                        [
                            -133.71523578114048,
                            59.10635019603137
                        ],
                        [
                            -133.717247513566,
                            59.10639151265721
                        ],
                        [
                            -133.72271942576336,
                            59.105275946285445
                        ],
                        [
                            -133.72803039936684,
                            59.103375268119066
                        ],
                        [
                            -133.73036400898047,
                            59.10242488952218
                        ],
                        [
                            -133.73535310539583,
                            59.102548853441846
                        ],
                        [
                            -133.73857187727666,
                            59.10135051677801
                        ],
                        [
                            -133.7434805043949,
                            59.09982154367779
                        ],
                        [
                            -133.75023992534483,
                            59.09874708936141
                        ],
                        [
                            -133.75667746910648,
                            59.09787923629801
                        ],
                        [
                            -133.76311501286833,
                            59.097672601379145
                        ],
                        [
                            -133.77062906001277,
                            59.09748237369797
                        ],
                        [
                            -133.7755376871312,
                            59.099052776566566
                        ],
                        [
                            -133.77851505112105,
                            59.099961924305944
                        ],
                        [
                            -133.78165335370474,
                            59.09979662651003
                        ],
                        [
                            -133.78366508613027,
                            59.1004578129137
                        ],
                        [
                            -133.7888151211397,
                            59.10041648913685
                        ],
                        [
                            -133.78865418254566,
                            59.10116030949851
                        ],
                        [
                            -133.790022160595,
                            59.10169750305843
                        ],
                        [
                            -133.79267764739672,
                            59.10136692340211
                        ],
                        [
                            -133.79275811669385,
                            59.10074707795627
                        ],
                        [
                            -133.7954940727925,
                            59.10107766358851
                        ],
                        [
                            -133.79903472186143,
                            59.10116030949851
                        ],
                        [
                            -133.80225349374226,
                            59.10132560072125
                        ],
                        [
                            -133.804667572653,
                            59.10074707795627
                        ],
                        [
                            -133.8052308577321,
                            59.09967265263995
                        ],
                        [
                            -133.8071621208607,
                            59.09983795103352
                        ],
                        [
                            -133.80901291469218,
                            59.100251193531705
                        ],
                        [
                            -133.8107027699296,
                            59.09971397731286
                        ],
                        [
                            -133.81271450235516,
                            59.09942470355523
                        ],
                        [
                            -133.81271450235516,
                            59.09855686764044
                        ],
                        [
                            -133.81416294970163,
                            59.09843288928826
                        ],
                        [
                            -133.81488717337476,
                            59.09789564458305
                        ],
                        [
                            -133.8172207829884,
                            59.097110425642995
                        ],
                        [
                            -133.8173012522853,
                            59.0962425311605
                        ],
                        [
                            -133.81943133083945,
                            59.095111085444586
                        ],
                        [
                            -133.82297197990837,
                            59.09349916995913
                        ],
                        [
                            -133.82458136584887,
                            59.09312717870415
                        ],
                        [
                            -133.82458136584887,
                            59.091556504484146
                        ],
                        [
                            -133.82546652811607,
                            59.09122582706257
                        ],
                        [
                            -133.82393761147267,
                            59.089779075861884
                        ],
                        [
                            -133.82265010272027,
                            59.08779486070782
                        ],
                        [
                            -133.82200634834408,
                            59.08552113975165
                        ],
                        [
                            -133.8228110413143,
                            59.08370205449239
                        ],
                        [
                            -133.8255469974132,
                            59.08204825692994
                        ],
                        [
                            -133.82602981319513,
                            59.08163479508525
                        ],
                        [
                            -133.82763919913563,
                            59.07952606218464
                        ],
                        [
                            -133.83117984820456,
                            59.0788644722187
                        ],
                        [
                            -133.83230641836292,
                            59.07799611603332
                        ],
                        [
                            -133.83504237446158,
                            59.077210441504945
                        ],
                        [
                            -133.83689316829307,
                            59.07613527879428
                        ],
                        [
                            -133.83624941391685,
                            59.07539091564951
                        ],
                        [
                            -133.83689316829307,
                            59.07435705116802
                        ],
                        [
                            -133.8395486550948,
                            59.07468789119008
                        ],
                        [
                            -133.8410775717382,
                            59.074398406345324
                        ],
                        [
                            -133.84091663314413,
                            59.072661446006265
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "28",
                "name": "L'óox̲'u Áa",
                "translation": "Silty Lake",
                "location": "Sloko Lake",
                "audio": "map-28.mp3",
                "type": "lake",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.76350507937372,
                    59.09483006075922
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "19",
                "name": "A Shuyee",
                "translation": "The Foot of [the Glacier]",
                "location": "Head of Llewellyn Inlet",
                "audio": "map-19.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.98426146225032,
                    59.17502315685391
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "6",
                "name": "Watsíx Héeni",
                "translation": "Caribou River",
                "location": "O'Donnel River",
                "audio": "map-6.mp3",
                "type": "river",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -133.60221259409286,
                        59.37715588024045
                    ],
                    [
                        -133.59933599866895,
                        59.37463222569181
                    ],
                    [
                        -133.59685893038713,
                        59.374184460917206
                    ],
                    [
                        -133.59422205124847,
                        59.37410304850445
                    ],
                    [
                        -133.593263186107,
                        59.37288183887142
                    ],
                    [
                        -133.59222441553734,
                        59.37239334270711
                    ],
                    [
                        -133.5903066852547,
                        59.37316679171809
                    ],
                    [
                        -133.5900669689692,
                        59.372515467407766
                    ],
                    [
                        -133.58830904954357,
                        59.37194554837686
                    ],
                    [
                        -133.58830904954357,
                        59.37080568158987
                    ],
                    [
                        -133.59038659068307,
                        59.36999146757822
                    ],
                    [
                        -133.59254403725103,
                        59.36995075636446
                    ],
                    [
                        -133.59174498296665,
                        59.369462217991156
                    ],
                    [
                        -133.58950763097005,
                        59.369462217991156
                    ],
                    [
                        -133.5872702789737,
                        59.36929937030334
                    ],
                    [
                        -133.58623150840384,
                        59.368851535131114
                    ],
                    [
                        -133.58663103554613,
                        59.367792992133474
                    ],
                    [
                        -133.58439368354976,
                        59.367792992133474
                    ],
                    [
                        -133.58239604783844,
                        59.36803727421807
                    ],
                    [
                        -133.5813572772688,
                        59.36820012796397
                    ],
                    [
                        -133.58263576412392,
                        59.366937990986315
                    ],
                    [
                        -133.58111756098333,
                        59.366530840010114
                    ],
                    [
                        -133.5789601144154,
                        59.366978705815455
                    ],
                    [
                        -133.57856058727327,
                        59.36596082043488
                    ],
                    [
                        -133.57967926327137,
                        59.36482075255597
                    ],
                    [
                        -133.575843802706,
                        59.36437285826648
                    ],
                    [
                        -133.57400597785193,
                        59.365146490144724
                    ],
                    [
                        -133.57176862585536,
                        59.36416926799839
                    ],
                    [
                        -133.56969108471583,
                        59.362988420356174
                    ],
                    [
                        -133.56857240871756,
                        59.361685368372605
                    ],
                    [
                        -133.57017051728656,
                        59.36070804654935
                    ],
                    [
                        -133.5731270181389,
                        59.35989359019672
                    ],
                    [
                        -133.57176862585536,
                        59.35920128692939
                    ],
                    [
                        -133.5704102335718,
                        59.3593234591205
                    ],
                    [
                        -133.56929155757354,
                        59.35871259376776
                    ],
                    [
                        -133.5675336381479,
                        59.35740937760184
                    ],
                    [
                        -133.5684125978607,
                        59.35639120515506
                    ],
                    [
                        -133.570250422715,
                        59.35541373087193
                    ],
                    [
                        -133.5680130707184,
                        59.354843524540996
                    ],
                    [
                        -133.5668144892917,
                        59.35545445952883
                    ],
                    [
                        -133.5640177992962,
                        59.35586174341242
                    ],
                    [
                        -133.56521638072292,
                        59.3534179668093
                    ],
                    [
                        -133.56569581490464,
                        59.35101474973615
                    ],
                    [
                        -133.56194025976725,
                        59.349606230056935
                    ],
                    [
                        -133.559383286057,
                        59.34911739874471
                    ],
                    [
                        -133.5585842317726,
                        59.348547086651564
                    ],
                    [
                        -133.55490852915318,
                        59.34834337332251
                    ],
                    [
                        -133.54995439258974,
                        59.347324928934285
                    ],
                    [
                        -133.54851609487756,
                        59.34679532578005
                    ],
                    [
                        -133.54763713516473,
                        59.345899055471136
                    ],
                    [
                        -133.54995439258974,
                        59.34488053776775
                    ],
                    [
                        -133.54955486544742,
                        59.34308787241318
                    ],
                    [
                        -133.54555959402524,
                        59.3425582031758
                    ],
                    [
                        -133.5423633768874,
                        59.34243597064045
                    ],
                    [
                        -133.5396465923203,
                        59.34202852567765
                    ],
                    [
                        -133.53820829460835,
                        59.34202852567765
                    ],
                    [
                        -133.53796857832288,
                        59.34088765377783
                    ],
                    [
                        -133.5353316991842,
                        59.34039869694652
                    ],
                    [
                        -133.5340532123291,
                        59.34060242981522
                    ],
                    [
                        -133.5285397377665,
                        59.339339266330256
                    ],
                    [
                        -133.52606266948467,
                        59.339176274071775
                    ],
                    [
                        -133.524144939202,
                        59.33889103573716
                    ],
                    [
                        -133.52350569577442,
                        59.33689430032675
                    ],
                    [
                        -133.52062910035028,
                        59.33730180688215
                    ],
                    [
                        -133.51847165378234,
                        59.33709805421552
                    ],
                    [
                        -133.51727307235564,
                        59.33758705856172
                    ],
                    [
                        -133.5123452057697,
                        59.3367991236224
                    ],
                    [
                        -133.51066719177228,
                        59.33606559744871
                    ],
                    [
                        -133.5091489886319,
                        59.33533205543628
                    ],
                    [
                        -133.5075508800629,
                        59.335454313538094
                    ],
                    [
                        -133.50675182577848,
                        59.33504678482029
                    ],
                    [
                        -133.5049140009242,
                        59.33618785291105
                    ],
                    [
                        -133.5045144739308,
                        59.33463925124019
                    ],
                    [
                        -133.50395513593168,
                        59.33324773164986
                    ],
                    [
                        -133.501318256793,
                        59.33373679142187
                    ],
                    [
                        -133.49956033736717,
                        59.333206976351306
                    ],
                    [
                        -133.50059910793703,
                        59.33479639677745
                    ],
                    [
                        -133.49820194508362,
                        59.33459262908366
                    ],
                    [
                        -133.49764260708446,
                        59.33341075235609
                    ],
                    [
                        -133.49532534965968,
                        59.33259564100274
                    ],
                    [
                        -133.49372724109068,
                        59.33120990682079
                    ],
                    [
                        -133.49220903795032,
                        59.329946394044214
                    ],
                    [
                        -133.48941234795458,
                        59.32790837120476
                    ],
                    [
                        -133.48541707653237,
                        59.328723595008285
                    ],
                    [
                        -133.48397877882041,
                        59.32803065602184
                    ],
                    [
                        -133.48405868424885,
                        59.3259109902296
                    ],
                    [
                        -133.48134189968175,
                        59.3257071692434
                    ],
                    [
                        -133.47942416939907,
                        59.32697083965198
                    ],
                    [
                        -133.47734662825954,
                        59.32750075196847
                    ],
                    [
                        -133.47654757397513,
                        59.32697083965198
                    ],
                    [
                        -133.47470974912085,
                        59.327663800249525
                    ],
                    [
                        -133.47305694687378,
                        59.32654925834214
                    ],
                    [
                        -133.4695411080223,
                        59.325937803200645
                    ],
                    [
                        -133.468422432024,
                        59.325407866512336
                    ],
                    [
                        -133.46714394516889,
                        59.32606009510923
                    ],
                    [
                        -133.46171037603472,
                        59.324714859912916
                    ],
                    [
                        -133.46091132175013,
                        59.32548939577143
                    ],
                    [
                        -133.45979264575203,
                        59.324347968345165
                    ],
                    [
                        -133.46035198375097,
                        59.32349187261795
                    ],
                    [
                        -133.4561969014719,
                        59.323002665377885
                    ],
                    [
                        -133.45276096804884,
                        59.322472682921614
                    ],
                    [
                        -133.45300068433409,
                        59.32194269220139
                    ],
                    [
                        -133.44972456176788,
                        59.31998573179902
                    ],
                    [
                        -133.44836616948433,
                        59.319781875277585
                    ],
                    [
                        -133.44708768262922,
                        59.32027112887465
                    ],
                    [
                        -133.44261297863625,
                        59.31921107051426
                    ],
                    [
                        -133.44144635592826,
                        59.31718870062525
                    ],
                    [
                        -133.43840994964728,
                        59.318167275422894
                    ],
                    [
                        -133.4349740162242,
                        59.32024665334117
                    ],
                    [
                        -133.4329763805131,
                        59.3185342337099
                    ],
                    [
                        -133.42850167652017,
                        59.31763721757076
                    ],
                    [
                        -133.42570498652447,
                        59.31718870062525
                    ],
                    [
                        -133.42514564852553,
                        59.318248822051146
                    ],
                    [
                        -133.42658394623749,
                        59.31906427757579
                    ],
                    [
                        -133.42570498652447,
                        59.31934968238778
                    ],
                    [
                        -133.42426668881248,
                        59.31886041552838
                    ],
                    [
                        -133.42282839110055,
                        59.319186594217086
                    ],
                    [
                        -133.42123028253172,
                        59.3185342337099
                    ],
                    [
                        -133.41803406539393,
                        59.318982732903606
                    ],
                    [
                        -133.41459813197065,
                        59.318656552258375
                    ],
                    [
                        -133.4127603071164,
                        59.318656552258375
                    ],
                    [
                        -133.4117215365467,
                        59.319716627911305
                    ],
                    [
                        -133.40781344601865,
                        59.319485338690356
                    ],
                    [
                        -133.4040578908817,
                        59.31981151138325
                    ],
                    [
                        -133.40086167374392,
                        59.319363023124026
                    ],
                    [
                        -133.39790517289157,
                        59.31862912048177
                    ],
                    [
                        -133.3964668751796,
                        59.31960765381703
                    ],
                    [
                        -133.39382999604092,
                        59.32017845192112
                    ],
                    [
                        -133.39207207661508,
                        59.31956688215689
                    ],
                    [
                        -133.38615907491018,
                        59.32046384737913
                    ],
                    [
                        -133.38480068262663,
                        59.32099386115817
                    ],
                    [
                        -133.38264323605847,
                        59.32062692942205
                    ],
                    [
                        -133.3800063569198,
                        59.320708470150095
                    ],
                    [
                        -133.3783283429226,
                        59.320708470150095
                    ],
                    [
                        -133.37609099092603,
                        59.32050461796345
                    ],
                    [
                        -133.37529193664162,
                        59.32087155101942
                    ],
                    [
                        -133.373214332865,
                        59.320015370455764
                    ],
                    [
                        -133.37145641343935,
                        59.32079001341174
                    ],
                    [
                        -133.36897934515753,
                        59.32025999645421
                    ],
                    [
                        -133.36594293887654,
                        59.31985228547879
                    ],
                    [
                        -133.36674199316096,
                        59.321034633835495
                    ],
                    [
                        -133.36394530316545,
                        59.321523869402114
                    ],
                    [
                        -133.3617878565975,
                        59.32050462069262
                    ],
                    [
                        -133.3602696534569,
                        59.31964842815708
                    ],
                    [
                        -133.35883135574494,
                        59.320137683675256
                    ],
                    [
                        -133.35907107203042,
                        59.32115694338739
                    ],
                    [
                        -133.35739305803298,
                        59.320423079475574
                    ],
                    [
                        -133.35843182860285,
                        59.32160540797858
                    ],
                    [
                        -133.35643419289175,
                        59.321890791455985
                    ],
                    [
                        -133.35411693546675,
                        59.321320022105056
                    ],
                    [
                        -133.35467627346588,
                        59.32070847287929
                    ],
                    [
                        -133.3500417586161,
                        59.32058616171409
                    ],
                    [
                        -133.34860346090395,
                        59.321850022534306
                    ],
                    [
                        -133.34277036462746,
                        59.32054539122757
                    ],
                    [
                        -133.33909471491913,
                        59.32168694635942
                    ],
                    [
                        -133.33885499863368,
                        59.32274692751136
                    ],
                    [
                        -133.33637793035206,
                        59.32327690569147
                    ],
                    [
                        -133.33589849778133,
                        59.323806875607744
                    ],
                    [
                        -133.3325424697867,
                        59.32515214674322
                    ],
                    [
                        -133.3326223752151,
                        59.326048964584714
                    ],
                    [
                        -133.3294261580773,
                        59.32772024377701
                    ],
                    [
                        -133.32806776579375,
                        59.32947296048039
                    ],
                    [
                        -133.32678927893863,
                        59.33016587005585
                    ],
                    [
                        -133.32678921129659,
                        59.33204077768943
                    ],
                    [
                        -133.32447195387158,
                        59.33305968046545
                    ],
                    [
                        -133.32343318330194,
                        59.335219653297656
                    ],
                    [
                        -133.32143554759082,
                        59.33631996401786
                    ],
                    [
                        -133.32231450730364,
                        59.33803148763516
                    ],
                    [
                        -133.31975753359342,
                        59.338357482356656
                    ],
                    [
                        -133.3190383847374,
                        59.33949843924472
                    ],
                    [
                        -133.31799961416755,
                        59.340435625161206
                    ],
                    [
                        -133.31847904673828,
                        59.34129129406659
                    ],
                    [
                        -133.3162416947419,
                        59.341617257503486
                    ],
                    [
                        -133.3149632078868,
                        59.34206545212035
                    ],
                    [
                        -133.3089703007533,
                        59.34051711836668
                    ],
                    [
                        -133.3089703007533,
                        59.339253951709395
                    ],
                    [
                        -133.308730584468,
                        59.3378684891012
                    ],
                    [
                        -133.3090502061819,
                        59.33705348469863
                    ],
                    [
                        -133.30729228675602,
                        59.33750173953982
                    ],
                    [
                        -133.3060137999009,
                        59.33847972957085
                    ],
                    [
                        -133.30537455647337,
                        59.3374202390996
                    ],
                    [
                        -133.3027376773347,
                        59.337134986018754
                    ],
                    [
                        -133.30177881219322,
                        59.33852047854418
                    ],
                    [
                        -133.30289748819152,
                        59.33937619569684
                    ],
                    [
                        -133.3016989067648,
                        59.33982441988772
                    ],
                    [
                        -133.30209843390713,
                        59.34031338498667
                    ],
                    [
                        -133.3016989067648,
                        59.34214694141525
                    ],
                    [
                        -133.30297739361993,
                        59.34210619679246
                    ],
                    [
                        -133.30225824476395,
                        59.34365445810491
                    ],
                    [
                        -133.30034051448126,
                        59.34434707847248
                    ],
                    [
                        -133.30097975790886,
                        59.34544709355171
                    ],
                    [
                        -133.3028175827631,
                        59.34666929073859
                    ],
                    [
                        -133.30233815019238,
                        59.34728037283614
                    ],
                    [
                        -133.3016989067648,
                        59.34785070620566
                    ],
                    [
                        -133.29906202762618,
                        59.34801365683728
                    ],
                    [
                        -133.29818306791333,
                        59.34956164883624
                    ],
                    [
                        -133.29802325705649,
                        59.35086516630736
                    ],
                    [
                        -133.29426770191952,
                        59.35249449276975
                    ],
                    [
                        -133.29402798563407,
                        59.35298327546096
                    ],
                    [
                        -133.2953064724892,
                        59.353879358783104
                    ],
                    [
                        -133.2949868507755,
                        59.354897606575264
                    ],
                    [
                        -133.2953064509323,
                        59.355793659070684
                    ],
                    [
                        -133.29762370835707,
                        59.3573412963643
                    ],
                    [
                        -133.29426768036242,
                        59.35713766390967
                    ],
                    [
                        -133.29171070665217,
                        59.35901103641055
                    ],
                    [
                        -133.28955326008423,
                        59.35929610577011
                    ],
                    [
                        -133.2880350569436,
                        59.36222810878448
                    ],
                    [
                        -133.29051212522546,
                        59.36304250911243
                    ],
                    [
                        -133.28723600265923,
                        59.36426407295906
                    ],
                    [
                        -133.28683647551713,
                        59.36605562039057
                    ],
                    [
                        -133.2847589343774,
                        59.365282009240275
                    ],
                    [
                        -133.2831608258086,
                        59.36674778383758
                    ],
                    [
                        -133.28332063666542,
                        59.3683763480154
                    ],
                    [
                        -133.27980479781394,
                        59.36923131291624
                    ],
                    [
                        -133.28100337924064,
                        59.37085975788605
                    ],
                    [
                        -133.28300101495176,
                        59.372569540978475
                    ],
                    [
                        -133.2811631900975,
                        59.37309874208984
                    ],
                    [
                        -133.2761291481054,
                        59.37326157154001
                    ],
                    [
                        -133.27461094496505,
                        59.37476770690063
                    ],
                    [
                        -133.27285302553918,
                        59.37602955260695
                    ],
                    [
                        -133.27588943182016,
                        59.376395886115944
                    ],
                    [
                        -133.2705357681142,
                        59.377209946413814
                    ],
                    [
                        -133.27197406582636,
                        59.378675205712
                    ],
                    [
                        -133.27125491697038,
                        59.38009970267137
                    ],
                    [
                        -133.27213147258726,
                        59.38059052772937
                    ],
                    [
                        -133.2696544043054,
                        59.38193355061881
                    ],
                    [
                        -133.27085298573212,
                        59.383357910711595
                    ],
                    [
                        -133.26885535002103,
                        59.38482290438657
                    ],
                    [
                        -133.2670974305952,
                        59.38527052871743
                    ],
                    [
                        -133.2651797003125,
                        59.387223729367236
                    ],
                    [
                        -133.2601456583204,
                        59.38860717841317
                    ],
                    [
                        -133.26086480717638,
                        59.389990571003295
                    ],
                    [
                        -133.25998584746355,
                        59.39165870462986
                    ],
                    [
                        -133.25870736060844,
                        59.39206555403703
                    ],
                    [
                        -133.2573489683249,
                        59.39316402304442
                    ],
                    [
                        -133.2548719000431,
                        59.39377426822111
                    ],
                    [
                        -133.2552714271854,
                        59.39483200050057
                    ],
                    [
                        -133.25407284575869,
                        59.3955235768353
                    ],
                    [
                        -133.2518354853693,
                        59.39568630889812
                    ],
                    [
                        -133.25199529622614,
                        59.39674398150578
                    ],
                    [
                        -133.25055699851418,
                        59.397720265383924
                    ],
                    [
                        -133.2478402139471,
                        59.397842298891305
                    ],
                    [
                        -133.25007756594346,
                        59.39987612598165
                    ],
                    [
                        -133.24975794422977,
                        59.40138107944128
                    ],
                    [
                        -133.24512342938,
                        59.401747139094994
                    ],
                    [
                        -133.24224683395587,
                        59.40211319479391
                    ],
                    [
                        -133.2414477796715,
                        59.403170666819875
                    ],
                    [
                        -133.24480380766613,
                        59.40426877591335
                    ],
                    [
                        -133.24344541538258,
                        59.40540751812085
                    ],
                    [
                        -133.2404090091016,
                        59.40589553877811
                    ],
                    [
                        -133.2387309951044,
                        59.40764422173686
                    ],
                    [
                        -133.24056881995864,
                        59.408782850485665
                    ],
                    [
                        -133.23897071138964,
                        59.40939281442857
                    ],
                    [
                        -133.2344960073967,
                        59.410612709362454
                    ],
                    [
                        -133.23537496710975,
                        59.412035864589626
                    ],
                    [
                        -133.23361704768388,
                        59.41309302690513
                    ],
                    [
                        -133.22866291112024,
                        59.41536987981567
                    ],
                    [
                        -133.226984897123,
                        59.415857756952164
                    ],
                    [
                        -133.22490735598348,
                        59.41687414507808
                    ],
                    [
                        -133.2241882071273,
                        59.415532506308836
                    ],
                    [
                        -133.22211066598777,
                        59.416061037018096
                    ],
                    [
                        -133.2206723682758,
                        59.417443309103504
                    ],
                    [
                        -133.2185948271363,
                        59.418093770560546
                    ],
                    [
                        -133.21755601126563,
                        59.42069552679217
                    ],
                    [
                        -133.21667705155278,
                        59.42114267743443
                    ],
                    [
                        -133.2119626312744,
                        59.42175241879286
                    ],
                    [
                        -133.2087664141368,
                        59.423418989143556
                    ],
                    [
                        -133.2064491567118,
                        59.424719669973854
                    ],
                    [
                        -133.20788745442377,
                        59.425532570115365
                    ],
                    [
                        -133.20628934585494,
                        59.42658931111529
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "3",
                "name": "Koosawu Héen",
                "translation": "Narrow Creek",
                "location": "Pine Creek",
                "audio": "map-3.mp3",
                "type": "river",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -133.42014492538334,
                        59.63049150839589
                    ],
                    [
                        -133.4229181798766,
                        59.628397659467026
                    ],
                    [
                        -133.42515251940142,
                        59.62784073416729
                    ],
                    [
                        -133.42855545218356,
                        59.62714478339109
                    ],
                    [
                        -133.42941294247456,
                        59.626095758533296
                    ],
                    [
                        -133.43130154446314,
                        59.625220524119925
                    ],
                    [
                        -133.43582994867043,
                        59.62450302049357
                    ],
                    [
                        -133.4371594792765,
                        59.62229308795028
                    ],
                    [
                        -133.44658949619316,
                        59.62055581397824
                    ],
                    [
                        -133.45836160450492,
                        59.61471775181311
                    ],
                    [
                        -133.4654938873236,
                        59.61274459814547
                    ],
                    [
                        -133.4688263608586,
                        59.61309774966746
                    ],
                    [
                        -133.47617851590175,
                        59.611067881133245
                    ],
                    [
                        -133.4742465644391,
                        59.61028946672984
                    ],
                    [
                        -133.48422765318364,
                        59.605500806391326
                    ],
                    [
                        -133.48740271208754,
                        59.60365758644953
                    ],
                    [
                        -133.49366409628763,
                        59.60159999555404
                    ],
                    [
                        -133.51313431222886,
                        59.6025634362846
                    ],
                    [
                        -133.5243218085741,
                        59.60038101737505
                    ],
                    [
                        -133.52905520049356,
                        59.59971293334294
                    ],
                    [
                        -133.53221517167347,
                        59.59843237613747
                    ],
                    [
                        -133.5410031478053,
                        59.59722836415485
                    ],
                    [
                        -133.55178815795227,
                        59.594738054979814
                    ],
                    [
                        -133.57134228008726,
                        59.5875703783278
                    ],
                    [
                        -133.59182592502842,
                        59.58691803282839
                    ],
                    [
                        -133.5977430145868,
                        59.59138816499827
                    ],
                    [
                        -133.60104026979087,
                        59.592822581730346
                    ],
                    [
                        -133.60433752499495,
                        59.59346056569355
                    ],
                    [
                        -133.61329232560027,
                        59.59101982273802
                    ],
                    [
                        -133.61625342222843,
                        59.59162493506963
                    ],
                    [
                        -133.61728019877938,
                        59.59128648443304
                    ],
                    [
                        -133.62055760605764,
                        59.591892768514754
                    ],
                    [
                        -133.62312871043295,
                        59.59130513524987
                    ],
                    [
                        -133.6248693423314,
                        59.59020864007772
                    ],
                    [
                        -133.62983671477105,
                        59.590803312010195
                    ],
                    [
                        -133.6396308146655,
                        59.58709286174799
                    ],
                    [
                        -133.64369739213598,
                        59.583865742072625
                    ],
                    [
                        -133.65215591529733,
                        59.582606250693985
                    ],
                    [
                        -133.65633094884083,
                        59.581280381807574
                    ],
                    [
                        -133.65853693680694,
                        59.58037627974746
                    ],
                    [
                        -133.6568091077777,
                        59.57905167365895
                    ],
                    [
                        -133.6558502573586,
                        59.576733710068055
                    ],
                    [
                        -133.66033483360758,
                        59.57511352604616
                    ],
                    [
                        -133.65843500407394,
                        59.574436698688785
                    ],
                    [
                        -133.65865537945663,
                        59.57127366346319
                    ],
                    [
                        -133.6646050663202,
                        59.56592816784223
                    ],
                    [
                        -133.6631043252139,
                        59.56338214383362
                    ],
                    [
                        -133.66724957091253,
                        59.56100576852475
                    ]
                ],
                "type": "LineString"
            },
            "id": 43
        },
        {
            "type": "Feature",
            "properties": {
                "id": "3",
                "name": "Koosawu Héen",
                "translation": "Narrow Creek",
                "location": "Pine Creek",
                "audio": "map-3.mp3",
                "type": "river",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.50262301103584,
                    59.60203169875592
                ],
                "type": "Point"
            },
            "id": 44
        },
        {
            "type": "Feature",
            "properties": {
                "id": "2",
                "name": "Koosawu Áa",
                "translation": "Narrow Lake",
                "location": "Surprise Lake",
                "audio": "map-2.mp3",
                "type": "lake",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.21847616217246,
                    59.690224582979255
                ],
                "type": "Point"
            },
            "id": 45
        },
        {
            "type": "Feature",
            "properties": {
                "id": "9",
                "name": "Dashdané Áayi",
                "translation": "Suckerfish Lake",
                "location": "Dixie Lake",
                "audio": "map-9.mp3",
                "type": "lake",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#0042aa",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -133.31784469303028,
                            59.32519441712813
                        ],
                        [
                            -133.31676941429026,
                            59.32429456308088
                        ],
                        [
                            -133.31560499248243,
                            59.3240487431388
                        ],
                        [
                            -133.3144405706749,
                            59.32476571300063
                        ],
                        [
                            -133.3125935567728,
                            59.324868136031995
                        ],
                        [
                            -133.3102245606813,
                            59.324868136031995
                        ],
                        [
                            -133.30918059630181,
                            59.324519896466256
                        ],
                        [
                            -133.30769495468516,
                            59.323372729130085
                        ],
                        [
                            -133.30624946554437,
                            59.32275815927218
                        ],
                        [
                            -133.30552672097423,
                            59.322000174476614
                        ],
                        [
                            -133.30544641529127,
                            59.3209348698104
                        ],
                        [
                            -133.3040812311029,
                            59.32050464122045
                        ],
                        [
                            -133.30351909643696,
                            59.31978758146988
                        ],
                        [
                            -133.30263574196212,
                            59.31956221670998
                        ],
                        [
                            -133.30119025282158,
                            59.31835341658993
                        ],
                        [
                            -133.29922278149115,
                            59.316857723048855
                        ],
                        [
                            -133.29841973196858,
                            59.31587421746298
                        ],
                        [
                            -133.29560906216557,
                            59.314255470340214
                        ],
                        [
                            -133.29504692749964,
                            59.31347680450119
                        ],
                        [
                            -133.29500677502352,
                            59.312308772306665
                        ],
                        [
                            -133.2936415908352,
                            59.310997249743764
                        ],
                        [
                            -133.29231653856073,
                            59.308968384389146
                        ],
                        [
                            -133.2914331840859,
                            59.30882492498108
                        ],
                        [
                            -133.29091120189625,
                            59.30833305955923
                        ],
                        [
                            -133.2899475424692,
                            59.30796415582421
                        ],
                        [
                            -133.28930510285105,
                            59.307328812233635
                        ],
                        [
                            -133.288341443424,
                            59.307021383654615
                        ],
                        [
                            -133.28733763152087,
                            59.30712386015645
                        ],
                        [
                            -133.2863338196177,
                            59.3071033648807
                        ],
                        [
                            -133.28577168495175,
                            59.30618106469299
                        ],
                        [
                            -133.28464741562027,
                            59.305791641548524
                        ],
                        [
                            -133.28336253638415,
                            59.305238243094294
                        ],
                        [
                            -133.28255948686154,
                            59.304582351785314
                        ],
                        [
                            -133.28187689476727,
                            59.30398793936149
                        ],
                        [
                            -133.2807526254358,
                            59.3034140140613
                        ],
                        [
                            -133.28027079572206,
                            59.30251211188133
                        ],
                        [
                            -133.2799094234371,
                            59.30177417412895
                        ],
                        [
                            -133.27894576400982,
                            59.30158968718956
                        ],
                        [
                            -133.27790179963057,
                            59.30091322651839
                        ],
                        [
                            -133.2768979877274,
                            59.30017525408084
                        ],
                        [
                            -133.2760949382048,
                            59.3008312303715
                        ],
                        [
                            -133.2748502114448,
                            59.3011387149034
                        ],
                        [
                            -133.27316380744736,
                            59.30046224526441
                        ],
                        [
                            -133.27155770840216,
                            59.300011258031844
                        ],
                        [
                            -133.27023267668991,
                            59.30097472349897
                        ],
                        [
                            -133.27099557373643,
                            59.30191766327903
                        ],
                        [
                            -133.27159786087827,
                            59.30267609587446
                        ],
                        [
                            -133.27119633611696,
                            59.30357799370654
                        ],
                        [
                            -133.27187892821124,
                            59.30421340736575
                        ],
                        [
                            -133.27396685697,
                            59.30454135815856
                        ],
                        [
                            -133.27544368243804,
                            59.30542122009447
                        ],
                        [
                            -133.27725054386403,
                            59.30642552374724
                        ],
                        [
                            -133.27897710033756,
                            59.30734781730604
                        ],
                        [
                            -133.28014152214513,
                            59.30796266578366
                        ],
                        [
                            -133.28126579147684,
                            59.30800365528694
                        ],
                        [
                            -133.28206884099944,
                            59.30827008585433
                        ],
                        [
                            -133.28255067071296,
                            59.308065139449354
                        ],
                        [
                            -133.28327341528333,
                            59.308372558593476
                        ],
                        [
                            -133.28415676975817,
                            59.30792167623099
                        ],
                        [
                            -133.2851204291852,
                            59.308024150020174
                        ],
                        [
                            -133.28624469851667,
                            59.30808563414553
                        ],
                        [
                            -133.2859636311839,
                            59.30855700874611
                        ],
                        [
                            -133.28532119156574,
                            59.30863898627061
                        ],
                        [
                            -133.2843575321387,
                            59.30888491765856
                        ],
                        [
                            -133.28516058166133,
                            59.309376775098826
                        ],
                        [
                            -133.28580302127926,
                            59.30915134132175
                        ],
                        [
                            -133.28773034013358,
                            59.309786650864936
                        ],
                        [
                            -133.28897506689358,
                            59.309438256778776
                        ],
                        [
                            -133.2898985738445,
                            59.30980714452366
                        ],
                        [
                            -133.29122360555675,
                            59.31109822011899
                        ],
                        [
                            -133.29174558774636,
                            59.31204087927483
                        ],
                        [
                            -133.29114330060455,
                            59.312737610550926
                        ],
                        [
                            -133.2922274174599,
                            59.31378268069727
                        ],
                        [
                            -133.29295016203028,
                            59.31474575611685
                        ],
                        [
                            -133.2933516867916,
                            59.31585223462534
                        ],
                        [
                            -133.29307061525893,
                            59.31695869919042
                        ],
                        [
                            -133.29242817564077,
                            59.31777826301084
                        ],
                        [
                            -133.2925887855452,
                            59.318433899841665
                        ],
                        [
                            -133.29250848059297,
                            59.31937635563361
                        ],
                        [
                            -133.29198649840333,
                            59.32019586117042
                        ],
                        [
                            -133.2922274132602,
                            59.32107680758767
                        ],
                        [
                            -133.29282970040205,
                            59.32206016266767
                        ],
                        [
                            -133.29359259744854,
                            59.322818146125485
                        ],
                        [
                            -133.29467671430393,
                            59.32324834543357
                        ],
                        [
                            -133.29580098363562,
                            59.32335077303773
                        ],
                        [
                            -133.29704571039565,
                            59.32369902458271
                        ],
                        [
                            -133.29821013220342,
                            59.32427260758405
                        ],
                        [
                            -133.29873211439306,
                            59.324805211703705
                        ],
                        [
                            -133.29909348667826,
                            59.3252968388675
                        ],
                        [
                            -133.29909348667826,
                            59.325870394903546
                        ],
                        [
                            -133.29837074210786,
                            59.32638249032772
                        ],
                        [
                            -133.29776845496602,
                            59.32703796121055
                        ],
                        [
                            -133.29716616782395,
                            59.32773438517131
                        ],
                        [
                            -133.2969252529673,
                            59.32818500483475
                        ],
                        [
                            -133.29564037373117,
                            59.32910670826206
                        ],
                        [
                            -133.29511839154134,
                            59.330192238014774
                        ],
                        [
                            -133.29624266087302,
                            59.331134367802576
                        ],
                        [
                            -133.2978086074421,
                            59.331564461846995
                        ],
                        [
                            -133.299374554011,
                            59.33193310955187
                        ],
                        [
                            -133.3012215679131,
                            59.33209695169225
                        ],
                        [
                            -133.30302842933884,
                            59.33199455044698
                        ],
                        [
                            -133.3044739184794,
                            59.331564461846995
                        ],
                        [
                            -133.305558035335,
                            59.33174878619943
                        ],
                        [
                            -133.30664215219036,
                            59.3317897470308
                        ],
                        [
                            -133.30824825123557,
                            59.331523500744055
                        ],
                        [
                            -133.30868992847297,
                            59.331031963656415
                        ],
                        [
                            -133.30901114828205,
                            59.330192238014774
                        ],
                        [
                            -133.30945282551946,
                            59.32972116332485
                        ],
                        [
                            -133.31057709485094,
                            59.32935249162057
                        ],
                        [
                            -133.3107778572317,
                            59.3289838159163
                        ],
                        [
                            -133.31101877208835,
                            59.328451277281744
                        ],
                        [
                            -133.3119422790395,
                            59.32810307443134
                        ],
                        [
                            -133.31330746322783,
                            59.32820548740463
                        ],
                        [
                            -133.3143514276071,
                            59.32818500483475
                        ],
                        [
                            -133.31483325732063,
                            59.32795969574997
                        ],
                        [
                            -133.3147529523684,
                            59.32759100493428
                        ],
                        [
                            -133.31523478208192,
                            59.326996994650784
                        ],
                        [
                            -133.315556001891,
                            59.32634152297777
                        ],
                        [
                            -133.31684088112715,
                            59.32576797489267
                        ],
                        [
                            -133.31784469303028,
                            59.32519441712813
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "9",
                "name": "Dashdané Áayi",
                "translation": "Suckerfish Lake",
                "location": "Dixie Lake",
                "audio": "map-9.mp3",
                "type": "lake",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.29831936108317,
                    59.3209246232351
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "10",
                "name": "S'eek X'áat'i",
                "translation": "Black Bear Island",
                "location": "Griffith Island",
                "audio": "map-10.mp3",
                "type": "island",
                "marker-color": "#4f7a28",
                "stroke": "#4f7a28",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#4f7a28",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -133.85769588124234,
                            59.30650196358988
                        ],
                        [
                            -133.8576894720365,
                            59.30571014209846
                        ],
                        [
                            -133.85816890460723,
                            59.30497594472027
                        ],
                        [
                            -133.85721003946577,
                            59.30430291653428
                        ],
                        [
                            -133.85633107975298,
                            59.30403778056208
                        ],
                        [
                            -133.85477292389817,
                            59.30350750241752
                        ],
                        [
                            -133.85361429518588,
                            59.30297721600604
                        ],
                        [
                            -133.85225590290213,
                            59.302650881797774
                        ],
                        [
                            -133.8510173687614,
                            59.30230414827059
                        ],
                        [
                            -133.84989869276313,
                            59.301753446581756
                        ],
                        [
                            -133.84878001676486,
                            59.3014882907369
                        ],
                        [
                            -133.84802091519464,
                            59.30116194224456
                        ],
                        [
                            -133.84810082062307,
                            59.30044804400217
                        ],
                        [
                            -133.84881996947905,
                            59.30044804400217
                        ],
                        [
                            -133.85041807804805,
                            59.30034605731595
                        ],
                        [
                            -133.85161665947456,
                            59.30030526255567
                        ],
                        [
                            -133.85245566647336,
                            59.29995850511989
                        ],
                        [
                            -133.85173651761738,
                            59.299448561293644
                        ],
                        [
                            -133.85157670676034,
                            59.29869383040284
                        ],
                        [
                            -133.85273533547286,
                            59.29824506355723
                        ],
                        [
                            -133.8536542479001,
                            59.29838785365047
                        ],
                        [
                            -133.85465306575554,
                            59.29930577565267
                        ],
                        [
                            -133.85553202546836,
                            59.299774926223364
                        ],
                        [
                            -133.85509254561205,
                            59.29997890271406
                        ],
                        [
                            -133.85529230918308,
                            59.30036645467774
                        ],
                        [
                            -133.8561712688959,
                            59.30044804400217
                        ],
                        [
                            -133.85689041775208,
                            59.301019163794365
                        ],
                        [
                            -133.85784928289334,
                            59.30116194224456
                        ],
                        [
                            -133.85908781703426,
                            59.30099876682431
                        ],
                        [
                            -133.85912776974845,
                            59.30038685202729
                        ],
                        [
                            -133.8572899448942,
                            59.30028486515761
                        ],
                        [
                            -133.85669065418085,
                            59.29985651696646
                        ],
                        [
                            -133.85701027589474,
                            59.29942816338192
                        ],
                        [
                            -133.85808899917862,
                            59.298959008029215
                        ],
                        [
                            -133.85904786432005,
                            59.29820426627796
                        ],
                        [
                            -133.85892800617742,
                            59.297510705041645
                        ],
                        [
                            -133.85824881003566,
                            59.297102721239014
                        ],
                        [
                            -133.85800909375018,
                            59.29657233498196
                        ],
                        [
                            -133.85693037046633,
                            59.29644993697957
                        ],
                        [
                            -133.85545212003993,
                            59.29598074055676
                        ],
                        [
                            -133.85445330218448,
                            59.295348335141
                        ],
                        [
                            -133.85377410604272,
                            59.29453231078526
                        ],
                        [
                            -133.85313486261515,
                            59.294144692360334
                        ],
                        [
                            -133.85193628118844,
                            59.29357345716008
                        ],
                        [
                            -133.85013840904838,
                            59.29292060518853
                        ],
                        [
                            -133.8493793074782,
                            59.292798194049794
                        ],
                        [
                            -133.84754148262394,
                            59.292594174506036
                        ],
                        [
                            -133.8467024756253,
                            59.29196170614759
                        ],
                        [
                            -133.84658261748268,
                            59.29124761484135
                        ],
                        [
                            -133.8454239887702,
                            59.29106398894052
                        ],
                        [
                            -133.84410554920083,
                            59.2912272120123
                        ],
                        [
                            -133.84234762977502,
                            59.29110479478166
                        ],
                        [
                            -133.84054975763496,
                            59.291390434301235
                        ],
                        [
                            -133.83943108163686,
                            59.2912272120123
                        ],
                        [
                            -133.83775306763945,
                            59.29169647398285
                        ],
                        [
                            -133.83619491178484,
                            59.292614576515575
                        ],
                        [
                            -133.8359551954994,
                            59.29338984380905
                        ],
                        [
                            -133.83643462807012,
                            59.293716266859036
                        ],
                        [
                            -133.83607505364202,
                            59.2950831293723
                        ],
                        [
                            -133.83607505364202,
                            59.295715539716696
                        ],
                        [
                            -133.83663439164116,
                            59.29604194045737
                        ],
                        [
                            -133.83747339864,
                            59.296755931160476
                        ],
                        [
                            -133.83867198006647,
                            59.297061922589904
                        ],
                        [
                            -133.84082942663463,
                            59.29708232192081
                        ],
                        [
                            -133.8423076770608,
                            59.297449507782886
                        ],
                        [
                            -133.8434663057733,
                            59.29749030596764
                        ],
                        [
                            -133.84558379962704,
                            59.29761270022746
                        ],
                        [
                            -133.84658261748268,
                            59.29797988036606
                        ],
                        [
                            -133.8470620500532,
                            59.29865303366128
                        ],
                        [
                            -133.84682233376793,
                            59.29940776545752
                        ],
                        [
                            -133.8465426647685,
                            59.30004009542316
                        ],
                        [
                            -133.84530413062757,
                            59.29999930029598
                        ],
                        [
                            -133.84394573834402,
                            59.299672937519176
                        ],
                        [
                            -133.84210791348977,
                            59.29930577565267
                        ],
                        [
                            -133.84054975763496,
                            59.29879582204225
                        ],
                        [
                            -133.83871193278088,
                            59.29818386761977
                        ],
                        [
                            -133.8379927839247,
                            59.29818386761977
                        ],
                        [
                            -133.83775306763945,
                            59.29785748742711
                        ],
                        [
                            -133.83539585750026,
                            59.29683752914397
                        ],
                        [
                            -133.83415732335953,
                            59.29644993697957
                        ],
                        [
                            -133.83343817450336,
                            59.295960340565415
                        ],
                        [
                            -133.83215968764824,
                            59.29559313863206
                        ],
                        [
                            -133.82984243022344,
                            59.29545033680961
                        ],
                        [
                            -133.82880365965357,
                            59.29491992479427
                        ],
                        [
                            -133.82792469994075,
                            59.29447110816977
                        ],
                        [
                            -133.82680602394248,
                            59.29402228562492
                        ],
                        [
                            -133.82600696965807,
                            59.293430646861765
                        ],
                        [
                            -133.8257273006586,
                            59.29381827342013
                        ],
                        [
                            -133.825339377531,
                            59.29505367860406
                        ],
                        [
                            -133.8263781481009,
                            59.29544128667439
                        ],
                        [
                            -133.82757672952738,
                            59.29584929039905
                        ],
                        [
                            -133.8268176279572,
                            59.29589009050267
                        ],
                        [
                            -133.82513961395998,
                            59.29595129056588
                        ],
                        [
                            -133.8241008433901,
                            59.29662448399759
                        ],
                        [
                            -133.82438051238958,
                            59.29760365066744
                        ],
                        [
                            -133.8256989519589,
                            59.29821561552626
                        ],
                        [
                            -133.82609847910123,
                            59.298827569377664
                        ],
                        [
                            -133.82641810081512,
                            59.29978627494765
                        ],
                        [
                            -133.82729706052794,
                            59.30043899519589
                        ],
                        [
                            -133.82729706052794,
                            59.30101011514034
                        ],
                        [
                            -133.82801620938392,
                            59.30172400158705
                        ],
                        [
                            -133.8292547435248,
                            59.30215232626466
                        ],
                        [
                            -133.82945450709587,
                            59.30292737625703
                        ],
                        [
                            -133.82981408152398,
                            59.30349845442501
                        ],
                        [
                            -133.83021360866604,
                            59.30404912786432
                        ],
                        [
                            -133.83121242652172,
                            59.304477423264615
                        ],
                        [
                            -133.83177176452085,
                            59.30478334524855
                        ],
                        [
                            -133.83169185909244,
                            59.30563991216616
                        ],
                        [
                            -133.8327705823763,
                            59.306129369291426
                        ],
                        [
                            -133.8339292110888,
                            59.306394488959825
                        ],
                        [
                            -133.83420888008848,
                            59.30680236133722
                        ],
                        [
                            -133.8327705823763,
                            59.30684314830614
                        ],
                        [
                            -133.83141219009275,
                            59.30708786909122
                        ],
                        [
                            -133.83189162266348,
                            59.3074957331533
                        ],
                        [
                            -133.83297034594756,
                            59.30798516357126
                        ],
                        [
                            -133.8338892583746,
                            59.308372624323965
                        ],
                        [
                            -133.83528760337236,
                            59.30873968833342
                        ],
                        [
                            -133.83652613751326,
                            59.30867851127337
                        ],
                        [
                            -133.83616656308538,
                            59.30806673462283
                        ],
                        [
                            -133.83532755608655,
                            59.307740449242715
                        ],
                        [
                            -133.8351677452297,
                            59.307108262410594
                        ],
                        [
                            -133.8362464685138,
                            59.30680236133722
                        ],
                        [
                            -133.83808429336807,
                            59.307149049012224
                        ],
                        [
                            -133.83920296936614,
                            59.30731219493052
                        ],
                        [
                            -133.8402816926502,
                            59.306863541771804
                        ],
                        [
                            -133.84080107793514,
                            59.30598661169063
                        ],
                        [
                            -133.84048145622126,
                            59.3052524202804
                        ],
                        [
                            -133.8410807469346,
                            59.30466097678533
                        ],
                        [
                            -133.84283866636048,
                            59.30425307872727
                        ],
                        [
                            -133.8448762547858,
                            59.303967547176626
                        ],
                        [
                            -133.84683393778266,
                            59.30449781814926
                        ],
                        [
                            -133.84835214092305,
                            59.30494650251498
                        ],
                        [
                            -133.84947081692133,
                            59.305028080854726
                        ],
                        [
                            -133.85046843553914,
                            59.30529269908203
                        ],
                        [
                            -133.85199422963197,
                            59.30529269908203
                        ],
                        [
                            -133.8525563642978,
                            59.30564113563776
                        ],
                        [
                            -133.8542427682951,
                            59.30617403170103
                        ],
                        [
                            -133.8561700871493,
                            59.3066454327969
                        ],
                        [
                            -133.85769588124234,
                            59.30650196358988
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "10",
                "name": "S'eek X'áat'i",
                "translation": "Black Bear Island",
                "location": "Griffith Island",
                "audio": "map-10.mp3",
                "type": "island",
                "marker-color": "#4f7a28",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.84040992313575,
                    59.3011517438035
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "77",
                "name": "Sit'.áa",
                "translation": "Glacier Lake",
                "location": "Mendenhall Lake",
                "audio": "map-77.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#0042aa",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -134.5539095194615,
                            58.43928899941821
                        ],
                        [
                            -134.55362055226766,
                            58.437604922653904
                        ],
                        [
                            -134.55320141444992,
                            58.43736293421629
                        ],
                        [
                            -134.55354115398234,
                            58.43706692444454
                        ],
                        [
                            -134.5534509999518,
                            58.43642088008582
                        ],
                        [
                            -134.55240477564237,
                            58.43525800665532
                        ],
                        [
                            -134.55123682314442,
                            58.43313255197914
                        ],
                        [
                            -134.55059429611129,
                            58.431232144028485
                        ],
                        [
                            -134.5504093311643,
                            58.429420441078335
                        ],
                        [
                            -134.55122745119635,
                            58.429746885849966
                        ],
                        [
                            -134.55086523854703,
                            58.428695828503116
                        ],
                        [
                            -134.55121951516819,
                            58.42799488826704
                        ],
                        [
                            -134.55054285582077,
                            58.42613034010728
                        ],
                        [
                            -134.5518072368769,
                            58.425925136150056
                        ],
                        [
                            -134.55243942740495,
                            58.425447377005625
                        ],
                        [
                            -134.55381199017964,
                            58.42543231570224
                        ],
                        [
                            -134.55722493214307,
                            58.42770377765352
                        ],
                        [
                            -134.5603990443496,
                            58.43120069215141
                        ],
                        [
                            -134.56276480847745,
                            58.432092062135524
                        ],
                        [
                            -134.56417525357756,
                            58.43340853160399
                        ],
                        [
                            -134.56343210858677,
                            58.43381714880081
                        ],
                        [
                            -134.56515210882998,
                            58.434574486133755
                        ],
                        [
                            -134.5658087308043,
                            58.434170547373114
                        ],
                        [
                            -134.56577879715582,
                            58.432918375376026
                        ],
                        [
                            -134.5659504360615,
                            58.43243653981504
                        ],
                        [
                            -134.56535781974515,
                            58.43175465838222
                        ],
                        [
                            -134.566417586827,
                            58.4320037754475
                        ],
                        [
                            -134.56641309041268,
                            58.43147911072913
                        ],
                        [
                            -134.5677960547952,
                            58.431666892664836
                        ],
                        [
                            -134.56946561488667,
                            58.431279528114345
                        ],
                        [
                            -134.5693888957054,
                            58.42956025670148
                        ],
                        [
                            -134.57082535872286,
                            58.429534016242094
                        ],
                        [
                            -134.57216628983707,
                            58.42900761838682
                        ],
                        [
                            -134.57410352862232,
                            58.42945491120935
                        ],
                        [
                            -134.5758988126407,
                            58.42777865753712
                        ],
                        [
                            -134.57936339667643,
                            58.42705209009256
                        ],
                        [
                            -134.58201595953847,
                            58.42720085201151
                        ],
                        [
                            -134.5837059464058,
                            58.42653213002315
                        ],
                        [
                            -134.5841540185371,
                            58.425963447347335
                        ],
                        [
                            -134.58543229041052,
                            58.42570143931941
                        ],
                        [
                            -134.5856483025394,
                            58.42542752844662
                        ],
                        [
                            -134.5867622535245,
                            58.424368304069944
                        ],
                        [
                            -134.58629226106225,
                            58.42328843822353
                        ],
                        [
                            -134.58710811576174,
                            58.422335795324756
                        ],
                        [
                            -134.58706418333668,
                            58.421608267858886
                        ],
                        [
                            -134.58625982058447,
                            58.42179081578509
                        ],
                        [
                            -134.58540386267026,
                            58.421356808083466
                        ],
                        [
                            -134.58387918143652,
                            58.42199837634152
                        ],
                        [
                            -134.58336589145375,
                            58.42157358662152
                        ],
                        [
                            -134.58547366105603,
                            58.420673316331204
                        ],
                        [
                            -134.5861484521166,
                            58.41977304604089
                        ],
                        [
                            -134.58766027015758,
                            58.419154485092804
                        ],
                        [
                            -134.58957875755587,
                            58.41722859486832
                        ],
                        [
                            -134.59135100724131,
                            58.415964385449286
                        ],
                        [
                            -134.5919981750185,
                            58.414858079435106
                        ],
                        [
                            -134.58949431396158,
                            58.41321302084546
                        ],
                        [
                            -134.58890109096023,
                            58.41324424279625
                        ],
                        [
                            -134.58764321085602,
                            58.41353185928341
                        ],
                        [
                            -134.58874637110273,
                            58.41430135025797
                        ],
                        [
                            -134.5862188495041,
                            58.415108244286415
                        ],
                        [
                            -134.5858633123866,
                            58.414607655821584
                        ],
                        [
                            -134.5870601686887,
                            58.413906918520894
                        ],
                        [
                            -134.58556498267927,
                            58.414276992864586
                        ],
                        [
                            -134.5848340518916,
                            58.41418422756044
                        ],
                        [
                            -134.58321974696122,
                            58.41443008480499
                        ],
                        [
                            -134.58306369967244,
                            58.41392208669524
                        ],
                        [
                            -134.58161576247278,
                            58.41388480336332
                        ],
                        [
                            -134.58222176118278,
                            58.41304691340471
                        ],
                        [
                            -134.58105059926933,
                            58.41269725006586
                        ],
                        [
                            -134.58015868466887,
                            58.412276362523116
                        ],
                        [
                            -134.57900405733605,
                            58.41245595092212
                        ],
                        [
                            -134.5776032301501,
                            58.412219919751564
                        ],
                        [
                            -134.577420434725,
                            58.411283319763925
                        ],
                        [
                            -134.57929856412596,
                            58.4105487029248
                        ],
                        [
                            -134.58086621484296,
                            58.41011433748963
                        ],
                        [
                            -134.5809384094577,
                            58.40962205637952
                        ],
                        [
                            -134.58049684725083,
                            58.409150720305014
                        ],
                        [
                            -134.5808731405399,
                            58.40862729526589
                        ],
                        [
                            -134.58058071050942,
                            58.40775354588632
                        ],
                        [
                            -134.58208681232466,
                            58.40848940985583
                        ],
                        [
                            -134.58354514818825,
                            58.4095755902803
                        ],
                        [
                            -134.58353406038097,
                            58.40928758068091
                        ],
                        [
                            -134.58524254682354,
                            58.410075510859855
                        ],
                        [
                            -134.58571848889432,
                            58.40967696199398
                        ],
                        [
                            -134.5849533749507,
                            58.40917742481748
                        ],
                        [
                            -134.58485698432665,
                            58.40842766566777
                        ],
                        [
                            -134.58373276702616,
                            58.408554628937495
                        ],
                        [
                            -134.58178544578553,
                            58.40721681495905
                        ],
                        [
                            -134.57959375340502,
                            58.40603491435793
                        ],
                        [
                            -134.57773642268432,
                            58.40565378861086
                        ],
                        [
                            -134.57674717337952,
                            58.40601469610267
                        ],
                        [
                            -134.57839192123487,
                            58.40706557338811
                        ],
                        [
                            -134.57829596035992,
                            58.40818771645401
                        ],
                        [
                            -134.57784481709174,
                            58.409185283984705
                        ],
                        [
                            -134.57829118350884,
                            58.409613689073865
                        ],
                        [
                            -134.57690171539457,
                            58.410883842659786
                        ],
                        [
                            -134.57604571620524,
                            58.41165970809182
                        ],
                        [
                            -134.57642608302996,
                            58.41221031258368
                        ],
                        [
                            -134.57770634390675,
                            58.4131025422336
                        ],
                        [
                            -134.57853461699952,
                            58.413661286075076
                        ],
                        [
                            -134.57850280938675,
                            58.41474091423228
                        ],
                        [
                            -134.57681794216603,
                            58.41589704891389
                        ],
                        [
                            -134.57428229201503,
                            58.41868287516519
                        ],
                        [
                            -134.56991189010628,
                            58.419714203339566
                        ],
                        [
                            -134.56683895933833,
                            58.419438641607584
                        ],
                        [
                            -134.56468431094714,
                            58.41999056402414
                        ],
                        [
                            -134.56091923454704,
                            58.419407914538255
                        ],
                        [
                            -134.55849803424928,
                            58.41958534892288
                        ],
                        [
                            -134.55646509210501,
                            58.41879018286957
                        ],
                        [
                            -134.54929854325005,
                            58.41733343901831
                        ],
                        [
                            -134.54877366561948,
                            58.41761465529589
                        ],
                        [
                            -134.54791992131933,
                            58.4174455824338
                        ],
                        [
                            -134.54685115684262,
                            58.41789587157348
                        ],
                        [
                            -134.54690498821043,
                            58.418582144549774
                        ],
                        [
                            -134.54636747593122,
                            58.41920680075679
                        ],
                        [
                            -134.5471738397544,
                            58.420084821187885
                        ],
                        [
                            -134.5463782746681,
                            58.42122101126449
                        ],
                        [
                            -134.5445461902668,
                            58.42146107463333
                        ],
                        [
                            -134.54429255065114,
                            58.42047102940592
                        ],
                        [
                            -134.54323258537403,
                            58.42007214578388
                        ],
                        [
                            -134.54278647208054,
                            58.41970378270839
                        ],
                        [
                            -134.5417490533021,
                            58.419785836729844
                        ],
                        [
                            -134.54091664119264,
                            58.420165094939165
                        ],
                        [
                            -134.54290050278553,
                            58.42197457989138
                        ],
                        [
                            -134.54266999940154,
                            58.42228188586405
                        ],
                        [
                            -134.54117378959373,
                            58.422474152634415
                        ],
                        [
                            -134.54082995171188,
                            58.422664812759706
                        ],
                        [
                            -134.54031775885562,
                            58.42258753102247
                        ],
                        [
                            -134.53999001350044,
                            58.4221987104489
                        ],
                        [
                            -134.53949177916306,
                            58.42236696755279
                        ],
                        [
                            -134.5398875023382,
                            58.422888822909016
                        ],
                        [
                            -134.54049137451258,
                            58.42293716352802
                        ],
                        [
                            -134.54067389572145,
                            58.423536618659426
                        ],
                        [
                            -134.53954712456573,
                            58.424111484235624
                        ],
                        [
                            -134.53984352611243,
                            58.42472407707692
                        ],
                        [
                            -134.53939465249363,
                            58.42575569278823
                        ],
                        [
                            -134.53845372641302,
                            58.42620897985564
                        ],
                        [
                            -134.53879528454644,
                            58.42666071547713
                        ],
                        [
                            -134.53772774711427,
                            58.42719998916127
                        ],
                        [
                            -134.53917004016893,
                            58.42999364404682
                        ],
                        [
                            -134.54005070705367,
                            58.430448244742806
                        ],
                        [
                            -134.54016711871557,
                            58.43082782388015
                        ],
                        [
                            -134.5401902343408,
                            58.43124414970603
                        ],
                        [
                            -134.53983122235465,
                            58.432585712630186
                        ],
                        [
                            -134.53954309194535,
                            58.43451864281741
                        ],
                        [
                            -134.5405498430063,
                            58.435662540764696
                        ],
                        [
                            -134.54277279278662,
                            58.436759551644386
                        ],
                        [
                            -134.5439687746125,
                            58.43683145208783
                        ],
                        [
                            -134.54494411566836,
                            58.43755823921235
                        ],
                        [
                            -134.54588556273438,
                            58.44050926116645
                        ],
                        [
                            -134.54645181817025,
                            58.44142233725806
                        ],
                        [
                            -134.5463528182768,
                            58.44191639268697
                        ],
                        [
                            -134.54943198814615,
                            58.4439882964336
                        ],
                        [
                            -134.55101933903208,
                            58.44437431848241
                        ],
                        [
                            -134.54987849344403,
                            58.44512976214
                        ],
                        [
                            -134.54997956259197,
                            58.445585245359894
                        ],
                        [
                            -134.55071363993662,
                            58.445310091043964
                        ],
                        [
                            -134.5524985682119,
                            58.44524740813901
                        ],
                        [
                            -134.5530607459826,
                            58.44405554453719
                        ],
                        [
                            -134.55307912213559,
                            58.44317217486178
                        ],
                        [
                            -134.5518463954757,
                            58.44163056417318
                        ],
                        [
                            -134.552137585222,
                            58.44118476478846
                        ],
                        [
                            -134.55190105248445,
                            58.440286876116275
                        ],
                        [
                            -134.55316799870573,
                            58.4400754381685
                        ],
                        [
                            -134.5531566314722,
                            58.43946971349037
                        ],
                        [
                            -134.5539095194615,
                            58.43928899941821
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "76",
                "name": "Kichx̲aak'",
                "translation": "Wing Island*",
                "location": "Shelter Island",
                "audio": "map-76.mp3",
                "type": "land",
                "marker-color": "#4f7a28",
                "stroke": "#4f7a28",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#669c35",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -134.87125147816664,
                            58.44510652017004
                        ],
                        [
                            -134.8751794529365,
                            58.44955937194163
                        ],
                        [
                            -134.8763570272346,
                            58.45296577566299
                        ],
                        [
                            -134.8801015762495,
                            58.45371511945898
                        ],
                        [
                            -134.8817215915748,
                            58.456165648952854
                        ],
                        [
                            -134.884962544042,
                            58.45858281300711
                        ],
                        [
                            -134.88894426704746,
                            58.462107172909015
                        ],
                        [
                            -134.89173081501258,
                            58.461538416055234
                        ],
                        [
                            -134.89427271196587,
                            58.46201931062399
                        ],
                        [
                            -134.89454192048913,
                            58.46605878311301
                        ],
                        [
                            -134.89705843362765,
                            58.4715347668139
                        ],
                        [
                            -134.90410370479154,
                            58.48035215521776
                        ],
                        [
                            -134.90498434305314,
                            58.48043519405437
                        ],
                        [
                            -134.90588257097002,
                            58.48234177901735
                        ],
                        [
                            -134.9078023194616,
                            58.48222322262791
                        ],
                        [
                            -134.91095911283833,
                            58.48524564972987
                        ],
                        [
                            -134.91232816267365,
                            58.48544847439972
                        ],
                        [
                            -134.91340181146055,
                            58.48678865666628
                        ],
                        [
                            -134.91527944351031,
                            58.4866934727637
                        ],
                        [
                            -134.91752695353412,
                            58.48727495479223
                        ],
                        [
                            -134.9175834378921,
                            58.487761252918176
                        ],
                        [
                            -134.919397396387,
                            58.48829382795678
                        ],
                        [
                            -134.91967019665626,
                            58.48681256760449
                        ],
                        [
                            -134.92294646489248,
                            58.48678870082553
                        ],
                        [
                            -134.92304794718436,
                            58.487360942270925
                        ],
                        [
                            -134.92544827845637,
                            58.48778276268554
                        ],
                        [
                            -134.9258759271998,
                            58.48685127744639
                        ],
                        [
                            -134.9247402023511,
                            58.48603695168967
                        ],
                        [
                            -134.92187838028968,
                            58.4851743156982
                        ],
                        [
                            -134.92071656451498,
                            58.48364244422859
                        ],
                        [
                            -134.92050969342668,
                            58.482437427914235
                        ],
                        [
                            -134.92083778218594,
                            58.48204440805139
                        ],
                        [
                            -134.92007713163005,
                            58.48118725035498
                        ],
                        [
                            -134.9210222024261,
                            58.48119374930953
                        ],
                        [
                            -134.92135080993197,
                            58.479266580520175
                        ],
                        [
                            -134.92483497859797,
                            58.47932088949625
                        ],
                        [
                            -134.92213274847842,
                            58.4705730196379
                        ],
                        [
                            -134.91797104985656,
                            58.466711905930254
                        ],
                        [
                            -134.91758273427112,
                            58.46403870688889
                        ],
                        [
                            -134.91425000747805,
                            58.462337234553885
                        ],
                        [
                            -134.91268412378446,
                            58.45910684962581
                        ],
                        [
                            -134.90569109380436,
                            58.453913299663355
                        ],
                        [
                            -134.89726362797936,
                            58.45315792207856
                        ],
                        [
                            -134.8961577884407,
                            58.45254019329232
                        ],
                        [
                            -134.89613591551986,
                            58.449962370196374
                        ],
                        [
                            -134.8976304455648,
                            58.44778196095402
                        ],
                        [
                            -134.8944999656466,
                            58.444350456673725
                        ],
                        [
                            -134.89515925063893,
                            58.44107124928679
                        ],
                        [
                            -134.89849174016058,
                            58.43977421820756
                        ],
                        [
                            -134.89679875655645,
                            58.438608572382435
                        ],
                        [
                            -134.8972835930763,
                            58.436738990667855
                        ],
                        [
                            -134.88915865032754,
                            58.43115228459938
                        ],
                        [
                            -134.88778227564063,
                            58.42934629963804
                        ],
                        [
                            -134.8854714781586,
                            58.42834059311022
                        ],
                        [
                            -134.88319313458985,
                            58.424391467750326
                        ],
                        [
                            -134.88317161925778,
                            58.420695170507415
                        ],
                        [
                            -134.88112448758346,
                            58.41733369621704
                        ],
                        [
                            -134.86908672789616,
                            58.40861875728204
                        ],
                        [
                            -134.86940875609108,
                            58.407028625539226
                        ],
                        [
                            -134.85831634687182,
                            58.39560449128866
                        ],
                        [
                            -134.85638470805875,
                            58.3909619260197
                        ],
                        [
                            -134.8427680908515,
                            58.375913385552394
                        ],
                        [
                            -134.8435119864009,
                            58.37281431679895
                        ],
                        [
                            -134.84144687730887,
                            58.371886909521194
                        ],
                        [
                            -134.84139923594051,
                            58.37048664384176
                        ],
                        [
                            -134.8329015602475,
                            58.37204363409174
                        ],
                        [
                            -134.815578521779,
                            58.37319323013824
                        ],
                        [
                            -134.813863363081,
                            58.372523139022036
                        ],
                        [
                            -134.81239794753645,
                            58.37039791528838
                        ],
                        [
                            -134.81001482693796,
                            58.370363825068736
                        ],
                        [
                            -134.80688047955792,
                            58.374694410168985
                        ],
                        [
                            -134.81123372023873,
                            58.38019704682753
                        ],
                        [
                            -134.81538613410612,
                            58.381483791429694
                        ],
                        [
                            -134.81683520907686,
                            58.384705911751
                        ],
                        [
                            -134.81798438187224,
                            58.385206035336466
                        ],
                        [
                            -134.82049995311658,
                            58.390430783210405
                        ],
                        [
                            -134.82980521288712,
                            58.400575334463504
                        ],
                        [
                            -134.83709137754406,
                            58.410459546731644
                        ],
                        [
                            -134.83940048258685,
                            58.415447505147796
                        ],
                        [
                            -134.84230132160377,
                            58.419731177638766
                        ],
                        [
                            -134.8472027199382,
                            58.425280932788326
                        ],
                        [
                            -134.85261789034593,
                            58.43016145921308
                        ],
                        [
                            -134.85968040796064,
                            58.43869778717726
                        ],
                        [
                            -134.8626728730296,
                            58.44152217486129
                        ],
                        [
                            -134.87125147816664,
                            58.44510652017004
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "141",
                "name": "S'iknax̲s'aank'í (Sik'náx̲ Aan G̲eey*)",
                "translation": "Little One below Siknáx̲",
                "location": "Taku Harbor",
                "audio": "map-141.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.01666141880546,
                    58.06919094113402
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "146",
                "name": "Tlaksidak*",
                "translation": "Unknown*",
                "location": "Sweetheart Creek*",
                "audio": "map-146.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.66904997675087,
                    57.94601179165575
                ],
                "type": "Point"
            },
            "id": 53
        },
        {
            "type": "Feature",
            "properties": {
                "id": "146",
                "name": "Tlaksidak*",
                "translation": "Unknown*",
                "location": "Sweetheart Creek*",
                "audio": "map-146.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -133.68709890663058,
                        57.94325537962828
                    ],
                    [
                        -133.68049261257323,
                        57.944170105857864
                    ],
                    [
                        -133.67446850672223,
                        57.9455674093613
                    ],
                    [
                        -133.66904351773064,
                        57.94601383273334
                    ],
                    [
                        -133.66599817820517,
                        57.94484708789983
                    ],
                    [
                        -133.66366778606132,
                        57.94306212174948
                    ],
                    [
                        -133.65652164993674,
                        57.943159662220495
                    ],
                    [
                        -133.64949822230662,
                        57.942006248650216
                    ],
                    [
                        -133.63990653763994,
                        57.944710534790175
                    ],
                    [
                        -133.63620551294417,
                        57.94560526941132
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "104",
                "name": "Eix̲'gul'héen",
                "translation": "Creek at the End of the Slough",
                "location": "Lemon Creek or Switzer Creek",
                "audio": "map-104.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -134.5064958725522,
                        58.362833844435954
                    ],
                    [
                        -134.50642627079813,
                        58.3625991595475
                    ],
                    [
                        -134.50611789999405,
                        58.36254052250182
                    ],
                    [
                        -134.5059885845854,
                        58.362374227192554
                    ],
                    [
                        -134.50616019181803,
                        58.36231064836676
                    ],
                    [
                        -134.50604703548743,
                        58.36204730135367
                    ],
                    [
                        -134.5061577090971,
                        58.36177090877982
                    ],
                    [
                        -134.50641628286886,
                        58.361587678950116
                    ],
                    [
                        -134.50650076668705,
                        58.361313128966
                    ],
                    [
                        -134.5063044249443,
                        58.36107316754165
                    ],
                    [
                        -134.50577102918905,
                        58.36093361805282
                    ],
                    [
                        -134.50547389837075,
                        58.360657085466215
                    ],
                    [
                        -134.50586491056168,
                        58.36040601934164
                    ],
                    [
                        -134.50603209281238,
                        58.36006363002522
                    ],
                    [
                        -134.5067768122043,
                        58.35968543920811
                    ],
                    [
                        -134.507331236215,
                        58.35978061447362
                    ],
                    [
                        -134.50777374525558,
                        58.36012367075625
                    ],
                    [
                        -134.5089671626636,
                        58.359873253394824
                    ],
                    [
                        -134.50886887770105,
                        58.35924163225308
                    ],
                    [
                        -134.50909390265227,
                        58.35875352342889
                    ],
                    [
                        -134.50959369254142,
                        58.35764684023704
                    ],
                    [
                        -134.51101836666035,
                        58.35699725227562
                    ],
                    [
                        -134.5127699499874,
                        58.35698228628273
                    ],
                    [
                        -134.514496663321,
                        58.356680276333094
                    ],
                    [
                        -134.51712218161862,
                        58.35549066202961
                    ],
                    [
                        -134.51771641473243,
                        58.35447713091068
                    ],
                    [
                        -134.51905908062344,
                        58.354152307439364
                    ],
                    [
                        -134.5196307767201,
                        58.35376224159543
                    ],
                    [
                        -134.51928381696624,
                        58.35297106011309
                    ],
                    [
                        -134.51990678695358,
                        58.35219292753732
                    ],
                    [
                        -134.52028205180878,
                        58.351003512123356
                    ],
                    [
                        -134.52141844900373,
                        58.35023083668267
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "149",
                "name": "Ldak̲éex̲'",
                "translation": "Unknown*",
                "location": "Gambier Bay",
                "audio": "map-149.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.0302390673152,
                    57.482086028337065
                ],
                "type": "Point"
            },
            "id": 56
        },
        {
            "type": "Feature",
            "properties": {
                "id": "144",
                "name": "Sit'k̲ú",
                "translation": "Glacier Area",
                "location": "Port Snettisham",
                "audio": "map-144.mp3",
                "audio2": "map-144_2.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.81895290733317,
                    57.98526784983584
                ],
                "type": "Point"
            },
            "id": 57
        },
        {
            "type": "Feature",
            "properties": {
                "id": "145",
                "name": "Sít' G̲eeyí",
                "translation": "Glacier Bay",
                "location": "Gilbert Bay",
                "audio": "map-145.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.71294095889616,
                    57.95953966057667
                ],
                "type": "Point"
            },
            "id": 58
        },
        {
            "type": "Feature",
            "properties": {
                "id": "143",
                "name": "Kanak̲'aa",
                "translation": "Skinny*",
                "location": "Seymour Canal",
                "audio": "map-143.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.06904659286027,
                    57.748431727834664
                ],
                "type": "Point"
            },
            "id": 59
        },
        {
            "type": "Feature",
            "properties": {
                "id": "150",
                "name": "G̲eiwk'óo [G̲eiwk'ú]",
                "translation": "Unknown*",
                "location": "Upper Seymour Canal*",
                "audio": "map-150.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.24055613891318,
                    58.00217646432315
                ],
                "type": "Point"
            },
            "id": 60
        },
        {
            "type": "Feature",
            "properties": {
                "id": "147",
                "name": "Chichnáx̲",
                "translation": "Porpoise Harbor*",
                "location": "Windfall Harbor",
                "audio": "map-147.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.28613716874727,
                    57.860863878386084
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "134",
                "name": "Tsaa T'éi Héen",
                "translation": "Behind the Seals Creek",
                "location": "Young Bay creek",
                "audio": "map-134.mp3",
                "type": "water",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.52967458732866,
                    58.15979131150326
                ],
                "type": "Point"
            },
            "id": 62
        },
        {
            "type": "Feature",
            "properties": {
                "id": "139",
                "name": "Deishú Áak'u",
                "translation": "Little Lake at the End of the Trail",
                "location": "Oliver Inlet, Admiralty Island",
                "audio": "map-139.mp3",
                "type": "lake",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.320220947266,
                    58.1198774501198,
                    0
                ],
                "type": "Point"
            },
            "id": 63
        },
        {
            "type": "Feature",
            "properties": {
                "id": "140",
                "name": "Deishú Áak'u Aan",
                "translation": "Camp on Deishú Áak'u",
                "location": "Camp on Oliver Inlet, Admiralty Island",
                "audio": "map-140.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.311122873332,
                    58.1077266862905,
                    0
                ],
                "type": "Point"
            },
            "id": 64
        },
        {
            "type": "Feature",
            "properties": {
                "id": "104",
                "name": "Eix̲'gul'héen",
                "translation": "Creek at the End of the Slough",
                "location": "Lemon Creek or Switzer Creek",
                "audio": "map-104.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.5127542577029,
                    58.356978025236636
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "46",
                "name": "Dax̲anáak",
                "translation": "Between Two Points*",
                "location": "Berners Bay",
                "audio": "map-46.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.98405108119658,
                    58.71034889801472
                ],
                "type": "Point"
            },
            "id": 66
        },
        {
            "type": "Feature",
            "properties": {
                "id": "60",
                "name": "Eeyák'w",
                "translation": "Small Rapids",
                "location": "Eagle River Landing",
                "audio": "map-60.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.788856506348,
                    58.491809882467,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "98",
                "name": "Kax̲dig̲oowu Héen Dei",
                "translation": "Going Back Clear Water Trail",
                "location": "Trail to Montana Creek",
                "audio": "map-98.mp3",
                "type": "trail",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.58831345317563,
                    58.39936697043004
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "98",
                "name": "Kax̲dig̲oowu Héen Dei",
                "translation": "Going Back Clear Water Trail",
                "location": "Trail to Montana Creek",
                "audio": "map-98.mp3",
                "type": "trail",
                "marker-color": "#b5b5b5",
                "stroke": "#b5b5b5",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -134.60084449246835,
                        58.37575512776672
                    ],
                    [
                        -134.60158833474966,
                        58.37526132524249
                    ],
                    [
                        -134.6028140406434,
                        58.37395569674864
                    ],
                    [
                        -134.6027626843995,
                        58.37324414274138
                    ],
                    [
                        -134.6034753922585,
                        58.37135706411162
                    ],
                    [
                        -134.60289342528998,
                        58.37112202439935
                    ],
                    [
                        -134.60270094298025,
                        58.37070516620366
                    ],
                    [
                        -134.6020786761672,
                        58.37074471169554
                    ],
                    [
                        -134.60210036547528,
                        58.37104992062864
                    ],
                    [
                        -134.60150424463092,
                        58.37166312093616
                    ],
                    [
                        -134.6013806755127,
                        58.37304208697457
                    ],
                    [
                        -134.60042719152557,
                        58.37432627300082
                    ],
                    [
                        -134.6008006348465,
                        58.3763067159102
                    ],
                    [
                        -134.60051492553674,
                        58.37692180980079
                    ],
                    [
                        -134.59952650894638,
                        58.377887883054626
                    ],
                    [
                        -134.59958185962412,
                        58.378838122636495
                    ],
                    [
                        -134.60077929178752,
                        58.38018024740418
                    ],
                    [
                        -134.59985101198345,
                        58.38107055796314
                    ],
                    [
                        -134.5995888950681,
                        58.3823504344367
                    ],
                    [
                        -134.59782513271043,
                        58.384826122350695
                    ],
                    [
                        -134.5974766322131,
                        58.386564724542026
                    ],
                    [
                        -134.59631105954668,
                        58.388703063192246
                    ],
                    [
                        -134.59025993432746,
                        58.39236058747976
                    ],
                    [
                        -134.5895474319226,
                        58.39412712834873
                    ],
                    [
                        -134.58962545101878,
                        58.39764047243626
                    ],
                    [
                        -134.58838918864734,
                        58.39931612042267
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "79",
                "name": "Wóoshde X̲'al.at Yé",
                "translation": "Place Where the Open Sides Face Each Other*",
                "location": "Tee Harbor",
                "audio": "map-79.mp3",
                "type": "default",
                "marker-color": "#555555",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.760360717773,
                    58.40989560076,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "59",
                "name": "Asx̲'ée",
                "translation": "Twisted Tree (Dam)*",
                "location": "Eagle River",
                "audio": "map-59.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.788684844971,
                    58.4960261753489,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "1",
                "name": "At Ch'éeni Shaa",
                "translation": "Sheep's Bow Mountain",
                "location": "Upper Taku River (Carter Mountain)",
                "audio": "map-1.mp3",
                "type": "mountain",
                "marker-color": "#4f7a28",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.63068155626132,
                    59.8900067715299
                ],
                "type": "Point"
            },
            "id": 72
        },
        {
            "type": "Feature",
            "properties": {
                "id": "132",
                "name": "T'aak̲ú X̲'aka.aan",
                "translation": "Town at the Mouth of T'aak̲ú",
                "location": "Taku River",
                "audio": "map-132.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.08196017665526,
                    58.190630552168415
                ],
                "type": "Point"
            },
            "id": 73
        },
        {
            "type": "Feature",
            "properties": {
                "id": "131",
                "name": "Keishixjix'aa",
                "translation": "Runs-Up Point",
                "location": "Bishop Point",
                "audio": "map-131.mp3",
                "type": "default",
                "marker-color": "#555555",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.1497536500142,
                    58.201631536613306
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "89",
                "name": "G̲athéeni",
                "translation": "Sockeye Creek",
                "location": "Auke Creek",
                "audio": "map-89.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.63925411587238,
                    58.381631429793146
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "86",
                "name": "Áak'w",
                "translation": "Little Lake",
                "location": "Auke Lake",
                "audio": "map-86.mp3",
                "audio2": "map-86_2.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.63240088819242,
                    58.3874850904177
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "77",
                "name": "Sit'.áa",
                "translation": "Glacier Lake",
                "location": "Mendenhall Lake",
                "audio": "map-77.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.56783360182828,
                    58.42336221140056
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "58",
                "name": "Sít' Áayi",
                "translation": "Glacier Lake",
                "location": "Twin Glacier Lake",
                "audio": "map-58.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.90569010051104,
                    58.53866387211767
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "74",
                "name": "Sít' K̲unaa G̲eeyí",
                "translation": "Glacier-Bidding Bay",
                "location": "Taku Glacier cove",
                "audio": "map-74.mp3",
                "type": "water",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.9876489956915,
                    58.43740679877041
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "73",
                "name": "T'aak̲ú K̲wáan Sít'i",
                "translation": "T'aak̲ú People's Glacier",
                "location": "Taku Glacier",
                "audio": "map-73.mp3",
                "type": "ice",
                "marker-color": "#00c7fc",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.0369508884697,
                    58.43995099456532
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "78",
                "name": "Yakwdeiyí",
                "translation": "Canoe Path",
                "location": "Across from Taku Glacier",
                "audio": "map-78.mp3",
                "audio2": "map-78_2.mp3",
                "type": "water",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.94750010256445,
                    58.418074415113864
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "83",
                "name": "Yanyeidi X'aayí",
                "translation": "Yanyeidí Point",
                "location": "Taku Point",
                "audio": "map-83.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.00680324018415,
                    58.395048413666586
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "125",
                "name": "X'áat' T'áak",
                "translation": "Beside the Island",
                "location": "Douglas (Mayflower Island*)",
                "audio": "map-125.mp3",
                "audio2": "map-125_2.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.55212103608005,
                    58.26604193187939
                ],
                "type": "Point"
            },
            "id": 83
        },
        {
            "type": "Feature",
            "properties": {
                "id": "116",
                "name": "Deishú Áak'w",
                "translation": "Little Lagoon at the End of the Trail",
                "location": "Douglas Island, Outer Point cove",
                "audio": "map-116.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.67564436234107,
                    58.29696122332447
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "113",
                "name": "Shikaagi Noow",
                "translation": "Thick-Walled (of Logs)* Fort",
                "location": "Outer Point",
                "audio": "map-113.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.67577457428,
                    58.3018227952963,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "117",
                "name": "Kanáx̲s'*",
                "translation": "Unknown*",
                "location": "West side of Douglas Island, Peterson Creek*",
                "audio": "map-117.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.67253101562727,
                    58.29692482225328
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "119",
                "name": "K̲aalahéenak'u*",
                "translation": "Inside a Person's Mouth Creek*",
                "location": "Peterson Creek",
                "audio": "map-119.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.66707256146813,
                    58.28641357326635
                ],
                "type": "Point"
            },
            "id": 87
        },
        {
            "type": "Feature",
            "properties": {
                "id": "128",
                "name": "K̲oosh",
                "translation": "Oozing Sore",
                "location": "Thane",
                "audio": "map-128.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.31673025522628,
                    58.25800158086991
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "120",
                "name": "Wooshkeenax̲ Deiyí",
                "translation": "Trails above Each Other",
                "location": "On Mount Roberts",
                "audio": "map-120.mp3",
                "type": "trail",
                "marker-color": "#d95000",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.32844093342513,
                    58.26779172156513
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "126",
                "name": "Chas'héeni",
                "translation": "Humpback Salmon Creek",
                "location": "Sheep Creek",
                "audio": "map-126.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.3215529730532,
                    58.26493931959856
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "127",
                "name": "Anax̲ Yaa Andagan Yé",
                "translation": "Where the Sun Rays Hit First",
                "location": "Douglas Boat Harbor",
                "audio": "map-127.mp3",
                "type": "default",
                "marker-color": "#555555",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.3876656947157,
                    58.27567164814255
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "124",
                "name": "Sayéik*",
                "translation": "Spirit Helper*",
                "location": "Douglas",
                "audio": "map-124.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.393606185913,
                    58.2783182756625,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "123",
                "name": "Deishú",
                "translation": "End of the Trail",
                "location": "Lawson Creek village",
                "audio": "map-123.mp3",
                "audio2": "map-123_2.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.395236968994,
                    58.2789049507226,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "122",
                "name": "Shgóonaa Héenak'u",
                "translation": "Schooner's Little Creek",
                "location": "Lawson Creek village",
                "audio": "map-122.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.4119375213847,
                    58.28426485790376
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "118",
                "name": "Kaawa.ée Héenak'u",
                "translation": "Kaawa.ée's Little Creek",
                "location": "Kowee Creek, near Juneau-Douglas Bridge",
                "audio": "map-118.mp3",
                "type": "water",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.4397649371949,
                    58.29481795225322
                ],
                "type": "Point"
            },
            "id": 95
        },
        {
            "type": "Feature",
            "properties": {
                "id": "114",
                "name": "It'ji Shaanáx̲",
                "translation": "Sparkling Valley",
                "location": "Perseverance Valley",
                "audio": "map-114.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.39862270726576,
                    58.307655874839824
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "114.1",
                "name": "Dzánti",
                "translation": "Flounder",
                "location": "Hill above Gold Creek (Capitol Hill)",
                "audio": "map-114.1.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.413604736328,
                    58.3102328095887,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "115",
                "name": "Dzántik'i Héeni (Dzanti K'ihéeni)",
                "translation": "Flounder at the Base of the Creek*",
                "location": "Juneau (Gold Creek)",
                "audio": "map-115.mp3",
                "audio2": "map-115_2.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.412360191345,
                    58.3064902619046,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "110.1",
                "name": "Yadaa.at Kalé",
                "translation": "Beautifully Adorned Face",
                "location": "Mount Juneau (face)",
                "audio": "map-110.1.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.40666607394778,
                    58.312128220938654
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "109",
                "name": "Til'héeni",
                "translation": "Dog Salmon Creek",
                "location": "Salmon Creek",
                "audio": "map-109.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.44509783175238,
                    58.33465516544718
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "102",
                "name": "Katlaax̲ Jíni (Tlax̲ Satán Jín)",
                "translation": "Hand of Moldy Head (Idle Hands)",
                "location": "Blackerby Ridge*",
                "audio": "map-102.mp3",
                "type": "trail",
                "marker-color": "#d95000",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.4251470674865,
                    58.356219704039965
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "87",
                "name": "Sít'",
                "translation": "Glacier",
                "location": "Lemon Creek Glacier",
                "audio": "map-72.mp3",
                "audio2": "map-72_2.mp3",
                "type": "ice",
                "marker-color": "#00c7fc",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.3633102086722,
                    58.40108843299498
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "88",
                "name": "Shaanáx̲ (Shaanáx̲ Tlein)",
                "translation": "Valley (Big Valley)",
                "location": "Lower Lemon Creek Valley",
                "audio": "map-88.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.4791712738475,
                    58.372902963151006
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "82",
                "name": "Tleix̲satanjín",
                "translation": "Hand at Rest",
                "location": "Heintzleman Ridge",
                "audio": "map-82.mp3",
                "type": "trail",
                "marker-color": "#d95000",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.4536769184623,
                    58.40128478447954
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "99",
                "name": "Sit'k̲ú Héen",
                "translation": "Glacier Area Creek",
                "location": "Lemon Creek",
                "audio": "map-99.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -134.3772950317372,
                        58.40116231544485
                    ],
                    [
                        -134.3803740430488,
                        58.401688170719076
                    ],
                    [
                        -134.38098676565068,
                        58.401107098427175
                    ],
                    [
                        -134.38456209253954,
                        58.40068312457271
                    ],
                    [
                        -134.38901396213376,
                        58.40161745745954
                    ],
                    [
                        -134.394765595213,
                        58.40133787598123
                    ],
                    [
                        -134.39684282262482,
                        58.402699372889856
                    ],
                    [
                        -134.4048471699372,
                        58.40240186813563
                    ],
                    [
                        -134.40467534726577,
                        58.4018603794933
                    ],
                    [
                        -134.40603852977986,
                        58.40057764902258
                    ],
                    [
                        -134.40816898331784,
                        58.40074169453794
                    ],
                    [
                        -134.4105268509649,
                        58.3990455620156
                    ],
                    [
                        -134.41039171680455,
                        58.397952187782536
                    ],
                    [
                        -134.41203063525862,
                        58.39691885123099
                    ],
                    [
                        -134.41421971170934,
                        58.396004901087764
                    ],
                    [
                        -134.41783057126025,
                        58.393722345034035
                    ],
                    [
                        -134.41799975804005,
                        58.39248483571919
                    ],
                    [
                        -134.41889419247227,
                        58.39163216674859
                    ],
                    [
                        -134.4210892892712,
                        58.39084303011958
                    ],
                    [
                        -134.42359513464723,
                        58.391178398417225
                    ],
                    [
                        -134.4282317589194,
                        58.39046297014181
                    ],
                    [
                        -134.43161352953703,
                        58.39063793501731
                    ],
                    [
                        -134.4328944888811,
                        58.38863036396371
                    ],
                    [
                        -134.43594945451662,
                        58.38822978012237
                    ],
                    [
                        -134.437104373828,
                        58.38699604524448
                    ],
                    [
                        -134.43808561787242,
                        58.384619985919
                    ],
                    [
                        -134.4375285000457,
                        58.38255272954582
                    ],
                    [
                        -134.43756026155313,
                        58.38136942806844
                    ],
                    [
                        -134.43903419235255,
                        58.38067473254069
                    ],
                    [
                        -134.4385440929727,
                        58.37916027940493
                    ],
                    [
                        -134.4419812833718,
                        58.37952406813443
                    ],
                    [
                        -134.44605147595655,
                        58.37891441734725
                    ],
                    [
                        -134.44830804247712,
                        58.378091176691896
                    ],
                    [
                        -134.44976737346434,
                        58.378791233996
                    ],
                    [
                        -134.45078834132957,
                        58.37827660456051
                    ],
                    [
                        -134.45365371242218,
                        58.37885207066686
                    ],
                    [
                        -134.46011017513885,
                        58.38105485766748
                    ],
                    [
                        -134.4630085616406,
                        58.38095222242953
                    ],
                    [
                        -134.4648958563794,
                        58.37945711202893
                    ],
                    [
                        -134.46446714405738,
                        58.37609844743025
                    ],
                    [
                        -134.4641031150904,
                        58.37456606723316
                    ],
                    [
                        -134.46455179042982,
                        58.3721853162133
                    ],
                    [
                        -134.46618351480865,
                        58.371029856940666
                    ],
                    [
                        -134.46874611472452,
                        58.37029778719581
                    ],
                    [
                        -134.46819382243996,
                        58.36924143877306
                    ],
                    [
                        -134.47303772711012,
                        58.36902676619931
                    ],
                    [
                        -134.4745207437827,
                        58.36949502758886
                    ],
                    [
                        -134.4758693374414,
                        58.3709107739994
                    ],
                    [
                        -134.47714135902706,
                        58.37072231887893
                    ],
                    [
                        -134.47676236037637,
                        58.36971519814762
                    ],
                    [
                        -134.4793549084532,
                        58.36859240761184
                    ],
                    [
                        -134.4788376261767,
                        58.36465530466913
                    ],
                    [
                        -134.47969700564653,
                        58.36289179779857
                    ],
                    [
                        -134.48471433109924,
                        58.36017461755188
                    ],
                    [
                        -134.49254559176012,
                        58.359275038449866
                    ],
                    [
                        -134.500612097687,
                        58.35695495338652
                    ],
                    [
                        -134.50110997782383,
                        58.3553649829513
                    ],
                    [
                        -134.50679304773945,
                        58.353527754344746
                    ],
                    [
                        -134.5062506166295,
                        58.35257456335712
                    ],
                    [
                        -134.50917792807758,
                        58.349740229262636
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "111",
                "name": "Aangoox̲a Yé X'áat' T'aak̲",
                "translation": "Beside Aangoox̲a Yé Island",
                "location": "Island at Fish Creek",
                "audio": "map-111.mp3",
                "type": "land",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.60500248932823,
                    58.33270591515966
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "100",
                "name": "T'áa Shuyee",
                "translation": "Board Squared at the End",
                "location": "Mendenhall Valley",
                "audio": "map-100.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.58009417260524,
                    58.381545230597595
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "108",
                "name": "Aangoox̲a Yé",
                "translation": "Beside the Slaves of the Town Place",
                "location": "Fish Creek",
                "audio": "map-108.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.58721429706165,
                    58.329210172457785
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "148",
                "name": "Kichx'aa Yík*",
                "translation": "Inside the Wing* Point",
                "location": "Island in Seymour Canal",
                "audio": "map-148.mp3",
                "type": "land",
                "marker-color": "#4f7a28",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.15714263916,
                    57.826382791883,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "135",
                "name": "Weineidei Aan (Weineideiyan)*",
                "translation": "Alkali Deposit* Village",
                "location": "In Young Bay",
                "audio": "map-135.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.70697019443,
                    58.16794448627161
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "137",
                "name": "Weineidei",
                "translation": "Alkali Deposit* Trail",
                "location": "Hawk Inlet",
                "audio": "map-137.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.7484183250355,
                    58.155737387941365
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "64",
                "name": "Naayádi",
                "translation": "Child of the Clan",
                "location": "Lincoln Island",
                "audio": "map-64.mp3",
                "type": "land",
                "marker-color": "#4f7a28",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.990386962891,
                    58.4910921651796,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "76",
                "name": "Kichx̲aak'",
                "translation": "Wing Island*",
                "location": "Shelter Island",
                "audio": "map-76.mp3",
                "type": "land",
                "marker-color": "#4f7a28",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.8474566581519,
                    58.4036818832528
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "50",
                "name": "Ch'eet' Taayí",
                "translation": "Murrelet Fat*",
                "location": "Cowee Creek, Echo Cove",
                "audio": "map-50.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.91688763854523,
                    58.655290759247855
                ],
                "type": "Point"
            },
            "id": 114
        },
        {
            "type": "Feature",
            "properties": {
                "id": "81",
                "name": "Kax̲dig̲oowu Héen",
                "translation": "Going Back Clear Water",
                "location": "Montana Creek",
                "audio": "map-81.mp3",
                "audio2": "map-81_2.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.6586715644872,
                    58.449523265584475
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "129",
                "name": "Shakanáx̲k'w",
                "translation": "Dead Trees on Mountain*",
                "location": "Funter Bay",
                "audio": "map-129.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.9036769367022,
                    58.24087597021145
                ],
                "type": "Point"
            },
            "id": 116
        },
        {
            "type": "Feature",
            "properties": {
                "id": "105",
                "name": "Yáxwch'i K̲aadí Táak",
                "translation": "Sea Otter Slide Back of Bay",
                "location": "Barlow Cove",
                "audio": "map-105.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.892539978027,
                    58.3504062428612,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "80",
                "name": "Xutsnoowú Lutú",
                "translation": "Brown Bear Fort Point (Nose)",
                "location": "Point Retreat",
                "audio": "map-80.mp3",
                "type": "default",
                "marker-color": "#555555",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.9547178377346,
                    58.40956189883178
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "70",
                "name": "Kichx̲aak' Tuk̲yee",
                "translation": "Wing Island* Bottom End",
                "location": "Shelter Island fort",
                "audio": "map-70.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.89013676066,
                    58.4539301853325,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "71",
                "name": "Jánwu Téix̲'i",
                "translation": "Mountain Goat Heart",
                "location": "Mount Golub, Chilkat Peninsula",
                "audio": "map-71.mp3",
                "type": "default",
                "marker-color": "#555555",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -135.1736669573756,
                    58.46510670293091
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "41",
                "name": "K̲utak.aan*",
                "translation": "Unknown* Village",
                "location": "Mouth of Lace River, Berners Bay",
                "audio": "map-41.mp3",
                "type": "default",
                "marker-color": "#555555",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.9804274762206,
                    58.824914848200365
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "107",
                "name": "Yaana.éit X'áat'i",
                "translation": "WIld Celery Island",
                "location": "Auke Bay",
                "audio": "map-107.mp3",
                "type": "land",
                "marker-color": "#4f7a28",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.66318384187684,
                    58.35077192532603
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "90",
                "name": "Áak'w Táak",
                "translation": "Inland from Little Lake",
                "location": "Mendenhall Valley",
                "audio": "map-90.mp3",
                "type": "default",
                "marker-color": "#555555",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.6194904635213,
                    58.39481553626467
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "54",
                "name": "Taan X'áat'i",
                "translation": "Sea Lion Island",
                "location": "Benjamin Island",
                "audio": "map-54.mp3",
                "type": "land",
                "marker-color": "#4f7a28",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.906787872314,
                    58.5607767686536,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "40",
                "name": "Aan Sakweis's'í*",
                "translation": "Village Unknown*",
                "location": "Berners Bay area",
                "audio": "map-40.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -135.063343048096,
                    58.7655298553249,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "103",
                "name": "Shaa X'áat'i",
                "translation": "Mountain Island",
                "location": "Island at end of Fritz Cove",
                "audio": "map-103.mp3",
                "type": "land",
                "marker-color": "#4f7a28",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.69992679577865,
                    58.35506721671524
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "81",
                "name": "Kax̲dig̲oowu Héen",
                "translation": "Going Back Clear Water",
                "location": "Montana Creek",
                "audio": "map-81.mp3",
                "audio2": "map-81_2.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -134.59811325409282,
                        58.38121041614079
                    ],
                    [
                        -134.5991568158192,
                        58.381748602609946
                    ],
                    [
                        -134.59985051957727,
                        58.382487900851686
                    ],
                    [
                        -134.60121104275976,
                        58.38312914348634
                    ],
                    [
                        -134.6014093390462,
                        58.38366608108552
                    ],
                    [
                        -134.60113934773537,
                        58.38389412658455
                    ],
                    [
                        -134.60298360120498,
                        58.383920982657884
                    ],
                    [
                        -134.60407695283317,
                        58.38487663862989
                    ],
                    [
                        -134.60355663339266,
                        58.38511378075114
                    ],
                    [
                        -134.6040356851217,
                        58.38558944660156
                    ],
                    [
                        -134.60393062998116,
                        58.386526362568304
                    ],
                    [
                        -134.60455889212324,
                        58.38736030990182
                    ],
                    [
                        -134.60579294558087,
                        58.38779721485378
                    ],
                    [
                        -134.60564781688834,
                        58.38891717358922
                    ],
                    [
                        -134.606907861144,
                        58.38950304323754
                    ],
                    [
                        -134.60700607972709,
                        58.38993281595248
                    ],
                    [
                        -134.6059266224989,
                        58.39013309924769
                    ],
                    [
                        -134.60710108099994,
                        58.390600613859135
                    ],
                    [
                        -134.60741658938616,
                        58.39120931488014
                    ],
                    [
                        -134.60811869520037,
                        58.392069278732095
                    ],
                    [
                        -134.60762097001202,
                        58.39231353395448
                    ],
                    [
                        -134.60734957022274,
                        58.39203512653913
                    ],
                    [
                        -134.6068000006644,
                        58.39203561462102
                    ],
                    [
                        -134.60668693207862,
                        58.39217479778216
                    ],
                    [
                        -134.6070045799501,
                        58.392841094157546
                    ],
                    [
                        -134.6069428549619,
                        58.393160731237174
                    ],
                    [
                        -134.6062192476662,
                        58.39316184739812
                    ],
                    [
                        -134.60598683102162,
                        58.39348437231226
                    ],
                    [
                        -134.60626029775167,
                        58.39370001246746
                    ],
                    [
                        -134.60704426268077,
                        58.393698773700976
                    ],
                    [
                        -134.60788149976574,
                        58.39398736571877
                    ],
                    [
                        -134.60787620426322,
                        58.39457241781693
                    ],
                    [
                        -134.6077037726388,
                        58.394831146515486
                    ],
                    [
                        -134.60878160741322,
                        58.39553885960896
                    ],
                    [
                        -134.6111540310119,
                        58.39754512412691
                    ],
                    [
                        -134.611380499605,
                        58.39841744482766
                    ],
                    [
                        -134.61105580296717,
                        58.399361399383736
                    ],
                    [
                        -134.61159243456927,
                        58.39973035678696
                    ],
                    [
                        -134.61233921172283,
                        58.399605776426
                    ],
                    [
                        -134.61288905670926,
                        58.399802842055834
                    ],
                    [
                        -134.61308248186126,
                        58.401294124145494
                    ],
                    [
                        -134.61374092239785,
                        58.40155105711332
                    ],
                    [
                        -134.61382964933742,
                        58.40194247243957
                    ],
                    [
                        -134.61315589522806,
                        58.40215486099984
                    ],
                    [
                        -134.61319607026724,
                        58.40247296265986
                    ],
                    [
                        -134.61453893832487,
                        58.40295192885358
                    ],
                    [
                        -134.61507104261588,
                        58.40357552171858
                    ],
                    [
                        -134.61448605228716,
                        58.403577065901715
                    ],
                    [
                        -134.6143380244496,
                        58.40376970164513
                    ],
                    [
                        -134.61433198834942,
                        58.40443537757372
                    ],
                    [
                        -134.61403441885287,
                        58.404575990347155
                    ],
                    [
                        -134.61427886322602,
                        58.40482929208903
                    ],
                    [
                        -134.614774049246,
                        58.40488491603918
                    ],
                    [
                        -134.61537011078866,
                        58.405196557258336
                    ],
                    [
                        -134.61512147653363,
                        58.405368773831526
                    ],
                    [
                        -134.61472237649326,
                        58.40539830415918
                    ],
                    [
                        -134.61522273844247,
                        58.4060623545424
                    ],
                    [
                        -134.61502136329509,
                        58.40656059762097
                    ],
                    [
                        -134.6127685539645,
                        58.407485266723086
                    ],
                    [
                        -134.61413517327546,
                        58.40892515287587
                    ],
                    [
                        -134.61339535639405,
                        58.40946056010091
                    ],
                    [
                        -134.61386358004387,
                        58.40971459058267
                    ],
                    [
                        -134.61322439800512,
                        58.410097595274635
                    ],
                    [
                        -134.61363847032982,
                        58.41061363677659
                    ],
                    [
                        -134.61285756450596,
                        58.41206969660331
                    ],
                    [
                        -134.6119879492985,
                        58.412204601977095
                    ],
                    [
                        -134.61181168214137,
                        58.41257730856762
                    ],
                    [
                        -134.61089698101404,
                        58.41312853798348
                    ],
                    [
                        -134.61119800380828,
                        58.41335969861558
                    ],
                    [
                        -134.61127335023605,
                        58.41451078252257
                    ],
                    [
                        -134.61394677258528,
                        58.41609844224347
                    ],
                    [
                        -134.6169573502643,
                        58.41698897810485
                    ],
                    [
                        -134.61979228841648,
                        58.417031515445515
                    ],
                    [
                        -134.6215345576303,
                        58.4171679533007
                    ],
                    [
                        -134.62388709616576,
                        58.41764143801652
                    ],
                    [
                        -134.62390850121847,
                        58.41873636216991
                    ],
                    [
                        -134.6256863711175,
                        58.418452920317094
                    ],
                    [
                        -134.62753307318056,
                        58.41981520694823
                    ],
                    [
                        -134.6314222817177,
                        58.42278733321135
                    ],
                    [
                        -134.62969323775926,
                        58.42296494855675
                    ],
                    [
                        -134.63034590999396,
                        58.423898297111585
                    ],
                    [
                        -134.63296504871045,
                        58.424372520246806
                    ],
                    [
                        -134.63269993762128,
                        58.42539357938794
                    ],
                    [
                        -134.62957521070615,
                        58.4255828387927
                    ],
                    [
                        -134.63063074114888,
                        58.42640635062173
                    ],
                    [
                        -134.63500005483658,
                        58.4271191164093
                    ],
                    [
                        -134.63388121196465,
                        58.42808381598243
                    ],
                    [
                        -134.63272938420204,
                        58.42884968982416
                    ],
                    [
                        -134.6350815957256,
                        58.430585985702315
                    ],
                    [
                        -134.633367025631,
                        58.43126444955563
                    ],
                    [
                        -134.6348818369511,
                        58.43174769864482
                    ],
                    [
                        -134.63694116550116,
                        58.43137333449485
                    ],
                    [
                        -134.637694559703,
                        58.43333845909261
                    ],
                    [
                        -134.64010613331803,
                        58.43357872844074
                    ],
                    [
                        -134.6395738426816,
                        58.435762703971506
                    ],
                    [
                        -134.6425927172053,
                        58.43663646086465
                    ],
                    [
                        -134.64269206225157,
                        58.43718984307606
                    ],
                    [
                        -134.6444932008125,
                        58.43745656863612
                    ],
                    [
                        -134.64478550860167,
                        58.43899311150743
                    ],
                    [
                        -134.65099168538595,
                        58.443883842355525
                    ],
                    [
                        -134.65994887996337,
                        58.45047686012836
                    ],
                    [
                        -134.67028654645506,
                        58.45533552680162
                    ],
                    [
                        -134.68062448165463,
                        58.46471480054172
                    ],
                    [
                        -134.67631940091266,
                        58.46774364868811
                    ],
                    [
                        -134.66874164667334,
                        58.46806139840562
                    ],
                    [
                        -134.66320436402617,
                        58.469480703266925
                    ],
                    [
                        -134.64845498672807,
                        58.470722284453814
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "85",
                "name": "Yéilch Awataayi Yé",
                "translation": "Raven's Sleeping* Place",
                "location": "Lena Point shaman site",
                "audio": "map-85.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.769287109375,
                    58.391681509945,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "84",
                "name": "Nex̲'w X'aayí",
                "translation": "Cloudberry Point*",
                "location": "Lena Point",
                "audio": "map-84.mp3",
                "type": "default",
                "marker-color": "#555555",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.776496887207,
                    58.3943804876966,
                    0
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "94",
                "name": "Áak'w Tá",
                "translation": "Back of Little Lake [Bay]",
                "location": "Auke Bay",
                "audio": "map-94.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.64773229066725,
                    58.37822003544986
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "86",
                "name": "Áak'w",
                "translation": "Little Lake",
                "location": "Auke Lake",
                "audio": "map-86.mp3",
                "audio2": "map-86_2.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#002e7a",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -134.62335993216365,
                            58.39004015956195
                        ],
                        [
                            -134.6231765108725,
                            58.391138494315655
                        ],
                        [
                            -134.6222563825488,
                            58.39213287337895
                        ],
                        [
                            -134.6230229354004,
                            58.392517770324574
                        ],
                        [
                            -134.6239893433861,
                            58.39264157926053
                        ],
                        [
                            -134.62505777961667,
                            58.39230870960237
                        ],
                        [
                            -134.6272312763952,
                            58.39216146899186
                        ],
                        [
                            -134.62783806501994,
                            58.39154028029716
                        ],
                        [
                            -134.62882496318306,
                            58.39151564574637
                        ],
                        [
                            -134.62881531749917,
                            58.391718834193824
                        ],
                        [
                            -134.62941939362074,
                            58.39163514187721
                        ],
                        [
                            -134.63048587143416,
                            58.39102224494418
                        ],
                        [
                            -134.63148543162717,
                            58.3909845104944
                        ],
                        [
                            -134.63257783566561,
                            58.39111694942618
                        ],
                        [
                            -134.6330342157062,
                            58.390668996269795
                        ],
                        [
                            -134.63361715725767,
                            58.39096493837043
                        ],
                        [
                            -134.63437010812444,
                            58.391127222243945
                        ],
                        [
                            -134.6352483751632,
                            58.39114470942097
                        ],
                        [
                            -134.63578497359396,
                            58.39149937926391
                        ],
                        [
                            -134.63677103219518,
                            58.39129530918346
                        ],
                        [
                            -134.63704288651687,
                            58.3906268714795
                        ],
                        [
                            -134.6374542214834,
                            58.39031338338156
                        ],
                        [
                            -134.6375182145373,
                            58.389027931789514
                        ],
                        [
                            -134.63769554713488,
                            58.38848506131923
                        ],
                        [
                            -134.63858149143925,
                            58.38812704170398
                        ],
                        [
                            -134.63909908222757,
                            58.38770961368257
                        ],
                        [
                            -134.63921433825723,
                            58.38682708164377
                        ],
                        [
                            -134.6387648130565,
                            58.38605715913437
                        ],
                        [
                            -134.6382857084402,
                            58.38560626894575
                        ],
                        [
                            -134.63667320838954,
                            58.384991993936865
                        ],
                        [
                            -134.63586695836432,
                            58.38408699281697
                        ],
                        [
                            -134.63463568505125,
                            58.38343450561352
                        ],
                        [
                            -134.63373155714692,
                            58.38263457068475
                        ],
                        [
                            -134.6337135760333,
                            58.38179659824466
                        ],
                        [
                            -134.63209086019833,
                            58.38124962787833
                        ],
                        [
                            -134.63017716816276,
                            58.3811509655969
                        ],
                        [
                            -134.6274556066745,
                            58.38160739306362
                        ],
                        [
                            -134.62787921751624,
                            58.38323734211434
                        ],
                        [
                            -134.62797556472395,
                            58.38466269767457
                        ],
                        [
                            -134.62803649332452,
                            58.385772432837214
                        ],
                        [
                            -134.62744571955065,
                            58.387060404930054
                        ],
                        [
                            -134.62468593605314,
                            58.38977647969992
                        ],
                        [
                            -134.62335993216365,
                            58.39004015956195
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "106",
                "name": "Kadigooni X'áat'",
                "translation": "Island That Has Spring Water",
                "location": "Auke Bay",
                "audio": "map-106.mp3",
                "type": "land",
                "marker-color": "#4f7a28",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.6586647435152,
                    58.33811359363128
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "110",
                "name": "Katlaax̲ (Shaa Tlaax̲)",
                "translation": "Moldy Top (Moldy Mountain)",
                "location": "Mount Juneau (top)",
                "audio": "map-110.mp3",
                "type": "default",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.40884260970537,
                    58.318491573548435
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "99",
                "name": "Sit'k̲ú Héen",
                "translation": "Glacier Area Creek",
                "location": "Lemon Creek",
                "audio": "map-99.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.48829322446204,
                    58.359453169746246
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "58",
                "name": "Sít' Áayi",
                "translation": "Glacier Lake",
                "location": "Twin Glacier Lake",
                "audio": "map-58.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#0056d6",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -133.94099990160763,
                            58.563092694831255
                        ],
                        [
                            -133.9437600089929,
                            58.55624064013364
                        ],
                        [
                            -133.925582878257,
                            58.54799794646161
                        ],
                        [
                            -133.92556871746586,
                            58.54371448269731
                        ],
                        [
                            -133.93701147505584,
                            58.53650496056272
                        ],
                        [
                            -133.92691342883325,
                            58.52581190221281
                        ],
                        [
                            -133.90673483567952,
                            58.52470331785969
                        ],
                        [
                            -133.89527998953417,
                            58.52916661871424
                        ],
                        [
                            -133.88496903628248,
                            58.527244291665795
                        ],
                        [
                            -133.87649341028447,
                            58.53061780737474
                        ],
                        [
                            -133.864759926469,
                            58.54955694371506
                        ],
                        [
                            -133.88245333777573,
                            58.55641877287539
                        ],
                        [
                            -133.8796409143529,
                            58.56547661392179
                        ],
                        [
                            -133.88961622832915,
                            58.5656608471821
                        ],
                        [
                            -133.90245144593115,
                            58.55499696860488
                        ],
                        [
                            -133.94099990160763,
                            58.563092694831255
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "56",
                "name": "Naahéeni",
                "translation": "Tribe* Creek",
                "location": "Taku River, secondary channel",
                "audio": "map-56.mp3",
                "type": "water",
                "marker-color": "#b5b5b5",
                "stroke": "#b5b5b5",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -133.74270259370854,
                        58.5278784665345
                    ],
                    [
                        -133.72852633043018,
                        58.522816947408614
                    ],
                    [
                        -133.7274750313328,
                        58.51668320597935
                    ],
                    [
                        -133.71804144743177,
                        58.51830513111952
                    ],
                    [
                        -133.71073485572242,
                        58.510544909060854
                    ],
                    [
                        -133.68493789856612,
                        58.50603369521488
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "if": 73,
                "stroke": "#00c7fc",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#00c7fc",
                "fill-opacity": 0.1
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -134.02476627819325,
                            58.44670110848719
                        ],
                        [
                            -134.04096320721013,
                            58.44574511715635
                        ],
                        [
                            -134.05856065271772,
                            58.45038583911065
                        ],
                        [
                            -134.06683090858914,
                            58.46043493307579
                        ],
                        [
                            -134.0503438319057,
                            58.46751432318575
                        ],
                        [
                            -134.06725647533636,
                            58.48269547493504
                        ],
                        [
                            -134.08221086314427,
                            58.4641339036271
                        ],
                        [
                            -134.07835292750775,
                            58.45133974035218
                        ],
                        [
                            -134.03223260164916,
                            58.43379293385934
                        ],
                        [
                            -134.02476627819325,
                            58.44670110848719
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "78",
                "name": "Yakwdeiyí",
                "translation": "Canoe Path",
                "location": "Across from Taku Glacier",
                "audio": "map-78.mp3",
                "audio2": "map-78_2.mp3",
                "type": "water",
                "marker-color": "#b5b5b5",
                "stroke": "#b5b5b5",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -133.95268552579208,
                        58.42073810349325
                    ],
                    [
                        -133.94737454811204,
                        58.41800714575851
                    ],
                    [
                        -133.94479945413514,
                        58.41462718780073
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "119",
                "name": "K̲aalahéenak'u*",
                "translation": "Inside a Person's Mouth Creek*",
                "location": "Peterson Creek",
                "audio": "map-119.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -134.68228178289272,
                        58.29899792609686
                    ],
                    [
                        -134.6797818358343,
                        58.29806862085343
                    ],
                    [
                        -134.6782877217103,
                        58.29799626621971
                    ],
                    [
                        -134.67790539934896,
                        58.29616497966197
                    ],
                    [
                        -134.67721294136066,
                        58.29605077714126
                    ],
                    [
                        -134.67744840860036,
                        58.295462894530175
                    ],
                    [
                        -134.67683490272475,
                        58.29523970798593
                    ],
                    [
                        -134.6756713375191,
                        58.29535112066344
                    ],
                    [
                        -134.67159658328248,
                        58.293294103649515
                    ],
                    [
                        -134.67260252731194,
                        58.29234154606456
                    ],
                    [
                        -134.66829205765217,
                        58.287116388884215
                    ],
                    [
                        -134.6670757938827,
                        58.28643212627017
                    ],
                    [
                        -134.6660479803507,
                        58.28299310546453
                    ],
                    [
                        -134.6637037744956,
                        58.28175437861029
                    ],
                    [
                        -134.66044942622514,
                        58.279485266518066
                    ],
                    [
                        -134.65579296682253,
                        58.27617425981339
                    ],
                    [
                        -134.65037035285388,
                        58.27478303707062
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "126",
                "name": "Chas'héeni",
                "translation": "Humpback Salmon Creek",
                "location": "Sheep Creek",
                "audio": "map-126.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -134.32538521380798,
                        58.2605024907875
                    ],
                    [
                        -134.3240063716755,
                        58.26111140613597
                    ],
                    [
                        -134.32156761373002,
                        58.26103984351104
                    ],
                    [
                        -134.32005393314182,
                        58.261428696828546
                    ],
                    [
                        -134.32021065442055,
                        58.26296853411927
                    ],
                    [
                        -134.31974098948643,
                        58.26371828974135
                    ],
                    [
                        -134.3201589644829,
                        58.26416559564129
                    ],
                    [
                        -134.32074378530686,
                        58.26426242405944
                    ],
                    [
                        -134.3210833433062,
                        58.26480127012897
                    ],
                    [
                        -134.32389997638546,
                        58.265604555496935
                    ],
                    [
                        -134.323575018753,
                        58.265963493042136
                    ],
                    [
                        -134.3241894909827,
                        58.26618803857875
                    ],
                    [
                        -134.3241111527325,
                        58.26664261299263
                    ],
                    [
                        -134.32460850318571,
                        58.26678397461677
                    ],
                    [
                        -134.324744613395,
                        58.26710464852792
                    ],
                    [
                        -134.32460165109114,
                        58.26742898712203
                    ],
                    [
                        -134.32379427928976,
                        58.26757423929911
                    ],
                    [
                        -134.32345685867475,
                        58.26856586354205
                    ],
                    [
                        -134.32147593013815,
                        58.269591905045445
                    ],
                    [
                        -134.32119178133962,
                        58.27046727317719
                    ],
                    [
                        -134.32138885485662,
                        58.270799442035894
                    ],
                    [
                        -134.32048312963803,
                        58.27264779400147
                    ],
                    [
                        -134.32057579231093,
                        58.273071068802096
                    ],
                    [
                        -134.3179375067301,
                        58.27354545747977
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "120",
                "name": "Wooshkeenax̲ Deiyí",
                "translation": "Trails above Each Other",
                "location": "On Mount Roberts",
                "audio": "map-120.mp3",
                "type": "trail",
                "marker-color": "#d95000",
                "stroke": "#d95000",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -134.34029006569432,
                        58.26781777443367
                    ],
                    [
                        -134.3393294164353,
                        58.26789916366289
                    ],
                    [
                        -134.33358061092173,
                        58.26589235303666
                    ],
                    [
                        -134.33116721583184,
                        58.26569710983526
                    ],
                    [
                        -134.33893991928872,
                        58.27003726605588
                    ],
                    [
                        -134.32990008447032,
                        58.267769062260214
                    ],
                    [
                        -134.32698509487005,
                        58.26781777443367
                    ],
                    [
                        -134.32571023795123,
                        58.26859297289775
                    ],
                    [
                        -134.32174233727727,
                        58.271307725962714
                    ],
                    [
                        -134.32150860301334,
                        58.27334776480447
                    ],
                    [
                        -134.3158187696901,
                        58.27701814193901
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "118",
                "name": "Kaawa.ée Héenak'u",
                "translation": "Kaawa.ée's Little Creek",
                "location": "Kowee Creek, near Juneau-Douglas Bridge",
                "audio": "map-118.mp3",
                "type": "water",
                "marker-color": "#b5b5b5",
                "stroke": "#b5b5b5",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -134.43494469229472,
                        58.29806300343594
                    ],
                    [
                        -134.44512045281243,
                        58.291096082320124
                    ],
                    [
                        -134.45500118143883,
                        58.28449087639552
                    ],
                    [
                        -134.45889587381245,
                        58.27649460942904
                    ],
                    [
                        -134.46141489369035,
                        58.268601735352405
                    ],
                    [
                        -134.45848225459895,
                        58.257106785139
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "82",
                "name": "Tleix̲satanjín",
                "translation": "Hand at Rest",
                "location": "Heintzleman Ridge",
                "audio": "map-82.mp3",
                "type": "trail",
                "marker-color": "#d95000",
                "stroke": "#d95000",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -134.4755270751518,
                        58.396813045516694
                    ],
                    [
                        -134.45394287718727,
                        58.401180195087704
                    ],
                    [
                        -134.4332756019979,
                        58.40901147781989
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "109",
                "name": "Til'héeni",
                "translation": "Dog Salmon Creek",
                "location": "Salmon Creek",
                "audio": "map-109.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -134.47351092997275,
                        58.33064444013212
                    ],
                    [
                        -134.46543385083532,
                        58.3330128448861
                    ],
                    [
                        -134.45650919216808,
                        58.33274064652285
                    ],
                    [
                        -134.4528547252457,
                        58.33366944676473
                    ],
                    [
                        -134.45073539880465,
                        58.33291530006437
                    ],
                    [
                        -134.447657857826,
                        58.333424490684045
                    ],
                    [
                        -134.44510853320833,
                        58.334690818568205
                    ],
                    [
                        -134.4417040528454,
                        58.33349166083645
                    ],
                    [
                        -134.43934116859236,
                        58.333814715003456
                    ],
                    [
                        -134.43822038564363,
                        58.33563191204847
                    ],
                    [
                        -134.4341646100732,
                        58.33613820405744
                    ],
                    [
                        -134.433682936806,
                        58.337366565803705
                    ],
                    [
                        -134.42983190261833,
                        58.33794135077788
                    ],
                    [
                        -134.42407613486526,
                        58.33794073717405
                    ],
                    [
                        -134.41588549895027,
                        58.34232825157426
                    ],
                    [
                        -134.40371059841073,
                        58.34192595962486
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "102",
                "name": "Katlaax̲ Jíni (Tlax̲ Satán Jín)",
                "translation": "Hand of Moldy Head (Idle Hands)",
                "location": "Blackerby Ridge*",
                "audio": "map-102.mp3",
                "type": "trail",
                "marker-color": "#d95000",
                "stroke": "#d95000",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -134.411633221612,
                        58.354654270235756
                    ],
                    [
                        -134.4184687453585,
                        58.35596340329596
                    ],
                    [
                        -134.42514511087762,
                        58.35621283327583
                    ],
                    [
                        -134.44645376614068,
                        58.3519268663928
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "87",
                "name": "Sít'",
                "translation": "Glacier",
                "location": "Lemon Creek Glacier",
                "audio": "map-72.mp3",
                "audio2": "map-72_2.mp3",
                "type": "ice",
                "marker-color": "#00c7fc",
                "stroke": "#00c7fc",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#00c7fc",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -134.34942619448051,
                            58.403228456990576
                        ],
                        [
                            -134.35276637564553,
                            58.40342853523856
                        ],
                        [
                            -134.3559875420971,
                            58.40531506220654
                        ],
                        [
                            -134.36505293471424,
                            58.40682199336362
                        ],
                        [
                            -134.36728263662755,
                            58.408421192106346
                        ],
                        [
                            -134.36752632179466,
                            58.406340127762036
                        ],
                        [
                            -134.37325238851543,
                            58.40423279625361
                        ],
                        [
                            -134.37729273740848,
                            58.400857766511024
                        ],
                        [
                            -134.37446159553383,
                            58.39995066130976
                        ],
                        [
                            -134.37400262450916,
                            58.4010532151089
                        ],
                        [
                            -134.3674432587184,
                            58.39778134158652
                        ],
                        [
                            -134.3628691657184,
                            58.39274755153588
                        ],
                        [
                            -134.36168694033867,
                            58.386550941098164
                        ],
                        [
                            -134.3593245855444,
                            58.38516002290609
                        ],
                        [
                            -134.36155412568795,
                            58.38000592873502
                        ],
                        [
                            -134.3276655455576,
                            58.39124618476839
                        ],
                        [
                            -134.32179191754398,
                            58.39519793754991
                        ],
                        [
                            -134.3196894423093,
                            58.39864248812469
                        ],
                        [
                            -134.3249443237218,
                            58.401814477208944
                        ],
                        [
                            -134.34168723364968,
                            58.404344776143375
                        ],
                        [
                            -134.34942619448051,
                            58.403228456990576
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "148",
                "name": "Kichx'aa Yík*",
                "translation": "Inside the Wing* Point",
                "location": "Island in Seymour Canal",
                "audio": "map-148.mp3",
                "type": "land",
                "marker-color": "#4f7a28",
                "stroke": "#4f7a28",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#4f7a28",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -134.20621560595782,
                            57.8951822823185
                        ],
                        [
                            -134.21756383423636,
                            57.891814543454274
                        ],
                        [
                            -134.20207815465184,
                            57.873934710681624
                        ],
                        [
                            -134.1702821439531,
                            57.843669100836905
                        ],
                        [
                            -134.19570446187362,
                            57.848615152166104
                        ],
                        [
                            -134.18870033696732,
                            57.834199699782374
                        ],
                        [
                            -134.14882037655332,
                            57.80088351930533
                        ],
                        [
                            -134.1425355043457,
                            57.79948922031656
                        ],
                        [
                            -134.13102506107475,
                            57.786532825459744
                        ],
                        [
                            -134.13481226042,
                            57.78497956738252
                        ],
                        [
                            -134.12028562815942,
                            57.771231199241726
                        ],
                        [
                            -134.1103523623279,
                            57.772437299058
                        ],
                        [
                            -134.10150373104597,
                            57.77786350887686
                        ],
                        [
                            -134.1038552154195,
                            57.783671710115556
                        ],
                        [
                            -134.10780399575586,
                            57.799319747969264
                        ],
                        [
                            -134.12761793666633,
                            57.82022602641422
                        ],
                        [
                            -134.13377790752682,
                            57.82376377846447
                        ],
                        [
                            -134.14280199172225,
                            57.83249568121062
                        ],
                        [
                            -134.14217607344472,
                            57.83473960739619
                        ],
                        [
                            -134.15510569943808,
                            57.84213476508279
                        ],
                        [
                            -134.1697325532194,
                            57.854164685113375
                        ],
                        [
                            -134.16189267341184,
                            57.85627747972924
                        ],
                        [
                            -134.20621560595782,
                            57.8951822823185
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "133",
                "name": "Kanak̲'aa Sít'[i]",
                "translation": "Skinny* Glacier",
                "location": "Young Glacier",
                "audio": "map-133.mp3",
                "type": "ice",
                "marker-color": "#b5b5b5",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.67590489858424,
                    58.16970527867019
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "134",
                "name": "Tsaa T'éi Héen",
                "translation": "Behind the Seals Creek",
                "location": "Young Bay creek",
                "audio": "map-134.mp3",
                "type": "water",
                "marker-color": "#b5b5b5",
                "stroke": "#b5b5b5",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -134.56064558574465,
                        58.1754935043563
                    ],
                    [
                        -134.55966846436945,
                        58.17426854010681
                    ],
                    [
                        -134.55474027139547,
                        58.1742734782294
                    ],
                    [
                        -134.55202428119287,
                        58.17286378597616
                    ],
                    [
                        -134.5444317002538,
                        58.16555511925497
                    ],
                    [
                        -134.53515895125253,
                        58.16149726295505
                    ],
                    [
                        -134.53279304246036,
                        58.159416576489555
                    ],
                    [
                        -134.5296918573335,
                        58.15984599997209
                    ],
                    [
                        -134.52649772662934,
                        58.1557666159877
                    ],
                    [
                        -134.5237474038182,
                        58.154315711799875
                    ],
                    [
                        -134.5233974966379,
                        58.15318658378885
                    ],
                    [
                        -134.5211777141288,
                        58.1507692243804
                    ],
                    [
                        -134.5148259761271,
                        58.1490432358248
                    ],
                    [
                        -134.51455128211398,
                        58.14662063468458
                    ],
                    [
                        -134.5118103565287,
                        58.14455993037111
                    ],
                    [
                        -134.50988954540009,
                        58.139677345387085
                    ],
                    [
                        -134.5087624901112,
                        58.13957022561791
                    ],
                    [
                        -134.50859392300947,
                        58.13816461699271
                    ],
                    [
                        -134.504949483858,
                        58.13512187532849
                    ],
                    [
                        -134.502812797116,
                        58.13522937652817
                    ],
                    [
                        -134.50069241932874,
                        58.13397702742435
                    ],
                    [
                        -134.4972077077412,
                        58.13371746309912
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "72",
                "name": "Sít'",
                "translation": "Glacier",
                "location": "Mendenhall Glacier",
                "audio": "map-72.mp3",
                "audio2": "map-72_2.mp3",
                "type": "ice",
                "marker-color": "#00c7fc",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -134.53769503047823,
                    58.456061820031636
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "64",
                "name": "Naayádi",
                "translation": "Child of the Clan",
                "location": "Lincoln Island",
                "audio": "map-64.mp3",
                "type": "land",
                "marker-color": "#4f7a28",
                "stroke": "#4f7a28",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#4f7a28",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -134.96913042035862,
                            58.49888410717395
                        ],
                        [
                            -134.96756089002076,
                            58.500885465329844
                        ],
                        [
                            -134.97588775124467,
                            58.50317539232623
                        ],
                        [
                            -134.9907929495233,
                            58.50449304386689
                        ],
                        [
                            -134.9944804978464,
                            58.502151767322715
                        ],
                        [
                            -134.9999379070195,
                            58.503585301320484
                        ],
                        [
                            -135.00450225844105,
                            58.505818615683836
                        ],
                        [
                            -135.0124734830302,
                            58.50990022100879
                        ],
                        [
                            -135.01549891222174,
                            58.514923629916495
                        ],
                        [
                            -135.0294488947603,
                            58.5208422719634
                        ],
                        [
                            -135.03113903258736,
                            58.51395036148932
                        ],
                        [
                            -135.0273344642649,
                            58.50948956490791
                        ],
                        [
                            -135.02495188296436,
                            58.50516747372069
                        ],
                        [
                            -135.01679700076696,
                            58.49861562501991
                        ],
                        [
                            -135.01394799428965,
                            58.49400071295729
                        ],
                        [
                            -135.0060140388387,
                            58.48540400808051
                        ],
                        [
                            -134.99143900802392,
                            58.47883154320513
                        ],
                        [
                            -134.9849999379779,
                            58.477037291838826
                        ],
                        [
                            -134.9757306462177,
                            58.47622818052483
                        ],
                        [
                            -134.96648696070022,
                            58.47289465829657
                        ],
                        [
                            -134.95613186694325,
                            58.47066332121261
                        ],
                        [
                            -134.9445067478198,
                            58.47100106961318
                        ],
                        [
                            -134.9389687137307,
                            58.47250576661693
                        ],
                        [
                            -134.94114428606153,
                            58.4756913757285
                        ],
                        [
                            -134.94654961983744,
                            58.479754318296585
                        ],
                        [
                            -134.95446071318545,
                            58.482130414244835
                        ],
                        [
                            -134.96251354797985,
                            58.48311341797293
                        ],
                        [
                            -134.96891335671728,
                            58.485995345556574
                        ],
                        [
                            -134.97092751068726,
                            58.48969946768919
                        ],
                        [
                            -134.9742184347148,
                            58.49300703282648
                        ],
                        [
                            -134.97253313031553,
                            58.493917990627224
                        ],
                        [
                            -134.9736542033403,
                            58.49615795490777
                        ],
                        [
                            -134.96913042035862,
                            58.49888410717395
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "54",
                "name": "Taan X'áat'i",
                "translation": "Sea Lion Island",
                "location": "Benjamin Island",
                "audio": "map-54.mp3",
                "type": "land",
                "marker-color": "#4f7a28",
                "stroke": "#4f7a28",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#4f7a28",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -134.90845278456047,
                            58.567202768766094
                        ],
                        [
                            -134.90803117261,
                            58.56550855127361
                        ],
                        [
                            -134.90799363010893,
                            58.56400707485895
                        ],
                        [
                            -134.91064389240353,
                            58.56395579465732
                        ],
                        [
                            -134.9123639631317,
                            58.56561313458866
                        ],
                        [
                            -134.91439688527177,
                            58.56650755911289
                        ],
                        [
                            -134.91590202894736,
                            58.56816416260193
                        ],
                        [
                            -134.91943931838424,
                            58.57024122097735
                        ],
                        [
                            -134.92185971159068,
                            58.57124343885576
                        ],
                        [
                            -134.92422912166288,
                            58.57096384177703
                        ],
                        [
                            -134.92366741680007,
                            58.56952479300986
                        ],
                        [
                            -134.92196294721077,
                            58.56941533071961
                        ],
                        [
                            -134.9227394470643,
                            58.56805916220796
                        ],
                        [
                            -134.9217047839597,
                            58.56598717655572
                        ],
                        [
                            -134.91823521659012,
                            58.564069026998595
                        ],
                        [
                            -134.91662000026398,
                            58.560365225979524
                        ],
                        [
                            -134.9123611925612,
                            58.558195159811646
                        ],
                        [
                            -134.91048263437665,
                            58.555714933680775
                        ],
                        [
                            -134.90930751173764,
                            58.55452701609221
                        ],
                        [
                            -134.90767414559048,
                            58.55457236292273
                        ],
                        [
                            -134.9073770180044,
                            58.55548504163073
                        ],
                        [
                            -134.90443037284143,
                            58.55562289361325
                        ],
                        [
                            -134.90161718461175,
                            58.555118652536635
                        ],
                        [
                            -134.89761735086978,
                            58.555439146367746
                        ],
                        [
                            -134.89643438765762,
                            58.55521008441502
                        ],
                        [
                            -134.89565054365875,
                            58.555991722082126
                        ],
                        [
                            -134.8970516847466,
                            58.55905595462366
                        ],
                        [
                            -134.90274726503048,
                            58.56360926606848
                        ],
                        [
                            -134.90300695583693,
                            58.565152121449785
                        ],
                        [
                            -134.9038341544548,
                            58.565152121449785
                        ],
                        [
                            -134.90633676002145,
                            58.56667202676732
                        ],
                        [
                            -134.90845278456047,
                            58.567202768766094
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "50",
                "name": "Ch'eet' Taayí",
                "translation": "Murrelet Fat*",
                "location": "Cowee Creek, Echo Cove",
                "audio": "map-50.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -134.94767091342695,
                        58.674427885164874
                    ],
                    [
                        -134.9450946798927,
                        58.67297525215819
                    ],
                    [
                        -134.94169034048102,
                        58.66819313812799
                    ],
                    [
                        -134.94178751520332,
                        58.66613932614176
                    ],
                    [
                        -134.94100323631778,
                        58.66532641479783
                    ],
                    [
                        -134.93751404264734,
                        58.665165834747526
                    ],
                    [
                        -134.93783718830906,
                        58.66407038621911
                    ],
                    [
                        -134.94085615269495,
                        58.663407542754086
                    ],
                    [
                        -134.9407719735543,
                        58.66252285182314
                    ],
                    [
                        -134.9377494162363,
                        58.662283205248556
                    ],
                    [
                        -134.93662366470738,
                        58.660578177827986
                    ],
                    [
                        -134.93808556944163,
                        58.65955762583789
                    ],
                    [
                        -134.9375401983874,
                        58.65873644955489
                    ],
                    [
                        -134.93450538775218,
                        58.65807005928333
                    ],
                    [
                        -134.93264637483657,
                        58.65660076180903
                    ],
                    [
                        -134.92951180762986,
                        58.65658958415504
                    ],
                    [
                        -134.92883943446952,
                        58.65734260034776
                    ],
                    [
                        -134.9265524499496,
                        58.65721556269699
                    ],
                    [
                        -134.92540127153794,
                        58.65644489091102
                    ],
                    [
                        -134.92322044493278,
                        58.6570139820559
                    ],
                    [
                        -134.9203994906087,
                        58.65584356152641
                    ],
                    [
                        -134.91686616249797,
                        58.65528667951414
                    ],
                    [
                        -134.91747895718882,
                        58.65346514259751
                    ],
                    [
                        -134.91553830949883,
                        58.652305145097515
                    ],
                    [
                        -134.9093155134575,
                        58.65094652970584
                    ],
                    [
                        -134.91021174336672,
                        58.64964505001791
                    ],
                    [
                        -134.90864432000066,
                        58.64840094141522
                    ],
                    [
                        -134.90549846605234,
                        58.644320269890294
                    ],
                    [
                        -134.9082736687633,
                        58.642214630258906
                    ],
                    [
                        -134.90493971318628,
                        58.639600855025726
                    ],
                    [
                        -134.9054151807494,
                        58.63475772249171
                    ],
                    [
                        -134.89417867476647,
                        58.627692819949445
                    ],
                    [
                        -134.88571366132157,
                        58.62521440116299
                    ],
                    [
                        -134.86383372988976,
                        58.623979930754004
                    ],
                    [
                        -134.85790062887588,
                        58.62559049213451
                    ],
                    [
                        -134.8533558421485,
                        58.62574535605191
                    ],
                    [
                        -134.84842340536483,
                        58.62720763531841
                    ],
                    [
                        -134.83916390819095,
                        58.63112443766559
                    ],
                    [
                        -134.8357015995628,
                        58.63280022505846
                    ],
                    [
                        -134.83016692336741,
                        58.63247307308164
                    ],
                    [
                        -134.8267570580249,
                        58.63380493914017
                    ],
                    [
                        -134.8159536359125,
                        58.634872197066585
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "103",
                "name": "Shaa X'áat'i",
                "translation": "Mountain Island",
                "location": "Island at end of Fritz Cove",
                "audio": "map-103.mp3",
                "type": "land",
                "marker-color": "#4f7a28",
                "stroke": "#4f7a28",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#4f7a28",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -134.69728486988424,
                            58.363058291894134
                        ],
                        [
                            -134.6976170282733,
                            58.362332572379785
                        ],
                        [
                            -134.69842953009876,
                            58.36191005998745
                        ],
                        [
                            -134.69971658388482,
                            58.361192232455096
                        ],
                        [
                            -134.70016384228143,
                            58.36141523640265
                        ],
                        [
                            -134.70056890481132,
                            58.361296432517975
                        ],
                        [
                            -134.700158859493,
                            58.36113314328841
                        ],
                        [
                            -134.70026434760047,
                            58.36069772725571
                        ],
                        [
                            -134.70174484453716,
                            58.359064191731164
                        ],
                        [
                            -134.70247515363306,
                            58.35898553140291
                        ],
                        [
                            -134.7029225763681,
                            58.35920400196633
                        ],
                        [
                            -134.70348941461086,
                            58.35844146136853
                        ],
                        [
                            -134.7036614274724,
                            58.35679292432573
                        ],
                        [
                            -134.70553485663362,
                            58.35513108647166
                        ],
                        [
                            -134.7058533910509,
                            58.35378883666277
                        ],
                        [
                            -134.70260430190012,
                            58.351643047971976
                        ],
                        [
                            -134.69599334182905,
                            58.347683306650026
                        ],
                        [
                            -134.6951044185897,
                            58.34720219669855
                        ],
                        [
                            -134.69475980434723,
                            58.34721377139145
                        ],
                        [
                            -134.69467055099685,
                            58.34739849575718
                        ],
                        [
                            -134.69257804730398,
                            58.347214986371
                        ],
                        [
                            -134.69206102733534,
                            58.34736902593719
                        ],
                        [
                            -134.69167764165067,
                            58.34763550836949
                        ],
                        [
                            -134.69137040339902,
                            58.34773168984565
                        ],
                        [
                            -134.69114052805585,
                            58.34816869397507
                        ],
                        [
                            -134.6912183719195,
                            58.34835671401805
                        ],
                        [
                            -134.69143168286007,
                            58.34881167605462
                        ],
                        [
                            -134.6915347936214,
                            58.349511195542334
                        ],
                        [
                            -134.69212769688232,
                            58.350115692309146
                        ],
                        [
                            -134.69200978690407,
                            58.350683307345264
                        ],
                        [
                            -134.69253705719706,
                            58.351070390105036
                        ],
                        [
                            -134.69349535949,
                            58.3539580105583
                        ],
                        [
                            -134.69535158543337,
                            58.356180980502415
                        ],
                        [
                            -134.69643795943378,
                            58.35690939771811
                        ],
                        [
                            -134.6963990898546,
                            58.35865437208042
                        ],
                        [
                            -134.69592312725908,
                            58.358830369614935
                        ],
                        [
                            -134.69577542751205,
                            58.359182558152725
                        ],
                        [
                            -134.69643427270933,
                            58.35978901590252
                        ],
                        [
                            -134.69650687088284,
                            58.360559330578525
                        ],
                        [
                            -134.69676461007518,
                            58.360755703706474
                        ],
                        [
                            -134.6968753561355,
                            58.36141111648371
                        ],
                        [
                            -134.69657958992366,
                            58.361772191893664
                        ],
                        [
                            -134.69680155678645,
                            58.36223240130974
                        ],
                        [
                            -134.69676447199868,
                            58.362655275199614
                        ],
                        [
                            -134.69728486988424,
                            58.363058291894134
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "106",
                "name": "Kadigooni X'áat'",
                "translation": "Island That Has Spring Water",
                "location": "Auke Bay",
                "audio": "map-106.mp3",
                "type": "land",
                "marker-color": "#4f7a28",
                "stroke": "#4f7a28",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#4f7a28",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -134.66111549365863,
                            58.34367628756033
                        ],
                        [
                            -134.6623000814335,
                            58.34289651067732
                        ],
                        [
                            -134.66331035866995,
                            58.3429567859082
                        ],
                        [
                            -134.66431985798354,
                            58.342657570466855
                        ],
                        [
                            -134.66465420434935,
                            58.34211917295892
                        ],
                        [
                            -134.66549282607036,
                            58.3418505149333
                        ],
                        [
                            -134.6643604475231,
                            58.338097714772374
                        ],
                        [
                            -134.66330622803957,
                            58.33754207377831
                        ],
                        [
                            -134.66302969100622,
                            58.33658120413534
                        ],
                        [
                            -134.66297516574343,
                            58.33510528906879
                        ],
                        [
                            -134.6640202047623,
                            58.33478839095082
                        ],
                        [
                            -134.66506516555057,
                            58.33481716596947
                        ],
                        [
                            -134.66606179024166,
                            58.33571178392006
                        ],
                        [
                            -134.66683560862492,
                            58.335943277315664
                        ],
                        [
                            -134.66661914695527,
                            58.33640718582507
                        ],
                        [
                            -134.66722741980487,
                            58.33649424927171
                        ],
                        [
                            -134.66821355901092,
                            58.33588531735052
                        ],
                        [
                            -134.66820684215412,
                            58.33536494247028
                        ],
                        [
                            -134.66955806815506,
                            58.33378336960786
                        ],
                        [
                            -134.66943593965328,
                            58.333011574638675
                        ],
                        [
                            -134.6691420592545,
                            58.33167511280476
                        ],
                        [
                            -134.66743655072725,
                            58.330375819160366
                        ],
                        [
                            -134.6655868856604,
                            58.33023494606917
                        ],
                        [
                            -134.66482875388476,
                            58.330939655225734
                        ],
                        [
                            -134.66330434089977,
                            58.33210062553076
                        ],
                        [
                            -134.66324997016324,
                            58.333297048335595
                        ],
                        [
                            -134.66220806058232,
                            58.33369744187797
                        ],
                        [
                            -134.66133363053086,
                            58.33304001986909
                        ],
                        [
                            -134.65996971308334,
                            58.33244166929785
                        ],
                        [
                            -134.65810766578372,
                            58.33269786942614
                        ],
                        [
                            -134.65595254595394,
                            58.33389796359941
                        ],
                        [
                            -134.65609316377046,
                            58.335249493589316
                        ],
                        [
                            -134.65511708691668,
                            58.33722134281737
                        ],
                        [
                            -134.65471421759622,
                            58.33795122718905
                        ],
                        [
                            -134.65403012104048,
                            58.33877189778369
                        ],
                        [
                            -134.65430091089473,
                            58.33909508808139
                        ],
                        [
                            -134.65372500583067,
                            58.339949826223176
                        ],
                        [
                            -134.65246950284737,
                            58.34107539724371
                        ],
                        [
                            -134.6507809426442,
                            58.34146231047151
                        ],
                        [
                            -134.65029470018928,
                            58.34092733554792
                        ],
                        [
                            -134.64930984454716,
                            58.340334551345535
                        ],
                        [
                            -134.64807852404368,
                            58.3404529773932
                        ],
                        [
                            -134.6501648292197,
                            58.34149207686153
                        ],
                        [
                            -134.65032317531586,
                            58.341789924886285
                        ],
                        [
                            -134.65098942709952,
                            58.341968837414555
                        ],
                        [
                            -134.6519350252758,
                            58.34220740638381
                        ],
                        [
                            -134.65190076978763,
                            58.343435841322304
                        ],
                        [
                            -134.65081171932624,
                            58.34412839974556
                        ],
                        [
                            -134.6527141144274,
                            58.344581088687875
                        ],
                        [
                            -134.65349145731636,
                            58.34506529380684
                        ],
                        [
                            -134.6541789050243,
                            58.34461102293065
                        ],
                        [
                            -134.66111549365863,
                            58.34367628756033
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "107",
                "name": "Yaana.éit X'áat'i",
                "translation": "WIld Celery Island",
                "location": "Auke Bay",
                "audio": "map-107.mp3",
                "type": "land",
                "marker-color": "#4f7a28",
                "stroke": "#4f7a28",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "#4f7a28",
                "fill-opacity": 0.2
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -134.66263225158133,
                            58.35308613827726
                        ],
                        [
                            -134.6628561637219,
                            58.35304132638608
                        ],
                        [
                            -134.6633234608837,
                            58.35251548973965
                        ],
                        [
                            -134.66396096981268,
                            58.352090328482944
                        ],
                        [
                            -134.66472515017264,
                            58.35181824153517
                        ],
                        [
                            -134.66489782801298,
                            58.35167315450799
                        ],
                        [
                            -134.66516573049063,
                            58.35171527652769
                        ],
                        [
                            -134.66523452496347,
                            58.35160090874703
                        ],
                        [
                            -134.66501782295103,
                            58.351216938314366
                        ],
                        [
                            -134.66485097670017,
                            58.35082466295203
                        ],
                        [
                            -134.66507390979183,
                            58.350632056734625
                        ],
                        [
                            -134.66530385561416,
                            58.34986011402977
                        ],
                        [
                            -134.6656157780453,
                            58.34966787287104
                        ],
                        [
                            -134.66541433167438,
                            58.34945805683453
                        ],
                        [
                            -134.66546662443128,
                            58.34934081755296
                        ],
                        [
                            -134.66520781786997,
                            58.34905552406296
                        ],
                        [
                            -134.66436446807404,
                            58.34885775367169
                        ],
                        [
                            -134.6643036375875,
                            58.34892232480665
                        ],
                        [
                            -134.66436614112672,
                            58.34903706005698
                        ],
                        [
                            -134.66414685551993,
                            58.3491533524418
                        ],
                        [
                            -134.66359531121634,
                            58.34919087685878
                        ],
                        [
                            -134.66312624175472,
                            58.34895918581246
                        ],
                        [
                            -134.66247752661798,
                            58.349027590152694
                        ],
                        [
                            -134.66244600262104,
                            58.34918245221952
                        ],
                        [
                            -134.6628377646162,
                            58.349409376268916
                        ],
                        [
                            -134.66239039607916,
                            58.34971248182154
                        ],
                        [
                            -134.66198850822653,
                            58.34984077368753
                        ],
                        [
                            -134.6616381922695,
                            58.3498373320341
                        ],
                        [
                            -134.66145878725146,
                            58.3500095543923
                        ],
                        [
                            -134.66146424497458,
                            58.35016680221531
                        ],
                        [
                            -134.66162019143195,
                            58.35036420536049
                        ],
                        [
                            -134.66190255030148,
                            58.350528366442205
                        ],
                        [
                            -134.6618782389314,
                            58.35070979288304
                        ],
                        [
                            -134.66126456969857,
                            58.35082741635091
                        ],
                        [
                            -134.66122018012558,
                            58.35096665944633
                        ],
                        [
                            -134.66191448126466,
                            58.35186168230584
                        ],
                        [
                            -134.6617853653011,
                            58.35213332115029
                        ],
                        [
                            -134.66242753287958,
                            58.35297602099743
                        ],
                        [
                            -134.66263225158133,
                            58.35308613827726
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "89",
                "name": "G̲athéeni",
                "translation": "Sockeye Creek",
                "location": "Auke Creek",
                "audio": "map-89.mp3",
                "type": "water",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -134.6423322441088,
                        58.380222498610465
                    ],
                    [
                        -134.6417280789383,
                        58.38074713920179
                    ],
                    [
                        -134.64153725823314,
                        58.38086875721896
                    ],
                    [
                        -134.6404814063315,
                        58.38119040179195
                    ],
                    [
                        -134.63945898412118,
                        58.38153575805363
                    ],
                    [
                        -134.63846948966804,
                        58.38188482161362
                    ],
                    [
                        -134.63750277721644,
                        58.382245794746325
                    ],
                    [
                        -134.6365932246315,
                        58.38247112718082
                    ],
                    [
                        -134.63532356415354,
                        58.38261328882399
                    ],
                    [
                        -134.63422696515832,
                        58.38301467346466
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": "44",
                "name": "Yayéinaa Héeni",
                "translation": "Whetstone Creek",
                "location": "Shazah Creek",
                "audio": "map-44.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "stroke": "#0056d6",
                "stroke-width": 2,
                "stroke-opacity": 1
            },
            "geometry": {
                "coordinates": [
                    [
                        -133.48475373804354,
                        58.81569575000606
                    ],
                    [
                        -133.48721897031763,
                        58.81725940177165
                    ],
                    [
                        -133.490485403081,
                        58.81893998287384
                    ],
                    [
                        -133.49358749397499,
                        58.82041840340875
                    ],
                    [
                        -133.49677175232912,
                        58.82118417816645
                    ],
                    [
                        -133.49938078981936,
                        58.822035019171864
                    ],
                    [
                        -133.50036688160068,
                        58.82291774524518
                    ],
                    [
                        -133.50392092479612,
                        58.82385362240436
                    ],
                    [
                        -133.50579039260398,
                        58.82448107135258
                    ],
                    [
                        -133.50839943009422,
                        58.822726312713684
                    ],
                    [
                        -133.5112344472094,
                        58.82270504236726
                    ],
                    [
                        -133.51177416088274,
                        58.82175618515879
                    ],
                    [
                        -133.51538983488484,
                        58.82017146427009
                    ],
                    [
                        -133.52032029943328,
                        58.81857603450521
                    ],
                    [
                        -133.5207781498511,
                        58.81785556349186
                    ],
                    [
                        -133.52240109443164,
                        58.81795129333727
                    ],
                    [
                        -133.5250512191264,
                        58.816174929830225
                    ],
                    [
                        -133.5285641751171,
                        58.8159302738344
                    ],
                    [
                        -133.53224148264022,
                        58.81419635723782
                    ],
                    [
                        -133.53429584286872,
                        58.81269639689194
                    ],
                    [
                        -133.53795260407534,
                        58.81216448047863
                    ],
                    [
                        -133.54082880920458,
                        58.80951535742528
                    ],
                    [
                        -133.5422874049668,
                        58.80895147405414
                    ],
                    [
                        -133.54232849217152,
                        58.80779176057408
                    ],
                    [
                        -133.54510187847984,
                        58.8063660046314
                    ],
                    [
                        -133.548060157906,
                        58.80426982220891
                    ],
                    [
                        -133.55025832335042,
                        58.80303546382078
                    ],
                    [
                        -133.5514703958851,
                        58.80181170310311
                    ],
                    [
                        -133.5536891049319,
                        58.800630467038275
                    ],
                    [
                        -133.55535313671714,
                        58.80056661529454
                    ],
                    [
                        -133.5558872766806,
                        58.80030055730799
                    ],
                    [
                        -133.55711989281767,
                        58.80043890366497
                    ],
                    [
                        -133.56042741278557,
                        58.800268631147475
                    ],
                    [
                        -133.56231742419578,
                        58.79971523969451
                    ],
                    [
                        -133.56293373226418,
                        58.7999600100793
                    ],
                    [
                        -133.5657481950473,
                        58.79973652945449
                    ],
                    [
                        -133.5681723401169,
                        58.798310442448695
                    ],
                    [
                        -133.57113061884607,
                        58.79814015948671
                    ],
                    [
                        -133.5751571648938,
                        58.79705458595981
                    ],
                    [
                        -133.57850577206625,
                        58.79557517128595
                    ],
                    [
                        -133.58411763324312,
                        58.79372165985956
                    ],
                    [
                        -133.58707591197208,
                        58.79200792287557
                    ],
                    [
                        -133.5880209176773,
                        58.791305372702
                    ],
                    [
                        -133.58750732762007,
                        58.79068796838675
                    ],
                    [
                        -133.59104082669361,
                        58.78693008052048
                    ],
                    [
                        -133.59579988741504,
                        58.78331387533822
                    ],
                    [
                        -133.59812131447322,
                        58.78154638553721
                    ],
                    [
                        -133.60392699637316,
                        58.77999708384965
                    ],
                    [
                        -133.60854930688717,
                        58.77876186198486
                    ],
                    [
                        -133.61664349269248,
                        58.77747334396642
                    ],
                    [
                        -133.62075221314944,
                        58.776557513693376
                    ],
                    [
                        -133.62471711602223,
                        58.773799213573994
                    ],
                    [
                        -133.62626068196346,
                        58.77052760887537
                    ],
                    [
                        -133.6248431763455,
                        58.765940910300515
                    ],
                    [
                        -133.6244817386466,
                        58.76357301777273
                    ],
                    [
                        -133.62203704997467,
                        58.76113332399888
                    ],
                    [
                        -133.62036881285715,
                        58.75836617722405
                    ],
                    [
                        -133.6165066156275,
                        58.75725804924403
                    ],
                    [
                        -133.61579988448204,
                        58.754653043626206
                    ],
                    [
                        -133.6122095396679,
                        58.751278859144634
                    ],
                    [
                        -133.612147908861,
                        58.75058614207751
                    ],
                    [
                        -133.61438716150997,
                        58.74996801365711
                    ],
                    [
                        -133.6134216122026,
                        58.74862514508149
                    ],
                    [
                        -133.61528824058428,
                        58.74746171117701
                    ],
                    [
                        -133.61387073202656,
                        58.746044135092234
                    ],
                    [
                        -133.6134393163786,
                        58.743795080026615
                    ]
                ],
                "type": "LineString"
            },
            "id": 158
        },
        {
            "type": "Feature",
            "properties": {
                "id": "44",
                "name": "Yayéinaa Héeni",
                "translation": "Whetstone Creek",
                "location": "Shazah Creek",
                "audio": "map-44.mp3",
                "type": "creek",
                "marker-color": "#0056d6",
                "marker-size": "medium",
                "marker-symbol": "circle"
            },
            "geometry": {
                "coordinates": [
                    -133.6084264995748,
                    58.77875629258418
                ],
                "type": "Point"
            },
            "id": 159
        }
    ]
}