import React, { Component } from 'react';
import { Container, Header, Button, Icon, Divider, Form, Input, Transition, Image, Grid, Popup, List, Checkbox} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { API_URL, TUTORIAL_URL, ICON_URL } from '../App.js';
// import {lessonsList, dialogueLibrary} from './constants/qaneryaurci.js';
// import { dialogueGenerator, exerciseGenerator } from './constants/dialogueListGenerator.js';
// import {sentenceTransitionTimings} from './constants/sentenceTransitionTimings.js';
// import '../semantic/dist/semantic.min.css';
import * as Scroll from 'react-scroll';
// import ReactGA from "react-ga4";
// ReactGA.initialize("G-WDEJDCK7QT")
import { TagColors } from './HomeHelper.js';
import { customFontFam3, customTitleFontFam3, customFirefoxTitleFontFam } from './constants/constants.js';
import { dialogueData } from './constants/dialogueData.js';
import './constants/dialogueData.css';

// import RecorderControls from './RecorderControls.js';


var scroll    = Scroll.animateScroll;
// let customFontFam = "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"

let customFontFam = customFontFam3

let tlingitAudioClips = [
// Phase 1:
// ["Waa sá iduwasáakw","“What are you called?” or “What is your name?”","what are you called in tlingit.mp3"],



// ["X̲at","me","",],
// ["_____ yéi x̲at duwasáakw","“______, that is what I am called” or “my name is ____”","i am called Koodeik\'.mp3"],
// ["_____ yéi duwasáakw","“_____ that is what s/he/it is called” or “His/her/its name is ____”","",],
// ["Waa sá duwasáakw","“What is it/s/he called?”","",],
// ["_____ yéi iduwasáakw yéi kwshé","“You are called ______, right?”","",],
// ["Aaá","yes","yes.mp3"],
// ["Tléik’","no","no.mp3",],
["Waa sá iduwasáakw?","“What you called?”","thisone.mp3"],

["Koodéik yéi x̲at duwasáakw","“______, that is what I am called” or “my name is ____”","i am called Koodeik\'.mp3"],
["Waa sá duwasáakw yáat’aa?","“What called this one here?”","thisone.mp3"],
["_______ yéi duwasáakw yáat’aa.","“That one is called ______”","",],
["Waa śa duwasáakw wéit’aa?","“What is that one called there?”","whatisthatonecalled.mp3"],
["_______ yéi duwasáakw wéit’aa.","“That one is called _____”","",],
// ["Haandéi ijín","“Give me your hand” (so we can shake hands)","",],
// ["Gunalchéesh","“Thank you”","",],

// Noun Bank: 
// ["Aas","tree","",],
// ["Yaakw","boat ","",],
// ["Xoots","brown bear","brown bear.mp3"],
// ["Gút","dime ","",],
// ["Hít","house","",],
// ["Té","rock","",],
// ["Kooxéedaa","pencil or pen","",],
// ["X’úx’","paper ","noun-1324-hw.mp3",],
// ["liG̲éinaa","tissue (emp: on nose)","tissue.mp3"],
// ["jiG̲éinaa","towel (emp: on hands)","towel.mp3"],
// ["Kées","bracelet ","",],
 
// Phase 2:
["Daak̲w aa naa-x̲ sá isitee?","“Which moiety are you?” or “Which clan are you?”","whichclanyou.mp3"],
["Ch’áak’ naa-x̲ x̲at sitee.","“I am eagle moiety”","iameagle.mp3"],
// ["Yéil naa-x̲ x̲at sitee","“I am raven moiety”","",],
// ["Ch’áak’ naa-x̲ gé isitee","“Are you eagle moiety?”","areyoueagle.mp3"],
// ["Aaá, ch’áak’ naa-x̲ x̲at sitee, clan áyá x̲at","“Yes, I am eagle moiety”","",],
// ["Tléik’, yéil naa-x̲ x̲at sitee, clan áyá x̲at","“No, I am raven moiety”","",],
// ["Daakw aa naa-x̲ sá sitee","“Which moiety is s/he/it?”","",],
// ["Yéil/ch’áak’ naa-x̲ sitee, wéit’aa","S/he/it is raven/eagle moiety.","",],
 
// Phase 3:
// ["Waa sá duwasáakw, yáat’aa","“What is this one here called?”","",],
// ["_____ yéi duwasáakw wéit’aa","“That one there is called ______”","",],
// ["Noun ax̲ tuwáa sigóo","I want ______.","",],
// ["Ná","“Here!” (giving something to someone)","",],
// ["Waa sá duwasáakw lingít x̲’eináx̲","“What is s/he/it called in the Tlingit language?”","",],
// ["_____ yéi duwasáakw lingít x̲’eináx̲","“S/he/it is called ______ in the Tlingit language.”","",],
// ["Waa sá duwasáakw, yáat’aa k̲a yáat’aa","“What is this here and this here called?”","",],

["Daa sá i tuwáa sigóo?","What do you want?","want.mp3"],
// ["Tlél x̲wasikóo. Daa sá i tuwáa sigóo.","I don’t know. What do you want?","",],
["Wé g̲áatl ax̲ tuwáa sigóo.","I want the pilot bread. ","s0371.mp3",], 
 
["Daa sá i tuwáa sigóo?","What do you want?",""],

["ldakát át ax̲ tuwáa sigóo.","I want everything.","iwant.mp3",],

// ["Daak̲w aa sá i tuwáa sigóo ____ kachoo ____?","“Which one do you want _____ or ____?”","12_333.mp3",],

// ["_____ ax̲ tuwáa sigóo","I want _____.",""],
// ["_____ gé i tuwáa sigóo","“Do you want _____?”","",],
// ["Aaá, _____ ax̲ tuwáa sigóo","Yes, I want _____","",],


// ["Daak̲w aa sá du tuwáa sigóo","“Which one does s/he/it want?”","",],
// ["_____ du tuwáa sigóo","S/he/it wants ______. ","",],



// Phase 4
// ["Yóo x̲’ax̲atánk","I am speaking","i am speaking.mp3"],
// ["yóo tux̲datánk","I am thinking ","",],
// ["ee een yóo x̲’ax̲atánk","I am speaking with you (sg)","",],
// ["yee een yóo x̲’ax̲atánk","I am speaking with you all.","",],
// ["ax̲ tuwáa sigóo ee een yóo x̲’ax̲ataaní","I want to speak with you (sg)","iwanttospeaktoyou.mp3"],
// ["Góok","Go ahead","",],
 
// ["ch’áak’ naa-x̲ siteeyee aa, kíndei latsaak̲ ijin.","All of you who are eagles, raise your hand. ","",],
// ["yéil naa-x̲ sitee aa, kíndei latsaak̲ ijín","all of you who are ravens, raise your hand. ","",],
// ["aadéi nay.á","you all go over there.","",],
// ["ldakát ch’aak’ naa-x̲ siteeyee aa ax̲ tuwáa sigóo lingít x̲’éináx̲ yee een yóo x̲’ax̲ataani","All of you who are eagles, I want to speak with you all in the Tlingit language. ","",],
// ["ldakát yéil naa-x̲ siteeyee aa ax̲ tuwáa sigóo lingít x̲’éináx̲ yee een yóo x̲’ax̲ataani","All of you who are ravens, I want to speak with you all in the Tlingit language. ","all of you who are ravens, i want to speak with you all in the tlingit langauge.mp3"],
 
// Final (Script)
// ["Ldakát _____ naa-x̲ siteeyee aa ax̲ tuwáa sigóo lingít x̲’éináx̲ yee een yóo x̲’ax̲ataaní","All of you who are _____ moiety, I want to speak to you all in the Tlingit language. ","all of you who are ravens, i want to speak with you all in the tlingit langauge.mp3"],
// ["Góok","go ahead","goahead.mp3"],
// ["Yáat’aa _______ yéi duwasáakw _______ naa-x̲ sitee _______ áyá/áwé","This one here, his/her/it is called. s/he/it is _____ moiety. S/he/it is _______. ","this one here, his/her/it is called. s/he/it is ___ moiety. s/he/it is ___..mp3"],
// ["Gunalchéesh haandéi ijín","Thank you, give me your hand. ","give me your hand.mp3",],
["Gunalchéesh!","thank you. ","thankyougunalcheesh.mp3"],
]


class Dialogues extends Component {
  constructor(props) {
    super(props);
    console.log(props)
    const currentLesson = decodeURI(props.match.params.num)
    // const dialog_gen = dialogueGenerator()
    // const exercise_gen = exerciseGenerator(dialog_gen.lessons[currentLesson],dialog_gen.dialogues,dialog_gen.bagOfWords)
    this.state = {
      // show: false,
      currentLesson: currentLesson,
      automatic:false,
      automaticPause:false,
      // exerciseNumber: props.location.state === undefined ? -1 : props.location.state.exerciseNumber,
      // lessonIndex: props.location.state === undefined ? -1 : props.location.state.lessonIndex,
      // exerciseNumberMatrix: [],
      currentCounter: -1,
      currentlyPlaying:-1,
      countersEncountered:[],
      opened:[-1,-1],
      // // questionMode:0,
      // showCurrent: false,
      // correct:'assirpagta',
      // inputtedWords: '',
      // record:{},
      // randomCounters:{},
      // lesson: exercise_gen.lesson,
      // dialogues: exercise_gen.dialogues,
      // audioExists: true,
      visible:[true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,],
      // playingAudio:false,
      // showContinue:false,
      // showAll:false,
      showEnglish: [],
      showFullTranslation: false,
      // showEnglishMatrix:[],
      // showAlternateList:[],
    }
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.state.automatic && !this.state.automaticPause && prevState.goNext !== this.state.goNext && this.state.goNext && this.state.currentCounter !== dialogueData[this.state.currentLesson]['content'].length-1 ) {
      this.playNextCounterAutomatic()
    }
  }

  componentWillMount() {
    // this.initializeMatrix()
  }


  soundEnded(goNext) {
    if (goNext) {
      this.setState({
        playingAudio: false,
        goNext:true,
        visible:[true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,],
        currentlyPlaying:-1,
      });      
    } else {
      this.setState({
        playingAudio: false,
        visible:[true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,],
        currentlyPlaying:-1,
      });           
    }

  }

  repeatAudio(audio, goNext, event, data) {
    if (!this.state.playingAudio) {

      this.sound = new Audio(API_URL + "/tlingitaudiolibrary/" +  audio);
      this.setState({playingAudio: true});

      this.sound.play()

      this.sound.onended=()=>{
        this.soundEnded(goNext)
      }
    }
  }


  processStyledText = (sentence) => {     
    sentence = sentence.replace("⟨","").replace("⟩","").replace("«","").replace("»","")
    let matches = sentence.match(/⎡.*?⎤/g)
    let matches1 = sentence.match(/{.*?}/g)
    let matches2 = sentence.match(/_.*?_/g)
    if (matches !== null || matches1 !== null || matches2 !== null) {
      if (matches !== null) {
        matches.map((m) => sentence = sentence.replace(m,'<i>'+m.slice(1,-1)+'</i>'))           
      }
      if (matches1 !== null) {
        matches1.map((m) => sentence = sentence.replace(m,m.slice(1,-1)))           
      }
      if (matches2 !== null) {
        matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
      }
      return <span dangerouslySetInnerHTML={{__html: sentence}} />    
    } else {
      return <span>{sentence}</span>
    }
  }

  processStyledTextLink = (sentence) => {
    sentence = sentence.replaceAll(">","")


    sentence = sentence.replaceAll("⟨","").replaceAll("⟩","")
    let matches = sentence.match(/⎡.*?⎤/g)
    let matches1 = sentence.match(/{.*?}/g)
    let matches2 = sentence.match(/_.*?_/g)
    // console.log(matches2)
    if (matches !== null || matches1 !== null || matches2 !== null) {
      if (matches !== null) {
        matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
      }
      if (matches1 !== null) {
        matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
      }
      if (matches2 !== null) {
        matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
      }
      return <span style={{whiteSpace:'nowrap'}} dangerouslySetInnerHTML={{__html: sentence}} />    
    } else {
      return <span style={{whiteSpace:'nowrap'}}>{sentence}</span>
    }


    // let matches = sentence.match(/\⎡.*?\⎤/g)
    // if (matches !== null) {
    //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
    //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
    // } else {
    //   return <span>{sentence}</span>
    // }
  }



  timeoutCounter = (timeStampList) => {
    if (timeStampList !== undefined && timeStampList.length > 0) {
      this.setState({visible:[true,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,]},()=>{
        timeStampList.map((k,kindex)=>{
          this.timeoutID = setTimeout(() => {
            let vis = this.state.visible
            vis[kindex+1] = true
            this.setState({visible:vis})
            // console.log('vis',vis)
          }, timeStampList[kindex]);
        })
      })
    }
  }

  playNextCounter = () => {
    // let dialogueInfo = {
    //   0:[[600,1000,2100],'tlingitdialogue-06.mp3'],
    //   1:[[600,2100],'tlingitdialogue-07.mp3'],
    //   2:[[1000,2100,3000],'tlingitdialogue-08.mp3'],
    //   3:[[],'tlingitdialogue-09.mp3'],
    // }
    scroll.scrollToBottom();
    let current = this.state.currentCounter+1
    // console.log(current)

    if (dialogueData[this.state.currentLesson]['content'][current]['type']=='voicerecord') {
      this.setState({automaticPause:true, goNext:true,currentCounter:current,countersEncountered:[...this.state.countersEncountered,current]});
    } else if (dialogueData[this.state.currentLesson]['content'][current]['type']=='dialogueoriginal') {
      this.setState({goNext:false,currentlyPlaying:current,currentCounter:current,countersEncountered:[...this.state.countersEncountered,current]},()=>{
      this.timeoutCounter(dialogueData[this.state.currentLesson]['content'][current]['visibility']); 
      this.repeatAudio(dialogueData[this.state.currentLesson]['content'][current]['mp3'],true)        
      });
    } else if (dialogueData[this.state.currentLesson]['content'][current]['type']=='dialogue') {
      this.setState({goNext:false,currentCounter:current,countersEncountered:[...this.state.countersEncountered,current]});
      // this.setState({goNext:false,currentlyPlaying:current,currentCounter:current,countersEncountered:[...this.state.countersEncountered,current]},()=>{
      // this.timeoutCounter(dialogueData[this.state.currentLesson]['content'][current]['visibility']); 
      // this.repeatAudio(dialogueData[this.state.currentLesson]['content'][current]['mp3'])        
      // });
    }
  }

  playNextCounterAutomatic = () => {
    // let dialogueInfo = {
    //   0:[[600,1000,2100],'tlingitdialogue-06.mp3'],
    //   1:[[600,2100],'tlingitdialogue-07.mp3'],
    //   2:[[1000,2100,3000],'tlingitdialogue-08.mp3'],
    //   3:[[],'tlingitdialogue-09.mp3'],
    // }
    scroll.scrollToBottom();
    let current = this.state.currentCounter+1
    // console.log(current)
    if (dialogueData[this.state.currentLesson]['content'][current]['type']=='voicerecord') {
      this.setState({automaticPause:true, goNext:true,currentCounter:current,countersEncountered:[...this.state.countersEncountered,current]});
    } else if (dialogueData[this.state.currentLesson]['content'][current]['type']=='dialogueoriginal') {
      this.setState({goNext:false,currentlyPlaying:current,currentCounter:current,countersEncountered:[...this.state.countersEncountered,current]},()=>{
      this.timeoutCounter(dialogueData[this.state.currentLesson]['content'][current]['visibility']); 
      this.repeatAudio(dialogueData[this.state.currentLesson]['content'][current]['mp3'],true)        
      });
    } else if (dialogueData[this.state.currentLesson]['content'][current]['type']=='dialogue') {
      this.setState({goNext:false,currentCounter:current,countersEncountered:[...this.state.countersEncountered,current]});
      // this.setState({goNext:false,currentlyPlaying:current,currentCounter:current,countersEncountered:[...this.state.countersEncountered,current]},()=>{
      // this.timeoutCounter(dialogueData[this.state.currentLesson]['content'][current]['visibility']); 
      // this.repeatAudio(dialogueData[this.state.currentLesson]['content'][current]['mp3'])        
      // });
    }
  }


  render() {
    console.log(this.state)
    // console.log(this.props)
    // let exerciseNum = this.state.exerciseNumber
    // if (exerciseNum == '10') {
    //   //alternate between exerciseNumbers
    // }
    const addAudioElement = (blob) => {
      const url = URL.createObjectURL(blob);
      const audio = document.createElement("audio");
      audio.src = url;
      audio.controls = true;
      document.body.appendChild(audio);
    };

    return (
      <div className="app" style={{paddingRight:5,paddingLeft:5}}>
      <Grid textAlign='center'>
      <Grid.Column style={{maxWidth:'800px'}}>

        <div style={{justifyContent:'space-between',display:'flex'}}>
          <div>
          <Icon onClick={()=>{this.props.history.goBack()}} circular style={{margin:0,color:'#B1B1B1',cursor:'pointer',fontSize:'22px'}} name='chevron left' />
          </div>
          <div>
            <div style={{textAlign:'center',fontWeight:'bold',fontSize:'14px'}}>{"Introductions"}</div>
            <div style={{textAlign:'center',color:'grey',fontStyle:'italic'}}>{'Preliminary audio'}</div>
          </div>
          <div style={{width:36}} />
        </div>
        <Divider />
        <div>

        {this.state.currentCounter == -1 ?
          <Button style={{margin:20}} onClick={()=>{this.playNextCounter()}}>Start Manual</Button>
          :
          null
        }

        {this.state.currentCounter == -1 ?
          <Button style={{margin:20}} onClick={()=>{this.setState({automatic:true, automaticPause:false},()=>{this.playNextCounterAutomatic()})}}>Start Automatic</Button>
          :
          null
        }

        {this.state.currentCounter > -1 ?
          <Button style={{margin:20}} onClick={()=>{this.sound.pause(); clearTimeout(this.timeoutID); this.soundEnded(); this.setState({automatic:false, currentCounter:-1,countersEncountered:[]})}}>Restart</Button>
          :
          null
        }

        {this.state.playingAudio ?
          <Button style={{margin:20}} onClick={()=>{this.sound.pause(); clearTimeout(this.timeoutID); this.soundEnded(); this.setState({automaticPause:true,goNext:true})}}>Stop</Button>
          :
          null
        }


        {dialogueData[this.state.currentLesson]['type'] == 'soundboard2' ?

            <div style={{fontFamily:customFontFam,display:'flex',flexDirection:'row',alignItems:'center',fontSize:'20px',lineHeight:'30px'}}>
                <span style={{flex:6,display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap',fontSize:'20px',lineHeight:'30px'}}>
                {dialogueData[this.state.currentLesson]['content'].map((c,cindex)=>{
                  if (c.type == 'picture') {
                    return <div onClick={()=>{this.setState({currentlyPlaying:cindex},()=>{this.repeatAudio(c['mp3'],false)})}} style={{cursor:'pointer',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid #cbcbcb',borderRadius:10,padding:10,margin:5}}>
                    <Image style={{maxHeight:'70px',maxWidth:'70px'}} src={'/'+c.image} />
                    {c['t'].map((t,tindex)=>
                        <div style={{cursor:'pointer', marginBottom:'2px',marginTop:'5px',paddingBottom:'1px',marginLeft:'10px',fontSize:'20px', borderBottom:'2px dotted #b5b5b5'}}>
                          <span dangerouslySetInnerHTML={{__html: (this.state.playingAudio && this.state.currentlyPlaying === cindex ? t : t.replaceAll("<span","<span style='color:white'"))}} />
                        </div>
                        )}
{/*                    {c['t'].map((t,tindex)=>
                      <Popup hideOnScroll on='hover' onOpen={()=>{console.log('opened');this.setState({'opened':[cindex,tindex]})}} onClose={()=>{console.log('closed');this.setState({'opened':[-1,-1]})}} position='bottom' content={
                        <span style={{fontFamily:customFontFam, fontSize:'14px'}}>
                          <span dangerouslySetInnerHTML={{__html: c['e'][tindex]}} />
                        </span>
                      } trigger={
                        <div style={{cursor:'pointer', marginBottom:'2px',marginTop:'5px',paddingBottom:'1px',marginLeft:'10px',fontSize:'20px', borderBottom:'2px dotted #b5b5b5'}}>
                          <span dangerouslySetInnerHTML={{__html: (this.state.playingAudio && this.state.currentlyPlaying === cindex ? t : t.replaceAll("<span","<span style='color:white'"))}} />
                        </div>
                      } />
                    )}*/}
                    </div>
                  } else if (c.type == 'sentence') {
                    return <div onClick={()=>{this.setState({currentlyPlaying:cindex},()=>{this.repeatAudio(c['mp3'],false)})}} style={{cursor:'pointer',border:'1px solid #cbcbcb',borderRadius:10,padding:"10px 15px",paddingTop:'15px',margin:5}}>
                    {console.log(c)}
                    {c['t'].map((t,tindex)=>
                        <span style={{cursor:'pointer', margin:'5px',marginBottom:'2px',paddingBottom:'1px',fontSize:'20px', borderBottom:'2px dotted #b5b5b5'}}>
                          <span dangerouslySetInnerHTML={{__html: (this.state.playingAudio && this.state.currentlyPlaying === cindex ? t : t.replaceAll("<span","<span style='color:white'"))}} />
                        </span>
                    )}
                    {c['translation'].map((e,eindex)=>
                        <div style={{fontFamily:customFontFam,cursor:'pointer', marginBottom:'2px',marginTop:'10px',fontSize:'16px',fontStyle:'italic'}}>
                          <span style={{fontFamily:customFontFam}} dangerouslySetInnerHTML={{__html: e}} />
                        </div>
                    )}
                    </div>            
                  }}
                )}

              </span>

            </div>

          :
          null
        }

        {dialogueData[this.state.currentLesson]['type'] == 'soundboard1' ?

            <div style={{fontFamily:customFontFam,display:'flex',flexDirection:'row',alignItems:'center',fontSize:'20px',lineHeight:'30px'}}>
                <span style={{flex:6,display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap',fontSize:'20px',lineHeight:'30px'}}>
                {dialogueData[this.state.currentLesson]['content'].map((c,cindex)=>{
                  if (c.type == 'picture') {
                    return <div onClick={()=>{this.setState({currentlyPlaying:cindex},()=>{this.repeatAudio(c['mp3'],false)})}} style={{cursor:'pointer',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid #cbcbcb',borderRadius:10,padding:10,margin:10}}>
                    <Image style={{maxHeight:'70px',maxWidth:'70px'}} src={'/'+c.image} />
                    {c['t'].map((t,tindex)=>
                        <div style={{cursor:'pointer', marginBottom:'2px',marginTop:'5px',paddingBottom:'1px',marginLeft:'10px',fontSize:'20px', borderBottom:'2px dotted #b5b5b5'}}>
                          <span dangerouslySetInnerHTML={{__html: (this.state.playingAudio && this.state.currentlyPlaying === cindex ? t : t.replaceAll("<span","<span style='color:white'"))}} />
                        </div>
                        )}
{/*                    {c['t'].map((t,tindex)=>
                      <Popup hideOnScroll on='hover' onOpen={()=>{console.log('opened');this.setState({'opened':[cindex,tindex]})}} onClose={()=>{console.log('closed');this.setState({'opened':[-1,-1]})}} position='bottom' content={
                        <span style={{fontFamily:customFontFam, fontSize:'14px'}}>
                          <span dangerouslySetInnerHTML={{__html: c['e'][tindex]}} />
                        </span>
                      } trigger={
                        <div style={{cursor:'pointer', marginBottom:'2px',marginTop:'5px',paddingBottom:'1px',marginLeft:'10px',fontSize:'20px', borderBottom:'2px dotted #b5b5b5'}}>
                          <span dangerouslySetInnerHTML={{__html: (this.state.playingAudio && this.state.currentlyPlaying === cindex ? t : t.replaceAll("<span","<span style='color:white'"))}} />
                        </div>
                      } />
                    )}*/}
                    </div>
                  } else if (c.type == 'sentence') {
                    return <div onClick={()=>{this.setState({currentlyPlaying:cindex},()=>{this.repeatAudio(c['mp3'],false)})}} style={{cursor:'pointer',border:'1px solid #cbcbcb',borderRadius:10,padding:10,paddingBottom:65,margin:10}}>
                    {c['t'].map((t,tindex)=>
                      <Popup hideOnScroll on='click' open={true} position='bottom' style={{maxWidth:(document.getElementById(cindex+tindex) !== null ? document.getElementById(cindex+tindex).offsetWidth : 0)}} content={
                        <span style={{fontFamily:customFontFam, fontSize:'14px'}}>
                          <span style={{padding:1,}} dangerouslySetInnerHTML={{__html: c['e'][tindex]}} />
                        </span>
                      } trigger={
                        <span id={cindex+tindex} style={{cursor:'pointer', marginBottom:'2px',marginTop:'5px',paddingBottom:'1px',marginLeft:'10px',fontSize:'20px', borderBottom:'2px dotted #b5b5b5'}}>
                          <span dangerouslySetInnerHTML={{__html: (this.state.playingAudio && this.state.currentlyPlaying === cindex ? t : t.replaceAll("<span","<span style='color:white'"))}} />
                        </span>
                      } />
                    )}
                    </div>            
                  }}
                )}

              </span>

            </div>

          :
          null
        }


        {this.state.countersEncountered.map((k,kindex)=>
            {
              let currentType = dialogueData[this.state.currentLesson]['content'][k]['type']
              return <div>
            {currentType=='dialogue' ?

                <div style={{fontFamily:customFontFam,display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap',fontSize:'20px',lineHeight:'30px'}}>
                    <div style={{flex:1,height:'70px',width:'70px'}}><Image style={{maxHeight:'50px',maxWidth:'50px',margin:'10px 20px'}} src={'/tlingitperson'+dialogueData[this.state.currentLesson]['content'][k].speaker+'.png'} /></div>
                    <span style={{flex:6,display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap',fontSize:'20px',lineHeight:'30px'}}>
                    {dialogueData[this.state.currentLesson]['content'][k]['t'].map((t,tindex)=>
                      <Popup hideOnScroll on='click' onOpen={()=>{console.log('opened');this.setState({'opened':[kindex,tindex]})}} onClose={()=>{console.log('closed');this.setState({'opened':[-1,-1]})}} position='bottom' content={
                        <span style={{fontFamily:customFontFam, fontSize:'14px'}}>
                          <span dangerouslySetInnerHTML={{__html: dialogueData[this.state.currentLesson]['content'][k]['e'][tindex]}} />
                        </span>
                      } trigger={
                        <div style={{cursor:'pointer', marginBottom:'2px',marginTop:'5px',paddingBottom:'1px',marginLeft:'10px',fontSize:'20px', borderBottom:'2px dotted #b5b5b5'}}>
                          <span dangerouslySetInnerHTML={{__html: (this.state.currentlyPlaying == k && !this.state.visible[tindex] ? t.replaceAll("<span","<span style='color:white'"):(this.state.goNext || (this.state.playingAudio && this.state.currentlyPlaying === k) || this.state.currentCounter != k ? t : t.replaceAll("<span","<span style='color:white'")))}} />
                        </div>
                      } />
                    )}
                    {this.state.showFullTranslation ?
                      <Popup hideOnScroll on='click' position='bottom' content={
                        <div style={{fontFamily:customFontFam, fontSize:'14px'}}>
                          <span dangerouslySetInnerHTML={{__html: dialogueData[this.state.currentLesson]['content'][this.state.currentCounter]['translation']}} />
                        </div>
                      } trigger={
                        <Icon style={{color:'#d4d4d4',fontSize:'26px',marginLeft:'20px',marginTop:'0px',height:'34px'}} link name='comment alternate outline'>{'\n'}</Icon>
                      } />
                      :
                      null
                    }
                    {!this.state.automatic || !this.state.playingAudio ?
                      <span style={{marginLeft:'13px'}}><Icon circular disabled={this.state.playingAudio} name='volume up' style={{'color':'#a7a7a7',fontSize:'16px',cursor:(this.state.playingAudio ? '':'pointer')}} onClick={()=>{if (this.state.currentCounter == k) {this.timeoutCounter(dialogueData[this.state.currentLesson]['content'][k]['visibility'])}; this.setState({currentlyPlaying:k},()=>{this.repeatAudio(dialogueData[this.state.currentLesson]['content'][k]['mp3'],(this.state.currentCounter == k ? true:false))})}} /></span>
                      :
                      null
                    }
                  </span>

                </div>

              :
              null
            }

            {currentType=='dialogueoriginal' ?

                <div style={{fontFamily:customFontFam,display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap',fontSize:'20px',lineHeight:'30px'}}>
                    <div style={{flex:1,height:'70px',width:'70px'}}><Image style={{maxHeight:'50px',maxWidth:'50px',margin:'10px 20px'}} src={'/tlingitperson'+dialogueData[this.state.currentLesson]['content'][k].speaker+'.png'} /></div>
                    <span style={{flex:6,display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap',fontSize:'20px',lineHeight:'30px'}}>
                    {dialogueData[this.state.currentLesson]['content'][k]['t'].map((t,tindex)=>
                      <Popup hideOnScroll on='click' position='bottom' content={
                        <span style={{fontFamily:customFontFam, fontSize:'14px'}}>
                          <span dangerouslySetInnerHTML={{__html: dialogueData[this.state.currentLesson]['content'][k]['e'][tindex]}} />
                        </span>
                      } trigger={
                        <div style={{cursor:'pointer', marginBottom:'2px',marginTop:'5px',paddingBottom:'1px',marginLeft:'10px',fontSize:'20px', borderBottom:(this.state.currentlyPlaying == k && !this.state.visible[tindex] ? '2px dotted white':'2px dotted #b5b5b5')}}>
                          <span dangerouslySetInnerHTML={{__html: (this.state.currentlyPlaying == k && !this.state.visible[tindex] ? t.replaceAll("<span","<span style='color:#dbdbdb'"):t)}} />
                        </div>
                      } />
                    )}
                    {this.state.showFullTranslation ?
                      <Popup hideOnScroll on='click' position='bottom' content={
                        <div style={{fontFamily:customFontFam, fontSize:'14px'}}>
                          <span dangerouslySetInnerHTML={{__html: dialogueData[this.state.currentLesson]['content'][this.state.currentCounter]['translation']}} />
                        </div>
                      } trigger={
                        <Icon style={{color:'#d4d4d4',fontSize:'26px',marginLeft:'20px',marginTop:'0px',height:'34px'}} link name='comment alternate outline'>{'\n'}</Icon>
                      } />
                      :
                      null
                    }
                    {this.state.automaticPause ?
                      <span style={{marginLeft:'13px'}}><Icon circular disabled={this.state.playingAudio} name='volume up' style={{'color':'#a7a7a7',fontSize:'16px',cursor:(this.state.playingAudio ? '':'pointer')}} onClick={()=>{this.timeoutCounter(dialogueData[this.state.currentLesson]['content'][k]['visibility']); this.setState({currentlyPlaying:k},()=>{this.repeatAudio(dialogueData[this.state.currentLesson]['content'][k]['mp3'],true)})}} /></span>
                      :
                      null
                    }
                  </span>
                </div>
                
              :
              null
            }
{/*
            {currentType=='voicerecord' ?
              <RecorderControls />
              :
              null
            }*/}

            {!this.state.goNext && currentType == 'dialogue' && this.state.currentCounter == k && !this.state.playingAudio && this.state.currentCounter < 2 ? <div style={{marginTop:20,fontSize:16,color:'#a8a8a8de'}}>Say out loud then click audio</div>:null}

            </div>}
        )}


        {this.state.goNext && this.state.currentCounter !== -1 ?
          (this.state.currentCounter < dialogueData[this.state.currentLesson]['content'].length-1 ?
            (this.state.automatic ?
              <Button basic style={{margin:20}} onClick={()=>{this.playNextCounter(); this.setState({automaticPause:false})}}>Continue</Button>
              :
              <Button basic style={{margin:20}} onClick={()=>{this.playNextCounter()}}>Continue</Button>
            )
          :
          <Button basic style={{margin:20}} onClick={()=>{}}>Finish</Button>
          )
          :
          null
        }



        </div>
      </Grid.Column>
      </Grid>

      </div>
    );
  }
}
export default Dialogues;
